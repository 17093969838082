export const findClosestIndex = (searchIndex: number, availableIndexes: number[]) => {
  let closestIndex = 0;
  let minFound = Number.MAX_SAFE_INTEGER;
  const availableIndexesLength = availableIndexes.length;
  for (let i = 0; i < availableIndexesLength; i++) {
    const testIndex = availableIndexes[i];
    const diff = Math.abs(testIndex - searchIndex);
    if (diff < minFound) {
      closestIndex = testIndex;
      minFound = diff;
    }
  }
  return closestIndex;
};

export const replaceItemInList = <T>(list: T[], index: number, button: T) => {
  const newList = [...list];
  newList.splice(index, 1, button);
  return newList;
};
