<section>
  <main class="popup__page-container">
    <section class="full flex flex--column place-center justify-evenly">
      <h3 class="text-center">{{ '89' | translate }}</h3>
      <div class="option-box">
        <img skin-part="image" class="mt--60 size--140" alt="" src="/Channels/skin/icon-scanVoucher.svg" />
      </div>

      <img
        skin-part="image"
        src="/Channels/skin/icon-longArrowDown.svg"
        class="ui-button--icon color--accent size--100 mt--40 bounceDown"
        alt=""
      />
    </section>
  </main>
</section>
<footer class="popup__actions justify-center">
  <button class="ui-button ui-button--primary ui-button--small" (click)="cancelClicked()" data-automation="Cancel Button">
    <span class="ui-button__wrapper">
      <span>{{ '23' | translate }}</span>
    </span>
  </button>
</footer>
