import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutErrorComponent } from '@dotxix/pages/checkout/checkout-error/checkout-error.component';
import { CodViewComponent } from '@dotxix/pages/checkout/cod-view/cod-view.component';
import { MenuComponent } from '@dotxix/pages/menu/menu.component';
import { OrderNumberComponent } from '@dotxix/pages/checkout/order-number/order-number.component';
import { PreorderComponent } from '@dotxix/pages/checkout/preorder/preorder.component';
import { PromosComponent } from '@dotxix/pages/promotions/promos.component';
import { AppRoutes } from '@dotxix/app-routes';
import { BridgeOperationComponent } from '@dotxix/pages/checkout/bridge-operation/bridge-operation.component';
import { BannersComponent } from '@dotxix/pages/banners/banners.component';
import { ServiceTypeComponent } from '@dotxix/pages/service-type/service-type.component';
import { ReceiptOptionsComponent } from '@dotxix/pages/checkout/receipt/receipt-options/receipt-options.component';
import { ElectronicReceiptPageComponent } from '@dotxix/pages/checkout/receipt/electronic-receipt/electronic-receipt-page.component';
import { SmsReceiptPageComponent } from '@dotxix/pages/checkout/receipt/sms-receipt/sms-receipt-page.component';
import { EmailReceiptPageComponent } from '@dotxix/pages/checkout/receipt/email-receipt/email-receipt-page.component';
import { PayTowerStartPageComponent } from '@dotxix/pages/pay-tower-start-screen/pay-tower-start-page.component';

const routes: Routes = [
  {
    path: AppRoutes.Banners,
    component: BannersComponent,
  },
  {
    path: AppRoutes.ServiceTypeSelection,
    component: ServiceTypeComponent,
  },
  {
    path: AppRoutes.PayTowerStartPage,
    component: PayTowerStartPageComponent,
  },
  {
    path: AppRoutes.Menu,
    component: MenuComponent,
  },
  {
    path: AppRoutes.CheckoutError,
    component: CheckoutErrorComponent,
  },
  {
    path: AppRoutes.BridgeOperation,
    component: BridgeOperationComponent,
  },
  {
    path: AppRoutes.OrderNumber,
    component: OrderNumberComponent,
  },
  {
    path: AppRoutes.CodView,
    component: CodViewComponent,
  },
  {
    path: AppRoutes.Promos,
    component: PromosComponent,
  },
  {
    path: AppRoutes.Preorder,
    component: PreorderComponent,
  },
  {
    path: AppRoutes.ReceiptOptions,
    component: ReceiptOptionsComponent,
  },
  {
    path: AppRoutes.ElectronicReceipt,
    component: ElectronicReceiptPageComponent,
  },
  {
    path: AppRoutes.SmsReceipt,
    component: SmsReceiptPageComponent,
  },
  {
    path: AppRoutes.EmailReceipt,
    component: EmailReceiptPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
