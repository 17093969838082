import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { FaceRecallScreenSteps } from '../models/enums/face-recall-screen-steps.enum';
import { FaceRecallHttpService } from './face-recall-http.service';
import { OsirisLoginFaceRecallService } from './osiris-login-face-recall.service';
import { OsirisOrderStateService } from './osiris-orders-state.service';
import { OsirisStateService } from './osiris-state.service';

interface RetryWithPinState {
  maxRetries: number;
}

const initialState: RetryWithPinState = {
  maxRetries: 3,
};

@Injectable({ providedIn: 'root' })
export class RetryWithPinStateService {
  public retryWithPinState$ = new BehaviorSubject<RetryWithPinState>(initialState);
  public defaultMaxRetries = initialState.maxRetries;
  public currentRetries$ = this.retryWithPinState$.pipe(map((state) => state.maxRetries));
  constructor(
    private faceRecallHttpService: FaceRecallHttpService,
    private osirisOrderStateService: OsirisOrderStateService,
    private osirisLoginFaceRecallService: OsirisLoginFaceRecallService,
    private osirisStateService: OsirisStateService
  ) {}

  public tryRegisterWithPin(pin: string) {
    this.faceRecallHttpService
      .setPinForLastSearch(pin)
      .then((faceID) => {
        this.osirisOrderStateService.setCustomerIdentification({ customerID: faceID, customerIdentificationMethod: 'face-recall' });
        this.osirisLoginFaceRecallService.handleSuccessfulPinRegistration();
        this.retryWithPinState$.next(initialState);
        this.resetState();
      })
      .catch(() => {
        this.retryWithPinState$.next({ ...this.retryWithPinState$.value, maxRetries: --this.retryWithPinState$.value.maxRetries });
        if (this.retryWithPinState$.value.maxRetries === 0) {
          this.osirisStateService.setFaceRecallStep(FaceRecallScreenSteps.MaxPinRetries);
          this.resetState();
        }
      });
  }

  public resetState() {
    this.retryWithPinState$.next(initialState);
  }
}
