import { Injectable } from '@angular/core';
import { DemoMockService } from '@dotxix/services/demo-mock.service';
import { AtpPaymentService, PosPaidState, PosRefintService, PosTenderType } from 'dotsdk';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { getTenderMediaId, insertPaymentOperation, insertTender } from '@dotxix/payment/helpers/payment-elog.helper';
import { getPaymentName } from '@dotxix/payment/helpers/payment-type.helper';
import { paymentLogger } from '@dotxix/log-manager';

@Injectable({
  providedIn: 'root',
})
export class CardPaymentService {
  constructor(private demoMockService: DemoMockService) {}
  public async pay(amountOwned: number, paymentType: PaymentType) {
    paymentLogger.debug(`payment with card initiated ${amountOwned} ${JSON.stringify(paymentType)}`);
    const payResult =
      (await this.demoMockService.cardPayMock(amountOwned)) ||
      (await AtpPaymentService.getInstance()
        .pay(amountOwned, `${PosRefintService.getInstance()._refInt}`, getPaymentName(paymentType), this.computePaymentParams(paymentType))
        .catch(() => null));

    if (payResult && payResult.PaidAmount) {
      paymentLogger.debug(`card payment successfully executed`);
      insertPaymentOperation(true, getPaymentName(paymentType));
      insertTender(PosTenderType.CARD, PosPaidState.PAID, payResult.PaidAmount, getTenderMediaId(payResult, paymentType));
    } else {
      paymentLogger.debug(`card payment failed`);
      insertPaymentOperation(false, getPaymentName(paymentType));
    }

    return payResult;
  }

  private computePaymentParams(paymentType: PaymentType) {
    return paymentType.PaymentApplication
      ? JSON.stringify({
          OrderDetails: {
            PaymentApplication: paymentType.PaymentApplication,
          },
        })
      : '';
  }
}
