import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasketService } from '@dotxix/services/basket.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { DotButton, DotPage } from 'dotsdk';
import { RecommendationService } from '@dotxix/services/suggestions/recommendation-service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';

@Component({
  selector: 'acr-basket',
  templateUrl: './basket.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketComponent implements OnInit {
  @Output() public suggestionSelect = new EventEmitter<DotButton>();
  public basketSuggestionPages: DotPage[] = [];

  public numberOfItems$ = this.basketService.numberOfItems$;

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private basketService: BasketService,
    private checkoutService: CheckoutService,
    protected recoService: RecommendationService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    if (this.basketService.buttons.length) {
      this.fetchBasketSuggestions().then((suggestions) => {
        this.basketSuggestionPages = suggestions;
        this.changeDetector.detectChanges();
      });
    }
  }

  public isBasketSuggestionVisible(): boolean {
    return !!this.basketSuggestionPages?.length && !!this.basketSuggestionPages[0]?.Buttons?.length;
  }

  public hasOrderDiscount() {
    return this.checkoutService.hasOrderDiscount();
  }
  public getOrderDiscount() {
    return this.checkoutService.orderDiscount();
  }

  public get totalPriceAccountingForOrderDiscount(): number {
    return this.basketService.calculateTotalPrice(this.basketService.buttons) - this.checkoutService.orderDiscount();
  }

  public onCloseClicked(): void {
    this.basketService.closeBasket();
  }
  public async fetchBasketSuggestions(): Promise<DotPage[]> {
    if (this.applicationSettingsService.settings$.value.enableRecoModule) {
      if (this.basketService.buttons.length) {
        return await this.recoService.getBasketSuggestions(3, 1);
      }
    }
    return [];
  }
  public onSuggestionSelect(button: DotButton) {
    this.suggestionSelect.emit(button);
  }
}
