import { Component, OnInit } from '@angular/core';
import { AppBIService } from '../../../business-intelligence/bi.service';
import { BIPOSProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-bridge-operation',
  template: ` <acr-loading [waitingMessage]="'2020121701' | translate" [waitingMessage2]="'2020121702' | translate"></acr-loading> `,
})
export class BridgeOperationComponent implements OnInit {
  constructor(private appBiService: AppBIService) {}
  ngOnInit(): void {
    this.appBiService.actions.impressions.viewPosProgress(BIPOSProgressViews.POS_PROGRESS);
  }
}
