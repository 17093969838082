import { Injectable } from '@angular/core';
import { DotAvailabilityService, DotButton, DotButtonStatus, DotButtonType, DotPage, DotSuggestionSalesService } from 'dotsdk';
import {
  evaluateCatalogItemButtonAvailability,
  evaluateCatalogMenuButtonAvailability,
} from '@dotxix/services/suggestions/helpers/filter.helper';

@Injectable({ providedIn: 'root' })
export class SuggestionsSaleService {
  public getItemSuggestions(button: DotButton) {
    const suggestions = DotSuggestionSalesService.getInstance().getButtonSuggestion(button);
    return this.filterSuggestionsByStatusAndAvailability(suggestions ?? []);
  }

  public getOrderSuggestions() {
    const suggestions = DotSuggestionSalesService.getInstance().getOrderSuggestions();
    return this.filterSuggestionsByStatusAndAvailability(suggestions);
  }

  private filterSuggestionsByStatusAndAvailability(suggestions: DotPage[]) {
    if (!Array.isArray(suggestions)) {
      return [];
    }
    suggestions.forEach((p) => {
      p.Buttons = p.Buttons.filter((button) => {
        if (button.ButtonStatus === DotButtonStatus.DISABLED) {
          return false;
        }
        if (DotAvailabilityService.getInstance().isButtonAvailable(button)) {
          switch (button.ButtonType) {
            case DotButtonType.ITEM_BUTTON:
              return evaluateCatalogItemButtonAvailability(button);

            case DotButtonType.MENU_BUTTON:
              return evaluateCatalogMenuButtonAvailability(button);
          }
          return true;
        }
        return false;
      });
    });
    return suggestions;
  }
}
