import { Component } from '@angular/core';

@Component({
  selector: 'acr-face-recall-customer-already-exists',
  host: { class: 'full-height full-width' },
  template: `
    <div class="container">
      <p class="messages">{{ '2022051702' | translate }}</p>
    </div>
  `,
  styleUrls: ['../face-recall-common.scss'],
})
export class CustomerAlreadyExistingComponent {}
