import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { parseProductList } from '@dotxix/osiris/helpers/extract-osiris-order.helper';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';
import { TitleCatalogService } from '@dotxix/osiris/services/title-catalog.service';
import { PictureCatalogService } from '@dotxix/osiris/services/picture-catalog.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';

enum ServiceTypeI18n {
  EAT_IN = '11',
  TAKE_OUT = '12',
}

interface ProductList {
  extraItems?: number;
  products: SingleProduct[];
  isListInvalid: boolean;
}

interface SingleProduct {
  image: string;
  displayName: string;
  quantity: number;
}

@Component({
  selector: 'acr-osiris-order-card',
  templateUrl: './osiris-order-card.component.html',
})
export class OsirisOrderCardComponent implements OnInit {
  public currency = this.appSettings.settings$.value.currencySymbol;

  @Input() public order!: OsirisOrder;
  @Output() public reorder: EventEmitter<void> = new EventEmitter<void>();
  @Output() public viewFullOrder: EventEmitter<void> = new EventEmitter<void>();
  public productList!: ProductList;

  constructor(
    protected titleCatalogService: TitleCatalogService,
    protected pictureCatalogService: PictureCatalogService,
    private appSettings: ApplicationSettingsService
  ) {}

  public ngOnInit(): void {
    this.productList = this.extractProductList(this.order);
  }

  public extractServiceType(serviceType: number) {
    return serviceType === 0 ? ServiceTypeI18n.EAT_IN : ServiceTypeI18n.TAKE_OUT;
  }

  public reorderClicked() {
    this.reorder.emit();
  }

  public viewFullOrderClicked() {
    this.viewFullOrder.emit();
  }

  private extractProductList(order: OsirisOrder): ProductList {
    const parsedProducts = parseProductList(order.p);
    const products: SingleProduct[] = [];
    parsedProducts.forEach((product) => {
      const productTitle = this.titleCatalogService.transform(product.link);
      if (productTitle) {
        products.push({
          displayName: productTitle,
          image: this.pictureCatalogService.transform(product.link) ?? '',
          quantity: product.quantity,
        });
      }
    });
    const isListInvalid = products.length !== parsedProducts.length;
    return {
      ...(parsedProducts.length > 2 && { extraItems: parsedProducts.length - 2 }),
      products: isListInvalid ? [] : products.slice(0, 2),
      isListInvalid,
    };
  }
}
