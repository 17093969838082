<button
  data-automation="Pay Order Button"
  class="ui-button ui-button--secondary ui-button--x-large ui-button--iconRight"
  (click)="onClick()"
  [disabled]="isDisabled"
>
  <span class="ui-button__wrapper">
    <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
    <span>{{ '17' | translate }}</span>
  </span>
</button>
