<!-- <button
  class="ui-button ui-button--primary ui-button--tiny voucher-info--button"
  [style.visibility]="displayPromoButton ? 'visible' : 'hidden'"
  data-automation="Vouchers Button"
  (click)="onVouchersButtonClicked()"
>
  <span>{{ '4' | translate }}</span>
</button> -->

<div class="flex align-center">
  <button
    *ngIf="displayCaloriesButton"
    class="ui-button ui-button--link voucher-info--button"
    (click)="toCalories()"
    data-automation="Calories Button"
  >
    <span>{{ '2' | translate }}</span>
  </button>
  <button
    *ngIf="displayAllergensButton"
    class="ui-button ui-button--link pr--0 voucher-info--button"
    data-automation="Allergens Button"
    (click)="allergensClick()"
  >
    <span>{{ '3' | translate }}</span>
  </button>
  <button
    *ngIf="displayOrderHistoryButton"
    class="ui-button ui-button--primary ui-button--tiny ml--20 voucher-info--button"
    data-automation="Order History Button"
    (click)="orderHistoryClick()"
  >
    <span>{{ '20210331001' | translate }}</span>
  </button>
</div>
