import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { StepperStep } from '@dotxix/models/interfaces/stepper-step';

@Component({
  selector: 'acr-stepper',
  host: { class: 'full-height full-width' },
  template: `<div class="stepper">
    <div
      *ngFor="let step of steps; let stepIndex = index"
      class="stepper__step"
      [class.stepper__step--active]="stepIndex === activeIndex"
      [class.stepper__step--selected]="stepIndex < activeIndex"
      [class.stepper__step--visible]="visibleSteps.contains(stepIndex)"
      [class.stepper__step--last-item]="visibleSteps.last() === stepIndex"
    >
      <span class="stepper__step--name">{{ step.name }}</span>
      <span class="stepper__step--circle">
        <img
          skin-part="image"
          src="/Channels/skin/icon-pillCheckmark.svg"
          class="ui-button--icon size--32 color--accent stepper__step--icon"
          alt=""
        />
        <img *ngIf="step.image && stepIndex === activeIndex" class="stepper__step--image" [src]="step.image" alt="" />
      </span>
    </div>
  </div>`,
})
export class StepperComponent implements OnInit, OnChanges {
  @Input() steps: StepperStep[] = [];
  @Input() activeIndex = 0;

  public visibleSteps: number[] = [];
  public underlineWidth = 0;

  public ngOnInit() {
    this.computeVisualElements();
  }

  public ngOnChanges() {
    this.computeVisualElements();
  }

  private computeVisualElements() {
    this.visibleSteps = this.computeVisibleSteps();
    this.underlineWidth = this.computeUnderlineWidth();
  }

  private computeVisibleSteps() {
    let visibleSteps;
    const stepsLength = this.steps.length;
    if (this.activeIndex === 0) {
      visibleSteps = [0, 1, 2];
    } else if (this.activeIndex === stepsLength - 1) {
      visibleSteps = [this.activeIndex - 2, this.activeIndex - 1, this.activeIndex];
    } else {
      visibleSteps = [this.activeIndex - 1, this.activeIndex, this.activeIndex + 1];
    }
    return visibleSteps.filter((index) => index >= 0 && index < stepsLength);
  }

  private computeUnderlineWidth() {
    const oneStepPercentage = 100 / this.visibleSteps.length;
    return (this.visibleSteps.indexOf(this.activeIndex) + 1) * oneStepPercentage;
  }
}
