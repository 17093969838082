import { Injectable } from '@angular/core';
import { PaymentFailType } from '@dotxix/models/enums/payment-fail-type';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { CardPaymentService } from '@dotxix/payment/services/card-payment.service';
import { Router } from '@angular/router';
import { lastValueFrom, Subject } from 'rxjs';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { StatusService } from '@dotxix/services/status.service';
import { PaymentRetryComponent } from '@dotxix/payment/components/card-payment/payment-retry/payment-retry.component';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { PaymentRetryAnswer } from '@dotxix/payment/models/payment-retry-answer.enum';
import { PaymentRoutes } from '@dotxix/payment/payment-routes';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { CheckoutErrorParams } from '@dotxix/models/interfaces/checkout-error-params';
import { AppBIService } from '../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Injectable({ providedIn: 'root' })
export class CardPaymentFlowService {
  public cardPaymentWithSuccess$ = new Subject<void>();
  public displayPaymentSelection$ = new Subject<void>();
  public cancelOrderOnPaymentError$ = new Subject<CheckoutErrorParams>();

  constructor(
    private router: Router,
    private cardPaymentService: CardPaymentService,
    private checkoutService: CheckoutService,
    private paymentService: PaymentService,
    private applicationSettingsService: ApplicationSettingsService,
    private statusService: StatusService,
    private dynamicContentService: DynamicContentService,
    private appBiService: AppBIService
  ) {}

  public async startPayment(paymentType: PaymentType) {
    const amountOwed = this.checkoutService.getAmountOwed();
    this.paymentService.onCardPaymentAttempt(paymentType.PaymentName);
    if (amountOwed === 0) {
      this.cardPaymentWithSuccess$.next();
      return;
    }

    await this.router.navigate([PaymentRoutes.CardPayment]);

    const payResult = await this.cardPaymentService.pay(amountOwed, paymentType);
    if (payResult && payResult.PaidAmount) {
      this.paymentService.onCardPaymentSuccess(payResult.PaidAmount);
      this.cardPaymentWithSuccess$.next();
    } else {
      this.onCardPaymentError(paymentType);
    }
  }

  private onCardPaymentError(paymentType: PaymentType) {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CARD_PAYMENT_ERROR);
    if (this.canRetryPayment(paymentType)) {
      if (
        this.applicationSettingsService.settings$.value.paymentFailRedirect === PaymentFailType.PAY_SELECTION &&
        this.statusService.state$.value.availablePayments.length > 1
      ) {
        this.displayPaymentSelection$.next();
      } else {
        this.showRetryPaymentPrompt(paymentType);
      }
    } else {
      this.cancelOrderOnPaymentError$.next({ showCardErrorImage: true });
    }
  }

  private canRetryPayment(paymentType: PaymentType) {
    const maxPaymentAttempts = Math.max(1, paymentType.PaymentRetries || 1);
    return this.getExecutedPaymentAttempts(paymentType.PaymentName) < maxPaymentAttempts;
  }

  private getExecutedPaymentAttempts(paymentName: string) {
    return this.paymentService.state$.value.card.executedCardPaymentAttempts[paymentName] || 0;
  }

  private showRetryPaymentPrompt(paymentType: PaymentType) {
    lastValueFrom(this.dynamicContentService.openContent(PaymentRetryComponent, {}).afterClosed).then((response) => {
      if (response !== undefined) {
        this.handlePaymentRetry(paymentType, response);
      }
    });
  }

  private handlePaymentRetry(paymentType: PaymentType, response: PaymentRetryAnswer) {
    if (response === PaymentRetryAnswer.Yes) {
      this.startPayment(paymentType);
    } else {
      this.cancelOrderOnPaymentError$.next({ showCardErrorImage: true });
    }
  }
}
