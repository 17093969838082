import { Component, OnInit } from '@angular/core';
import { DotButton } from 'dotsdk';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { MakeItAMealDataParams } from '@dotxix/models/interfaces/make-it-a-meal-data-params';
import { SessionService } from '@dotxix/services/session.service';
import { ButtonInfoService } from '@dotxix/button-info.service';

@Component({
  selector: 'acr-make-it-a-meal',
  templateUrl: './make-it-a-meal.component.html',
})
export class MakeItAMealComponent
  extends AbstractDynamicAnimatedPopup<MakeItAMealDataParams, DotButton | null | undefined>
  implements OnInit
{
  public infoButtons: { button: DotButton; showInfoButton: boolean }[] = [];

  constructor(
    public sessionService: SessionService,
    protected buttonInfoService: ButtonInfoService
  ) {
    super();
  }

  public ngOnInit(): void {
    qaAutomationLog('makeItAMeal: ', this.dataParams!.buttons);
    this.infoButtons = this.dataParams!.buttons.map((button) => ({
      button,
      showInfoButton: this.buttonInfoService.showProductInfo(button),
    }));
  }

  public onNoThanksClicked() {
    this.close(null);
  }

  public selectedMeal(button: DotButton) {
    this.close(button);
  }

  public onProductInfoClick(event: MouseEvent, button: DotButton): void {
    event.stopPropagation();
    this.buttonInfoService.openProductInfo(button);
  }
}
