<language-change-view-trigger></language-change-view-trigger>
<div class="basket-toggler" [class.basket-toggler--opened]="basketOpened">
  <button (click)="click()" class="basket-toggler__button" data-automation="Basket Toggle Button">
    <div class="flex flex--column">
      <div class="flex justify-center align-end">
        <img skin-part="image" src="/Channels/skin/icon-meal.svg" class="ui-button--icon size--64 color--accent" alt="" />
        <div class="basket-toggler__items">
          <h5 class="basket-toggler__items--text" data-automation="Basket Message">
            <ng-container [ngSwitch]="numberOfItems">
              <ng-container *ngSwitchCase="0">{{ '6' | translate }}</ng-container>
              <ng-container *ngSwitchCase="1">{{ '19' | translate }}</ng-container>
              <ng-container *ngSwitchDefault>{{ '92' | translate }}</ng-container>
            </ng-container>
          </h5>
        </div>
      </div>
      <div class="basket-toggler__line"></div>
      <h6 class="basket-toggler__text fs--26">{{ '7' | translate }}</h6>
    </div>
    <img
      skin-part="image"
      src="/Channels/skin/icon-chevronUp.svg"
      class="basket-toggler__icon-chevron size--24"
      alt=""
      [class.basket-toggler__icon-chevron--opened]="basketOpened"
    />
    <div *ngIf="numberOfItems" class="basket-toggler__items-number">
      <h5 class="fs--40" data-automation="basket-number-of-items">{{ numberOfItems }}</h5>
    </div>
  </button>
</div>
