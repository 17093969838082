import { RecommendedProduct } from '@dotxix/services/suggestions/models/recommended-product';
import { DotButton, DotCatalogLoader, DotCombosCatalogLoader } from 'dotsdk';
import { RecommendedProductType } from '@dotxix/services/suggestions/models/recommended-product-type';
import { recoLogger } from '@dotxix/services/suggestions/logger';
import * as _ from 'lodash';
import { DotButtonSuggestionMetadata } from 'dotsdk';

export const mapRecoProductToDotButton = (recommendedProduct: RecommendedProduct): DotButton | null => {
  const comboCatalog = DotCombosCatalogLoader.getInstance().loadedModel;
  const itemsCatalog = DotCatalogLoader.getInstance().loadedModel;

  let catalogButton: DotButton | undefined;
  if (recommendedProduct.productType === RecommendedProductType.MENU_BUTTON && comboCatalog) {
    catalogButton = DotCombosCatalogLoader.getInstance().findByLink(recommendedProduct.productId);
  } else if (recommendedProduct.productType === RecommendedProductType.ITEM_BUTTON && itemsCatalog) {
    catalogButton = DotCatalogLoader.getInstance().findByLink(recommendedProduct.productId);
  }
  if (!catalogButton) {
    recoLogger
      .warn(`RECO: Suggested ${recommendedProduct.productType} with link: ${recommendedProduct.productId} was not found in catalog.`)
      .then();
  } else {
    if (catalogButton.ExcludeS4) {
      recoLogger
        .warn(
          `RECO: Suggested ${recommendedProduct.productType} with link: ${recommendedProduct.productId} was excluded as marked in the catalog with ExcludeS4.`
        )
        .then();
    } else {
      const clonedButton = _.cloneDeep(catalogButton);
      clonedButton.metadata.suggestionMetadata = new DotButtonSuggestionMetadata('0', '0', undefined, undefined);
      return clonedButton;
    }
  }

  return null;
};
