import { Injectable } from '@angular/core';
import { ReceiptOption } from '@dotxix/services/receipt/models/receipt-option.enum';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { SessionService } from '@dotxix/services/session.service';

export const RECEIPT_NAME_DATE_FORMAT = 'yyyy-MM-dd_HH-mm';

@Injectable({
  providedIn: 'root',
})
export class ReceiptService {
  public availableReceiptOptions: ReceiptOption[] = [];

  constructor(
    private sessionService: SessionService,
    private applicationSettingsService: ApplicationSettingsService
  ) {
    this.sessionService.sessionStarted.subscribe(() => {
      this.availableReceiptOptions = this.computeAvailableReceiptOptions();
    });
  }

  private computeAvailableReceiptOptions() {
    const options = [ReceiptOption.PAPER_PRINT];
    if (this.applicationSettingsService.settings$.value.ReceiptEmailEnabled) {
      options.push(ReceiptOption.EMAIL);
    }
    if (this.applicationSettingsService.settings$.value.ReceiptElectronicEnabled) {
      options.push(ReceiptOption.ELECTRONIC_RECEIPT);
    }
    if (this.applicationSettingsService.settings$.value.ReceiptSmsEnabled) {
      options.push(ReceiptOption.SMS);
    }
    return options;
  }
}
