import { Component, OnInit } from '@angular/core';
import { DotButton, DotModifier, getCatalogButton } from 'dotsdk';
import * as _ from 'lodash';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { ButtonDetailsDataParams } from '@dotxix/models/interfaces/button-details-data-params';
import {
  addAutoCompleteModifiers,
  forceShowModifierGroupsThatAreNotReachingMinQuantity,
  isModifierSelectionValidForBasket,
} from '@dotxix/helpers/modifiers.helper';
import {
  computeAutoPopFeatDefaults,
  computeButtonDisplayPrice,
  isItemPackButton,
  isNotItemPackButton,
} from '@dotxix/helpers/button.helper';
import {
  computeModifierStepComponentsMinPrice,
  deselectModifierStepComponent,
  getSelectedModifierStepComponent,
  initializeModifierStepperButton,
  multiplyModifiersWithMinQuantity,
} from '@dotxix/helpers/modifier-stepper.helper';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';
import { Subject } from 'rxjs';
import { comboCustomizableModifiers, hasComboCustomizableModifiers, selectComponent } from '@dotxix/helpers/combo.helper';
import { StepperStep } from '@dotxix/models/interfaces/stepper-step';
import { filterCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { ProductCustomizationService } from '@dotxix/services/product-customization.service';
import { AppBIService } from '../../business-intelligence/bi.service';

enum ModifierStepState {
  CHOOSE_COMPONENT = 'chooseComponent',
  CUSTOMIZE = 'customize',
  SUBGROUP = 'subgroup',
}

@Component({
  selector: 'acr-modifiers-stepper',
  templateUrl: './modifiers-stepper.component.html',
})
export class ModifiersStepperComponent
  extends AbstractDynamicAnimatedPopup<ButtonDetailsDataParams, DotButton | null | undefined>
  implements OnInit
{
  public ModifierStepState = ModifierStepState;
  public button!: DotButton<ButtonState>;
  public catalogButton!: DotButton;
  public modifierTitle!: string;

  public displayBackButton = false;
  public disableConfirmButton = false;

  public dynamicHeaderImages!: boolean;
  public headerImages!: string[];

  public currentModifierStepState!: ModifierStepState;
  public visibleModifierSteps: DotModifier[] = [];
  public stepperSteps: StepperStep[] = [];
  public currentModifierStepIndex = 0;
  public isLastStep = false;
  public currentModifierStep!: DotModifier;
  public currentModifierStepComponents: DotButton[] = [];
  public currentModifierStepComponentsMinPrice!: number;
  public currentModifierStepComponentFeatures: DotModifier[] = [];
  public itemPackVisibleComponents: DotButton[] = [];

  public scrollToTop$ = new Subject<void>();

  constructor(
    private appSettings: ApplicationSettingsService,
    private translationsService: TranslationsService,
    private productTranslationsService: ProductTranslationsService,
    private productCustomizationService: ProductCustomizationService,
    private appBIService: AppBIService
  ) {
    super();
  }

  public ngOnInit() {
    this.appBIService.actions.impressions.viewProductCustomization(this.dataParams!.button);
    this.button = _.cloneDeep(this.dataParams!.button);
    initializeModifierStepperButton(this.button);
    qaAutomationLog('button: ', this.button);
    this.button.ModifiersPage.Modifiers = multiplyModifiersWithMinQuantity(this.button.ModifiersPage.Modifiers);
    this.visibleModifierSteps = this.button.ModifiersPage.Modifiers;

    this.catalogButton = getCatalogButton(this.button.Link);
    this.modifierTitle = this.catalogButton ? this.translationsService.getTranslatedButtonCaption(this.catalogButton) ?? '' : '';

    this.updateProps();

    this.dynamicHeaderImages = this.appSettings.settings$.value.enableComboAnimation;
    this.headerImages = this.computeHeaderImages();

    this.listModifierStepComponents(0);
    this.autoSelectCurrentModifierStepComponent();
  }

  public computeComponentDisplayPrice(component: DotButton): number {
    return computeButtonDisplayPrice(component, this.currentModifierStepComponentsMinPrice ?? 0);
  }

  public currentModifierStepComponentFeaturesChanged() {
    this.updateNavigationButtonStates();
  }

  public isModifierStepComponentSelected(component: DotButton) {
    return isNotItemPackButton(component) ? component.Selected : component.Page.Buttons.some((itemPackButton) => itemPackButton.Selected);
  }

  public onBackButtonClicked() {
    switch (this.currentModifierStepState) {
      case ModifierStepState.CHOOSE_COMPONENT:
        this.previousVisibleStep();
        break;
      case ModifierStepState.CUSTOMIZE:
        this.currentModifierStepState = ModifierStepState.CHOOSE_COMPONENT;
        break;
      case ModifierStepState.SUBGROUP:
        this.currentModifierStepState = ModifierStepState.CHOOSE_COMPONENT;
        break;
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
    this.appBIService.actions.clicks.clickedProductCustomizationStepBack(this.button);
  }

  public onComponentButtonClicked(component: DotButton) {
    if (isItemPackButton(component)) {
      this.listModifierStepItemPack(component);
    } else {
      this.selectCurrentModifierStepComponent(component);
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  public onConfirmClicked() {
    if (this.currentModifierStepState === ModifierStepState.CUSTOMIZE) {
      const selectedModifierStepComponent = getSelectedModifierStepComponent(this.currentModifierStep);
      if (selectedModifierStepComponent) {
        addAutoCompleteModifiers(selectedModifierStepComponent);
      }
    }
    this.goToNextVisibleStepOrFinishModifier();
  }

  public cancelClick(): void {
    this.productCustomizationService.onCancelButton(null, this);
  }

  public onItemPackComponentButtonClicked(component: DotButton) {
    this.selectCurrentModifierStepComponent(component);
  }

  private listModifierStepComponents(modifierStepIndex: number) {
    this.currentModifierStepIndex = modifierStepIndex;
    this.updateIsLastStep();
    this.currentModifierStep = this.visibleModifierSteps[this.currentModifierStepIndex];
    this.currentModifierStepState = ModifierStepState.CHOOSE_COMPONENT;
    this.updateCurrentModifierStepComponents();
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private updateCurrentModifierStepComponents() {
    if (this.currentModifierStep) {
      this.currentModifierStepComponents = this.currentModifierStep.Buttons;
      this.currentModifierStepComponentsMinPrice = computeModifierStepComponentsMinPrice(this.currentModifierStepComponents);
    }
  }

  private autoSelectCurrentModifierStepComponent() {
    if (this.currentModifierStepComponents.length === 1 && isNotItemPackButton(this.currentModifierStepComponents.first())) {
      this.selectCurrentModifierStepComponent(this.currentModifierStepComponents.first());
      if (this.currentModifierStepState !== ModifierStepState.CUSTOMIZE) {
        this.nextVisibleStep();
      }
    }
  }

  private nextVisibleStep() {
    if (this.visibleModifierSteps[this.currentModifierStepIndex + 1]) {
      this.listModifierStepComponents(this.currentModifierStepIndex + 1);
      this.autoSelectCurrentModifierStepComponent();
    }
  }

  private previousVisibleStep() {
    if (this.visibleModifierSteps[this.currentModifierStepIndex - 1]) {
      this.listModifierStepComponents(this.currentModifierStepIndex - 1);
      if (this.currentModifierStepComponents.length === 1) {
        const selectedModifierStepComponent = getSelectedModifierStepComponent(this.currentModifierStep);
        if (selectedModifierStepComponent) {
          this.customizeComponentFeaturesIfAny(selectedModifierStepComponent);
        }
      }
    }
  }

  private selectCurrentModifierStepComponent(componentButton: DotButton) {
    const selectedButton = this.visibleModifierSteps[this.currentModifierStepIndex].Buttons.find((button) => button.Selected);
    if (selectedButton !== componentButton) {
      deselectModifierStepComponent(this.currentModifierStepComponents);
    }
    selectComponent(componentButton);
    computeAutoPopFeatDefaults(componentButton);
    this.updateDynamicHeaderImages();
    this.updateNavigationButtonStates();

    if (componentButton.hasModifiers) {
      forceShowModifierGroupsThatAreNotReachingMinQuantity(componentButton);
      this.customizeComponentFeaturesIfAny(componentButton);
    }
  }

  private customizeComponentFeaturesIfAny(component: DotButton) {
    if (hasComboCustomizableModifiers(component, !this.dataParams!.isUpdate)) {
      this.customizeComponentModifiers(component);
    }
  }

  private customizeComponentModifiers(component: DotButton) {
    this.currentModifierStepComponentFeatures = comboCustomizableModifiers(component, !this.dataParams!.isUpdate);
    this.currentModifierStepState = ModifierStepState.CUSTOMIZE;
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private listModifierStepItemPack(component: DotButton) {
    this.currentModifierStepState = ModifierStepState.SUBGROUP;
    this.itemPackVisibleComponents = filterCatalogAvailable(component.Page.Buttons);
  }

  private goToNextVisibleStepOrFinishModifier() {
    if (this.visibleModifierSteps[this.currentModifierStepIndex + 1]) {
      this.appBIService.actions.clicks.clickedProductCustomizationStepForward(this.button);
      this.nextVisibleStep();
    } else {
      this.appBIService.actions.clicks.clickedProductCustomizationAccept(this.button);
      this.modifierFinished();
    }
  }

  private modifierFinished() {
    const clonedButton = _.cloneDeep(this.button);
    this.close(clonedButton);
  }

  private updateProps() {
    this.updateCurrentModifierStepComponents();
    this.stepperSteps = this.visibleModifierSteps.map((modifier) => ({
      name: this.translationsService.translateModifierName(modifier) ?? '',
      image: this.productTranslationsService.translateModifierImage(modifier) ?? '',
    }));
  }

  private updateIsLastStep() {
    this.isLastStep = this.currentModifierStepIndex === this.visibleModifierSteps.length - 1;
  }

  private updateDynamicHeaderImages() {
    if (this.dynamicHeaderImages) {
      this.headerImages = this.computeDynamicHeaderImages();
    }
  }

  private computeStaticHeaderImages() {
    const headerImageButton = this.catalogButton ? this.catalogButton : this.button;
    const buttonHeaderImage = this.productTranslationsService.translatePicture(headerImageButton);
    return buttonHeaderImage ? [buttonHeaderImage] : [];
  }

  private computeDynamicHeaderImages() {
    return [
      ...this.computeStaticHeaderImages(),
      ...this.visibleModifierSteps.reduce((acc, modifier) => {
        const selectedButton = getSelectedModifierStepComponent(modifier);
        const picturePath = selectedButton ? this.productTranslationsService.translatePicture(selectedButton) : null;
        return picturePath ? [...acc, picturePath] : acc;
      }, [] as string[]),
    ];
  }

  private computeHeaderImages() {
    if (this.dynamicHeaderImages) {
      return this.computeDynamicHeaderImages();
    } else {
      return this.computeStaticHeaderImages();
    }
  }

  private updateNavigationButtonStates() {
    this.displayBackButton =
      this.currentModifierStepIndex > 0 ||
      ((this.currentModifierStepState === ModifierStepState.CUSTOMIZE || this.currentModifierStepState === ModifierStepState.SUBGROUP) &&
        this.currentModifierStepComponents.length > 1);

    this.disableConfirmButton = !this.isCurrentStepValid();
  }

  private isCurrentStepValid() {
    const selectedModifierStepComponent = getSelectedModifierStepComponent(this.currentModifierStep);
    switch (this.currentModifierStepState) {
      case ModifierStepState.CHOOSE_COMPONENT:
      case ModifierStepState.SUBGROUP:
        return !!selectedModifierStepComponent;
      case ModifierStepState.CUSTOMIZE:
        return selectedModifierStepComponent ? isModifierSelectionValidForBasket(selectedModifierStepComponent) : false;
    }
  }

  private scrollToTop() {
    this.scrollToTop$.next();
  }
}
