import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'acr-item-pack-indicator',
  template: `<language-change-view-trigger></language-change-view-trigger
    ><ng-container>
      <div class="item-pack-indicator">
        <div class="item-pack-indicator__ticket" [class.item-pack-indicator__ticket--checked]="checked">
          <span *ngIf="!checked" class="item-pack-indicator__ticket--ripple"></span>
        </div>
      </div>
    </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemPackIndicatorComponent {
  @Input() public checked!: boolean;
}
