import { GetKnownCustomerApiResponse } from '@dotxix/osiris/models/interfaces/get-known-customer.interface';
import { DotSdkMocksManager, IJsonMocksOverride, MockPromptEffects, TAB_TYPES } from 'dotsdk';

export class PayWithFace implements IJsonMocksOverride {
  private uiPanel!: HTMLElement;
  private dotSdkMockManager = DotSdkMocksManager.getInstance();

  public processInputArgs(mockJson: GetKnownCustomerApiResponse): Promise<string> {
    return new Promise((resolve, reject) => {
      const btnSuccess = document.createElement('button');
      const btnFail = document.createElement('button');

      btnFail.innerText = 'Error';
      btnSuccess.innerText = 'Success';

      this.uiPanel = this.dotSdkMockManager.addMockPrompt({
        title: `Pay with face`,
        interactiveElements: [
          { htmlElement: btnSuccess, clickHandler: () => this.successClick(resolve, mockJson) },
          { htmlElement: btnFail, clickHandler: () => this.failClick(reject, mockJson) },
        ],
        canRememberChoice: true,
        uniqueId: this.dotSdkMockManager.sanitizePersistedMockReplayKey(`pay-with-face`),
        hostTabType: TAB_TYPES.PROMISED_MOCKS,
        afterAppendEffects: [MockPromptEffects.OPEN_DRAWER_TAB_AND_FOCUS_MOCK_PROMPT],
        afterCTAEffects: [MockPromptEffects.REMOVE_PANEL_AND_CLOSE_DRAWER_IF_HOST_TAB_EMPTY],
      });
    });
  }
  private successClick(resolve: (value: string | PromiseLike<string>) => void, mockJson: GetKnownCustomerApiResponse) {
    resolve(mockJson.FaceID);
  }
  private failClick(reject: (reason?: unknown) => void, mockJson: GetKnownCustomerApiResponse) {
    reject(mockJson.Error);
  }
}
