import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CustomThemeFlowService {
  // public themeEditorState: BehaviorSubject<ThemeEditorState> = this.themeEditorStateService.themeEditorState$;
  // constructor(
  //   private themeEditorStateService: ThemeEditorStateService,
  //   private writeToFileService: WriteToFileService,
  //   private GeneratedThemeStateService: GeneratedThemeStateService,
  //   private logoPickerStateService: LogoPickerStateService
  // ) {}

  public writeToFile() {
    // this.writeToFileService
    //   .writeCssVariablesToFile({
    //     availableDefaults: this.themeEditorState.value.availableDefaultVariables,
    //     generalTheming: this.themeEditorState.value.generalThemingVariables,
    //     generatedTheming: this.GeneratedThemeStateService.generatedThemState$.value,
    //     logoImage: this.logoPickerStateService.logoPickerState$.value.logoImage
    //       ? this.logoPickerStateService.logoPickerState$.value.logoImage
    //       : null,
    //   })
    //   .then((response: boolean) => this.themeEditorStateService.changesSent(AtpSaveFileResponse[response.toString()]))
    //   .catch();
  }
}
