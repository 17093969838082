import { Component } from '@angular/core';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';
import { OsirisLoginFaceRecallService } from '@dotxix/osiris/services/osiris-login-face-recall.service';

@Component({
  selector: 'acr-face-recall-error',
  host: { class: 'full-height full-width' },
  template: `
    <div class="container" *ngIf="faceRecall$ | async as faceRecall">
      <p class="messages error" *ngIf="!faceRecall.customError">{{ '2022050301' | translate }}</p>
      <p class="messages error" *ngIf="faceRecall.customError">{{ faceRecall.customError }}</p>
      <div class="actions full-width">
        <button class="ui-button ui-button--secondary ui-button--x-large" (click)="onTryAgainClicked()">
          {{ '2022042902' | translate }}
        </button>
        <button class="ui-button ui-button--secondary ui-button--x-large" (click)="onContinueWithoutClicked()">
          {{ '2022042901' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['../face-recall-common.scss'],
})
export class FaceRecallErrorComponent {
  public faceRecall$ = this.osirisStateService.faceRecall$;

  constructor(
    private osirisStateService: OsirisStateService,
    private osirisLoginFaceRecallService: OsirisLoginFaceRecallService
  ) {}

  public onTryAgainClicked() {
    this.osirisLoginFaceRecallService.retry();
  }

  public onContinueWithoutClicked() {
    this.osirisLoginFaceRecallService.errorScreenOnContinueWithoutUser();
  }
}
