import { Injectable } from '@angular/core';
import { TicketPrintSelectionComponent } from '@dotxix/components/ticket-print-selection/ticket-print-selection.component';
import { PrintTicketOption } from '@dotxix/models/enums/print-ticket-option';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { ReceiptService } from '@dotxix/services/receipt/receipt.service';
import { BehaviorSubject, firstValueFrom, merge, Subject, take, tap } from 'rxjs';
import { ReceiptOption } from '@dotxix/services/receipt/models/receipt-option.enum';
import { Router } from '@angular/router';
import { AppRoutes } from '@dotxix/app-routes';
import { ReceiptQuestionTimeoutService } from '@dotxix/services/receipt/receipt-question-timeout.service';
import { filter, map } from 'rxjs/operators';
import { ElectronicReceiptFlowService } from '@dotxix/services/receipt/electronic-receipt-flow.service';
import { ReceiptDeliveryResult } from '@dotxix/services/receipt/models/receipt-delivery-result.enum';
import { EmailReceiptFlowService } from '@dotxix/services/receipt/email-receipt-flow.service';
import { SmsReceiptFlowService } from '@dotxix/services/receipt/sms-receipt-flow.service';
import { receiptLogger } from '@dotxix/log-manager';

const logPrefix = '[Receipt Flow] ';

@Injectable({
  providedIn: 'root',
})
export class ReceiptFlowService {
  private $receiptDelivered = new BehaviorSubject<boolean>(false);
  private $userSelectedReceiptOption = new Subject<ReceiptOption>();

  constructor(
    private router: Router,
    private applicationSettingsService: ApplicationSettingsService,
    private paymentService: PaymentService,
    private checkoutService: CheckoutService,
    private dynamicContentService: DynamicContentService,
    private receiptService: ReceiptService,
    private receiptQuestionTimeoutService: ReceiptQuestionTimeoutService,
    private electronicReceiptFlowService: ElectronicReceiptFlowService,
    private emailReceiptFlowService: EmailReceiptFlowService,
    private smsReceiptFlowService: SmsReceiptFlowService
  ) {}

  public async deliverReceipt() {
    if (
      this.paymentService.state$.value.cash.cashFailedToRefundAmount > 0 ||
      this.paymentService.state$.value.cash.cashBack.cashBackFailedToRefundAmount > 0
    ) {
      receiptLogger.debug(`${logPrefix}Failed to return all cash. Printing full receipt`);
      this.$receiptDelivered.next(true);
      return this.checkoutService.printReceipt({ printTicketOption: PrintTicketOption.FULL });
    }

    this.$receiptDelivered.next(false);

    if (this.receiptService.availableReceiptOptions.length === 1) {
      receiptLogger.debug(`${logPrefix}Only one available receipt option: ${this.receiptService.availableReceiptOptions[0]}`);
      this.deliverReceiptAs(this.receiptService.availableReceiptOptions[0]);
    } else {
      this.presentReceiptOptions();
    }

    await firstValueFrom(this.$receiptDelivered.pipe(filter((receiptDelivered) => receiptDelivered)));
  }

  public userSelectedReceiptOption(receiptOption: ReceiptOption) {
    receiptLogger.debug(`${logPrefix}User selected ${receiptOption}`);
    this.$userSelectedReceiptOption.next(receiptOption);
  }

  private presentReceiptOptions() {
    receiptLogger.debug(`${logPrefix}Prompting user for receipt option`);
    this.router.navigate([AppRoutes.ReceiptOptions]);
    const $timedOut = this.receiptQuestionTimeoutService.start();
    const $defaultAnswer = $timedOut.pipe(
      map(() => ReceiptOption.PAPER_PRINT),
      tap(() => receiptLogger.debug(`${logPrefix}Question timed out, auto selecting paper print`))
    );
    merge(this.$userSelectedReceiptOption, $defaultAnswer)
      .pipe(take(1))
      .subscribe((receiptOption) => this.deliverReceiptAs(receiptOption));
  }

  private deliverReceiptAs(receiptOption: ReceiptOption) {
    receiptLogger.debug(`${logPrefix}Delivering receipt as ${receiptOption}`);
    switch (receiptOption) {
      case ReceiptOption.PAPER_PRINT:
        return this.printPaperReceipt();
      case ReceiptOption.ELECTRONIC_RECEIPT:
        return this.electronicReceiptFlowService.deliver().then((result) => this.processReceiptDeliveryResult(result));
      case ReceiptOption.EMAIL:
        return this.emailReceiptFlowService.deliver().then((result) => this.processReceiptDeliveryResult(result));
      case ReceiptOption.SMS:
        return this.smsReceiptFlowService.deliver().then((result) => this.processReceiptDeliveryResult(result));
    }
  }

  private processReceiptDeliveryResult(result: ReceiptDeliveryResult) {
    switch (result) {
      case ReceiptDeliveryResult.RETURN_TO_OPTIONS:
        return this.presentReceiptOptions();
      case ReceiptDeliveryResult.SUCCESS:
        return this.$receiptDelivered.next(true);
      case ReceiptDeliveryResult.TIMEOUT:
        return this.receiptDeliveryTimedOut();
    }
  }

  private receiptDeliveryTimedOut() {
    receiptLogger.debug(`${logPrefix}Receipt delivery timed out, printing paper receipt`);
    this.checkoutService.printReceipt({ printTicketOption: PrintTicketOption.FULL });
    this.$receiptDelivered.next(true);
  }

  private printPaperReceipt() {
    if (
      this.applicationSettingsService.settings$.value.allowNoCCReceipt &&
      this.paymentService.state$.value.cash.cashFailedToRefundAmount === 0 &&
      this.paymentService.state$.value.cash.cashBack.cashBackFailedToRefundAmount === 0 &&
      !this.checkoutService.orderNotPaidWillPayAtCounter
    ) {
      receiptLogger.debug(`${logPrefix}Prompt user for paper print confirmation`);
      this.dynamicContentService.openContent(TicketPrintSelectionComponent).afterClosed.subscribe((printTicketOption) => {
        this.checkoutService.printReceipt({ printTicketOption: printTicketOption || PrintTicketOption.NONE });
        receiptLogger.debug(`${logPrefix}Delivered receipt as paper print`);
        this.$receiptDelivered.next(true);
      });
    } else {
      this.checkoutService.printReceipt({ printTicketOption: PrintTicketOption.FULL });
      receiptLogger.debug(`${logPrefix}Delivered receipt as paper print`);
      this.$receiptDelivered.next(true);
    }
  }
}
