import { KioskStatusColor } from '@dotxix/models/enums/kiosk-status-color';

export interface StatusColorCalculatorInputs {
  isCardEnabledInBS: boolean;
  isCardAvailable: boolean;
  isCashEnabledInBS: boolean;
  isCashAvailable: boolean;
  isCashInventoryWarn: boolean;
  isElectronicPayEnabledInBS: boolean;
  isElectronicPayAvailable: boolean;
}

export const statusColor = ({
  isCardEnabledInBS,
  isCardAvailable,
  isCashEnabledInBS,
  isCashAvailable,
  isCashInventoryWarn,
  isElectronicPayEnabledInBS,
  isElectronicPayAvailable,
}: StatusColorCalculatorInputs): KioskStatusColor => {
  const isCardUsable = isCardEnabledInBS && isCardAvailable;
  const isCashUsable = isCashEnabledInBS && isCashAvailable;
  const isElectronicPayUsable = isElectronicPayEnabledInBS && isElectronicPayAvailable;

  if (!isCardUsable && !isCashUsable && !isElectronicPayUsable) {
    return KioskStatusColor.RED;
  }

  if (isCashEnabledInBS && !isCashAvailable) {
    return KioskStatusColor.BLUE;
  }
  if ((isCardEnabledInBS && !isCardAvailable) || (isElectronicPayEnabledInBS && !isElectronicPayAvailable)) {
    return KioskStatusColor.PURPLE;
  }
  if (isCashEnabledInBS && isCashAvailable && isCashInventoryWarn) {
    return KioskStatusColor.ORANGE;
  }
  return KioskStatusColor.NO_COLOR;
};
