import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { extractToKeyValue } from '@dotxix/custom-theme/helpers/general-helpers.helper';
import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';
import { GeneratedTheming } from '@dotxix/custom-theme/models/generated-theme-state.interface';
import { CssVariablesInjectService } from '@dotxix/custom-theme/services/css-variables-inject.service';

const initialState: GeneratedTheming = {};

@Injectable({ providedIn: 'root' })
export class GeneratedThemeStateService {
  public generatedThemState$ = new BehaviorSubject<GeneratedTheming>(initialState);
  public generatedVariableUpdated$ = new Subject<void>();

  constructor(private cssVariablesInjectService: CssVariablesInjectService) {
    // this.cssVariablesInjectService.atpLoadedCssVariables$.subscribe((value) => {
    //   if (value) {
    //     this.generatedThemState$.next({
    //       ...this.generatedThemState$.value,
    //       ...value.generatedTheming,
    //     });
    //   }
    // });
  }

  public updateGeneratedVariable(cssVariable: CssVariables, identifier: string) {
    const { key, value } = extractToKeyValue(cssVariable);
    this.generatedThemState$.next({
      ...this.generatedThemState$.value,
      [`${identifier}`]: { ...this.generatedThemState$.value[identifier], [`${key}`]: value },
    });
    this.generatedVariableUpdated$.next();
  }
}
