import { Injectable } from '@angular/core';
import { AtpApplicationSettings, AtpEnvironmentService, DotSessionService } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { InactivityService } from '@dotxix/services/inactivity.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { environment } from '../../environments/environment';
import { OmsService } from '@dotxix/services/oms.service';
import { StatusService } from '@dotxix/services/status.service';
import { AppFlowService } from '@dotxix/services/app-flow.service';
import { MetaTagsService } from '@dotxix/services/meta-tags.service';
import { TouchlessService } from '@dotxix/services/touchless.service';
import { RecommendationService } from '@dotxix/services/suggestions/recommendation-service';
import { CssVariablesInjectService } from '@dotxix/custom-theme/services/css-variables-inject.service';
import { PromosService } from '@dotxix/services/promos.service';
import { appLogger } from '@dotxix/log-manager';
import { SkinService } from '@dotxix/skin/skin.service';
import { ChannelsService } from '@dotxix/services/channels.service';
import { PayTowerAppFlowService } from '@dotxix/services/pay-tower-app-flow.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private appSettings: ApplicationSettingsService,
    private skinService: SkinService,
    private translationsService: TranslationsService,
    private inactivityService: InactivityService,
    private omsService: OmsService,
    private statusService: StatusService,
    private appFlowService: AppFlowService,
    private appFlowPayTowerService: PayTowerAppFlowService,
    private metaTagsService: MetaTagsService,
    private touchlessService: TouchlessService,
    private cssVariablesInjectService: CssVariablesInjectService,
    private recommendationService: RecommendationService,
    private promotionsService: PromosService,
    private channelsService: ChannelsService
  ) {}

  public initialize() {
    this.addKioskDeviceClassToDocumentIfMBirdIsConnected();
    this.hideAtpCloseButton();
    this.openDeveloperTools();
    this.appSettings.initialize();
    this.skinService.initialize();
    this.translationsService.initialize();
    this.statusService.initialize();
    this.omsService.initialize();
    this.inactivityService.initialize();
    this.metaTagsService.initialize();
    this.touchlessService.initialize();
    this.cssVariablesInjectService.initialize();
    this.recommendationService.initialize();
    this.promotionsService.initialize();
    this.channelsService.initialize();
    DotSessionService.initializationFinished();
    this.appFlowService.onAppInitialized();
    this.appFlowPayTowerService.initialize();
  }

  private openDeveloperTools() {
    if (
      environment.production ||
      !AtpApplicationSettings.getInstance().applicationDetailsJson.IsBeta ||
      !AtpEnvironmentService.getInstance().mBirdIsConnected()
    ) {
      appLogger.debug('openDeveloperTools skipped');
      return;
    }
    AtpEnvironmentService.getInstance()
      .openDeveloperTools()
      .then(() => {});
    appLogger.debug('ATP openDeveloperTools called');
  }

  private hideAtpCloseButton() {
    if (environment.production) {
      appLogger.debug('ATP hideCloseButton called');
      AtpEnvironmentService.getInstance()
        .hideCloseButton()
        .then(() => {})
        .catch(() => {
          appLogger.debug('Error hiding the close button');
        });
    }
  }

  private addKioskDeviceClassToDocumentIfMBirdIsConnected() {
    if (AtpEnvironmentService.getInstance().mBirdIsConnected()) {
      document.documentElement.classList.add('kioskDevice');
    }
  }
}
