<div class="popup calories" data-automation="calories-popup">
  <div class="popup__content">
    <main class="popup__page-container">
      <img *ngIf="images && images.length > 0" class="calories__img full" [src]="currentImage" alt="" />
      <p *ngIf="!images || images.length <= 0" class="fs--40" [innerHtml]="'2020012202' | translate"></p>
    </main>
    <footer class="flex justify-center place-center calories__actions">
      <button class="ui-button ui-button--secondary ui-button--small gap--10" (click)="closeClick()" data-automation="Cancel Button">
        <span class="ui-button__wrapper">
          <img skin-part="image" src="/Channels/skin/icon-cross-basket.svg" class="ui-button--icon size--25" alt="" />
          <span>{{ '24' | translate }}</span>
        </span>
      </button>
      <nav *ngIf="images && images.length > 0" class="flex justify-center place-center calories__pagination">
        <button class="flex justify-center place-center" (click)="previousImage()" [disabled]="currentIndex <= 0">
          <img
            skin-part="image"
            src="/Channels/skin/icon-chevronLeft.svg"
            class="ui-button--icon size--42 color--accent"
            alt=""
            acrRevertOnRtl
          />
        </button>
        <h5 class="calories__pagination--number">{{ currentIndex + 1 }}</h5>
        <button class="flex justify-center place-center" (click)="nextImage()" [disabled]="images && currentIndex >= images.length - 1">
          <img
            skin-part="image"
            src="/Channels/skin/icon-chevronRight.svg"
            class="ui-button--icon size--42 color--accent"
            alt=""
            acrRevertOnRtl
          />
        </button>
      </nav>
    </footer>
  </div>
</div>
