<language-change-view-trigger></language-change-view-trigger>
<div class="button-modifier-list">
  <header class="button-modifier-list__header">
    <h5 class="fs--40" *ngIf="modifier.PageInfo | pageTitle as groupTitle">
      {{ groupTitle }}
    </h5>
    <h5 class="button-modifier-list__header--title" *ngIf="isBucketStandard">
      <span *ngIf="groupMaxQuantityReached">{{ '73' | translate }}</span>
    </h5>
  </header>
  <ng-container *ngIf="displayModifierButtons">
    <ng-container *ngIf="(!isComplementModifier && !isIceModifier) || (isIceModifier && !hasIceModifierButtonsAndTags)">
      <div class="modifiers-grid" [class.modifiers-grid--dual-modifiers]="isDualModifiers">
        <ng-container *ngFor="let modifierButton of modifier.Buttons; let i = index; trackBy: trackByLink">
          <acr-button-modifier
            *ngIf="!isItemPack(modifierButton)"
            [modifierButton]="modifierButton"
            [groupMaxQuantity]="modifier.PageInfo.MaxQuantity"
            [disableIncrease]="!isBucketStandard && groupMaxQuantityReached"
            [chargeThresholdGroup]="chargeThresholdGroup"
            (clicked)="onModifierButtonClicked(modifierButton)"
            (quantityChanged)="onButtonModifierQuantityChanged($event, modifierButton)"
          ></acr-button-modifier>
          <acr-button-item-pack
            *ngIf="isItemPack(modifierButton)"
            [itemPack]="modifierButton"
            (clicked)="onItemPackClicked(modifierButton)"
          ></acr-button-item-pack>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="isComplementModifier">
      <acr-complement-modifier [complementModifier]="modifier" (modifierChanged)="onModifierChanged()"></acr-complement-modifier>
    </ng-container>

    <ng-container *ngIf="isIceModifier && hasIceModifierButtonsAndTags">
      <acr-ice-modifier [modifier]="modifier" [isUpdate]="!onOrder" (modifierChanged)="onModifierChanged()"></acr-ice-modifier>
    </ng-container>
  </ng-container>
</div>
