import { Component, OnInit } from '@angular/core';
import { AllergensService } from '@dotxix/services/allergens.service';
import { DotAllergen } from 'dotsdk';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { AppBIService } from '../../business-intelligence/bi.service';

@Component({
  selector: 'acr-allergen-selection',
  templateUrl: './allergen-selection.component.html',
})
export class AllergenSelectionComponent extends AbstractDynamicAnimatedPopup<undefined> implements OnInit {
  public supportedAllergenIcons: string[] = [
    'CER',
    'LUP',
    'SOJ',
    'LAI',
    'OEU',
    'CEL',
    'FRU',
    'ARA',
    'SES',
    'MOU',
    'POI',
    'MOL',
    'CRU',
    'SUL',
  ];
  public displayTabbedContent = false;

  public get allergens() {
    return this.allergensService.allergens;
  }

  constructor(
    private allergensService: AllergensService,
    private appBiService: AppBIService
  ) {
    super();
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  public isAllergenIconAvailable(allergen: DotAllergen): boolean {
    return this.supportedAllergenIcons.includes(allergen.Name);
  }

  public isAllergenSelected(allergen: DotAllergen): boolean {
    return this.allergensService.selectedAllergens.some((a) => a.Name === allergen.Name);
  }

  public resetFilterClick() {
    this.appBiService.actions.clicks.clickedAllergenFilterReset();
    this.allergensService.resetAllergens();
  }

  public closeClick() {
    this.appBiService.actions.clicks.clickedAllergenFilterOptionsClose();
    this.close();
  }

  public allergensClick(allergen: DotAllergen) {
    if (this.allergensService.selectedAllergens.some((a) => a.Name === allergen.Name)) {
      this.appBiService.actions.clicks.clickedAllergenFilterDeselect(allergen.Name);
      this.allergensService.removeAllergen(allergen);
    } else {
      this.appBiService.actions.clicks.clickedAllergenFilterSelect(allergen.Name);
      this.allergensService.addAllergen(allergen);
    }
  }
  public tabbedContentClick() {
    this.displayTabbedContent = !this.displayTabbedContent;
  }
}
