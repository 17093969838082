import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AdaptabilityService } from '@dotxix/services/adaptability.service';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { SkinService } from '@dotxix/skin/skin.service';

@Component({
  selector: 'acr-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent {
  public skinLoaded$ = this.skinService.loaded$;
  public isAdaEnabled$ = this.adaService.isAdaEnabled$;
  public showBlockingLoader$ = this.orderFlowService.showBlockingLoader$;

  constructor(
    private skinService: SkinService,
    private adaService: AdaptabilityService,
    private orderFlowService: OrderFlowService,
    public applicationSettingsService: ApplicationSettingsService
  ) {}
}
