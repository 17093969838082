import { logManager } from 'dotsdk';

export const appLogManager = logManager.configure({
  minLevels: {
    '': 'error',
    application: 'debug',
  },
});

export const appLogger = appLogManager.getLogger('application');
export const exceptionsLogger = appLogManager.getLogger('application.exceptions');
export const paymentLogger = appLogManager.getLogger('application.payment');
export const osirisLogger = appLogManager.getLogger('application.osiris');
export const tableServiceLogger = appLogManager.getLogger('application.table-service');
export const electronicPaymentLogger = appLogManager.getLogger('application.electronicPayment');
export const popIdLogger = appLogManager.getLogger('application.popID');
export const receiptLogger = appLogManager.getLogger('application.receipt');
