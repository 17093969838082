import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MaintenanceService } from 'dotsdk';

@Component({
  selector: 'acr-admin-zone',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminZoneComponent implements OnInit, OnDestroy {
  public ngOnInit() {
    MaintenanceService.getInstance().addAdminGestureZone({
      cssWidth: '10vw',
      cssHeight: '10vw',
      tapCount: 4,
      tapInterval: 400,
    });
  }

  public ngOnDestroy() {
    MaintenanceService.getInstance().removeAdminGestureZone();
  }
}
