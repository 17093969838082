<language-change-view-trigger></language-change-view-trigger>
<ng-container *ngFor="let step of displaySteps; let stepIndex = index; trackBy: trackByPath">
  <ng-container *ngIf="visibleIndex === stepIndex">
    <img
      *ngIf="step.isImage"
      class="slide-show__item full"
      [class.slide-show__item--animated]="isCssOnly()"
      [src]="basePath + '/' + step.path"
      alt=""
      [@enter]="resolveAnimationTypeEnter(stepIndex)"
      [@leave]="resolveAnimationTypeLeave()"
      [@.disabled]="isCssOnly()"
    />
    <video
      *ngIf="step.isWebm || step.isMp4"
      class="slide-show__item"
      [class.slide-show__item--animated]="isCssOnly()"
      [src]="basePath + '/' + step.path"
      [attr.type]="step.isMp4 ? 'video/mp4' : step.isWebm ? 'video/webm' : ''"
      [@enter]="resolveAnimationTypeEnter(stepIndex)"
      [@leave]="resolveAnimationTypeLeave()"
      [@.disabled]="isCssOnly()"
      autoplay
      muted
      loop
    ></video>
  </ng-container>
</ng-container>
