import { Component, Input } from '@angular/core';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-confirm-order',
  templateUrl: './confirm-order.component.html',
})
export class ConfirmOrderComponent {
  @Input() public isDisabled = false;

  constructor(
    private orderFlowService: OrderFlowService,
    private appBiService: AppBIService
  ) {}
  public async click() {
    this.appBiService.actions.clicks.clickedOrderConfirmation();
    this.orderFlowService.onCheckoutButtonClicked().then(() => {});
  }
}
