<div class="popup osiris-login-scan" data-automation="osiris-login-scan-popup">
  <div class="popup__content flex flex--column place-center justify-center">
    <div class="osiris-login-scan__wrapper">
      <h2 class="popup__title">
        <span>{{ '2022060901' | translate }}</span>
      </h2>
      <acr-osiris-scan (barcodeChanged)="barcodeChanged($event)"></acr-osiris-scan>
    </div>
    <footer class="popup__actions justify-center">
      <button class="ui-button ui-button--primary ui-button--small" (click)="cancelClick()" data-automation="Cancel Button">
        <span class="ui-button__wrapper">
          <span>{{ '23' | translate }}</span>
        </span>
      </button>
    </footer>
  </div>
</div>
