import { Component, OnInit } from '@angular/core';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-table-service-confirmation',
  templateUrl: './table-service-confirmation.component.html',
})
export class TableServiceConfirmationComponent implements OnInit {
  constructor(private appBiService: AppBIService) {}
  public ngOnInit() {
    this.appBiService.actions.impressions.viewTableServiceConfirmation();
  }
}
