import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TableServiceConfirmationComponent } from '@dotxix/table-service/pages/table-service-confirmation/table-service-confirmation.component';
import { TableServiceEntryComponent } from '@dotxix/table-service/pages/table-service-entry/table-service-entry.component';
import { TableServiceSelectionComponent } from '@dotxix/table-service/pages/table-service-selection/table-service-selection.component';
import { TableServiceUnavailableComponent } from '@dotxix/table-service/pages/table-service-unavailable/table-service-unavailable.component';
import { TableServiceRoutes } from '@dotxix/table-service/table-service-routes';

const routes: Routes = [
  {
    path: TableServiceRoutes.Confirmation,
    component: TableServiceConfirmationComponent,
  },
  {
    path: TableServiceRoutes.Entry,
    component: TableServiceEntryComponent,
  },
  {
    path: TableServiceRoutes.Selection,
    component: TableServiceSelectionComponent,
  },
  {
    path: TableServiceRoutes.Unavailable,
    component: TableServiceUnavailableComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TableServiceRoutingModule {}
