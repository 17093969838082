<main class="page-container payment-selection" [class.payment-selection__footer]="(availablePayments$ | async)!.length > 4">
  <header class="page-header">
    <img src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <section class="flex flex--column flex--1 justify-center align-center ml--50 mr--50">
    <div class="flex align-baseline gap--20">
      <h3>{{ '2001000' | translate }}</h3>
      <span class="fs--64 color--accent">{{ amountOwed | dotCurrency: true }}</span>
    </div>
    <h3>{{ '2001001' | translate }}</h3>
    <!-- Choose your payment -->
    <ng-container *ngIf="availablePayments$ | async as availablePayments">
      <acr-scrollable-container class="payment-selection-listing">
        <div
          class="payment-selection__types"
          [ngClass]="{ 'payment-selection__single-item': availablePayments.length < 2 }"
          *ngIf="availablePayments.length > 0"
        >
          <button
            class="payment-selection__type"
            *ngFor="let payment of availablePayments"
            [disabled]="cashGloryPayableAmountReached && payment.PaymentType === PAYMENT_TYPE.CASH"
            (click)="onPaymentTypeClicked(payment)"
            [attr.data-automation]="'Payment Type: ' + payment.PaymentType"
          >
            <img
              class="ui-button--icon size--200 payment-selection__type--image payment-selection__type--payment-image"
              src="/Channels/skin/icon-{{ payment.Image }}.svg"
              alt=""
            />
            <div class="payment-selection__type--text">
              <h3 class="payment-selection__type--name">
                <ng-container [ngSwitch]="payment.PaymentType">
                  <ng-container *ngSwitchCase="PAYMENT_TYPE.CARD">{{ '2001002' | translate }}</ng-container>
                  <ng-container *ngSwitchCase="PAYMENT_TYPE.CASH">{{ '2001003' | translate }}</ng-container>
                  <ng-container *ngSwitchCase="PAYMENT_TYPE.PAY_TOWER">{{ '2001039' | translate }}</ng-container>
                  <ng-container *ngSwitchCase="PAYMENT_TYPE.PREORDER">{{ '2001004' | translate }}</ng-container>
                  <ng-container *ngSwitchCase="PAYMENT_TYPE.EPAY">{{ '2023013001' | translate }}</ng-container>
                  <ng-container *ngSwitchCase="PAYMENT_TYPE.FACE">{{ '2022110101' | translate }}</ng-container>
                </ng-container>
              </h3>
              <img src="/Channels/skin/icon-pillChevronRight.svg" class="size--44 payment-selection__type--image" alt="" acrRevertOnRtl />
            </div>
          </button>
        </div>
      </acr-scrollable-container>
    </ng-container>
  </section>
  <acr-footer>
    <button
      middle-slot
      (click)="onCancelClicked()"
      class="ui-button ui-button--primary ui-button--tertiary ui-button--medium"
      data-automation="Cancel Payment Selection"
    >
      <span class="ui-button__wrapper">
        <span>{{ '2001005' | translate }}</span>
      </span>
    </button>
  </acr-footer>
</main>
