<div class="popup promo-stepper" data-automation="promo-stepper-popup">
  <div class="popup__content">
    <div class="popup__page-container promo-stepper__page-container">
      <header class="flex flex--column place-center justify-center p--20">
        <h3 class="text-center">
          <ng-container *ngIf="(promotionState$ | async)! as promotionState">
            {{ promotionState.currentPromotionPage ? (promotionState.currentPromotionPage | pageTitle) : '' }}
          </ng-container>
        </h3>
        <button
          *ngIf="!(promotionState$ | async)!.isRootPage || (currentPromoNode$ | async)?.back"
          class="ui-button ui-button--primary bg--tertiary ui-button--tiny ui-button--iconLeft mt--30"
          (click)="previousStep()"
          data-automation="Promo Back Button"
        >
          <span class="ui-button__wrapper">
            <img skin-part="image" src="/Channels/skin/icon-chevronLeft.svg" class="ui-button--icon size--20" alt="" acrRevertOnRtl />
            <span>{{ '9' | translate }}</span>
          </span>
        </button>
      </header>

      <div class="flex flex--column scrollY hideScrollbar">
        <div class="modifiers-grid p--40">
          <acr-button-item
            *ngFor="let button of (promotionState$ | async)!.currentPromotionPage?.Buttons; let index = index"
            [button]="button"
            [selected]="button.quantity > 0"
            [isDisabled]="
              (promotionState$ | async)!.disableButtons &&
              !button.Selected &&
              button.ButtonType !== DotButtonType.PAGE_BUTTON &&
              button.ButtonType !== DotButtonType.ITEM_PACK_BUTTON
            "
            [displayPrice]="button | calculateButtonPrice | dotCurrency"
            [showCounterEdit]="(promotionState$ | async)!.maxQuantity > 1"
            [quantity]="button.quantity"
            [maxQuantity]="computeMaxQuantity(button)"
            [isIncreaseDisabled]="(promotionState$ | async)!.disableButtons"
            (buttonClicked)="promoButtonClicked(button, index)"
            (quantityUpdate)="onQuantityUpdate(button, index, $event)"
          ></acr-button-item>
        </div>
      </div>

      <footer class="popup__actions promo-stepper__actions">
        <button class="ui-button ui-button--primary ui-button--small" (click)="cancelClick()" data-automation="Cancel Button">
          <span class="ui-button__wrapper">
            <span>{{ '23' | translate }}</span>
          </span>
        </button>

        <acr-stepper
          *ngIf="stepperSteps && stepperSteps.length > 1"
          [steps]="stepperSteps"
          [activeIndex]="(currentStepIndex$ | async)!"
        ></acr-stepper>

        <button
          *ngIf="(promotionState$ | async)!.isRootPage || !(currentPromoNode$ | async)?.next"
          [disabled]="(promotionState$ | async)!.disableAddToBag"
          class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
          (click)="nextStep()"
          data-automation="Continue Button"
        >
          <span class="ui-button__wrapper">
            <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
            <span *ngIf="(currentPromoNode$ | async)?.next">{{ '28' | translate }}</span>
            <span *ngIf="!(currentPromoNode$ | async)?.next">{{ '27' | translate }}</span>
          </span>
        </button>
      </footer>
    </div>
  </div>
</div>
