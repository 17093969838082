import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNumberInRange } from '@dotxix/helpers/number-range.helper';
import { KeyboardKey } from '@dotxix/models/interfaces/keyboard-key';
import { KeyboardLayout } from '@dotxix/models/interfaces/keyboard-layout';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';

const textKeyboard: KeyboardLayout = {
  name: 'text',
  layout: [
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((_) => <KeyboardKey>{ type: 'number', value: _ }),
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P']
      .map((_) => <KeyboardKey>{ type: 'text', value: _ })
      .concat({ type: 'backspace', value: '' }),
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'].map((_) => <KeyboardKey>{ type: 'text', value: _ }).concat({ type: 'blank', value: '_' }),
    ['Z', 'X', 'C', 'V', 'B', 'N', 'M']
      .map((_) => <KeyboardKey>{ type: 'text', value: _ })
      .concat({ type: 'empty', value: '' }, { type: 'empty', value: '' }),
  ],
};

const numericKeyboard: KeyboardLayout = {
  name: 'numeric',
  layout: [
    ['1', '2', '3', '4', '5'].map((_) => <KeyboardKey>{ type: 'number', value: _ }),
    ['6', '7', '8', '9', '0'].map((_) => <KeyboardKey>{ type: 'number', value: _ }),
    [
      { type: 'empty', value: '' },
      { type: 'empty', value: '' },
      { type: 'backspace', value: '' },
      { type: 'clear', value: 'clear' },
    ],
  ],
};

const numpadKeyboard: KeyboardLayout = {
  name: 'numpad',
  layout: [
    ['1', '2', '3'].map((_) => <KeyboardKey>{ type: 'number', value: _ }),
    ['4', '5', '6'].map((_) => <KeyboardKey>{ type: 'number', value: _ }),
    ['7', '8', '9'].map((_) => <KeyboardKey>{ type: 'number', value: _ }),
    ['0', '+'].map((_) => <KeyboardKey>{ type: 'number', value: _ }).concat({ type: 'backspace', value: '' }),
    [{ type: 'clear', value: 'clear' }],
  ],
};

const mailKeyboard: KeyboardLayout = {
  name: 'email',
  layout: [
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((_) => <KeyboardKey>{ type: 'number', value: _ }),
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'].map((_) => <KeyboardKey>{ type: 'text', value: _ }),
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'].map((_) => <KeyboardKey>{ type: 'text', value: _ }),
    ['z', 'x', 'c', 'v', 'b', 'n', 'm'].map((_) => <KeyboardKey>{ type: 'text', value: _ }),
    [
      { type: 'email', value: '@gmail.com' },
      { type: 'email', value: '@yahoo.com' },
      { type: 'email', value: '@hotmail.com' },
    ],
    ['-', '_', '.', '@']
      .map((_) => <KeyboardKey>{ type: 'text', value: _ })
      .concat({ type: 'dotcom', value: ' .com' }, { type: 'clear', value: 'clear' }, { type: 'backspace', value: '' }),
  ],
};

@Component({
  selector: 'acr-keyboard',
  templateUrl: './keyboard.component.html',
})
export class KeyboardComponent {
  @Input() public text = '';
  @Input() public hideValue = false;
  @Input() public set reset(val: boolean) {
    if (val) {
      const resetKey: KeyboardKey = { type: 'clear', value: '' };
      this.keyPress(resetKey);
    }
    return;
  }
  @Input() public keyboardLayout: string | undefined;
  @Input() public useRange = false;
  @Input() public limitNumberChars: number | undefined;

  @Output() public inputChanged: EventEmitter<string> = new EventEmitter();
  protected keyboards = [textKeyboard, numericKeyboard, numpadKeyboard, mailKeyboard];
  private tentNumberMaximumValue: number;
  private tentNumberMinimumValue: number;
  private tentNumberFormatLength: number;

  constructor(private applicationSettingsService: ApplicationSettingsService) {
    this.tentNumberMaximumValue = this.applicationSettingsService.settings$.value.tentNumberMaximumValue;
    this.tentNumberMinimumValue = this.applicationSettingsService.settings$.value.tentNumberMinimumValue;
    this.tentNumberFormatLength = this.applicationSettingsService.settings$.value.tentNumberFormatLength;
  }

  public get keyboard(): KeyboardLayout {
    const k = this.keyboards.find((kb) => kb.name === this.keyboardLayout);
    return k ? k : textKeyboard;
  }

  public keyPress(key: KeyboardKey): void {
    switch (key.type) {
      case 'number':
      case 'email':
      case 'text':
        this.text += key.value;
        break;
      case 'backspace':
        if (this.text.length > 0) {
          this.text = this.text.slice(0, -1);
        }
        break;
      case 'blank':
        this.text += '_';
        break;
      case 'clear':
        this.text = '';
        break;
      case 'dotcom':
        this.text += '.com';
        break;
    }
    this.inputChanged.emit(this.text);
  }

  public isKeyDisabled(keyType: string, keyValue: string): boolean {
    return (
      (this.text.length < 1 && (keyType === 'backspace' || keyType === 'clear')) ||
      (this.useRange &&
        keyType === 'number' &&
        (this.text.length === this.tentNumberFormatLength ||
          !isNumberInRange(keyValue, this.text, this.tentNumberMinimumValue, this.tentNumberMaximumValue)) &&
        this.keyboard.name === 'numeric') ||
      (typeof this.limitNumberChars === 'number' && this.text.length >= this.limitNumberChars && keyType === 'number')
    );
  }
}
