import { Pipe, PipeTransform } from '@angular/core';
import { DotButton, DotButtonType, getCatalogButton, getCombosCatalogButton } from 'dotsdk';
import { LocalizationService } from '@dotxix/services/localization.service';

@Pipe({
  name: 'catalogButtonMinPrice',
})
export class CatalogButtonMinPricePipe implements PipeTransform {
  constructor(protected localizationService: LocalizationService) {}

  public transform(mealButton: DotButton): string | null {
    let button: DotButton | undefined = undefined;
    switch (mealButton.ButtonType) {
      case DotButtonType.MENU_BUTTON:
        button = getCombosCatalogButton(mealButton.Link);
        break;
      case DotButtonType.ITEM_BUTTON:
        button = getCatalogButton(mealButton.Link);
    }
    return button && button.MinPrice ? this.localizationService.formatCurrency(button.MinPrice) : null;
  }
}
