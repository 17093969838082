import { Injectable } from '@angular/core';
import { ReceiptDeliveryResult } from '@dotxix/services/receipt/models/receipt-delivery-result.enum';
import { Router } from '@angular/router';
import { AppRoutes } from '@dotxix/app-routes';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { PrintTicketOption } from '@dotxix/models/enums/print-ticket-option';
import { BehaviorSubject, firstValueFrom, merge, Observable, Subject, take, timer } from 'rxjs';
import { StoreReceiptWithRetryResult } from '@dotxix/services/receipt/models/store-receipt-with-retry-result.enum';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { filter, map } from 'rxjs/operators';
import { ConfirmRetryStoreElectronicReceiptResult } from '@dotxix/services/receipt/models/confirm-retry-store-electronic-receipt-answer.enum';
import { ReceiptQuestionTimeoutService } from '@dotxix/services/receipt/receipt-question-timeout.service';
import { SessionService } from '@dotxix/services/session.service';
import { AtpElectronicReceipt, AtpStoreElectronicReceiptResponse } from 'dotsdk';
import { receiptLogger } from '@dotxix/log-manager';

const logPrefix = '[Electronic Receipt Flow] ';

@Injectable({
  providedIn: 'root',
})
export class ElectronicReceiptFlowService {
  public $storingReceiptContent = new BehaviorSubject<boolean>(false);
  public $electronicReceiptResponse = new BehaviorSubject<AtpStoreElectronicReceiptResponse | null>(null);
  private $userConfirmedElectronicReceiptDelivery = new Subject<void>();
  private $userRequestedToReturnToReceiptOptions = new Subject<void>();
  private sessionCachedReceiptDetails: AtpStoreElectronicReceiptResponse | null = null;

  constructor(
    private router: Router,
    private checkoutService: CheckoutService,
    private applicationSettingsService: ApplicationSettingsService,
    private dynamicContentService: DynamicContentService,
    private translationService: TranslationsService,
    private receiptQuestionTimeoutService: ReceiptQuestionTimeoutService,
    private sessionService: SessionService
  ) {
    this.sessionService.sessionStarted.subscribe(() => {
      this.sessionCachedReceiptDetails = null;
      this.$electronicReceiptResponse.next(null);
      this.$storingReceiptContent.next(false);
    });
  }

  public async deliver(): Promise<ReceiptDeliveryResult> {
    await this.router.navigate([AppRoutes.ElectronicReceipt]);

    const printableContent = await this.checkoutService.generatePrintableReceiptContent({ printTicketOption: PrintTicketOption.FULL });
    const storeReceiptResult = await this.storeReceiptWithRetry(printableContent);

    if (storeReceiptResult === StoreReceiptWithRetryResult.CANCEL) {
      return ReceiptDeliveryResult.RETURN_TO_OPTIONS;
    }
    if (storeReceiptResult === StoreReceiptWithRetryResult.TIMEOUT) {
      return ReceiptDeliveryResult.TIMEOUT;
    }

    return await this.presentQrCodeWithReceiptUrlAndWaitForConfirmation(storeReceiptResult as AtpStoreElectronicReceiptResponse);
  }

  public userConfirmedElectronicReceiptDelivery() {
    receiptLogger.debug(`${logPrefix}User confirmed electronic receipt delivery`);
    this.$userConfirmedElectronicReceiptDelivery.next();
  }

  public userRequestedToReturnToReceiptOptions() {
    receiptLogger.debug(`${logPrefix}User requested to return to receipt options`);
    this.$userRequestedToReturnToReceiptOptions.next();
  }

  private async storeReceiptWithRetry(
    receiptContentMarkup: string
  ): Promise<StoreReceiptWithRetryResult | AtpStoreElectronicReceiptResponse> {
    if (this.sessionCachedReceiptDetails !== null) {
      receiptLogger.debug(`${logPrefix}Receipt already stored at ${this.sessionCachedReceiptDetails.Url}`);
      return this.sessionCachedReceiptDetails;
    }
    this.$storingReceiptContent.next(true);
    receiptLogger.debug(`${logPrefix}Storing receipt in electronic format`);
    const storeResult = await AtpElectronicReceipt.getInstance()
      .storeElectronicReceipt(receiptContentMarkup)
      .catch(() => null);
    this.$storingReceiptContent.next(false);
    if (storeResult?.Url) {
      receiptLogger.debug(`${logPrefix}Receipt stored at ${storeResult.Url}`);
      this.sessionCachedReceiptDetails = storeResult;
      return this.sessionCachedReceiptDetails;
    } else {
      receiptLogger.debug(`${logPrefix}Failed to store electronic receipt. Prompt user for retry`);
      const retryConfirmation = await firstValueFrom(this.confirmStoreElectronicReceiptRetry());
      receiptLogger.debug(`${logPrefix}Retry result ${retryConfirmation}`);
      switch (retryConfirmation) {
        case ConfirmRetryStoreElectronicReceiptResult.TIMEOUT:
          return StoreReceiptWithRetryResult.TIMEOUT;
        case ConfirmRetryStoreElectronicReceiptResult.CANCEL:
          return StoreReceiptWithRetryResult.CANCEL;
        case ConfirmRetryStoreElectronicReceiptResult.RETRY:
          return await this.storeReceiptWithRetry(receiptContentMarkup);
      }
    }
  }

  private confirmStoreElectronicReceiptRetry(): Observable<ConfirmRetryStoreElectronicReceiptResult> {
    return new Observable((observer) => {
      const confirmDialogRef = this.dynamicContentService.openContent(ConfirmDialogComponent, {
        title: this.translationService.translate('2003005'),
        leftButtonText: this.translationService.translate('23'),
        rightButtonText: this.translationService.translate('70'),
      });

      const $timeout = timer(this.applicationSettingsService.settings$.value.timeoutTicketQuestion * 1000).pipe(
        map(() => ConfirmRetryStoreElectronicReceiptResult.TIMEOUT)
      );
      const $userAnswer = confirmDialogRef.afterClosed.pipe(
        filter((answer) => !!answer),
        map((answer) => {
          if (answer === ConfirmDialogButtons.LEFT_BUTTON) {
            return ConfirmRetryStoreElectronicReceiptResult.CANCEL;
          } else {
            return ConfirmRetryStoreElectronicReceiptResult.RETRY;
          }
        })
      );
      const subscription = merge($userAnswer, $timeout)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            observer.next(result);
          }
          observer.complete();
        });
      return () => {
        subscription.unsubscribe();
        confirmDialogRef.close();
        observer.complete();
      };
    });
  }

  private async presentQrCodeWithReceiptUrlAndWaitForConfirmation(
    atpStoreElectronicReceiptResponse: AtpStoreElectronicReceiptResponse
  ): Promise<ReceiptDeliveryResult> {
    this.$electronicReceiptResponse.next(atpStoreElectronicReceiptResponse);
    const $userConfirmedElectronicReceiptDelivery = this.$userConfirmedElectronicReceiptDelivery.pipe(
      map(() => ReceiptDeliveryResult.SUCCESS)
    );
    const $userRequestedToReturnToReceiptOptions = this.$userRequestedToReturnToReceiptOptions.pipe(
      map(() => ReceiptDeliveryResult.RETURN_TO_OPTIONS)
    );
    const $timedOut = this.receiptQuestionTimeoutService.start();
    const $timeoutResponse = $timedOut.pipe(map(() => ReceiptDeliveryResult.SUCCESS));

    return await firstValueFrom(merge($userConfirmedElectronicReceiptDelivery, $userRequestedToReturnToReceiptOptions, $timeoutResponse));
  }
}
