import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { AppRoutes } from '@dotxix/app-routes';
import { CalculateTotalMode } from '@dotxix/models/enums/calculate-total-mode';
import { CheckoutType } from '@dotxix/models/enums/checkout-type';
import { PAYMENT_TYPE } from '@dotxix/models/enums/payment-type';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { TableServiceTier } from '@dotxix/table-service/models/enums/table-service-tier';
import { DotSessionEndType, DotSessionService, PosElogHandler } from 'dotsdk';
import { PosOperationsService } from '@dotxix/services/pos-operations.service';
import { SessionService } from '@dotxix/services/session.service';
import { StatusService } from '@dotxix/services/status.service';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { lastValueFrom, Subject, timer } from 'rxjs';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { OsirisFlowService } from '@dotxix/osiris/services/osiris-flow.service';
import { TableServiceRoutes } from '@dotxix/table-service/table-service-routes';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { HesetManagementService } from '@dotxix/osiris/services/heset/heset-management.service';
import { TableServiceSelectionService } from '@dotxix/table-service/pages/table-service-selection/table-service-selection.service';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { PaymentFlowService } from '@dotxix/payment/services/payment-flow.service';
import { CheckoutErrorParams } from '@dotxix/models/interfaces/checkout-error-params';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { appLogger } from '@dotxix/log-manager';
import { ReceiptFlowService } from '@dotxix/services/receipt/receipt-flow.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutFlowService {
  public cancelOrder$ = new Subject<void>();
  public displayMainMenu$ = new Subject<void>();
  constructor(
    private router: Router,
    private checkoutService: CheckoutService,
    private posOperationsService: PosOperationsService,
    private hesetManagementService: HesetManagementService,
    private sessionService: SessionService,
    private statusService: StatusService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private osirisFlowService: OsirisFlowService,
    private tableServiceSelectionService: TableServiceSelectionService,
    private paymentFlowService: PaymentFlowService,
    private paymentService: PaymentService,
    private applicationSettingsService: ApplicationSettingsService,
    private receiptFlowService: ReceiptFlowService
  ) {
    this.paymentService.state$.subscribe((state) => this.onPaymentPaidAmountChanged(state.paidAmount));
    this.osirisFlowService.checkoutFinished$.subscribe(() => this.checkoutFinished());
    this.paymentFlowService.readyToPayWithPayment$.subscribe((payment) => this.updatePosAndProceedWithPayment(payment));
    this.paymentFlowService.cancelOrder$.subscribe(async (checkoutErrorParams) => this.cancelOrderFromPayment(checkoutErrorParams));
    this.paymentFlowService.orderCompletelyPaid$.subscribe(() => this.paidWithSuccess());
    this.paymentFlowService.orderFinishedWithPreorder$.subscribe(() => this.orderFinishedWithPreorder());
    this.paymentFlowService.orderFinishedWithPayTower$.subscribe(() => this.orderFinishedWithPayTower());
  }

  public async startCheckoutTunnel() {
    appLogger.debug(`start checkout tunnel`);
    this.checkoutService.startCheckoutTunnel();

    if (this.applicationSettingsService.settings$.value.skipPrecalculate) {
      this.checkoutService.updateTotalFromBasket();
      return this.displayCodView();
    }

    if (this.checkoutService.returnedFromCheckout && this.checkoutService.sameBasket()) {
      return this.displayCodView();
    }

    if (
      this.checkoutService.returnedFromCheckout &&
      this.applicationSettingsService.settings$.value.calculateTotalMode === CalculateTotalMode.VOID_ON_RETURN
    ) {
      appLogger.debug(`voiding and getting a new refint`);
      await this.checkoutService.voidOrder();
      await DotSessionService.populateNewOrderRefint();
    }

    this.checkoutService.populateElogBeforeEndScene();
    return this.calculateTotals();
  }

  public async calculateTotals() {
    await this.router.navigate([AppRoutes.BridgeOperation]);
    const calculateTotalsResponse = await this.posOperationsService.sendCalculateTotals();
    if (
      !!calculateTotalsResponse &&
      (this.posOperationsService.isBridgeSuccessResponse(calculateTotalsResponse) ||
        this.posOperationsService.isBridgeDifferentTotalResponse(calculateTotalsResponse))
    ) {
      this.checkoutService.processCalculateTotalsResponse(calculateTotalsResponse);
      await this.displayCodView();
    } else {
      await this.cancelOrderWithCheckoutErrorScreen();
    }
  }

  public returnFromCheckout() {
    appLogger.debug(`back button on checkout clicked`);
    this.checkoutService.returnFromCheckout();
    this.displayMainMenu$.next();
  }

  public async checkAvailablePaymentsAndSelectTableService() {
    if (this.statusService.state$.value.availablePayments.length < 1) {
      qaAutomationLog('no payment method found, going to first page');
      appLogger.debug(`no payment method found, canceling order`);
      const showPopup = this.showMissingPaymentMethod();
      setTimeout(() => {
        showPopup.close();
        return this.sessionService.restartSession(DotSessionEndType.CANCEL_ORDER);
      }, 5000);
      return;
    }
    if (this.applicationSettingsService.settings$.value.skipPrecalculate) {
      this.checkoutService.populateElogBeforeEndScene();
    }
    if (this.sessionService.hasMandatoryTSS()) {
      appLogger.debug(`has mandatory table service`);
      return this.displayTableServiceSelection();
    }
    if (this.sessionService.hasTableService()) {
      return this.displayTableServiceEntry();
    }
    return this.proceedToPayment();
  }

  public displayTableServiceEntry() {
    return this.router.navigate([TableServiceRoutes.Entry]);
  }

  public displayTableServiceSelection() {
    return this.router.navigate([TableServiceRoutes.Selection]);
  }

  public async proceedToPayment() {
    appLogger.debug(`proceeding to payment`);
    if (this.checkoutService.getAmountOwed() === 0) {
      return this.completeOrderWithZeroAmount();
    }

    if (
      this.statusService.state$.value.availablePayments.length === 1 &&
      this.applicationSettingsService.settings$.value.skipSinglePaymentSelection
    ) {
      this.paymentFlowService.autoSelectedPaymentType(this.statusService.state$.value.availablePayments[0]);
      return;
    } else {
      return this.paymentFlowService.displayPaymentSelection();
    }
  }

  public async paymentSelectionCanceled() {
    appLogger.debug(`cancel button clicked on payment selection page`);
    if (this.paymentService.state$.value.paidAmount === 0) {
      this.cancelOrder$.next();
      return;
    }

    const cancelConfirmed = await this.paymentFlowService.cancelAllPaymentsWithConfirmation();
    if (cancelConfirmed) {
      await this.checkoutService.voidOrderAndRestartSession(DotSessionEndType.CANCEL_ORDER);
    }
  }

  public async openOrder(): Promise<boolean> {
    if (this.checkoutService.openedOrder) {
      return true;
    }
    await this.router.navigate([AppRoutes.BridgeOperation]);
    const openOrderResponse = await this.posOperationsService.sendOpenOrderToPOS();
    if (!!openOrderResponse && this.posOperationsService.isBridgeSuccessResponse(openOrderResponse)) {
      this.checkoutService.processOpenOrderResponse(openOrderResponse);
      return true;
    } else {
      return false;
    }
  }

  public async orderFinishedWithPreorder() {
    appLogger.debug(`finishing with preorder`);
    this.checkoutService.orderNotPaidWillPayAtCounter = true;
    PosElogHandler.getInstance().setPreorderElogCofigurantion();
    await this.receiptFlowService.deliverReceipt();
    await this.router.navigate([AppRoutes.Preorder]).catch(() => {});
    if (this.applicationSettingsService.settings$.value.unlockOrder) {
      await this.posOperationsService.sendUnlockOrderToPOS();
    }
    await lastValueFrom(timer(4000));
    this.displayOrderNumberView();
  }

  public async orderFinishedWithPayTower() {
    appLogger.debug(`finishing pay tower order`);
    await this.checkoutService.voidOrder();
    await this.sessionService.restartSession(DotSessionEndType.CANCEL_ORDER);
  }

  public async cancelOrderWithCheckoutErrorScreen(displayPaymentImage = false) {
    appLogger.debug(`order failed`);
    this.displayCheckoutError(displayPaymentImage);

    setTimeout(() => {
      this.sessionService.restartSession(DotSessionEndType.ORDER_FAIL);
    }, 5000);
  }

  public async paidWithSuccess() {
    appLogger.debug(`order completely paid`);
    return this.applicationSettingsService.settings$.value.posInjectionFlow === CheckoutType.PAY_AFTER_POS
      ? this.tenderOrder()
      : this.completeOrder();
  }

  public async tenderOrder() {
    const tableServiceNumber = PosElogHandler.getInstance().posConfig.posHeader?.cvars?.TS_No;
    await this.router.navigate([AppRoutes.BridgeOperation]);
    const tenderOrderResponse = await this.posOperationsService.sendTenderOrderToPOS();
    if (
      !!tenderOrderResponse &&
      this.posOperationsService.isBridgeSuccessResponse(tenderOrderResponse) &&
      !!tenderOrderResponse.OrderPOSNumber
    ) {
      this.checkoutService.processTenderOrderResponse(tenderOrderResponse);
      return this.finishCheckoutSuccessfully();
    } else {
      this.checkoutService.printOrderCompletelyPaidButNotIntegratedIntoPos();
      if (this.applicationSettingsService.settings$.value.unlockOrder) {
        await this.posOperationsService.sendUnlockOrderToPOS();
      }

      if (tableServiceNumber) {
        this.displayTableServiceUnavailable().then();
      } else {
        this.displayCheckoutError();
      }

      await lastValueFrom(timer(4000));
      this.displayOrderNumberView().then();
    }
  }

  public async completeOrder() {
    const tableServiceNumber = PosElogHandler.getInstance().posConfig.posHeader?.cvars?.TS_No;
    await this.router.navigate([AppRoutes.BridgeOperation]);
    const completeOrderResponse = await this.posOperationsService.sendCompleteOrderToPOS();
    if (
      !!completeOrderResponse &&
      this.posOperationsService.isBridgeSuccessResponse(completeOrderResponse) &&
      !!completeOrderResponse.OrderPOSNumber
    ) {
      this.checkoutService.processCompleteOrderResponse(completeOrderResponse);
      return this.finishCheckoutSuccessfully();
    } else {
      this.checkoutService.printOrderCompletelyPaidButNotIntegratedIntoPos();
      // this.checkoutService.processCompleteOrderResponse();
      if (tableServiceNumber) {
        this.displayTableServiceUnavailable().then();
      } else {
        this.displayCheckoutError();
      }

      await lastValueFrom(timer(4000));
      await this.sessionService.restartSession(DotSessionEndType.ORDER_FAIL);
      // this.displayOrderNumberView().then();
    }
  }

  public async displayOrderNumberView() {
    this.osirisFlowService.displayOrderNumberView().then();
  }

  public async checkoutFinished() {
    await this.sessionService.restartSession(DotSessionEndType.ORDER_SUCCESS);
  }

  private async displayCodView() {
    return this.router.navigate([AppRoutes.CodView]);
  }

  private displayCheckoutError(displayPaymentImage = false) {
    this.router.navigate([AppRoutes.CheckoutError], { state: { displayPaymentImage } });
  }

  private async finishCheckoutSuccessfully() {
    this.saveHesetOrder();
    await this.receiptFlowService.deliverReceipt();
    const tableServiceNumber = PosElogHandler.getInstance().posConfig.posHeader?.cvars?.TS_No;
    if (tableServiceNumber) {
      if (this.applicationSettingsService.settings$.value.tableServiceTier === TableServiceTier.GOLD) {
        this.tableServiceSelectionService.handleTableServiceBunjee(tableServiceNumber).then();
      }
      return this.displayTableServiceConfirmation();
    } else {
      return this.displayOrderNumberView();
    }
  }

  private async displayTableServiceConfirmation() {
    await this.router.navigate([TableServiceRoutes.Confirmation]);
    setTimeout(() => {
      this.displayOrderNumberView();
    }, 4000);
  }

  private async displayTableServiceUnavailable() {
    return this.router.navigate([TableServiceRoutes.Unavailable]);
  }

  private onPaymentPaidAmountChanged(paidAmount: number) {
    if (paidAmount > 0) {
      const preorderPaymentType = this.statusService.state$.value.availablePayments.find(
        (paymentType) => paymentType.PaymentType === PAYMENT_TYPE.PREORDER && paymentType.PaymentIsEnabled
      );
      if (preorderPaymentType) {
        this.statusService.removePaymentOptionForCurrentSession(preorderPaymentType);
      }
    }
  }

  private showMissingPaymentMethod() {
    return this.dynamicContentService.openContent(InfoDialogComponent, {
      hideFooterActions: true,
      title: this.translationsService.translate('2022110201'),
      buttonText: '',
    });
  }

  private async completeOrderWithZeroAmount() {
    this.checkoutService.updateOpenOrderTenders(this.checkoutService.getAmountOwed());
    if (false === (await this.openOrderIfRequired())) {
      return this.cancelOrderWithCheckoutErrorScreen();
    }

    return this.paidWithSuccess();
  }

  private async cancelOrderFromPayment(checkoutErrorParams: CheckoutErrorParams) {
    await this.checkoutService.voidOrder();
    this.cancelOrderWithCheckoutErrorScreen(checkoutErrorParams.showCardErrorImage);
  }

  private async updatePosAndProceedWithPayment(paymentType: PaymentType) {
    if (false === (await this.openOrderIfRequired(paymentType.PaymentType))) {
      return this.cancelOrderWithCheckoutErrorScreen();
    }
    this.paymentFlowService.startPayment(paymentType);
  }

  private async openOrderIfRequired(paymentType?: PAYMENT_TYPE) {
    if (paymentType === PAYMENT_TYPE.PAY_TOWER) {
      return true;
    }
    if (
      this.applicationSettingsService.settings$.value.posInjectionFlow === CheckoutType.PAY_AFTER_POS ||
      (this.applicationSettingsService.settings$.value.posInjectionFlow === CheckoutType.PAY_BEFORE_POS_PREORDER &&
        paymentType === PAYMENT_TYPE.PREORDER)
    ) {
      return await this.openOrder();
    }
    return true;
  }

  private saveHesetOrder() {
    this.hesetManagementService.submitOrder(this.checkoutService.orderPOSNumber?.toString() ?? '');
  }
}
