import { Component } from '@angular/core';
import { OsirisFlowService } from '@dotxix/osiris/services/osiris-flow.service';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';

@Component({
  selector: 'acr-osiris-order-review',
  templateUrl: './osiris-order-review.component.html',
})
export class OsirisOrderReviewComponent {
  public reviewOrder$ = this.osirisStateService.reviewOrder$;

  constructor(
    private osirisFlowService: OsirisFlowService,
    private osirisStateService: OsirisStateService
  ) {}

  public goBack() {
    this.osirisFlowService.reviewOrderCanceled();
  }

  public addToBasket() {
    this.osirisFlowService.reviewOrderAccepted();
  }
}
