import { Injectable } from '@angular/core';
import { DotButton, Mockable, MocksOptions, PosElogHandler, UseMocks } from 'dotsdk';
import { environment } from '../../../environments/environment';
import { OsirisGetLastOrdersMockOverrider } from '@dotxix/osiris/mocks/json-overriders/osiris-get-last-orders-mock-overrider';
import { OsirisRecallOrdersMockOverrider } from '@dotxix/osiris/mocks/json-overriders/osiris-recall-order-mock-overrider';
import { OsirisStoreOrdersMockOverrider } from '@dotxix/osiris/mocks/json-overriders/osiris-store-orders-mock-overrider';
import { GetLastOrdersOptions } from '@dotxix/osiris/models/interfaces/get-last-orders-options.interface';
import { GetLastOrdersResponseInterface } from '@dotxix/osiris/models/interfaces/get-last-orders-response.interface';
import { RecallOrderOptions } from '@dotxix/osiris/models/interfaces/recall-order-options.interface';
import { RecallOrderResponse } from '@dotxix/osiris/models/interfaces/recall-order-response.interface';
import { StoreOrdersOptions } from '@dotxix/osiris/models/interfaces/store-orders-options.interface';
import { OsirisHttpService } from '@dotxix/osiris/services/osiris-http.service';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';
import { extractOrderFromOsiris } from '@dotxix/osiris/helpers/osiris-basket.helper';
import { OsirisStoreOrdersPayload } from '@dotxix/osiris/models/interfaces/store-orders-payload.interface';
import { getMetadata, getPayload } from '@dotxix/osiris/helpers/metadata-payload.helper';
import { BasketService } from '@dotxix/services/basket.service';
import { ParentOrderIdService } from './parent-order-id.service';
import { osirisLogger } from '@dotxix/log-manager';

@Injectable({
  providedIn: 'root',
})
export class OsirisService implements Mockable {
  public mocksOptions: MocksOptions = {
    useMocks: environment.useMocksForOsiris,
    mocksFolder: environment.osirisMocksPath,
  };

  constructor(
    private osirisHttpService: OsirisHttpService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private basketService: BasketService,
    private parentOrderIdService: ParentOrderIdService
  ) {}

  @UseMocks(OsirisGetLastOrdersMockOverrider)
  public getLastOrders(options: GetLastOrdersOptions): Promise<GetLastOrdersResponseInterface> {
    return this.osirisHttpService.getLastOrders(options);
  }

  @UseMocks(OsirisStoreOrdersMockOverrider)
  public storeOrders(options: StoreOrdersOptions): Promise<unknown> {
    return this.osirisHttpService.storeOrders(options);
  }

  @UseMocks(OsirisRecallOrdersMockOverrider)
  public recallOrder(options: RecallOrderOptions): Promise<RecallOrderResponse> {
    return this.osirisHttpService.recallOrder(options);
  }

  public async recallOrderAsBasket(order: OsirisOrder): Promise<DotButton[]> {
    const orderID = order.i;
    const recalledOrder = await this.recallOrder({ orderID });
    return extractOrderFromOsiris(recalledOrder.KioskOrder, orderID);
  }

  public async getCustomerLastOrders(customerID: string): Promise<GetLastOrdersResponseInterface | null> {
    const loadingPopup = this.dynamicContentService.openContent(InfoDialogComponent, {
      title: this.translationsService.translate('20220420001'),
      buttonText: '',
      hideFooterActions: true,
    });

    const response = await this.getLastOrders({ customerID }).catch(() => {
      loadingPopup.close();
      this.loadPopup('20220420003');
      return null;
    });
    loadingPopup.close();
    return response;
  }

  public storeAnonymousOrder() {
    const orderId = PosElogHandler.getInstance().posConfig.posHeader.AUID;
    if (orderId) {
      this.storeOrders({ StoreOrdersPayload: this.makeStoredOrder(orderId, null) }).catch((error) => {
        osirisLogger.error(`[storeOrders]: request with ${error}`);
      });
    }
  }

  public storeCustomerOrder(customerId: string) {
    return new Promise((resolve, reject) => {
      const orderId = PosElogHandler.getInstance().posConfig.posHeader.AUID;
      if (orderId) {
        this.storeOrders({
          StoreOrdersPayload: this.makeStoredOrder(orderId, customerId),
        })
          .then(() => resolve(true))
          .catch(() => reject());
      } else {
        reject();
      }
    });
  }

  private makeStoredOrder(orderId: string, customerID: string | null): OsirisStoreOrdersPayload {
    const parentOrderId = this.parentOrderIdService.getParentOrderId();

    return {
      MetaData: getMetadata(orderId, parentOrderId, customerID, this.basketService.buttons),
      Payload: getPayload(this.basketService.buttons),
    };
  }

  private loadPopup(messageId: string) {
    const dynamicPopup = this.dynamicContentService.openContent(InfoDialogComponent, {
      title: this.translationsService.translate(messageId),
      buttonText: '',
      hideFooterActions: true,
    });
    setTimeout(() => {
      dynamicPopup.close();
    }, 3000);
  }
}
