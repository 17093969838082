import { AtpApplicationSettings, AtpEnvironmentService, IEnvironmentAbout, PeripheralCheckMode, PosServingLocation } from 'dotsdk';

import { Injectable } from '@angular/core';

import { CalculateTotalMode } from '@dotxix/models/enums/calculate-total-mode';
import { CheckoutType } from '@dotxix/models/enums/checkout-type';
import { HomeButtonDisplayType } from '@dotxix/models/enums/home-button-display-type';
import { MandatoryTableServiceSelection } from '@dotxix/table-service/models/enums/mandatory-table-service-selection';
import { PaymentFailType } from '@dotxix/models/enums/payment-fail-type';
import { NutritionalInformationDisplayType } from '@dotxix/models/enums/nutritional-information-display-type';
import { PromoDiscountsMode } from '@dotxix/models/enums/promos';
import { PromoInputOption } from '@dotxix/models/enums/promo-input-option';
import { RoundingStrategies } from '@dotxix/models/enums/rounding-strategies';
import { TableServiceType } from '@dotxix/table-service/models/enums/table-service-type';
import { TableServiceTier } from '@dotxix/table-service/models/enums/table-service-tier';
import { OrderCheckInFlow } from '@dotxix/models/enums/order-check-in-flow';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from '@dotxix/models/interfaces/app-settings';
import { appSettingsDefaultValues as defaults } from '@dotxix/services/app-settings-defaults';
import {
  booleanOrDefault,
  jsonOrDefault,
  numberOrDefault,
  parseGloryPayableAmount,
  stringOrDefault,
} from '@dotxix/helpers/app-settings.helper';
import { DemoMode } from '@dotxix/models/enums/demo-mode';
import { appLogger } from '@dotxix/log-manager';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsService {
  public settings$ = new BehaviorSubject<AppSettings>(defaults);

  constructor() {
    AtpEnvironmentService.getInstance()
      .onBundleSettingsChanged()
      .subscribe(() => this.updateFromBundleSettings());
  }

  public initialize() {
    this.updateFromBundleSettings();
  }

  public updateFromBundleSettings() {
    appLogger.debug(`computing bundle settings: ${JSON.stringify(AtpApplicationSettings.getInstance().bundleSettingsJson)}`);
    const bsJson = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const sharedFolderPath = stringOrDefault(bsJson.sharedFolderPath, defaults.sharedFolderPath);
    const bridgeAssetsPath = `${sharedFolderPath}/assets`;
    const bannersPath = `${bridgeAssetsPath}/Banners`;
    const skipPrecalculate = booleanOrDefault(bsJson.skipPrecalculate, defaults.skipPrecalculate);
    const posInjectionFlow = stringOrDefault(bsJson.posInjectionFlow, defaults.posInjectionFlow) as CheckoutType;
    const maxPaymentRetries = numberOrDefault(bsJson.posInjectionFlow, defaults.maxPaymentRetries);
    const paymentFailRedirect =
      posInjectionFlow === CheckoutType.PAY_BEFORE_POS
        ? PaymentFailType.PAY_RETRY
        : (stringOrDefault(bsJson.paymentFailRedirect, defaults.paymentFailRedirect) as PaymentFailType);
    const defaultLanguage = stringOrDefault(bsJson.defaultLanguage, defaults.defaultLanguage);
    const languages = jsonOrDefault(bsJson.languages, defaults.languages, `'languages' BS`);
    const currencySymbol = stringOrDefault(bsJson.currencySymbol, defaults.currencySymbol);
    const currencySymbolBefore = booleanOrDefault(bsJson.currencySymbolBefore, defaults.currencySymbolBefore);
    const currencyDecimals = numberOrDefault(bsJson.currencyDecimals, defaults.currencyDecimals);
    const decimalSeparator = stringOrDefault(bsJson.decimalSeparator, defaults.decimalSeparator);
    const posInjectorPathTest = `${stringOrDefault(bsJson.posInjectorPath, defaults.posInjectorPath)}/pos/testconnect`;
    const posInjectorPath = `${stringOrDefault(bsJson.posInjectorPath, defaults.posInjectorPath)}/pos/transaction`;
    const reverseBasketOrder = booleanOrDefault(bsJson.reverseBasketOrder, defaults.reverseBasketOrder);
    const orderCheckInFlow = stringOrDefault(bsJson.orderCheckInFlow, defaults.orderCheckInFlow) as OrderCheckInFlow;
    const isServiceTypeStartScreen = orderCheckInFlow === OrderCheckInFlow.ONLY_SERVICE_TYPE;
    const serviceType = stringOrDefault(bsJson.serviceType, defaults.serviceType) as PosServingLocation;
    const enableTouchToOrderSection = booleanOrDefault(bsJson.enableTouchToOrderSection, defaults.enableTouchToOrderSection);
    const peripheralsCheckTimer = numberOrDefault(bsJson.peripheralsCheckTimer, defaults.peripheralsCheckTimer);
    const paymentCheckMode = stringOrDefault(bsJson.paymentCheckMode, defaults.paymentCheckMode) as PeripheralCheckMode;
    const printerCheckMode = stringOrDefault(bsJson.printerCheckMode, defaults.printerCheckMode) as PeripheralCheckMode;
    const scannerCheckMode = stringOrDefault(bsJson.scannerCheckMode, defaults.scannerCheckMode) as PeripheralCheckMode;
    const POSCheckMode = stringOrDefault(bsJson.POSCheckMode, defaults.POSCheckMode) as PeripheralCheckMode;
    const ignoreGloryDispensableEmptyStatus = booleanOrDefault(
      bsJson.ignoreGloryDispensableEmptyStatus,
      defaults.ignoreGloryDispensableEmptyStatus
    );
    const kioskId = numberOrDefault(bsJson.kioskId, defaults.kioskId);
    const paymentTypes = jsonOrDefault(bsJson.paymentTypes, defaults.paymentTypes, `'paymentTypes' BS`);
    const payTowerOrderApiUrl = stringOrDefault(bsJson.payTowerOrderApiUrl, defaults.payTowerOrderApiUrl);
    const payTowerOrderApiAuthKeys = jsonOrDefault(
      bsJson.payTowerOrderApiAuthKeys,
      defaults.payTowerOrderApiAuthKeys,
      `'payTowerOrderApiAuthKeys' BS`
    );
    const isPayTower = booleanOrDefault(bsJson.isPayTower, defaults.isPayTower);
    const payTowerTimeout = numberOrDefault(bsJson.payTowerTimeout, defaults.payTowerTimeout);
    const productRemovalWarning = booleanOrDefault(bsJson.productRemovalWarning, defaults.productRemovalWarning);
    const quantityCalculated = booleanOrDefault(bsJson.quantityCalculated, defaults.quantityCalculated);
    const homeButtonDisplay = stringOrDefault(bsJson.homeButtonDisplay, defaults.homeButtonDisplay) as HomeButtonDisplayType;
    const viewBasketAfterProductSelection = booleanOrDefault(
      bsJson.viewBasketAfterProductSelection,
      defaults.viewBasketAfterProductSelection
    );
    const inactivityWarningTimer = numberOrDefault(bsJson.inactivityWarningTimer, defaults.inactivityWarningTimer);
    const inactivityCloseTimer = numberOrDefault(bsJson.inactivityCloseTimer, defaults.inactivityCloseTimer);
    const calculateTotalMode = stringOrDefault(bsJson.calculateTotalMode, defaults.calculateTotalMode) as CalculateTotalMode;
    const skipSinglePaymentSelection = booleanOrDefault(bsJson.skipSinglePaymentSelection, defaults.skipSinglePaymentSelection);
    const enableComboAnimation = booleanOrDefault(bsJson.enableComboAnimation, defaults.enableComboAnimation);
    const nutritionalInformationDisplayType = stringOrDefault(
      bsJson.nutritionalInformationDisplayType,
      defaults.nutritionalInformationDisplayType
    ) as NutritionalInformationDisplayType;

    const printStoreDetails = booleanOrDefault(bsJson.printStoreDetails, defaults.printStoreDetails);
    const storeName = stringOrDefault(bsJson.storeName, defaults.storeName);
    const storeCode = stringOrDefault(bsJson.storeCode, defaults.storeCode);
    const storeAddress1 = stringOrDefault(bsJson.storeAddress1, defaults.storeAddress1);
    const storeAddress2 = stringOrDefault(bsJson.storeAddress2, defaults.storeAddress2);
    const storeTelephone = stringOrDefault(bsJson.storeTelephone, defaults.storeTelephone);
    const printerMaxCharsPerRow = numberOrDefault(bsJson.printerMaxCharsPerRow, defaults.printerMaxCharsPerRow);
    const printPOSReceipt = booleanOrDefault(bsJson.printPOSReceipt, defaults.printPOSReceipt);
    const paymentRetryCounter = numberOrDefault(bsJson.paymentRetryCounter, defaults.paymentRetryCounter);
    const ReceiptSmsEnabled = booleanOrDefault(bsJson.ReceiptSmsEnabled, defaults.ReceiptSmsEnabled);
    const ReceiptEmailEnabled = booleanOrDefault(bsJson.ReceiptEmailEnabled, defaults.ReceiptEmailEnabled);
    const ReceiptEmailFrom = stringOrDefault(bsJson.ReceiptEmailFrom, defaults.ReceiptEmailFrom);
    const ReceiptElectronicEnabled = booleanOrDefault(bsJson.ReceiptElectronicEnabled, defaults.ReceiptElectronicEnabled);
    const receiptEFTPartialCut = booleanOrDefault(bsJson.receiptEFTPartialCut, defaults.receiptEFTPartialCut);
    const promoInputOption =
      !bsJson.promoInputOption ||
      (bsJson.promoInputOption !== PromoInputOption.KEYBOARD &&
        bsJson.promoInputOption !== PromoInputOption.SCANNER &&
        bsJson.promoInputOption !== PromoInputOption.NONE)
        ? PromoInputOption.BOTH
        : bsJson.promoInputOption;
    const gloryPayableAmount = parseGloryPayableAmount(bsJson.gloryPayableAmount, defaults.gloryPayableAmount);
    const enableOMSModule = booleanOrDefault(bsJson.enableOMSModule, defaults.enableOMSModule);
    const enableOMSRecall = booleanOrDefault(bsJson.enableOMSRecall, defaults.enableOMSRecall);
    const OMSSendForPreOrder = booleanOrDefault(bsJson.OMSSendForPreOrder, defaults.OMSSendForPreOrder);

    const promoDiscountsMode = stringOrDefault(bsJson.promoDiscountsMode, defaults.promoDiscountsMode) as PromoDiscountsMode;
    const roundingStrategy = numberOrDefault(bsJson.roundingStrategy, defaults.roundingStrategy) as RoundingStrategies;
    const unlockOrder = booleanOrDefault(bsJson.unlockOrder, defaults.unlockOrder);

    const tableServiceMode = stringOrDefault(bsJson.tableServiceMode, defaults.tableServiceMode) as TableServiceType;
    const mandatoryTSS = stringOrDefault(bsJson.mandatoryTSS, defaults.mandatoryTSS) as MandatoryTableServiceSelection;
    const tableServiceItem = stringOrDefault(bsJson.tableServiceItem, defaults.tableServiceItem);
    const tableServiceNumberPrefix = numberOrDefault(bsJson.tableServiceNumberPrefix, defaults.tableServiceNumberPrefix);
    const tableServiceTier = stringOrDefault(bsJson.tableServiceTier, defaults.tableServiceTier) as TableServiceTier;
    const bunjeeAddress = stringOrDefault(bsJson.bunjeeAddress, defaults.bunjeeAddress);

    const sendModifiersWithZeroQuantity = booleanOrDefault(bsJson.sendModifiersWithZeroQuantity, defaults.sendModifiersWithZeroQuantity);
    const reloadAfterCompletedOrders = numberOrDefault(bsJson.reloadAfterCompletedOrders, defaults.reloadAfterCompletedOrders);
    const touchlessMode = booleanOrDefault(bsJson.touchlessMode, defaults.touchlessMode);
    const SubtotalIncludesVAT = numberOrDefault(bsJson.SubtotalIncludesVAT, defaults.SubtotalIncludesVAT);
    const infiniteNavbarScroll = booleanOrDefault(bsJson.infiniteNavbarScroll, defaults.infiniteNavbarScroll);
    const orderRecallMode = stringOrDefault(bsJson.orderRecallMode, defaults.orderRecallMode);
    const availableOsirisLoginMethods = jsonOrDefault(
      bsJson.availableOsirisLoginMethods,
      defaults.availableOsirisLoginMethods,
      `'availableOsirisLoginMethods' BS`
    );
    const osirisBaseUrl = stringOrDefault(bsJson.osirisBaseUrl, defaults.osirisBaseUrl);
    const osirisAuthKeys = jsonOrDefault(bsJson.osirisAuthKeys, defaults.osirisAuthKeys, `'osirisAuthKeys' BS`);
    const faceRecallBaseUrl = stringOrDefault(bsJson.faceRecallBaseUrl, defaults.faceRecallBaseUrl);
    const faceRecallAuthToken = stringOrDefault(bsJson.faceRecallAuthToken, defaults.faceRecallAuthToken);
    const faceRecallRegisterUrl = stringOrDefault(bsJson.faceRecallRegisterUrl, defaults.faceRecallRegisterUrl);
    const osirisOrdersCount = numberOrDefault(bsJson.osirisOrdersCount, defaults.osirisOrdersCount);
    const osirisFaceIdShowFullErrorMessages = booleanOrDefault(
      bsJson.osirisFaceIdShowFullErrorMessages,
      defaults.osirisFaceIdShowFullErrorMessages
    );
    const addFailedOrdersIntoOsiris = booleanOrDefault(bsJson.addFailedOrdersIntoOsiris, defaults.addFailedOrdersIntoOsiris);
    const hesetMobileBaseUrl = stringOrDefault(bsJson.hesetMobileBaseUrl, defaults.hesetMobileBaseUrl);
    const hesetWebSocketUrl = stringOrDefault(bsJson.hesetWebSocketUrl, defaults.hesetWebSocketUrl);
    const hesetApiUrl = stringOrDefault(bsJson.hesetApiUrl, defaults.hesetApiUrl);
    const showInfoButton = booleanOrDefault(bsJson.showInfoButton, defaults.showInfoButton);
    const enableRecoModule = booleanOrDefault(bsJson.enableRecoModule, defaults.enableRecoModule);

    const envDetails: IEnvironmentAbout | null = AtpApplicationSettings.getInstance().environmentDetailsJson;
    const isBetaDevice = envDetails ? envDetails.DeviceState === 'sandbox' : true;
    const demoMode = isBetaDevice ? stringOrDefault(bsJson.demoMode, defaults.demoMode) : DemoMode.DISABLED;

    const demoDelay = numberOrDefault(bsJson.demoDelay, defaults.demoDelay);
    const smallTicket = booleanOrDefault(bsJson.smallTicket, defaults.smallTicket);
    const allowNoCCReceipt = booleanOrDefault(bsJson.allowNoCCReceipt, defaults.allowNoCCReceipt);
    const timeoutTicketQuestion = numberOrDefault(bsJson.timeoutTicketQuestion, defaults.timeoutTicketQuestion);
    const addPaymentDetailsForSmallReceipt = booleanOrDefault(
      bsJson.addPaymentDetailsForSmallReceipt,
      defaults.addPaymentDetailsForSmallReceipt
    );
    const addDefaultModifiersToElog = booleanOrDefault(bsJson.addDefaultModifiersToElog, defaults.addDefaultModifiersToElog);
    const tentNumberMinimumValue = numberOrDefault(bsJson.tentNumberMinimumValue, defaults.tentNumberMinimumValue);
    const tentNumberMaximumValue = numberOrDefault(bsJson.tentNumberMaximumValue, defaults.tentNumberMaximumValue);
    const tentNumberFormatLength = numberOrDefault(bsJson.tentNumberFormatLength, defaults.tentNumberFormatLength);
    const replaceTextOnReceipt = jsonOrDefault(bsJson.replaceTextOnReceipt, defaults.replaceTextOnReceipt, `'replaceTextOnReceipt' BS`);
    const receiptHeaderText = stringOrDefault(bsJson.receiptHeaderText, defaults.receiptHeaderText);
    const minOrderAmount = numberOrDefault(bsJson.minOrderAmount, defaults.minOrderAmount);
    const enableBIAnalyticsModule = booleanOrDefault(bsJson.enableBIAnalyticsModule, defaults.enableBIAnalyticsModule);
    const biAnalyticsUrl = stringOrDefault(bsJson.biAnalyticsUrl, defaults.biAnalyticsUrl);
    const biAnalyticsAuthToken = stringOrDefault(bsJson.biAnalyticsAuthToken, defaults.biAnalyticsAuthToken);
    const navBarOrientation = stringOrDefault(bsJson.navBarOrientation, defaults.navBarOrientation);

    this.settings$.next({
      sharedFolderPath,
      bridgeAssetsPath,
      bannersPath,
      skipPrecalculate,
      posInjectionFlow,
      maxPaymentRetries,
      paymentFailRedirect,
      defaultLanguage,
      languages,
      currencySymbol,
      currencySymbolBefore,
      currencyDecimals,
      decimalSeparator,
      posInjectorPathTest,
      posInjectorPath,
      reverseBasketOrder,
      orderCheckInFlow,
      isServiceTypeStartScreen,
      serviceType,
      enableTouchToOrderSection,
      peripheralsCheckTimer,
      paymentCheckMode,
      printerCheckMode,
      scannerCheckMode,
      POSCheckMode,
      ignoreGloryDispensableEmptyStatus,
      kioskId,
      paymentTypes,
      payTowerOrderApiUrl,
      payTowerOrderApiAuthKeys,
      isPayTower,
      payTowerTimeout,
      productRemovalWarning,
      quantityCalculated,
      homeButtonDisplay,
      viewBasketAfterProductSelection,
      inactivityWarningTimer,
      inactivityCloseTimer,
      calculateTotalMode,
      skipSinglePaymentSelection,
      enableComboAnimation,
      nutritionalInformationDisplayType,
      tableServiceMode,
      mandatoryTSS,
      tableServiceItem,
      tableServiceNumberPrefix,
      tableServiceTier,
      bunjeeAddress,
      printStoreDetails,
      storeName,
      storeCode,
      storeAddress1,
      storeAddress2,
      storeTelephone,
      printerMaxCharsPerRow,
      printPOSReceipt,
      paymentRetryCounter,
      receiptEFTPartialCut,
      ReceiptSmsEnabled,
      ReceiptElectronicEnabled,
      ReceiptEmailEnabled,
      ReceiptEmailFrom,
      promoInputOption,
      gloryPayableAmount,
      enableOMSModule,
      enableOMSRecall,
      OMSSendForPreOrder,
      promoDiscountsMode,
      roundingStrategy,
      unlockOrder,
      sendModifiersWithZeroQuantity,
      reloadAfterCompletedOrders,
      touchlessMode,
      SubtotalIncludesVAT,
      infiniteNavbarScroll,
      orderRecallMode,
      availableOsirisLoginMethods,
      osirisBaseUrl,
      osirisAuthKeys,
      faceRecallBaseUrl,
      faceRecallAuthToken,
      faceRecallRegisterUrl,
      osirisOrdersCount,
      osirisFaceIdShowFullErrorMessages,
      addFailedOrdersIntoOsiris,
      hesetMobileBaseUrl,
      hesetWebSocketUrl,
      hesetApiUrl,
      showInfoButton,
      enableRecoModule,
      demoMode,
      demoDelay,
      smallTicket,
      allowNoCCReceipt,
      timeoutTicketQuestion,
      addPaymentDetailsForSmallReceipt,
      addDefaultModifiersToElog,
      tentNumberMinimumValue,
      tentNumberMaximumValue,
      tentNumberFormatLength,
      replaceTextOnReceipt,
      receiptHeaderText,
      minOrderAmount,
      enableBIAnalyticsModule,
      biAnalyticsUrl,
      biAnalyticsAuthToken,
      navBarOrientation,
    });
    appLogger.debug(`using application settings: ${JSON.stringify(this.settings$.value)}`);
  }
}
