import { DotSessionEndType, RecoAbortMethodEnum, RecoOrderState, RecoPaymentMethod } from 'dotsdk';
import { PAYMENT_TYPE } from '@dotxix/models/enums/payment-type';

export const mapDotSessionEndTypeToRecoOrderState = (state: DotSessionEndType): RecoOrderState | undefined => {
  if (state === DotSessionEndType.CANCEL_ORDER || state === DotSessionEndType.APP_TIMEOUT) {
    return RecoOrderState.CANCELLED;
  } else if (state === DotSessionEndType.ORDER_FAIL) {
    return RecoOrderState.PAYMENT_FAILED;
  } else if (state === DotSessionEndType.ORDER_SUCCESS) {
    return RecoOrderState.PAID;
  }
  return undefined;
};

export const mapPaymentTypeToRecoPaymentMethod = (paymentType: PAYMENT_TYPE) => {
  if (paymentType === PAYMENT_TYPE.CARD) {
    return RecoPaymentMethod.CARD;
  } else if (paymentType === PAYMENT_TYPE.CASH) {
    return RecoPaymentMethod.CASH;
  } else {
    return RecoPaymentMethod.CUSTOM;
  }
};

export const mapRecoOrderAbortMethod = (state: DotSessionEndType): RecoAbortMethodEnum | undefined => {
  if (state === DotSessionEndType.APP_TIMEOUT) {
    return RecoAbortMethodEnum.TIMEOUT;
  }
  if (state === DotSessionEndType.CANCEL_ORDER) {
    return RecoAbortMethodEnum.MANUAL;
  }
  return undefined;
};
