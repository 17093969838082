<main class="page-container cod-view">
  <header class="flex-center text-center cod-view__header">
    <h4 class="color--white cod-view__title">{{ '7' | translate }}</h4>
  </header>

  <acr-scrollable-container>
    <acr-basket-product-list [allowModifyProduct]="allowModifyProducts"></acr-basket-product-list>
  </acr-scrollable-container>

  <section class="flex-center justify-center flex--column cod-view__total" data-automation="Cod View Total">
    <div class="flex full-width justify-between" *ngIf="hasOrderDiscount">
      <h4 class="color--white">{{ '20210504004' | translate }}</h4>
      <h4 class="price-holder">
        <span class="color--white" data-automation="Order Discount">{{ orderDiscount | dotCurrency: true }}</span>
      </h4>
    </div>
    <div class="flex full-width justify-between">
      <h3 class="color--white">{{ '63' | translate }}</h3>
      <h3 class="price-holder">
        <span class="color--white" data-automation="Total Price">{{ totalPrice | dotCurrency: true }}</span>
      </h3>
    </div>
  </section>

  <acr-footer>
    <acr-go-back left-slot *ngIf="!isPayTower"></acr-go-back>
    <acr-start-over left-slot *ngIf="isPayTower"></acr-start-over>
    <acr-pay-order right-slot [isDisabled]="(checkoutButtonDisabled$ | async)!"></acr-pay-order>
  </acr-footer>
</main>
