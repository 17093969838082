import { Injectable } from '@angular/core';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { PaymentRoutes } from '@dotxix/payment/payment-routes';
import { ApgPaymentService } from './apg-payment.service';
import { paymentLogger } from '../../log-manager';
import { CheckoutService } from '../../services/checkout.service';

@Injectable({ providedIn: 'root' })
export class ApgPaymentFlowService {
  public apgWithSuccess$ = new Subject<void>();
  public displayPaymentSelection$ = new Subject<void>();

  constructor(
    private router: Router,
    private apgPaymentService: ApgPaymentService,
    private paymentService: PaymentService,
    private checkoutService: CheckoutService
  ) {}

  public async startPayment(paymentType: PaymentType) {
    await this.router.navigate([PaymentRoutes.APG]);

    paymentLogger.debug(`Starting APG pay...`);

    const amountOwed = this.checkoutService.getAmountOwed();

    const payResult = await this.apgPaymentService.pay(amountOwed, paymentType);

    if (payResult && payResult.PaidAmount) {
      this.paymentService.onApgTransactionComplete(amountOwed, payResult, paymentType);
      this.apgWithSuccess$.next();
    } else {
      this.displayPaymentSelection$.next();
    }
  }
}
