import { Injectable } from '@angular/core';
import { FaceRecallHttpService } from '@dotxix/osiris/services/face-recall-http.service';
import { OsirisOrderStateService } from '@dotxix/osiris/services/osiris-orders-state.service';
import { PosElogHandler, PosPaidState, PosTenderType } from 'dotsdk';
import { BehaviorSubject, map } from 'rxjs';
import { CheckoutFlowService } from './checkout-flow.service';
import { CheckoutService } from './checkout.service';
import { PaymentFlowService } from '@dotxix/payment/services/payment-flow.service';
import { popIdLogger } from '@dotxix/log-manager';

export enum FacePayProgressMessages {
  Error = 'error',
  Waiting = 'waiting',
}

const FacePayProgressErrorTimeout = 3000;

@Injectable({ providedIn: 'root' })
export class FacePayProgressStateService {
  public message$ = new BehaviorSubject<FacePayProgressMessages>(FacePayProgressMessages.Waiting);
  public getMessage$ = this.message$.pipe(map((state) => state));
  constructor(
    private faceRecallHttpService: FaceRecallHttpService,
    private osirisOrderStateService: OsirisOrderStateService,
    private checkoutFlowService: CheckoutFlowService,
    private checkoutService: CheckoutService,
    private paymentFlowService: PaymentFlowService
  ) {}

  public payWithFace(
    { customerID } = this.osirisOrderStateService.getCustomerIdentification() || { customerID: null },
    amount = this.checkoutService.getAmountOwed(),
    tip = 0
  ) {
    if (!customerID) {
      this.message$.next(FacePayProgressMessages.Error);
      return this.goToPaymentSelectionWithDelay();
    }
    this.faceRecallHttpService
      .payWithFace(customerID, amount, tip)
      .then(() => {
        this.addElogTender();
        this.checkoutFlowService.paidWithSuccess().then(() => {});
      })
      .catch((error) => {
        popIdLogger.error(`${error}`);
        this.message$.next(FacePayProgressMessages.Error);
        this.goToPaymentSelectionWithDelay();
      });
  }

  private goToPaymentSelectionWithDelay() {
    setTimeout(async () => {
      await this.paymentFlowService.displayPaymentSelection();
    }, FacePayProgressErrorTimeout);
  }

  protected addElogTender(tenderMediaId = '-1') {
    const cardTender = {
      paid: PosPaidState.PAID,
      type: PosTenderType.ONLINE,
      paymentMediaId: tenderMediaId,
      paidAmount: this.checkoutService.getAmountOwed(),
    };
    const posHeaderAmounts = PosElogHandler.getInstance().posConfig.posHeader.amounts;
    if (posHeaderAmounts) {
      if (posHeaderAmounts.tenders) {
        posHeaderAmounts.tenders.push(cardTender);
      } else {
        posHeaderAmounts.tenders = [cardTender];
      }
    }
  }
}
