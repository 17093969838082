<div *ngIf="showEditElementOverlay$ | async" [@enter] [@leave] class="edit-overlay-container">
  <acr-edit-element-overlay *ngIf="showEditElementOverlay$ | async"></acr-edit-element-overlay>
</div>

<div [@enter] [@leave] *ngIf="editorOpenClose$ | async" class="container">
  <div>
    <acr-upload-file></acr-upload-file>
  </div>
  <h1 class="title">General Branding Colors</h1>
  <div class="variables-list">
    <acr-variable-groups></acr-variable-groups>
  </div>
  <div class="container-reset-button">
    <acr-reset-button></acr-reset-button>
  </div>
</div>

<div class="toggle-button-container">
  <acr-toggle-button></acr-toggle-button>
  <div class="theme-save-button-container" [@pulse] *ngIf="showSaveButton$ | async">
    <acr-save-button></acr-save-button>
  </div>
  <div class="toast-container">
    <div [@enter] [@leave] *ngIf="cssVariablesupdatesWereMade$ | async" class="toast">
      Changes have been saved locally. When finished, click save button to save changes to file
    </div>
    <div [@enter] [@leave] *ngIf="changesSent$ | async" class="toast">
      {{ changesSent$ | async }}
    </div>
  </div>
</div>
