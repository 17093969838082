import { DotButton } from 'dotsdk';
import { initializeProductModifiersQuantities } from '@dotxix/helpers/modifiers.helper';

export const initializeComboModifiersQuantities = (button: DotButton) => {
  if (button.hasCombos) {
    button.ComboPage.Combos.forEach((combo) => {
      combo.Buttons.forEach((comboComponent) => initializeProductModifiersQuantities(comboComponent));
    });
  }
};
