import { Injectable } from '@angular/core';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { getCatalogButton, getCombosCatalogButton } from 'dotsdk';

@Injectable({
  providedIn: 'root',
})
export class TitleCatalogService {
  constructor(protected translationsService: TranslationsService) {}

  public transform(link: string): string | null {
    let catalogButton = getCatalogButton(link);
    if (catalogButton) {
      return this.translationsService.getTranslatedButtonCaption(catalogButton);
    }
    catalogButton = getCombosCatalogButton(link);
    if (catalogButton) {
      return this.translationsService.getTranslatedButtonCaption(catalogButton);
    }
    return null;
  }
}
