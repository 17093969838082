import { FaceRecallHTTPCodeReceived } from '@dotxix/osiris/models/enums/http-code-received.enum';
import { DotSdkMocksManager, IJsonMocksOverride, MockPromptEffects, TAB_TYPES } from 'dotsdk';

export class GetLastPreview implements IJsonMocksOverride {
  private uiPanel!: HTMLElement;
  private dotSdkMockManager = DotSdkMocksManager.getInstance();

  public processInputArgs(mockJson: { HTTPCode: FaceRecallHTTPCodeReceived; Error: string; PreviewBase64: string }): Promise<string> {
    return new Promise((resolve, reject) => {
      const btnSuccess = document.createElement('button');
      const btnFail = document.createElement('button');

      btnFail.innerText = 'Error';
      btnSuccess.innerText = 'Success';

      this.uiPanel = this.dotSdkMockManager.addMockPrompt({
        title: `Get Last preview`,
        interactiveElements: [
          { htmlElement: btnSuccess, clickHandler: () => this.successClick(resolve, mockJson) },
          { htmlElement: btnFail, clickHandler: () => this.failClick(reject, mockJson) },
        ],
        canRememberChoice: true,
        uniqueId: this.dotSdkMockManager.sanitizePersistedMockReplayKey(`get-last-preview`),
        hostTabType: TAB_TYPES.PROMISED_MOCKS,
        afterAppendEffects: [MockPromptEffects.OPEN_DRAWER_TAB_AND_FOCUS_MOCK_PROMPT],
        afterCTAEffects: [MockPromptEffects.REMOVE_PANEL_AND_CLOSE_DRAWER_IF_HOST_TAB_EMPTY],
      });
    });
  }
  private successClick(
    resolve: (value: string | PromiseLike<string>) => void,
    mockJson: { HTTPCode: FaceRecallHTTPCodeReceived; Error: string; PreviewBase64: string }
  ) {
    resolve(`data:image/jpg;base64,${mockJson.PreviewBase64}`);
  }
  private failClick(
    reject: (reason?: unknown) => void,
    mockJson: { HTTPCode: FaceRecallHTTPCodeReceived; Error: string; PreviewBase64: string }
  ) {
    reject(mockJson.Error);
  }
}
