<language-change-view-trigger></language-change-view-trigger>
<div class="status" [class.status--hasBackground]="(messages$ | async)!.length > 0">
  <div class="status__messages eventsNone">
    <p class="status__messages--message" *ngFor="let message of messages$ | async">{{ message }}</p>
    <!-- Glory Cash Errors Color Codes -->
    <div
      data-automation="Color Code"
      class="status__color-code eventsNone {{ (kioskStatusColor$ | async)! | statusBulletColorClass }}"
    ></div>
  </div>
</div>
