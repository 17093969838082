import { DotButton } from 'dotsdk';
import { isButtonDisabled } from '@dotxix/helpers/button.helper';

export const isButtonVisuallyUnavailable = (button: DotButton) => {
  if (isButtonDisabled(button)) {
    return true;
  }

  if (button.hasCombos) {
    return button.ComboPage.Combos.every((btn) => btn.Buttons.every((comboButton) => isButtonDisabled(comboButton)));
  } else if (button.Page) {
    if (button.Page.PageTemplate?.toLowerCase() === 'dropdown') {
      return button.Page.Buttons.every((pageButton) => isButtonDisabled(pageButton));
    }
  }
  return false;
};
