import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BannersService } from '@dotxix/services/banners.service';
import { map } from 'rxjs/operators';
import { AnimationType } from '@dotxix/models/enums/animation-type';

@Component({
  selector: 'acr-ada-banners',
  templateUrl: './ada-banners.component.html',
  host: { class: 'ada-banners' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdaBannersComponent {
  public accessibilityBanners$ = this.bannersService.accessibilityBanners$;
  public bannersPath$ = this.appSettings.settings$.pipe(map((settings) => settings.bannersPath));
  public AnimationType = AnimationType;
  constructor(
    public bannersService: BannersService,
    private appSettings: ApplicationSettingsService
  ) {}
}
