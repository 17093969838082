<section class="promos-select-method">
  <main class="popup__page-container">
    <section class="promos-select-method__voucher-input">
      <button class="promos-select-method__option-box" (click)="onKeyboardSelected()" data-automation="Keyboard Button">
        <h3>{{ '87' | translate }}</h3>
        <div>
          <img skin-part="image" class="size--140" alt="" src="/Channels/skin/icon-numericKeyboard.svg" />
        </div>
      </button>
      <h4 class="text-center pt--140">OR</h4>
      <button class="promos-select-method__option-box" (click)="onScanSelected()" data-automation="Scan Button">
        <h3>{{ '88' | translate }}</h3>
        <div>
          <img skin-part="image" class="size--140" alt="" src="/Channels/skin/icon-scanVoucher.svg" />
        </div>
      </button>
    </section>
  </main>
</section>
<footer class="popup__actions justify-center">
  <button class="ui-button ui-button--primary ui-button--small" (click)="onCancelSelected()" data-automation="Cancel Button">
    <span class="ui-button__wrapper">
      <span>{{ '23' | translate }}</span>
    </span>
  </button>
</footer>
