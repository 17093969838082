import { OsirisLoginMethodType } from '@dotxix/osiris/models/interfaces/osiris-login-method.interface';
import { Component } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { OsirisLoginService } from '@dotxix/osiris/services/osiris-login.service';

@Component({
  selector: 'acr-osiris-login',
  templateUrl: './osiris-login.component.html',
})
export class OsirisComponent {
  public availableOsirisLoginMethods = this.appSettings.settings$.value.availableOsirisLoginMethods;

  constructor(
    private appSettings: ApplicationSettingsService,
    private osirisLoginService: OsirisLoginService
  ) {
    console.log(this.availableOsirisLoginMethods);
  }

  public methodSelection(eventType: OsirisLoginMethodType | 'continue-without') {
    this.osirisLoginService.selectedLoginMethod(eventType);
  }

  public getFaceRecallDisplayName() {
    const nameWords = this.appSettings.settings$.value.availableOsirisLoginMethods
      .find((method) => method.Type === 'facerecall')
      ?.Name.split(' ');
    return nameWords ? nameWords : ['FACE', 'RECALL'];
  }
}
