import { Injectable } from '@angular/core';
import { OsirisRoutes } from '@dotxix/osiris/osiris-routes';
import { Router } from '@angular/router';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { OsirisOrderStateService } from '@dotxix/osiris/services/osiris-orders-state.service';
import { QrCodeScreenSteps } from '@dotxix/osiris/models/enums/qr-code-screen-steps.enum';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';
import { Subject } from 'rxjs';
import { HesetHttpService } from '@dotxix/osiris/services/heset/heset.http.service';
import { HesetUserIdStateService } from '@dotxix/osiris/services/heset/heset-user-id-state.service';
import { HesetWebSocketService } from '@dotxix/osiris/services/heset/heset-web-socket.service';
import { getUserIdFromTimeStamp } from '@dotxix/osiris/helpers/create-user-id.helper';
import { encryptAuthenticationUrl } from '@dotxix/osiris/helpers/crypt.helper';

@Injectable({
  providedIn: 'root',
})
export class OsirisLoginHesetService {
  public qrString!: string;
  public loginFinished$ = new Subject<void>();

  constructor(
    private router: Router,
    private appSettings: ApplicationSettingsService,
    private hesetWebSocketService: HesetWebSocketService,
    private hesetHttpService: HesetHttpService,
    private hesetUserIdStateService: HesetUserIdStateService,
    private checkoutService: CheckoutService,
    private osirisOrderStateService: OsirisOrderStateService,
    private osirisStateService: OsirisStateService
  ) {
    this.appSettings.settings$.subscribe((settings) => {
      this.qrString = `${settings.hesetMobileBaseUrl}/authenticated/${encryptAuthenticationUrl()}`;
    });
    this.hesetWebSocketService.onUnknownUser().subscribe(async () => {
      this.hesetHttpService.submitNewUser(getUserIdFromTimeStamp()).catch(() => {
        this.osirisStateService.setHesetScreenStep(QrCodeScreenSteps.ConnectionError);
      });
    });
    this.hesetWebSocketService.onUserAuthenticated().subscribe((userId) => {
      this.hesetUserIdStateService.setUser(userId);
      this.checkoutService.hesetLogged = true;
      this.osirisOrderStateService.setCustomerIdentification({ customerID: userId, customerIdentificationMethod: 'heset' });
      this.loginFinished$.next();
    });
  }

  public loginWithHeset() {
    this.connectToSocket();
    this.router.navigate([OsirisRoutes.HesetQrCodeLogin]).then();
  }

  private connectToSocket() {
    this.hesetWebSocketService
      .startConnection(encryptAuthenticationUrl())
      .then(() => {
        this.osirisStateService.setHesetScreenStep(QrCodeScreenSteps.ShowingQrCode);
      })
      .catch(() => {
        this.osirisStateService.setHesetScreenStep(QrCodeScreenSteps.ConnectionError);
      });
  }
}
