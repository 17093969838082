import { calculateButtonPrice, ComboStepType, DotButton, DotCombo, DotSessionService } from 'dotsdk';
import { initializeComboModifiersQuantities } from '@dotxix/helpers/combo-modifier-quantity.helper';
import { isUpSizeComboStep, visibleOnSize } from '@dotxix/helpers/combo-up-size.helper';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { isItemPackButton } from '@dotxix/helpers/button.helper';
import { isButtonCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { getModifiersWithEditableButtons, isAutoPopFeatVisible } from '@dotxix/helpers/auto-pop-feat.helper';

export const initializeComboButton = (button: DotButton<ButtonState>) => {
  initializeComboModifiersQuantities(button);

  if (typeof button.state.$$initialForcePriceIn !== 'number' && typeof button.ForcePriceIN === 'number') {
    button.state.$$initialForcePriceIn = button.ForcePriceIN;
  }
  if (typeof button.state.$$initialForcePriceOut !== 'number' && typeof button.ForcePriceOUT === 'number') {
    button.state.$$initialForcePriceOut = button.ForcePriceOUT;
  }

  button.Selected = true;
  button.quantity = button.quantity || 1;
  const currentSize = button.selectedSize || comboInitialStartSize(button);
  button.ComboPage.Combos.forEach((combo) => {
    if (!combo.Visible || allComboButtonsAreInvisible(combo)) {
      for (const comboButton of combo.Buttons) {
        if (visibleOnSize(comboButton, currentSize)) {
          comboButton.Selected = true;
          comboButton.quantity = 1;
          break;
        }
      }
    }
  });
};

export const comboInitialStartSize = (button: DotButton) => button.StartSize || button.DefaultSize;

export const isComboStep = (combo: DotCombo): boolean => combo.ComboStepType.toLocaleLowerCase() === ComboStepType.ComboStep.toLowerCase();

export const allComboButtonsAreInvisible = (combo: DotCombo): boolean => combo.Buttons.every((btn) => btn.Visibility === '255');

export const hasVisibleComboButton = (combo: DotCombo, size: string): boolean =>
  combo.Buttons.some((btn) => btn.Visibility !== '255' && visibleOnSize(btn, size));

export const isComboStepWithAtLeastOneVisibleItem = (combo: DotCombo, size: string): boolean =>
  isComboStep(combo) && hasVisibleComboButton(combo, size);

export const filterComboVisibleSteps = (button: DotButton, size: string): DotCombo[] =>
  button.ComboPage.Combos.filter(
    (combo) => combo.Visible && (isUpSizeComboStep(combo) || isComboStepWithAtLeastOneVisibleItem(combo, size))
  );

export const getSelectedComboStepComponent = (combo: DotCombo): DotButton | undefined => {
  const selectedButton = combo.Buttons.find((btn) => btn.Selected);
  if (selectedButton) {
    return selectedButton;
  }

  const itemPackButtons = combo.Buttons.filter((button) => isItemPackButton(button));
  const buttonsLength = itemPackButtons.length;
  for (let index = 0; index < buttonsLength; index++) {
    for (const itemPackButton of itemPackButtons[index].Page.Buttons) {
      if (itemPackButton.Selected) {
        return itemPackButton;
      }
    }
  }

  return undefined;
};

export const hasComboCustomizableModifiers = (component: DotButton, onOrder: boolean) =>
  component.hasModifiers && component.ModifiersPage.Modifiers.some((modifier) => isAutoPopFeatVisible(modifier, onOrder, false));

export const comboCustomizableModifiers = (component: DotButton, onOrder: boolean) =>
  component.hasModifiers ? getModifiersWithEditableButtons(component.ModifiersPage.Modifiers, !onOrder, true) : [];

export const computeComboStepVisibleComponents = (comboStep: DotCombo, size: string): DotButton[] => {
  return comboStep.Buttons.filter(
    (comboStepButton) =>
      (isItemPackButton(comboStepButton) && comboStepButton.Page.Buttons.some((itemPackButton) => visibleOnSize(itemPackButton, size))) ||
      visibleOnSize(comboStepButton, size)
  ).filter((comboStepButton) => isButtonCatalogAvailable(comboStepButton));
};

export const deselectComboStepComponent = (components: DotButton[]) => {
  components.forEach((component) => {
    if (isItemPackButton(component)) {
      component.Page.Buttons.forEach((itemPackButton) => deselectComponent(itemPackButton));
    } else {
      deselectComponent(component);
    }
  });
};

export const selectComponent = (component: DotButton) => {
  component.quantity = 1;
  component.Selected = true;
};

export const deselectComponent = (component: DotButton) => {
  component.quantity = 0;
  component.Selected = false;
};

export const autoSelectComboHiddenSteps = (button: DotButton) => {
  button.ComboPage.Combos.forEach((combo) => {
    if (!isComboStepWithAtLeastOneVisibleItem(combo, button.selectedSize)) {
      const invisibleComponent = combo.Buttons.find(
        (componentButton) => componentButton.Visibility === '255' && visibleOnSize(componentButton, button.selectedSize)
      );
      if (invisibleComponent) {
        selectComponent(invisibleComponent);
      }
    }
  });
};

export const computeComboStepComponentsMinPrice = (buttons: DotButton[], size: string): number =>
  buttons
    .filter((b) => visibleOnSize(b, size))
    .reduce((acc, btn) => {
      if (isItemPackButton(btn)) {
        const itemPackMinPrice = computeComboStepComponentsMinPrice(btn.Page.Buttons, size);
        return acc > itemPackMinPrice ? itemPackMinPrice : acc;
      } else {
        const buttonPrice = calculateButtonPrice(btn, DotSessionService.getInstance().currentPosServingLocation);
        return acc > buttonPrice ? buttonPrice : acc;
      }
    }, Number.MAX_SAFE_INTEGER);

export const addComputedPrice = (button: DotButton): void => {
  const currentPosServingLocation = DotSessionService.getInstance().currentPosServingLocation;
  button.ComputedPrice = calculateButtonPrice(button, currentPosServingLocation);
};
