import { AfterViewChecked, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[acrScaleTextToFitContainer]',
})
export class ScaleTextToFitContainerDirective implements OnInit, OnDestroy, AfterViewChecked {
  private initialFontSize = 0;
  private initialFontSizeLineHeightRation = 1;
  private failedFontSizes: number[] = [];
  private resizeObserver: ResizeObserver | null = null;
  constructor(private elementRef: ElementRef) {}

  public ngOnInit() {
    this.resizeObserver = new ResizeObserver(() => {
      this.updateInitialFontSize();
      this.resetFailedFontSizes();
      this.checkSize();
    });
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  public ngAfterViewChecked() {
    this.updateInitialFontSize();
    this.resetFailedFontSizes();
    this.checkSize();
  }

  public ngOnDestroy() {
    this.resizeObserver?.disconnect();
  }

  private checkSize() {
    const nativeElement = this.elementRef.nativeElement;
    const clientWidth = nativeElement.clientWidth;
    const scrollWidth = nativeElement.scrollWidth;
    const clientHeight = nativeElement.clientHeight;
    const scrollHeight = nativeElement.scrollHeight;

    if (clientWidth < scrollWidth || clientHeight < scrollHeight) {
      this.decreaseSize();
    } else {
      this.increaseSize();
    }
  }

  private decreaseSize() {
    const nativeElement = this.elementRef.nativeElement;
    const style = window.getComputedStyle(nativeElement);
    const fontSize = parseInt(style.fontSize);
    this.failedFontSizes.push(fontSize);
    if (fontSize > 1) {
      nativeElement.style.fontSize = fontSize - 1 + 'px';
      nativeElement.style.lineHeight = ((fontSize - 1) * this.initialFontSizeLineHeightRation).toFixed(2) + 'px';
      this.checkSize();
    }
  }

  private increaseSize() {
    const nativeElement = this.elementRef.nativeElement;
    const style = window.getComputedStyle(nativeElement);
    const fontSize = parseInt(style.fontSize);
    if (fontSize < this.initialFontSize && !this.failedFontSizes.includes(fontSize + 1)) {
      nativeElement.style.fontSize = fontSize + 1 + 'px';
      nativeElement.style.lineHeight = ((fontSize + 1) * this.initialFontSizeLineHeightRation).toFixed(2) + 'px';
      this.checkSize();
    }
  }

  private updateInitialFontSize() {
    const nativeElement = this.elementRef.nativeElement;
    const backupFontSize = nativeElement.style.fontSize;
    const backupLineHeight = nativeElement.style.lineHeight;
    nativeElement.style.fontSize = '';
    nativeElement.style.lineHeight = '';
    const style = window.getComputedStyle(nativeElement);
    this.initialFontSize = parseInt(style.fontSize);
    const initialLineHeight = parseInt(style.lineHeight);
    let fontSizeLineHeightRation = 1;
    if (initialLineHeight > 0) {
      fontSizeLineHeightRation = initialLineHeight / this.initialFontSize;
    }
    this.initialFontSizeLineHeightRation = fontSizeLineHeightRation;
    nativeElement.style.fontSize = backupFontSize;
    nativeElement.style.lineHeight = backupLineHeight;
  }

  private resetFailedFontSizes() {
    this.failedFontSizes = [];
  }
}
