<div class="popup ticket-print-selection" data-automation="ticket-print-selection-popup">
  <div *ngIf="!showOptionsPicker" class="popup__content ticket-print-selection__content">
    <h3 class="m--auto">{{ '2022111410' | translate }}</h3>
    <div class="popup__actions ticket-print-selection__actions">
      <button
        class="ui-button ui-button--secondary ticket-print-selection__actions--button"
        (click)="onDenyClicked()"
        data-automation="Deny"
      >
        <span class="ui-button__wrapper">
          <span>{{ '2022111412' | translate }}</span>
        </span>
      </button>
      <button
        class="ui-button ui-button--primary ticket-print-selection__actions--button"
        (click)="onConfirmClicked()"
        data-automation="Confirm"
      >
        <span class="ui-button__wrapper">
          <span>{{ '71' | translate }}</span>
        </span>
      </button>
    </div>
    <h4 class="m--auto">{{ '2022111409' | translate }}</h4>
    <h3 class="m--auto color--accent">{{ counter }}</h3>
  </div>
  <div *ngIf="showOptionsPicker" class="popup__content gap--40 align-center ticket-print-selection__content">
    <h4>{{ '2022111408' | translate }}</h4>
    <button
      class="ui-button ui-button--primary ticket-print-selection__ticket-options--button"
      (click)="onOrderTicketClicked()"
      data-automation="Order Ticket"
    >
      <span class="ui-button__wrapper">
        <span>{{ '2022111405' | translate }}</span>
      </span>
    </button>
    <button
      class="ui-button ui-button--primary ticket-print-selection__ticket-options--button"
      (click)="onCCTicketClicked()"
      data-automation="CC Ticket"
    >
      <span class="ui-button__wrapper">
        <span>{{ '2022111406' | translate }}</span>
      </span>
    </button>
    <button
      class="ui-button ui-button--primary ui-button--small ticket-print-selection__ticket-options--button"
      (click)="onBothTicketClicked()"
      data-automation="Both"
    >
      <span class="ui-button__wrapper">
        <span>{{ '2022111407' | translate }}</span>
      </span>
    </button>
    <h3 class="m--auto color--accent">{{ counter }}</h3>
  </div>
</div>
