import { Injectable } from '@angular/core';
import { ReceiptDeliveryResult } from '@dotxix/services/receipt/models/receipt-delivery-result.enum';
import { AppRoutes } from '@dotxix/app-routes';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, map, merge, Subject, tap, timer } from 'rxjs';
import { ReceiptQuestionTimeoutService } from '@dotxix/services/receipt/receipt-question-timeout.service';
import { PrintTicketOption } from '@dotxix/models/enums/print-ticket-option';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ElectronicReceiptSender } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { receiptLogger } from '@dotxix/log-manager';

const logPrefix = '[Email Receipt Flow] ';
const emailValidationRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

@Injectable({
  providedIn: 'root',
})
export class EmailReceiptFlowService {
  public $sendingEmail = new BehaviorSubject<boolean>(false);

  private $userRequestedToReturnToReceiptOptions = new Subject<void>();
  private $emailDelivered = new Subject<void>();
  private $keyboardInputTimeout = new Subject<void>();
  private $userRequestedSendingEmail = new Subject<string>();
  private $userKeyboardHadKeyboardActivity = new Subject<void>();

  constructor(
    private router: Router,
    private receiptQuestionTimeoutService: ReceiptQuestionTimeoutService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private applicationSettingsService: ApplicationSettingsService,
    private checkoutService: CheckoutService
  ) {}

  public async deliver(): Promise<ReceiptDeliveryResult> {
    receiptLogger.debug(`${logPrefix}Prompt user for email address where to send email receipt`);
    await this.router.navigate([AppRoutes.EmailReceipt]);

    const $emailDelivered = this.$emailDelivered.pipe(map(() => ReceiptDeliveryResult.SUCCESS));
    const $userRequestedToReturnToReceiptOptions = this.$userRequestedToReturnToReceiptOptions.pipe(
      map(() => ReceiptDeliveryResult.RETURN_TO_OPTIONS)
    );
    const $timeoutResponse = this.$keyboardInputTimeout.pipe(
      map(() => ReceiptDeliveryResult.TIMEOUT),
      tap(() => receiptLogger.debug(`${logPrefix}Email input timed out`))
    );
    this.startKeyboardInput();

    return await firstValueFrom(merge($emailDelivered, $userRequestedToReturnToReceiptOptions, $timeoutResponse));
  }

  public userRequestedSendingEmail(email: string) {
    receiptLogger.debug(`${logPrefix}User requested to send email receipt at ${email}`);
    this.$userRequestedSendingEmail.next(email);
  }

  public userRequestedToReturnToReceiptOptions() {
    receiptLogger.debug(`${logPrefix}User requested to return to receipt options`);
    this.$userRequestedToReturnToReceiptOptions.next();
  }

  public userKeyboardHadKeyboardActivity() {
    this.$userKeyboardHadKeyboardActivity.next();
  }

  private startKeyboardInput() {
    const $timeout = this.receiptQuestionTimeoutService.start(this.$userKeyboardHadKeyboardActivity).pipe(map(() => 'timeout'));
    firstValueFrom(merge(this.$userRequestedSendingEmail, this.$userRequestedToReturnToReceiptOptions, $timeout)).then(
      (result: string | 'timeout' | void) => {
        if (result === 'timeout') {
          return this.$keyboardInputTimeout.next();
        }
        if (result) {
          if (emailValidationRegex.test(result)) {
            this.send(result);
          } else {
            this.showInfoDialogWithAutoDismissAndReStartKeyboardInput('2003013');
          }
        }
      }
    );
  }

  private async send(email: string) {
    this.$sendingEmail.next(true);
    const printableContent = await this.checkoutService.generatePrintableReceiptContent({ printTicketOption: PrintTicketOption.FULL });
    const sendResult = await ElectronicReceiptSender.getInstance().sendEmailReceipt(
      email,
      this.applicationSettingsService.settings$.value.ReceiptEmailFrom,
      this.translationsService.translate('2003010'),
      printableContent
    );
    this.$sendingEmail.next(false);
    if (sendResult.success) {
      receiptLogger.debug(`${logPrefix}Email with receipt sent successfully`);
      this.$emailDelivered.next();
    } else {
      receiptLogger.debug(`${logPrefix}Failed to send email with receipt`);
      this.showInfoDialogWithAutoDismissAndReStartKeyboardInput('2003011');
    }
  }

  private async showInfoDialogWithAutoDismissAndReStartKeyboardInput(messageTextId: string) {
    const infoDialogRef = this.dynamicContentService.openContent(InfoDialogComponent, {
      title: this.translationsService.translate(messageTextId),
      buttonText: this.translationsService.translate('2001014'),
    });
    const $readTimeExpired = timer(6000);
    await firstValueFrom(merge($readTimeExpired, infoDialogRef.afterClosed));
    infoDialogRef.close();
    this.startKeyboardInput();
  }
}
