import { Component } from '@angular/core';
import { OsirisOrderStateService } from '@dotxix/osiris/services/osiris-orders-state.service';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';
import { OrderNumberSteps } from '@dotxix/osiris/models/enums/order-number-steps.enum';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { OsirisFlowService } from '@dotxix/osiris/services/osiris-flow.service';

@Component({
  selector: 'acr-osiris-order-number-info',
  templateUrl: './osiris-order-number-info.component.html',
})
export class OsirisOrderNumberInfoComponent {
  public OrderNumberSteps = OrderNumberSteps;
  public showFaceRecallIcon = false;
  public orderNumberScreenStep$ = this.osirisStateService.orderNumberScreenStep$;
  public showAccountCreated$ = this.osirisStateService.showAccountCreated$;

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private osirisOrdersState: OsirisOrderStateService,
    private osirisStateService: OsirisStateService,
    private osirisFlowService: OsirisFlowService
  ) {
    const customerId = this.osirisOrdersState.getCustomerIdentification()?.customerID;
    if (customerId) {
      if (this.osirisOrdersState.getCustomerIdentification()?.customerIdentificationMethod === 'face-recall') {
        this.showFaceRecallIcon = true;
      }
    }
  }

  public getFaceRecallDisplayName() {
    const nameWords = this.applicationSettingsService.settings$.value.availableOsirisLoginMethods
      .find((method) => method.Type === 'facerecall')
      ?.Name.split(' ');
    return nameWords ? nameWords : ['FACE', 'RECALL'];
  }

  public faceRecallRegistration() {
    this.osirisFlowService.orderNumberFaceRecallRegistrationRequested();
  }
}
