import { Component } from '@angular/core';
import { SmsReceiptFlowService } from '@dotxix/services/receipt/sms-receipt-flow.service';

@Component({
  selector: 'acr-receipt-sms',
  templateUrl: './sms-receipt-page.component.html',
})
export class SmsReceiptPageComponent {
  public limitNumberChars = 15;
  public phoneNumber = '';
  public $sendingSms = this.smsReceiptFlowService.$sendingSms;

  constructor(private smsReceiptFlowService: SmsReceiptFlowService) {}

  public sendEmail() {
    this.smsReceiptFlowService.userRequestedSendingSms(this.phoneNumber);
  }

  public backToReceiptOption() {
    this.smsReceiptFlowService.userRequestedToReturnToReceiptOptions();
  }

  public onSmsInputChanged(newPhoneNumber: string) {
    this.phoneNumber = newPhoneNumber;
    this.smsReceiptFlowService.userKeyboardHadKeyboardActivity();
  }
}
