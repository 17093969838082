import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationReloadService {
  private currentOrders = 0;
  private reloadExecuted = false;
  constructor(protected appSettings: ApplicationSettingsService) {}

  public onSessionEnded() {
    this.currentOrders += 1;
    this.reloadIfThresholdReached();
  }

  private reloadIfThresholdReached() {
    if (this.reloadExecuted) {
      return;
    }

    if (
      this.appSettings.settings$.value.reloadAfterCompletedOrders > 0 &&
      this.currentOrders >= this.appSettings.settings$.value.reloadAfterCompletedOrders
    ) {
      window.location.reload();
    }
  }
}
