import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DotButton } from 'dotsdk';
import { computeProductListOfSelectedButtonsFromModifiersPage } from '@dotxix/helpers/basket-display.helper';
import { canChangeModifiersFromBasket } from '@dotxix/helpers/auto-pop-feat.helper';
import { BasketService } from '@dotxix/services/basket.service';

@Component({
  selector: 'acr-product-card-sub-card-item',
  templateUrl: './product-card-sub-card-item.component.html',
})
export class ProductCardSubCardItemComponent {
  @Input() public allowModifyProduct = true;
  @Output() public customizeProduct = new EventEmitter<DotButton>();

  public itemButton!: DotButton;
  public modifierButtons: DotButton[] | undefined;
  public displayChangeButton: boolean | undefined;

  @Input() set button(button: DotButton) {
    this.itemButton = button;
    this.modifierButtons = computeProductListOfSelectedButtonsFromModifiersPage(button);
    this.displayChangeButton = this.allowModifyProduct && button.isPromo && (button.hasCombos || canChangeModifiersFromBasket(button));
  }

  constructor(private basketService: BasketService) {}

  public onRemoveClick() {
    if (this.itemButton) {
      this.basketService.removeProductWithConfirmation(this.itemButton).then();
    }
  }

  public changeProduct(product: DotButton): void {
    this.customizeProduct.next(product);
  }
}
