import { Component, ViewEncapsulation } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { GloryCashPaymentView } from '@dotxix/payment/models/glory-cash-payment-view.enum';

@Component({
  selector: 'acr-glory-cash-payment-page',
  templateUrl: './glory-cash-payment-page.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class GloryCashPaymentPageComponent {
  public paymentServiceState$ = this.paymentService.state$;
  public GloryCashPaymentView = GloryCashPaymentView;
  constructor(private paymentService: PaymentService) {}
}
