import { Component } from '@angular/core';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import { OsirisLoginHesetService } from '@dotxix/osiris/services/osiris-login-heset.service';
import { OsirisLoginService } from '@dotxix/osiris/services/osiris-login.service';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';
import { QrCodeScreenSteps } from '@dotxix/osiris/models/enums/qr-code-screen-steps.enum';

@Component({
  selector: 'acr-qr-code-login',
  templateUrl: './heset-qr-code-login.component.html',
})
export class HesetQrCodeLoginComponent {
  public qrString = this.osirisHesetLoginService.qrString;
  public qRCodeScreenStep$ = this.osirisStateService.hesetScreenStep$;
  public qrCodeScreenSteps = QrCodeScreenSteps;
  constructor(
    private orderFlowService: OrderFlowService,
    private osirisHesetLoginService: OsirisLoginHesetService,
    private osirisLoginService: OsirisLoginService,
    private osirisStateService: OsirisStateService
  ) {}

  public tryAgainAction() {
    this.osirisHesetLoginService.loginWithHeset();
  }

  public continueWithoutAction() {
    this.osirisLoginService.continueWithoutUser();
  }
}
