import { Pipe, PipeTransform } from '@angular/core';
import { DotCombo } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'translateComboStepTitle',
})
export class ComboStepTitleTranslationPipe implements PipeTransform {
  constructor(protected translationsService: TranslationsService) {}

  public transform(combo: DotCombo): string | null {
    if (combo && combo.TitleDictionary && Object.keys(combo.TitleDictionary).length !== 0) {
      const t = combo.TitleDictionary[this.translationsService.currentLanguageUpperCase];
      return t ? t : combo.TitleDictionary.DEF;
    } else if (combo && combo.Title) {
      return combo.Title;
    } else {
      return null;
    }
  }
}
