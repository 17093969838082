import { Injectable } from '@angular/core';
import { SessionService } from '@dotxix/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class HesetUserIdStateService {
  private _userId = '';

  constructor(private sessionService: SessionService) {
    this.sessionService.onRestartSession.subscribe(() => {
      this._userId = '';
    });
  }
  public setUser(userId: string) {
    this._userId = userId;
  }
  public getUser() {
    return this._userId;
  }
}
