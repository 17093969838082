import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { AppBIService } from '../../../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-glory-cash-cash-back-progress',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001034'"
      [amounts]="[{ label: '2001035', value: toBeRefundedAmount }]"
    ></acr-glory-cash-message>

    <!-- Refunding: {toBeRefundedAmount}-->

    <!-- Your refund is in the works. |Please wait for your transaction to be processed. --> `,
})
export class GloryCashCashBackProgressComponent implements OnInit {
  public toBeRefundedAmount;
  constructor(
    private paymentService: PaymentService,
    private appBiService: AppBIService
  ) {
    this.toBeRefundedAmount =
      this.paymentService.state$.value.cash.cashPaidAmount + this.paymentService.state$.value.cash.cashFailedToRefundAmount;
  }
  public ngOnInit() {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CASH_CASHBACK_PROGRESS);
  }
}
