export enum PROMOS_VIEW {
  SELECT_METHOD = 'select-method',
  SCAN = 'scan',
  KEYBOARD = 'keyboard',
}

export enum PromoDiscountsMode {
  FULL_DISCOUNT = 'FullDiscount',
  EXCLUDE_PRICED_ITEMS = 'ExcludePricedItems',
  EXCLUDE_ONLY_PRICED_MODIFIERS = 'ExcludeOnlyPricedModifiers',
}

export enum PromoResponseType {
  ORDER_DISCOUNT = 0,
  PROMO_NODE = 1,
  PROMO_ERROR = 2,
}

export enum PromoMethod {
  SCAN = 'scan',
  KEYBOARD = 'keyboard',
}
