<div *ngIf="suggestionMenuState$ | async as suggestionMenuState" class="popup suggestion-sales" data-automation="suggestion-sales-popup">
  <div class="popup__content">
    <main
      class="popup__page-container hideScrollbar suggestion-sales__page-container"
      [acrBiViewSuggestions]="{ products: suggestionMenuState.currentSuggestionPage?.Buttons || [], type: 'POPUP' }"
    >
      <header class="suggestion-sales__header">
        <button
          [class.suggestion-sales--hidden]="suggestionMenuState.isRootPage"
          class="ui-button ui-button--primary ui-button--tiny ui-button--iconLeft suggestion-sales__header--button"
          (click)="onBack()"
          data-automation="Button: Back"
        >
          <span class="ui-button__wrapper">
            <img skin-part="image" src="/Channels/skin/icon-chevronLeft.svg" class="size--20" alt="" acrRevertOnRtl />
            <span>{{ '9' | translate }}</span>
          </span>
        </button>
        <h3 class="popup__title mb--0">
          {{ suggestionMenuState.currentSuggestionPage ? (suggestionMenuState.currentSuggestionPage | pageTitle) : '' }}
        </h3>
      </header>
      <acr-scrollable-container>
        <div class="modifiers-grid pb--60">
          <acr-button-item
            *ngFor="let button of suggestionMenuState.currentSuggestionPage?.Buttons; let index = index"
            [showCounterEdit]="!(button.ButtonType === ButtonType.PAGE_BUTTON || button.ButtonType === ButtonType.ITEM_PACK_BUTTON)"
            [button]="button"
            [selected]="button.quantity > 0"
            [displayPrice]="button | calculateButtonPrice | dotCurrency"
            [displayPrefixPlusSignForPrice]="true"
            [quantity]="button.quantity"
            [isIncreaseDisabled]="(suggestionMenuState$ | async)!.disableButtons"
            (buttonClicked)="onButtonClick(index, button)"
            (quantityUpdate)="onQuantityChanged(index, button, $event)"
          ></acr-button-item>
        </div>
      </acr-scrollable-container>
    </main>

    <footer class="popup__actions suggestion-sales__actions">
      <button class="ui-button ui-button--primary ui-button--small" (click)="cancelClick()" data-automation="No thanks Button">
        <span class="ui-button__wrapper">
          <span>{{ '72' | translate }}</span>
        </span>
      </button>
      <button
        class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
        (click)="addToBasketClick()"
        data-automation="Add to bag Button"
        [disabled]="suggestionMenuState.disableAddToBag"
      >
        <span class="ui-button__wrapper">
          <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
          <span>{{ '27' | translate }}</span>
        </span>
      </button>
    </footer>
  </div>
</div>
