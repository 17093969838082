import { IPayResponse, PosElogHandler, PosOperationDevicesTypes, PosPaidState, PosTenderType } from 'dotsdk';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';

export const markFirstPayStartTime = () => {
  PosElogHandler.getInstance().posConfig.posHeader.payStartTime = PosElogHandler.getInstance().posConfig.posHeader.payStartTime
    ? PosElogHandler.getInstance().posConfig.posHeader.payStartTime
    : new Date();
};

export const insertPaymentOperation = (success: boolean, name: string) =>
  PosElogHandler.getInstance().posConfig.posHeader.operations?.push({
    deviceType: PosOperationDevicesTypes.PAY,
    time: new Date(),
    id: `${(PosElogHandler.getInstance().posConfig.posHeader.operations?.length ?? 0) + 1}`,
    name,
    operation: 'DoPayment',
    status: success ? 1 : 0,
    code: success ? 0 : 1,
  });

export const insertTender = (type: PosTenderType, paid: PosPaidState, paidAmount: number, paymentMediaId: string) => {
  const cardTender = { type, paid, paidAmount, paymentMediaId };
  const posHeaderAmounts = PosElogHandler.getInstance().posConfig.posHeader.amounts;
  if (posHeaderAmounts) {
    if (posHeaderAmounts.tenders) {
      posHeaderAmounts.tenders.push(cardTender);
    } else {
      posHeaderAmounts.tenders = [cardTender];
    }
  }
};

export const getTenderMediaId = (payResult: IPayResponse | null, paymentType: PaymentType): string => {
  if (payResult?.TenderMediaId) {
    return payResult.TenderMediaId;
  } else if (paymentType?.TenderMediaID) {
    return paymentType.TenderMediaID;
  } else {
    return '-1';
  }
};
