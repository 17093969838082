import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';
import { modifierButtonPrice } from '@dotxix/helpers/modifiers.helper';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'modifierButtonDefaultQuantityLabel',
})
export class ModifierButtonDefaultQuantityLabelPipe implements PipeTransform {
  constructor(private translationsService: TranslationsService) {}

  public transform(modifierButton: DotButton, chargeThresholdGroup: number) {
    if (modifierButton.DefaultQuantity === modifierButton.quantity) {
      return;
    }
    if (modifierButton.quantity === 0) {
      return `${this.translationsService.translate('2021060201')}`;
    }
    const quantityOffsetToDefaultQuantity = modifierButton.quantity - modifierButton.DefaultQuantity;
    if (quantityOffsetToDefaultQuantity > 0) {
      // ex: "2 Extra"
      const label = `${quantityOffsetToDefaultQuantity} ${this.translationsService.translate('2021060202')}`;
      if (modifierButtonPrice(modifierButton, chargeThresholdGroup) > 0) {
        // ex: "2 Extra Add. +"
        return `${label} ${this.translationsService.translate('2021051001')}`;
      } else {
        return label;
      }
    } else {
      // ex: "Remove 2"
      return `${this.translationsService.translate('2021060203')} ${Math.abs(quantityOffsetToDefaultQuantity)} `;
    }
  }
}
