<main class="page-container table-service-confirmation">
  <header class="flex--1 page-header">
    <img src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <div class="flex flex--column justify-center flex--3 mr--50 ml--50">
    <div class="full-height full-width flex place-center justify-between square table-service-confirmation__square">
      <div class="grid cols--2">
        <h3>{{ '83' | translate }}</h3>
        <img
          class="ui-button--icon size--460 table-service-confirmation__flag"
          alt=""
          src="/Channels/skin/icon-tableServiceFlagReceipt.svg"
          acrRevertOnRtl
        />
      </div>
    </div>
  </div>
  <footer class="flex--2">
    <h2 class="text-center" [innerHtml]="'84' | translate"></h2>
  </footer>
</main>
