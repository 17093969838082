<div class="scanning-face">
  <div class="scanning-face__message">{{ '20220426013' | translate }}</div>
  <div class="scanning-face__scanning" *ngIf="base64Image$ | async">
    <div class="scanning-face__scanning--scan-bar"></div>
    <img width="400rem" height="400rem" class="scan-image" *ngIf="base64Image$ | async" [src]="base64Image$ | async" />
  </div>
  <div *ngIf="(base64Image$ | async) === false" class="scanning-face__scanning size--260">
    <div class="scanning-face__scanning--scan-bar"></div>
    <svg class="scanning-face__scanning--icon" id="icon--face-recall" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.604 273.111">
      <path
        id="sides"
        d="M-4540.782-5881.889a10,10,0,0,1-10-10v-6.621a10,10,0,0,1,10-10h69.79v-69.864a10,10,0,0,1,10-10h6.6a10,10,0,0,1,10,10v86.03a9.964,9.964,0,0,1-3.156,7.29,9.97,9.97,0,0,1-7.3,3.165Zm-168.445,0a10,10,0,0,1-10-10v-86.484a10,10,0,0,1,10-10h6.6a10,10,0,0,1,10,10v69.864h69.335a10,10,0,0,1,10,10v6.621a10,10,0,0,1-10,10Zm248.235-166.837a10,10,0,0,1-10-10v-69.408h-69.79a10,10,0,0,1-10-10v-6.621a10,10,0,0,1,10-10h86.387a10,10,0,0,1,10,10v86.029a10,10,0,0,1-10,10Zm-254.007-.246a10,10,0,0,1-10-10V-6145a10,10,0,0,1,9.486-9.987c.17-.009.342-.014.515-.014h85.932a10,10,0,0,1,10,10v6.619a10,10,0,0,1-10,10H-4698.4v69.408a10,10,0,0,1-10,10Z"
        transform="translate(4725 6155)"
        fill="#bedc31"
      />
      <path
        class="mouth"
        d="M65.695,57.985a65.863,65.863,0,0,1-23.935-4.463A65.964,65.964,0,0,1,21.714,41.251,66.19,66.19,0,0,1,0,0H131.39a66.189,66.189,0,0,1-21.715,41.251A65.967,65.967,0,0,1,89.63,53.521,65.864,65.864,0,0,1,65.695,57.985Z"
        transform="translate(74.484 175.611)"
      />
      <path
        class="eyes"
        d="M-4631-6070a10,10,0,0,1-10-10v-65a10,10,0,0,1,10-10h8a10,10,0,0,1,10,10v65a10,10,0,0,1-10,10Zm-84,0a10,10,0,0,1-10-10v-65a10,10,0,0,1,10-10h8a10,10,0,0,1,10,10v65a10,10,0,0,1-10,10Z"
        transform="translate(4807.605 6216.111)"
      />
    </svg>
  </div>
  <div class="scanning-face__message">{{ '20220426014' | translate }}</div>
</div>
