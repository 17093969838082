import { Directive, Input, OnInit } from '@angular/core';
import { AppBIService } from '../bi.service';
import { DotButton } from '@acrelec.foundation/dotsdk';

@Directive({
  selector: '[acrBiViewSuggestions]',
})
export class BiViewSuggestionsDirective implements OnInit {
  @Input() public acrBiViewSuggestions: { products: DotButton[] | null; type: string } | undefined;

  constructor(private appBiService: AppBIService) {}

  public ngOnInit() {
    if (!this.acrBiViewSuggestions) {
      return;
    }
    if (!this.acrBiViewSuggestions.products) {
      return;
    }
    if (!this.acrBiViewSuggestions.products.length) {
      return;
    }
    this.appBiService.actions.impressions.viewSuggestions(this.acrBiViewSuggestions.products, this.acrBiViewSuggestions.type);
  }
}
