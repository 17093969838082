import { Injectable } from '@angular/core';
import { OsirisRoutes } from '@dotxix/osiris/osiris-routes';
import { OsirisOrderStateService } from '@dotxix/osiris/services/osiris-orders-state.service';
import { Router } from '@angular/router';
import { FaceRecallScreenSteps } from '@dotxix/osiris/models/enums/face-recall-screen-steps.enum';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';
import { Subject } from 'rxjs';
import { FaceRecallHttpService } from '@dotxix/osiris/services/face-recall-http.service';
import { GetFaceRecallImageService } from './face-recall-get-image.service';
import { GetKnownCustomer } from '../models/interfaces/get-known-customer.interface';
import { popIdLogger } from '@dotxix/log-manager';

export enum LoginMethods {
  Existing,
  EndScreen,
}

enum CustomerErrorStringType {
  NotRegistered = 'Person not recognized.',
}

@Injectable({
  providedIn: 'root',
})
export class OsirisLoginFaceRecallService {
  public cancelFaceRecallLogin$ = new Subject<void>();
  public cancelEndScreenFaceRecallLogin$ = new Subject<void>();
  public cancelEndScreenFaceRecallLoginNoRegistration$ = new Subject<void>();
  public loginFinished$ = new Subject<void>();
  public continueWithoutUser$ = new Subject<void>();
  public endScreenLoggedIn$ = new Subject<void>();
  private loginMethod: LoginMethods | null = null;

  constructor(
    private osirisStateService: OsirisStateService,
    private osirisOrderStateService: OsirisOrderStateService,
    private router: Router,
    private faceRecallHttpService: FaceRecallHttpService,
    private getFaceRecallImageService: GetFaceRecallImageService
  ) {}

  public retry() {
    if (this.loginMethod === LoginMethods.Existing) {
      this.customerSelectedExistingAccount();
    }
    if (this.loginMethod === LoginMethods.EndScreen) {
      this.endScreenLoginWithFaceRecall();
    }
  }

  public navigateFromQrCodeRegisterIdentity() {
    if (this.loginMethod === LoginMethods.Existing) {
      this.loginFinished$.next();
      return;
    }
    this.endScreenLoggedIn$.next();
  }

  public navigateFromEnterPinCancel() {
    if (this.loginMethod === LoginMethods.Existing) {
      this.loginFinished$.next();
      return;
    }
    this.cancelEndScreenFaceRecallLogin$.next();
  }

  public navigateFromMaxPinAttempts() {
    if (this.loginMethod === LoginMethods.EndScreen) {
      this.cancelEndScreenFaceRecallLogin$.next();
      return;
    }
    this.continueWithoutUser$.next();
  }

  public handleSuccessfulPinRegistration() {
    if (this.loginMethod === LoginMethods.EndScreen) {
      this.endScreenLoggedIn$.next();
      return;
    }
    this.loginFinished$.next();
  }

  public loginWithFaceRecall() {
    this.osirisStateService.setFaceRecallStep(FaceRecallScreenSteps.CustomerLogin);
    this.router.navigate([OsirisRoutes.FaceRecall]).then();
  }

  public cancelFaceRecallLogin() {
    this.cancelFaceRecallLogin$.next();
  }

  public customerSelectedExistingAccount() {
    this.loginMethod = LoginMethods.Existing;
    this.doLogin();
  }

  public doLogin() {
    const countDownTime = 3000;
    this.osirisStateService.setFaceRecallStep(FaceRecallScreenSteps.LookAtCamera);

    setTimeout(() => {
      this.osirisStateService.setFaceRecallStep(FaceRecallScreenSteps.ScanningFace);
      this.loginExistingCustomer()
        .then(({ pinRequested }) => {
          this.getFaceRecallImageService.getFaceRecallImage();

          setTimeout(() => {
            if (pinRequested) {
              this.osirisStateService.setFaceRecallStep(FaceRecallScreenSteps.EnterPin);
              return;
            }
            if (this.loginMethod === LoginMethods.EndScreen) {
              return this.endScreenLoggedIn$.next();
            }
            this.osirisStateService.setFaceRecallStep(FaceRecallScreenSteps.AccountRetrieved);
          }, 2500);
        })
        .catch((error) => {
          if (error === CustomerErrorStringType.NotRegistered) {
            this.osirisStateService.setFaceRecallStep(FaceRecallScreenSteps.QrCodeRegisterIdentity);
            return;
          }
          this.osirisStateService.faceRecallError(error);
        });
    }, countDownTime);
  }

  public endScreenLoginWithFaceRecall() {
    this.loginMethod = LoginMethods.EndScreen;
    this.router.navigate([OsirisRoutes.FaceRecall]).then();
    this.doLogin();
  }

  public loginFinished() {
    this.loginFinished$.next();
  }

  public errorScreenOnContinueWithoutUser() {
    if (this.loginMethod === LoginMethods.EndScreen) {
      this.cancelEndScreenFaceRecallLoginNoRegistration$.next();
    } else {
      this.continueWithoutUser$.next();
    }
  }

  private loginExistingCustomer(): Promise<GetKnownCustomer> {
    return new Promise((resolve, reject) => {
      this.faceRecallHttpService
        .getKnownCustomer()
        .then(({ FaceID, pinRequested }) => {
          if (!pinRequested) {
            this.osirisOrderStateService.setCustomerIdentification({ customerID: FaceID, customerIdentificationMethod: 'face-recall' });
          }
          popIdLogger.info(`customer id = ${FaceID}`);
          return resolve({ FaceID, pinRequested });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
