import { DotPage } from 'dotsdk';

export const pageContainsLinkInDepth = (link: string, page: DotPage): boolean => {
  if (page.ID === link) {
    return true;
  }

  for (const button of page.Buttons) {
    if (button.Page) {
      if (pageContainsLinkInDepth(link, button.Page)) {
        return true;
      }
    }
  }
  return false;
};
