import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PosServingLocation } from 'dotsdk';
import { AppFlowService } from '@dotxix/services/app-flow.service';
import { Subscription } from 'rxjs';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { map } from 'rxjs/operators';
import { ServiceTypeService } from '@dotxix/services/service-type.service';
import { StatusService } from '@dotxix/services/status.service';
import { AppBIService } from '../../business-intelligence/bi.service';

@Component({
  selector: 'acr-service-type',
  templateUrl: './service-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceTypeComponent implements OnInit, OnDestroy {
  public IN = PosServingLocation.IN;
  public OUT = PosServingLocation.OUT;
  public settings$ = this.appSettings.settings$;
  public isServiceTypeStartScreen$ = this.settings$.pipe(map((settings) => settings.isServiceTypeStartScreen));
  public serviceType$ = this.settings$.pipe(map((settings) => settings.serviceType));
  public canStartOrdering$ = this.statusService.canStartOrdering$;
  public serviceTypeInEnabled$ = this.serviceTypeService.serviceTypeInEnabled$;
  public serviceTypeOutEnabled$ = this.serviceTypeService.serviceTypeOutEnabled$;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private appSettings: ApplicationSettingsService,
    private translationsService: TranslationsService,
    private changeDetector: ChangeDetectorRef,
    private appFlowService: AppFlowService,
    private statusService: StatusService,
    private serviceTypeService: ServiceTypeService,
    private appBIService: AppBIService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(this.translationsService.dictionaryReloaded.subscribe(() => this.changeDetector.detectChanges()));
    this.appBIService.actions.impressions.serviceTypeOptions(this.getBIUserSelectableOptions());
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onServiceTypeInClicked() {
    this.appFlowService.onServiceTypeSelected(PosServingLocation.IN);
    this.appBIService.actions.clicks.selectedServiceType(this.getReverseEnum(PosServingLocation, PosServingLocation.IN));
  }

  public onServiceTypeOutClicked() {
    this.appFlowService.onServiceTypeSelected(PosServingLocation.OUT);
    this.appBIService.actions.clicks.selectedServiceType(this.getReverseEnum(PosServingLocation, PosServingLocation.OUT));
  }

  private getBIUserSelectableOptions(): string[] {
    return [
      { value: this.IN, enabled: this.serviceTypeInEnabled$ },
      { value: this.OUT, enabled: this.serviceTypeOutEnabled$ },
    ]
      .filter((item) => item.enabled)
      .map((item) => this.getReverseEnum(PosServingLocation, item.value));
  }
  private getReverseEnum<T extends object>(enumObj: T, enumValue: (typeof enumObj)[keyof T]): keyof T {
    const keyIndex = Object.values(enumObj).indexOf(enumValue);
    return Object.keys(enumObj)[keyIndex] as keyof T;
  }
}
