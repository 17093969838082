<div class="popup osiris-login-keyboard" data-automation="osiris-login-keyboard-popup">
  <div class="popup__content flex flex--column justify-center">
    <div class="osiris-login-keyboard__wrapper">
      <h2 class="text-center">
        <span>{{ '20210331002' | translate }}</span>
      </h2>
      <acr-keyboard
        (inputChanged)="onInputChanged($event)"
        [limitNumberChars]="limitNumberChars"
        [keyboardLayout]="'numeric'"
      ></acr-keyboard>
    </div>
    <footer class="popup__actions">
      <button class="ui-button ui-button--primary ui-button--small" (click)="onCancelClicked()" data-automation="Cancel Button">
        <span class="ui-button__wrapper">
          <span>{{ '23' | translate }}</span>
        </span>
      </button>

      <button
        class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
        (click)="onConfirmClicked()"
        data-automation="Confirm Button"
        [disabled]="!customerId.length"
      >
        <span class="ui-button__wrapper">
          <img src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
          <span>{{ '71' | translate }}</span>
        </span>
      </button>
    </footer>
  </div>
</div>
