import { Injectable } from '@angular/core';
import { tableServiceLogger } from '@dotxix/log-manager';
import { TableServiceTier } from '@dotxix/table-service/models/enums/table-service-tier';
import { TableServiceSATType } from '@dotxix/table-service/models/enums/table-service-sat-type';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BasketService } from '@dotxix/services/basket.service';
import { DotButton, DotTableServiceHandler, getCatalogButton, ILSSetterResponses, PosElogHandler } from 'dotsdk';
import { SessionService } from '@dotxix/services/session.service';

@Injectable({ providedIn: 'root' })
export class TableServiceSelectionService {
  public userInputTentNumber = '';
  private tableServiceItem: DotButton | null = null;

  constructor(
    private appSettingsService: ApplicationSettingsService,
    private basketService: BasketService,
    private sessionService: SessionService
  ) {
    this.sessionService.onRestartSession.subscribe(() => (this.userInputTentNumber = ''));
  }

  public setTableServiceToElog() {
    this.tableServiceItem = this.getTableServiceItem();

    DotTableServiceHandler.getInstance().setTableNumber(this.getTableServiceNumberForElog());
    if (this.tableServiceItem) {
      this.basketService.addButtonToBasket(this.tableServiceItem);
      PosElogHandler.getInstance().posConfig.posHeader.cvars!.TS_Element = this.tableServiceItem.Link;
    }

    switch (this.appSettingsService.settings$.value.tableServiceTier) {
      case TableServiceTier.BRONZE:
        PosElogHandler.getInstance().posConfig.posHeader.cvars!.TableService = TableServiceSATType.SAT_MODE_TENT;
        break;
      case TableServiceTier.GOLD:
        PosElogHandler.getInstance().posConfig.posHeader.cvars!.TableService = TableServiceSATType.SAT_MODE_TENT_BUNJEE;
        break;
    }
    tableServiceLogger
      .info(
        `Table Service ${this.appSettingsService.settings$.value.tableServiceTier} set to ELOG for order ${
          PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber
        }`
      )
      .then();
  }

  public handleTableServiceBunjee(tentNumber: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.appSettingsService.settings$.value.bunjeeAddress) {
        const msg = 'Cannot call Bunjee server. Please add bunjeeAddress in bundle settings.';
        tableServiceLogger.error(msg).then();
        reject(msg);
      }
      DotTableServiceHandler.getInstance()
        .setIlsRfid(PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber!, tentNumber)
        .then((response) => {
          if (response === ILSSetterResponses.SUCCESS) {
            tableServiceLogger
              .info(`Set Bunjee rfid with success for order ${PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber}`)
              .then();
            resolve();
          } else {
            const msg = 'Error when trying to set Bunjee rfid to server. Please check dotsdk.bunjee logs.';
            tableServiceLogger.error(msg).then();
            reject(msg);
          }
        })
        .catch((e) => {
          tableServiceLogger.error(
            `Set Bunjee rfid error for order ${PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber}. Error: ${e}`
          );
          reject(e);
        });
    });
  }

  private getTableServiceItem(): DotButton | null {
    if (this.appSettingsService.settings$.value.tableServiceItem) {
      const catalogButton = getCatalogButton(this.appSettingsService.settings$.value.tableServiceItem);
      return catalogButton || null;
    }
    return null;
  }

  public getTableServiceNumberForElog(): string {
    return this.appSettingsService.settings$.value.tableServiceNumberPrefix
      ? `${this.appSettingsService.settings$.value.tableServiceNumberPrefix} ${this.userInputTentNumber}`
      : this.userInputTentNumber;
  }
}
