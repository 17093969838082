<div class="page-container osiris-process-order">
  <header class="page-header">
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <div class="osiris-process-order__container flex--1">
    <acr-spinner></acr-spinner>
    <ng-container [ngSwitch]="processOrderError$ | async">
      <ng-container *ngSwitchCase="recallOrderStatus.SERVER_TIMEOUT">
        <div class="flex flex--column">
          <h1 class="osiris-process-order__container--title color--errorRed">{{ '2022050405' | translate }}</h1>
          <h2 class="osiris-process-order__container--subtitle">{{ '2022050404' | translate }}</h2>
        </div>
        <img skin-part="image" alt="" src="/Channels/skin/icon-iconWarning.svg" class="ui-button--icon size--160" />
      </ng-container>
      <ng-container *ngSwitchCase="recallOrderStatus.ORDER_UNAVAILABLE">
        <div class="flex flex--column">
          <h1 class="osiris-process-order__container--title color--errorRed">{{ '2022050403' | translate }}</h1>
          <h2 class="osiris-process-order__container--subtitle">{{ '2022050404' | translate }}</h2>
        </div>
        <img skin-part="image" alt="" src="/Channels/skin/icon-iconWarning.svg" class="ui-button--icon size--160" />
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="flex flex--column">
          <h1 class="osiris-process-order__container--title">{{ '2022050401' | translate }}</h1>
          <h2 class="osiris-process-order__container--subtitle">{{ '2022050402' | translate }}</h2>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
