import * as _ from 'lodash';
import { environment } from '../../environments/environment';

/**
 * Log function that takes into consideration if the environment is set on automation or not.
 * Works exactly like console.og function.
 * @param msg : Message for the log. Same as first parameter of console.log function
 * @param obj : Object to be logged. Same as second parameter of console.log function
 */
export function qaAutomationLog(msg: string, obj: unknown = '') {
  if (environment.production) {
    return;
  }

  if (environment.automation) {
    console.log('[AUTOMATION] ' + msg, JSON.stringify(obj));
  } else {
    if (!_.isEmpty(obj)) {
      const styles = 'color: #000080; font-weight: bold; font-size:14px';

      console.log(`%c ${msg.toUpperCase()} `, styles, obj);
    }
  }
}
