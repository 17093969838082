<section class="keyboard" [ngClass]="'keyboard--' + keyboard.name">
  <div class="keyboard__output">
    <h2 class="keyboard__output--text" acrScaleTextToFitContainer>{{ text }}</h2>
  </div>
  <div class="keyboard__keys">
    <div class="row flex justify-center place-center" *ngFor="let keyLine of keyboard.layout">
      <button
        *ngFor="let key of keyLine"
        class="keyboard__key"
        [ngClass]="'keyboard__key--' + key.type"
        [attr.data-automation]="'Button Key: ' + key.type"
        (click)="keyPress(key)"
        [disabled]="isKeyDisabled(key.type, key.value)"
      >
        {{ key.value }}

        <img
          *ngIf="key.type === 'backspace'"
          skin-part="image"
          src="/Channels/skin/icon-chevronLeft.svg"
          [class.color--keyboardGray]="keyboard.name === 'numeric'"
          class="size--32"
          alt=""
          acrRevertOnRtl
        />
      </button>
    </div>
  </div>
</section>
