import { Component } from '@angular/core';
import { ConfirmDialogDataParams } from '@dotxix/models/interfaces/confirm-dialog-data-params';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';

@Component({
  selector: 'acr-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends AbstractDynamicAnimatedPopup<ConfirmDialogDataParams, ConfirmDialogButtons> {
  public ConfirmDialogButtons = ConfirmDialogButtons;
  public onControlsButtonsClick(dialogButton: ConfirmDialogButtons) {
    this.close(dialogButton);
  }
}
