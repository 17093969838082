import { ErrorHandler, Injectable } from '@angular/core';
import { exceptionsLogger } from '@dotxix/log-manager';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(err: unknown) {
    if (err instanceof Error) {
      try {
        exceptionsLogger.debug(`Unhandled exception: ${err.message}`);
      } catch (e) {
        console.log(e);
      }
    }
    throw err;
  }
}
