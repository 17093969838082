import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { environment } from '../../../environments/environment';
import { DdsClient, DdsResponse, Mockable, MocksOptions, UseMocks } from 'dotsdk';
import { GetKnownCustomer, GetKnownCustomerApiResponse } from '../models/interfaces/get-known-customer.interface';
import { GetKnownCustomerMockOverrider } from '../mocks/json-overriders/get-known-customer-mock-overrider';
import { FaceRecallHTTPCodeReceived } from '../models/enums/http-code-received.enum';
import { SetPinForLastSearch } from '../mocks/json-overriders/set-pin-for-last-search';
import { PayWithFace } from '../mocks/json-overriders/pay-with-face-mock-overrider';
import { GetLastPreview } from '../mocks/json-overriders/get-last-preview-mock-overrider';
import { popIdLogger } from '@dotxix/log-manager';

@Injectable({
  providedIn: 'root',
})
export class FaceRecallHttpService implements Mockable {
  public mocksOptions: MocksOptions = {
    useMocks: environment.useMocksForFaceRecall,
    mocksFolder: environment.faceRecallPath,
  };
  private ddsClient = new DdsClient();
  private DDS_TIMEOUT = 20000;
  constructor(private appSettings: ApplicationSettingsService) {}

  @UseMocks(GetKnownCustomerMockOverrider)
  public getKnownCustomer(): Promise<GetKnownCustomer> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<GetKnownCustomer>(async (res, rej) => {
      const fullUrl = `${this.appSettings.settings$.value.faceRecallBaseUrl}/getknowncustomerid`;
      let responseObject: DdsResponse;
      try {
        responseObject = await this.ddsClient.get(fullUrl, {
          timeout: this.DDS_TIMEOUT,
          headers: this.buildDDSHeaders(),
        });
        if (responseObject && responseObject.body) {
          try {
            const { HTTPCode, Error, FaceID }: GetKnownCustomerApiResponse = JSON.parse(responseObject.body) || {};
            if (HTTPCode) {
              switch (HTTPCode) {
                case FaceRecallHTTPCodeReceived.success:
                  return res({ FaceID, pinRequested: false });

                case FaceRecallHTTPCodeReceived.pinRequested:
                  return res({ FaceID, pinRequested: true });

                case FaceRecallHTTPCodeReceived.error:
                  return rej(Error);

                default:
                  return rej(Error);
              }
            }
            popIdLogger.error(`[getKnownCustomer]: error-> HTTPCode: ${HTTPCode}, FaceID: ${FaceID}, Error: ${Error}`);
            rej();
          } catch (error) {
            rej();
          }
        } else {
          rej();
        }
      } catch (errorResponse) {
        rej();
      }
    });
  }

  @UseMocks(SetPinForLastSearch)
  public setPinForLastSearch(pin: string): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (res, rej) => {
      const fullUrl = `${this.appSettings.settings$.value.faceRecallBaseUrl}/setpinforlastsearch`;
      let responseObject: DdsResponse;
      try {
        responseObject = await this.ddsClient.post(
          fullUrl,
          { pin },
          {
            timeout: this.DDS_TIMEOUT,
            headers: this.buildDDSHeaders(),
          }
        );
        if (responseObject && responseObject.body) {
          try {
            const { HTTPCode, Error, FaceID }: { HTTPCode: FaceRecallHTTPCodeReceived; Error: string; FaceID: string } =
              JSON.parse(responseObject.body) || {};
            if (HTTPCode) {
              switch (HTTPCode) {
                case FaceRecallHTTPCodeReceived.success:
                  return res(FaceID);
                case FaceRecallHTTPCodeReceived.error:
                  return rej(Error);
                default:
                  return rej(Error);
              }
            }
            popIdLogger.error(`[setPinForLastSearch]: error-> HTTPCode: ${HTTPCode}, FaceID: ${FaceID}, Error: ${Error}`);
            return rej();
          } catch (error) {
            popIdLogger.error(`[setPinForLastSearch]: cannot parse ${responseObject.body}`);
            return rej();
          }
        } else {
          popIdLogger.error('[setPinForLastSearch]: no body received');
          return rej();
        }
      } catch (errorResponse) {
        popIdLogger.error(`[setPinForLastSearch]: dds call error ${errorResponse}`);
        return rej();
      }
    });
  }

  @UseMocks(PayWithFace)
  public payWithFace(faceid: string, amount: number, tip: number): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (res, rej) => {
      const fullUrl = `${this.appSettings.settings$.value.faceRecallBaseUrl}/paywithface`;
      let responseObject: DdsResponse;
      try {
        responseObject = await this.ddsClient.post(
          fullUrl,
          { faceid, amount, tip },
          {
            timeout: this.DDS_TIMEOUT,
            headers: this.buildDDSHeaders(),
          }
        );
        if (responseObject && responseObject.body) {
          try {
            const { HTTPCode, Error, FaceID }: { HTTPCode: FaceRecallHTTPCodeReceived; Error: string; FaceID: string } =
              JSON.parse(responseObject.body) || {};
            if (HTTPCode) {
              switch (HTTPCode) {
                case FaceRecallHTTPCodeReceived.success:
                  return res(FaceID);
                case FaceRecallHTTPCodeReceived.error:
                  return rej(Error);
                default:
                  return rej(Error);
              }
            }
            popIdLogger.error(`[payWithFace]: error-> HTTPCode: ${HTTPCode}, FaceID: ${FaceID}, Error: ${Error}`);
            return rej();
          } catch (error) {
            popIdLogger.error(`[payWithFace]: cannot parse ${responseObject.body}`);
            return rej();
          }
        } else {
          popIdLogger.error(`[payWithFace]: no body received`);

          return rej();
        }
      } catch (errorResponse) {
        popIdLogger.error(`[payWithFace]: dds call error ${errorResponse}`);

        return rej();
      }
    });
  }

  @UseMocks(GetLastPreview)
  public getLastPreview(): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (res, rej) => {
      const fullUrl = `${this.appSettings.settings$.value.faceRecallBaseUrl}/getlastpreview`;
      let responseObject: DdsResponse;
      try {
        responseObject = await this.ddsClient.get(fullUrl, {
          timeout: this.DDS_TIMEOUT,
          headers: this.buildDDSHeaders(),
        });
        if (responseObject && responseObject.body) {
          try {
            const {
              HTTPCode,
              Error,
              PreviewBase64,
            }: {
              HTTPCode: FaceRecallHTTPCodeReceived;
              Error: string;
              PreviewBase64: string;
            } = JSON.parse(responseObject.body) || {};
            if (HTTPCode) {
              switch (HTTPCode) {
                case FaceRecallHTTPCodeReceived.success:
                  return res(`data:image/jpg;base64,${PreviewBase64}`);
                case FaceRecallHTTPCodeReceived.error:
                  return rej(Error);

                default:
                  return rej(Error);
              }
            }
            popIdLogger.error(`[getLastPreview]: error-> HTTPCode: ${HTTPCode}, FaceID: ${PreviewBase64}, Error: ${Error}`);
            return rej();
          } catch (error) {
            popIdLogger.error(`[getLastPreview]: cannot parse ${responseObject.body}`);
            return rej();
          }
        } else {
          popIdLogger.error(`[getLastPreview]: no body received`);
          return rej();
        }
      } catch (errorResponse) {
        popIdLogger.error(`[getLastPreview]: dds call error ${errorResponse}`);
        return rej();
      }
    });
  }

  private buildDDSHeaders() {
    return {
      token: this.appSettings.settings$.value.faceRecallAuthToken,
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
    };
  }
}
