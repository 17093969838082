import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SkinService } from '@dotxix/skin/skin.service';
import { AppBIService } from '../business-intelligence/bi.service';

@Injectable({
  providedIn: 'root',
})
export class AdaptabilityService {
  public isAdaEnabled$ = new BehaviorSubject<boolean>(false);

  constructor(
    private skinService: SkinService,
    private appBIService: AppBIService
  ) {}

  public toggle() {
    if (this.isAdaEnabled$.value) {
      this.disable();
    } else {
      this.enable();
    }
    this.appBIService.actions.clicks.selectedAccessibility(this.isAdaEnabled$.value);
  }

  public enable() {
    document.documentElement.classList.add('ada');
    this.skinService.addExtension('ada');
    this.isAdaEnabled$.next(true);
  }

  public disable() {
    document.documentElement.classList.remove('ada');
    this.skinService.removeExtension('ada');
    this.isAdaEnabled$.next(false);
  }
}
