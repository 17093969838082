import { Injectable } from '@angular/core';
import { SessionService } from '@dotxix/services/session.service';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';

export interface CustomerIdentification {
  customerID: string;
  customerIdentificationMethod: CustomerIdentificationMethod;
}

type CustomerIdentificationMethod = 'face-recall' | 'manual-input' | 'heset';

@Injectable({
  providedIn: 'root',
})
export class OsirisOrderStateService {
  private _orders: OsirisOrder[] = [];
  private _customerIdentification: CustomerIdentification | null = null;

  constructor(private sessionService: SessionService) {
    this.sessionService.onRestartSession.subscribe(() => {
      this.resetOrders();
      this.resetCustomerId();
    });
  }

  public getCustomerIdentification(): CustomerIdentification | null {
    return this._customerIdentification;
  }

  public setCustomerIdentification(customerIdentification: CustomerIdentification) {
    this._customerIdentification = customerIdentification;
  }

  public getOrders(): OsirisOrder[] {
    return this._orders;
  }
  public setOrders(value: OsirisOrder[]) {
    this._orders = value;
  }
  public removeSpecificOrder(orderToRemove: OsirisOrder) {
    this.setOrders(this.getOrders().filter((order) => order.i !== orderToRemove.i));
  }

  private resetCustomerId() {
    this._customerIdentification = null;
  }
  private resetOrders() {
    this._orders = [];
  }
}
