import { ChangeDetectionStrategy, Component } from '@angular/core';

// import { Color, ColorPickerControl } from '@iplab/ngx-color-picker';

@Component({
  selector: 'acr-color-picker-wrapper',
  templateUrl: 'color-picker-wrapper.component.html',
  styleUrls: ['color-picker-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerWrapperComponent {
  // public colorControl = new ColorPickerControl();
  // private currentColorSelection: string;
  // private currentTriggerRef: ElementRef;
  // public subscription: Subscription;
  // constructor(private ref: ElementRef, private colorPickerStateService: ColorPickerStateService, private readonly cdr: ChangeDetectorRef) {}
  //
  // @Input()
  // public set color(color: string) {
  //   this.currentColorSelection = color;
  //   this.colorControl.setValueFrom(color);
  // }
  //
  // @Input() set triggerRef(ref: ElementRef) {
  //   this.currentTriggerRef = ref;
  // }
  //
  // @Output()
  // public colorChange: EventEmitter<string> = new EventEmitter();
  //
  // @HostListener('document:click', ['$event.target'])
  // public onPageClick(targetElement: ElementRef) {
  //   const clickedInside = this.ref.nativeElement.contains(targetElement) || targetElement === this.currentTriggerRef.nativeElement;
  //   if (!clickedInside) {
  //     this.close();
  //   }
  // }
  // public ngOnInit() {
  //   this.subscription = this.colorControl.valueChanges.subscribe((value: Color) => {
  //     const alpha = value.getRgba().alpha !== 1;
  //     this.currentColorSelection = value.toHexString(alpha);
  //   });
  //   this.colorControl.setColorPresets(this.colorPickerStateService.colorPickerState$.value.history);
  // }
  //
  // public ngOnDestroy(): void {
  //   this.subscription.unsubscribe();
  //   this.cdr.detach();
  // }
  //
  // public close() {
  //   this.colorChange.emit(this.currentColorSelection);
  // }
  //
  // public async eyeDrop() {
  //   //from chrome 95+
  //   try {
  //     if (window['EyeDropper']) {
  //       const eyeDropper = new (window as any).EyeDropper();
  //       const result = await eyeDropper.open();
  //       if (result.sRGBHex) {
  //         this.colorControl.setValueFrom(result.sRGBHex);
  //         this.cdr.markForCheck();
  //       }
  //     }
  //   } catch (e) {}
  // }
}
