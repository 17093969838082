import { animate, group, keyframes, query, style, transition, trigger } from '@angular/animations';

export const POP_UP_ANIMATION_TIME = 350;

export class Animations {
  public static popUp = trigger('popUp', [
    transition(':enter', [
      group([
        query(
          '.popup__content',
          animate(`${POP_UP_ANIMATION_TIME}ms ease-in-out`, keyframes([style({ transform: 'scale(0)' }), style({ transform: 'scale(1)' })]))
        ),
      ]),
    ]),
    transition(':leave', [
      query('.popup', animate(`${POP_UP_ANIMATION_TIME}ms ease-in-out`, keyframes([style({ opacity: 1 }), style({ opacity: 0 })]))),
    ]),
  ]);
}
