import { Injectable } from '@angular/core';
import { DotAllergen, DotAllergensLoader, DotButton } from 'dotsdk';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';

@Injectable({
  providedIn: 'root',
})
export class AllergensService {
  protected _allergens: DotAllergen[] = [];
  protected _selectedAllergens: DotAllergen[] = [];

  public get selectedAllergens(): DotAllergen[] {
    return this._selectedAllergens;
  }

  public get allergens(): DotAllergen[] {
    return this._allergens || [];
  }

  constructor(
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService
  ) {
    this._allergens = DotAllergensLoader.getInstance().loadedModel.Allergens;
  }

  public addAllergen(allergen: DotAllergen) {
    this._selectedAllergens.push(allergen);
  }

  public removeAllergen(allergen: DotAllergen) {
    this._selectedAllergens = this._selectedAllergens.filter((a) => a.Name !== allergen.Name);
  }

  public resetAllergens() {
    this._selectedAllergens = [];
  }

  public confirmAllergenSelection(button: DotButton) {
    return new Promise((resolve) => {
      const allergens = button.AllergensAndNutritionalValues;
      if (!allergens) {
        resolve(true);
        return;
      }

      if (!this.includesSelectedAllergens(button)) {
        resolve(true);
        return;
      }

      const hasFullAllergen = this.includesFullSelectedAllergens(button);
      const contentRef = this.dynamicContentService.openContent(ConfirmDialogComponent, {
        title: hasFullAllergen ? this.translationsService.translate('39') : this.translationsService.translate('2021012601'),
        leftButtonText: this.translationsService.translate('23'),
        rightButtonText: this.translationsService.translate('28'),
      });
      contentRef.afterClosed.subscribe((response) => {
        if (response === ConfirmDialogButtons.RIGHT_BUTTON) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  private includesFullSelectedAllergens(button: DotButton) {
    return button.AllergensAndNutritionalValues?.Allergens.some((a) =>
      this.selectedAllergens.some((allergen) => a.Name.includes(allergen.Name) && !a.MayContain)
    );
  }

  private includesSelectedAllergens(button: DotButton) {
    return button.AllergensAndNutritionalValues?.Allergens.some((a) =>
      this.selectedAllergens.some((allergen) => a.Name.includes(allergen.Name))
    );
  }
}
