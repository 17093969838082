import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'acr-service-type-button',
  templateUrl: './service-type-button.component.html',
})
export class ServiceTypeButtonComponent {
  @Input() text = '';
  @Input() automationTag = '';
  @Input() iconName = '';
  @Input() disabled = false;
  @Output() selected = new EventEmitter<void>();

  public click() {
    if (!this.disabled) {
      this.selected.next();
    }
  }
}
