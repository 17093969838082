import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { PaymentRetryAnswer } from '@dotxix/payment/models/payment-retry-answer.enum';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { AppBIService } from '../../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-payment-retry',
  templateUrl: './payment-retry.component.html',
})
export class PaymentRetryComponent extends AbstractDynamicAnimatedPopup<object, PaymentRetryAnswer> implements OnInit, OnDestroy {
  public counter!: number;
  private timerSubscriber!: Subscription;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private applicationSettingsService: ApplicationSettingsService,
    private appBIService: AppBIService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.appBIService.actions.impressions.viewPaymentRetryPrompt();
    this.counter = this.applicationSettingsService.settings$.value.paymentRetryCounter;
    this.countdown();
  }

  public ngOnDestroy(): void {
    this.timerSubscriber.unsubscribe();
  }

  public onNoClicked() {
    this.appBIService.actions.clicks.clickedPaymentRetryDecline();
    this.close(PaymentRetryAnswer.No);
  }

  public onYesClicked() {
    this.appBIService.actions.clicks.clickedPaymentRetryConfirm();
    this.close(PaymentRetryAnswer.Yes);
  }

  private countdown() {
    this.timerSubscriber = timer(1000, 1000).subscribe(() => {
      this.counter--;
      if (this.counter <= 0) {
        this.close(PaymentRetryAnswer.No);
      }
    });
  }
}
