import { Component, OnInit } from '@angular/core';
import { DotButton, DotCombo, DotModifier, DotSessionService, getCombosCatalogButton } from 'dotsdk';
import * as _ from 'lodash';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { ButtonDetailsDataParams } from '@dotxix/models/interfaces/button-details-data-params';
import {
  addAutoCompleteModifiers,
  forceShowModifierGroupsThatAreNotReachingMinQuantity,
  isModifierSelectionValidForBasket,
} from '@dotxix/helpers/modifiers.helper';
import { computeButtonDisplayPrice, isItemPackButton, isNotItemPackButton } from '@dotxix/helpers/button.helper';
import {
  addComputedPrice,
  autoSelectComboHiddenSteps,
  comboCustomizableModifiers,
  comboInitialStartSize,
  computeComboStepComponentsMinPrice,
  computeComboStepVisibleComponents,
  deselectComboStepComponent,
  filterComboVisibleSteps,
  getSelectedComboStepComponent,
  hasComboCustomizableModifiers,
  hasVisibleComboButton,
  isComboStep,
  selectComponent,
} from '@dotxix/helpers/combo.helper';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';
import { computeProductStepItemPackVisibleComponents } from '@dotxix/helpers/item-pack.helper';
import { Subject } from 'rxjs';
import { ProductCustomizationService } from '@dotxix/services/product-customization.service';
import { updateButtonPromoCustomForcePrice } from '../../helpers/promo.helper';

enum EditComboStepState {
  CHOOSE_COMPONENT = 'chooseComponent',
  CUSTOMIZE = 'customize',
  SUBGROUP = 'subgroup',
}

@Component({
  selector: 'acr-edit-single-combo-step',
  templateUrl: './edit-single-combo-step.component.html',
})
export class EditSingleComboStepComponent
  extends AbstractDynamicAnimatedPopup<ButtonDetailsDataParams, DotButton | null | undefined>
  implements OnInit
{
  public ComboStepState = EditComboStepState;
  public button!: DotButton<ButtonState>;
  public catalogButton!: DotButton;
  public comboTitle!: string;
  public comboSubTitle!: string;

  public displayBackButton = false;
  public disableConfirmButton = false;

  public currentSize!: string;
  public comboButton!: DotButton;

  public dynamicHeaderImages!: boolean;
  public headerImages!: string[];

  public currentComboStepState!: EditComboStepState;
  public visibleComboSteps: DotCombo[] = [];
  public currentComboStepIndex = 0;
  public currentComboStep!: DotCombo;
  public currentComboStepComponents: DotButton[] = [];
  public currentComboStepComponentsMinPrice!: number;
  public currentComboStepComponentFeatures: DotModifier[] = [];
  public itemPackVisibleComponents: DotButton[] = [];

  public scrollToTop$ = new Subject<void>();

  constructor(
    private appSettings: ApplicationSettingsService,
    private translationsService: TranslationsService,
    private productTranslationsService: ProductTranslationsService,
    private productCustomizationService: ProductCustomizationService
  ) {
    super();
  }

  public ngOnInit() {
    this.button = _.cloneDeep(this.dataParams!.button);
    this.comboButton = this.dataParams!.button;
    qaAutomationLog('button: ', this.button);

    this.catalogButton = getCombosCatalogButton(this.button.ComboPage.ID.toString());
    this.comboTitle = this.catalogButton ? this.translationsService.getTranslatedButtonCaption(this.catalogButton) ?? '' : '';

    this.currentSize = this.button.selectedSize || comboInitialStartSize(this.button);
    this.updateSubtitle();
    this.updateSizeRelatedProps();

    this.dynamicHeaderImages = this.appSettings.settings$.value.enableComboAnimation;
    this.headerImages = this.computeHeaderImages();

    if (typeof this.dataParams!.buttonIndex === 'number' && this.button.ComboPage.Combos[this.dataParams!.buttonIndex]) {
      this.visibleComboSteps = [this.button.ComboPage.Combos[this.dataParams!.buttonIndex]];
    }
    this.listComboStepComponents(0);
  }

  public computeComponentDisplayPrice(component: DotButton): number {
    return computeButtonDisplayPrice(component, this.currentComboStepComponentsMinPrice);
  }

  public currentComboStepComponentFeaturesChanged() {
    this.updateNavigationButtonStates();
  }

  public isComboStepComponentSelected(component: DotButton) {
    return isNotItemPackButton(component) ? component.Selected : component.Page.Buttons.some((itemPackButton) => itemPackButton.Selected);
  }

  public onBackButtonClicked() {
    switch (this.currentComboStepState) {
      case EditComboStepState.CHOOSE_COMPONENT:
        break;
      case EditComboStepState.CUSTOMIZE:
        this.currentComboStepState = EditComboStepState.CHOOSE_COMPONENT;
        break;
      case EditComboStepState.SUBGROUP:
        this.currentComboStepState = EditComboStepState.CHOOSE_COMPONENT;
        break;
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  public onComponentButtonClicked(component: DotButton) {
    if (isItemPackButton(component)) {
      this.listComboStepItemPack(component);
    } else {
      this.selectCurrentComboStepComponent(component);
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  public onConfirmClicked() {
    if (this.currentComboStepState === EditComboStepState.CUSTOMIZE) {
      const selectedComboStepComponent = getSelectedComboStepComponent(this.currentComboStep);
      if (selectedComboStepComponent) {
        addAutoCompleteModifiers(selectedComboStepComponent);
      }
    }
    this.finishCombo();
  }

  public cancelClick(): void {
    this.productCustomizationService.onCancelButton(this.comboButton, this);
  }

  public onItemPackComponentButtonClicked(component: DotButton) {
    this.selectCurrentComboStepComponent(component);
  }

  private listComboStepComponents(comboStepIndex: number) {
    this.currentComboStepIndex = comboStepIndex;
    this.currentComboStep = this.visibleComboSteps[this.currentComboStepIndex];
    this.currentComboStepState = EditComboStepState.CHOOSE_COMPONENT;
    this.updateCurrentComboStepComponents();
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private updateCurrentComboStepComponents() {
    if (this.currentComboStep) {
      this.currentComboStepComponents = computeComboStepVisibleComponents(this.currentComboStep, this.currentSize);
      this.currentComboStepComponentsMinPrice = computeComboStepComponentsMinPrice(this.currentComboStepComponents, this.currentSize);
    }
  }

  private selectCurrentComboStepComponent(componentButton: DotButton) {
    deselectComboStepComponent(this.currentComboStepComponents);
    selectComponent(componentButton);
    this.updateDynamicHeaderImages();
    this.updateNavigationButtonStates();

    if (componentButton.hasModifiers) {
      forceShowModifierGroupsThatAreNotReachingMinQuantity(componentButton);
      this.customizeComponentFeaturesIfAny(componentButton);
    }
  }

  private customizeComponentFeaturesIfAny(component: DotButton) {
    if (hasComboCustomizableModifiers(component, !this.dataParams!.isUpdate)) {
      this.customizeComponentModifiers(component);
    }
  }

  private customizeComponentModifiers(component: DotButton) {
    this.currentComboStepComponentFeatures = comboCustomizableModifiers(component, !this.dataParams!.isUpdate);
    this.currentComboStepState = EditComboStepState.CUSTOMIZE;
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private listComboStepItemPack(component: DotButton) {
    this.currentComboStepState = EditComboStepState.SUBGROUP;
    this.itemPackVisibleComponents = computeProductStepItemPackVisibleComponents(component, this.currentSize);
  }

  private finishCombo() {
    this.comboFinished();
  }

  private comboFinished() {
    this.button.selectedSize = this.currentSize;
    autoSelectComboHiddenSteps(this.button);
    this.addComputedPrice();

    this.close(this.button);
  }

  private addComputedPrice(): void {
    updateButtonPromoCustomForcePrice(
      this.button,
      DotSessionService.getInstance().currentPosServingLocation,
      this.appSettings.settings$.value.promoDiscountsMode
    );
    addComputedPrice(this.button);
  }

  private updateSizeRelatedProps() {
    this.visibleComboSteps = filterComboVisibleSteps(this.button, this.currentSize);
    this.updateCurrentComboStepComponents();
  }

  private updateSubtitle() {
    this.comboSubTitle = this.translationsService.getTranslatedButtonCaption(this.button) ?? '';
  }

  private updateDynamicHeaderImages() {
    if (this.dynamicHeaderImages) {
      this.headerImages = this.computeDynamicHeaderImages();
    }
  }

  private computeHeaderImages() {
    if (this.dynamicHeaderImages) {
      return this.computeDynamicHeaderImages();
    } else {
      return this.computeStaticHeaderImages();
    }
  }

  private computeStaticHeaderImages() {
    const headerImageButton = this.catalogButton ? this.catalogButton : this.button;
    const buttonHeaderImage = this.productTranslationsService.translatePicture(headerImageButton);
    return buttonHeaderImage ? [buttonHeaderImage] : [];
  }

  private computeDynamicHeaderImages() {
    return this.button?.ComboPage?.Combos?.filter((combo) => isComboStep(combo) && hasVisibleComboButton(combo, this.currentSize))?.reduce(
      (acc, modifier) => {
        const selectedButton = getSelectedComboStepComponent(modifier);
        const imagePath = selectedButton ? this.productTranslationsService.translatePicture(selectedButton) : null;
        return imagePath ? [...acc, imagePath] : acc;
      },
      [] as string[]
    );
  }

  private updateNavigationButtonStates() {
    this.displayBackButton =
      this.currentComboStepState === EditComboStepState.CUSTOMIZE || this.currentComboStepState === EditComboStepState.SUBGROUP;

    this.disableConfirmButton = !this.isCurrentStepValid();
  }

  private isCurrentStepValid() {
    const selectedComboStepComponent = getSelectedComboStepComponent(this.currentComboStep);
    if (selectedComboStepComponent) {
      return isModifierSelectionValidForBasket(selectedComboStepComponent);
    } else {
      return false;
    }
  }

  private scrollToTop() {
    this.scrollToTop$.next();
  }
}
