<language-change-view-trigger></language-change-view-trigger>
<div class="popup" data-automation="button-modifier-subgroup-popup">
  <div class="popup__content">
    <main class="flex flex--column full-height pt--100">
      <div class="flex flex--column place-center justify-center">
        <h3>{{ '2021071301' | translate }}</h3>
        <h3 *ngIf="itemPackButton.Caption">{{ itemPackButton.Caption }}</h3>
        <h5 *ngIf="totalGroupSelectedQuantity >= maxQuantityGroup">{{ '73' | translate }}</h5>
      </div>

      <acr-scrollable-container>
        <div class="modifiers-grid p--40">
          <acr-button-modifier
            *ngFor="let modifierButton of itemPackButton.Page.Buttons"
            [modifierButton]="modifierButton"
            [groupMaxQuantity]="modifier.PageInfo.MaxQuantity"
            [disableIncrease]="groupMaxQuantityReached"
            (clicked)="onModifierButtonClicked(modifierButton)"
            (quantityChanged)="onModifierButtonQuantityChanged(modifierButton, $event)"
          ></acr-button-modifier>
        </div>
      </acr-scrollable-container>

      <footer class="popup__actions">
        <button class="ui-button ui-button--primary ui-button--small" (click)="onCancelClicked()" data-automation="No thanks Subgroup">
          <span class="ui-button__wrapper">
            <span>{{ '26' | translate }}</span>
          </span>
        </button>
        <button
          class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
          (click)="onConfirmClicked()"
          data-automation="Finish choosing Subgroups"
        >
          <span class="ui-button__wrapper">
            <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
            <span>{{ '71' | translate }}</span>
          </span>
        </button>
      </footer>
    </main>
  </div>
</div>
