import { Observable, Subscription, timer } from 'rxjs';

export const restartableTimer = (durationInMilliseconds: number): [Observable<void>, () => void] => {
  const rxTimer = timer(durationInMilliseconds);
  let timerSubscription: Subscription;
  let timerStep: () => void = () => {};
  const restart = () => timerStep();

  const timerObservable = new Observable<void>((observer) => {
    timerStep = () => {
      if (timerSubscription) {
        timerSubscription.unsubscribe();
      }
      timerSubscription = rxTimer.subscribe(() => observer.next());
    };

    timerStep();

    return () => {
      timerSubscription.unsubscribe();
      observer.complete();
    };
  });
  return [timerObservable, restart];
};
