<language-change-view-trigger></language-change-view-trigger>
<div
  class="nav-slider"
  [class.nav-slider__firstItemFixed]="dockHomePage"
  [class.hasNavArrows]="hasNavArrows"
  [class.vertical]="vertical || navBarVerticalOrientation"
  [class.horizontal]="!vertical || !navBarVerticalOrientation"
>
  <button
    *ngIf="dockHomePage"
    (click)="goToHomePage()"
    class="nav-slider__item nav-slider__item--home"
    [class.nav-slider__item--selected]="selectedNavButtonLink === mainPageId"
    data-automation="Nav Slider Button: Home"
  >
    <span class="nav-slider__item--title">{{ '1' | translate }}</span>
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo nav-slider__item--image" alt="" width="100rem" height="100rem" />
  </button>
  <div class="nav-slider__wrapper">
    <div class="nav-slider__container hideScrollbar" (scroll)="onScrollContainerScrolled()" #scrollRef>
      <button
        #navList
        *ngFor="let button of displayNavButtons; trackBy: trackByIndex"
        class="nav-slider__item"
        [class.nav-slider__item--home]="mainPageId === button.Link"
        [class.nav-slider__item--selected]="selectedNavButtonLink === button.Link"
        [ngClass]="displayMode(button, 'nav-slider__item--displayOnlyImage', 'nav-slider__item--displayOnlyText')"
        (click)="onNavButtonClicked(button)"
        [attr.data-link]="button.Link"
        [attr.data-automation]="'Link to:' + button.Link"
      >
        <ng-container *ngIf="mainPageId !== button.Link">
          <span class="nav-slider__item--title">{{ button | translateCaption }}</span>
          <img
            class="nav-slider__item--image"
            [src]="button | translatePicture"
            [class.logo]="!(button | translatePicture)"
            alt=""
            width="100rem"
            height="100rem"
          />
        </ng-container>
        <ng-container *ngIf="mainPageId === button.Link">
          <span class="nav-slider__item--title">{{ '1' | translate }}</span>
          <img class="nav-slider__item--image" skin-part="image" src="/Channels/skin/logo.svg" alt="" width="100rem" height="100rem" />
        </ng-container>
      </button>
    </div>
    <ng-container *ngIf="hasNavArrows">
      <button
        [disabled]="disableLeftArrow"
        class="nav-slider__controller nav-slider__controller--left"
        data-automation="Nav Slider Button: Scroll Left"
        (click)="scrollLeft()"
      >
        <img
          skin-part="image"
          src="/Channels/skin/icon-chevronLeft.svg"
          class="ui-button--icon color--white size--30"
          alt=""
          acrRevertOnRtl
        />
      </button>
      <button
        [disabled]="disableRightArrow"
        class="nav-slider__controller nav-slider__controller--right"
        data-automation="Nav Slider Button: Scroll Right"
        (click)="scrollRight()"
      >
        <img
          skin-part="image"
          src="/Channels/skin/icon-chevronRight.svg"
          class="ui-button--icon color--white size--30"
          alt=""
          acrRevertOnRtl
        />
      </button>
    </ng-container>
  </div>
</div>
