import { AtpSaveFileResponse } from '@dotxix/custom-theme/enums/atp-save-file.enum';
import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';

export enum NotificationType {
  generalThemingVariable = 'general',
  customthemingVariable = 'custom',
  customLogoVariable = 'logo',
}

export interface ChangesMadeNotification {
  showNotification: boolean;
  completedTypes: NotificationType[];
}

export interface ThemeEditorState {
  generalThemingVariables: CssVariables;
  availableDefaultVariables: CssVariables;
  editorOpenClose: boolean;
  changesSent: AtpSaveFileResponse | null;
  showEditElementOverlay: boolean;
  generatedThemeIdentifier: string;
  editElementClone: Node | null;
  changesMadeNotification: ChangesMadeNotification;
}
