import { Component, OnInit } from '@angular/core';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-ts-unavailable',
  templateUrl: './table-service-unavailable.component.html',
})
export class TableServiceUnavailableComponent implements OnInit {
  constructor(private appBiService: AppBIService) {}
  public ngOnInit() {
    this.appBiService.actions.impressions.viewTableServiceUnavailable();
  }
}
