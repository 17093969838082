import { Injectable } from '@angular/core';
import { DotFlow, DotSessionEndType, DotSessionService, PosElogHandler, PosRefintService, PosServingLocation } from 'dotsdk';
import { Observable, Subject } from 'rxjs';
import { AllergensService } from '@dotxix/services/allergens.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { MandatoryTableServiceSelection } from '@dotxix/table-service/models/enums/mandatory-table-service-selection';
import { TableServiceType } from '@dotxix/table-service/models/enums/table-service-type';
import { WorkingHoursService } from '@dotxix/services/working-hours.service';
import { AdaptabilityService } from '@dotxix/services/adaptability.service';
import { appLogger } from '@dotxix/log-manager';
import { environment } from '../../environments/environment';
import { ApplicationReloadService } from '@dotxix/services/application-reload.service';
import { AppBIService } from '../business-intelligence/bi.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public sessionStarted: Observable<PosServingLocation>;
  public onRestartSession: Observable<DotSessionEndType>;

  private $sessionStarted = new Subject<PosServingLocation>();
  private beforeSessionRestartCallbacks: ((status: DotSessionEndType) => Promise<void>)[] = [];
  private $onRestartSession: Subject<DotSessionEndType> = new Subject();

  public get serviceType(): PosServingLocation {
    return DotSessionService.getInstance().currentPosServingLocation;
  }

  constructor(
    private translationService: TranslationsService,
    private appSettings: ApplicationSettingsService,
    private allergensService: AllergensService,
    private workingHoursService: WorkingHoursService,
    private adaptabilityService: AdaptabilityService,
    private applicationReloadService: ApplicationReloadService,
    private appBIService: AppBIService
  ) {
    this.sessionStarted = this.$sessionStarted.asObservable();
    this.onRestartSession = this.$onRestartSession.asObservable();
  }

  public registerBeforeSessionRestartCallback(fn: (status: DotSessionEndType) => Promise<void>) {
    this.beforeSessionRestartCallbacks.push(fn);
  }

  public async startSession(type: PosServingLocation) {
    appLogger.debug(`start session ${type}`);
    await DotFlow.sessionStart(type);
    this.$sessionStarted.next(type);
  }

  public hasTableService() {
    const tableServiceMode = this.appSettings.settings$.value.tableServiceMode;
    return (
      this.workingHoursService.tableServiceEnabled$.value &&
      (tableServiceMode === TableServiceType.BOTH ||
        (tableServiceMode === TableServiceType.EAT_IN && this.serviceType === PosServingLocation.IN) ||
        (tableServiceMode === TableServiceType.TAKE_OUT && this.serviceType === PosServingLocation.OUT))
    );
  }

  public hasMandatoryTSS() {
    const hasTableService = this.hasTableService();
    if (!hasTableService) {
      return false;
    }
    const mandatoryTSS = this.appSettings.settings$.value.mandatoryTSS;
    const tableServiceMode = this.appSettings.settings$.value.tableServiceMode;
    if (mandatoryTSS === MandatoryTableServiceSelection.NONE) {
      return false;
    }
    if (tableServiceMode === TableServiceType.BOTH) {
      if (mandatoryTSS === MandatoryTableServiceSelection.BOTH) {
        return true;
      }
      const lookUpMandatoryTSSToServiceType = {
        [MandatoryTableServiceSelection.EAT_IN]: PosServingLocation.IN,
        [MandatoryTableServiceSelection.TAKE_OUT]: PosServingLocation.OUT,
      };
      return lookUpMandatoryTSSToServiceType[mandatoryTSS] === this.serviceType;
    }
    return (tableServiceMode as string) === mandatoryTSS || mandatoryTSS === MandatoryTableServiceSelection.BOTH;
  }

  public async restartSession(type: DotSessionEndType) {
    const AUID = PosElogHandler.getInstance().posConfig.posHeader.AUID || '';
    const refInt = PosRefintService.getInstance()._refInt;

    /**
     * @todo Workaround:
     * By some reason orderPOSNumber from CheckoutService is not cleared when new session starts,
     * however orderPosNumber from PosElogHandler is cleared.
     * So, to avoid sending wrong orderPOSNumber, I send one from PosElogHandler.
     */
    const orderPosNumber = PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber;
    const biOrderPOSNumber = orderPosNumber ? Number(orderPosNumber) : undefined;

    await this.executeBeforeSessionRestartCallbacks(type);
    if (environment.production) {
      console.clear();
    }
    this.$onRestartSession.next(type);
    await DotFlow.sessionStop(type);
    this.translationService.setCurrentLanguage(this.appSettings.settings$.value.defaultLanguage);
    this.allergensService.resetAllergens();
    this.adaptabilityService.disable();

    const biAnalyticsUrl = this.appSettings.settings$.value.biAnalyticsUrl;
    const biAnalyticsAuthToken = this.appSettings.settings$.value.biAnalyticsAuthToken;
    this.appBIService.dumpToApiAndReinit(biAnalyticsUrl, biAnalyticsAuthToken, AUID, refInt, biOrderPOSNumber, type);

    appLogger.debug(`session restart ${type}`);
    this.applicationReloadService.onSessionEnded();
  }

  private async executeBeforeSessionRestartCallbacks(status: DotSessionEndType) {
    for (const callback of this.beforeSessionRestartCallbacks) {
      await callback(status);
    }
  }
}
