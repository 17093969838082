import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { appLogger } from '@dotxix/log-manager';

@Injectable({
  providedIn: 'root',
})
export class TouchlessService {
  constructor(private appSettingsService: ApplicationSettingsService) {}
  public initialize() {
    this.appSettingsService.settings$.subscribe((settings) => this.applyTouchlessCssClass(settings.touchlessMode));
  }

  public applyTouchlessCssClass(touchlessMode: boolean) {
    if (touchlessMode) {
      appLogger.debug('enabling touchless mode');
      document.documentElement.classList.add('touchlessEnabled');
    } else {
      document.documentElement.classList.remove('touchlessEnabled');
    }
  }
}
