import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { PrintTicketOption } from '@dotxix/models/enums/print-ticket-option';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { PaymentService } from '@dotxix/payment/services/payment.service';

@Component({
  selector: 'acr-ticket-print-selection',
  templateUrl: './ticket-print-selection.component.html',
})
export class TicketPrintSelectionComponent extends AbstractDynamicAnimatedPopup<undefined, PrintTicketOption> implements OnInit, OnDestroy {
  private intervalId: NodeJS.Timer | undefined;

  public showOptionsPicker = false;

  public counter = 10;

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private paymentService: PaymentService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setCounter(() => {
      this.close(this.applicationSettingsService.settings$.value.smallTicket === true ? PrintTicketOption.SMALL : PrintTicketOption.NONE);
    });
  }

  public ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  public onDenyClicked(): void {
    this.close(this.applicationSettingsService.settings$.value.smallTicket === true ? PrintTicketOption.SMALL : PrintTicketOption.NONE);
  }

  public onConfirmClicked(): void {
    if (this.paymentService.state$.value.card.cardPaidAmount) {
      this.showCardPaymentPrintOptions();
    } else {
      this.close(PrintTicketOption.FULL);
    }
  }

  public onOrderTicketClicked(): void {
    this.close(PrintTicketOption.ORDER_TICKET);
  }

  public onCCTicketClicked(): void {
    this.close(PrintTicketOption.CREDIT_CARD_TICKET);
  }
  public onBothTicketClicked(): void {
    this.close(PrintTicketOption.FULL);
  }

  private showCardPaymentPrintOptions() {
    clearInterval(this.intervalId);
    this.setCounter(() => {
      this.close(PrintTicketOption.FULL);
    });
    this.showOptionsPicker = true;
  }

  private setCounter(timeoutFn: () => void): void {
    this.counter = Math.max(10, this.applicationSettingsService.settings$.value.timeoutTicketQuestion);

    this.intervalId = setInterval(() => {
      this.counter = this.counter - 1;

      if (this.counter === 0) {
        clearInterval(this.intervalId);
        timeoutFn();
      }
    }, 1000);
  }
}
