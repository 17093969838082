<div class="enter-pin" *ngIf="faceRecall$ | async as faceRecall">
  <h4 class="enter-pin__message enter-pin__message--error" *ngIf="!faceRecall.customError" [innerHtml]="'2022102808' | translate"></h4>
  <section class="enter-pin__content">
    <acr-keyboard
      [hideValue]="true"
      [reset]="keyboardReset"
      (inputChanged)="keyboardValue($event)"
      [keyboardLayout]="'numeric'"
    ></acr-keyboard>
  </section>
  <div class="enter-pin__actions">
    <button class="ui-button ui-button--secondary ui-button--medium" (click)="submit()">{{ '2022102809' | translate }}</button>
    <button class="ui-button ui-button--primary bg--tertiary ui-button--medium" (click)="cancel()">{{ '23' | translate }}</button>
  </div>
  <div *ngIf="(currentRetries$ | async)! < defaultMaxRetries">
    <p class="enter-pin__message enter-pin__message--error" [innerHtml]="'2022102810' | translate"></p>
    <p class="enter-pin__message enter-pin__message--error">
      <span>{{ '2022102807' | translate }}</span>
      <span>{{ currentRetries$ | async }}</span>
    </p>
  </div>
</div>
