export const isNumberInRange = (keyValue: string, currentValue: string, minValue: number, maxValue: number): boolean => {
  const valueWithKey = Number((currentValue.length > 0 ? currentValue : 0) + keyValue);
  const tentNumberMinimumValue = minValue.toString();
  const tentNumberMaximumValue = maxValue.toString();

  const firstDigitOfMinValue = tentNumberMinimumValue[0];
  const secondDigitOfMinValue = tentNumberMinimumValue[tentNumberMinimumValue.length - 2];
  const lastDigitOfMinValue = tentNumberMinimumValue[tentNumberMinimumValue.length - 1];

  const secondDigitOfMaxValue = tentNumberMaximumValue[tentNumberMaximumValue.length - 2];
  const lastDigitOfMaxValue = tentNumberMaximumValue[tentNumberMaximumValue.length - 1];

  const zerosToCheck = '0'.repeat(maxValue.toString().length - 1);
  const minValueDigits = '0'.repeat(minValue.toString().length);

  if (currentValue.length === 0 && Number(keyValue) <= Number(firstDigitOfMinValue) && Number(currentValue + zerosToCheck) > maxValue) {
    return false;
  }

  if (tentNumberMinimumValue.length === 1) {
    if (currentValue.length === 0) {
      if (Number(keyValue) < Number(firstDigitOfMinValue) || Number(currentValue) === 1) {
        return Number(keyValue + zerosToCheck) <= maxValue && Number(keyValue + zerosToCheck) >= minValue;
      }
      return Number(keyValue) <= maxValue && Number(keyValue) >= minValue;
    }
    return Number(valueWithKey) <= maxValue && Number(valueWithKey) >= minValue;
  } else {
    if (currentValue.length === 0) {
      if (Number(keyValue) < Number(firstDigitOfMinValue) || Number(currentValue) === 1) {
        return (
          Number(keyValue + (tentNumberMinimumValue.length === 2 ? minValueDigits : secondDigitOfMaxValue + lastDigitOfMaxValue)) <=
            maxValue &&
          Number(keyValue + (tentNumberMinimumValue.length === 2 ? minValueDigits : secondDigitOfMinValue + lastDigitOfMinValue)) >=
            minValue
        );
      }
      return (
        Number(keyValue + (tentNumberMinimumValue.length === 2 ? lastDigitOfMaxValue : secondDigitOfMaxValue + lastDigitOfMaxValue)) <=
          maxValue &&
        Number(keyValue + (tentNumberMinimumValue.length === 2 ? lastDigitOfMinValue : secondDigitOfMinValue + lastDigitOfMinValue)) >=
          minValue
      );
    }
    if (currentValue.length === 1) {
      if (Number(currentValue) < Number(firstDigitOfMinValue)) {
        return (
          Number(
            currentValue + keyValue + (tentNumberMinimumValue.length === 2 ? '0'.repeat(minValueDigits.length - 1) : minValueDigits)
          ) <= maxValue &&
          Number(
            currentValue + keyValue + (tentNumberMinimumValue.length === 2 ? '0'.repeat(minValueDigits.length - 1) : minValueDigits)
          ) >= minValue
        );
      }
      return (
        Number(currentValue + keyValue + (tentNumberMinimumValue.length === 2 ? '' : lastDigitOfMaxValue)) <= maxValue &&
        Number(currentValue + keyValue + (tentNumberMinimumValue.length === 2 ? '' : lastDigitOfMinValue)) >= minValue
      );
    }
    if (currentValue.length > 1) {
      return Number(valueWithKey) <= maxValue && Number(valueWithKey) >= minValue;
    }
  }
  return false;
};
