import { Injectable } from '@angular/core';
import { DotButton } from 'dotsdk';
import { hasDlgMessages } from '@dotxix/helpers/dialog-message.helper';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Injectable({
  providedIn: 'root',
})
export class DlgMessageService {
  constructor(
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService
  ) {}

  public confirm(button: DotButton) {
    return new Promise((resolve) => {
      if (!hasDlgMessages(button)) {
        resolve(true);
        return;
      }
      const contentRef = this.dynamicContentService.openContent(ConfirmDialogComponent, {
        title: this.translationsService.getTranslatedDlgMessage(button),
        leftButtonText: this.translationsService.translate('23'),
        rightButtonText: this.translationsService.translate('28'),
      });
      contentRef.afterClosed.subscribe((response) => {
        resolve(response === ConfirmDialogButtons.RIGHT_BUTTON);
      });
    });
  }
}
