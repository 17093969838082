<div class="popup make-it-a-meal" data-automation="make-it-a-meal-popup">
  <div class="popup__content">
    <div class="popup__page-container make-it-a-meal__page-container">
      <h2 class="popup__title">{{ '2021040201' | translate }}</h2>
      <acr-scrollable-container>
        <div class="modifiers-grid make-it-a-meal__wrapper">
          <button
            class="product product--modifier make-it-a-meal__product"
            *ngFor="let infoButton of infoButtons"
            (click)="selectedMeal(infoButton.button)"
            data-automation="Make it a meal Button"
          >
            <img
              class="product__img make-it-a-meal__product--img"
              alt=""
              [src]="infoButton.button | catalogPicture"
              [class.logo]="!(infoButton.button | catalogPicture)"
            />

            <h4 class="product__title make-it-a-meal__product--title">{{ infoButton.button | translateCatalogTitle }}</h4>
            <h4 class="product__price">{{ infoButton.button | catalogButtonMinPrice }}</h4>
            <img
              *ngIf="infoButton.showInfoButton"
              skin-part="image"
              alt=""
              src="/Channels/skin/icon-info.svg"
              class="size--34 mr--10 make-it-a-meal__product--info"
              (click)="onProductInfoClick($event, infoButton.button)"
            />
          </button>
        </div>
      </acr-scrollable-container>
    </div>
    <footer class="popup__actions make-it-a-meal__actions">
      <button class="ui-button ui-button--primary ui-button--small" (click)="onNoThanksClicked()" data-automation="No thanks Button">
        <span class="ui-button__wrapper">
          <span>{{ '72' | translate }}</span>
        </span>
      </button>
    </footer>
  </div>
</div>
