import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { enter, leave } from '@dotxix/custom-theme/helpers/general-helpers.helper';
import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';
import { NotificationType } from '@dotxix/custom-theme/models/theme-editor-state.interface';
import { GeneratedThemeStateService } from '@dotxix/custom-theme/services/generated-theme-state.service';
import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'acr-edit-element-overlay',
  templateUrl: 'edit-element-overlay.component.html',
  styleUrls: ['edit-element-overlay.component.scss'],
  animations: [enter, leave],
})
export class EditElementOverlayComponent implements AfterViewInit, OnDestroy, OnInit {
  private availableVariablesToEdit$ = new BehaviorSubject<CssVariables>(
    this.themeEditorStateService.themeEditorState$.value.availableDefaultVariables
  );
  public availableVariablesToEditAsObservable$ = this.availableVariablesToEdit$.asObservable();
  private generatedThemeIdentifier!: string;

  private editElementClone!: Node;
  private subscriptions: Subscription[] = [];
  constructor(
    private themeEditorStateService: ThemeEditorStateService,
    private renderer: Renderer2,
    private generatedThemeStateService: GeneratedThemeStateService
  ) {}

  @ViewChild('divRef') divRef!: ElementRef;

  ngAfterViewInit(): void {
    if (this.divRef && this.editElementClone) {
      this.renderer.appendChild(this.divRef.nativeElement, this.editElementClone);
      this.renderer.setStyle(this.divRef.nativeElement.children[0], 'position', 'static');
      this.renderer.setStyle(this.divRef.nativeElement.children[0], 'transform', 'unset');
      const contentWidth = this.divRef.nativeElement.offsetWidth;
      const parentWidth = this.divRef.nativeElement.parentElement.offsetWidth;
      const scale = Math.min(parentWidth / contentWidth);
      this.renderer.setStyle(this.divRef.nativeElement, 'transform', `scale(${scale})`);
      this.renderer.setStyle(this.divRef.nativeElement, 'transform-origin', `top`);
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      // this.themeEditorStateService.editElementClone$.subscribe((node) => {
      //   this.editElementClone = node;
      // }),
      this.themeEditorStateService.generatedThemeIdentifier$.subscribe((identifier) => {
        const variables = this.generatedThemeStateService.generatedThemState$.value[identifier];
        this.generatedThemeIdentifier = identifier;
        if (variables) {
          const mergeDefaultValuesWithCustomAvailable = Object.keys(this.availableVariablesToEdit$.value).reduce(
            (acc, key) => ({ ...acc, [key]: variables[key] ? variables[key] : this.availableVariablesToEdit$.value[key] }),
            {} as CssVariables
          );
          this.availableVariablesToEdit$.next(mergeDefaultValuesWithCustomAvailable);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  public updateColor(variable: CssVariables) {
    this.generatedThemeStateService.updateGeneratedVariable(variable, this.generatedThemeIdentifier);
    this.themeEditorStateService.showToastVariablesChangedNotification(NotificationType.customthemingVariable);
    this.setInlineStyleToHost(this.divRef.nativeElement.children[0], this.renderer, variable);
  }

  public toggleEditElementOverlay() {
    this.themeEditorStateService.toggleEditElementOverlay();
  }

  // duplicate function need helper
  private setInlineStyleToHost(host: ElementRef, renderer: Renderer2, styles: CssVariables) {
    if (host && styles && renderer) {
      Object.keys(styles).forEach((key) => {
        renderer.setStyle(host, key, styles[key], RendererStyleFlags2.DashCase);
      });
    }
  }
}
