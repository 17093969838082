export enum RecoParameterKey {
  RecommendationEngine = 'Recommendation Engine',
  RecommendationMode = 'Recommendation Mode',
  RecommendationEnablePopular = 'Recommendation Enable Popular',
  RecommendationEnableCombo = 'Recommendation Enable Combo',
  RecommendationEnableSuggestives = 'Recommendation Enable Suggestives',
  RecommendationEnableUpgrade = 'Recommendation Enable Upgrade',
  RecommendationEnableBasket = 'Recommendation Enable Basket',
  RecommendationMinimumPrice = 'Recommendation Minimum Price',
}
