import { NgModule } from '@angular/core';
import { SkinDirective } from '@dotxix/skin/skin.directive';
import { SkinService } from '@dotxix/skin/skin.service';
import { SkinProviderService } from '@dotxix/skin/skin-provider.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SkinDirective],
  exports: [SkinDirective],
  providers: [SkinService, SkinProviderService],
  imports: [CommonModule, HttpClientModule],
})
export class SkinModule {}
