import { Injectable } from '@angular/core';
import { DotAvailabilityService, DotBanner, DotBannerSkinType, getBanners, waitUntilSessionIdle } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BehaviorSubject, interval } from 'rxjs';
import { ContentService } from '@dotxix/services/content.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BannersService {
  public banners$ = new BehaviorSubject<DotBanner[]>([]);
  public fullHdBanners$ = new BehaviorSubject<DotBanner[]>([]);
  public accessibilityBanners$ = new BehaviorSubject<DotBanner[]>([]);
  private availabilityService: DotAvailabilityService;
  private sourceBanners: DotBanner[] = [];
  private sourceFullHdBanners: DotBanner[] = [];
  private sourceAccessibilityBanners: DotBanner[] = [];

  constructor(
    private appSettings: ApplicationSettingsService,
    private contentService: ContentService
  ) {
    this.availabilityService = DotAvailabilityService.getInstance();
    this.updateBannersFromSource();
    this.contentService.dataChanged$.subscribe(() => {
      this.updateBannersFromSource();
    });
    this.startAutoCheck();
  }

  private startAutoCheck() {
    interval(5000)
      .pipe(waitUntilSessionIdle())
      .subscribe(() => this.checkUpdates());
  }

  private updateBannersFromSource() {
    this.sourceBanners = getBanners()
      .filter((banner) => banner.Active)
      .sort((b1, b2) => b1.Order - b2.Order);
    this.sourceFullHdBanners = this.sourceBanners.filter((b) => b.Skin === DotBannerSkinType.FULL_HD);
    this.sourceAccessibilityBanners = this.sourceBanners.filter(
      (b) => (b.Skin as string) === 'Accessibility' || (b.Skin as string) === 'Accesibility'
    );
    this.checkUpdates();
  }

  private checkUpdates() {
    const banners = this.sourceBanners.filter((b) => this.availabilityService.isBannerAvailable(b));
    if (!_.isEqual(this.banners$.value, banners)) {
      this.banners$.next(banners);
    }
    const fullHdBanners = this.sourceFullHdBanners.filter((b) => this.availabilityService.isBannerAvailable(b));
    if (!_.isEqual(this.fullHdBanners$.value, fullHdBanners)) {
      this.fullHdBanners$.next(fullHdBanners);
    }
    const accessibilityBanners = this.sourceAccessibilityBanners.filter((b) => this.availabilityService.isBannerAvailable(b));
    if (!_.isEqual(this.accessibilityBanners$.value, accessibilityBanners)) {
      this.accessibilityBanners$.next(accessibilityBanners);
    }
  }
}
