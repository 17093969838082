import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FaceRecallScreenSteps } from '@dotxix/osiris/models/enums/face-recall-screen-steps.enum';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';

@Component({
  selector: 'acr-face-recall',
  templateUrl: './face-recall.component.html',
  styleUrls: ['../face-recall-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaceRecallComponent {
  public screenSteps = FaceRecallScreenSteps;
  public faceRecall$ = this.osirisStateService.faceRecall$;

  constructor(private osirisStateService: OsirisStateService) {}

  public syncBulletsStepsWithFaceRecallSteps(faceRecallScreenSteps: FaceRecallScreenSteps) {
    switch (faceRecallScreenSteps) {
      case FaceRecallScreenSteps.CustomerLogin:
        return 1;
      case FaceRecallScreenSteps.LookAtCamera:
      case FaceRecallScreenSteps.ScanningFace:
        return 2;
      default:
        return 3;
    }
  }

  public hasBulletSteps(currentStep: FaceRecallScreenSteps) {
    const notAllowedSteps = [
      FaceRecallScreenSteps.MaxPinRetries,
      FaceRecallScreenSteps.QrCodeRegisterIdentity,
      FaceRecallScreenSteps.Error,
      FaceRecallScreenSteps.EnterPin,
    ];

    return !notAllowedSteps.includes(currentStep);
  }
}
