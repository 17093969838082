import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton, DotModifier } from 'dotsdk';

@Component({
  selector: 'acr-complement-modifier',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <language-change-view-trigger></language-change-view-trigger>
    <section class="flex flex--column">
      <acr-collapsible
        *ngIf="extraButtons.length > 0"
        [buttons]="extraButtons"
        [complementModifier]="complementModifier"
        [isExtraButton]="true"
        (modifierChanged)="onModifierChanged()"
      ></acr-collapsible>
      <acr-collapsible
        *ngIf="simpleButtons.length > 0"
        [buttons]="simpleButtons"
        [complementModifier]="complementModifier"
        [isExtraButton]="false"
        (modifierChanged)="onModifierChanged()"
      ></acr-collapsible>
    </section>`,
})
export class ComplementModifierComponent implements OnInit {
  @Input() complementModifier!: DotModifier;
  @Output() modifierChanged = new EventEmitter<void>();

  public simpleButtons!: DotButton[];
  public extraButtons!: DotButton[];

  public ngOnInit() {
    this.updateSimpleAndExtraButtonLists();
  }

  public onModifierChanged() {
    this.modifierChanged.emit();
    this.updateSimpleAndExtraButtonLists();
  }

  private updateSimpleAndExtraButtonLists() {
    this.simpleButtons = this.computeSimpleButtons();
    this.extraButtons = this.computeExtraButtons();
  }

  private computeSimpleButtons() {
    return this.complementModifier.Buttons.filter((btn) => !btn.Price || Number(btn.Price) === 0);
  }

  private computeExtraButtons() {
    return this.complementModifier.Buttons.filter((btn) => Number(btn.Price) > 0);
  }
}
