import { Pipe, PipeTransform } from '@angular/core';
import { DotPrefixId } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'prefixLabel',
})
export class PrefixLabelPipe implements PipeTransform {
  constructor(private translationsService: TranslationsService) {}

  public transform(prefixId: number | undefined) {
    switch (prefixId) {
      case DotPrefixId.NO:
        return this.translationsService.translate('2021060201');
      case DotPrefixId.EXTRA:
        return this.translationsService.translate('2021060202');
      default:
        return '';
    }
  }
}
