import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { AppBIService } from '../../../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-glory-cash-cancel-transaction-refund-success',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001033'"
      [amounts]="[{ label: '2001032', value: (paymentServiceState$ | async)!.cash.lastTransactionRefundedAmount }]"
    ></acr-glory-cash-message>

    <!-- Amount refunded: {lastTransactionRefundedAmount} -->

    <!-- Your refund was processed. |Please take your cash and proceed with your order. -->`,
})
export class GloryCashCancelTransactionRefundSuccessComponent implements OnInit {
  public paymentServiceState$ = this.paymentService.state$;
  constructor(
    private paymentService: PaymentService,
    private appBiService: AppBIService
  ) {}
  public ngOnInit() {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CASH_PAYMENT_CANCEL_TRANSACTION_REFUND_SUCCESS);
  }
}
