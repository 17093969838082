<div class="page-container osiris-login">
  <header class="page-header flex--1">
    <img src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <h2 class="page-header__title">{{ '2022050101' | translate }}</h2>
  <div class="osiris-login__osiris-options">
    <ng-container *ngFor="let osirisOption of availableOsirisLoginMethods">
      <button
        class="osiris-login__osiris-options--button"
        *ngIf="osirisOption.IsEnabled"
        (click)="methodSelection(osirisOption.Type)"
        [attr.data-automation]="'osiris-login-option--' + osirisOption?.Type"
      >
        <img class="osiris-login__osiris-options--img size--100" alt="" src="/Channels/skin/icon-{{ osirisOption.Image }}.svg" />
        <h3 *ngIf="osirisOption.Type !== 'facerecall'" class="osiris-login__osiris-options--title">{{ osirisOption.Name | translate }}</h3>
        <h3 *ngIf="osirisOption.Type === 'facerecall'" class="osiris-login__osiris-options--title">
          <span *ngFor="let word of getFaceRecallDisplayName(); let last = last" [class.color--green]="last">{{ word }}</span>
        </h3>
      </button>
    </ng-container>
    <button
      data-automation="continue-without-button"
      class="osiris-login__osiris-options--button"
      (click)="methodSelection('continue-without')"
    >
      <img class="osiris-options__img size--100" alt="" src="/Channels/skin/icon-authContinue.svg" acrRevertOnRtl />
      <h3 class="osiris-login__osiris-options--title">{{ '20210331004' | translate }}</h3>
    </button>
  </div>
  <acr-footer></acr-footer>
</div>
