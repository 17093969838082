<div class="popup info-dialog" data-automation="info-dialog-popup">
  <div class="popup__content info-dialog__content">
    <h3 class="popup__title info-dialog__title" [innerHTML]="dataParams!.title"></h3>
    <h3 class="popup__title info-dialog__title" *ngIf="dataParams!.button">{{ dataParams!.button | translateDlgMessageDictionary }}</h3>
    <div class="popup__actions info-dialog__actions" *ngIf="!dataParams!.hideFooterActions">
      <button
        class="ui-button ui-button--secondary ui-button--small info-dialog__actions--button"
        (click)="onControlsButtonsClick('Ok')"
        data-automation="Ok Button"
      >
        <span class="ui-button__wrapper">
          <span>{{ dataParams!.buttonText }}</span>
        </span>
      </button>
    </div>
  </div>
</div>
