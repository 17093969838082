import { Component } from '@angular/core';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { OsirisLoginScanService } from '@dotxix/osiris/services/osiris-login-scan.service';

@Component({
  selector: 'acr-osiris-dialog-id',
  templateUrl: './osiris-login-scan.component.html',
})
export class OsirisLoginScanComponent extends AbstractDynamicAnimatedPopup<void, void> {
  constructor(private osirisLoginScanService: OsirisLoginScanService) {
    super();
  }

  public cancelClick(): void {
    this.osirisLoginScanService.scanCanceled();
  }

  public barcodeChanged(scannedId: string) {
    this.osirisLoginScanService.validateBarcodeScan(scannedId).then();
  }
}
