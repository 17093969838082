import { Component } from '@angular/core';
import { GroupIdentifier } from '@dotxix/custom-theme/enums/variable-groups.enum';
import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';
import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';
import { VariableGroupsStateService } from '@dotxix/custom-theme/services/variable-groups-state.service';

@Component({
  selector: 'acr-variable-groups',
  templateUrl: 'variable-groups.component.html',
  styles: [
    `
      :host {
        cursor: pointer;
      }
    `,
  ],
})
export class VariableGroupsComponent {
  // public variableGroups$ = this.variableGroupsStateService.variableGroupsState$;
  public groupLabeling = {
    [GroupIdentifier.ui]: 'UI BUTTONS',
    [GroupIdentifier.icon]: 'ICONS',
    [GroupIdentifier.nav]: 'NAVIGATION',
    [GroupIdentifier.general]: 'GENERAL',
  };
  constructor(
    private variableGroupsStateService: VariableGroupsStateService,
    private themeEditorStateService: ThemeEditorStateService
  ) {}

  public updateColor(variable: CssVariables) {
    this.themeEditorStateService.updateGeneralThemingColor(variable);
  }
}
