import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton, DotPrefixes } from 'dotsdk';
import { getSelectedPrefixFromButton } from '@dotxix/helpers/prefixes.helper';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';
import { hasModifiersWithMenuModifierTemplate, isButtonDisabled } from '@dotxix/helpers/button.helper';
import { isAutoPopFeatVisible } from '@dotxix/helpers/auto-pop-feat.helper';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { visibleOnSize } from '@dotxix/helpers/combo-up-size.helper';
import { isAutoPopCombo } from '@dotxix/helpers/auto-pop-combo.helper';
import { ButtonState } from '@dotxix/models/interfaces/button-state';

@Component({
  selector: 'acr-product-card-details',
  templateUrl: './product-card-details.component.html',
})
export class ProductCardDetailsComponent implements OnInit {
  @Input() product!: DotButton;
  @Input() showItemChangeButton = false;
  @Input() set productItem(button: DotButton) {
    this.item = button;
    this.hasDefaultQuantity = !!button.DefaultQuantity && button.DefaultQuantity > 0 && button.DefaultQuantity <= button.MaxQuantity;
    this.prefix = getSelectedPrefixFromButton(button);
    this.prefixedModifierCaption = this.productTranslationsService.translatePrefixedModifierCaption(button);
    this.selectedModifiers = this.productTranslationsService.translateSelectedModifiers(button);

    if (!this.item.ModifiersPage) {
      if (this.item.state.hasIceModifierTemplate) {
        this.hasIceTags = this.item.Tags?.includes('withIce') || this.item.Tags?.includes('withoutIce');

        if (this.hasIceTags) {
          if (button.Tags.includes('withIce')) {
            this.iceLabel = this.item.state.hasOnlyOneIceButton
              ? this.item.quantity > 0
                ? '2022120501'
                : '2022120502'
              : this.item.quantity > 0
              ? '2022120501'
              : '';
          } else {
            this.iceLabel = this.item.state.hasOnlyOneIceButton
              ? this.item.quantity > 0
                ? '2022120502'
                : '2022120501'
              : this.item.quantity > 0
              ? '2022120502'
              : '';
          }
        }
      }
    }
  }
  @Output() customizeSingleProduct = new EventEmitter();

  public item!: DotButton<ButtonState>;
  public hasDefaultQuantity = false;
  public prefix: DotPrefixes | null = null;
  public prefixedModifierCaption = '';
  public selectedModifiers = '';
  public hasIceTags = false;
  public iceLabel = '';
  public showChangeButton = true;

  constructor(
    private productTranslationsService: ProductTranslationsService,
    private applicationSettingsService: ApplicationSettingsService
  ) {}

  ngOnInit(): void {
    this.computeChangeButton();
  }

  public onCustomizeProduct() {
    this.customizeSingleProduct.emit(this.item);
  }

  private computeChangeButton(): void {
    if (!this.product || !this.item) {
      return;
    }
    this.showChangeButton =
      isAutoPopCombo(this.product, false) &&
      this.item.quantity > 0 &&
      !this.hasDefaultQuantity &&
      !isButtonDisabled(this.item) &&
      this.isChangeItemButtonAvailable();
  }

  private isChangeItemButtonAvailable(): boolean {
    if (!this.product || !this.item) {
      return false;
    }
    let buttonAvailable = true;

    if (this.product.hasCombos) {
      this.product.ComboPage.Combos.find((combo) =>
        combo.Buttons.filter((button) => visibleOnSize(button, button.quantity) && this.item?.Link === button.Link).find((btn) => {
          const modifierAutoPopFeat = btn?.ModifiersPage?.Modifiers.every((modifier) => !isAutoPopFeatVisible(modifier, false, true));

          if ((combo.Buttons.length === 1 && !btn.hasModifiers) || modifierAutoPopFeat || isButtonDisabled(btn)) {
            buttonAvailable = false;
          }
        })
      );
    } else {
      const productAutoPopFeat = this.product.ModifiersPage?.Modifiers.find((modifier) =>
        modifier.Buttons.find((btn) => btn.Link === this.item?.Link)
      );
      if (
        !hasModifiersWithMenuModifierTemplate(this.product) &&
        productAutoPopFeat &&
        isAutoPopFeatVisible(productAutoPopFeat, false, true) &&
        !this.product.hasModifiers
      ) {
        buttonAvailable = false;
      } else {
        this.item.ModifiersPage?.Modifiers.find((modifier) =>
          modifier.Buttons.find((btn) => {
            if ((modifier.Buttons.length === 1 && !btn.hasModifiers) || isButtonDisabled(btn)) {
              buttonAvailable = false;
            }
          })
        );

        const isClassicMenu = this.product?.ModifiersPage?.Modifiers?.find((modifier) => modifier.modifierTemplate === 'classic');
        if (isClassicMenu) {
          buttonAvailable = false;
        }
      }
    }

    if (!this.applicationSettingsService.settings$.value.skipPrecalculate && !this.showItemChangeButton) {
      buttonAvailable = false;
    }
    return buttonAvailable;
  }
}
