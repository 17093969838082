import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppBIService } from '../../../business-intelligence/bi.service';
import { BIContexts } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-promos-scan-barcode',
  templateUrl: `promos-scan-barcode.component.html`,
})
export class PromosScanBarcodeComponent implements OnInit {
  @Output() public cancel = new EventEmitter<void>();

  constructor(private appBiService: AppBIService) {}

  public ngOnInit() {
    this.appBiService.actions.impressions.viewScan(BIContexts.Promotions);
  }
  public cancelClicked() {
    this.appBiService.actions.clicks.clickScanInputCancel(BIContexts.Promotions);
    this.cancel.next();
  }
}
