<div class="product product--modifier button-item" [class.selected]="selected" [class.disabled]="button | isButtonDisabled">
  <button (click)="onButtonClicked()" [attr.data-automation]="'Button: ' + button.Link" [disabled]="isDisabled" class="product__button">
    <header class="product__header">
      <h5 class="product__title">{{ button | translateCaption }}</h5>
      <span class="product__subtitle">
        <span *ngIf="displayPrice">
          <span *ngIf="displayPrefixPlusSignForPrice">+</span>
          {{ displayPrice }}
        </span>
      </span>
    </header>
    <img
      class="product__img"
      [class.logo]="!(button | translatePicture)"
      [src]="button | translatePicture"
      alt=""
      width="180rem"
      height="180rem"
    />
    <img
      skin-part="image"
      src="/Channels/skin/icon-pillCheckmark.svg"
      class="ui-button--icon modifier-checkmark size--56 button-item__modifier-checkmark"
      alt=""
    />
  </button>
  <acr-buttons-counter-edit
    *ngIf="showCounterEdit"
    [isDisabled]="isDisabled"
    [quantity]="quantity"
    [isIncreaseDisabled]="quantity >= maxQuantity || isIncreaseDisabled"
    [isDecreaseDisabled]="quantity <= 0"
    (quantityUpdate)="onQuantityUpdate($event)"
  ></acr-buttons-counter-edit>
  <ng-container *ngIf="button | isButtonDisabled">
    <div class="disabled--overlay">{{ '20210401001' | translate }}</div>
  </ng-container>
  <acr-item-pack-indicator
    *ngIf="
      !isComboSizeSelectionButton && (button.ButtonType === ButtonType.ITEM_PACK_BUTTON || button.ButtonType === ButtonType.PAGE_BUTTON)
    "
    [checked]="hasSelectedItems()"
  ></acr-item-pack-indicator>
  <acr-info-indicator *ngIf="showInfoButton" [button]="button"></acr-info-indicator>
</div>
