import { DotButton } from 'dotsdk';
import { isItemPackButton } from '@dotxix/helpers/button.helper';
import { visibleOnSize } from '@dotxix/helpers/combo-up-size.helper';
import { isButtonCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';

export const isItemPackSelected = (itemPackButton: DotButton): boolean => itemPackButton.Page.Buttons.some((x) => x.Selected);

export const computeItemPackSelectedQuantity = (button: DotButton) =>
  isItemPackButton(button) ? button.Page.Buttons.reduce((quantity, button) => quantity + button.quantity, 0) : 0;

export const computeProductStepItemPackVisibleComponents = (button: DotButton, size: string) =>
  button.Page.Buttons.filter((itemPackButton) => visibleOnSize(itemPackButton, size) && isButtonCatalogAvailable(itemPackButton));
