import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DotButton, DotPage } from 'dotsdk';

@Component({
  selector: 'acr-basket-suggestions',
  templateUrl: './basket-suggestions.component.html',
})
export class BasketSuggestionsComponent implements OnInit {
  @Output() public suggestionSelect = new EventEmitter<DotButton>();
  @Input() public basketSuggestionPages: DotPage[] = [];
  public firstSuggestionButton: DotButton | undefined;

  public ngOnInit() {
    this.firstSuggestionButton = this.basketSuggestionPages[0]?.Buttons[0];
  }

  public onSuggestionSelect(button: DotButton) {
    this.suggestionSelect.emit(button);
  }
}
