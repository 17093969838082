<div class="popup payment-retry" data-automation="payment-retry-popup">
  <div class="popup__content">
    <div class="flex-center flex--column payment-retry__wrapper">
      <h3 class="text-center payment-retry__wrapper--title">{{ '2001007' | translate }}</h3>
      <h1 class="big--heading color--accent m--40">{{ counter }}</h1>
      <h2 class="message">{{ '2001008' | translate }}</h2>
    </div>

    <div class="flex justify-between payment-retry__actions">
      <button
        class="ui-button ui-button--primary tertiary ui-button--small payment-retry__actions--button"
        (click)="onNoClicked()"
        data-automation="Retry Button: No"
      >
        <span class="ui-button__wrapper">
          <span>{{ '32' | translate }}</span>
        </span>
      </button>

      <button
        class="ui-button ui-button--primary ui-button--small payment-retry__actions--button"
        (click)="onYesClicked()"
        data-automation="Retry Button: Yes"
      >
        <span class="ui-button__wrapper">
          <span>{{ '33' | translate }}</span>
        </span>
      </button>
    </div>
  </div>
</div>
