<div class="osiris-order-card">
  <header class="osiris-order-card__header">
    <h5 class="transform--none">
      <span class="uppercase-first-letter">{{ '20220414002' | translate }}</span>
      <time>{{ order.d | date: ' yyyy-MM-dd ' }}{{ '20220414001' | translate }} {{ order.d | date: ' h:mm' }}</time>
    </h5>
    <h5>{{ extractServiceType(order.s) | translate }}</h5>
  </header>
  <section class="osiris-order-card__item" *ngFor="let product of productList.products">
    <img class="osiris-order-card__product-img" [src]="product.image" [class.logo]="!product.image" alt="" width="150rem" height="150rem" />
    <h4 class="osiris-order-card__product-title">
      <span>
        <span>
          <span>{{ product.quantity }}</span>
          <span>x</span>
          <span>&nbsp;</span>
        </span>
        {{ product.displayName }}
      </span>
    </h4>
  </section>

  <footer class="osiris-order-card__footer">
    <div>
      <ng-container *ngIf="productList?.extraItems && !productList.isListInvalid">
        <b class="fs--26">&#43;</b>
        <span>&nbsp;</span>
        <b class="fs--26">{{ productList.extraItems }}</b>
        <b class="fs--26 lowercase">
          <span>&nbsp;</span>
          {{ '20220415003' | translate }}
        </b>
      </ng-container>
      <ng-container *ngIf="productList.isListInvalid">
        <span class="fs--36 color--red">
          {{ '20220418005' | translate }}
        </span>
      </ng-container>
    </div>
    <h5>
      {{ '63' | translate }}:
      <span>{{ order.a ? (order.a | dotCurrency) : currency.concat('0.00') }}</span>
    </h5>
  </footer>
  <section class="osiris-order-card__actions" *ngIf="!productList.isListInvalid">
    <button class="osiris-order-card__button osiris-order-card__button--reorder" (click)="reorderClicked()">
      {{ '20220415001' | translate }}
    </button>
    <button class="osiris-order-card__button osiris-order-card__button--full-order" (click)="viewFullOrderClicked()">
      {{ '20220415002' | translate }}
    </button>
  </section>
</div>
