import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { Subject, Subscription } from 'rxjs';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { LocalizationService } from '@dotxix/services/localization.service';
import { PaymentRoutes } from '@dotxix/payment/payment-routes';

@Injectable({
  providedIn: 'root',
})
export class GloryCashLegalRequirementsService {
  public approve$ = new Subject<void>();
  public finishWithOtherMethod$ = new Subject<void>();
  constructor(
    private router: Router,
    private checkoutService: CheckoutService,
    private applicationSettingsService: ApplicationSettingsService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private localizationService: LocalizationService
  ) {}

  public approve() {
    this.checkoutService.gloryLegalRequirementAccepted = true;
    this.approve$.next();
  }
  public finishWithOtherMethod() {
    this.finishWithOtherMethod$.next();
  }

  public async approveOrderTotal() {
    if (
      !this.checkoutService.gloryLegalRequirementAccepted &&
      this.checkoutService.orderTotal > this.applicationSettingsService.settings$.value.gloryPayableAmount
    ) {
      this.router.navigate([PaymentRoutes.GloryLegalRequirements]);
      return await this.getCustomerAnswer();
    }
    return true;
  }

  public showUnableToCheckout() {
    const formattedLimit = this.localizationService.formatCurrency(this.applicationSettingsService.settings$.value.gloryPayableAmount);
    this.dynamicContentService.openContent(InfoDialogComponent, {
      title: `${this.translationsService.translate('2001015')}<br/><br/>${this.translationsService
        .translate('2001012')
        .replace(/%%%/, formattedLimit)}`,
      buttonText: this.translationsService.translate('2001014'),
    });
  }

  private getCustomerAnswer() {
    const subscriptions = new Subscription();
    return new Promise<boolean>((resolve) => {
      subscriptions.add(
        this.approve$.subscribe(() => {
          subscriptions.unsubscribe();
          resolve(true);
        })
      );
      subscriptions.add(
        this.finishWithOtherMethod$.subscribe(() => {
          subscriptions.unsubscribe();
          resolve(false);
        })
      );
    });
  }
}
