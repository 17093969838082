import { Component, Input, OnInit } from '@angular/core';
import { ButtonInfoService } from '@dotxix/button-info.service';
import { ProductStepperHeaderImage } from '@dotxix/models/interfaces/product-stepper-header-image';
import { DotButton } from 'dotsdk';

@Component({
  selector: 'acr-product-stepper-header',
  templateUrl: './product-stepper-header.component.html',
})
export class ProductStepperHeaderComponent implements OnInit {
  @Input() productTitle = '';
  @Input() productSubTitle = '';
  @Input() button!: DotButton;
  @Input() set images(imagePaths: string[]) {
    if (Array.isArray(imagePaths)) {
      const imagePathsLength = imagePaths.length;
      this.displayImages = imagePaths.map((imagePath, index) => ({
        src: imagePath,
        zIndex: imagePathsLength - index,
        isNewAddition: this.displayImages[index] === undefined ? true : this.displayImages[index].src !== imagePath,
        ...this.computeImageLeftOrRightOffset(index),
      }));
    }
  }

  constructor(private buttonInfoService: ButtonInfoService) {}

  public displayImages: ProductStepperHeaderImage[] = [];
  public showInfoButton!: boolean;

  private standardPosition = 260;

  public ngOnInit() {
    this.showInfoButton = this.buttonInfoService.showProductInfo(this.button);
  }

  /**
   * will move images from list slightly off to left or right with inline styles
   */
  private computeImageLeftOrRightOffset(index: number) {
    if (index === 0) {
      return {};
    }
    // reset to lower number when over 11 products **will work up to 18
    const decreasePosition = this.standardPosition - (index >= 11 ? Math.round(index / 3) : index) * 40;
    return index % 2 === 0 ? { left: `${decreasePosition}rem` } : { right: `${decreasePosition}rem` };
  }
}
