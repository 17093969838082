<section class="flex flex--column flex--1 justify-evenly text-center">
  <div class="glory-cash-info">
    <h2 class="mb--20">{{ '2001017' | translate }}</h2>
    <span class="glory-cash-info__message">
      <span class="glory-cash-info__text">{{ '2001018' | translate }}</span>
      <span class="glory-cash-info__price">
        {{ (paymentServiceState$ | async)!.cash.maximumAmountThatCanBePaidAtTransactionStart | dotCurrency: true }}
      </span>
    </span>
    <span class="glory-cash-info__message">
      <span class="glory-cash-info__text">{{ '2001019' | translate }}</span>
      <span class="glory-cash-info__price">
        {{ (paymentServiceState$ | async)!.cash.currentTransactionPaidAmount | dotCurrency: true }}
      </span>
    </span>
    <span class="glory-cash-info__message">
      <span class="glory-cash-info__text">{{ '2001020' | translate }}</span>
      <span class="glory-cash-info__price">{{ (amountDue$ | async)! | dotCurrency: true }}</span>
    </span>
  </div>

  <div>
    <video class="glory-video" autoplay muted loop src="/Channels/skin/short_kiosk_video.webm"></video>
  </div>

  <div class="flex flex--column place-center justify-center">
    <p class="fs--30 m--10 font--secondary text-center" [innerHTML]="'2001021' | translate"></p>
  </div>
</section>

<footer class="page-footer flex place-center justify-between">
  <button
    class="ui-button ui-button--primary tertiary ui-button--medium"
    data-automation="Finish With Other Payment Method"
    (click)="finishWithOtherPayment()"
    [disabled]="cashEndAcceptMoneyOngoing$ | async"
  >
    <span class="fs--24">{{ '2001011' | translate }}</span>
  </button>
  <button
    class="ui-button ui-button--secondary ui-button--medium"
    data-automation="Cancel"
    (click)="cancelClick()"
    [disabled]="cashEndAcceptMoneyOngoing$ | async"
  >
    <span>{{ '2001022' | translate }}</span>
  </button>
</footer>
