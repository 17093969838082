import { DotButton, DotModifier } from 'dotsdk';
import { AutoPopFeatMode } from '@dotxix/models/enums/auto-pop-feat';

export function isAutoPopFeatVisible(modifier: DotModifier, onOrder: boolean, isItem: boolean): boolean {
  const buttonsWithIncludedQuantity = modifier.Buttons.filter((button) => button.IncludedQuantity > 0)?.length;

  switch (modifier.PageInfo.AutoPopFeat) {
    case AutoPopFeatMode.NOT_POP_ON_ORDER_POP_ON_MODIFY:
      return !onOrder;
    case AutoPopFeatMode.POP_ON_ORDER_POP_ON_MODIFY:
    case AutoPopFeatMode.POP_ON_ORDER_TAKE_DEFAULTS_POP_ON_MODIFY:
      return true;
    case AutoPopFeatMode.POP_ON_ORDER_NOT_POP_ON_MODIFY:
      return onOrder;
    case AutoPopFeatMode.NOT_POP_ON_ORDER_NOT_POP_ON_MODIFY:
      return false;
    case AutoPopFeatMode.POP_ON_ORDER_ITEMS_NOT_POP_ON_ORDER_COMBOS_POP_ON_MODIFY:
      return !(onOrder && !isItem);
    case AutoPopFeatMode.POP_ON_COMBOS_NOT_POP_ON_ITEMS:
      return !isItem;
    case AutoPopFeatMode.NOT_POP_ON_ORDER_TAKE_DEFAULTS_POP_ON_MODIFY:
      return modifier.PageInfo.MaxQuantity >= buttonsWithIncludedQuantity && !onOrder;
    case AutoPopFeatMode.POP_ON_ORDER_TAKE_DEFAULTS_NOT_POP_ON_MODIFY:
      return modifier.PageInfo.MaxQuantity >= buttonsWithIncludedQuantity && onOrder;
    case AutoPopFeatMode.NOT_POP_ON_ORDER_TAKE_DEFAULTS_NOT_POP_ON_MODIFY:
      return modifier.PageInfo.MaxQuantity <= buttonsWithIncludedQuantity;
  }
  return !onOrder;
}

export const canChangeModifiersFromBasket = (button: DotButton): boolean =>
  button.hasModifiers && button.ModifiersPage.Modifiers.some((modifierButton) => isAutoPopFeatVisible(modifierButton, false, true));

export const getModifiersWithEditableButtons = (modifiers: DotModifier[], isUpdate: boolean, isItemInCombo: boolean) =>
  modifiers.filter((modifier) => isAutoPopFeatVisible(modifier, !isUpdate, !isItemInCombo));
