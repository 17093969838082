import { Component, OnDestroy } from '@angular/core';
import { GetFaceRecallImageService } from '@dotxix/osiris/services/face-recall-get-image.service';

@Component({
  selector: 'acr-scanning-face',
  templateUrl: './scanning-face.component.html',
  host: { class: 'full-height full-width' },
})
export class ScanningFaceComponent implements OnDestroy {
  public base64Image$ = this.getFaceRecallImageService.base64Image$;

  constructor(private getFaceRecallImageService: GetFaceRecallImageService) {}

  ngOnDestroy(): void {
    this.getFaceRecallImageService.resetFaceRecallImageState();
  }
}
