import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { GloryCashFlowService } from '@dotxix/payment/services/glory-cash-flow.service';
import { AppBIService } from '../../../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-glory-cash-payment-progress',
  templateUrl: './glory-cash-payment-progress.component.html',
  host: { class: 'flex flex--column flex--1' },
})
export class GloryCashPaymentProgressComponent implements OnInit {
  public paymentServiceState$ = this.paymentService.state$;
  public cashEndAcceptMoneyOngoing$ = this.paymentServiceState$.pipe(map((state) => state.cash.endAcceptMoneyOngoing));
  public amountDue$ = this.paymentServiceState$.pipe(
    map((state) => state.cash.maximumAmountThatCanBePaidAtTransactionStart - state.cash.currentTransactionPaidAmount)
  );

  constructor(
    private paymentService: PaymentService,
    private gloryCashFlowService: GloryCashFlowService,
    private appBiService: AppBIService
  ) {}

  public ngOnInit() {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CASH_PAYMENT_PROGRESS);
  }

  public cancelClick() {
    this.appBiService.actions.clicks.clickedCancelPayment();
    this.gloryCashFlowService.cancelCurrentCashCurrentTransaction();
  }

  public async finishWithOtherPayment() {
    this.appBiService.actions.clicks.clickedOtherPaymentIntention();
    this.gloryCashFlowService.finishWithOtherPayment();
  }
}
