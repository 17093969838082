<div class="container">
  <!-- <h1 class="title">edit category: {{ elementCssVariableIdentifier$ | async }}</h1> -->
  <h3 class="title">Edit Component</h3>
  <div class="content">
    <div class="variables-container">
      <acr-edit-variable
        *ngFor="let variable of availableVariablesToEditAsObservable$ | async | keyvalue"
        [cssVariable]="variable"
        (pickedColor)="updateColor($event)"
      ></acr-edit-variable>
    </div>
    <div class="div-ref" #divRef></div>
  </div>
  <!-- <button class="close-button" (click)="saveAndClose()">save and close</button> -->
  <button (click)="toggleEditElementOverlay()" class="close-button">close ✖</button>
</div>
