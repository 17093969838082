<main class="page-container flex justify-center place-center gap--80">
  <ng-container [ngSwitch]="qrCodeScreenStep$ | async">
    <ng-container *ngSwitchCase="qrCodeScreenSteps.ConnectingToPayment">
      <section class="epay__scanning">
        <header class="epay__messages epay__messages--top">
          <p class="epay__title" [innerHtml]="'2023020101' | translate"></p>
        </header>
        <section class="epay__container">
          <img class="epay__qr-code-animation" src="/Channels/skin/qr-code-animation.gif" alt="" />
        </section>
      </section>
    </ng-container>
    <ng-container *ngSwitchCase="qrCodeScreenSteps.ShowingQrCode">
      <section class="epay__scanning">
        <header class="epay__messages epay__messages--top">
          <p class="epay__title" [innerHtml]="'2023020102' | translate"></p>
        </header>
        <section class="epay__container">
          <qrcode [qrdata]="(electronicPayment$ | async)!.QRData" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
        </section>
        <section *ngIf="canceling$ | async" class="epay__container">
          <p class="epay__title" [innerHtml]="'2023020301' | translate"></p>
        </section>
        <section *ngIf="autoCancelCounter$ | async as autoCancelCounter">
          <h1 class="big--heading color--accent m--40 text-center">{{ autoCancelCounter }}</h1>
        </section>
      </section>
      <footer *ngIf="(electronicPayment$ | async)!.IsCancelAvailable" class="page-footer flex justify-center place-center">
        <button
          class="ui-button ui-button--primary ui-button--x-large ui-button--tertiary"
          [disabled]="canceling$ | async"
          (click)="cancelClick()"
        >
          {{ '23' | translate }}
        </button>
      </footer>
    </ng-container>
  </ng-container>
</main>
