import { BannersComponent } from '@dotxix/pages/banners/banners.component';
import { CheckoutErrorComponent } from '@dotxix/pages/checkout/checkout-error/checkout-error.component';
import { CodViewComponent } from '@dotxix/pages/checkout/cod-view/cod-view.component';
import { CommonModule } from '@angular/common';
import { ComponentsModules } from '@dotxix/components/components.module';
import { MenuComponent } from '@dotxix/pages/menu/menu.component';
import { NgModule } from '@angular/core';
import { OrderNumberComponent } from '@dotxix/pages/checkout/order-number/order-number.component';
import { PipesModule } from '@dotxix/pipes/pipes.modules';
import { PromosComponent } from '@dotxix/pages/promotions/promos.component';
import { ServiceTypeComponent } from '@dotxix/pages/service-type/service-type.component';
import { BridgeOperationComponent } from '@dotxix/pages/checkout/bridge-operation/bridge-operation.component';
import { OsirisModule } from '@dotxix/osiris/osiris.module';
import { CustomThemeModule } from '@dotxix/custom-theme/custom-theme.module';
import { FacePayprogressComponent } from '@dotxix/pages/face-pay/face-pay-progress.component';
import { SkinModule } from '@dotxix/skin/skin.module';
import { ReceiptOptionsComponent } from '@dotxix/pages/checkout/receipt/receipt-options/receipt-options.component';
import { ElectronicReceiptPageComponent } from '@dotxix/pages/checkout/receipt/electronic-receipt/electronic-receipt-page.component';
import { SmsReceiptPageComponent } from '@dotxix/pages/checkout/receipt/sms-receipt/sms-receipt-page.component';
import { EmailReceiptPageComponent } from '@dotxix/pages/checkout/receipt/email-receipt/email-receipt-page.component';
import { PayTowerStartPageComponent } from '@dotxix/pages/pay-tower-start-screen/pay-tower-start-page.component';
import { AppBIModule } from '../business-intelligence/bi.module';

export const PAGES = [
  BannersComponent,
  ServiceTypeComponent,
  MenuComponent,
  CheckoutErrorComponent,
  OrderNumberComponent,
  CodViewComponent,
  PromosComponent,
  BridgeOperationComponent,
  FacePayprogressComponent,
  ReceiptOptionsComponent,
  ElectronicReceiptPageComponent,
  SmsReceiptPageComponent,
  EmailReceiptPageComponent,
  PayTowerStartPageComponent,
];

@NgModule({
  imports: [CommonModule, ComponentsModules, PipesModule, SkinModule, OsirisModule, CustomThemeModule, AppBIModule],
  declarations: [PAGES],
  exports: [PAGES],
})
export class PagesModule {}
