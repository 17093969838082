import { DotButton, DotPage, DotSuggestionSalesService } from 'dotsdk';
import { addRecommendationModeForPages } from '@dotxix/services/suggestions/helpers/recommendation-view.helper';
import { ButtonRecommendationMode } from '@dotxix/services/suggestions/models/button-recommendation-mode';

export const getKbForcedItemSuggestions = (button: DotButton): DotPage[] =>
  getKbItemSuggestions(button).filter((page) => isForceKbSuggestion(page));

export const getKbForcedOrderSuggestions = (): DotPage[] => getKbOrderSuggestions().filter((page) => isForceKbSuggestion(page));

export const isForceKbSuggestion = (page: DotPage): boolean =>
  typeof page.Tags === 'string' && page.Tags.split(',').contains('force-kb-suggestions');

export const getKbItemSuggestions = (button: DotButton): DotPage[] => {
  let resultPages: DotPage[] | null = DotSuggestionSalesService.getInstance().getButtonSuggestion(button);

  if (resultPages) {
    resultPages = addRecommendationModeForPages(resultPages, ButtonRecommendationMode.KIOSK_BUILDER);
    return resultPages;
  }
  return [];
};

export const getKbOrderSuggestions = (): DotPage[] => {
  let resultPages: DotPage[] = DotSuggestionSalesService.getInstance().getOrderSuggestions();

  if (resultPages) {
    resultPages = addRecommendationModeForPages(resultPages, ButtonRecommendationMode.KIOSK_BUILDER);
    return resultPages;
  }
  return [];
};
