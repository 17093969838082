import { Component, OnInit } from '@angular/core';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { price } from '@dotxix/helpers/price.helper';
import { SessionService } from '@dotxix/services/session.service';
import { DotAllergen, DotButton, DotIngredient, DotNutritionalValue } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { CALORIES_KEY } from '@dotxix/helpers/callories.helper';
import { AppBIService } from '../../business-intelligence/bi.service';

enum InfoSteps {
  nutritionView = 'nutrition-view',
  ingredientsView = 'ingredients-view',
  allergensView = 'allergens-view',
}

@Component({
  selector: 'acr-product-info',
  templateUrl: './product-info.component.html',
})
export class ProductInfoComponent extends AbstractDynamicAnimatedPopup<{ button: DotButton }> implements OnInit {
  public button!: DotButton;
  public price!: number;
  public calories: DotNutritionalValue | null = null;
  public description!: string;
  public ingredients!: DotIngredient[];
  public allergens!: DotAllergen[];
  public nutritionalFacts!: DotNutritionalValue[];
  public infoSteps = InfoSteps;
  public currentStep!: InfoSteps;

  constructor(
    private sessionService: SessionService,
    private translationsService: TranslationsService,
    private appBiService: AppBIService
  ) {
    super();
  }

  ngOnInit(): void {
    this.button = this.dataParams!.button;
    this.price = this.button ? price(this.button, this.sessionService.serviceType) : 0;
    this.description = this.button ? this.translationsService.getTranslatedButtonDescription(this.button) || '' : '';
    this.ingredients = this.button?.AllergensAndNutritionalValues?.Ingredients ?? [];
    this.allergens = this.button?.AllergensAndNutritionalValues?.Allergens ?? [];
    this.calories = (this.button?.AllergensAndNutritionalValues?.NutritionalValues ?? []).find((n) => n.Name === CALORIES_KEY) ?? null;
    this.nutritionalFacts = (this.button?.AllergensAndNutritionalValues?.NutritionalValues ?? [])?.filter((n) => n.Name !== CALORIES_KEY);
    this.currentStep = this.initializeDefaultOpenedTab()!;

    this.appBiService.actions.impressions.viewProductInfoCategories(
      this.getTabsConfig()
        .filter((tab) => tab.value)
        .map((tab) => tab.step)
    );
  }

  public closeClick() {
    this.close();
  }

  private getTabsConfig() {
    return [
      {
        step: this.infoSteps.nutritionView,
        value: this.nutritionalFacts?.length,
      },
      {
        step: this.infoSteps.ingredientsView,
        value: this.ingredients?.length,
      },
      {
        step: this.infoSteps.allergensView,
        value: this.allergens?.length,
      },
    ];
  }

  private initializeDefaultOpenedTab() {
    return this.getTabsConfig().find((step) => step.value && step.value > 0)?.step;
  }

  public isAtLeastOneTabAvailable(): boolean {
    return !!this.nutritionalFacts?.length || !!this.allergens?.length || !!this.ingredients?.length;
  }

  public changeCurrentStep(step: InfoSteps) {
    this.currentStep = step;
    this.appBiService.actions.clicks.clickedProductInfoCategory(this.button, step);
  }
}
