import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { AtpSaveFileResponse } from '@dotxix/custom-theme/enums/atp-save-file.enum';
import { DELAY_TO_RESET_MESSAGE, extractToKeyValue } from '@dotxix/custom-theme/helpers/general-helpers.helper';
import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';
import { NotificationType, ThemeEditorState } from '@dotxix/custom-theme/models/theme-editor-state.interface';
import { CssVariablesInjectService } from '@dotxix/custom-theme/services/css-variables-inject.service';

const initialState: ThemeEditorState = {
  generalThemingVariables: {},
  availableDefaultVariables: {},
  editorOpenClose: false,
  changesSent: null,
  showEditElementOverlay: false,
  generatedThemeIdentifier: '',
  editElementClone: null,
  changesMadeNotification: { showNotification: false, completedTypes: [] },
};

@Injectable({ providedIn: 'root' })
export class ThemeEditorStateService {
  public themeEditorState$ = new BehaviorSubject<ThemeEditorState>(initialState);
  public generalThemingVariables$ = this.themeEditorState$.pipe(map((state) => state.generalThemingVariables));
  public availableDefaultVariables$ = this.themeEditorState$.pipe(map((state) => state.availableDefaultVariables));
  public generatedThemeIdentifier$ = this.themeEditorState$.pipe(map((state) => state.generatedThemeIdentifier));
  public cssVariablesUpdatesWereMade$ = this.themeEditorState$.pipe(map((state) => state.changesMadeNotification.showNotification));
  public showSaveButton$ = this.themeEditorState$.pipe(map((state) => state.changesMadeNotification.completedTypes.length));
  public editorOpenClose$ = this.themeEditorState$.pipe(map((state) => state.editorOpenClose));
  public showEditElementOverlay$ = this.themeEditorState$.pipe(map((state) => state.showEditElementOverlay));
  public editElementClone$ = this.themeEditorState$.pipe(map((state) => state.editElementClone));
  public changesSent$ = this.themeEditorState$.pipe(map((state) => state.changesSent));

  constructor(private cssVariablesInjectService: CssVariablesInjectService) {
    // this.cssVariablesInjectService.atpLoadedCssVariables$.subscribe((value) => {
    //   if (value) {
    //     this.themeEditorState$.next({
    //       ...this.themeEditorState$.value,
    //       generalThemingVariables: value.generalTheming,
    //       availableDefaultVariables: value.availableDefaults,
    //     });
    //   }
    // });
  }

  public resetGeneralThemingVariables() {
    const generalThemingVariables = Object.keys(this.themeEditorState$.value.generalThemingVariables).reduce((acc, key) => {
      document.documentElement.style.removeProperty(key);
      const defaultCssValue = getComputedStyle(document.documentElement).getPropertyValue(key);
      document.documentElement.style.setProperty(key, defaultCssValue);
      return { ...acc, [`${key}`]: defaultCssValue };
    }, {} as CssVariables);

    this.themeEditorState$.next({ ...this.themeEditorState$.value, generalThemingVariables });
  }

  public toggleThemeEditor() {
    this.themeEditorState$.next({
      ...this.themeEditorState$.value,
      editorOpenClose: !this.themeEditorState$.value.editorOpenClose,
      showEditElementOverlay: false,
    });
  }

  public setGeneratedThemeIdentifier(generatedThemeIdentifier: string) {
    this.themeEditorState$.next({
      ...this.themeEditorState$.value,
      generatedThemeIdentifier,
    });
  }

  public setEditElementClone(editElementClone: Node) {
    this.themeEditorState$.next({
      ...this.themeEditorState$.value,
      editElementClone,
    });
  }

  public toggleEditElementOverlay() {
    this.themeEditorState$.next({
      ...this.themeEditorState$.value,
      showEditElementOverlay: !this.themeEditorState$.value.showEditElementOverlay,
      editorOpenClose: false,
    });
  }

  public updateGeneralThemingColor(cssVariable: CssVariables) {
    const { key, value } = extractToKeyValue(cssVariable);
    this.themeEditorState$.next({
      ...this.themeEditorState$.value,
      generalThemingVariables: { ...this.themeEditorState$.value.generalThemingVariables, [`${key}`]: value },
    });
    this.injectUpdatedCssValueInDom(key, value);
    this.showToastVariablesChangedNotification(NotificationType.generalThemingVariable);
  }

  public changesSent(response: AtpSaveFileResponse) {
    this.themeEditorState$.next({ ...this.themeEditorState$.value, changesSent: response });
    setTimeout(() => {
      this.themeEditorState$.next({ ...this.themeEditorState$.value, changesSent: null });
    }, DELAY_TO_RESET_MESSAGE);
  }

  public showToastVariablesChangedNotification(notificationType: NotificationType) {
    const isNotificationBeenCalled = this.themeEditorState$.value.changesMadeNotification.completedTypes.find(
      (f) => f === notificationType
    );
    if (isNotificationBeenCalled) {
      return;
    }
    this.themeEditorState$.next({
      ...this.themeEditorState$.value,
      changesMadeNotification: {
        showNotification: true,
        completedTypes: [...this.themeEditorState$.value.changesMadeNotification.completedTypes, notificationType],
      },
    });
    setTimeout(() => {
      this.themeEditorState$.next({
        ...this.themeEditorState$.value,
        changesMadeNotification: {
          ...this.themeEditorState$.value.changesMadeNotification,
          showNotification: false,
        },
      });
    }, DELAY_TO_RESET_MESSAGE);
  }

  private injectUpdatedCssValueInDom(cssVariableName: string, cssVariableValue: string) {
    document.documentElement.style.setProperty(cssVariableName, cssVariableValue);
  }
}
