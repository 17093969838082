import { ComponentRef } from '@angular/core';

import { Subject } from 'rxjs';
import { AbstractDynamicComponent } from '@dotxix/services/dynamic-content/models/abstract-dynamic.component';
import { POP_UP_ANIMATION_TIME } from '@dotxix/animation/animation';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';

export class DynamicContentRef<DTA, RTN> {
  public afterClosed = new Subject<RTN | undefined>();
  public beforeClose = new Subject<void>();

  constructor(private componentRef: ComponentRef<AbstractDynamicComponent<DTA, RTN>>) {
    componentRef.instance.close$.subscribe((result) => {
      this.close(result);
    });
  }

  public close(result: RTN | undefined = undefined) {
    this.beforeClose.next();
    this.beforeClose.complete();
    this.componentRef.instance.close$.complete();
    this.componentRef.destroy();
    if (this.componentRef.instance instanceof AbstractDynamicAnimatedPopup) {
      setTimeout(() => this.emitAfterClosed(result), POP_UP_ANIMATION_TIME);
    } else {
      this.emitAfterClosed(result);
    }
  }

  private emitAfterClosed(result: RTN | undefined) {
    this.afterClosed.next(result);
    this.afterClosed.complete();
  }
}
