<language-change-view-trigger></language-change-view-trigger>
<div class="buttons-counter-edit">
  <div class="buttons-counter-edit__actions" (click)="stopEvent($event)">
    <button
      class="ui-button ui-button--icon-button buttons-counter-edit__actions--button"
      (click)="changeQuantity(-1)"
      [disabled]="isDisabled || isDecreaseDisabled"
      data-automation="Quantity Decrease Button"
    >
      <img skin-part="image" src="/Channels/skin/icon-outlineMinus.svg" class="ui-button--icon iconQuantityColor size--52" alt="" />
    </button>
    <h3 class="buttons-counter-edit__actions--amount" [class.color--red]="isIncreaseDisabled" [class.disabled]="isDisabled">
      <span *ngIf="!hideQuantityAmount" data-automation="amount">
        {{ quantity }}
      </span>
    </h3>

    <button
      class="ui-button ui-button--icon-button justify-center buttons-counter-edit__actions--button"
      (click)="changeQuantity(1)"
      [disabled]="isDisabled || isIncreaseDisabled"
      data-automation="Quantity Increase Button"
    >
      <img
        skin-part="image"
        src="/Channels/skin/icon-outlinePlus.svg"
        class="ui-button--icon iconQuantityColor size--52 buttons-counter-edit__actions--plus"
        alt=""
      />
    </button>
  </div>

  <div *ngIf="label" class="quantity-info__total text-center">
    <h4 class="font--secondary">
      {{ label }}
    </h4>
  </div>
</div>
