export enum ReplaceTextType {
  TEXT = 'text',
  IMAGE = 'image',
}

export interface ReplaceReceiptText {
  input: string;
  type: ReplaceTextType;
  value: string;
}
