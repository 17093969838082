import {
  calculateButtonPrice,
  DotButton,
  DotButtonType,
  DotModifiersPage,
  DotPage,
  DotSessionService,
  PosServingLocation,
  PromoNode,
} from 'dotsdk';

import { ButtonState } from '@dotxix/models/interfaces/button-state';
import * as _ from 'lodash';
import { filterCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { hasForcedPrice } from './price.helper';
import { PromoDiscountsMode } from '../models/enums/promos';

export const computePageButtons = (buttons: DotButton[], selectedButtons: DotButton[]) => {
  buttons = _.cloneDeep(filterCatalogAvailable(buttons));
  if (selectedButtons.length === 0) {
    return buttons;
  }

  return buttons.map((button) => {
    if (selectedButtons.length === 0) {
      return button;
    }

    const selectionIndex = selectedButtons.findIndex((selectedButton) => selectedButton.Link === button.Link);
    if (selectionIndex !== -1) {
      return selectedButtons.splice(selectionIndex, 1)[0];
    }
    return button;
  });
};

const countPromoSteps = (promoNode: PromoNode): number => {
  return 1 + (promoNode.next ? countPromoSteps(promoNode.next) : 0);
};

export const computeStepperSteps = (firstPromoNode: PromoNode, name: string) => Array(countPromoSteps(firstPromoNode)).fill({ name });

export const computePromotionPageMaxQuantity = (page: DotPage) => {
  if (!page.MaxQty || page.MaxQty === 0) {
    return 1;
  } else {
    return page.MaxQty;
  }
};

export const computeSelectedQuantity = (buttons: DotButton[]) => buttons.reduce((acc, button) => acc + (button.quantity || 0), 0);

export const deselectPromoButton = (buttons: DotButton[], originalButtons: DotButton[], index: number) => {
  if (buttons[index] && originalButtons[index]) {
    buttons.splice(index, 1, originalButtons[index]);
  }
};

export const selectPromoButton = (button: DotButton, quantity = 1) => {
  button.Selected = true;
  button.quantity = quantity;
};

export const deselectAllPromoButtons = (buttons: DotButton[], originalButtons: DotButton[]) =>
  buttons.forEach((button, index) => {
    if (button.quantity > 0) {
      deselectPromoButton(buttons, originalButtons, index);
    }
  });

export const selectedPromotionProducts = (promoNode: PromoNode): DotButton[] => {
  return [...promoNode.previousSelections, ...(promoNode.next ? selectedPromotionProducts(promoNode.next) : [])];
};

export const selectedPromotionButtons = (promoNode: PromoNode): DotButton[] => {
  return [
    ...getSelectedPromotionButtonsFromPage(promoNode.getData(promoNode.previousSelections)),
    ...(promoNode.next ? selectedPromotionButtons(promoNode.next) : []),
  ];
};

export const getSelectedPromotionButtonsFromPage = (page: DotPage): DotButton[] => {
  return page.Buttons.reduce((acc, currentButton) => {
    if (currentButton.ButtonType === DotButtonType.PAGE_BUTTON || currentButton.ButtonType === DotButtonType.ITEM_PACK_BUTTON) {
      return [...acc, ...getSelectedPromotionButtonsFromPage(currentButton.Page)];
    }
    if (currentButton.quantity > 0) {
      return [...acc, currentButton];
    }
    return acc;
  }, [] as DotButton[]);
};

const mutateButtonPriceForFullDiscount = (button: DotButton<ButtonState>) => {
  button.ForcePriceIN = button.state.$$initialForcePriceIn!;
  button.ForcePriceOUT = button.state.$$initialForcePriceOut!;
};

const mutateComboButtonPriceForPricedItemsExclusion = (button: DotButton<ButtonState>) => {
  button.ForcePriceIN = button.state.$$initialCalculatedPriceWithoutEnforcements! - button.MinPrice + button.state.$$initialForcePriceIn!;
  button.ForcePriceOUT = button.state.$$initialCalculatedPriceWithoutEnforcements! - button.MinPrice + button.state.$$initialForcePriceOut!;
};

const cloneComboButtonStrippedOfModifiers = (button: DotButton<ButtonState>): DotButton<ButtonState> => {
  if (button.ButtonType !== DotButtonType.MENU_BUTTON) {
    throw new Error('Function cannot be used to compute price for types other than DotButtonType.MENU_BUTTON');
  }
  const buttonWithoutModifiers = _.cloneDeep(button) as DotButton;
  buttonWithoutModifiers.ComboPage?.Combos.forEach((combo) =>
    combo.Buttons.forEach((lBtn) => (lBtn.ModifiersPage = new DotModifiersPage({})))
  );
  return buttonWithoutModifiers;
};
const cloneSimpleButtonStrippedOfModifiers = (button: DotButton<ButtonState>): DotButton<ButtonState> => {
  if (button.ButtonType !== DotButtonType.ITEM_BUTTON) {
    throw new Error('Function cannot be used to compute price for types other than DotButtonType.ITEM_BUTTON');
  }
  const buttonWithoutModifiers = _.cloneDeep(button) as DotButton;
  buttonWithoutModifiers.ModifiersPage = new DotModifiersPage({});
  return buttonWithoutModifiers;
};

const mutateButtonPriceForOnlyPricedModifiersExclusion = (
  button: DotButton<ButtonState>,
  clonedButtonStrippedOfModifiers: DotButton<ButtonState>,
  serviceType: PosServingLocation
) => {
  const buttonWithoutModifiersPrice = calculateButtonPrice(clonedButtonStrippedOfModifiers, serviceType);
  button.ForcePriceIN =
    button.state.$$initialCalculatedPriceWithoutEnforcements! - buttonWithoutModifiersPrice + button.state.$$initialForcePriceIn!;
  button.ForcePriceOUT =
    button.state.$$initialCalculatedPriceWithoutEnforcements! - buttonWithoutModifiersPrice + button.state.$$initialForcePriceOut!;
};

const addPreliminaryPriceCalculationStatusMetadata = (button: DotButton<ButtonState>, serviceType: PosServingLocation) => {
  if (typeof button.state.$$initialForcePriceIn !== 'number') {
    button.state.$$initialForcePriceIn = typeof button.ForcePriceIN === 'number' ? button.ForcePriceIN : undefined;
  }
  if (typeof button.state.$$initialForcePriceOut !== 'number') {
    button.state.$$initialForcePriceOut = typeof button.ForcePriceOUT === 'number' ? button.ForcePriceOUT : undefined;
  }
  delete button.ForcePriceIN;
  delete button.ForcePriceOUT;
  button.state.$$initialCalculatedPriceWithoutEnforcements = calculateButtonPrice(button, serviceType);
};

export const updateButtonPromoCustomForcePrice = (
  button: DotButton<ButtonState>,
  serviceType: PosServingLocation,
  promoDiscountsMode: PromoDiscountsMode
): void => {
  const currentPosServingLocation = DotSessionService.getInstance().currentPosServingLocation;
  if ((button.metadata?.promotionMetadata || button.promoId) && hasForcedPrice(button, currentPosServingLocation)) {
    const utilities: {
      isMenu: boolean;
      isItem: boolean;
      modifierStripperFunction: ((button: DotButton<ButtonState>) => DotButton<ButtonState>) | null;
    } = {
      isMenu: false,
      isItem: false,
      modifierStripperFunction: null,
    };

    if (button.ButtonType === DotButtonType.MENU_BUTTON) {
      utilities.isMenu = true;
      utilities.modifierStripperFunction = cloneComboButtonStrippedOfModifiers;
    } else if (button.ButtonType === DotButtonType.ITEM_BUTTON) {
      utilities.isItem = true;
      utilities.modifierStripperFunction = cloneSimpleButtonStrippedOfModifiers;
    }

    addPreliminaryPriceCalculationStatusMetadata(button, serviceType);
    if (promoDiscountsMode === PromoDiscountsMode.FULL_DISCOUNT) {
      return mutateButtonPriceForFullDiscount(button);
    }
    if (promoDiscountsMode === PromoDiscountsMode.EXCLUDE_PRICED_ITEMS && utilities.isMenu) {
      return mutateComboButtonPriceForPricedItemsExclusion(button);
    }
    if (promoDiscountsMode === PromoDiscountsMode.EXCLUDE_ONLY_PRICED_MODIFIERS && utilities.modifierStripperFunction) {
      return mutateButtonPriceForOnlyPricedModifiersExclusion(button, utilities.modifierStripperFunction(button), serviceType);
    }
  }
};
