import { Injectable } from '@angular/core';

import {
  DotWorkingHoursList,
  DotWorkingHoursLoader,
  getWorkingHoursList,
  isKioskWithinWorkingHoursRange,
  SectionAvailability,
  SectionResponse,
  waitUntilSessionIdle,
  WorkingHourHelpers,
} from 'dotsdk';
import { BehaviorSubject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkingHoursService {
  public outsideWorkingHours$ = new BehaviorSubject<boolean>(false);
  public whEatInEnabled$ = new BehaviorSubject<boolean>(true);
  public whTakeAwayEnabled$ = new BehaviorSubject<boolean>(true);
  public tableServiceEnabled$ = new BehaviorSubject<boolean>(true);
  public preorderPaymentTypeEnabled$ = new BehaviorSubject<boolean>(true);

  public whHelper: WorkingHourHelpers;

  constructor() {
    this.whHelper = new WorkingHourHelpers(getWorkingHoursList() || { WorkingHourList: [] });
    this.startCheck();
  }

  private startCheck() {
    timer(0, 1000)
      .pipe(waitUntilSessionIdle())
      .subscribe(() => this.doCheck());
  }

  public getSectionResponse<T extends SectionResponse>(sectionAvailability: SectionAvailability): T {
    return this.whHelper.isSectionWithinWorkingHours<T>(sectionAvailability);
  }

  private doCheck() {
    const workingHourList = DotWorkingHoursLoader.getInstance().loadedModel;
    if (!workingHourList) {
      this.enableAllServiceTypesAndTableService();
    } else {
      this.updateOutsideWorkingHours(!isKioskWithinWorkingHoursRange(workingHourList, true));
      this.updateState(workingHourList);
    }
  }

  private enableAllServiceTypesAndTableService() {
    this.updateWhTakeAwayEnabled(true);
    this.updateWhEatInEnabled(true);
    this.updateTableService(true);
  }

  private updateOutsideWorkingHours(newValue: boolean) {
    if (this.outsideWorkingHours$.value !== newValue) {
      this.outsideWorkingHours$.next(newValue);
    }
  }

  private updateWhTakeAwayEnabled(newValue: boolean) {
    if (this.whTakeAwayEnabled$.value !== newValue) {
      this.whTakeAwayEnabled$.next(newValue);
    }
  }

  private updateWhEatInEnabled(newValue: boolean) {
    if (this.whEatInEnabled$.value !== newValue) {
      this.whEatInEnabled$.next(newValue);
    }
  }

  private updateTableService(newValue: boolean) {
    if (this.tableServiceEnabled$.value !== newValue) {
      this.tableServiceEnabled$.next(newValue);
    }
  }

  private updatePreorder(newValue: boolean) {
    if (this.preorderPaymentTypeEnabled$.value !== newValue) {
      this.preorderPaymentTypeEnabled$.next(newValue);
    }
  }

  private updateState(workingHourList: DotWorkingHoursList) {
    this.whHelper.updateWorkingHoursModel(workingHourList);

    const whResponse = this.whHelper.isSectionWithinWorkingHours(SectionAvailability.SERVICE_TYPE);
    if (whResponse && 'EatInEnabled' in whResponse && typeof whResponse.EatInEnabled === 'boolean') {
      this.updateWhEatInEnabled(whResponse.EatInEnabled);
    } else {
      this.updateWhEatInEnabled(true);
    }

    if (whResponse && 'TakeAwayEnabled' in whResponse && typeof whResponse.TakeAwayEnabled === 'boolean') {
      this.updateWhTakeAwayEnabled(whResponse.TakeAwayEnabled);
    } else {
      this.updateWhTakeAwayEnabled(true);
    }

    const tsResponse = this.getSectionResponse(SectionAvailability.TABLE_SERVICE);
    if (tsResponse && 'TSSEnabled' in tsResponse && typeof tsResponse.TSSEnabled === 'boolean') {
      this.updateTableService(tsResponse.TSSEnabled);
    } else {
      this.updateTableService(true);
    }

    const preorderResponse = this.getSectionResponse(SectionAvailability.PRE_ORDER);
    if (preorderResponse && 'PreOrderEnabled' in preorderResponse && typeof preorderResponse.PreOrderEnabled === 'boolean') {
      this.updatePreorder(preorderResponse.PreOrderEnabled);
    } else {
      this.updatePreorder(true);
    }
  }
}
