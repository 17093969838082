<language-change-view-trigger></language-change-view-trigger>
<div #scrollRef (scroll)="onScrolled()" class="scrollable-container__scroll hideScrollbar"><ng-content></ng-content></div>
<div *ngIf="enableButtons" class="scrollable-container__scroll-nav">
  <button
    (click)="onScrollUp()"
    [disabled]="disableUpArrow"
    class="scrollable-container__scroll-nav--up"
    data-automation="Scroll Nav Button: Scroll Up"
  >
    <span class="scrollable-container__scroll-nav--text">{{ '2021081801' | translate }}</span>
    <div class="scrollable-container__icon">
      <img
        skin-part="image"
        src="/Channels/skin/icon-chevronUp.svg"
        class="ui-button--icon color--white size--30 scrollable-container__icon--image"
        alt=""
      />
    </div>
  </button>

  <button
    (click)="onScrollDown()"
    [disabled]="disableDownArrow"
    class="scrollable-container__scroll-nav--down"
    data-automation="Scroll Nav Button: Scroll Down"
  >
    <div class="scrollable-container__icon">
      <img
        skin-part="image"
        src="/Channels/skin/icon-chevronDown.svg"
        class="ui-button--icon color--white size--30 scrollable-container__icon--image"
        alt=""
      />
    </div>
    <span class="scrollable-container__scroll-nav--text">{{ '2021081802' | translate }}</span>
  </button>
</div>
