import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';
import { itemButtonCaloriesValue } from '@dotxix/helpers/callories.helper';

@Pipe({
  name: 'calories',
})
export class CaloriesPipe implements PipeTransform {
  public transform(button: DotButton): string {
    return itemButtonCaloriesValue(button);
  }
}
