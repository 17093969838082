import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { AppBIService } from '../../../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-glory-cash-payment-progress-error-print',
  templateUrl: './glory-cash-payment-progress-error-print.component.html',
  host: { class: 'flex flex--column flex--1' },
})
export class GloryCashPaymentProgressErrorPrintComponent implements OnInit {
  public paymentServiceState$ = this.paymentService.state$;

  constructor(
    private paymentService: PaymentService,
    private appBiService: AppBIService
  ) {}
  public ngOnInit() {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CASH_PAYMENT_PROGRESS_ERROR_PRINT);
  }
}
