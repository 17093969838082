<language-change-view-trigger></language-change-view-trigger>
<ng-container *ngIf="isServiceTypeStartScreen$ | async">
  <acr-admin-zone></acr-admin-zone>
  <acr-status></acr-status>
</ng-container>
<acr-payment-logos></acr-payment-logos>

<main skin="serviceTypePage" class="page-container--grid service-types">
  <header skin-part="header" class="page-header">
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
    <div class="service-types__titles">
      <h1 skin-part="title" class="service-types__titles--title">{{ '10' | translate }}</h1>
      <h2 skin-part="subTitle" class="service-types__titles--subtitle">{{ '14' | translate }}</h2>
    </div>
  </header>

  <section class="service-types__buttons" skin-part="serviceTypeSelectionList">
    <acr-service-type-button
      *ngIf="(serviceType$ | async) !== OUT"
      [disabled]="(canStartOrdering$ | async) === false || (serviceTypeInEnabled$ | async) === false"
      (selected)="onServiceTypeInClicked()"
      [text]="'11' | translate"
      [automationTag]="'Service Type: ' + IN"
      [iconName]="'serviceTypeIn'"
      skin="serviceTypeButton"
      skin-part="serviceTypeIn"
    ></acr-service-type-button>
    <acr-service-type-button
      *ngIf="(serviceType$ | async) !== IN"
      [disabled]="(canStartOrdering$ | async) === false || (serviceTypeOutEnabled$ | async) === false"
      (selected)="onServiceTypeOutClicked()"
      [text]="'12' | translate"
      [automationTag]="'Service Type: ' + OUT"
      [iconName]="'serviceTypeOut'"
      skin="serviceTypeButton"
      skin-part="serviceTypeOut"
    ></acr-service-type-button>
  </section>

  <acr-languages></acr-languages>
  <acr-footer></acr-footer>
</main>
