import { Injectable } from '@angular/core';
import { DotButton, DotButtonType, getCatalogButton, getCombosCatalogButton } from 'dotsdk';
import { MakeItAMealComponent } from '@dotxix/components/make-it-a-meal/make-it-a-meal.component';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { isButtonCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { sortByMinPrice } from '@dotxix/helpers/button.helper';

@Injectable({
  providedIn: 'root',
})
export class MakeItAMealService {
  constructor(private dynamicContentService: DynamicContentService) {}

  public selectMakeItAMealButton(button: DotButton) {
    return new Promise<DotButton | null>((resolve) => {
      this.dynamicContentService
        .openContent(MakeItAMealComponent, { buttons: sortByMinPrice(this.extractButtonsFromMakeItAMeal(button)) })
        .afterClosed.subscribe((response) => {
          if (response) {
            resolve(response);
          } else if (response === null) {
            resolve(button);
          }

          resolve(null);
        });
    });
  }

  private extractButtonsFromMakeItAMeal(button: DotButton) {
    const buttons: DotButton[] = [];
    button.MakeItAMeal.filter(
      (meal) => meal.ButtonType === DotButtonType.MENU_BUTTON || meal.ButtonType === DotButtonType.ITEM_BUTTON
    ).forEach((meal) => {
      let button: DotButton | null = null;
      if (meal.ButtonType === DotButtonType.MENU_BUTTON) {
        button = getCombosCatalogButton(meal.Id);
      }
      if (meal.ButtonType === DotButtonType.ITEM_BUTTON) {
        button = getCatalogButton(meal.Id);
      }

      if (button && isButtonCatalogAvailable(button)) {
        button.selectedSize = meal?.Size;
        button.StartSize = button.selectedSize;
        buttons.push(button);
      }
    });
    return buttons;
  }
}
