import { Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2 } from '@angular/core';
import { SkinService } from '@dotxix/skin/skin.service';
import { Subscription } from 'rxjs';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[skin],[skin-part],[skin-part-icon]' })
export class SkinDirective implements OnChanges, OnDestroy {
  @Input('skin') public sectionId: string | undefined;
  @Input('skin-part') public partId: string | undefined;
  @Input('skin-part-icon') public partIconId: string | undefined;

  private extensionUpdateSubscription: Subscription;

  constructor(
    private skinService: SkinService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.extensionUpdateSubscription = this.skinService.extensionsUpdated$.subscribe(() => {
      this.skinService.updateExtensions(this.elementRef, this.renderer);
    });
  }

  public ngOnChanges() {
    if (this.sectionId) {
      this.skinService.applySection(this.sectionId, this.elementRef, this.renderer);
    }
    if (this.partId) {
      this.skinService.applyPart(this.partId, this.elementRef, this.renderer);
    }
  }

  public ngOnDestroy() {
    this.extensionUpdateSubscription.unsubscribe();
  }
}
