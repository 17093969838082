import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';
import { isButtonDisabled } from '@dotxix/helpers/button.helper';

@Pipe({
  name: 'isButtonDisabled',
})
export class IsButtonUnavailablePipe implements PipeTransform {
  public transform(button: DotButton) {
    return isButtonDisabled(button);
  }
}
