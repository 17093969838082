<section class="flex flex--column flex--1 justify-evenly text-center">
  <div class="glory-cash-info">
    <span class="glory-cash-info__message">
      <span class="glory-cash-info__text">{{ '2001018' | translate }}</span>
      <!-- Total amount owed: -->
      <span class="glory-cash-info__price">
        {{ (paymentServiceState$ | async)!.cash.maximumAmountThatCanBePaidAtTransactionStart | dotCurrency: true }}
      </span>
    </span>
    <span class="glory-cash-info__message">
      <span class="glory-cash-info__text">{{ '2001019' | translate }}</span>
      <!-- Inserted cash: -->
      <span class="glory-cash-info__price">
        {{ (paymentServiceState$ | async)!.cash.lastTransactionFailedToRefundAmount | dotCurrency: true }}
      </span>
    </span>
  </div>

  <div class="flex flex--column place-center justify-center">
    <p class="fs--30 m--10 font--secondary color--errorRed text-center" [innerHTML]="'2001023' | translate"></p>
    <!-- An error has occurred on the cash machine. |Please see a crew member for help or print receipt for a refund at the front counter. -->
  </div>
</section>

<footer class="page-footer flex justify-center place-center">
  <button class="ui-button ui-button--secondary ui-button--medium" data-automation="Print Receipt" (click)="onPrintReceiptClicked()">
    <span>{{ '2001024' | translate }}</span>
  </button>
</footer>
