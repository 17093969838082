<language-change-view-trigger></language-change-view-trigger>
<div
  class="product product--modifier button-modifier"
  [class.selected]="isSelected"
  [class.product--disabled]="disabled"
  [class.product--hasChargeThreshold]="isAboveChargeThreshold"
>
  <button (click)="onButtonClicked()" [disabled]="disabled" class="product__button">
    <header class="product__header">
      <h5 class="product__title">{{ modifierButton | translateCaption }}</h5>
      <span class="product__subtitle">
        <span [class.mr--10]="displayPrice" *ngIf="hasDefaultQuantity && !hasPrefixes">
          {{ modifierButton | modifierButtonDefaultQuantityLabel: chargeThresholdGroup }}
        </span>
        <span [class.mr--10]="displayPrice" *ngIf="hasPrefixes">{{ modifierButton.selectedPrefixId | prefixLabel }}</span>
        <span *ngIf="displayPrice">{{ displayPrice | dotCurrency }}</span>
      </span>
    </header>
    <img
      class="product__img"
      [class.logo]="!(modifierButton | translatePicture)"
      [src]="modifierButton | translatePicture"
      alt=""
      width="180rem"
      height="180rem"
    />
    <img
      skin-part="image"
      src="/Channels/skin/icon-pillCheckmark.svg"
      class="ui-button--icon modifier-checkmark size--56 button-modifier--modifier-checkmark"
      alt=""
    />
  </button>
  <acr-buttons-counter-edit
    *ngIf="showCounterEdit && !disabled"
    [quantity]="modifierButton.quantity"
    [hideQuantityAmount]="hasDefaultQuantity || hasPrefixes"
    [isIncreaseDisabled]="disableIncreaseQuantity"
    [isDecreaseDisabled]="disableDecreaseQuantity"
    (quantityUpdate)="onQuantityUpdate($event)"
  ></acr-buttons-counter-edit>
  <ng-container *ngIf="modifierButton | isButtonDisabled">
    <div class="disabled--overlay">{{ '20210401001' | translate }}</div>
  </ng-container>
  <acr-info-indicator *ngIf="showInfoButton" [button]="modifierButton"></acr-info-indicator>
</div>
