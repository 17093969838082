import { DotButton, DotModifier, DotPrefixes, DotPrefixesLoader, DotPrefixId } from 'dotsdk';

export const hasPrefixes = (modifier: DotButton) => modifier.HasPrefixes && modifier.Included;
export const doesNotHavePrefixes = (modifier: DotButton) => !modifier.HasPrefixes || !modifier.Included;

export const modifierButtonIncreasePrefixQuantity = (modifierButton: DotButton) => {
  if (modifierButton.selectedPrefixId === DotPrefixId.NO) {
    setButtonPrefix(modifierButton, undefined);
    return;
  }
  if (modifierButton.selectedPrefixId === DotPrefixId.EXTRA) {
    return;
  }
  setButtonPrefix(modifierButton, DotPrefixId.EXTRA);
};

export const modifierButtonDecreasePrefixQuantity = (modifierButton: DotButton) => {
  if (modifierButton.selectedPrefixId === DotPrefixId.NO) {
    return;
  }
  if (modifierButton.selectedPrefixId === DotPrefixId.EXTRA) {
    setButtonPrefix(modifierButton, undefined);
    return;
  }
  setButtonPrefix(modifierButton, DotPrefixId.NO);
};

export const setButtonPrefix = (button: DotButton, prefixId: DotPrefixId | undefined) => {
  if (prefixId === undefined) {
    button.quantity = 0;
    button.Selected = false;
    delete button.selectedPrefixId;
  }
  if (prefixId === DotPrefixId.NO) {
    button.quantity = 1;
    button.Selected = true;
    button.selectedPrefixId = DotPrefixId.NO;
  }
  if (prefixId === DotPrefixId.EXTRA) {
    button.quantity = 1;
    button.Selected = true;
    button.selectedPrefixId = DotPrefixId.EXTRA;
  }
};

export const getSelectedPrefixFromButton = (button: DotButton): DotPrefixes | null => {
  if (button.selectedPrefixId) {
    const prefixes: DotPrefixes[] = DotPrefixesLoader.getInstance().loadedModel;
    if (!prefixes) {
      return null;
    }
    if (button.selectedPrefixId === DotPrefixId.NO) {
      return prefixes.find((x) => x.Id === DotPrefixId.NO) ?? null;
    } else if (button.selectedPrefixId === DotPrefixId.EXTRA) {
      return prefixes.find((x) => x.Id === DotPrefixId.EXTRA) ?? null;
    }
  }
  return null;
};

export const modifierButtonsWithoutPrefixes = (modifier: DotModifier) =>
  modifier.Buttons.filter((modifierButton) => doesNotHavePrefixes(modifierButton));
