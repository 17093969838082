import { Component } from '@angular/core';
import { OsirisLoginFaceRecallService } from '@dotxix/osiris/services/osiris-login-face-recall.service';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';
import { RetryWithPinStateService } from '@dotxix/osiris/services/retry-with-pin-state.service';

@Component({
  selector: 'acr-enter-pin',
  templateUrl: './enter-pin.component.html',
  host: { class: 'full-height full-width' },
})
export class EnterPinComponent {
  private _keyboardValue = '';
  public keyboardReset = false;
  public faceRecall$ = this.osirisStateService.faceRecall$;
  public currentRetries$ = this.retryWithPinStateService.currentRetries$;
  public defaultMaxRetries = this.retryWithPinStateService.defaultMaxRetries;

  constructor(
    private osirisStateService: OsirisStateService,
    private osirisLoginFaceRecallService: OsirisLoginFaceRecallService,
    private retryWithPinStateService: RetryWithPinStateService
  ) {}

  public submit() {
    this.retryWithPinStateService.tryRegisterWithPin(this._keyboardValue);
    this.keyboardReset = true;
    setTimeout(() => {
      this.keyboardReset = false;
    }, 0);
  }
  public cancel() {
    this.osirisLoginFaceRecallService.navigateFromEnterPinCancel();
  }

  public keyboardValue(event: string) {
    this._keyboardValue = event;
  }
}
