import { DotButton, DotModifier } from 'dotsdk';
import { ButtonState } from '@dotxix/models/interfaces/button-state';

const adjustModifiersChargeThresholdBasedOnGroupChargeThreshold = (chargeThresholdGroup: number, modifierButtons: DotButton[]) => {
  modifierButtons.forEach((btn) => {
    if ((btn.ChargeThreshold ?? 0) > chargeThresholdGroup || btn.ChargeThreshold === undefined) {
      btn.ChargeThreshold = chargeThresholdGroup;
    }
  });
};

const saveChargeThresholdStartValuesForModifiers = (modifierButtons: DotButton<ButtonState>[]) => {
  modifierButtons.forEach((btn) => {
    btn.state.originalChargeThresholdValue =
      typeof btn.state.originalChargeThresholdValue === 'number' ? btn.state.originalChargeThresholdValue : btn.ChargeThreshold || 0;
    btn.ChargeThreshold = btn.ChargeThreshold || 0;
  });
};

const revertModifiersChargeThresholdToOriginalValues = (modifierButtons: DotButton<ButtonState>[]) => {
  modifierButtons.forEach((btn) => {
    btn.ChargeThreshold = btn.state.originalChargeThresholdValue;
  });
};

const getFirstModifierWithReducedChargeThreshold = (modifierButtons: DotButton<ButtonState>[]) => {
  return modifierButtons.find(
    (btn) => btn.quantity > (btn.ChargeThreshold ?? 0) && (btn.ChargeThreshold ?? 0) < btn.state.originalChargeThresholdValue!
  );
};

const reduceModifiersChargeThresholdToQuantity = (modifierButtons: DotButton[]) => {
  modifierButtons.forEach((btn) => {
    btn.ChargeThreshold = Math.min(btn.quantity, btn.ChargeThreshold ?? 0);
  });
};

const getUnusedGroupChargeThresholdQuantity = (chargeThresholdGroup: number, modifierButtons: DotButton[]) => {
  return chargeThresholdGroup - modifierButtons.reduce((acc, btn) => acc + btn.quantity, 0);
};

const getGroupUsedChargeThresholdValue = (modifierButtons: DotButton[]) => {
  return modifierButtons.reduce((acc, btn) => acc + Math.min(btn.quantity || 0, btn.ChargeThreshold || 0), 0);
};

export const updateModifiersChargeThresholdWithinGroupLimit = (
  chargeThresholdGroup: number,
  modifierButtons: DotButton[],
  lastChangeType: 1 | -1
) => {
  if (!chargeThresholdGroup) {
    return;
  }
  const unusedGroupChargeThresholdQuantity = getUnusedGroupChargeThresholdQuantity(chargeThresholdGroup, modifierButtons);
  if (lastChangeType > 0) {
    // modifier added
    if (unusedGroupChargeThresholdQuantity <= 0) {
      reduceModifiersChargeThresholdToQuantity(modifierButtons);
    }
  } else {
    // modifier removed
    if (unusedGroupChargeThresholdQuantity > 0) {
      if (unusedGroupChargeThresholdQuantity === 1) {
        const modifierWithReducedChargeThreshold = getFirstModifierWithReducedChargeThreshold(modifierButtons);
        if (modifierWithReducedChargeThreshold) {
          modifierWithReducedChargeThreshold.ChargeThreshold!++;
          reduceModifiersChargeThresholdToQuantity(modifierButtons);
        } else {
          revertModifiersChargeThresholdToOriginalValues(modifierButtons);
        }
      } else {
        revertModifiersChargeThresholdToOriginalValues(modifierButtons);
      }
    } else {
      reduceModifiersChargeThresholdToQuantity(modifierButtons);
      if (getGroupUsedChargeThresholdValue(modifierButtons) < chargeThresholdGroup) {
        const modifierWithReducedChargeThreshold = getFirstModifierWithReducedChargeThreshold(modifierButtons);
        if (modifierWithReducedChargeThreshold) {
          modifierWithReducedChargeThreshold.ChargeThreshold!++;
        }
      }
    }
  }
};

export const initializeChargeThreshold = (
  modifier: DotModifier,
  initialModifierButtonsListQuantity: number,
  modifierButtonsListQuantity: number
) => {
  adjustModifiersChargeThresholdBasedOnGroupChargeThreshold(modifier.PageInfo.ChargeThreshold, modifier.Buttons);
  saveChargeThresholdStartValuesForModifiers(modifier.Buttons);
  const diffQuantityValue = Math.abs(modifierButtonsListQuantity - initialModifierButtonsListQuantity);
  if (diffQuantityValue > 0) {
    const lastChangeType = modifierButtonsListQuantity > initialModifierButtonsListQuantity ? 1 : -1;
    for (let i = 0; i < diffQuantityValue; i++) {
      updateModifiersChargeThresholdWithinGroupLimit(modifier.PageInfo.ChargeThreshold, modifier.Buttons, lastChangeType);
    }
  }
};
