import { Component, OnInit } from '@angular/core';
import { DotButton } from 'dotsdk';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { ButtonInfoService } from '@dotxix/button-info.service';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { ItemPackGroupDataParams } from '@dotxix/models/interfaces/item-pack-group-data-params';

@Component({
  selector: 'acr-item-pack-group',
  templateUrl: './item-pack-group.component.html',
})
export class ItemPackGroupComponent
  extends AbstractDynamicAnimatedPopup<ItemPackGroupDataParams, DotButton | null | undefined>
  implements OnInit
{
  public infoButtons: { button: DotButton; showInfoButton: boolean }[] = [];

  constructor(protected buttonInfoService: ButtonInfoService) {
    super();
  }

  public ngOnInit(): void {
    qaAutomationLog('item pack group buttons: ', this.dataParams!.buttons);
    this.infoButtons = this.dataParams!.buttons.map((button) => ({
      button,
      showInfoButton: this.buttonInfoService.showProductInfo(button),
    }));
  }

  public onNoThanksClicked() {
    this.close(null);
  }

  public selectedMeal(button: DotButton) {
    this.close(button);
  }

  public onProductInfoClick(event: MouseEvent, button: DotButton): void {
    event.stopPropagation();
    this.buttonInfoService.openProductInfo(button);
  }
}
