import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OsirisProcessOrderComponent } from '@dotxix/osiris/components/osiris-process-order/osiris-process-order.component';
import { OsirisComponent } from '@dotxix/osiris/components/osiris-login.component';
import { OsirisOrderHistoryComponent } from '@dotxix/osiris/components/osiris-order-history/osiris-order-history.component';
import { OsirisOrderReviewComponent } from '@dotxix/osiris/components/osiris-order-review/osiris-order-review.component';
import { FaceRecallComponent } from '@dotxix/osiris/components/face-recall/face-recall/face-recall.component';
import { HesetQrCodeLoginComponent } from '@dotxix/osiris/components/heset/heset-qr-code-login/heset-qr-code-login.component';
import { OsirisRoutes } from '@dotxix/osiris/osiris-routes';

const routes: Routes = [
  {
    path: OsirisRoutes.Login,
    component: OsirisComponent,
  },
  {
    path: OsirisRoutes.OrderHistory,
    component: OsirisOrderHistoryComponent,
  },
  {
    path: OsirisRoutes.OrderReview,
    component: OsirisOrderReviewComponent,
  },
  {
    path: OsirisRoutes.ProcessOrder,
    component: OsirisProcessOrderComponent,
  },
  {
    path: OsirisRoutes.FaceRecall,
    component: FaceRecallComponent,
  },
  {
    path: OsirisRoutes.HesetQrCodeLogin,
    component: HesetQrCodeLoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OsirisRoutingModule {}
