import { Component, OnInit } from '@angular/core';
import { DotButton, DotModifier, getCatalogButton } from 'dotsdk';
import * as _ from 'lodash';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { ButtonDetailsDataParams } from '@dotxix/models/interfaces/button-details-data-params';
import {
  addAutoCompleteModifiers,
  forceShowModifierGroupsThatAreNotReachingMinQuantity,
  isModifierSelectionValidForBasket,
} from '@dotxix/helpers/modifiers.helper';
import { computeButtonDisplayPrice, isItemPackButton, isNotItemPackButton } from '@dotxix/helpers/button.helper';
import {
  computeModifierStepComponentsMinPrice as computeModifierStepComponentsMinPrice,
  deselectModifierStepComponent,
  getSelectedModifierStepComponent as getSelectedModifierStepComponent,
  initializeModifierStepperButton,
} from '@dotxix/helpers/modifier-stepper.helper';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';
import { Subject } from 'rxjs';
import { comboCustomizableModifiers, hasComboCustomizableModifiers, selectComponent } from '@dotxix/helpers/combo.helper';
import { isButtonCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { ProductCustomizationService } from '@dotxix/services/product-customization.service';

enum ModifierStepState {
  CHOOSE_COMPONENT = 'chooseComponent',
  CUSTOMIZE = 'customize',
  SUBGROUP = 'subgroup',
}

@Component({
  selector: 'acr-edit-single-modifier-step',
  templateUrl: './edit-single-modifier-step.component.html',
})
export class EditSingleModifierStepComponent
  extends AbstractDynamicAnimatedPopup<ButtonDetailsDataParams, DotButton | null | undefined>
  implements OnInit
{
  public ModifierStepState = ModifierStepState;
  public button!: DotButton<ButtonState>;
  public catalogButton!: DotButton;
  public modifierTitle!: string;
  public modifierSubTitle!: string;

  public displayBackButton = false;
  public disableConfirmButton = false;

  public dynamicHeaderImages!: boolean;
  public headerImages!: string[];

  public currentModifierStepState: ModifierStepState | undefined;
  public visibleModifierSteps: DotModifier[] = [];
  public currentModifierStepIndex = 0;
  public isLastStep = false;
  public currentModifierStep!: DotModifier;
  public currentModifierStepComponents: DotButton[] = [];
  public currentModifierStepComponentsMinPrice: number | undefined;
  public currentModifierStepComponentFeatures: DotModifier[] = [];
  public itemPackVisibleComponents: DotButton[] = [];

  public scrollToTop$ = new Subject<void>();
  public modifierButton: DotModifier | undefined;

  constructor(
    private appSettings: ApplicationSettingsService,
    private translationsService: TranslationsService,
    private productTranslationsService: ProductTranslationsService,
    private productCustomizationService: ProductCustomizationService
  ) {
    super();
  }

  public ngOnInit() {
    this.button = _.cloneDeep(this.dataParams!.button);
    this.modifierButton = this.dataParams!.buttonIndex ? this.button.ModifiersPage.Modifiers[this.dataParams!.buttonIndex] : undefined;

    initializeModifierStepperButton(this.button);
    qaAutomationLog('button: ', this.button);
    this.visibleModifierSteps = this.modifierButton ? [this.modifierButton] : [];

    this.catalogButton = getCatalogButton(this.button.Link);
    this.modifierTitle = this.catalogButton ? this.translationsService.getTranslatedButtonCaption(this.catalogButton) ?? '' : '';

    this.updateSizeRelatedProps();

    this.dynamicHeaderImages = this.appSettings.settings$.value.enableComboAnimation;
    this.headerImages = this.computeHeaderImages();

    this.listModifierStepComponents(0);
    this.autoSelectCurrentModifierStepComponent();
  }

  public computeComponentDisplayPrice(component: DotButton): number {
    return computeButtonDisplayPrice(component, this.currentModifierStepComponentsMinPrice ?? 0);
  }

  public currentModifierStepComponentFeaturesChanged() {
    this.updateNavigationButtonStates();
  }

  public isModifierStepComponentSelected(component: DotButton) {
    return isNotItemPackButton(component) ? component.Selected : component.Page.Buttons.some((itemPackButton) => itemPackButton.Selected);
  }

  public onBackButtonClicked() {
    switch (this.currentModifierStepState) {
      case ModifierStepState.CHOOSE_COMPONENT:
        this.previousVisibleStep();
        break;
      case ModifierStepState.CUSTOMIZE:
        this.currentModifierStepState = ModifierStepState.CHOOSE_COMPONENT;
        break;
      case ModifierStepState.SUBGROUP:
        this.currentModifierStepState = ModifierStepState.CHOOSE_COMPONENT;
        break;
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  public onComponentButtonClicked(component: DotButton) {
    if (isItemPackButton(component)) {
      this.listModifierStepItemPack(component);
    } else {
      this.selectCurrentModifierStepComponent(component);
    }
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  public onConfirmClicked() {
    if (this.currentModifierStepState === ModifierStepState.CUSTOMIZE) {
      if (this.currentModifierStep) {
        const selectedModifierStepComponent = getSelectedModifierStepComponent(this.currentModifierStep);
        if (selectedModifierStepComponent) {
          addAutoCompleteModifiers(selectedModifierStepComponent);
        }
      }
    }
    this.goToNextVisibleStepOrFinishModifier();
  }

  public cancelClick(): void {
    this.productCustomizationService.onCancelButton(this.dataParams!.button, this);
  }

  public onItemPackComponentButtonClicked(component: DotButton) {
    this.selectCurrentModifierStepComponent(component);
  }

  private listModifierStepComponents(modifierStepIndex: number) {
    this.currentModifierStepIndex = modifierStepIndex;
    this.updateIsLastStep();
    this.currentModifierStep = this.visibleModifierSteps[this.currentModifierStepIndex];
    this.currentModifierStepState = ModifierStepState.CHOOSE_COMPONENT;
    this.updateCurrentModifierStepComponents();
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private updateCurrentModifierStepComponents() {
    if (this.currentModifierStep) {
      this.currentModifierStepComponents = this.currentModifierStep.Buttons;
      this.currentModifierStepComponentsMinPrice = computeModifierStepComponentsMinPrice(this.currentModifierStepComponents);
    }
  }

  private autoSelectCurrentModifierStepComponent() {
    if (this.currentModifierStepComponents.length === 1 && isNotItemPackButton(this.currentModifierStepComponents.first())) {
      this.selectCurrentModifierStepComponent(this.currentModifierStepComponents.first());
      if (this.currentModifierStepState !== ModifierStepState.CUSTOMIZE) {
        this.nextVisibleStep();
      }
    }
  }

  private nextVisibleStep() {
    if (this.visibleModifierSteps[this.currentModifierStepIndex + 1]) {
      this.listModifierStepComponents(this.currentModifierStepIndex + 1);
      this.autoSelectCurrentModifierStepComponent();
    }
  }

  private previousVisibleStep() {
    if (this.visibleModifierSteps[this.currentModifierStepIndex - 1]) {
      this.listModifierStepComponents(this.currentModifierStepIndex - 1);
      if (this.currentModifierStepComponents.length === 1) {
        if (this.currentModifierStep) {
          const selectedModifierStepComponent = getSelectedModifierStepComponent(this.currentModifierStep);
          if (selectedModifierStepComponent) {
            this.customizeComponentFeaturesIfAny(selectedModifierStepComponent);
          }
        }
      }
    }
  }

  private selectCurrentModifierStepComponent(componentButton: DotButton) {
    const selectedButton = (this.modifierButton?.Buttons ?? []).find((button) => button.Selected);
    if (selectedButton !== componentButton) {
      deselectModifierStepComponent(this.currentModifierStepComponents);
    }
    selectComponent(componentButton);
    this.updateDynamicHeaderImages();
    this.updateNavigationButtonStates();

    if (componentButton.hasModifiers) {
      forceShowModifierGroupsThatAreNotReachingMinQuantity(componentButton);
      this.customizeComponentFeaturesIfAny(componentButton);
    }
  }

  private customizeComponentFeaturesIfAny(component: DotButton) {
    if (hasComboCustomizableModifiers(component, this.dataParams!.isUpdate)) {
      this.customizeComponentModifiers(component);
    }
  }

  private customizeComponentModifiers(component: DotButton) {
    this.currentModifierStepComponentFeatures = comboCustomizableModifiers(component, !this.dataParams!.isUpdate);
    this.currentModifierStepState = ModifierStepState.CUSTOMIZE;
    this.updateNavigationButtonStates();
    this.scrollToTop();
  }

  private listModifierStepItemPack(component: DotButton) {
    this.currentModifierStepState = ModifierStepState.SUBGROUP;
    this.itemPackVisibleComponents = component.Page.Buttons.filter((itemPackButton) => isButtonCatalogAvailable(itemPackButton));
    selectComponent(component);
  }

  private goToNextVisibleStepOrFinishModifier() {
    if (this.visibleModifierSteps[this.currentModifierStepIndex + 1]) {
      this.nextVisibleStep();
    } else {
      this.modifierFinished();
    }
  }

  private modifierFinished() {
    const clonedButton = _.cloneDeep(this.button);
    this.close(clonedButton);
  }

  private updateSizeRelatedProps() {
    this.updateCurrentModifierStepComponents();
    this.updateSubtitle();
  }

  private updateIsLastStep() {
    this.isLastStep = this.currentModifierStepIndex === this.visibleModifierSteps.length - 1;
  }

  private updateSubtitle() {
    this.modifierSubTitle = this.button ? this.translationsService.getTranslatedButtonCaption(this.button) ?? '' : '';
  }

  private updateDynamicHeaderImages() {
    if (this.dynamicHeaderImages) {
      this.headerImages = this.computeDynamicHeaderImages();
    }
  }

  private computeStaticHeaderImages() {
    const headerImageButton = this.catalogButton ? this.catalogButton : this.button;
    if (headerImageButton) {
      const buttonHeaderImage = this.productTranslationsService.translatePicture(headerImageButton);
      return buttonHeaderImage ? [buttonHeaderImage] : [];
    } else {
      return [];
    }
  }

  private computeDynamicHeaderImages() {
    return [
      ...this.computeStaticHeaderImages(),
      ...(this.button?.ModifiersPage?.Modifiers ?? []).reduce((acc, modifier) => {
        const selectedButton = getSelectedModifierStepComponent(modifier);
        const picturePath = selectedButton ? this.productTranslationsService.translatePicture(selectedButton) : null;
        return selectedButton && picturePath ? [...acc, picturePath] : acc;
      }, [] as string[]),
    ];
  }

  private computeHeaderImages() {
    if (this.dynamicHeaderImages) {
      return this.computeDynamicHeaderImages();
    } else {
      return this.computeStaticHeaderImages();
    }
  }

  private updateNavigationButtonStates() {
    this.displayBackButton =
      this.currentModifierStepIndex > 0 ||
      ((this.currentModifierStepState === ModifierStepState.CUSTOMIZE || this.currentModifierStepState === ModifierStepState.SUBGROUP) &&
        this.currentModifierStepComponents.length > 1);

    this.disableConfirmButton = !this.isCurrentStepValid();
  }

  private isCurrentStepValid() {
    const selectedModifierStepComponent = this.currentModifierStep ? getSelectedModifierStepComponent(this.currentModifierStep) : null;
    switch (this.currentModifierStepState) {
      case ModifierStepState.CHOOSE_COMPONENT:
      case ModifierStepState.SUBGROUP:
        if (!this.currentModifierStep) {
          return false;
        }
        return !!getSelectedModifierStepComponent(this.currentModifierStep);
      case ModifierStepState.CUSTOMIZE:
        if (!this.currentModifierStep) {
          return false;
        }
        return selectedModifierStepComponent ? isModifierSelectionValidForBasket(selectedModifierStepComponent) : false;
    }
    return false;
  }

  private scrollToTop() {
    this.scrollToTop$.next();
  }
}
