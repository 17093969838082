<language-change-view-trigger></language-change-view-trigger>
<div class="flex basket">
  <div class="flex-full flex--column basket__content">
    <header class="flex align-center justify-center text-center basket__header">
      <h4 class="color--white">
        <span *ngIf="(numberOfItems$ | async) === 0">{{ '94' | translate }}</span>
      </h4>
      <button
        class="ui-button ui-button--primary ui-button--iconLeft ui-button--bg-white basket__header--button"
        data-automation="Close Basket Button"
        (click)="onCloseClicked()"
      >
        <span class="ui-button__wrapper gap--10">
          <img skin-part="image" src="/Channels/skin/icon-cross-basket.svg" class="size--25" alt="" />
          <span>{{ '24' | translate }}</span>
        </span>
      </button>
    </header>

    <acr-scrollable-container>
      <acr-basket-product-list></acr-basket-product-list>
    </acr-scrollable-container>

    <footer class="flex flex--column align-center justify-center basket__total" data-automation="Basket Total">
      <div class="flex full-width justify-between" *ngIf="hasOrderDiscount()">
        <h4 class="flex full-width justify-between color--white">{{ '20210504004' | translate }}</h4>
        <h4 class="color--white">
          <span data-automation="Basket Total Discount Amount">{{ getOrderDiscount() | dotCurrency: true }}</span>
        </h4>
      </div>
      <div class="flex full-width justify-between">
        <h3 class="flex full-width justify-between color--white">{{ '63' | translate }}</h3>
        <h3 class="color--white">
          <span data-automation="Basket Owned Amount">{{ totalPriceAccountingForOrderDiscount | dotCurrency: true }}</span>
        </h3>
      </div>
    </footer>
    <acr-basket-suggestions
      *ngIf="isBasketSuggestionVisible()"
      [basketSuggestionPages]="basketSuggestionPages"
      (suggestionSelect)="onSuggestionSelect($event)"
    ></acr-basket-suggestions>
  </div>
</div>
