import { Injectable } from '@angular/core';
import { OrderCheckInFlow } from '@dotxix/models/enums/order-check-in-flow';
import { PosServingLocation, waitUntilSessionIdle } from 'dotsdk';
import { AppRoutes } from '@dotxix/app-routes';
import { StatusService } from '@dotxix/services/status.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { Router } from '@angular/router';
import { SessionService } from '@dotxix/services/session.service';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import { OsirisFlowService } from '@dotxix/osiris/services/osiris-flow.service';
import { ServiceTypeService } from '@dotxix/services/service-type.service';
import { appLogger } from '@dotxix/log-manager';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppFlowService {
  constructor(
    private appSettings: ApplicationSettingsService,
    private statusService: StatusService,
    private router: Router,
    private sessionService: SessionService,
    private orderFlowService: OrderFlowService,
    private osirisFlowService: OsirisFlowService,
    private serviceTypeService: ServiceTypeService
  ) {
    this.sessionService.sessionStarted.subscribe(() => this.onSessionStarted());
    this.sessionService.onRestartSession.subscribe(() => this.displayStartScreen());

    this.osirisFlowService.displayMainMenu$.subscribe(() => this.orderFlowService.displayMainMenu());
  }

  public onAppInitialized() {
    this.appSettings.settings$
      .pipe(
        waitUntilSessionIdle(),
        map((settings) => `${settings.orderCheckInFlow}${settings.isPayTower}`),
        distinctUntilChanged()
      )
      .subscribe(() => this.displayStartScreen());
  }

  public onBannersClicked() {
    if (this.statusService.state$.value.blockKiosk) {
      return;
    }

    if (this.appSettings.settings$.value.isPayTower) {
      return this.displayPayTowerStartPage();
    }

    if (this.appSettings.settings$.value.orderCheckInFlow === OrderCheckInFlow.ONLY_BANNERS) {
      this.autoSelectServiceType();
    } else {
      this.displayServiceTypeSelection();
    }
  }

  public onServiceTypeSelected(type: PosServingLocation) {
    if (this.statusService.state$.value.blockKiosk) {
      return;
    }
    this.sessionService.startSession(type);
  }

  private autoSelectServiceType() {
    const autoSelectServiceType = this.serviceTypeService.findEnabledServiceType();
    if (autoSelectServiceType) {
      appLogger.debug(`auto select service type ${autoSelectServiceType}`);
      this.onServiceTypeSelected(autoSelectServiceType);
    }
  }

  private onSessionStarted() {
    this.osirisFlowService.onSessionStarted();
  }

  private displayStartScreen() {
    if (this.appSettings.settings$.value.orderCheckInFlow === OrderCheckInFlow.ONLY_SERVICE_TYPE) {
      if (this.appSettings.settings$.value.isPayTower) {
        this.displayPayTowerStartPage();
      } else {
        this.displayServiceTypeSelection();
      }
    } else {
      this.displayBanners();
    }
  }

  private displayServiceTypeSelection() {
    this.router.navigate([AppRoutes.ServiceTypeSelection]);
  }

  private displayPayTowerStartPage() {
    this.router.navigate([AppRoutes.PayTowerStartPage]);
  }

  private displayBanners() {
    this.router.navigate([AppRoutes.Banners]);
  }
}
