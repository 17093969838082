import * as CryptoJS from 'crypto-js';
import { AtpApplicationSettings } from 'dotsdk';

const encrypt = (value1: string, value2: string, value3: string): string => {
  const message = CryptoJS.enc.Utf8.parse(value1 + '#$%' + value2 + '#$%' + value3);

  const encrypted = CryptoJS.AES.encrypt(message, CryptoJS.enc.Utf8.parse('4512631236589784'), {
    keySize: 128 / 8,
    iv: CryptoJS.enc.Utf8.parse('4512631236589784'),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encodeURIComponent(encrypted.toString().replaceAll('/', '*'));
};

export const encryptAuthenticationUrl = (): string => {
  const marketKey = AtpApplicationSettings.getInstance().environmentDetailsJson.NestIdentifier?.split('.')[1];
  const storeCode = AtpApplicationSettings.getInstance().environmentDetailsJson?.StoreDetails.Code;
  const kioskId = AtpApplicationSettings.getInstance().bundleSettingsJson.kioskId;
  return encrypt(marketKey, storeCode, kioskId);
};

export const encryptTableServiceUrl = (orderId: string): string => {
  const marketKey = AtpApplicationSettings.getInstance().environmentDetailsJson.NestIdentifier?.split('.')[1];
  const storeCode = AtpApplicationSettings.getInstance().environmentDetailsJson?.StoreDetails.Code;
  return encrypt(marketKey, storeCode, orderId);
};
