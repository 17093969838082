<main class="page-container">
  <header class="page-header">
    <img src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <ng-container [ngSwitch]="(paymentServiceState$ | async)!.cash.view">
    <ng-container *ngSwitchCase="GloryCashPaymentView.PAYMENT_PROGRESS">
      <acr-glory-cash-payment-progress></acr-glory-cash-payment-progress>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.PAYMENT_PROGRESS_ERROR">
      <acr-glory-cash-payment-progress-error></acr-glory-cash-payment-progress-error>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.PAYMENT_PROGRESS_ERROR_PRINT">
      <acr-glory-cash-payment-progress-error-print></acr-glory-cash-payment-progress-error-print>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.PAYMENT_OVERPAY_REFUND_PROGRESS">
      <acr-glory-cash-overpay-refund-progress></acr-glory-cash-overpay-refund-progress>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.PAYMENT_OVERPAY_REFUND_FAILED">
      <acr-glory-cash-overpay-refund-failed></acr-glory-cash-overpay-refund-failed>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.PAYMENT_OVERPAY_REFUND_SUCCESS">
      <acr-glory-cash-overpay-refund-success></acr-glory-cash-overpay-refund-success>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.PAYMENT_CANCEL_TRANSACTION_REFUND_PROGRESS">
      <acr-glory-cash-cancel-transaction-refund-progress></acr-glory-cash-cancel-transaction-refund-progress>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.PAYMENT_CANCEL_TRANSACTION_REFUND_SUCCESS">
      <acr-glory-cash-cancel-transaction-refund-success></acr-glory-cash-cancel-transaction-refund-success>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.CASHBACK_PROGRESS">
      <acr-glory-cash-cash-back-progress></acr-glory-cash-cash-back-progress>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.CASHBACK_SUCCESS">
      <acr-glory-cash-cash-back-success></acr-glory-cash-cash-back-success>
    </ng-container>
    <ng-container *ngSwitchCase="GloryCashPaymentView.CASHBACK_FAILED">
      <acr-glory-cash-cash-back-failed></acr-glory-cash-cash-back-failed>
    </ng-container>
  </ng-container>
</main>
