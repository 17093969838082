import { DotButton, DotButtonType, DotNutritionalValue } from 'dotsdk';

export const CALORIES_KEY = 'energy_kcal';

/**
 *
 * @deprecated
 * Functionality was moved to extractCaloriesByButtonType() function in corelation with Nutritional Info specific pipes.
 */
export const buttonCalories = (button: DotButton): string => {
  if (button.Page) {
    const firstSimpleButton = button.Page.Buttons.find((btn) => btn.ButtonType === DotButtonType.ITEM_BUTTON);
    return firstSimpleButton ? itemButtonCaloriesValue(firstSimpleButton) : '';
  }
  if (button.hasCombos) {
    return '';
  }
  return itemButtonCaloriesValue(button);
};

export const extractCaloriesByButtonType = (button: DotButton): DotNutritionalValue | undefined => {
  if (button.Page) {
    const firstSimpleButton = button.Page.Buttons.find((btn) => btn.ButtonType === DotButtonType.ITEM_BUTTON);
    return firstSimpleButton ? itemButtonCalories(firstSimpleButton) : undefined;
  }
  if (button.hasCombos) {
    return undefined;
  }
  return itemButtonCalories(button);
};

export const itemButtonCalories = (button: DotButton): DotNutritionalValue | undefined => {
  return button.AllergensAndNutritionalValues?.NutritionalValues?.find((n) => n.Name === CALORIES_KEY);
};

export const itemButtonCaloriesValue = (button: DotButton): string => {
  const calories = itemButtonCalories(button);
  return calories ? calories.Value : '';
};

export const itemButtonCaloriesValueName = (button: DotButton): string => {
  const calories = itemButtonCalories(button);
  return calories ? calories.Value + ' ' + calories.Name : '';
};

export const itemButtonCaloriesValueLabel = (button: DotButton): string => {
  const calories = itemButtonCalories(button);
  return calories ? calories.Value + ' ' + calories.Label : '';
};
