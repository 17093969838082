import { DotAvailabilityService, DotButton, DotButtonStatus, DotButtonType, DotCatalogLoader, DotCombosCatalogLoader } from 'dotsdk';

export const filteredByAvailabilityAndStatus = (buttons: DotButton[]): DotButton[] =>
  buttons.filter((button) => {
    if (button.ButtonStatus === DotButtonStatus.DISABLED) {
      return false;
    }
    if (DotAvailabilityService.getInstance().isButtonAvailable(button)) {
      switch (button.ButtonType) {
        case DotButtonType.ITEM_BUTTON:
          return evaluateCatalogItemButtonAvailability(button);

        case DotButtonType.MENU_BUTTON:
          return evaluateCatalogMenuButtonAvailability(button);
      }
      return true;
    }
    return false;
  });

export const evaluateCatalogItemButtonAvailability = (button: DotButton): boolean => {
  if (!DotCatalogLoader.getInstance().loadedModel) {
    return true;
  }

  const equivalentCatalogItem = DotCatalogLoader.getInstance().findByLink(button.Link);
  if (!equivalentCatalogItem) {
    return true;
  }

  return DotAvailabilityService.getInstance().isButtonAvailable(equivalentCatalogItem);
};

export const evaluateCatalogMenuButtonAvailability = (button: DotButton): boolean => {
  if (!DotCombosCatalogLoader.getInstance().loadedModel) {
    return true;
  }
  const equivalentCatalogMenu = DotCombosCatalogLoader.getInstance().findByLink(button.Link);
  if (!equivalentCatalogMenu) {
    return true;
  }

  return DotAvailabilityService.getInstance().isButtonAvailable(equivalentCatalogMenu);
};
