import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';
import { ColorPickerStateService } from '@dotxix/custom-theme/services/color-picker-state.service';
import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'acr-edit-variable',
  templateUrl: 'edit-variable.component.html',
  styleUrls: ['edit-variable.component.scss'],
})
export class EditVariableComponent {
  public cssVariableName!: string;
  public cssVariableValue!: string;
  public showColorPicker = false;
  public variablesListLength = Object.keys(this.themeEditorStateService.themeEditorState$.value.generalThemingVariables).length;

  @Input() public variableIndex!: number;

  @Input() set cssVariable(ssVariablesKeyValue: KeyValue<string, string>) {
    this.cssVariableName = ssVariablesKeyValue.key;
    this.cssVariableValue = ssVariablesKeyValue.value;
  }
  @Output() public pickedColor: EventEmitter<CssVariables> = new EventEmitter();

  constructor(
    private colorPickerStateService: ColorPickerStateService,
    private themeEditorStateService: ThemeEditorStateService
  ) {}

  @ViewChild('pickerTrigger') public elementTrigger!: ElementRef;

  public prettifyCSSVariableName(variable: string) {
    const rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    return variable.slice(2).replace(rex, '$1$4 $2$3$5');
  }

  public toggleColorPicker() {
    this.showColorPicker = !this.showColorPicker;
  }

  public colorChange(color: string) {
    if (this.cssVariableValue !== color) {
      this.pickedColor.emit({ [this.cssVariableName]: color });
      this.cssVariableValue = color;
      this.colorPickerStateService.appendToHistory(color);
    }
    this.toggleColorPicker();
  }
}
