import { BIActions, BIGenericAppInfo, BIService, BISessionInfo } from '@acrelec.foundation/business-analytics';
import { IEnvironmentAbout } from 'dotsdk';
import { Injectable } from '@angular/core';
import { AtpApplicationSettings, DotTreeLoader, IAppDetails } from '@acrelec.foundation/dotsdk';
import { ApplicationSettingsService } from '../services/app-settings.service';

@Injectable()
export class AppBIService {
  public actions: BIActions = BIService.defaultActions;

  public acreburgerCustomBIActions = BIService.registerCustomActions({
    viewCustomEvent: (arg: string) => ({ Type: 'Impression', Subject: 'CustomEvent', arg }),
  });

  constructor(private applicationSerttingsService: ApplicationSettingsService) {
    this.applicationSerttingsService.settings$.subscribe((settings) => {
      if (settings.enableBIAnalyticsModule === true) {
        BIService.resume();
      }
      if (settings.enableBIAnalyticsModule === false) {
        BIService.pause();
      }
    });
  }

  public dumpToApiAndReinit(
    biAnalyticsUrl: string,
    biAnalyticsAuthToken: string,
    AUID: string,
    refInt: number | null = null,
    orderPOSNumber: number | undefined,
    sessionEndStatus: string | undefined
  ) {
    BIService.dumpToApiAndReinit(
      biAnalyticsUrl,
      biAnalyticsAuthToken,
      this.getGenericAppInfo(),
      this.getSessionInfo(AUID, refInt, orderPOSNumber, sessionEndStatus)
    );
  }

  public getSessionInfo(
    AUID: string,
    refInt: number | null = null,
    orderPOSNumber: number | undefined,
    sessionEndStatus: string | undefined
  ): BISessionInfo {
    return {
      AUID: AUID,
      RefInt: refInt,
      OrderPOSNumber: orderPOSNumber,
      SessionEndStatus: sessionEndStatus,
    } as BISessionInfo;
  }

  public getGenericAppInfo(): BIGenericAppInfo {
    const atpBs = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const appDetails: IAppDetails = AtpApplicationSettings.getInstance().applicationDetailsJson;
    const envDetails: IEnvironmentAbout = AtpApplicationSettings.getInstance().environmentDetailsJson;
    const storeTags: string[] = AtpApplicationSettings.getInstance().storeTagsJson?.StoreTags || [];
    const kioskTags: string[] = AtpApplicationSettings.getInstance().kioskTagsJson?.Tags || [];
    const dotTreeMainPage = DotTreeLoader.getInstance().loadedModel?.MainPage;
    return {
      Brand: atpBs.brandName,
      NestIdentifier: envDetails?.NestIdentifier,
      RootPageId: dotTreeMainPage?.ID,
      SourceHost: {
        StoreName: atpBs.storeName,
        StoreCode: atpBs.storeCode,
        KioskId: atpBs.kioskId,
      },
      Device: {
        Resolution: [window.innerWidth, window.innerHeight],
      },
      Application: {
        Name: appDetails.AppName,
        Version: appDetails.AppVersion,
      },
      MarketTags: {
        Store: storeTags,
        Kiosk: kioskTags,
      },
      CampaignInfo: {
        CampaignId: dotTreeMainPage?.CampaignId,
        CampaignName: dotTreeMainPage?.CampaignName,
        CampaignZipFile: dotTreeMainPage?.CampaignZipFile,
      },
    };
  }
}
