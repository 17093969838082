import { Injectable, NgZone } from '@angular/core';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { paymentLogger } from '@dotxix/log-manager';
import { getPaymentName } from '@dotxix/payment/helpers/payment-type.helper';
import { AtpPaymentService, IPayProgress, PosPaidState, PosRefintService, PosTenderType } from 'dotsdk';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { lastValueFrom, Subject, Subscription, timer } from 'rxjs';
import { insertPaymentOperation, insertTender } from '@dotxix/payment/helpers/payment-elog.helper';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { PrintTicketOption } from '@dotxix/models/enums/print-ticket-option';

@Injectable({
  providedIn: 'root',
})
export class GloryCashPaymentService {
  public paymentProgressError$ = new Subject<number>();
  public currentPaidAmount$ = new Subject<{ currentPaidAmount: number; paymentType: PaymentType }>();
  private paymentProgressSubscription: Subscription | undefined;
  constructor(
    private paymentService: PaymentService,
    private ngZone: NgZone,
    private checkoutService: CheckoutService
  ) {}

  public async startAcceptMoney(paymentType: PaymentType) {
    this.paymentService.onCashAcceptingMoneyStarted(paymentType);
    const paymentName = getPaymentName(paymentType);
    paymentLogger.debug(`Glory start accept money request: ${paymentName}`);
    const startResult = await AtpPaymentService.getInstance()
      .cashStartAcceptingMoney(paymentName)
      .catch(() => null);
    paymentLogger.debug(`Glory start accept money response: ${JSON.stringify(startResult)}`);
    this.paymentService.onAcceptingMoneySuccessfully();
    return startResult && !(typeof startResult === 'object' && startResult.Error);
  }

  public startListeningPaymentProgress(paymentType: PaymentType) {
    paymentLogger.debug(`Glory pay progress subscription starting...`);
    this.removePaymentProgressSubscription();
    this.paymentProgressSubscription = AtpPaymentService.getInstance()
      .payProgress()
      .subscribe((paymentInfo: IPayProgress) => {
        this.ngZone.run(() => {
          paymentLogger.debug(`Pay progress response: ${JSON.stringify(paymentInfo)}`);
          if (paymentInfo.MessageClass === 'Error') {
            this.paymentProgressError$.next(paymentInfo.CurrentPaidAmount);
          } else {
            this.currentPaidAmount$.next({ currentPaidAmount: paymentInfo.CurrentPaidAmount || 0, paymentType });
          }
        });
      });
  }

  public async endAcceptMoney(amountToKeep: number, paymentType: PaymentType) {
    this.removePaymentProgressSubscription();
    this.paymentService.onEndAcceptMoneyStarted();

    const transactionReference = `${PosRefintService.getInstance()._refInt}`;
    const paymentName = getPaymentName(paymentType);
    paymentLogger.debug(
      `Glory end accept money request: amountToKeep=${amountToKeep}, paymentName=${paymentName}, params=null, transactionReference=${transactionReference} `
    );
    const endResult = await AtpPaymentService.getInstance()
      .cashEndAcceptingMoney(amountToKeep, paymentName, null, transactionReference)
      .catch((errorResult) => errorResult);
    paymentLogger.debug(`Glory end accept money response: ${JSON.stringify(endResult)}`);
    const endResultPaidAmount = endResult?.PaidAmount || 0;
    const endResultRefundedAmount = endResult?.RefundAmount || 0;

    // try to avoid collision between end accept money and start accept money
    // the cash machine needs some rest time between end accept money and start accept money
    await lastValueFrom(timer(2000));

    const oldCashPaidAmount = this.paymentService.state$.value.cash.cashPaidAmount;
    this.paymentService.onEndCurrentCashTransaction(endResultPaidAmount, endResultRefundedAmount, paymentName, transactionReference);

    const amountPaidWithCashForLastTransaction = this.paymentService.state$.value.cash.cashPaidAmount - oldCashPaidAmount;
    insertPaymentOperation(true, getPaymentName(paymentType));
    insertTender(
      PosTenderType.CASH,
      amountPaidWithCashForLastTransaction > 0 ? PosPaidState.PAID : PosPaidState.NOT_PAID,
      amountPaidWithCashForLastTransaction,
      paymentType.TenderMediaID ?? ''
    );

    return {
      paidAmount: amountPaidWithCashForLastTransaction,
      refundedAmount: this.paymentService.state$.value.cash.lastTransactionRefundedAmount,
      failedToRefundAmount: this.paymentService.state$.value.cash.lastTransactionFailedToRefundAmount,
    };
  }

  public async cashBack() {
    const paymentServiceState = this.paymentService.state$.value;
    if (paymentServiceState.cash.cashBack.cashBackParams === null) {
      return;
    }

    this.paymentService.onCashBackStarted();

    const paymentName = paymentServiceState.cash.cashBack.cashBackParams.paymentName;
    const transactionReference = paymentServiceState.cash.cashBack.cashBackParams.transactionReference;
    paymentLogger.debug(`Glory cashback request: transactionReference=${transactionReference}, paymentName=${paymentName}`);
    const cashBackResult = await AtpPaymentService.getInstance()
      .cashBack(transactionReference, paymentName)
      .catch((e) => {
        qaAutomationLog('error on cashback call: ', e);
        return e;
      });
    paymentLogger.debug(`Glory cashback response: ${JSON.stringify(cashBackResult, null, 2)}`);

    const amountReturned = cashBackResult?.Response?.ReturnedAmount || 0;
    this.paymentService.onCashBackFinished(amountReturned);

    if (this.paymentService.state$.value.cash.cashBack.cashBackFailedToRefundAmount > 0) {
      this.printCashBackFailedToRefund();
    }

    await lastValueFrom(timer(3000));
  }

  public removePaymentProgressSubscription() {
    if (this.paymentProgressSubscription) {
      this.paymentProgressSubscription.unsubscribe();
      delete this.paymentProgressSubscription;
    }
  }

  private printCashBackFailedToRefund() {
    this.checkoutService.printReceipt({ printTicketOption: PrintTicketOption.FULL });
  }
}
