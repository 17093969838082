import { Injectable } from '@angular/core';
import { OsirisLoginKeyboardComponent } from '@dotxix/osiris/components/osiris-login-keyboard/osiris-login-keyboard.component';
import { DynamicContentRef } from '@dotxix/services/dynamic-content/models/dynamic-content.ref';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { OsirisService } from '@dotxix/osiris/services/osiris.service';
import { Subject } from 'rxjs';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';

@Injectable({
  providedIn: 'root',
})
export class OsirisLoginKeyboardService {
  public customerAuthenticated$ = new Subject<{ customerId: string; orders: OsirisOrder[] }>();
  public newCustomer$ = new Subject<string>();

  private keyboardScreen: DynamicContentRef<void, void> | null = null;

  constructor(
    private osirisService: OsirisService,
    private dynamicContentService: DynamicContentService
  ) {}

  public loginWithKeyboard() {
    this.keyboardScreen = this.dynamicContentService.openContent(OsirisLoginKeyboardComponent);
  }

  public keyboardCanceled() {
    this.hideKeyboardScreen();
  }

  public async validateKeyboardInput(customerId: string) {
    const response = await this.osirisService.getCustomerLastOrders(customerId);
    if (response && response.Orders) {
      if (response.Orders.length) {
        this.hideKeyboardScreen();
        this.customerAuthenticated$.next({ customerId, orders: response.Orders });
      } else {
        this.hideKeyboardScreen();
        this.newCustomer$.next(customerId);
      }
    }
  }

  private hideKeyboardScreen() {
    if (this.keyboardScreen) {
      this.keyboardScreen.close();
      this.keyboardScreen = null;
    }
  }
}
