import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppInitStep, AppInitStepProgressFlag, FilesLoaderService } from 'dotsdk';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  public dataLoadingState$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public dataChanged$: Subject<void> = new Subject<void>();

  constructor(private ngZone: NgZone) {
    FilesLoaderService.getInstance()
      .loadingProgress.pipe(distinctUntilChanged())
      .subscribe((loadingStep: AppInitStep) => this.updateLoadingStep(loadingStep));
  }

  private updateLoadingStep(loadingStep: AppInitStep) {
    this.ngZone.run(() => {
      // if between sessions and SDK is notified of new data arrival it will load data immediately
      // if order is ongoing and SDK is notified of new data arrival it will delay loading of data until DotFlow.sessionStop()
      if (loadingStep.progressFlag !== AppInitStepProgressFlag.LOADING_FINISHED) {
        this.dataLoadingState$.next(true);
      } else {
        this.dataLoadingState$.next(false);
        this.dataChanged$.next();
      }
    });
  }
}
