import { Injectable } from '@angular/core';
import { SessionService } from '@dotxix/services/session.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ParentOrderIdService {
  private parentOrderId$ = new BehaviorSubject<string | null>(null);

  public setParentOrderId(id: string | null) {
    this.parentOrderId$.next(id);
  }

  public getParentOrderId(): string | null {
    return this.parentOrderId$.value;
  }

  constructor(private sessionService: SessionService) {
    this.sessionService.sessionStarted.subscribe(() => this.parentOrderId$.next(null));
  }
}
