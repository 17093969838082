import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoPopFeatMode } from '@dotxix/models/enums/auto-pop-feat';
import { DotModifier } from 'dotsdk';
import { ModifierState } from '@dotxix/models/interfaces/modifier-state';

@Component({
  selector: 'acr-ice-modifier',
  templateUrl: './ice-modifier.component.html',
})
export class IceModifierComponent implements OnInit {
  @Input() public modifier: DotModifier<ModifierState> | undefined;
  @Input() public isUpdate: boolean | undefined;
  @Output() public modifierChanged = new EventEmitter<void>();

  public isWithoutIceSelected: boolean | undefined;
  public isWithIceSelected: boolean | undefined;

  public ngOnInit(): void {
    if (!this.modifier) {
      return;
    }
    if (this.isUpdate) {
      this.isWithIceSelected = this.modifier.Buttons.some((b) => b.Tags.includes('withIce') && b.Selected && b.quantity > 0);
      this.isWithoutIceSelected = this.modifier.Buttons.some((b) => b.Tags.includes('withoutIce') && b.Selected && b.quantity > 0);

      if (
        !this.isWithoutIceSelected &&
        !this.isWithIceSelected &&
        (!(this.modifier.PageInfo.AutoPopFeat === AutoPopFeatMode.NOT_POP_ON_ORDER_POP_ON_MODIFY) ||
          (this.modifier.PageInfo.AutoPopFeat === AutoPopFeatMode.NOT_POP_ON_ORDER_POP_ON_MODIFY &&
            this.modifier.state.iceModifierSelected))
      ) {
        this.isWithIceSelected = this.modifier.Buttons.some((b) => b.Tags.includes('withoutIce'));
        this.isWithoutIceSelected = this.modifier.Buttons.some((b) => b.Tags.includes('withIce'));
      }
    }
  }

  public selectWithIce(): void {
    if (!this.isWithIceSelected) {
      this.isWithIceSelected = true;
      this.isWithoutIceSelected = false;
      this.setWithIceButtons(true);
      this.setWithoutIceButtons(false);
      if (this.modifier) {
        this.modifier.state.iceModifierSelected = true;
      }
      this.modifierChanged.emit();
    }
  }

  public selectWithoutIce(): void {
    if (!this.isWithoutIceSelected) {
      this.isWithIceSelected = false;
      this.isWithoutIceSelected = true;
      this.setWithIceButtons(false);
      this.setWithoutIceButtons(true);
      if (this.modifier) {
        this.modifier.state.iceModifierSelected = true;
      }
      this.modifierChanged.emit();
    }
  }

  private setWithIceButtons(value: boolean): void {
    if (!this.modifier) {
      return;
    }
    this.modifier.Buttons.forEach((b) => {
      if (b.Tags?.includes('withIce')) {
        b.Selected = value;
        b.quantity = value ? 1 : 0;
      }
    });
  }

  private setWithoutIceButtons(value: boolean): void {
    if (!this.modifier) {
      return;
    }
    this.modifier.Buttons.forEach((b) => {
      if (b.Tags?.includes('withoutIce')) {
        b.Selected = value;
        b.quantity = value ? 1 : 0;
      }
    });
  }
}
