import { Injectable } from '@angular/core';
import { SessionService } from '@dotxix/services/session.service';
import { DotBasketService, DotSessionEndType, DotSessionService, OMSEventsFacade, PosElogHandler, PosRefintService } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BasketService } from '@dotxix/services/basket.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { PAYMENT_TYPE } from '@dotxix/models/enums/payment-type';

@Injectable({ providedIn: 'root' })
export class OmsService {
  constructor(
    private sessionService: SessionService,
    private appSettings: ApplicationSettingsService,
    private basketService: BasketService,
    private checkoutService: CheckoutService
  ) {}

  public initialize() {
    this.sessionService.registerBeforeSessionRestartCallback(async (status) => {
      if (this.appSettings.settings$.value.enableOMSModule) {
        if (this.appSettings.settings$.value.enableOMSRecall) {
          this.sendOMSUpdates(status);
        } else if (
          !this.appSettings.settings$.value.enableOMSRecall &&
          (this.appSettings.settings$.value.OMSSendForPreOrder || this.checkoutService.paymentType !== PAYMENT_TYPE.PREORDER) &&
          status === DotSessionEndType.ORDER_SUCCESS
        ) {
          this.sendOMSUpdates(status);
        }
      }
    });
    DotBasketService.getInstance()
      .onCartUpdate()
      .subscribe(() => {
        this.sendOmsRecallEvent();
      });
  }

  protected sendOMSUpdates(status: DotSessionEndType) {
    const orderTotal = PosElogHandler.getInstance().posConfig.posHeader?.amounts?.amountsTotalPaid;
    const amount = orderTotal && orderTotal > 0 ? orderTotal : this.basketService.totalPrice;
    const isPaid = this.checkoutService.getAmountOwed() <= 0;
    const orderPosNumber = PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber;
    const refint = PosRefintService.getInstance()._refInt;
    const serviceType = DotSessionService.getInstance().currentPosServingLocation;
    const orderStartTime = PosElogHandler.getInstance().posConfig.posHeader.orderStartTime;
    const firstItemAddedTime = PosElogHandler.getInstance().posConfig.posHeader.firstItemAddedTime;
    if (status !== DotSessionEndType.ORDER_SUCCESS) {
      new OMSEventsFacade()
        .recallEvent(amount, false, undefined, orderPosNumber, refint, serviceType, orderStartTime, firstItemAddedTime)
        .subscribe()
        .add(() =>
          new OMSEventsFacade()
            .cancelEvent(amount, isPaid, undefined, orderPosNumber, refint, serviceType, orderStartTime, firstItemAddedTime)
            .subscribe()
        );
    } else {
      if (isPaid || this.appSettings.settings$.value.OMSSendForPreOrder) {
        new OMSEventsFacade()
          .finishEvent(amount, isPaid, undefined, orderPosNumber, refint, serviceType, orderStartTime, firstItemAddedTime)
          .subscribe();
      }
    }
  }

  private sendOmsRecallEvent() {
    if (!this.appSettings.settings$.value.enableOMSModule || !this.appSettings.settings$.value.enableOMSRecall) {
      return;
    }
    const orderPosNumber = PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber;
    const refint = PosRefintService.getInstance()._refInt;
    const serviceType = DotSessionService.getInstance().currentPosServingLocation;
    const orderStartTime = PosElogHandler.getInstance().posConfig.posHeader.orderStartTime;
    const firstItemAddedTime = PosElogHandler.getInstance().posConfig.posHeader.firstItemAddedTime;
    new OMSEventsFacade()
      .recallEvent(this.basketService.totalPrice, false, undefined, orderPosNumber, refint, serviceType, orderStartTime, firstItemAddedTime)
      .subscribe();
  }
}
