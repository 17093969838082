import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AtpScannerService } from 'dotsdk';
import { Subscription } from 'rxjs';

@Component({
  selector: 'acr-scan',
  templateUrl: './scan.component.html',
})
export class ScanComponent implements OnInit, OnDestroy {
  @Output() public barcodeChanged: EventEmitter<string> = new EventEmitter();

  public isScannerError = false;
  private _code = '';
  private scanSubscription!: Subscription;

  public get text(): string {
    return this._code;
  }

  public ngOnInit() {
    this.startScan();
  }
  public ngOnDestroy() {
    this.scanSubscription.unsubscribe();
  }

  private startScan() {
    this.scanSubscription = AtpScannerService.getInstance()
      .startContinuouslyScan()
      .subscribe({
        next: (code) => {
          this.barcodeChanged.emit(code);
        },
        error: () => {
          this.isScannerError = true;
        },
      });
  }
}
