import { Injectable } from '@angular/core';
import { ReceiptDeliveryResult } from '@dotxix/services/receipt/models/receipt-delivery-result.enum';
import { AppRoutes } from '@dotxix/app-routes';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, map, merge, Subject, tap, timer } from 'rxjs';
import { ReceiptQuestionTimeoutService } from '@dotxix/services/receipt/receipt-question-timeout.service';
import { PrintTicketOption } from '@dotxix/models/enums/print-ticket-option';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ElectronicReceiptSender } from 'dotsdk';
import { receiptLogger } from '@dotxix/log-manager';

const logPrefix = '[SMS Receipt Flow] ';

@Injectable({
  providedIn: 'root',
})
export class SmsReceiptFlowService {
  public $sendingSms = new BehaviorSubject<boolean>(false);

  private $userRequestedToReturnToReceiptOptions = new Subject<void>();
  private $smsDelivered = new Subject<void>();
  private $keyboardInputTimeout = new Subject<void>();
  private $userRequestedSendingSms = new Subject<string>();
  private $userKeyboardHadKeyboardActivity = new Subject<void>();

  constructor(
    private router: Router,
    private receiptQuestionTimeoutService: ReceiptQuestionTimeoutService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private checkoutService: CheckoutService
  ) {}

  public async deliver(): Promise<ReceiptDeliveryResult> {
    await this.router.navigate([AppRoutes.SmsReceipt]);
    receiptLogger.debug(`${logPrefix}Prompt user for phone number where to send SMS receipt`);

    const $smsDelivered = this.$smsDelivered.pipe(map(() => ReceiptDeliveryResult.SUCCESS));
    const $userRequestedToReturnToReceiptOptions = this.$userRequestedToReturnToReceiptOptions.pipe(
      map(() => ReceiptDeliveryResult.RETURN_TO_OPTIONS)
    );
    const $timeoutResponse = this.$keyboardInputTimeout.pipe(
      map(() => ReceiptDeliveryResult.TIMEOUT),
      tap(() => receiptLogger.debug(`${logPrefix}SMS input timed out`))
    );
    this.startKeyboardInput();

    return await firstValueFrom(merge($smsDelivered, $userRequestedToReturnToReceiptOptions, $timeoutResponse));
  }

  public userRequestedSendingSms(phoneNumber: string) {
    receiptLogger.debug(`${logPrefix}User requested to send SMS receipt at ${phoneNumber}`);
    this.$userRequestedSendingSms.next(phoneNumber);
  }

  public userRequestedToReturnToReceiptOptions() {
    receiptLogger.debug(`${logPrefix}User requested to return to receipt options`);
    this.$userRequestedToReturnToReceiptOptions.next();
  }

  public userKeyboardHadKeyboardActivity() {
    this.$userKeyboardHadKeyboardActivity.next();
  }

  private startKeyboardInput() {
    const $timeout = this.receiptQuestionTimeoutService.start(this.$userKeyboardHadKeyboardActivity).pipe(map(() => 'timeout'));
    firstValueFrom(merge(this.$userRequestedSendingSms, this.$userRequestedToReturnToReceiptOptions, $timeout)).then(
      (result: string | 'timeout' | void) => {
        if (result === 'timeout') {
          return this.$keyboardInputTimeout.next();
        }
        if (result) {
          this.send(result);
        }
      }
    );
  }

  private async send(phoneNumber: string) {
    this.$sendingSms.next(true);
    const printableContent = await this.checkoutService.generatePrintableReceiptContent({ printTicketOption: PrintTicketOption.FULL });
    const sendResult = await ElectronicReceiptSender.getInstance().sendSmsReceipt(phoneNumber, printableContent);
    this.$sendingSms.next(false);
    if (sendResult.success) {
      receiptLogger.debug(`${logPrefix}SMS with receipt sent successfully`);
      this.$smsDelivered.next();
    } else {
      receiptLogger.debug(`${logPrefix}Failed to send SMS with receipt`);
      const infoDialogRef = this.dynamicContentService.openContent(InfoDialogComponent, {
        title: this.translationsService.translate('2003009'),
        buttonText: this.translationsService.translate('2001014'),
      });
      const $readTimeExpired = timer(10000);
      await firstValueFrom(merge($readTimeExpired, infoDialogRef.afterClosed));
      infoDialogRef.close();
      this.startKeyboardInput();
    }
  }
}
