import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { SessionService } from '@dotxix/services/session.service';
import { DotButton } from 'dotsdk';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { canChangeModifiersFromBasket } from '@dotxix/helpers/auto-pop-feat.helper';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';
import { computeProductDetailItems, computeProductDisplayPrice } from '@dotxix/helpers/basket-display.helper';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import { isAutoPopCombo } from '@dotxix/helpers/auto-pop-combo.helper';
import { AppBIService } from '../../business-intelligence/bi.service';

@Component({
  selector: 'acr-product-card',
  templateUrl: './product-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnChanges {
  @Input() public product!: DotButton<ButtonState>;

  @Input() public children: DotButton[] = [];
  @Input() public allowModifyProduct = true;

  @Output() public changeProductQuantity = new EventEmitter<1 | -1>();
  @Output() public removeProduct = new EventEmitter<void>();
  @Output() public customizeProduct = new EventEmitter<DotButton>();

  public productTitle = '';
  public productImagePath = '';
  public productCanBeCustomized = false;
  public price = 0;
  public productDetailItems: DotButton[] = [];

  constructor(
    private appSettingsService: ApplicationSettingsService,
    private sessionService: SessionService,
    private productTranslationsService: ProductTranslationsService,
    private orderFlowService: OrderFlowService,
    private appBIService: AppBIService
  ) {}

  ngOnChanges() {
    this.productTitle = this.productTranslationsService.translateProductTitle(this.product) ?? '';
    this.productImagePath = this.productTranslationsService.translateButtonPicture(this.product);
    this.productCanBeCustomized =
      (this.product.hasCombos && isAutoPopCombo(this.product, false)) || canChangeModifiersFromBasket(this.product);
    this.price = computeProductDisplayPrice(
      this.sessionService.serviceType,
      this.product,
      this.appSettingsService.settings$.value.quantityCalculated,
      this.children
    );
    this.productDetailItems = computeProductDetailItems(this.product);
  }

  public onQuantityUpdate(count: 1 | -1): void {
    this.changeProductQuantity.next(count);
  }

  public changeProduct(product: DotButton): void {
    this.customizeProduct.next(product);
  }

  public onRemoveClick() {
    this.removeProduct.next();
  }

  public onCustomizeSingleProduct(item: DotButton): void {
    if (!this.product) {
      return;
    }
    if (this.product.hasModifiers) {
      const buttonIndex = this.product.ModifiersPage.Modifiers.findIndex(
        (modifier) => modifier?.Buttons?.find((button) => button === item || button?.Page?.Buttons?.find((btn) => btn === item))
      );
      const isCombo = false;
      this.orderFlowService.customizeBasketSingleProductComponent(this.product, buttonIndex, isCombo);
    }
    if (this.product.hasCombos) {
      const buttonIndex = this.product.ComboPage.Combos.findIndex(
        (combo) => combo?.Buttons?.find((button) => button === item || button?.Page?.Buttons?.find((btn) => btn === item))
      );
      const isCombo = true;
      this.orderFlowService.customizeBasketSingleProductComponent(this.product, buttonIndex, isCombo);
    }
  }
}
