import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BasketDisplayItem } from '@dotxix/models/interfaces/basket-display-item';
import { map } from 'rxjs/operators';
import { computeBasketDisplayItems } from '@dotxix/helpers/basket-display.helper';
import { BasketService } from '@dotxix/services/basket.service';
import { PromosService } from '@dotxix/services/promos.service';
import { DotButton } from 'dotsdk';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import { AppBIService } from '../../business-intelligence/bi.service';
import { BIContexts } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-basket-product-list',
  templateUrl: './basket-product-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketProductListComponent {
  @Input() public allowModifyProduct = true;
  public basketDisplayItems$: Observable<BasketDisplayItem[]> = this.basketService.buttons$.pipe(
    map((basketButtons) => computeBasketDisplayItems(basketButtons))
  );

  private biBasketItemsSubscriber: Subscription | undefined;

  constructor(
    private basketService: BasketService,
    private promosService: PromosService,
    private orderFlowService: OrderFlowService,
    private appBIService: AppBIService
  ) {}

  public ngOnInit() {
    this.biBasketItemsSubscriber = this.basketDisplayItems$.subscribe((basketDisplayItems) => {
      this.appBIService.actions.impressions.viewedBasket(basketDisplayItems);
    });
  }

  public ngOnDestroy() {
    if (this.biBasketItemsSubscriber) {
      this.biBasketItemsSubscriber.unsubscribe();
    }
  }

  public onChangeProductQuantity(basketButton: DotButton, count: 1 | -1) {
    if (count > 0) {
      this.appBIService.actions.clicks.increaseProductQuantity(basketButton, BIContexts.Basket);
    } else if (count < 0) {
      this.appBIService.actions.clicks.decreaseProductQuantity(basketButton, BIContexts.Basket);
    }
    this.basketService.changeProductQuantity(basketButton, count).then();
  }

  public onRemoveProduct(basketButton: DotButton) {
    this.appBIService.actions.clicks.clickedOrderedProductRemove(basketButton);
    this.basketService.removeProductWithConfirmation(basketButton).then();
  }

  public onCustomizeProduct(basketButton: DotButton) {
    this.appBIService.actions.clicks.clickedOrderedProductChange(basketButton);
    this.orderFlowService.customizeBasketProduct(basketButton);
  }

  public trackByProduct(index: number, displayItem: BasketDisplayItem) {
    return displayItem.button.uuid;
  }
}
