import { GetKnownCustomer, GetKnownCustomerApiResponse } from '@dotxix/osiris/models/interfaces/get-known-customer.interface';
import { DotSdkMocksManager, IJsonMocksOverride, MockPromptEffects, TAB_TYPES } from 'dotsdk';

export class GetKnownCustomerMockOverrider implements IJsonMocksOverride {
  private uiPanel!: HTMLElement;
  private dotSdkMockManager = DotSdkMocksManager.getInstance();

  public processInputArgs(mockJson: GetKnownCustomerApiResponse) {
    return new Promise<GetKnownCustomer>((resolve, reject) => {
      const btnSuccess = document.createElement('button');
      const btnFail = document.createElement('button');
      const btnPin = document.createElement('button');

      btnFail.innerText = 'Error';
      btnSuccess.innerText = 'Success';
      btnPin.innerText = 'Pin requested';

      this.uiPanel = this.dotSdkMockManager.addMockPrompt({
        title: `Get known customer Mock`,
        interactiveElements: [
          { htmlElement: btnSuccess, clickHandler: () => this.successClick(resolve, mockJson) },
          { htmlElement: btnFail, clickHandler: this.failClick.bind(this, reject, mockJson) },
          { htmlElement: btnPin, clickHandler: () => this.btnPin(resolve, mockJson) },
        ],
        canRememberChoice: true,
        uniqueId: this.dotSdkMockManager.sanitizePersistedMockReplayKey(`osiris-face-id-mock`),
        hostTabType: TAB_TYPES.PROMISED_MOCKS,
        afterAppendEffects: [MockPromptEffects.OPEN_DRAWER_TAB_AND_FOCUS_MOCK_PROMPT],
        afterCTAEffects: [MockPromptEffects.REMOVE_PANEL_AND_CLOSE_DRAWER_IF_HOST_TAB_EMPTY],
      });
    });
  }
  private successClick(resolve: (value: GetKnownCustomer | PromiseLike<GetKnownCustomer>) => void, mockJson: GetKnownCustomerApiResponse) {
    resolve({ FaceID: mockJson.FaceID, pinRequested: false });
  }
  private failClick(reject: (value: string | PromiseLike<string>) => void, mockJson: GetKnownCustomerApiResponse) {
    reject(mockJson.Error);
  }
  private btnPin(resolve: (value: GetKnownCustomer | PromiseLike<GetKnownCustomer>) => void, mockJson: GetKnownCustomerApiResponse) {
    resolve({ FaceID: mockJson.FaceID, pinRequested: true });
  }
}
