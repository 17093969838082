<div class="page-container">
  <header class="page-header">
    <img src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>

  <section class="flex flex--1 align-center pr--50 pl--50">
    <p class="fs--42 font--secondary text-center" [innerHTML]="legalMessage"></p>
  </section>

  <footer class="page-footer justify-between">
    <button
      class="ui-button ui-button--primary ui-button--medium"
      data-automation="Finish With Other Method"
      (click)="finishWithOtherMethodClicked()"
    >
      <span class="ui-button__wrapper">
        <span>{{ '2001011' | translate }}</span>
      </span>
    </button>
    <button
      class="ui-button ui-button--secondary ui-button--medium"
      data-automation="Accept Legal Requirements"
      (click)="acceptLegalTerms()"
    >
      <span class="ui-button__wrapper">
        <span>{{ '2001016' | translate }}</span>
      </span>
    </button>
  </footer>
</div>
