import { RecommendationSessionParameters } from '@dotxix/services/suggestions/models/recommendation-session-parameters';
import { RecoParameterKey } from '@dotxix/services/suggestions/models/reco-parameter-key';
import { RecoCampaign } from '@dotxix/services/suggestions/models/reco-campaign';
import { RecoVariation } from '@dotxix/services/suggestions/models/reco-variation';
import { RecoParameter } from '@dotxix/services/suggestions/models/reco-parameter';
import { AtpApplicationSettings } from 'dotsdk';

export const defaultRecommendationSessionParameters = (): RecommendationSessionParameters => {
  const bundleSettings = AtpApplicationSettings.getInstance().bundleSettingsJson;
  return {
    [RecoParameterKey.RecommendationMode]: bundleSettings.recoDefaultRecommendationMode || 'CLOUD_ENGINE',
    [RecoParameterKey.RecommendationEngine]: bundleSettings.recoDefaultRecommendationEngine || '',
    [RecoParameterKey.RecommendationMinimumPrice]:
      typeof bundleSettings.recoDefaultRecommendationMinimumPrice === 'number' ? bundleSettings.recoDefaultRecommendationMinimumPrice : -1,
    [RecoParameterKey.RecommendationEnableBasket]:
      typeof bundleSettings.recoDefaultRecommendationEnableBasket === 'boolean'
        ? bundleSettings.recoDefaultRecommendationEnableBasket
        : false,
    [RecoParameterKey.RecommendationEnableCombo]:
      typeof bundleSettings.recoDefaultRecommendationRecommendationEnableCombo === 'boolean'
        ? bundleSettings.recoDefaultRecommendationRecommendationEnableCombo
        : false,
    [RecoParameterKey.RecommendationEnablePopular]:
      typeof bundleSettings.recoDefaultRecommendationRecommendationEnablePopular === 'boolean'
        ? bundleSettings.recoDefaultRecommendationRecommendationEnablePopular
        : false,
    [RecoParameterKey.RecommendationEnableSuggestives]:
      typeof bundleSettings.recoDefaultRecommendationEnableRecommendationEnableSuggestives === 'boolean'
        ? bundleSettings.recoDefaultRecommendationEnableRecommendationEnableSuggestives
        : false,
    [RecoParameterKey.RecommendationEnableUpgrade]:
      typeof bundleSettings.recoDefaultRecommendationRecommendationEnableUpgrade === 'boolean'
        ? bundleSettings.recoDefaultRecommendationRecommendationEnableUpgrade
        : false,
  };
};

export const buildSessionParameters = (
  recoCampaign: RecoCampaign,
  parameters: RecoParameter[],
  variations: RecoVariation[]
): { sessionParameters: RecommendationSessionParameters; optimizeUsedParameters: { [key: string]: string | boolean | number } } => {
  const sessionParameters: RecommendationSessionParameters = { ...defaultRecommendationSessionParameters() };
  const sessionParameterKeys = Object.keys(sessionParameters);

  // load default values from parameters input
  parameters.forEach((parameter) => {
    if (sessionParameterKeys.contains(parameter.name)) {
      (sessionParameters[parameter.name] as string) = parameter.defaultValue;
    }
  });

  // determine parameters with variations
  const parametersWithVariations: {
    [key: string]: {
      key: string;
      values: Array<{
        value: unknown;
        weight: number;
      }>;
    };
  } = {};
  recoCampaign.parameters.forEach((recoCampaignParameterId) => {
    const parameterNode = parameters.find((parameter) => parameter.id === recoCampaignParameterId);
    if (parameterNode && sessionParameterKeys.contains(parameterNode.name)) {
      parametersWithVariations[parameterNode.id] = {
        key: parameterNode.name,
        values: [],
      };
    }
  });

  // find variations for each parameter with variations
  recoCampaign.variations.forEach((recoCampaignVariationId) => {
    const variationNode = variations.find((variation) => variation.id === recoCampaignVariationId);
    if (variationNode) {
      const parameterIds = Object.keys(variationNode.values);
      parameterIds.forEach((parameterId) => {
        if (parametersWithVariations[parameterId]) {
          parametersWithVariations[parameterId].values.push({ value: variationNode.values[parameterId], weight: variationNode.weight });
        }
      });
    }
  });

  // override with randomized value
  const parametersWithVariationsKeys = Object.keys(parametersWithVariations);
  parametersWithVariationsKeys.forEach((parametersWithVariationsKey) => {
    const parametersWithVariation = parametersWithVariations[parametersWithVariationsKey];
    if (parametersWithVariation.values.length > 0) {
      (sessionParameters[parametersWithVariation.key as RecoParameterKey] as unknown) = randomizeRecoParameterValue(
        parametersWithVariation.values
      );
    }
  });

  // generate list of parameter ids and used value
  const optimizeUsedParameters: { [key: string]: string | number | boolean } = {};
  parameters.forEach((parameter) => {
    if (sessionParameterKeys.contains(parameter.name)) {
      optimizeUsedParameters[parameter.id] = sessionParameters[parameter.name];
    }
  });

  fixRecoVariationBooleanParameters(sessionParameters);

  return { sessionParameters, optimizeUsedParameters };
};

export const fixRecoVariationBooleanParameters = (sessionParameters: RecommendationSessionParameters) => {
  const keys = Object.keys(sessionParameters);
  keys.forEach((key: string) => {
    if (
      key === RecoParameterKey.RecommendationEnableBasket ||
      key === RecoParameterKey.RecommendationEnableCombo ||
      key === RecoParameterKey.RecommendationEnablePopular ||
      key === RecoParameterKey.RecommendationEnableSuggestives ||
      key === RecoParameterKey.RecommendationEnableUpgrade
    ) {
      sessionParameters[key] = fixRecoVariationBooleanParameter(sessionParameters[key]);
    }
  });
};

export const fixRecoVariationBooleanParameter = (value: string | boolean) => {
  return typeof value === 'boolean' ? value : value === 'true';
};

export const randomizeRecoParameterValue = (values: Array<{ value: unknown; weight: number }>) => {
  if (values.length === 1) {
    return values[0].value;
  }

  const weightSum = values.reduce((acc, valueWithWeight) => acc + valueWithWeight.weight, 0);
  const random = Math.random() * weightSum;

  let resultingValue = values[0].value;
  let intervalOffset = values[0].weight;
  for (let index = 1; index < values.length; index++) {
    if (random >= intervalOffset) {
      resultingValue = values[index].value;
    }
    intervalOffset += values[index].weight;
  }
  return resultingValue;
};
