import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { modifierButtonRemoveOne, selectModifierButtonInGroup } from '@dotxix/helpers/modifier-group.helper';
import { DotButton, DotModifier } from 'dotsdk';
import {
  computeModifierButtonsListQuantity,
  modifierButtonIncreaseQuantity,
  modifierButtonSetQuantity,
} from '@dotxix/helpers/modifiers.helper';

const DEFAULT_DURATION = 150;

@Component({
  selector: 'acr-collapsible',
  templateUrl: './collapsible.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
})
export class CollapsibleComponent implements OnInit, OnChanges {
  @Input() public buttons: DotButton[] = [];
  @Input() public isExtraButton!: boolean;
  @Input() public complementModifier!: DotModifier;

  @Output() modifierChanged = new EventEmitter<void>();

  public collapsed!: boolean;
  public groupMaxQuantityReached = false;

  public ngOnInit(): void {
    this.collapsed = !this.isExtraButton;
  }

  public ngOnChanges() {
    this.updateGroupMaxQuantityReached();
  }

  public toggleCollapsible() {
    this.collapsed = !this.collapsed;
  }

  public selectModifiers(button: DotButton) {
    if (this.isExtraButton) {
      this.selectExtraButton(button);
    } else {
      this.addSimpleModifierButton(button);
    }
    this.onModifierChanged();
  }

  public onQuantityChanged(button: DotButton, changedQuantity: 1 | -1) {
    if (this.isExtraButton) {
      if (changedQuantity > 0) {
        this.increaseExtraModifierButton(button);
      } else {
        this.decreaseExtraModifierButton(button);
      }
    } else {
      if (changedQuantity > 0) {
        this.addSimpleModifierButton(button);
      } else {
        this.removeSimpleModifierButton(button);
      }
    }
    this.onModifierChanged();
  }

  private onModifierChanged() {
    this.modifierChanged.emit();
  }

  private updateGroupMaxQuantityReached() {
    if (this.isExtraButton && this.complementModifier.PageInfo.MaxQuantity) {
      this.groupMaxQuantityReached = computeModifierButtonsListQuantity(this.buttons) >= this.complementModifier.PageInfo.MaxQuantity;
    }
  }

  private selectExtraButton(button: DotButton) {
    selectModifierButtonInGroup(button, this.complementModifier, computeModifierButtonsListQuantity(this.buttons));
    if (button.quantity > 0) {
      this.removeComplementButton(button);
    }
  }

  private addSimpleModifierButton(button: DotButton) {
    if (button.quantity > 0) {
      modifierButtonSetQuantity(button, 0);
    } else {
      modifierButtonSetQuantity(button, 1);
      this.removeComplementButton(button);
    }
  }

  private removeSimpleModifierButton(button: DotButton) {
    modifierButtonSetQuantity(button, 0);
  }

  private increaseExtraModifierButton(button: DotButton) {
    modifierButtonIncreaseQuantity(
      button,
      this.complementModifier.PageInfo.MaxQuantity || Number.MAX_SAFE_INTEGER,
      computeModifierButtonsListQuantity(this.buttons)
    );
    if (button.quantity > 0) {
      this.removeComplementButton(button);
    }
  }

  private decreaseExtraModifierButton(button: DotButton) {
    modifierButtonRemoveOne(button);
  }

  private removeComplementButton(button: DotButton) {
    const complementButton = this.findComplementButton(button);
    if (complementButton) {
      modifierButtonSetQuantity(complementButton, 0);
    }
  }

  private findComplementButton(button: DotButton) {
    const complementIdAsString = `${button.ComplementId}`;
    return this.complementModifier.Buttons.find((btn) => btn.Link === complementIdAsString);
  }
}
