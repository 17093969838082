const imageExtensions = /(\.png|\.jpg)$/i;
const mp4Extension = /\.mp4$/i;
const webmExtension = /\.webm$/i;

export const isImage = (path: string): boolean => {
  return imageExtensions.test(path);
};
export const isMp4 = (path: string): boolean => {
  return mp4Extension.test(path);
};
export const isWebm = (path: string): boolean => {
  return webmExtension.test(path);
};
