<main class="page-container">
  <header class="page-header">
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <section class="flex flex--column flex--1 justify-evenly align-center">
    <h2 class="text-center" [innerHtml]="'36' | translate"></h2>
    <div class="square bg--primary flex flex--column place-center justify-center">
      <h3 class="color--white text-center" [innerHtml]="'34' | translate"></h3>
      <h1 class="big--heading color--accent" [innerHtml]="orderNumber"></h1>
    </div>

    <h2 *ngIf="orderRecallMode !== OrderRecallModes.Full" class="text-center" [innerHtml]="'35' | translate"></h2>

    <acr-osiris-order-number-info *ngIf="orderRecallMode === OrderRecallModes.Full"></acr-osiris-order-number-info>
  </section>
</main>
