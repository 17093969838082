import { Component } from '@angular/core';
import { InfoDialogDataParams } from '@dotxix/models/interfaces/info-dialog-data-params';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';

@Component({
  selector: 'acr-info-dialog',
  templateUrl: './info-dialog.component.html',
})
export class InfoDialogComponent extends AbstractDynamicAnimatedPopup<InfoDialogDataParams, string> {
  public onControlsButtonsClick(buttonType: string): void {
    this.close(buttonType);
  }
}
