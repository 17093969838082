import { Component } from '@angular/core';
import { EmailReceiptFlowService } from '@dotxix/services/receipt/email-receipt-flow.service';

@Component({
  selector: 'acr-receipt-sms',
  templateUrl: './email-receipt-page.component.html',
})
export class EmailReceiptPageComponent {
  public email = '';
  public $sendingEmail = this.emailReceiptFlowService.$sendingEmail;

  constructor(private emailReceiptFlowService: EmailReceiptFlowService) {}

  public sendEmail() {
    this.emailReceiptFlowService.userRequestedSendingEmail(this.email);
  }

  public backToReceiptOption() {
    this.emailReceiptFlowService.userRequestedToReturnToReceiptOptions();
  }

  public onEmailInputChanged(newEmail: string) {
    this.email = newEmail;
    this.emailReceiptFlowService.userKeyboardHadKeyboardActivity();
  }
}
