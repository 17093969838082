import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { FaceRecallHttpService } from './face-recall-http.service';

@Injectable({ providedIn: 'root' })
export class GetFaceRecallImageService {
  public faceRecallmage$ = new BehaviorSubject<SafeUrl | null>(null);
  public base64Image$ = this.faceRecallmage$.asObservable();
  constructor(
    private faceRecallHttpService: FaceRecallHttpService,
    private domSanitizer: DomSanitizer
  ) {}
  public getFaceRecallImage() {
    this.faceRecallHttpService
      .getLastPreview()
      .then((image) => {
        this.faceRecallmage$.next(this.domSanitizer.bypassSecurityTrustUrl(image));
      })
      .catch((error) => {
        console.log('[getFaceRecallImage] error: ', error);
      });
  }

  public resetFaceRecallImageState() {
    this.faceRecallmage$.next(null);
  }
}
