import { Component, Input } from '@angular/core';
import { animateHeightEnter, animateHeightLeave } from '@dotxix/custom-theme/helpers/general-helpers.helper';

@Component({
  selector: 'acr-accordeon',
  templateUrl: 'accordeon.component.html',
  styles: [
    `
      .container {
        display: flex;
        flex-direction: column;
        padding-bottom: 20rem;
        border-bottom: 1px solid #0000003d;
      }
      .title {
        font-size: 30rem;
        padding: 20rem 0;
        color: black;
        position: relative;
      }
      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.35s;
      }
    `,
  ],
  animations: [animateHeightEnter, animateHeightLeave],
})
export class AccordeonComponent {
  public toggle = false;
  @Input() title!: string;

  public toggleContent() {
    this.toggle = !this.toggle;
  }

  // @HostListener('document:click', ['$event.target'])
  // public onAccordeonClick(targetElement: ElementRef) {
  //   const clickedInside = this.ref.nativeElement.contains(targetElement);
  //   if (!clickedInside && this.toggle === true) {
  //     this.toggle = false;
  //   }
  // }
}
