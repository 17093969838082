import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isAutoPopFeatVisible } from '@dotxix/helpers/auto-pop-feat.helper';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { DotButton, DotModifier, DotPrefixId } from 'dotsdk';
import { ButtonModifierSubgroupComponent } from '@dotxix/components/button-modifier-subgroup/button-modifier-subgroup.component';
import { computeModifierSelectedQuantity, modifierButtonIncreaseQuantity } from '@dotxix/helpers/modifiers.helper';
import { modifierButtonAddOne, modifierButtonRemoveOne, selectModifierButtonInGroup } from '@dotxix/helpers/modifier-group.helper';
import { isItemPackButton, isNotItemPackButton } from '@dotxix/helpers/button.helper';
import {
  doesNotHavePrefixes,
  hasPrefixes,
  modifierButtonDecreasePrefixQuantity,
  modifierButtonIncreasePrefixQuantity,
  modifierButtonsWithoutPrefixes,
} from '@dotxix/helpers/prefixes.helper';
import { bucketStandardAddModifierButton } from '@dotxix/helpers/modifier-bucket-standard.helper';
import * as _ from 'lodash';
import { updateModifiersChargeThresholdWithinGroupLimit } from '@dotxix/helpers/charge-threshold.helper';
import { AppBIService } from '../../business-intelligence/bi.service';
import { BIContexts } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-button-modifier-list',
  templateUrl: './button-modifier-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonModifierListComponent implements OnInit {
  @Input() public modifier!: DotModifier;
  @Input() public onOrder = false;
  @Input() public isCombo = false;
  @Output() public modifierChanged = new EventEmitter<void>();

  public displayModifierButtons!: boolean;
  public isComplementModifier!: boolean;
  public groupMaxQuantityReached = false;
  public isBucketStandard!: boolean;
  public isDualModifiers!: boolean;
  public isIceModifier!: boolean;
  public hasIceModifierButtonsAndTags!: boolean;
  public chargeThresholdGroup!: number;

  constructor(
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private changeDetectorRef: ChangeDetectorRef,
    private appBiService: AppBIService
  ) {}

  public ngOnInit(): void {
    this.displayModifierButtons = isAutoPopFeatVisible(this.modifier, this.onOrder, !this.isCombo);
    this.isComplementModifier = this.modifier.modifierTemplate.toLowerCase() === 'complement';
    this.isIceModifier = this.modifier.modifierTemplate.toLowerCase() === 'ice';
    this.hasIceModifierButtonsAndTags = this.modifier.Buttons.every((b) => b.Tags?.includes('withIce') || b.Tags?.includes('withoutIce'));
    this.isBucketStandard = this.modifier.Buttons.filter((modifierButton) => doesNotHavePrefixes(modifierButton)).length === 2;
    this.isDualModifiers = this.modifier.Buttons.length === 2 && this.modifier.PageInfo.MaxQuantity === 1;
    this.chargeThresholdGroup = this.modifier?.PageInfo?.ChargeThreshold || 0;
    this.updateGroupMaxQuantityReached();
  }

  public trackByLink(index: number, modifierButton: DotButton) {
    return modifierButton.Link;
  }

  public isItemPack(modifierButton: DotButton) {
    return isItemPackButton(modifierButton);
  }

  public onModifierChanged() {
    this.modifier.Buttons = _.cloneDeep(this.modifier.Buttons);
    this.updateGroupMaxQuantityReached();
    this.modifierChanged.emit();
  }

  public onItemPackClicked(itemPackButton: DotButton) {
    this.appBiService.actions.clicks.clickedProduct(itemPackButton, BIContexts.SingleProductCustomization);
    this.dynamicContentService
      .openContent(ButtonModifierSubgroupComponent, {
        modifier: this.modifier,
        itemPackButtonLink: itemPackButton.Link,
      })
      .afterClosed.subscribe((modifier) => {
        if (modifier) {
          this.modifier.Buttons = modifier.Buttons;
          this.onModifierChanged();
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  public onModifierButtonClicked(button: DotButton) {
    this.appBiService.actions.clicks.clickedProduct(button, BIContexts.SingleProductCustomization);
    // prefixes logic
    if (hasPrefixes(button)) {
      modifierButtonIncreasePrefixQuantity(button);
      if (button.selectedPrefixId !== DotPrefixId.EXTRA && button.selectedPrefixId !== DotPrefixId.NO) {
        updateModifiersChargeThresholdWithinGroupLimit(this.chargeThresholdGroup, this.modifier.Buttons, 1);
      }
      this.onModifierChanged();
      return;
    }

    // if no item pack is present
    if (this.modifier.Buttons.every((modifierButton) => isNotItemPackButton(modifierButton))) {
      // bucket standard
      if (this.isBucketStandard) {
        const btnModifierQuantity = button.quantity;
        bucketStandardAddModifierButton(button, modifierButtonsWithoutPrefixes(this.modifier), this.modifier);
        if (button.quantity !== btnModifierQuantity) {
          updateModifiersChargeThresholdWithinGroupLimit(
            this.chargeThresholdGroup,
            this.modifier.Buttons,
            button.quantity - btnModifierQuantity === 1 ? 1 : -1
          );
        }
        this.onModifierChanged();
        return;
      }

      // group max quantity 1
      if (this.modifier.PageInfo.MaxQuantity === 1) {
        const btnModifierQuantity = button.quantity;
        if (button.quantity > 0) {
          modifierButtonRemoveOne(button);
        } else {
          const groupModifierButtons = modifierButtonsWithoutPrefixes(this.modifier);
          const selectedModifierButton = groupModifierButtons.find((groupModifierButton) => groupModifierButton.quantity > 0);
          if (selectedModifierButton) {
            const selectedModifierButtonQuantity = selectedModifierButton.quantity;
            modifierButtonRemoveOne(selectedModifierButton);
            updateModifiersChargeThresholdWithinGroupLimit(
              this.chargeThresholdGroup,
              this.modifier.Buttons,
              selectedModifierButton.quantity - selectedModifierButtonQuantity === 1 ? 1 : -1
            );
            if (selectedModifierButton.quantity === 0) {
              modifierButtonAddOne(button, 1);
            }
          } else {
            modifierButtonAddOne(button, 1);
          }
        }
        if (button.quantity !== btnModifierQuantity) {
          updateModifiersChargeThresholdWithinGroupLimit(
            this.chargeThresholdGroup,
            this.modifier.Buttons,
            button.quantity - btnModifierQuantity === 1 ? 1 : -1
          );
        }
        this.onModifierChanged();
        return;
      }
    }

    const btnModifierQuantity = button.quantity;
    selectModifierButtonInGroup(button, this.modifier, computeModifierSelectedQuantity(this.modifier));
    if (button.quantity > btnModifierQuantity) {
      updateModifiersChargeThresholdWithinGroupLimit(this.chargeThresholdGroup, this.modifier.Buttons, -1);
    }
    if (button.quantity < btnModifierQuantity) {
      for (let i = 0; i < btnModifierQuantity - button.quantity; i++) {
        updateModifiersChargeThresholdWithinGroupLimit(this.chargeThresholdGroup, this.modifier.Buttons, -1);
      }
    }
    this.onModifierChanged();
  }

  public onButtonModifierQuantityChanged(changedQuantity: number, modifierButton: DotButton) {
    if (changedQuantity > 0) {
      this.appBiService.actions.clicks.increaseProductQuantity(modifierButton, BIContexts.SingleProductCustomization);
      if (this.isBucketStandard && this.modifier.Buttons.every((modifierButton) => isNotItemPackButton(modifierButton))) {
        bucketStandardAddModifierButton(modifierButton, modifierButtonsWithoutPrefixes(this.modifier), this.modifier);
      } else {
        this.onButtonModifierQuantityIncrease(modifierButton);
      }
    } else {
      this.appBiService.actions.clicks.decreaseProductQuantity(modifierButton, BIContexts.SingleProductCustomization);
      this.onButtonModifierQuantityDecrease(modifierButton);
    }
    updateModifiersChargeThresholdWithinGroupLimit(this.chargeThresholdGroup, this.modifier.Buttons, changedQuantity > 0 ? 1 : -1);
    this.onModifierChanged();
  }

  private onButtonModifierQuantityIncrease(modifierButton: DotButton) {
    if (hasPrefixes(modifierButton)) {
      modifierButtonIncreasePrefixQuantity(modifierButton);
      return;
    }

    modifierButtonIncreaseQuantity(
      modifierButton,
      this.modifier.PageInfo.MaxQuantity || Number.MAX_SAFE_INTEGER,
      computeModifierSelectedQuantity(this.modifier)
    );
  }

  private onButtonModifierQuantityDecrease(modifierButton: DotButton) {
    if (hasPrefixes(modifierButton)) {
      modifierButtonDecreasePrefixQuantity(modifierButton);
      return;
    }

    modifierButtonRemoveOne(modifierButton);
  }

  private updateGroupMaxQuantityReached() {
    if (this.modifier.PageInfo.MaxQuantity) {
      this.groupMaxQuantityReached = computeModifierSelectedQuantity(this.modifier) >= this.modifier.PageInfo.MaxQuantity;
    }
  }
}
