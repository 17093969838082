import { Injectable } from '@angular/core';
import { AppFlowService } from '@dotxix/services/app-flow.service';
import { BehaviorSubject, combineLatestWith, firstValueFrom, Subscription, timer } from 'rxjs';
import { AtpScannerService, DotButton, PosServingLocation } from 'dotsdk';
import { SessionService } from '@dotxix/services/session.service';
import { PayTowerService } from '@dotxix/services/pay-tower.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { Router } from '@angular/router';
import { AppRoutes } from '@dotxix/app-routes';
import { getDotButtonsFromBasketButtons } from '@dotxix/helpers/pay-tower.helper';
import { BasketService } from '@dotxix/services/basket.service';
import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { InactivityService } from '@dotxix/services/inactivity.service';
import { ParentOrderIdService } from '@dotxix/osiris/services/parent-order-id.service';
import { StatusService } from '@dotxix/services/status.service';
import { AppBIService } from '../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Injectable({
  providedIn: 'root',
})
export class PayTowerAppFlowService {
  public $errorMessage = new BehaviorSubject<string | null>(null);
  public $scanAllowed = new BehaviorSubject(true);
  public $readyToScan = new BehaviorSubject(false);

  private scanSubscription: Subscription | undefined;

  constructor(
    private appFlowService: AppFlowService,
    private applicationSettingsService: ApplicationSettingsService,
    private sessionService: SessionService,
    private payTowerService: PayTowerService,
    private translationsService: TranslationsService,
    private router: Router,
    private basketService: BasketService,
    private checkoutFlowService: CheckoutFlowService,
    private inactivityService: InactivityService,
    private parentOrderIdService: ParentOrderIdService,
    private statusService: StatusService,
    private appBiService: AppBIService
  ) {}

  public initialize() {
    this.sessionService.onRestartSession.subscribe(() => this.$scanAllowed.next(true));
    this.$scanAllowed
      .pipe(combineLatestWith(this.statusService.canStartOrdering$, this.applicationSettingsService.settings$))
      .subscribe(([scanAllowed, canStartOrdering, settings]) => {
        if (canStartOrdering && settings.isPayTower && scanAllowed) {
          this.startScan();
        } else {
          this.stopScan();
        }
      });
  }

  private startScan() {
    if (!this.scanSubscription) {
      this.$readyToScan.next(true);
      this.scanSubscription = AtpScannerService.getInstance()
        .startContinuouslyScan()
        .subscribe((scanString) => this.scanStringReceived(scanString));
    }
  }

  private stopScan() {
    if (this.scanSubscription) {
      this.$readyToScan.next(false);
      this.scanSubscription.unsubscribe();
      delete this.scanSubscription;
    }
  }

  private scanStringReceived(scanString: string) {
    this.inactivityService.resetInactivityProducerTimer();
    this.router.navigate([AppRoutes.PayTowerStartPage]);
    this.$scanAllowed.next(false);
    const payTowerOrderId = this.payTowerService.extractPayTowerOrderIdFromScannedString(scanString);
    if (!payTowerOrderId) {
      this.showErrorMessage('2001044').then(() => this.$scanAllowed.next(true));
      return;
    }

    this.payTowerService.retrieveOrder(payTowerOrderId).then((recalledOrder) => {
      if (recalledOrder) {
        const { servingLocation, basket, parentOrderID } = recalledOrder;
        const buttons = getDotButtonsFromBasketButtons(basket, servingLocation);
        if (basket.length === buttons.length) {
          this.startSessionAndGoToPaymentSelection(servingLocation, buttons, parentOrderID);
        } else {
          this.showErrorMessage('2001045').then(() => this.$scanAllowed.next(true));
        }
      } else {
        this.showErrorMessage('2001044').then(() => this.$scanAllowed.next(true));
      }
    });
  }

  private async showErrorMessage(i18nMessageId: string) {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.PAYTOWER_PAYMENT_ERROR);
    this.$errorMessage.next(this.translationsService.translate(i18nMessageId));
    await firstValueFrom(timer(6000));
    this.$errorMessage.next(null);
  }

  private async startSessionAndGoToPaymentSelection(
    servingLocation: PosServingLocation,
    buttons: DotButton[],
    parentOrderID: string | undefined
  ) {
    await this.sessionService.startSession(servingLocation);
    if (parentOrderID) {
      this.parentOrderIdService.setParentOrderId(parentOrderID);
    }
    buttons.forEach((button) => this.basketService.addButtonToBasket(button));
    await this.checkoutFlowService.startCheckoutTunnel();
  }
}
