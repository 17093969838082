<div class="popup product-info" data-automation="product-info-popup">
  <div class="popup__content product-info__content" [class.product-info__content--withoutTabs]="!isAtLeastOneTabAvailable()">
    <main class="popup__page-container product-info__page-container">
      <section class="product-info__info">
        <header class="product-info__info--header">
          <img
            class="product-info__info--img"
            [src]="button | translatePicture"
            [class.logo]="!(button | translatePicture)"
            alt=""
            width="250rem"
            height="250rem"
          />
          <div class="flex flex--column justify-center gap--20">
            <h2 class="product-info__info--title">{{ button | translateCaption }}</h2>
            <span *ngIf="description" class="product-info__info--description font--light" [innerHTML]="description"></span>
            <div class="flex">
              <h4>{{ price | dotCurrency }}</h4>
              <h4 *ngIf="calories" class="font--light">
                <span class="product-info__info--calories--separator">&nbsp; - &nbsp;</span>
                <span>{{ calories.Value }}</span>
                <span>{{ calories | nutritionalUnit }}</span>
              </h4>
            </div>
          </div>
        </header>

        <section *ngIf="isAtLeastOneTabAvailable()" class="product-info__tabs">
          <header class="product-info__tabs--header">
            <button
              *ngIf="nutritionalFacts && nutritionalFacts.length > 0"
              class="product-info__tab"
              [class.product-info__tab--selected]="currentStep === infoSteps.nutritionView"
              (click)="changeCurrentStep(infoSteps.nutritionView)"
            >
              {{ '2023030601' | translate }}
            </button>
            <button
              *ngIf="ingredients && ingredients.length > 0"
              class="product-info__tab"
              [class.product-info__tab--selected]="currentStep === infoSteps.ingredientsView"
              (click)="changeCurrentStep(infoSteps.ingredientsView)"
            >
              {{ '2022102701' | translate }}
            </button>
            <button
              *ngIf="allergens && allergens.length > 0"
              class="product-info__tab"
              [class.product-info__tab--selected]="currentStep === infoSteps.allergensView"
              (click)="changeCurrentStep(infoSteps.allergensView)"
            >
              {{ '2022102702' | translate }}
            </button>
          </header>

          <ng-container [ngSwitch]="currentStep">
            <ng-container *ngSwitchCase="infoSteps.nutritionView">
              <section class="product-info__nutrition">
                <article class="product-info__nutrition--article" *ngFor="let nutritionFact of nutritionalFacts">
                  <div class="flex justify-between">
                    <h3 class="product-info__nutrition--label">{{ nutritionFact | nutritionalLabel }}</h3>
                    <span class="product-info__nutrition--value" *ngIf="nutritionFact.Value">
                      {{ nutritionFact.Value }}{{ nutritionFact | nutritionalUnit }}
                    </span>
                  </div>
                  <p class="product-info__nutrition--description" [innerHTML]="nutritionFact | nutritionalDescription"></p>
                </article>
              </section>
              <p class="product-info__general-calories-message" [innerHTML]="'2023030701' | translate"></p>
            </ng-container>

            <ng-container *ngSwitchCase="infoSteps.ingredientsView">
              <section class="product-info__ingredients">
                <ng-container *ngFor="let ingredient of ingredients">
                  <p class="product-info__ingredients--ingredient">{{ ingredient | translateIngredientLabel }}</p>
                </ng-container>
              </section>
            </ng-container>

            <ng-container *ngSwitchCase="infoSteps.allergensView">
              <section>
                <div *ngIf="allergens && allergens.length > 0">
                  <div class="product-info__product-allergens flex">
                    <div *ngFor="let allergen of allergens" class="allergen flex flex--column">
                      <span class="product-info__allergen-icon">
                        <img
                          skin-part="image"
                          alt=""
                          src="/Channels/skin/icon-allergens{{ allergen.Name }}-selected.svg"
                          class="ui-button--icon size--56 color--primary"
                        />
                      </span>
                      <span class="fs--32 font--light m--auto uppercase">
                        {{ allergen | translateAllergenLabel }}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </ng-container>
          </ng-container>
        </section>
      </section>
    </main>

    <footer class="popup__actions justify-center">
      <button
        class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
        (click)="closeClick()"
        data-automation="Button Close"
      >
        <span class="ui-button__wrapper">
          <span>{{ '24' | translate }}</span>
        </span>
      </button>
    </footer>
  </div>
</div>
