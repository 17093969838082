<main class="page-container heset-qr-code-login">
  <header class="page-header">
    <img src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <ng-container [ngSwitch]="qRCodeScreenStep$ | async">
    <ng-container *ngSwitchCase="qrCodeScreenSteps.ConnectingToSocket">
      <section class="heset-qr-code-login__scanning">
        <header class="heset-qr-code-login__messages heset-qr-code-login__messages--top">
          <p class="heset-qr-code-login__title" [innerHtml]="'2022060903' | translate"></p>
        </header>
        <section class="heset-qr-code-login__container">
          <img class="heset-qr-code-login__qr-code-animation" src="/Channels/skin/qr-code-animation.gif" alt="" />
        </section>
      </section>
      <footer class="page-footer flex justify-center place-center">
        <button class="ui-button ui-button--primary ui-button--x-large ui-button--tertiary" (click)="continueWithoutAction()">
          {{ '2022042901' | translate }}
        </button>
      </footer>
    </ng-container>

    <ng-container *ngSwitchCase="qrCodeScreenSteps.ShowingQrCode">
      <section class="heset-qr-code-login__scanning">
        <header class="heset-qr-code-login__messages heset-qr-code-login__messages--top">
          <p class="heset-qr-code-login__title" [innerHtml]="'2022060902' | translate"></p>
        </header>
        <section class="heset-qr-code-login__container">
          <qrcode [qrdata]="qrString" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
        </section>
        <footer class="heset-qr-code-login__messages heset-qr-code-login__messages--bottom">
          <p class="heset-qr-code-login__title" [innerHtml]="'2022060905' | translate"></p>
        </footer>
      </section>
      <footer class="page-footer flex justify-center place-center">
        <button class="ui-button ui-button--primary ui-button--x-large ui-button--tertiary" (click)="continueWithoutAction()">
          {{ '2022042901' | translate }}
        </button>
      </footer>
    </ng-container>

    <ng-container *ngSwitchCase="qrCodeScreenSteps.ConnectionError">
      <section class="heset-qr-code-login__scanning">
        <header class="heset-qr-code-login__messages heset-qr-code-login__messages--top"></header>
        <section class="heset-qr-code-login__container">
          <img alt="" src="/Channels/skin/icon-iconWarning.svg" class="ui-button--icon size--160" />
        </section>
        <footer class="heset-qr-code-login__messages heset-qr-code-login__messages--bottom">
          <p class="heset-qr-code-login__title color--errorRed" [innerHtml]="'2022060904' | translate"></p>
        </footer>
      </section>
      <footer class="page-footer flex justify-center place-center">
        <button class="ui-button ui-button--secondary ui-button--x-large" (click)="tryAgainAction()">{{ '2022042902' | translate }}</button>
        <button class="ui-button ui-button--tertiary ui-button--x-large" (click)="continueWithoutAction()">
          {{ '2022042901' | translate }}
        </button>
      </footer>
    </ng-container>
  </ng-container>
</main>
