import { Component, OnInit } from '@angular/core';
import { PosElogHandler } from 'dotsdk';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-preorder',
  templateUrl: './preorder.component.html',
})
export class PreorderComponent implements OnInit {
  constructor(private appBiService: AppBIService) {}

  public get isTableServiceActive(): boolean {
    return !!PosElogHandler.getInstance().posConfig.posHeader?.cvars?.TS_No;
  }

  public ngOnInit() {
    this.appBiService.actions.impressions.viewPayAtCounterInfo();
  }
}
