<language-change-view-trigger></language-change-view-trigger>
<ng-container *ngIf="languages$ | async as languages">
  <section *ngIf="languages.length > 1" class="languages">
    <h4 class="languages__title">{{ '13' | translate }}</h4>
    <div class="languages__wrapper">
      <button
        class="languages__type"
        *ngFor="let language of languages"
        (click)="onLanguageButtonClicked(language.code)"
        [class.languages__type--active]="language.active"
        [attr.data-automation]="'Language: ' + language.name"
      >
        <img
          class="languages__type--image"
          [src]="'/Channels/skin/language-' + language.code + '.svg'"
          alt=""
          width="72rem"
          height="72rem"
        />
      </button>
    </div>
  </section>
</ng-container>
