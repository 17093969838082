<language-change-view-trigger></language-change-view-trigger>
<div class="product product--modifier" [class.selected]="selected" [class.disabled]="button | isButtonDisabled">
  <button [attr.data-automation]="'Button: ' + button.Link" class="product__button">
    <header class="product__header">
      <h5 class="product__title">{{ button | translateCaption }}</h5>
    </header>
    <img class="product__img" [src]="button | translatePicture" alt="" width="180rem" height="180rem" />
  </button>
  <ng-container *ngIf="button | isButtonDisabled">
    <div class="disabled--overlay">{{ '20210401001' | translate }}</div>
  </ng-container>

  <acr-item-pack-indicator [checked]="selected"></acr-item-pack-indicator>
</div>
