<div class="page-container osiris-order-review" *ngIf="reviewOrder$ | async as reviewOrder">
  <section class="osiris-order-review__header">
    <h2 class="color--white fs--64">
      {{ '20210403001' | translate }}
    </h2>
    <h4 class="color--white fs--46">
      <time>{{ reviewOrder.orderInfo.orderDate | date: ' dd/MM/yyyy - H:mm ' }}</time>
    </h4>
  </section>
  <acr-scrollable-container>
    <section class="osiris-order-review__orders">
      <acr-product-card *ngFor="let button of reviewOrder.basketButtons" [product]="button" [allowModifyProduct]="false"></acr-product-card>
    </section>
  </acr-scrollable-container>
  <acr-footer>
    <button left-slot class="ui-button ui-button--primary ui-button--iconLeft ui-button--small" (click)="goBack()">
      <span class="ui-button__wrapper justify-around">
        <img skin-part="image" src="/Channels/skin/icon-chevronLeft-back.svg" class="ui-button--icon size--25" alt="" acrRevertOnRtl />
        <span>{{ '16' | translate }}</span>
      </span>
    </button>

    <button *ngIf="true" right-slot class="ui-button ui-button--secondary ui-button--x-large ui-button--iconRight" (click)="addToBasket()">
      <span class="ui-button__wrapper">
        <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
        <span>{{ '27' | translate }}</span>
      </span>
    </button>
  </acr-footer>
</div>
