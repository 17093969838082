import { Injectable } from '@angular/core';
import { DynamicContentRef } from '@dotxix/services/dynamic-content/models/dynamic-content.ref';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { Subject } from 'rxjs';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';
import { OsirisService } from '@dotxix/osiris/services/osiris.service';
import { OsirisLoginScanComponent } from '@dotxix/osiris/components/osiris-login-scan/osiris-login-scan.component';

@Injectable({
  providedIn: 'root',
})
export class OsirisLoginScanService {
  public customerAuthenticated$ = new Subject<{ customerId: string; orders: OsirisOrder[] }>();
  public newCustomer$ = new Subject<string>();

  private scanScreen: DynamicContentRef<void, void> | null = null;

  constructor(
    private osirisService: OsirisService,
    private dynamicContentService: DynamicContentService
  ) {}

  public loginWithScan() {
    this.scanScreen = this.dynamicContentService.openContent(OsirisLoginScanComponent);
  }

  public async validateBarcodeScan(customerId: string) {
    const response = await this.osirisService.getCustomerLastOrders(customerId);
    if (response && response.Orders) {
      if (response.Orders.length) {
        this.hideScanScreen();
        this.customerAuthenticated$.next({ customerId, orders: response.Orders });
      } else {
        this.hideScanScreen();
        this.newCustomer$.next(customerId);
      }
    }
  }

  public scanCanceled() {
    this.hideScanScreen();
  }

  private hideScanScreen() {
    if (this.scanScreen) {
      this.scanScreen.close();
      this.scanScreen = null;
    }
  }
}
