import { Injectable } from '@angular/core';
import { ComboStepType, DotButton, DotButtonType, DotCombo, DotModifier, getCatalogButton, getCombosCatalogButton } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { ModifierState } from '@dotxix/models/interfaces/modifier-state';

@Injectable({
  providedIn: 'root',
})
export class ProductTranslationsService {
  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private translationsService: TranslationsService
  ) {}

  public translateProductTitle(button: DotButton) {
    if (button.hasCombos) {
      const parentButton = getCombosCatalogButton(button.ComboPage.ID.toString());
      const title = this.translationsService.getTranslatedButtonCaption(parentButton);
      if (title === null) {
        return this.translationsService.getTranslatedButtonCaption(button);
      }
      return `${title} ${this.subtitle(button)}`;
    }

    const translateCatalog = this.translateCatalogTitle(button);
    if (button.hasModifiers && translateCatalog) {
      return translateCatalog;
    }

    return this.translationsService.getTranslatedButtonCaption(button);
  }

  public translateCatalogTitle(button: DotButton) {
    let catalogButton;
    if (button.ButtonType === DotButtonType.ITEM_BUTTON) {
      catalogButton = getCatalogButton(button.Link);
    } else if (button.ButtonType === DotButtonType.MENU_BUTTON) {
      catalogButton = getCombosCatalogButton(button.Link);
    }
    if (catalogButton) {
      return this.translationsService.getTranslatedButtonCaption(catalogButton);
    }

    return null;
  }

  translatePicture(button: DotButton): string | null {
    const path = this.translationsService.getTranslatedButtonPicture(button);
    if (path) {
      return `${this.applicationSettingsService.settings$.value.bridgeAssetsPath}/items/${path}`;
    }
    return null;
  }

  translateCatalogPicture(button: DotButton): string | null {
    let catalogButton = null;
    if (button.ButtonType === DotButtonType.ITEM_BUTTON) {
      catalogButton = getCatalogButton(button.Link);
    } else if (button.ButtonType === DotButtonType.MENU_BUTTON) {
      catalogButton = getCombosCatalogButton(button.Link);
    }
    return catalogButton ? this.translatePicture(catalogButton) : null;
  }

  public translateButtonPicture(button: DotButton): string {
    if (button.hasCombos) {
      const parentButton = getCombosCatalogButton(button.ComboPage.ID.toString());
      return parentButton ? this.translatePicture(parentButton) ?? '' : '';
    }

    const translateCatalog = this.translateCatalogPicture(button);
    if (button.hasModifiers && translateCatalog) {
      return translateCatalog;
    }

    return button ? this.translatePicture(button) ?? '' : '';
  }

  public translatePrefixedModifierCaption(button: DotButton) {
    if (button.DefaultQuantity === button.quantity) {
      return '';
    }

    if (button.DefaultQuantity < button.quantity) {
      const addedQuantity = button.quantity - button.DefaultQuantity;
      const buttonCaption = this.translationsService.getTranslatedButtonCaption(button);
      return `${this.translationsService.translate('2021050702')} ${addedQuantity} ${buttonCaption}`;
    }

    if (button.quantity > 0) {
      const buttonCaption = this.translationsService.getTranslatedButtonCaption(button);
      const remove = this.translationsService.translate('2021060203');
      return `${remove} ${buttonCaption}`;
    }

    if (button.quantity === 0) {
      const buttonCaption = this.translationsService.getTranslatedButtonCaption(button);
      const without = this.translationsService.translate('2021060201');
      return `${without} ${buttonCaption}`;
    }

    return '';
  }

  public translateSelectedModifiers(button: DotButton) {
    if (!button.hasModifiers) {
      return '';
    }

    const parts: string[] = [];
    button.ModifiersPage.Modifiers.forEach((modifier: DotModifier<ModifierState>) => {
      modifier.Buttons.forEach((modifierButton: DotButton<ButtonState>) => {
        if (modifierButton.state.hasIceModifierTemplate) {
          const hasIceTags = modifierButton.Tags?.includes('withIce') || modifierButton.Tags?.includes('withoutIce');

          if (hasIceTags) {
            if (modifierButton.Tags.includes('withIce')) {
              const textId = modifierButton.state.hasOnlyOneIceButton
                ? modifierButton.quantity > 0
                  ? '2022120501'
                  : '2022120502'
                : modifierButton.quantity > 0
                ? '2022120501'
                : undefined;
              if (textId) {
                parts.push(this.translationsService.translate(textId));
              }
            } else {
              const textId = modifier.state.hasOnlyOneIceButton
                ? modifierButton.quantity > 0
                  ? '2022120502'
                  : '2022120501'
                : modifierButton.quantity > 0
                ? '2022120502'
                : undefined;
              if (textId) {
                parts.push(this.translationsService.translate(textId));
              }
            }
          }
        } else {
          if (modifierButton.quantity > 1 && !modifierButton.DefaultQuantity) {
            const buttonCaption = this.translationsService.getTranslatedButtonCaption(modifierButton);
            const text = this.translationsService.currentLanguageIsRtl$.value
              ? `${buttonCaption} x ${modifierButton.quantity}`
              : `${modifierButton.quantity} x ${buttonCaption}`;
            parts.push(text);
          }
          if (modifierButton.quantity === 1 && !modifierButton.DefaultQuantity) {
            const buttonCaption = this.translationsService.getTranslatedButtonCaption(modifierButton);
            if (buttonCaption) {
              parts.push(buttonCaption);
            }
          }
          if (modifierButton.DefaultQuantity > 0) {
            const prefixedModifierCaption = this.translatePrefixedModifierCaption(modifierButton);
            if (prefixedModifierCaption) {
              parts.push(prefixedModifierCaption);
            }
          }
        }
      });
    });

    return parts.join(', ');
  }

  public translateComboImage(combo: DotCombo): string | null {
    const path = this.translationsService.translateComboImage(combo);
    if (path) {
      return `${this.applicationSettingsService.settings$.value.bridgeAssetsPath}/items/${path}`;
    }
    return null;
  }

  public translateModifierImage(modifier: DotModifier): string | null {
    const path = this.translationsService.translateModifierImage(modifier);
    if (path) {
      return `${this.applicationSettingsService.settings$.value.bridgeAssetsPath}/items/${path}`;
    }
    return null;
  }

  private subtitle(button: DotButton): string {
    const upsizeButton = button?.ComboPage?.Combos.find(
      (combo) => combo.ComboStepType.toLowerCase() === ComboStepType.ComboUpSize.toLowerCase()
    )?.Buttons.find((btn) => btn.Selected);
    return upsizeButton ? this.translationsService.getTranslatedButtonCaption(upsizeButton) ?? '' : '';
  }
}
