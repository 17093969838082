<language-change-view-trigger></language-change-view-trigger>
<div class="collapsible full">
  <button class="collapsible__toggle" (click)="toggleCollapsible()" data-automation="Button Toggle Collapsible">
    <h4 class="collapsible__title">
      {{ (isExtraButton ? '2021012501' : '64') | translate }}
      <img
        skin-part="image"
        [src]="collapsed ? '/Channels/skin/icon-chevronUp.svg' : '/Channels/skin/icon-chevronDown.svg'"
        class="ui-button--icon size--20 color--accent collapsible__toggle--arrow"
        alt=""
      />
    </h4>
  </button>

  <div
    class="collapsible__content"
    [@collapse]="collapsed"
    [attr.data-automation]="'Collapsible Content: ' + (collapsed ? 'Closed' : 'Open')"
  >
    <div class="modifiers-grid centered">
      <acr-button-modifier
        *ngFor="let button of buttons"
        [modifierButton]="button"
        [groupMaxQuantity]="complementModifier.PageInfo.MaxQuantity"
        [disableIncrease]="groupMaxQuantityReached"
        (clicked)="selectModifiers(button)"
        (quantityChanged)="onQuantityChanged(button, $event)"
      ></acr-button-modifier>
    </div>
  </div>
</div>
