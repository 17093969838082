import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { DOTPlatform, IMockConfiguration } from 'dotsdk';
import { appLogger } from '@dotxix/log-manager';

if (environment.production) {
  enableProdMode();
}

const mockOptions: IMockConfiguration = {
  useMocksForEnvironment: environment.useMocksForEnvironment,
  environmentMocksPath: environment.environmentMocksPath,
  useMocksForPay: environment.useMocksForPay,
  payMocksPath: environment.payMocksPath,
  useMocksForPos: environment.useMocksForPos,
  posMocksPath: environment.posMocksPath,
  useMocksForIOBoard: environment.useMocksForIOBoard,
  IOBoardMocksPath: environment.IOBoardMocksPath,
  useMocksForTweet: environment.useMocksForTweet,
  useMocksForBridgeStatusSetter: environment.useMocksForBridgeStatusSetter,
  bridgeStatusSetterPath: environment.bridgeStatusSetterPath,
  tweetMocksPath: environment.tweetMocksPath,
  useMocksForPrint: environment.useMocksForPrint,
  printMocksPath: environment.printMocksPath,
  useMocksForElectronicReceiptSender: environment.useMocksForElectronicReceiptSender,
  electronicReceiptSenderMocksPath: environment.electronicReceiptSenderMocksPath,
  useMocksForAtpElectronicReceipt: environment.useMocksForAtpElectronicReceipt,
  atpElectronicReceiptMocksPath: environment.atpElectronicReceiptMocksPath,
  useMocksForScan: environment.useMocksForScan,
  scanMocksPath: environment.scanMocksPath,
  useMocksForRecommendations: environment.useMocksForRecommendations,
  recommendationsMocksPath: environment.recommendationsMocksPath,
};

DOTPlatform(mockOptions)
  .then(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .then(() => appLogger.info(`application initialized`))
      .catch((err) => appLogger.error(`application initialization failed: "${err}"`));
  })
  .catch((err) => appLogger.error(`DOTPlatform initialization failed: "${err}"`));
