<acr-loading *ngIf="$sendingEmail | async" [waitingMessage]="'2003006' | translate"></acr-loading>
<main class="page-container email-receipt-page">
  <header class="page-header email-receipt-page__header">
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
    <h2 class="email-receipt-page__title">{{ '2003012' | translate }}</h2>
  </header>
  <section class="email-receipt-page__container">
    <acr-keyboard [text]="email" [keyboardLayout]="'email'" (inputChanged)="onEmailInputChanged($event)"></acr-keyboard>
  </section>

  <acr-footer>
    <button
      left-slot
      class="ui-button ui-button--tertiary ui-button--small"
      (click)="backToReceiptOption()"
      [disabled]="$sendingEmail | async"
    >
      {{ '9' | translate }}
    </button>
    <button right-slot class="ui-button ui-button--secondary ui-button--small" (click)="sendEmail()" [disabled]="email.trim() === ''">
      {{ '71' | translate }}
    </button>
  </acr-footer>
</main>
