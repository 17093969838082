import { ElementRef, Injectable, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { SkinProviderService } from '@dotxix/skin/skin-provider.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { SectionOverride, Skin } from '@dotxix/skin/skin.interface';
import * as _ from 'lodash';
import type {} from 'css-font-loading-module';

const SKIN_DOM_PROP = '___skin';
const SKIN_EXTENSIONS_DOM_PROP = '___skin-extensions';

interface SkinableHTMLElement extends HTMLElement {
  [SKIN_DOM_PROP]: SectionOverride;
  [SKIN_EXTENSIONS_DOM_PROP]: SectionOverride;
}

@Injectable({
  providedIn: 'root',
})
export class SkinService {
  public loaded$ = new BehaviorSubject<boolean>(false);
  public skin: Skin | null = null;
  public extensionsUpdated$ = new Subject<string[]>();

  private extensions: string[] = [];
  constructor(private skinProviderService: SkinProviderService) {}

  public initialize() {
    this.skinProviderService.getSkin().then((skin) => {
      this.skin = skin;
      this.applySkin();
      this.loaded$.next(true);
    });
  }

  public addExtension(extensionName: string) {
    if (this.extensions.indexOf(extensionName) === -1) {
      this.extensions = [...this.extensions, extensionName];
    }
    this.extensionsUpdated$.next(this.extensions);
  }

  public removeExtension(extensionName: string) {
    this.extensions = this.extensions.filter((activeExtensionName) => activeExtensionName !== extensionName);
    this.extensionsUpdated$.next(this.extensions);
  }

  public applySection(sectionId: string, element: ElementRef, renderer: Renderer2) {
    if (element && element.nativeElement) {
      const elementStyleOverrides = this.findSectionOverrides(sectionId, element.nativeElement);
      element.nativeElement[SKIN_DOM_PROP] = elementStyleOverrides;
      this.applyStyles(element, elementStyleOverrides, renderer);
    }
  }

  public applyPart(partId: string, element: ElementRef, renderer: Renderer2) {
    if (element && element.nativeElement) {
      let elementStyleOverrides = this.findElementStylePartOverrides(partId, element.nativeElement);
      if (element.nativeElement[SKIN_DOM_PROP]) {
        elementStyleOverrides = _.merge({}, element.nativeElement[SKIN_DOM_PROP], elementStyleOverrides);
      }
      element.nativeElement[SKIN_DOM_PROP] = elementStyleOverrides;
      this.applyStyles(element, elementStyleOverrides, renderer);
    }
  }

  public updateExtensions(elementRef: ElementRef, renderer: Renderer2) {
    this.updateElementExtensions(elementRef, renderer);
  }

  private applySkin() {
    this.applyGlobal();
    this.addFontFaces();
  }

  private applyGlobal() {
    if (typeof this.skin?.global !== 'object') {
      return;
    }
    Object.keys(this.skin?.global).forEach((overrideKey) => {
      const overrideValue = this.skin?.global[overrideKey];
      if (typeof overrideValue === 'string') {
        document.documentElement.style.setProperty(overrideKey, overrideValue);
      }
    });
  }

  private addFontFaces() {
    if (typeof this.skin?.fonts !== 'object') {
      return;
    }
    Object.keys(this.skin?.fonts).forEach((fontFaceName) => {
      const filePath = this.skin!.fonts[fontFaceName];
      this.addFontFace(fontFaceName, filePath);
    });
  }

  private addFontFace(fontFaceName: string, filePath: string) {
    const fontFace = new FontFace(fontFaceName, `url('${filePath}')`);
    fontFace
      .load()
      .then(function (loadedFontFace) {
        document.fonts.add(loadedFontFace);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  private applyStyles(elementRef: ElementRef, styles: SectionOverride, renderer: Renderer2) {
    const element = elementRef.nativeElement;
    element[SKIN_EXTENSIONS_DOM_PROP] = {};
    Object.keys(styles).forEach((key) => {
      const styleValue = styles[key];
      if (typeof styleValue === 'string') {
        if (key[0] === '#') {
          this.applyExtensionStyle(element, key, styleValue, renderer);
        } else {
          renderer.setStyle(element, key, styleValue, RendererStyleFlags2.DashCase);
        }
      }
    });
  }

  private updateElementExtensions(elementRef: ElementRef, renderer: Renderer2) {
    const element = elementRef.nativeElement;
    if (!element) {
      return;
    }
    if (typeof element[SKIN_DOM_PROP] !== 'object') {
      return;
    }

    const styles = element[SKIN_DOM_PROP];
    this.revertExtensionStyles(element, renderer);

    element[SKIN_EXTENSIONS_DOM_PROP] = {};
    Object.keys(styles).forEach((key) => {
      const styleValue = styles[key];
      if (typeof styleValue === 'string' && key[0] === '#') {
        this.applyExtensionStyle(element, key, styleValue, renderer);
      }
    });
  }

  private revertExtensionStyles(element: SkinableHTMLElement, renderer: Renderer2) {
    const newStyles = element[SKIN_DOM_PROP];
    if (typeof element[SKIN_EXTENSIONS_DOM_PROP] === 'object') {
      const stylesToBeReverted = element[SKIN_EXTENSIONS_DOM_PROP];
      Object.keys(stylesToBeReverted).forEach((key) => {
        renderer.removeStyle(element, key, RendererStyleFlags2.DashCase);
        renderer.setStyle(element, key, newStyles[key], RendererStyleFlags2.DashCase);
      });
    }
  }

  private applyExtensionStyle(element: SkinableHTMLElement, key: string, styleValue: string, renderer: Renderer2) {
    const cssProp = this.extractCssPropFromNameWithExtension(key);
    element[SKIN_EXTENSIONS_DOM_PROP][cssProp] = styleValue;
    renderer.setStyle(element, cssProp, styleValue, RendererStyleFlags2.DashCase);
  }

  private findSectionOverrides(sectionId: string, element: SkinableHTMLElement): SectionOverride {
    const skinSection = this.skin?.sections[sectionId];
    let sectionOverrides = typeof skinSection === 'object' ? skinSection : {};
    sectionOverrides = _.merge({}, sectionOverrides, this.findSectionOverridesInParents(sectionId, element));
    return sectionOverrides;
  }

  private findSectionOverridesInParents(sectionId: string, element: SkinableHTMLElement): SectionOverride {
    const skinableParentElement = element.parentElement as SkinableHTMLElement;
    if (!skinableParentElement) {
      return {};
    }
    return _.merge(
      {},
      this.findSectionOverridesInParents(sectionId, skinableParentElement),
      skinableParentElement[SKIN_DOM_PROP] ? skinableParentElement[SKIN_DOM_PROP][sectionId] : {}
    );
  }

  private findElementStylePartOverrides(partId: string, element: SkinableHTMLElement): SectionOverride {
    const skinableParentElement = element.parentElement as SkinableHTMLElement;
    if (!skinableParentElement) {
      return {};
    }

    if (skinableParentElement[SKIN_DOM_PROP]) {
      const partDef = skinableParentElement[SKIN_DOM_PROP][partId];
      return typeof partDef !== 'string' ? partDef ?? {} : {};
    }

    return this.findElementStylePartOverrides(partId, skinableParentElement);
  }

  private extractCssPropFromNameWithExtension(nameWithExtension: string) {
    return this.extensions.reduce((prev, current) => {
      return prev.replace(current + '#', '');
    }, nameWithExtension.substring(1));
  }
}
