<section class="flex ice-modifier">
  <div class="ice-modifier__product" [class.ice-modifier__product--selected]="isWithIceSelected" (click)="selectWithIce()">
    <h5 class="ice-modifier__title">{{ '2022120501' | translate }}</h5>
    <img skin-part="image" class="ice-modifier__product-img" alt="" src="/Channels/skin/icon-ice.png" width="200rem" height="200rem" />
    <img
      skin-part="image"
      src="/Channels/skin/icon-pillCheckmark.svg"
      class="ui-button--icon size--56 ice-modifier__product-title--checkmark"
      alt=""
    />
  </div>
  <div class="ice-modifier__product" [class.ice-modifier__product--selected]="isWithoutIceSelected" (click)="selectWithoutIce()">
    <h5 class="ice-modifier__title">{{ '2022120502' | translate }}</h5>
    <img skin-part="image" class="ice-modifier__product-img" alt="" src="/Channels/skin/icon-ice.png" width="200rem" height="200rem" />
    <img
      skin-part="image"
      src="/Channels/skin/icon-pillCheckmark.svg"
      class="ui-button--icon size--56 ice-modifier__product-title--checkmark"
      alt=""
    />
  </div>
</section>
