import { DotButton, omitPropertiesInObject } from 'dotsdk';
import { replaceItemInList } from '@dotxix/helpers/array.helper';
import * as _ from 'lodash';
import { ButtonState } from '@dotxix/models/interfaces/button-state';

export const addBasketButton = (button: DotButton, list: DotButton[], reverseOrder: boolean) => {
  if (reverseOrder) {
    return [button, ...list];
  } else {
    return [...list, button];
  }
};

export const replaceBasketButtonByUuid = (list: DotButton[], basketButton: DotButton) => {
  const currentBasketIndex = list.findIndex((button) => basketButton.uuid === button.uuid);
  return replaceItemInList(list, currentBasketIndex, basketButton);
};

export const removeBasketButtonByUuid = (list: DotButton[], basketButton: DotButton) => {
  const currentBasketIndex = list.findIndex((button) => basketButton.uuid === button.uuid);
  const newList = [...list];
  newList.splice(currentBasketIndex, 1);
  return newList;
};

export const updateParentLinkUUID = (button: DotButton<ButtonState>, newParentLinkUUID: string, list: DotButton<ButtonState>[]) => {
  const newList = list.map((listButton) => {
    if (listButton.state.childLinkUUID && listButton.state.childLinkUUID === button.state.parentLinkUUID) {
      const clone = _.cloneDeep(listButton);
      clone.state.childLinkUUID = newParentLinkUUID;
      return clone;
    } else {
      return listButton;
    }
  });
  const clonedButton = _.cloneDeep(button);
  clonedButton.state.parentLinkUUID = newParentLinkUUID;
  return replaceBasketButtonByUuid(newList, clonedButton);
};

export const removeDuplicates = (newParentLinkUUID: string, list: DotButton<ButtonState>[]) => {
  const newList: DotButton<ButtonState>[] = [];
  list.forEach((button) => {
    const theSameButtonIndex = newList.findIndex(
      (btn) => button.state.childLinkUUID === newParentLinkUUID && areButtonsSimilar(btn, button)
    );
    if (theSameButtonIndex > -1) {
      newList[theSameButtonIndex].quantity += button.quantity;
    } else {
      newList.push(button);
    }
  });
  return newList;
};

export const areButtonsSimilar = (button1: DotButton<ButtonState>, button2: DotButton<ButtonState>) => {
  if (
    button1.Link !== button2.Link ||
    button1.isPromo !== button2.isPromo ||
    ((button1.state.childLinkUUID || button2.state.childLinkUUID) && button1.state.childLinkUUID !== button2.state.childLinkUUID)
  ) {
    return false;
  }
  const samePromo = button1.state.promoMetadata === button2.state.promoMetadata;
  const omitPropertiesModifiers = ['hasIceModifierTemplate', 'hasOnlyOneIceButton'];
  const sameModifiers =
    (!button1.hasModifiers && !button2.hasModifiers) ||
    _.isEqual(
      omitPropertiesInObject(button1.ModifiersPage, omitPropertiesModifiers),
      omitPropertiesInObject(button2.ModifiersPage, omitPropertiesModifiers)
    );
  const omitPropertiesCombos = omitPropertiesModifiers.concat(['StartSize', 'selectedSize']);
  const sameCombos =
    (!button1.hasCombos && !button2.hasCombos) ||
    _.isEqual(
      omitPropertiesInObject(button1.ComboPage, omitPropertiesCombos),
      omitPropertiesInObject(button2.ComboPage, omitPropertiesCombos)
    );
  return samePromo && sameModifiers && sameCombos;
};
