<header class="product-stepper-header">
  <img
    *ngFor="let headerImage of displayImages"
    [src]="headerImage.src"
    [class.logo]="!headerImage.src"
    [style.z-index]="headerImage.zIndex"
    [style.left]="headerImage.left || ''"
    [style.right]="headerImage.right || ''"
    class="product-stepper-header__product-img"
    [class.new-addition]="headerImage.isNewAddition"
    alt=""
    width="360rem"
    height="360rem"
  />
  <div class="product-stepper-header__product-name">
    <h3>{{ productTitle }}</h3>
    <h4 *ngIf="productTitle !== productSubTitle">{{ productSubTitle }}</h4>
  </div>
  <acr-info-indicator *ngIf="showInfoButton" [button]="button" [reversed]="true"></acr-info-indicator>
</header>
