import { animate, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';
import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';

export const extractToKeyValue = (object: CssVariables): { key: string; value: string } => {
  const key = Object.keys(object).join();
  const value = object[key];
  return { key, value };
};

export const DELAY_TO_RESET_MESSAGE = 5000;

export const extractCssVariablesValues = (document: Document, keys: string[]): CssVariables => {
  return keys.reduce(
    (acc, key) => ({ ...acc, [key]: getComputedStyle(document.documentElement).getPropertyValue(key) }),
    {} as CssVariables
  );
};

export const enter: AnimationTriggerMetadata = trigger('enter', [
  transition(':enter', [style({ transform: 'translateX(100%)' }), animate('0.2s linear', style({ transform: 'translateX(0)' }))]),
]);
export const leave: AnimationTriggerMetadata = trigger('leave', [
  transition(':leave', [style({ transform: 'translateX(0)' }), animate('0.2s linear', style({ transform: 'translateX(100%)' }))]),
]);

export const animateHeightEnter: AnimationTriggerMetadata = trigger('enterHeight', [
  transition(':enter', [
    style({ opacity: '0', height: '0', overflow: 'hidden' }),
    animate('0.2s linear', style({ opacity: '1', height: '*', overflow: 'hidden' })),
  ]),
]);
export const animateHeightLeave: AnimationTriggerMetadata = trigger('leaveHeight', [
  transition(':leave', [
    style({ opacity: '1', height: '*', overflow: 'hidden' }),
    animate('0.2s linear', style({ opacity: '0', height: '0', overflow: 'hidden' })),
  ]),
]);

export const pulseEnter: AnimationTriggerMetadata = trigger('pulse', [
  transition(':enter', [
    style({ transform: 'scale(0.4)' }),
    animate(
      '2s linear',
      keyframes([
        style({ transform: 'scale(1)' }),
        style({ transform: 'scale(0.4)' }),
        style({ transform: 'scale(1)' }),
        style({ transform: 'scale(0.4)' }),
        style({ transform: 'scale(1)' }),
      ])
    ),
  ]),
]);

export const getDateInFileName = () => {
  const date = new Date();
  return `${date.toJSON().slice(0, 10)}_${date.getHours()}-${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
};
