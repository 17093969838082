import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OsirisOrderStateService } from '@dotxix/osiris/services/osiris-orders-state.service';
import { OsirisService } from '@dotxix/osiris/services/osiris.service';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import { OsirisFlowService } from '@dotxix/osiris/services/osiris-flow.service';

@Component({
  selector: 'acr-account-retrieved',
  host: { class: 'full-height full-width' },
  template: `
    <div class="container">
      <h2 class="pb--60">{{ '20220426008' | translate }}</h2>
      <div class="actions">
        <button class="ui-button ui-button--secondary" (click)="pastOrders()">{{ '20220426009' | translate }}</button>
        <button class="ui-button ui-button--secondary" (click)="seeMenu()">{{ '20220426010' | translate }}</button>
      </div>
    </div>
  `,
  styleUrls: ['../face-recall-common.scss'],
})
export class AccountRetrievedComponent {
  constructor(
    private orderFlowService: OrderFlowService,
    private router: Router,
    private osirisOrderState: OsirisOrderStateService,
    private osirisService: OsirisService,
    private osirisFlowService: OsirisFlowService
  ) {}

  public pastOrders() {
    this.osirisFlowService.goToOrderHistory();
  }

  public seeMenu() {
    this.osirisFlowService.goToMenu();
  }
}
