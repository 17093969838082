import { DotButton, PosServingLocation, calculateButtonPrice } from 'dotsdk';

export function price(button: DotButton, serviceLocation: PosServingLocation): number {
  if (button.hasCombos) {
    return button.MinPrice;
  }
  if (button.Page && button.MinPrice && button.MinPrice !== 0) {
    return button.MinPrice;
  }
  return calculateButtonPrice(button, serviceLocation);
}
export const hasForcedPrice = (button: DotButton, serviceType: PosServingLocation) => {
  return (
    (serviceType === PosServingLocation.IN && typeof button.ForcePriceIN === 'number') ||
    (serviceType === PosServingLocation.OUT && typeof button.ForcePriceOUT === 'number')
  );
};
