import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { DotNutritionalValue } from 'dotsdk';

@Pipe({
  name: 'nutritionalDescription',
})
export class NutritionalDescription implements PipeTransform {
  constructor(private translationsService: TranslationsService) {}

  public transform(value: DotNutritionalValue): string {
    return this.translationsService.getTranslatedNutritionalDescription(value);
  }
}
