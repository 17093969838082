import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'acr-buttons-counter-edit',
  templateUrl: './buttons-counter-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsCounterEditComponent {
  @Input() public isDisabled = false;
  @Input() public isIncreaseDisabled = false;
  @Input() public isDecreaseDisabled = false;

  @Input() public hideQuantityAmount = false;
  @Input() public quantity = 0;
  @Input() public label: string | undefined;

  @Output() public quantityUpdate: EventEmitter<1 | -1> = new EventEmitter();

  public changeQuantity(quantity: 1 | -1): void {
    this.quantityUpdate.emit(quantity);
  }

  public stopEvent(event: Event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }
}
