import { Injectable } from '@angular/core';
import {
  ApgAllowedPayments,
  ApgStartOptionsPay,
  AtpPaymentService,
  PosPaidState,
  PosRefintService,
  PosTenderType,
} from '@acrelec.foundation/dotsdk';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { getTenderMediaId, insertPaymentOperation, insertTender } from '@dotxix/payment/helpers/payment-elog.helper';
import { getPaymentName } from '@dotxix/payment/helpers/payment-type.helper';
import { paymentLogger } from '@dotxix/log-manager';
import { TranslationsService } from '../../services/translations/translations.service';
import { PaymentService } from './payment.service';
import { ApplicationSettingsService } from '../../services/app-settings.service';
import { StatusService } from '../../services/status.service';
import { PAYMENT_TYPE } from '../../models/enums/payment-type';

@Injectable({
  providedIn: 'root',
})
export class ApgPaymentService {
  constructor(
    private translationsService: TranslationsService,
    private paymentService: PaymentService,
    private applicationSettingsService: ApplicationSettingsService,
    private statusService: StatusService
  ) {}

  public async pay(amountOwned: number, paymentType: PaymentType) {
    const apgStartPayOptions: ApgStartOptionsPay = {
      Language: this.translationsService.currentLanguageCode$.value,
      Amount: amountOwned,
      TransactionReference: `${PosRefintService.getInstance()._refInt}`,
      AllowedPaymentTypes: this.filterAvailableAPGPaymentTypes(paymentType.AllowedPaymentTypes),
      SuccessTTLSeconds: 5,
      FailureTTLSeconds: 5,
    };

    paymentLogger.debug(
      `payment with apg initiated. Amount: ${
        apgStartPayOptions.Amount
      }, allowed payment types: ${apgStartPayOptions.AllowedPaymentTypes.join(
        ', '
      )}, available payment types: ${apgStartPayOptions.AllowedPaymentTypes.join(', ')}`
    );

    const payResult = await AtpPaymentService.getInstance()
      .apgPay(apgStartPayOptions)
      .catch((e) => {
        paymentLogger.error(`APG Error: ${e}`);
        return null;
      });

    if (payResult && payResult.PaidAmount) {
      paymentLogger.debug(`APG payment successfully executed`);

      payResult.CardPayments.forEach((cardPaymentResult) => {
        insertPaymentOperation(true, getPaymentName(paymentType));
        insertTender(
          PosTenderType.CARD,
          PosPaidState.PAID,
          payResult.PaidAmount,
          getTenderMediaId(cardPaymentResult.PayDetails, paymentType)
        );
      });

      payResult.CashPayments.forEach((cashPaymentResult) => {
        insertPaymentOperation(true, getPaymentName(paymentType));
        insertTender(
          PosTenderType.CASH,
          cashPaymentResult.AcceptMoneyDetails.PaidAmount || 0 > 0 ? PosPaidState.PAID : PosPaidState.NOT_PAID,
          cashPaymentResult.AcceptMoneyDetails.PaidAmount || 0,
          paymentType.TenderMediaID ?? ''
        );
      });

      payResult.EPayPayments.forEach((ePaymentResult) => {
        insertPaymentOperation(true, getPaymentName(paymentType));
        insertTender(
          PosTenderType.CARD,
          PosPaidState.PAID,
          ePaymentResult.PayDetailsExtended.PaidAmount,
          getTenderMediaId(ePaymentResult.PayDetailsExtended, paymentType)
        );
      });
    } else {
      paymentLogger.debug(`APG payment failed`);
      insertPaymentOperation(false, getPaymentName(paymentType));
    }

    return payResult;
  }
  private filterAvailableAPGPaymentTypes(apgAllowedPaymentTypes: ApgAllowedPayments[] = []): ApgAllowedPayments[] {
    const _apgAllowedPaymentTypes = [...apgAllowedPaymentTypes] as unknown as PAYMENT_TYPE[];

    const cashPaymentPosition = apgAllowedPaymentTypes.indexOf(ApgAllowedPayments.cash);
    if (
      cashPaymentPosition > -1 &&
      this.paymentService.state$.value.cash.cashPaidAmount >= this.applicationSettingsService.settings$.value.gloryPayableAmount
    ) {
      _apgAllowedPaymentTypes.splice(cashPaymentPosition, 1);
    }

    const apgAvailablePayments: PAYMENT_TYPE[] = [];

    const allAvailablePaymentsMappedToName = this.statusService.state$.value.availablePayments.map(
      (availablePayment) => availablePayment.PaymentType
    );

    for (let i = 0; i < _apgAllowedPaymentTypes.length; i++) {
      const _apgAllowedPaymentType = _apgAllowedPaymentTypes[i];
      if (
        allAvailablePaymentsMappedToName.indexOf(_apgAllowedPaymentType) !== -1 &&
        apgAvailablePayments.indexOf(_apgAllowedPaymentType) === -1
      ) {
        apgAvailablePayments.push(_apgAllowedPaymentType);
      }
    }

    return apgAvailablePayments as unknown as ApgAllowedPayments[];
  }
}
