<main class="full-height checkout-error page-container">
  <header class="page-header">
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <div class="flex flex--1 flex--column justify-between checkout-error__square">
    <h3 class="text-center mb--20" [innerHtml]="'20210204001' | translate"></h3>
    <div class="flex flex--1 flex--center">
      <img *ngIf="displayPaymentImage" class="circle__img icon-pos--error" src="/Channels/skin/icon-pos--error.png" alt="" />
    </div>

    <h3 class="text-center" [innerHtml]="'36' | translate"></h3>
  </div>
</main>
