export enum RecommendationActionView {
  Home = 'Home',
  Page = 'Page',
  ItemSuggestion = 'ItemSuggestion',
  MenuSuggestion = 'MenuSuggestion',
  OrderSuggestion = 'OrderSuggestion',
  BasketSuggestion = 'BasketSuggestion',
  BasketPlus = 'BasketPlus',
  BasketMinus = 'BasketMinus',
  ConfirmationMinus = 'ConfirmationMinus',
  KioskOffer = 'kioskOffer',
}
