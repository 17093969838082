import { Injectable } from '@angular/core';
import { HmacSHA1 } from 'crypto-js';
import { AES } from 'crypto-js';
import { Xhr } from 'dotsdk';

import { BehaviorSubject } from 'rxjs';

import { OsirisAuthKeys } from '../models/interfaces/osiris-auth-keys.interface';
import { environment } from 'src/environments/environment';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';

@Injectable({ providedIn: 'root' })
export class OsirisHttpHandshakeService {
  private state$ = new BehaviorSubject<string | null>(null);
  private xhr = new Xhr();
  private jsonHeaders = [
    {
      headerName: 'Accept',
      headerValue: '*/*',
    },
  ];

  public constructor(private appSettings: ApplicationSettingsService) {
    // this.state$.subscribe((v) => console.log('%c DEBUG handshake state', 'background:#222;color:#bada55;font-size:1.2rem', v));
  }

  public async requestAutorizationCode(): Promise<string> {
    const k = environment.k;
    //TODO to be clarified 20s cache was not working on server side
    // if (lastResolvedChallenge) {
    //   return lastResolvedChallenge;
    // }
    const fullUrl = `${this.appSettings.settings$.value.osirisBaseUrl}/api/Auth`;
    try {
      const request: XMLHttpRequest = await this.xhr.makeRequest({
        url: fullUrl,
        method: 'GET',
        headers: this.jsonHeaders,
        useDds: true,
      });
      const { challenge, keyId } = JSON.parse(request.response) || {};
      const foundSpecificKeyValue = this.searchForKey(keyId);
      if (challenge && foundSpecificKeyValue) {
        const decrypted = AES.decrypt(foundSpecificKeyValue, k);
        const decrypt = HmacSHA1(challenge, decrypted).toString();
        return this.setResolvedChalange(window.btoa(`${challenge}:${decrypt}`));
      }
      throw new Error('no valid challange or specific key found');
    } catch (error) {
      throw new Error('auth process failed');
    }
  }

  private searchForKey(id: string) {
    const keys: OsirisAuthKeys[] = this.appSettings.settings$.value.osirisAuthKeys;
    return keys?.find((f) => f.keyId === id)?.keyValue;
  }

  private setResolvedChalange(resolvedChallenge: string): string {
    // this.state$.next(resolvedChallenge);
    // if(this.handler){
    //   clearTimeout(this.handler)
    // }
    // this.handler = this.startCountDown();
    return resolvedChallenge;
  }
}
