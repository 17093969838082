import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { DotButton } from 'dotsdk';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';

@Injectable({
  providedIn: 'root',
})
export class BasketProductRemovalService {
  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private productTranslationsService: ProductTranslationsService
  ) {}

  public confirmProductRemoval(basketButton: DotButton): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this.applicationSettingsService.settings$.value.productRemovalWarning) {
        return resolve(true);
      }

      const contentRef = this.dynamicContentService.openContent(ConfirmDialogComponent, {
        title: this.removeConfirmationTitle(basketButton),
        leftButtonText: this.translationsService.translate('23'),
        rightButtonText: this.translationsService.translate('71'),
      });
      contentRef.afterClosed.subscribe((response) => {
        if (response === ConfirmDialogButtons.RIGHT_BUTTON) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  private removeConfirmationTitle(button: DotButton): string {
    const title = this.translationsService.translate('2020122102');
    const caption = this.productTranslationsService.translateProductTitle(button) ?? '';

    return title.replace('%PRODUCT_NAME%', caption);
  }
}
