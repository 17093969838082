import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DotButton } from 'dotsdk';
import { ButtonInfoService } from '@dotxix/button-info.service';

@Component({
  selector: 'acr-info-indicator',
  template: `
    <language-change-view-trigger></language-change-view-trigger>
    <div class="info-indicator" [class.info-indicator--reversed]="reversed">
      <div class="info-indicator__ticket" (click)="onProductInfoClick($event)">
        <span class="info-indicator__info">i</span>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoIndicatorComponent {
  @Input() reversed = false;
  @Input() button!: DotButton;

  constructor(private buttonInfoService: ButtonInfoService) {}

  public onProductInfoClick(event: MouseEvent): void {
    event.stopPropagation();
    this.buttonInfoService.openProductInfo(this.button);
  }
}
