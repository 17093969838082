<div class="osiris-order-number-info">
  <ng-container [ngSwitch]="orderNumberScreenStep$ | async">
    <ng-container *ngSwitchDefault>
      <h2>{{ '2022051001' | translate }}</h2>
    </ng-container>
    <ng-container *ngSwitchCase="OrderNumberSteps.OrderSaved">
      <h2>{{ '20220427001' | translate }}</h2>
      <img *ngIf="showFaceRecallIcon" skin-part="image" src="/Channels/skin/icon-faceRecall.svg" class="size--170" alt="" />
      <h2>{{ '20220427002' | translate }}</h2>
    </ng-container>

    <ng-container *ngSwitchCase="OrderNumberSteps.FaceRecallLogin">
      <h2>{{ '20220427004' | translate }}</h2>
      <button class="osiris-order-number-info__save-button" (click)="faceRecallRegistration()">
        <img skin-part="image" src="/Channels/skin/icon-faceRecall.svg" class="size--100" alt="" />

        <span class="osiris-order-number-info__save-button--name">
          <span *ngFor="let word of getFaceRecallDisplayName(); let last = last" [class.color--green]="last">{{ word }}</span>
        </span>
      </button>
      <h3 [innerHTML]="'20220427003' | translate"></h3>
    </ng-container>

    <ng-container *ngSwitchCase="OrderNumberSteps.OrderFail">
      <h3 class="uppercase" *ngIf="showAccountCreated$ | async">{{ '20220426006' | translate }}</h3>
      <h3 class="color--errorRed">{{ '20220427006' | translate }}</h3>
      <img *ngIf="showFaceRecallIcon" skin-part="image" src="/Channels/skin/icon-faceRecallSad.svg" class="size--170" alt="" />

      <h2>{{ '20220427002' | translate }}</h2>
    </ng-container>

    <ng-container *ngSwitchCase="OrderNumberSteps.DisableFaceRecallLoginOption">
      <h2 class="text-center">{{ '35' | translate }}</h2>
    </ng-container>
  </ng-container>
</div>
