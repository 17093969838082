import { DotButtonType, DotPage } from 'dotsdk';

export const filterSuggestiveItems = (suggestivePages: DotPage[]): DotPage[] => {
  suggestivePages = suggestivePages.map((suggestivePage) => {
    suggestivePage.Buttons = suggestivePage.Buttons.filter(
      (button) =>
        button.ButtonType === DotButtonType.ITEM_BUTTON ||
        button.ButtonType === DotButtonType.MENU_BUTTON ||
        button.ButtonType === DotButtonType.PAGE_BUTTON
    );

    return suggestivePage;
  });
  return suggestivePages;
};
