import { Component, OnDestroy, OnInit } from '@angular/core';
import { OsirisLoginFaceRecallService } from '@dotxix/osiris/services/osiris-login-face-recall.service';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

@Component({
  selector: 'acr-qrcode-pin-attempts',
  templateUrl: './qrcode-pin-attempts.component.html',
  host: { class: 'full-height full-width' },
})
export class QrcodePinAttemptsComponent implements OnInit, OnDestroy {
  public faceRecall$ = this.osirisStateService.faceRecall$;
  public qrString = this.appSettings.settings$.value.faceRecallRegisterUrl;
  public counter$ = new BehaviorSubject<number>(10);
  private timerSubscriber: Subscription | undefined;

  constructor(
    private osirisStateService: OsirisStateService,
    private appSettings: ApplicationSettingsService,
    private osirisLoginFaceRecallService: OsirisLoginFaceRecallService
  ) {}

  public ngOnInit(): void {
    this.countdown();
  }

  public ngOnDestroy(): void {
    if (this.timerSubscriber) {
      this.timerSubscriber.unsubscribe();
    }
  }

  private countdown() {
    this.timerSubscriber = timer(1000, 1000).subscribe(() => {
      if (this.counter$.value >= 2) {
        this.counter$.next(this.counter$.value - 1);
      } else {
        this.close();
      }
    });
  }

  private close() {
    this.osirisLoginFaceRecallService.navigateFromMaxPinAttempts();
  }
}
