import { Directive, ElementRef } from '@angular/core';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Directive({
  selector: '[acrRevertOnRtl]',
})
export class RevertOnRtlDirective {
  constructor(
    private el: ElementRef,
    private translationsService: TranslationsService
  ) {
    this.translationsService.currentLanguageIsRtl$.subscribe((isRtl) => {
      if (isRtl) {
        const css = `-webkit-transform: scaleX(-1); transform: scaleX(-1);`;
        this.el.nativeElement.setAttribute('style', css);
      } else {
        const css = `-webkit-transform: scaleX(1); transform: scaleX(1);`;
        this.el.nativeElement.setAttribute('style', css);
      }
    });
  }
}
