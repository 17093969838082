import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { AtpScannerService } from 'dotsdk';
import { Subscription } from 'rxjs';
import { InactivityService } from '@dotxix/services/inactivity.service';

@Component({
  selector: 'acr-osiris-scan',
  templateUrl: './osiris-scan.component.html',
})
export class OsirisScanComponent implements OnInit, OnDestroy {
  @Output() public barcodeChanged: EventEmitter<string> = new EventEmitter();

  private codeSubscription!: Subscription;

  constructor(
    private ngZone: NgZone,
    private inactivityService: InactivityService
  ) {}

  public ngOnInit() {
    this.startScan();
  }
  public ngOnDestroy() {
    this.codeSubscription.unsubscribe();
  }

  protected startScan() {
    this.codeSubscription = AtpScannerService.getInstance()
      .startContinuouslyScan()
      .subscribe((scannedCode: string) => {
        this.ngZone.run(() => {
          this.inactivityService.resetInactivityProducerTimer();
          this.barcodeChanged.emit(scannedCode);
        });
      });
  }
}
