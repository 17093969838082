<div class="qrcode-register-identity" *ngIf="faceRecall$ | async as faceRecall">
  <div class="qrcode-register-identity__message">
    <p class="qrcode-register-identity__message" [innerHTML]="'2022102804' | translate"></p>
    <p class="qrcode-register-identity__message" [innerHTML]="'2022102805' | translate"></p>
  </div>
  <div class="qrcode-register-identity__qrcode-container">
    <p class="text-center fs--40" [innerHtml]="'2022102806' | translate"></p>
    <qrcode [qrdata]="qrString" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
    <h1 class="text-center fs--120">{{ counter$ | async }}</h1>
    <button class="ui-button ui-button--secondary ui-button--medium" (click)="onCloseClicked()">{{ '24' | translate }}</button>
  </div>
</div>
