import { IPosResponse, PosElogHandler, PosInjectorService, PosResponseReturnCode } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';
import { Injectable } from '@angular/core';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { DemoMockService } from '@dotxix/services/demo-mock.service';
import { appLogger } from '@dotxix/log-manager';

@Injectable({
  providedIn: 'root',
})
export class PosOperationsService {
  constructor(
    protected appSettingsService: ApplicationSettingsService,
    private translationsService: TranslationsService,
    private dynamicContentService: DynamicContentService,
    private demoMockService: DemoMockService
  ) {}

  public async sendCalculateTotals(): Promise<IPosResponse | null> {
    appLogger.debug(`sending calculate totals`);
    qaAutomationLog('calculate totals sent: ', PosElogHandler.getInstance().posConfig);
    const calculateTotalsResponse =
      (await this.demoMockService.calculateTransactionTotals()) ||
      (await PosInjectorService.getInstance()
        .calculateTransactionTotals(this.appSettingsService.settings$.value.posInjectorPath, PosElogHandler.getInstance().posConfig)
        .catch(() => null));
    qaAutomationLog('calculate totals response: ', calculateTotalsResponse);
    appLogger.debug(`calculate totals response: ${JSON.stringify(calculateTotalsResponse)}`);
    return calculateTotalsResponse;
  }

  public async sendOpenOrderToPOS(): Promise<IPosResponse | null> {
    appLogger.debug(`sending open order`);
    qaAutomationLog('open order sent: ', PosElogHandler.getInstance().posConfig);
    const openOrderResponse =
      (await this.demoMockService.sendOpenOrderToPos()) ||
      (await PosInjectorService.getInstance()
        .sendOpenOrderToPos(this.appSettingsService.settings$.value.posInjectorPath, PosElogHandler.getInstance().posConfig)
        .catch(() => null));
    qaAutomationLog('open order response: ', openOrderResponse);
    appLogger.debug(`open order response: ${JSON.stringify(openOrderResponse)}`);
    return openOrderResponse;
  }

  public async sendTenderOrderToPOS(): Promise<IPosResponse | null> {
    appLogger.debug(`sending tender order`);
    qaAutomationLog('tender order sent: ', PosElogHandler.getInstance().posConfig);
    const tenderOrderResponse =
      (await this.demoMockService.tenderOrderOnPos()) ||
      (await PosInjectorService.getInstance()
        .tenderOrderOnPos(this.appSettingsService.settings$.value.posInjectorPath, PosElogHandler.getInstance().posConfig)
        .catch(() => null));
    qaAutomationLog('tender order response: ', tenderOrderResponse);
    appLogger.debug(`tender order response: ${JSON.stringify(tenderOrderResponse)}`);
    return tenderOrderResponse;
  }

  public async sendCompleteOrderToPOS(): Promise<IPosResponse | null> {
    appLogger.debug(`sending complete order`);
    qaAutomationLog('complete order sent: ', PosElogHandler.getInstance().posConfig);
    const completeOrderResponse =
      (await this.demoMockService.sendCompleteOrderToPos()) ||
      (await PosInjectorService.getInstance()
        .sendCompleteOrderToPos(this.appSettingsService.settings$.value.posInjectorPath, PosElogHandler.getInstance().posConfig)
        .catch(() => null));
    qaAutomationLog('complete order response: ', completeOrderResponse);
    appLogger.debug(`complete order response: ${JSON.stringify(completeOrderResponse)}`);
    return completeOrderResponse;
  }

  public async sendVoidOrderToPOS(showLoadingPopup = true): Promise<boolean> {
    appLogger.debug(`sending void order`);
    qaAutomationLog('void order sent: ', PosElogHandler.getInstance().posConfig);
    const loadingPopup = showLoadingPopup ? this.showPosLoadingPopupOverlay() : null;
    const voidOrderResponse =
      (await this.demoMockService.voidOrderOnPos()) ||
      (await PosInjectorService.getInstance()
        .voidOrderOnPos(this.appSettingsService.settings$.value.posInjectorPath, PosElogHandler.getInstance().posConfig)
        .catch(() => null));
    if (showLoadingPopup) {
      loadingPopup?.close();
    }
    qaAutomationLog('void order response: ', voidOrderResponse);
    appLogger.debug(`void order response: ${JSON.stringify(voidOrderResponse)}`);
    return !!voidOrderResponse && this.isBridgeSuccessResponse(voidOrderResponse);
  }

  public async sendUnlockOrderToPOS(showLoadingPopup = true) {
    appLogger.debug(`sending unlock order`);
    qaAutomationLog('unlock order sent: ', PosElogHandler.getInstance().posConfig);
    const loadingPopup = showLoadingPopup ? this.showPosLoadingPopupOverlay() : null;
    const unlockOrderResponse =
      (await this.demoMockService.unlockOrderOnPos()) ||
      (await PosInjectorService.getInstance()
        .unlockOrderOnPos(this.appSettingsService.settings$.value.posInjectorPath, PosElogHandler.getInstance().posConfig)
        .catch(() => null));
    if (showLoadingPopup) {
      loadingPopup?.close();
    }
    qaAutomationLog('unlock order response: ', unlockOrderResponse);
    appLogger.debug(`unlock order response: ${JSON.stringify(unlockOrderResponse)}`);
  }

  public isBridgeSuccessResponse(response: IPosResponse) {
    return !!response && response.ReturnCode === PosResponseReturnCode.SUCCESS;
  }

  public isBridgeDifferentTotalResponse(response: IPosResponse) {
    return !!response && response.ReturnCode === PosResponseReturnCode.DifferentTotal;
  }

  private showPosLoadingPopupOverlay() {
    return this.dynamicContentService.openContent(InfoDialogComponent, {
      title: this.translationsService.translate('2020121702'), // please wait
      buttonText: this.translationsService.translate('24'),
      hideFooterActions: true,
    });
  }
}
