import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardPaymentPageComponent } from '@dotxix/payment/components/card-payment/card-payment-page.component';
import { ComponentsModules } from '@dotxix/components/components.module';
import { PipesModule } from '@dotxix/pipes/pipes.modules';
import { GloryCashOverpayRefundProgressComponent } from '@dotxix/payment/components/glory-cash-payment/views/glory-cash-overpay-refund-progress/glory-cash-overpay-refund-progress.component';
import { GloryCashPaymentProgressComponent } from '@dotxix/payment/components/glory-cash-payment/views/glory-cash-payment-progress/glory-cash-payment-progress.component';
import { GloryCashPaymentPageComponent } from '@dotxix/payment/components/glory-cash-payment/glory-cash-payment-page.component';
import { GloryCashOverpayRefundFailedComponent } from '@dotxix/payment/components/glory-cash-payment/views/glory-cash-overpay-refund-failed/glory-cash-overpay-refund-failed.component';
import { GloryCashOverpayRefundSuccessComponent } from '@dotxix/payment/components/glory-cash-payment/views/glory-cash-overpay-refund-success/glory-cash-overpay-refund-success.component';
import { GloryCashCashBackProgressComponent } from '@dotxix/payment/components/glory-cash-payment/views/./glory-cash-cash-back-progress/glory-cash-cash-back-progress.component';
import { GloryCashCashBackSuccessComponent } from '@dotxix/payment/components/glory-cash-payment/views/glory-cash-cash-back-success/glory-cash-cash-back-success.component';
import { GloryCashCashBackFailedComponent } from '@dotxix/payment/components/glory-cash-payment/views/glory-cash-cash-back-failed/glory-cash-cash-back-failed.component';
import { GloryCashMessageComponent } from '@dotxix/payment/components/glory-cash-payment/glory-cash-message/glory-cash-message.component';
import { GloryCashCancelTransactionRefundProgressComponent } from '@dotxix/payment/components/glory-cash-payment/views/./glory-cash-cancel-transaction-refund-progress/glory-cash-cancel-transaction-refund-progress.component';
import { GloryCashCancelTransactionRefundSuccessComponent } from '@dotxix/payment/components/glory-cash-payment/views/./glory-cash-cancel-transaction-refund-success/glory-cash-cancel-transaction-refund-success.component';
import { GloryCashPaymentProgressErrorComponent } from '@dotxix/payment/components/glory-cash-payment/views/glory-cash-payment-progress-error/glory-cash-payment-progress-error.component';
import { GloryCashPaymentProgressErrorPrintComponent } from '@dotxix/payment/components/glory-cash-payment/views/glory-cash-payment-progress-error-print/glory-cash-payment-progress-error-print.component';
import { PaymentRetryComponent } from '@dotxix/payment/components/card-payment/payment-retry/payment-retry.component';
import { GloryLegalRequirementsComponent } from '@dotxix/payment/components/glory-cash-payment/legal-requirements/glory-legal-requirements.component';
import { PaymentRoutingModule } from '@dotxix/payment/payment-routing.module';
import { PaymentSelectionPageComponent } from '@dotxix/payment/components/payment-selection/payment-selection-page.component';
import { ElectronicPaymentComponent } from './components/electronic-payment/electronic-payment.component';
import { PayTowerPaymentComponent } from '@dotxix/payment/components/pay-tower-payment/pay-tower-payment.component';
import { ApgPageComponent } from './components/apg/apg-page.component';

const PAGES = [
  PaymentSelectionPageComponent,
  CardPaymentPageComponent,
  GloryCashPaymentPageComponent,
  ElectronicPaymentComponent,
  PayTowerPaymentComponent,
  ApgPageComponent,
];
const COMPONENTS = [
  PaymentRetryComponent,
  GloryLegalRequirementsComponent,
  GloryCashPaymentProgressComponent,
  GloryCashOverpayRefundProgressComponent,
  GloryCashOverpayRefundFailedComponent,
  GloryCashOverpayRefundSuccessComponent,
  GloryCashCashBackProgressComponent,
  GloryCashCashBackSuccessComponent,
  GloryCashCashBackFailedComponent,
  GloryCashMessageComponent,
  GloryCashCancelTransactionRefundProgressComponent,
  GloryCashCancelTransactionRefundSuccessComponent,
  GloryCashPaymentProgressErrorComponent,
  GloryCashPaymentProgressErrorPrintComponent,
];

@NgModule({
  declarations: [...PAGES, ...COMPONENTS],
  imports: [CommonModule, ComponentsModules, PipesModule, PaymentRoutingModule],
  schemas: [NO_ERRORS_SCHEMA],
})
export class PaymentModule {}
