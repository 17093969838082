import { DotButton } from 'dotsdk';
import { Pipe, PipeTransform } from '@angular/core';
import { filterCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';

@Pipe({
  name: 'filterCatalogAvailable',
})
export class FilterCatalogAvailablePipe implements PipeTransform {
  /** DF-3938 */
  public transform(buttons: DotButton[]): DotButton[] {
    return filterCatalogAvailable(buttons);
  }
}
