import { Component, OnInit } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BasketService } from '@dotxix/services/basket.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { map } from 'rxjs/operators';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-cod-view',
  templateUrl: './cod-view.component.html',
})
export class CodViewComponent implements OnInit {
  public checkoutButtonDisabled$ = this.basketService.allowToCheckout$.pipe(map((allowToCheckout) => !allowToCheckout));
  public allowModifyProducts = this.appSettingsService.settings$.value.skipPrecalculate;
  public isPayTower = this.appSettingsService.settings$.value.isPayTower;
  public get totalPrice(): number {
    return this.checkoutService.getAmountOwed();
  }

  public get orderDiscount(): number {
    return this.checkoutService.orderDiscount();
  }

  public get hasOrderDiscount(): boolean {
    return this.checkoutService.hasOrderDiscount();
  }

  constructor(
    public basketService: BasketService,
    private checkoutService: CheckoutService,
    private appSettingsService: ApplicationSettingsService,
    private appBiService: AppBIService
  ) {}
  ngOnInit(): void {
    this.appBiService.actions.impressions.viewCODBasketReview();
  }
}
