import { DotButton, DotModifier } from 'dotsdk';
import {
  modifierButtonAddOneAndRemoveFromAnotherModifierButtonWhenMaxReached,
  modifierButtonRemoveOne,
} from '@dotxix/helpers/modifier-group.helper';
import { computeModifierSelectedQuantity } from '@dotxix/helpers/modifiers.helper';

export const bucketStandardAddModifierButton = (modifierButton: DotButton, groupModifierButtons: DotButton[], modifier: DotModifier) => {
  const maxGroupQuantity = modifier.PageInfo.MaxQuantity || Number.MAX_SAFE_INTEGER;

  if (modifierButton.quantity === 1 && (modifierButton.MaxQuantity === 1 || maxGroupQuantity === 1)) {
    modifierButtonRemoveOne(modifierButton);
    return;
  }

  const otherModifierButton = groupModifierButtons.find((groupModifierButton) => groupModifierButton.Link !== modifierButton.Link);
  if (otherModifierButton) {
    modifierButtonAddOneAndRemoveFromAnotherModifierButtonWhenMaxReached(
      modifierButton,
      otherModifierButton,
      computeModifierSelectedQuantity(modifier),
      maxGroupQuantity
    );
  }
};
