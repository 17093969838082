import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@dotxix/pipes/pipes.modules';
import { TableServiceRoutingModule } from '@dotxix/table-service/table-service-routing.module';
import { TableServiceEntryComponent } from '@dotxix/table-service/pages/table-service-entry/table-service-entry.component';
import { TableServiceConfirmationComponent } from '@dotxix/table-service/pages/table-service-confirmation/table-service-confirmation.component';
import { TableServiceSelectionComponent } from '@dotxix/table-service/pages/table-service-selection/table-service-selection.component';
import { TableServiceUnavailableComponent } from '@dotxix/table-service/pages/table-service-unavailable/table-service-unavailable.component';
import { ComponentsModules } from '@dotxix/components/components.module';
import { CustomThemeModule } from '@dotxix/custom-theme/custom-theme.module';

const COMPONENTS = [
  TableServiceSelectionComponent,
  TableServiceConfirmationComponent,
  TableServiceUnavailableComponent,
  TableServiceEntryComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [CommonModule, TableServiceRoutingModule, PipesModule, ComponentsModules, CustomThemeModule],
  providers: [],
  exports: [COMPONENTS],
})
export class TableServiceModule {}
