<div class="popup item-pack-group" data-automation="item-pack-group-popup">
  <div class="popup__content">
    <div class="popup__page-container item-pack-group__page-container">
      <h2 class="popup__title">{{ '2021040201' | translate }}</h2>
      <acr-scrollable-container>
        <div class="modifiers-grid item-pack-group__item-pack">
          <button
            class="product--modifier item-pack-group__product"
            *ngFor="let infoButton of infoButtons"
            (click)="selectedMeal(infoButton.button)"
            data-automation="Item Pack Button"
          >
            <img
              class="item-pack-group__product--img"
              alt=""
              [src]="infoButton.button | catalogPicture"
              [class.logo]="!(infoButton.button | catalogPicture)"
            />

            <h4 class="item-pack-group__product--title">{{ infoButton.button | translateCatalogTitle }}</h4>
            <h4 class="product__price">{{ infoButton.button | catalogButtonMinPrice }}</h4>
            <img
              *ngIf="infoButton.showInfoButton"
              skin-part="image"
              alt=""
              src="/Channels/skin/icon-info.svg"
              class="size--34 mr--10 item-pack-group__product--info"
              (click)="onProductInfoClick($event, infoButton.button)"
            />
          </button>
        </div>
      </acr-scrollable-container>
      <footer class="popup__actions item-pack-group__actions">
        <button class="ui-button ui-button--primary ui-button--small" (click)="onNoThanksClicked()" data-automation="No thanks Button">
          <span class="ui-button__wrapper">
            <span>{{ '72' | translate }}</span>
          </span>
        </button>
      </footer>
    </div>
  </div>
</div>
