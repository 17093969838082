import { DotSdkMocksManager, IJsonMocksOverride, MockPromptEffects, TAB_TYPES } from 'dotsdk';
import { GetLastOrdersResponseInterface } from '@dotxix/osiris/models/interfaces/get-last-orders-response.interface';

export class OsirisGetLastOrdersMockOverrider implements IJsonMocksOverride {
  private uiPanel!: HTMLElement;
  private dotSdkMockManager = DotSdkMocksManager.getInstance();

  public processInputArgs(mockJson: GetLastOrdersResponseInterface): Promise<GetLastOrdersResponseInterface> {
    return new Promise((resolve, reject) => {
      const btnSuccess = document.createElement('button');
      const btnFail = document.createElement('button');

      btnFail.innerText = 'Error';
      btnSuccess.innerText = 'Success';

      this.uiPanel = this.dotSdkMockManager.addMockPrompt({
        title: `Osiris Get Last Orders Mock`,
        interactiveElements: [
          { htmlElement: btnSuccess, clickHandler: () => this.successClick(resolve, mockJson) },
          { htmlElement: btnFail, clickHandler: () => this.failClick(reject) },
        ],
        canRememberChoice: true,
        hostTabType: TAB_TYPES.PROMISED_MOCKS,
        afterAppendEffects: [MockPromptEffects.OPEN_DRAWER_TAB_AND_FOCUS_MOCK_PROMPT],
        afterCTAEffects: [MockPromptEffects.REMOVE_PANEL_AND_CLOSE_DRAWER_IF_HOST_TAB_EMPTY],
        uniqueId: this.dotSdkMockManager.sanitizePersistedMockReplayKey(`osiris-get-last-orders-mock`),
      });
    });
  }
  private successClick(
    resolve: (value: GetLastOrdersResponseInterface | PromiseLike<GetLastOrdersResponseInterface>) => void,
    mockJson: GetLastOrdersResponseInterface
  ) {
    resolve(mockJson);
  }
  private failClick(reject: (reason?: unknown) => void) {
    reject('Error');
  }
}
