import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';
import { OsirisOrderStateService } from '@dotxix/osiris/services/osiris-orders-state.service';
import { OsirisService } from '@dotxix/osiris/services/osiris.service';
import { OrderFlowService } from '@dotxix/services/order-flow.service';
import { OsirisFlowService } from '@dotxix/osiris/services/osiris-flow.service';

@Component({
  selector: 'acr-osiris-order-history',
  templateUrl: './osiris-order-history.component.html',
})
export class OsirisOrderHistoryComponent implements OnInit {
  public orders: OsirisOrder[] = [];
  public showLoadingMessage = false;
  public serverTimeout = false;
  public showNoOrdersMessage = false;
  private handler!: NodeJS.Timeout;

  constructor(
    private orderFlowService: OrderFlowService,
    protected router: Router,
    protected osirisService: OsirisService,
    private osirisOrderState: OsirisOrderStateService,
    private osirisFlowService: OsirisFlowService
  ) {
    const failedOrderToBeRemoved = this.router.getCurrentNavigation()?.extras.state?.failedOrderToBeRemoved;
    if (failedOrderToBeRemoved) {
      this.osirisOrderState.removeSpecificOrder(failedOrderToBeRemoved);
    }
  }

  public ngOnInit() {
    if (!this.osirisOrderState.getOrders().length) {
      if (!this.osirisOrderState.getCustomerIdentification()?.customerID) {
        this.showNoOrdersMessage = true;
        return;
      }
      this.showLoadingMessage = true;
      this.osirisService
        .getLastOrders({ customerID: this.osirisOrderState.getCustomerIdentification()?.customerID ?? '' })
        .then(({ Orders }) => {
          this.osirisOrderState.setOrders(Orders);
          this.orders = this.osirisOrderState.getOrders();
          this.showLoadingMessage = false;
        })
        .catch(() => {
          this.serverTimeout = true;
          this.handler = setTimeout(() => {
            this.orderFlowService.displayMainMenu();
          }, 5000);
        });
    } else {
      this.orders = this.osirisOrderState.getOrders();
    }
  }

  public onReorder(order: OsirisOrder) {
    this.osirisFlowService.reorder(order).then();
  }

  public onViewFullOrder(order: OsirisOrder) {
    this.osirisFlowService.viewFullOrder(order).then();
  }

  public goToMenuClick() {
    clearTimeout(this.handler);
    this.orderFlowService.displayMainMenu().then(() => {});
  }
}
