<language-change-view-trigger></language-change-view-trigger>
<acr-admin-zone></acr-admin-zone>
<button class="flex-full" data-automation="SSV Banner" (click)="click()">
  <acr-slide-show
    [steps]="(fullHdBanners$ | async)! | bannersToSlideShowSteps"
    [basePath]="(bannersPath$ | async)!"
    [animationTypeSelection]="AnimationType.darthFader"
  ></acr-slide-show>
</button>

<div class="flex-center full-width banners__touch-to-order-section" skin="bannersTouchToOrderArea">
  <button
    skin-part="button"
    *ngIf="(enableTouchToOrderSection$ | async) === true && (canStartOrdering$ | async) === true"
    class="ui-button ui-button--secondary ui-button--x-large"
    (click)="click()"
  >
    <span class="ui-button__wrapper" skin-part="button-wrapper">
      <span skin-part="text">{{ '15' | translate }}</span>
    </span>
  </button>
</div>

<acr-payment-logos></acr-payment-logos>
<acr-status></acr-status>
