import { Pipe, PipeTransform } from '@angular/core';
import { DotIngredient } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'translateIngredientLabel',
})
export class IngredientLabelTranslationPipe implements PipeTransform {
  constructor(protected translationsService: TranslationsService) {}

  public transform(ingredient: DotIngredient): string | null {
    if (ingredient && ingredient.LabelDictionary && Object.keys(ingredient.LabelDictionary).length !== 0) {
      const t = ingredient.LabelDictionary[this.translationsService.currentLanguageUpperCase];
      return t ? t : ingredient.LabelDictionary.DEF;
    } else if (ingredient && ingredient.Label) {
      return ingredient.Label;
    } else {
      return null;
    }
  }
}
