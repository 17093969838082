<div class="popup popup--promos" data-automation="promos-popup">
  <div class="popup__content" *ngIf="promoState$ | async as promoState">
    <ng-container [ngSwitch]="promoState.view">
      <ng-container *ngSwitchCase="PROMOS_VIEW.SELECT_METHOD">
        <acr-promos-select-method
          (keyboardSelected)="onKeyboardSelected()"
          (scanSelected)="onScanSelected()"
          (cancel)="methodSelectionCanceled()"
        ></acr-promos-select-method>
      </ng-container>

      <ng-container *ngSwitchCase="PROMOS_VIEW.SCAN">
        <acr-promos-scan-barcode (cancel)="scanBarcodeCanceled()"></acr-promos-scan-barcode>
      </ng-container>

      <ng-container *ngSwitchCase="PROMOS_VIEW.KEYBOARD">
        <acr-promos-keyboard (cancel)="keyboardCanceled()" (continue)="onKeyboardBarcodeSelected($event)"></acr-promos-keyboard>
      </ng-container>
    </ng-container>
  </div>
</div>
