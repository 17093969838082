import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { AppBIService } from '../../../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-glory-cash-overpay-refund-failed',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001029'"
      [amounts]="[
        { label: '2001030', value: (paymentServiceState$ | async)!.cash.lastTransactionFailedToRefundAmount },
        { label: '2001032', value: (paymentServiceState$ | async)!.cash.lastTransactionRefundedAmount }
      ]"
    ></acr-glory-cash-message>

    <!-- Change due: {lastTransactionFailedToRefundAmount} -->
    <!-- Amount refunded: {lastTransactionRefundedAmount} -->

    <!-- Refund has failed. |Your order will still be processed. |Please collect change due at front counter. -->`,
})
export class GloryCashOverpayRefundFailedComponent implements OnInit {
  public paymentServiceState$ = this.paymentService.state$;
  constructor(
    private paymentService: PaymentService,
    private appBiService: AppBIService
  ) {}
  public ngOnInit() {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CASH_PAYMENT_OVERPAY_REFUND_FAILED);
  }
}
