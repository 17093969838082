import { DotButton, DotButtonType, generateUUID, OrderDiscountResponse, PromotionQuery } from 'dotsdk';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { createFakeButton } from '@dotxix/helpers/fake-button.helper';

export const setButtonStateOrderDiscount = (button: DotButton<ButtonState>, orderDiscountPromoInfo: OrderDiscountResponse) => {
  button.state.$$OrderDiscount = orderDiscountPromoInfo?.discountedAmount;
  button.state.$$OrderDiscountPercent = orderDiscountPromoInfo ? orderDiscountPromoInfo.percent / 100 : 0;
};

export const createOrderDiscountInfoButton = (barcode: string, promotionQuery: PromotionQuery): DotButton<ButtonState> => {
  const orderDiscountPromoInfo = promotionQuery.promoPayload as OrderDiscountResponse;
  const promotionUUID = generateUUID();
  const promotionName = promotionQuery.message;
  const promotionPageID = promotionQuery.pageId;

  const button = createFakeButton();
  button.Visibility = '32';
  button.isPromo = true;
  button.quantity = 1;
  button.ButtonType = DotButtonType.ITEM_BUTTON;

  button.state.promoMetadata = { name: promotionName, barcode: barcode, UUID: promotionUUID, pageID: promotionPageID };
  button.Caption = orderDiscountPromoInfo.name;
  setButtonStateOrderDiscount(button, orderDiscountPromoInfo);
  return button;
};
