import { calculateButtonPrice, ComboStepType, DotButton, DotCombo, DotSessionService } from 'dotsdk';
import * as _ from 'lodash';
import { comboInitialStartSize, isComboStep } from '@dotxix/helpers/combo.helper';
import { isItemPackButton, isNotItemPackButton } from '@dotxix/helpers/button.helper';
import { isButtonCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { UpSizeDisplayButton } from '@dotxix/models/interfaces/up-size-display-button';

export const isUpSizeComboStep = (comboStep: DotCombo): boolean =>
  comboStep.ComboStepType.toLocaleLowerCase() === ComboStepType.ComboUpSize.toLowerCase();
export const isNotUpSizeComboStep = (comboStep: DotCombo): boolean =>
  comboStep.ComboStepType.toLocaleLowerCase() !== ComboStepType.ComboUpSize.toLowerCase();

export const visibleOnSize = (button: DotButton, size: string) => Math.abs(Number(button.VisibleOn)) === Math.abs(Number(size));

export const computeUpsizeButtonListWithPrices = (button: DotButton): UpSizeDisplayButton[] => {
  const initialSize = comboInitialStartSize(button);
  return computeUpsizeButtonList(button).map((upsizeButton) => ({
    button: upsizeButton,
    price: getUpsizeButtonPrice(button, upsizeButton.Link, initialSize),
  }));
};

export const computeUpsizeButtonList = (button: DotButton): DotButton[] => {
  const initialSize = comboInitialStartSize(button);
  const upsizeComboStep = button.ComboPage.Combos.find((combo) => isUpSizeComboStep(combo));

  return upsizeComboStep
    ? upsizeComboStep.Buttons.filter(
        (upsizeButton) =>
          Math.abs(Number(upsizeButton.Link)) >= Math.abs(Number(initialSize)) &&
          isButtonCatalogAvailable(upsizeButton) &&
          sizeHasComponentVisibleInEveryCombo(upsizeButton.Link, button)
      )
    : [];
};

export const sizeHasComponentVisibleInEveryCombo = (size: string, button: DotButton): boolean => {
  return button.ComboPage.Combos.every(
    (combo) =>
      isUpSizeComboStep(combo) ||
      combo.Buttons.some((comboStepButton) => visibleOnSize(comboStepButton, size) && isButtonCatalogAvailable(comboStepButton))
  );
};

export const getUpsizeButtonPrice = (button: DotButton, newSize: string, initialSize: string) => {
  const initialButton = _.cloneDeep(button);
  replaceItemsOnResize(initialSize, initialButton);
  initialButton.selectedSize = initialSize;
  const upsizeButton = _.cloneDeep(button);
  replaceItemsOnResize(newSize, upsizeButton);
  upsizeButton.selectedSize = newSize;
  return (
    calculateButtonPrice(upsizeButton, DotSessionService.getInstance().currentPosServingLocation) -
    calculateButtonPrice(initialButton, DotSessionService.getInstance().currentPosServingLocation)
  );
};

export const selectComboSize = (newSize: string, button: DotButton) => {
  const upsizeComboStep = button.ComboPage.Combos.find((combo) => isUpSizeComboStep(combo));
  if (!upsizeComboStep) {
    return;
  }
  const sizeButtons = computeUpsizeButtonList(button);
  upsizeComboStep.Buttons.forEach((sizeButton) => {
    sizeButton.Selected = false;
    sizeButton.quantity = 0;
  });
  const newSizeButton = sizeButtons.find((sizeButton) => sizeButton.Link === newSize);
  if (newSizeButton) {
    newSizeButton.Selected = true;
    newSizeButton.quantity = 1;
  }
};

export const replaceItemsOnResize = (newSize: string, button: DotButton) => {
  button.ComboPage.Combos.filter((c) => isComboStep(c)).forEach((combo) => {
    const comboButtons = combo.Buttons;
    comboButtons.forEach((comboButton) => {
      if (isItemPackButton(comboButton)) {
        comboButton.Page.Buttons.filter((buttonInItemPack) => buttonInItemPack.Selected).forEach((buttonInItemPack) => {
          resizeComboButton(buttonInItemPack, newSize, comboButton.Page.Buttons);
        });
      }

      if (isNotItemPackButton(comboButton) && comboButton.Selected) {
        resizeComboButton(comboButton, newSize, comboButtons);
      }
    });
  });
};

export const resizeComboButton = (oldSizeComboButton: DotButton, newSize: string, comboButtons: DotButton[]) => {
  const replacerLink = oldSizeComboButton.Replacings.find((replacer) => replacer.On === newSize)?.With;
  if (replacerLink) {
    comboButtons.forEach((comboButton) => {
      if (comboButton.Link === replacerLink && comboButton.VisibleOn === newSize) {
        comboButton.Selected = true;
        comboButton.quantity = 1;
        copyModifierSelection(oldSizeComboButton, comboButton);
      }
    });
    oldSizeComboButton.Selected = false;
    oldSizeComboButton.quantity = 0;
  }
};

export const copyModifierSelection = (oldSizeComboButton: DotButton, newSizeComboButton: DotButton) => {
  if (oldSizeComboButton.hasModifiers && newSizeComboButton.hasModifiers) {
    newSizeComboButton.ModifiersPage.Modifiers.forEach((newSizeModifier) => {
      const oldSizeModifier = oldSizeComboButton.ModifiersPage.Modifiers.find(
        (oldSizeModifier) => oldSizeModifier.PageInfo.ModifierID === newSizeModifier.PageInfo.ModifierID
      );
      if (oldSizeModifier) {
        oldSizeModifier.Buttons.forEach((oldSizeModifierButton) => {
          const newSizeModifierButton = newSizeModifier.Buttons.find(
            (newSizeModifierButton) => newSizeModifierButton.Link === oldSizeModifierButton.Link
          );
          if (newSizeModifierButton) {
            newSizeModifierButton.Selected = oldSizeModifierButton.Selected;
            newSizeModifierButton.quantity = oldSizeModifierButton.quantity;
          }
        });
      }
    });
  }
};
