import { AtpEnvironmentService, PosConfig, PosElogHandler, PosOperationDevicesTypes, PosRefintService, PosXmlService } from 'dotsdk';

function extractXmlElementAttrAsObject(element: Element): { [key: string]: unknown } | null {
  const o: { [key: string]: string } = {};

  if (!element) {
    return null;
  }

  for (let i = 0; i < element.attributes.length; i++) {
    const currentAttr = element.attributes.item(i);
    if (currentAttr) {
      o[currentAttr.nodeName] = currentAttr.value;
    }
  }

  return o;
}

function getFirstLevelOfTagType(lookInto: Element, tagName: string): Element[] {
  const response: Element[] = [];
  for (let i = 0; i < lookInto.children.length; i++) {
    const currentChild = lookInto.children.item(i)!;
    if (currentChild.tagName === tagName || tagName === '*') {
      response.push(currentChild);
    }
  }

  return response;
}

export function getCurrentElogAsJson(posConfig: PosConfig): { [key: string]: unknown } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let elogJSON: { [key: string]: any } = {};

  const elogXML = new PosXmlService().generatePosXML(posConfig);

  const elogTransactionElement = elogXML.getElementsByTagName('Transaction').item(0)!;
  elogJSON = { ...elogJSON, ...extractXmlElementAttrAsObject(elogTransactionElement) };
  elogJSON.PreOrder = elogJSON.PreOrder === 'true' ? '1' : '0';
  elogJSON.Closed = elogJSON.Closed && elogJSON.Closed !== '0' ? '1' : '0';
  const { cvars } = posConfig.posHeader || {};
  if (cvars) {
    elogJSON.CVARS = Object.keys(cvars).map((m) => {
      return { name: m, value: cvars[m] };
    });
  } else {
    elogJSON.CVARS = [];
  }

  const statElement = getFirstLevelOfTagType(elogTransactionElement, 'Stat')[0];
  if (statElement) {
    elogJSON.Stat = extractXmlElementAttrAsObject(statElement);

    const operationsElements = getFirstLevelOfTagType(statElement, '*');
    for (let i = 0; i < operationsElements.length; i++) {
      const operationNode = operationsElements[i];

      switch (operationNode.nodeName) {
        case PosOperationDevicesTypes.POS:
          if (!elogJSON.Stat[PosOperationDevicesTypes.POS]) {
            elogJSON.Stat[PosOperationDevicesTypes.POS] = [];
          }
          elogJSON.Stat[PosOperationDevicesTypes.POS].push(extractXmlElementAttrAsObject(operationNode));
          break;
        case PosOperationDevicesTypes.PAY:
          if (!elogJSON.Stat[PosOperationDevicesTypes.PAY]) {
            elogJSON.Stat[PosOperationDevicesTypes.PAY] = [];
          }
          elogJSON.Stat[PosOperationDevicesTypes.PAY].push(extractXmlElementAttrAsObject(operationNode));
          break;
      }
    }

    elogJSON.Stat.POSDetail = elogJSON.Stat.POSDetail === '1' ? '1' : '0';
    elogJSON.Stat.PAYStatus = elogJSON.Stat.PAYStatus === '1' ? '1' : '0';
    elogJSON.Stat.PAYDetail = elogJSON.Stat.PAYDetail === '1' ? '1' : '0';
    elogJSON.Stat.PAYDetail_txt = elogJSON.Stat.PAYDetail_txt === '1' ? '1' : '0';
    elogJSON.Stat.PosDetail_txt = elogJSON.Stat.PosDetail_txt === '1' ? '1' : '0';
    elogJSON.Stat.POSTovDetail_txt = elogJSON.Stat.POSTovDetail_txt === '1' ? '1' : '0';
  }

  const timingElement = getFirstLevelOfTagType(elogTransactionElement, 'Timing')[0];
  if (timingElement) {
    elogJSON.Timing = extractXmlElementAttrAsObject(timingElement);
  }

  const serviceElement = getFirstLevelOfTagType(elogTransactionElement, 'Service')[0];
  if (serviceElement) {
    elogJSON.Service = extractXmlElementAttrAsObject(serviceElement);
  }

  const amountsElement = getFirstLevelOfTagType(elogTransactionElement, 'Amounts')[0];
  if (amountsElement) {
    elogJSON.Amounts = extractXmlElementAttrAsObject(amountsElement);
    const tenderElement = getFirstLevelOfTagType(amountsElement, 'Tender')[0];
    if (tenderElement) {
      elogJSON.Amounts.Tender = extractXmlElementAttrAsObject(tenderElement);
      const tenderItems = getFirstLevelOfTagType(tenderElement, 'TenderItem');
      for (let i = 0; i < tenderItems.length; i++) {
        const tenderItem = tenderItems[i];
        if (!elogJSON.Amounts.Tender.TenderItem) {
          elogJSON.Amounts.Tender.TenderItem = [];
        }
        elogJSON.Amounts.Tender.TenderItem.push(extractXmlElementAttrAsObject(tenderItem));
      }
    }

    const amountServiceElement = getFirstLevelOfTagType(amountsElement, 'Service')[0];
    if (amountServiceElement) {
      const serviceChargeElements = getFirstLevelOfTagType(amountServiceElement, 'SvcCharge');
      for (let i = 0; i < serviceChargeElements.length; i++) {
        const svcChargeItem = serviceChargeElements[i];
        if (!elogJSON.Amounts.Service) {
          elogJSON.Amounts.Service = {};
        }
        if (!elogJSON.Amounts.Service.SvcCharge) {
          elogJSON.Amounts.Service.SvcCharge = [];
        }
        elogJSON.Amounts.Service.SvcCharge.push(extractXmlElementAttrAsObject(svcChargeItem));
      }
    }
  }

  const orderElement = getFirstLevelOfTagType(elogTransactionElement, 'Order')[0];
  if (orderElement) {
    elogJSON.Order = extractXmlElementAttrAsObject(orderElement);
    const dotElements = getFirstLevelOfTagType(orderElement, 'DOTElement');
    for (let i = 0; i < dotElements.length; i++) {
      if (!elogJSON.Order.DOTElement) {
        elogJSON.Order.DOTElement = [];
      }
      const currentDotElement = dotElements[i];
      const currentDotElemJsonObj: {
        Feature: Array<{ [key: string]: unknown }>;
        InternalInfo: { [key: string]: unknown };
        MenuElement: Array<{ Feature: Array<unknown> }>;
      } = (extractXmlElementAttrAsObject(currentDotElement) ?? {}) as {
        Feature: Array<{ [key: string]: unknown }>;
        InternalInfo: { [key: string]: unknown };
        MenuElement: Array<{ Feature: Array<unknown> }>;
      };

      const dotElementInternalInfo = getFirstLevelOfTagType(currentDotElement, 'InternalInfo')[0];
      if (dotElementInternalInfo) {
        currentDotElemJsonObj.InternalInfo = extractXmlElementAttrAsObject(dotElementInternalInfo) ?? {};
      }

      const dotElementFeatures = getFirstLevelOfTagType(currentDotElement, 'Feature');
      for (let j = 0; j < dotElementFeatures.length; j++) {
        if (!currentDotElemJsonObj.Feature) {
          currentDotElemJsonObj.Feature = [];
        }
        const currentDotElementFeature = dotElementFeatures[j];
        currentDotElemJsonObj.Feature.push(extractXmlElementAttrAsObject(currentDotElementFeature) ?? {});
      }

      const dotElementMenuItems = getFirstLevelOfTagType(currentDotElement, 'MenuElement');
      for (let j = 0; j < dotElementMenuItems.length; j++) {
        if (!currentDotElemJsonObj.MenuElement) {
          currentDotElemJsonObj.MenuElement = [];
        }
        const currentMenuItem = dotElementMenuItems[j];
        const currentMenuElemJsonObj: { Feature: Array<unknown> } = (extractXmlElementAttrAsObject(currentMenuItem) ?? {}) as {
          Feature: Array<unknown>;
        };

        const menuItemFeatures = getFirstLevelOfTagType(currentMenuItem, 'Feature');

        for (let k = 0; k < menuItemFeatures.length; k++) {
          if (!currentMenuElemJsonObj.Feature) {
            currentMenuElemJsonObj.Feature = [];
          }
          const currentMenuItemFeature = menuItemFeatures[k];
          currentMenuElemJsonObj.Feature.push(extractXmlElementAttrAsObject(currentMenuItemFeature));
        }
        currentDotElemJsonObj.MenuElement.push(currentMenuElemJsonObj);
      }

      elogJSON.Order.DOTElement.push(currentDotElemJsonObj);
    }
  }

  return elogJSON;
}

export const generateAUIDOnPosHeader = async () => {
  const envDetails = await AtpEnvironmentService.getInstance().getEnvironmentDetails();
  const nestIdentifier = envDetails.NestIdentifier;
  const marketID = nestIdentifier.split('.')[1];
  PosElogHandler.getInstance().posConfig.posHeader.AUID = [
    'v1.1',
    marketID,
    PosElogHandler.getInstance().posConfig.posHeader.storeCode,
    PosElogHandler.getInstance().posConfig.posHeader.kioskId,
    PosRefintService.getInstance()._refInt,
    Math.floor((PosElogHandler.getInstance().posConfig.posHeader.orderStartTime?.getTime() ?? new Date().getTime()) / 1000),
  ].join('-');
};
