import { DotButton } from 'dotsdk';
import { ButtonState } from '@dotxix/models/interfaces/button-state';

export function createFakeButton() {
  return new DotButton<ButtonState>({
    ComboPage: null,
    ModifiersPage: null,
    Page: null,
    Scoring: null,
    SuggestivePages: [],
    Replacing: [],
    Avlb: null,
  });
}
