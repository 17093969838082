import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { DotButton } from 'dotsdk';

@Pipe({
  name: 'translateCaption',
  pure: false,
})
export class TranslateCaptionPipe implements PipeTransform {
  constructor(private translationsService: TranslationsService) {}

  public transform(value: DotButton): string | null {
    return this.translationsService.getTranslatedButtonCaption(value);
  }
}
