import {
  DotAvailabilityService,
  DotButton,
  DotButtonType,
  DotCatalogLoader,
  DotCombosCatalogLoader,
  DotPageType,
  getCatalogButton,
  getCombosCatalogButton,
} from 'dotsdk';

const evaluateCatalogItemButtonAvailability = (button: DotButton): boolean => {
  const itemsCatalog = DotCatalogLoader.getInstance().loadedModel;
  const equivalentCatalogItem = getCatalogButton(button.Link);

  if (!itemsCatalog || !equivalentCatalogItem) {
    return true;
  }

  return DotAvailabilityService.getInstance().isButtonAvailable(equivalentCatalogItem);
};

const evaluateCatalogMenuButtonAvailability = (button: DotButton): boolean => {
  const menuCatalog = DotCombosCatalogLoader.getInstance().loadedModel;
  const equivalentCatalogMenu = getCombosCatalogButton(button.Link);

  if (!menuCatalog || !equivalentCatalogMenu) {
    return true;
  }

  return DotAvailabilityService.getInstance().isButtonAvailable(equivalentCatalogMenu);
};

export const isButtonCatalogAvailable = (button: DotButton) => {
  if (DotAvailabilityService.getInstance().isButtonAvailable(button)) {
    switch (button.ButtonType) {
      case DotButtonType.ITEM_BUTTON:
        return evaluateCatalogItemButtonAvailability(button);
      case DotButtonType.MENU_BUTTON:
        return evaluateCatalogMenuButtonAvailability(button);
      default:
        return true;
    }
  } else {
    return false;
  }
};

export const filterCatalogAvailable = (buttons: DotButton[]) =>
  buttons.filter((button) => isButtonCatalogAvailable(button) || evaluatePromoButtonAvailable(button));

const evaluatePromoButtonAvailable = (button: DotButton): boolean => {
  return (
    (button.Page?.PageType === DotPageType.PROMO && DotAvailabilityService.getInstance().testAvailability(button.Avlb, new Date()) === 0) ||
    button.Page?.Buttons?.some((btn) => evaluatePromoButtonAvailable(btn))
  );
};
