import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AdaptabilityService } from '@dotxix/services/adaptability.service';

@Component({
  selector: 'acr-footer',
  templateUrl: './footer.component.html',
  host: { class: 'mt--auto' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  constructor(private adaptabilityService: AdaptabilityService) {}

  public switchAdaMode() {
    this.adaptabilityService.toggle();
  }
}
