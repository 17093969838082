import { ChangeDetectorRef, Directive, ViewContainerRef } from '@angular/core';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';

@Directive({
  selector: '[setAsDynamicContainer]', // eslint-disable-line
})
export class SetAsDynamicContainerDirective {
  constructor(
    private dynamicContentService: DynamicContentService,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.dynamicContentService.setViewRefAndChangeDetectorRef(viewContainerRef, changeDetectorRef);
  }
}
