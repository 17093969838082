export const executeWithTimeout = <T>(
  functionReturningPromise: () => Promise<T>,
  timeoutInMilliseconds: number,
  timeoutResponse: () => T
): Promise<T> => {
  return new Promise((resolve) => {
    let timeoutExpired = false;
    const timer = setTimeout(() => {
      timeoutExpired = true;
      resolve(timeoutResponse());
    }, timeoutInMilliseconds);

    functionReturningPromise()
      .then((result) => {
        if (!timeoutExpired) {
          clearTimeout(timer);
          resolve(result);
        }
      })
      .catch(() => {
        if (!timeoutExpired) {
          clearTimeout(timer);
          resolve(timeoutResponse());
        }
      });
  });
};
