<language-change-view-trigger></language-change-view-trigger>
<!-- edited card grid in _basket  -->
<div
  class="product-card product-card--basket-card"
  [ngClass]="{ 'product-card--promo-card': product.state.promoChildButtons }"
  data-automation="Basket Item"
>
  <!-- left column -->
  <header *ngIf="!product.state.promoChildButtons" class="product-card__header flex flex--column place-center justify-center">
    <img class="product-card__img" [class.logo]="!productImagePath" [src]="productImagePath" width="150rem" height="150rem" alt="" />
    <div class="flex" *ngIf="!allowModifyProduct || children.length > 0">
      <h4 data-automation="Product Quantity">{{ product.quantity }}</h4>
      <h4>x</h4>
    </div>
    <acr-buttons-counter-edit
      *ngIf="allowModifyProduct && !product.isPromo && children.length === 0"
      [quantity]="product.quantity"
      (quantityUpdate)="onQuantityUpdate($event)"
    ></acr-buttons-counter-edit>
  </header>
  <!-- right column  containing all contents-->
  <div class="flex flex--column justify-between">
    <div class="flex place-center justify-between">
      <!-- left column  -->
      <div>
        <h3 class="product-card__title" data-automation="Product Title">{{ productTitle }}</h3>
        <ul class="product-card__contents-list" *ngIf="productDetailItems.length">
          <li *ngFor="let item of productDetailItems" class="product-card__contents-list--item">
            <acr-product-card-details
              [productItem]="item"
              [product]="product"
              [showItemChangeButton]="allowModifyProduct"
              (customizeSingleProduct)="onCustomizeSingleProduct($event)"
            ></acr-product-card-details>
          </li>
        </ul>
      </div>
      <!-- right column  -->
      <div class="product-card__actions" *ngIf="allowModifyProduct">
        <ng-container *ngIf="productCanBeCustomized">
          <button class="ui-button ui-button--tertiary ui-button--small" (click)="changeProduct(product)" data-automation="Button: Change">
            {{ '18' | translate }}
          </button>
          <div class="spacer--20"></div>
        </ng-container>
        <button class="ui-button ui-button--tertiary ui-button--small" (click)="onRemoveClick()" data-automation="Button: Remove">
          {{ '37' | translate }}
        </button>
      </div>
    </div>

    <!-- link to parent subcard -->
    <div *ngIf="children.length > 0" class="product-card-sub-card-item">
      <acr-product-card-sub-card-item
        *ngFor="let child of children"
        [button]="child"
        [allowModifyProduct]="allowModifyProduct"
        (customizeProduct)="changeProduct($event)"
      ></acr-product-card-sub-card-item>
    </div>
    <!-- link to parent subcard end-->

    <h5 class="product-card__price p--20" data-automation="Product Price Or Discount">
      <ng-container *ngIf="product.state.$$OrderDiscount; else productPrice">
        {{ product.state.$$OrderDiscountPercent }} % {{ '20210504001' | translate }}
      </ng-container>
      <ng-template #productPrice>{{ price | dotCurrency }}</ng-template>
    </h5>
  </div>
</div>
