import { Component, OnInit } from '@angular/core';
import { AppBIService } from '../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-payment-progress',
  templateUrl: 'card-payment-page.component.html',
})
export class CardPaymentPageComponent implements OnInit {
  constructor(private appBiService: AppBIService) {}
  public ngOnInit() {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CARD_PAYMENT_PROGRESS);
  }
}
