import { Pipe, PipeTransform } from '@angular/core';
import { DotPrefixId } from 'dotsdk';

@Pipe({
  name: 'prefixVisualQuantity',
})
export class PrefixVisualQuantityPipe implements PipeTransform {
  public transform(prefixId: DotPrefixId): string {
    if (prefixId === DotPrefixId.EXTRA) {
      return '2';
    }
    if (prefixId === DotPrefixId.NO) {
      return '0';
    }

    return '1';
  }
}
