import { Component } from '@angular/core';
import { ReceiptService } from '@dotxix/services/receipt/receipt.service';
import { ReceiptOption } from '@dotxix/services/receipt/models/receipt-option.enum';
import { ReceiptFlowService } from '@dotxix/services/receipt/receipt-flow.service';

@Component({
  selector: 'acr-receipt-options',
  templateUrl: './receipt-options.component.html',
})
export class ReceiptOptionsComponent {
  public availableReceiptOptions = this.receiptService.availableReceiptOptions;

  public receiptOptionLabel = {
    [ReceiptOption.PAPER_PRINT]: '2003000',
    [ReceiptOption.EMAIL]: '2003001',
    [ReceiptOption.ELECTRONIC_RECEIPT]: '2003002',
    [ReceiptOption.SMS]: '2003003',
  };

  constructor(
    private receiptService: ReceiptService,
    private receiptFlowService: ReceiptFlowService
  ) {}

  public receiptOptionClick(receiptOption: ReceiptOption) {
    this.receiptFlowService.userSelectedReceiptOption(receiptOption);
  }
}
