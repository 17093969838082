import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonInfoService } from '@dotxix/button-info.service';
import { DotButton, DotButtonType } from 'dotsdk';

@Component({
  selector: 'acr-button-item',
  templateUrl: './button-item.component.html',
})
export class ButtonItemComponent implements OnInit {
  @Input() public button!: DotButton;
  @Input() public selected = false;
  @Input() public isDisabled = false;
  @Input() public displayPrice: string | undefined;
  @Input() public displayPrefixPlusSignForPrice = false;
  @Input() public showCounterEdit = true;
  @Input() public quantity!: number;
  @Input() public maxQuantity: number = Number.MAX_SAFE_INTEGER;
  @Input() public isIncreaseDisabled = false;
  @Input() public isComboSizeSelectionButton = false;
  @Output() public buttonClicked: EventEmitter<void> = new EventEmitter();
  @Output() public quantityUpdate: EventEmitter<1 | -1> = new EventEmitter();

  public ButtonType = DotButtonType;
  public showInfoButton!: boolean;

  constructor(private buttonInfoService: ButtonInfoService) {}

  public onButtonClicked() {
    this.buttonClicked.emit();
  }

  public onQuantityUpdate(count: 1 | -1) {
    this.quantityUpdate.emit(count);
  }

  public hasSelectedItems(): boolean {
    return this.button ? this.button.Page.Buttons.some((b) => b.Selected && b.quantity > 0) : false;
  }
  public ngOnInit(): void {
    this.showInfoButton = this.button ? this.buttonInfoService.showProductInfo(this.button) : false;
  }
}
