import { Injectable } from '@angular/core';
import {
  AtpApplicationSettings,
  calculateButtonPrice,
  DotButton,
  DotPage,
  DotSessionEndType,
  DotSessionService,
  DotSessionState,
  generateUUID,
  PosElogHandler,
  PosServingLocation,
} from 'dotsdk';
import { RecommendationModes } from '@dotxix/services/suggestions/models/recommendation-modes.enum';
import { SessionService } from '@dotxix/services/session.service';
import {
  getKbForcedItemSuggestions,
  getKbForcedOrderSuggestions,
  getKbItemSuggestions,
  getKbOrderSuggestions,
} from '@dotxix/services/suggestions/helpers/kb-suggestions.helper';
import { filteredByAvailabilityAndStatus } from '@dotxix/services/suggestions/helpers/filter.helper';
import { RecommendationActionView } from '@dotxix/services/suggestions/models/recommendation-action-view';
import { RecoButtonState } from '@dotxix/services/suggestions/models/reco-button-state.interface';
import { RecommendationHttpService } from '@dotxix/services/suggestions/recommendation-http.service';
import { ButtonRecommendationMode } from '@dotxix/services/suggestions/models/button-recommendation-mode';
import { recoLogger } from '@dotxix/services/suggestions/logger';
import { StoresAuthenticateResponse } from '@dotxix/services/suggestions/models/stores-authenticate-response';
import { RecoEngine } from '@dotxix/services/suggestions/models/reco-engine';
import { RecoOrderResponse } from '@dotxix/services/suggestions/models/reco-order-response';
import { RecoCampaignResponse } from '@dotxix/services/suggestions/models/reco-campaign-response';
import { RecoCampaign } from '@dotxix/services/suggestions/models/reco-campaign';
import { RecoVariation } from '@dotxix/services/suggestions/models/reco-variation';
import { RecoParameter } from '@dotxix/services/suggestions/models/reco-parameter';
import { BehaviorSubject } from 'rxjs';
import { RecommendationQueuedUpdates } from '@dotxix/services/suggestions/models/recommendation-queued-updates';
import { RecommendationSessionParameters } from '@dotxix/services/suggestions/models/recommendation-session-parameters';
import {
  buildSessionParameters,
  defaultRecommendationSessionParameters,
} from '@dotxix/services/suggestions/helpers/recommendation-session-parameters.helper';
import { RecoParameterKey } from '@dotxix/services/suggestions/models/reco-parameter-key';
import { mapRecoProductToDotButton } from '@dotxix/services/suggestions/helpers/map-reco-product-to-dot-button.helper';
import { addRecommendationMode } from '@dotxix/services/suggestions/helpers/recommendation-view.helper';
import { RecommendedProductType } from '@dotxix/services/suggestions/models/recommended-product-type';
import { RecommendedProduct } from '@dotxix/services/suggestions/models/recommended-product';
import { EnginesResponse } from '@dotxix/services/suggestions/models/engines-response';
import { diffInSeconds } from '@dotxix/services/suggestions/helpers/reco-time.helper';
import {
  mapDotSessionEndTypeToRecoOrderState,
  mapPaymentTypeToRecoPaymentMethod,
  mapRecoOrderAbortMethod,
} from '@dotxix/services/suggestions/helpers/reco.helper';
import { getAmountPaidWithCash, getElogPaidAmount } from '@dotxix/helpers/pos-config.helper';
import { PAYMENT_TYPE } from '@dotxix/models/enums/payment-type';
import { executeWithTimeout } from '@dotxix/helpers/promise.helper';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { price } from '@dotxix/helpers/price.helper';

@Injectable({ providedIn: 'root' })
export class RecommendationService {
  public popularSuggestions$: BehaviorSubject<DotButton[]> = new BehaviorSubject([] as DotButton[]);
  public loadingPopularSuggestions$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public sessionParameters: RecommendationSessionParameters;
  private accessToken = '';
  private engineList: RecoEngine[] = [];
  private sessionRecoOrder: RecoOrderResponse | null = null;
  private sessionRecoCampaign: RecoCampaign | null = null;
  private sessionOptimizeUsedParameters: { [key: string]: string | boolean | number } = {};
  private queuedUserActions: RecommendationQueuedUpdates = this.emptyQueuedUpdates();

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private sessionService: SessionService,
    private recommendationHttpService: RecommendationHttpService
  ) {
    this.sessionParameters = defaultRecommendationSessionParameters();
  }

  public initialize() {
    this.sessionService.sessionStarted.subscribe((type) => this.startRecoOrder(type));
    DotSessionService.getInstance().sessionStateChanges.subscribe((state: DotSessionState) => {
      if (state === DotSessionState.IDLE) {
        this.resetState();
      }
    });

    this.sessionService.registerBeforeSessionRestartCallback((status) => this.endOrder(status));

    if (this.applicationSettingsService.settings$.value.enableRecoModule) {
      this.authenticate()
        .then(() =>
          this.retrieveEnginesList()
            .catch(() => {})
            .finally(() => this.startEngineRefreshTimer())
        )
        .catch(() => {});
    }
  }

  public createOrder(type: PosServingLocation): Promise<RecoOrderResponse> {
    recoLogger.info('RECO: create order').then();
    return new Promise((resolve, reject) => {
      this.executePromiseAndAuthenticationWhenUnauthorized<RecoOrderResponse>(() => {
        return this.recommendationHttpService.createOrder(type === PosServingLocation.IN ? 'EAT_IN' : 'TAKE_OUT');
      })
        .then((response) => {
          recoLogger.info(`RECO: order created ${response.id}`).then();
          this.storeSessionRecoOrder(response);
          resolve(response);
        })
        .catch((response) => {
          recoLogger.error(`RECO: failed to create order ${JSON.stringify(response)}`).then();
          reject(response);
        });
    });
  }

  public generateCampaign(): Promise<RecoCampaignResponse> {
    recoLogger.info('RECO: generate campaign').then();
    return new Promise((resolve, reject) => {
      this.executePromiseAndAuthenticationWhenUnauthorized<RecoCampaignResponse>(() => {
        return this.recommendationHttpService.generateCampaign(this.sessionRecoOrder?.id ?? 0);
      })
        .then((response) => {
          if (response.campaigns && response.campaigns[0]) {
            recoLogger.info(`RECO: campaign generated ${response.campaigns[0].id} ${response.campaigns[0].name}`).then();
            this.storeCampaignVariationsAndParameters(response.campaigns[0], response.variations, response.parameters);
            resolve(response);
          } else {
            recoLogger.error(`RECO: failed to generate campaign ${JSON.stringify(response)}`).then();
            reject(response);
          }
        })
        .catch((response) => {
          recoLogger.error(`RECO: failed to create order ${JSON.stringify(response)}`).then();
          reject(response);
        });
    });
  }

  public async getItemSuggestionsAfterUserAction(
    view: RecommendationActionView,
    howManyToRequest: number,
    maximumNumberOfButtons: number,
    button: DotButton
  ) {
    if (button.metadata.isSuggestiveItem) {
      this.userAction(view, button, button.quantity).catch(() => {});
      return [];
    }

    if (
      this.sessionParameters[RecoParameterKey.RecommendationEnableSuggestives] === false ||
      this.sessionParameters[RecoParameterKey.RecommendationMode] === RecommendationModes.NONE
    ) {
      this.userAction(view, button, button.quantity).catch(() => {});
      return getKbForcedItemSuggestions(button);
    }

    if (this.sessionParameters[RecoParameterKey.RecommendationMode] === RecommendationModes.KIOSK_BUILDER) {
      this.userAction(view, button, button.quantity).catch(() => {});
      return this.getKioskBuilderItemSuggestions(button);
    }

    return this.findCloudItemSuggestionsWithKioskBuilderFallbackAfterUserAction(view, howManyToRequest, maximumNumberOfButtons, button);
  }

  public async getOrderSuggestions(howManyToRequest: number, maximumNumberOfButtons: number): Promise<DotPage[]> {
    if (!this.sessionParameters[RecoParameterKey.RecommendationEnableSuggestives]) {
      return [];
    }

    return await this.findOrderSuggestions(howManyToRequest, maximumNumberOfButtons);
  }

  public async getBasketSuggestions(howManyToRequest: number, maximumNumberOfButtons: number): Promise<DotPage[]> {
    if (!this.sessionParameters[RecoParameterKey.RecommendationEnableBasket]) {
      return [];
    }

    return await this.findOrderSuggestions(howManyToRequest, maximumNumberOfButtons);
  }

  public async userAction(view: RecommendationActionView, button: DotButton<RecoButtonState>, quantity: number): Promise<unknown> {
    if (!this.sessionRecoOrder) {
      return;
    }
    if (view === RecommendationActionView.BasketPlus || view === RecommendationActionView.BasketMinus) {
      return this.sendDelayedBufferedUserAction(view, button, quantity);
    } else {
      return this.sendUserAction(view, button, quantity);
    }
  }

  public async endOrder(state: DotSessionEndType) {
    if (!this.sessionRecoOrder) {
      return;
    }
    const updateOrderPayload = this.buildUpdateOrderPayload(state);
    recoLogger.info(`RECO: Update Order ${JSON.stringify(updateOrderPayload)}`).then();
    this.recommendationHttpService.updateOrder(updateOrderPayload, this.sessionRecoOrder.id).catch((errorResponse) => {
      recoLogger.error(`RECO: Update Order failed ${JSON.stringify(errorResponse)}`).then();
    });
  }

  private authenticate(): Promise<StoresAuthenticateResponse> {
    recoLogger.info('RECO: authenticate').then();
    return new Promise((resolve, reject) => {
      this.recommendationHttpService
        .storesAuthenticate(
          AtpApplicationSettings.getInstance().bundleSettingsJson.recoStoreId,
          AtpApplicationSettings.getInstance().bundleSettingsJson.recoPublicKey
        )
        .then((response) => {
          recoLogger.info(`RECO: authenticated successfully`).then();
          this.storeAccessToken(response.accessToken);
          resolve(response);
        })
        .catch((response) => {
          recoLogger.error(`RECO: failed to authenticate ${JSON.stringify(response)}`).then();
          reject(response);
        });
    });
  }

  private executePromiseAndAuthenticationWhenUnauthorized<RESPONSE_TYPE>(promisedRequestFunction: () => Promise<RESPONSE_TYPE>) {
    return new Promise<RESPONSE_TYPE>((resolve, reject) => {
      promisedRequestFunction()
        .then((firstCallResponse) => {
          resolve(firstCallResponse);
        })
        .catch((firstCallErrorResponse) => {
          if (firstCallErrorResponse.status === 401) {
            this.authenticate()
              .then(() => {
                promisedRequestFunction()
                  .then((secondCallResponse) => {
                    resolve(secondCallResponse);
                  })
                  .catch((secondCallErrorResponse) => {
                    reject(secondCallErrorResponse);
                  });
              })
              .catch(() => {
                reject(firstCallErrorResponse);
              });
          } else {
            reject(firstCallErrorResponse);
          }
        });
    });
  }

  private storeAccessToken(accessToken: string) {
    this.accessToken = accessToken;
    this.recommendationHttpService.setAccessToken(this.accessToken);
  }

  private storeEngineList(list: RecoEngine[]) {
    this.engineList = list;
  }

  private startRecoOrder(type: PosServingLocation) {
    if (!this.applicationSettingsService.settings$.value.enableRecoModule) {
      return;
    }
    this.createOrder(type)
      .then(() => {
        this.generateCampaign()
          .then(() => this.loadPopularSuggestions())
          .catch(() => {});
      })
      .catch(() => {});
  }

  private storeSessionRecoOrder(recoOrder: RecoOrderResponse) {
    this.sessionRecoOrder = recoOrder;
  }

  private storeCampaignVariationsAndParameters(recoCampaign: RecoCampaign, variations: RecoVariation[], parameters: RecoParameter[]) {
    this.sessionRecoCampaign = recoCampaign;
    const { sessionParameters, optimizeUsedParameters } = buildSessionParameters(recoCampaign, parameters, variations);
    this.sessionParameters = sessionParameters;
    this.sessionOptimizeUsedParameters = optimizeUsedParameters;
    recoLogger.info(`RECO: continue session with randomized parameters: ${JSON.stringify(this.sessionParameters)}`).then();
  }

  private retrieveEnginesList() {
    recoLogger.info('RECO: retrieve engines list').then();
    return new Promise<EnginesResponse>((resolve, reject) => {
      this.executePromiseAndAuthenticationWhenUnauthorized<EnginesResponse>(() => {
        return this.recommendationHttpService.engines();
      })
        .then((response) => {
          recoLogger.info(`RECO: received ${response.count} engines`).then();
          this.storeEngineList(response.items);
          resolve(response);
        })
        .catch((response) => {
          recoLogger.error(`RECO: failed to retrieve engines list ${JSON.stringify(response)}`).then();
          reject(response);
        });
    });
  }

  private startEngineRefreshTimer() {
    setTimeout(
      () => this.retrieveEnginesList().finally(() => this.startEngineRefreshTimer()),
      (AtpApplicationSettings.getInstance().bundleSettingsJson.recoEnginesRefreshMinutes || 60) * 60000
    );
  }

  private async loadPopularSuggestions() {
    if (this.sessionParameters[RecoParameterKey.RecommendationEnablePopular]) {
      this.loadingPopularSuggestions$.next(true);
      this.popularSuggestions$.next(await this.getPopularSuggestions(6, 3));
      this.loadingPopularSuggestions$.next(false);
    }
  }

  private findCloudItemSuggestionsWithKioskBuilderFallbackAfterUserAction(
    view: RecommendationActionView,
    howManyToRequest: number,
    maximumNumberOfButtons: number,
    button: DotButton
  ) {
    const timeoutInSeconds = AtpApplicationSettings.getInstance().bundleSettingsJson.recoFallbackTimerSeconds || 1.5;
    let fallbackUsed = false;
    return executeWithTimeout<DotPage[]>(
      async () => {
        await this.userAction(view, button, button.quantity).catch(() => {});
        if (fallbackUsed) {
          return [];
        }

        const cloudSuggestions = await this.getCloudEngineOrderSuggestions(howManyToRequest, maximumNumberOfButtons);
        if (fallbackUsed) {
          recoLogger.info(`RECO: CLOUD_ENGINE item suggestions retrieved but discarded because fallback was used`).then();
          return [];
        }

        return [...cloudSuggestions, ...getKbForcedItemSuggestions(button)];
      },
      timeoutInSeconds * 1000,
      () => {
        fallbackUsed = true;
        recoLogger
          .info(`RECO: Failed to retrieve item suggestions from CLOUD_ENGINE in ${timeoutInSeconds} seconds, using fallback KIOSK_BUILDER`)
          .then();
        return this.getKioskBuilderItemSuggestions(button);
      }
    );
  }

  private async findOrderSuggestions(howManyToRequest: number, maximumNumberOfButtons: number) {
    let cloudSuggestions;
    switch (this.sessionParameters[RecoParameterKey.RecommendationMode]) {
      case RecommendationModes.KIOSK_BUILDER:
        return this.getKioskBuilderOrderSuggestions();
      case RecommendationModes.CLOUD_ENGINE:
        cloudSuggestions = await this.findCloudOrderSuggestionsWithKioskBuilderFallback(howManyToRequest, maximumNumberOfButtons);
        return [...cloudSuggestions, ...getKbForcedOrderSuggestions()];
      default:
        return getKbForcedOrderSuggestions();
    }
  }

  private findCloudOrderSuggestionsWithKioskBuilderFallback(howManyToRequest: number, maximumNumberOfButtons: number) {
    const timeoutInSeconds = AtpApplicationSettings.getInstance().bundleSettingsJson.recoFallbackTimerSeconds || 1.5;
    let fallbackUsed = false;
    return executeWithTimeout<DotPage[]>(
      async () => {
        const cloudSuggestions = await this.getCloudEngineOrderSuggestions(howManyToRequest, maximumNumberOfButtons);
        if (fallbackUsed) {
          recoLogger.info(`RECO: CLOUD_ENGINE order suggestions retrieved but discarded because fallback was used`).then();
          return [];
        }

        return cloudSuggestions;
      },
      timeoutInSeconds * 1000,
      () => {
        fallbackUsed = true;
        recoLogger
          .info(`RECO: Failed to retrieve order suggestions from CLOUD_ENGINE in ${timeoutInSeconds} seconds, using fallback KIOSK_BUILDER`)
          .then();
        return this.getKioskBuilderOrderSuggestions();
      }
    );
  }

  private async sendDelayedBufferedUserAction(view: RecommendationActionView, button: DotButton<RecoButtonState>, quantity: number) {
    if (view !== RecommendationActionView.BasketMinus && view !== RecommendationActionView.BasketPlus) {
      return;
    }
    if (this.queuedUserActions[view][button.uuid]) {
      this.queuedUserActions[view][button.uuid].quantity += quantity;
      this.queuedUserActions[view][button.uuid].view = view;
    } else {
      this.queuedUserActions[view][button.uuid] = { button, quantity, view };
      this.delayPublishBufferedUpdate(view, button.uuid);
    }
  }

  private delayPublishBufferedUpdate(view: RecommendationActionView, buttonUuid: string) {
    if (view !== RecommendationActionView.BasketMinus && view !== RecommendationActionView.BasketPlus) {
      return;
    }
    setTimeout(() => {
      const queuedUpdate = this.queuedUserActions[view][buttonUuid];
      if (queuedUpdate) {
        delete this.queuedUserActions[view][buttonUuid];
        this.sendUserAction(queuedUpdate.view, queuedUpdate.button, queuedUpdate.quantity).catch(() => {});
      }
    }, this.getRecoUpdateBufferTimerDuration());
  }

  private getRecoUpdateBufferTimerDuration() {
    const bsRecoUpdateBufferTimerSeconds = AtpApplicationSettings.getInstance().bundleSettingsJson.recoUpdateBufferTimerSeconds;
    const timerInSeconds = bsRecoUpdateBufferTimerSeconds === 'number' ? bsRecoUpdateBufferTimerSeconds : 2;
    return timerInSeconds * 1000;
  }

  private async sendUserAction(view: RecommendationActionView, button: DotButton<RecoButtonState>, quantity: number) {
    const userActionPayload = {
      key: generateUUID(),
      productId: button.Link,
      productType: button.hasCombos ? 'MENU_BUTTON' : 'ITEM_BUTTON',
      quantity,
      value: calculateButtonPrice(button),
      additionalData: null,
      context: {
        recommendationMode:
          typeof button.state?.recommendationMode === 'string' ? button.state.recommendationMode : ButtonRecommendationMode.KIOSK_BUILDER,
        view,
      },
    };
    recoLogger.info(`RECO: User action ${JSON.stringify(userActionPayload)}`).then();
    return new Promise((resolve, reject) => {
      this.executePromiseAndAuthenticationWhenUnauthorized(() => {
        return this.recommendationHttpService.userAction(userActionPayload, this.sessionRecoOrder?.id ?? 0);
      })
        .then((result) => {
          resolve(result);
        })
        .catch((errorResult) => {
          recoLogger.info(`RECO: Publishing user action failed ${JSON.stringify(errorResult)}`).then();
          reject(errorResult);
        });
    });
  }

  private resetState() {
    this.sessionRecoOrder = null;
    this.sessionRecoCampaign = null;
    this.sessionParameters = defaultRecommendationSessionParameters();
    this.sessionOptimizeUsedParameters = {};
    this.queuedUserActions = this.emptyQueuedUpdates();
    this.popularSuggestions$.next([]);
    this.loadingPopularSuggestions$.next(false);
  }

  private filterPriceAcceptableRecommendations(buttons: DotButton[]) {
    const minPrice = this.sessionParameters[RecoParameterKey.RecommendationMinimumPrice];
    const currentPosServingLocation = DotSessionService.getInstance().currentPosServingLocation;
    return buttons.filter((button) => price(button, currentPosServingLocation) >= minPrice);
  }

  private getKioskBuilderItemSuggestions(button: DotButton): DotPage[] {
    const suggestionsPages = getKbItemSuggestions(button);
    suggestionsPages.forEach((p) => {
      p.Buttons = filteredByAvailabilityAndStatus(p.Buttons);
    });
    return suggestionsPages;
  }

  private getKioskBuilderOrderSuggestions(): DotPage[] {
    const suggestionsPages = getKbOrderSuggestions();
    suggestionsPages.forEach((p) => {
      p.Buttons = filteredByAvailabilityAndStatus(p.Buttons);
    });
    return suggestionsPages;
  }

  private async getPopularSuggestions(howManyToRequest: number, maximumNumberOfButtons: number): Promise<DotButton[]> {
    if (!this.sessionRecoOrder) {
      return [];
    }

    let response: DotButton[];
    try {
      const recommendedProducts = await this.executePromiseAndAuthenticationWhenUnauthorized<RecommendedProduct[]>(() => {
        return this.recommendationHttpService.popularItems(howManyToRequest, this.getRecommendationEngineUrl());
      }).catch((e) => Promise.reject(e));
      return this.processRecommendedProducts(recommendedProducts, maximumNumberOfButtons);
    } catch (error) {
      recoLogger.error(`RECO: Failed to retrieve popular suggestions ${JSON.stringify(error)}`).then();
      response = [];
    }

    return response;
  }

  private getCloudEngineOrderSuggestions(howManyToRequest: number, maximumNumberOfButtonsToReturn: number) {
    return new Promise<DotPage[]>((resolve, reject) => {
      if (!this.sessionRecoOrder) {
        reject('Missing reco session');
        return;
      }

      this.executePromiseAndAuthenticationWhenUnauthorized<RecommendedProduct[]>(() => {
        return this.recommendationHttpService.orderRecommendedItems(
          this.sessionRecoOrder?.id ?? 0,
          this.getRecommendationEngineUrl(),
          howManyToRequest
        );
      })
        .then((recommendedProducts) => {
          const page = new DotPage({});
          page.Buttons = this.processRecommendedProducts(recommendedProducts, maximumNumberOfButtonsToReturn);
          resolve([page]);
        })
        .catch((error) => {
          recoLogger.error(`RECO: Failed to retrieve order suggestions ${JSON.stringify(error)}`).then();
          reject(error);
        });
    });
  }

  private getRecommendationEngineUrl(): string {
    const engine = this.engineList.find(
      (engineInList) => engineInList.name === this.sessionParameters[RecoParameterKey.RecommendationEngine]
    );
    if (engine) {
      return engine.url;
    }
    return '';
  }

  private processRecommendedProducts(recommendedProducts: RecommendedProduct[], numberOfProducts: number) {
    if (!this.sessionParameters[RecoParameterKey.RecommendationEnableCombo]) {
      recommendedProducts = recommendedProducts.filter(
        (recommendedProduct) => recommendedProduct.productType !== RecommendedProductType.MENU_BUTTON
      );
    }
    const buttons: DotButton[] = [];
    recommendedProducts.forEach((recommendedProduct) => {
      const button = mapRecoProductToDotButton(recommendedProduct);
      if (button) {
        buttons.push(button);
      }
    });
    const availableButtons = filteredByAvailabilityAndStatus(buttons);
    const priceAcceptableButtons = this.filterPriceAcceptableRecommendations(availableButtons);
    return addRecommendationMode(priceAcceptableButtons.slice(0, numberOfProducts), ButtonRecommendationMode.CLOUD_ENGINE);
  }

  private emptyQueuedUpdates(): RecommendationQueuedUpdates {
    return {
      [RecommendationActionView.BasketMinus]: {},
      [RecommendationActionView.BasketPlus]: {},
    };
  }

  private buildUpdateOrderPayload(endReason: DotSessionEndType) {
    const posHeader = PosElogHandler.getInstance().posConfig.posHeader;
    const endDate = new Date();
    const orderTime = diffInSeconds(posHeader.orderStartTime!, endDate);
    const abortMethod = mapRecoOrderAbortMethod(endReason);
    const paidWithCash = getAmountPaidWithCash();
    const paidAmount = getElogPaidAmount() / 100;
    const paymentMethod =
      paidAmount > 0 ? mapPaymentTypeToRecoPaymentMethod(paidWithCash > 0 ? PAYMENT_TYPE.CASH : PAYMENT_TYPE.CARD) : null;
    const recommendationEngineUrl = this.getRecommendationEngineUrl();
    return {
      state: mapDotSessionEndTypeToRecoOrderState(endReason),
      orderTime,
      ...(posHeader.firstItemAddedTime ? { firstItemTime: diffInSeconds(posHeader.orderStartTime!, posHeader.firstItemAddedTime) } : {}),
      ...(posHeader.payStartTime ? { startPaymentTime: diffInSeconds(posHeader.orderStartTime!, posHeader.payStartTime) } : {}),
      ...(endReason === DotSessionEndType.CANCEL_ORDER ? { cancelTime: orderTime } : {}),
      ...(endReason === DotSessionEndType.APP_TIMEOUT ? { abandonTime: orderTime } : {}),
      ...(abortMethod ? { abortMethod, abortDate: endDate.toISOString() } : {}),
      ...(paidAmount === 0
        ? {}
        : {
            paymentMethod,
            paymentDate: new Date(),
            paidAmount,
            taxesAmount: 0,
          }),
      recommendationMode: this.sessionParameters[RecoParameterKey.RecommendationMode],
      recommendationEngine: recommendationEngineUrl,
      context: {
        kioskId: this.applicationSettingsService.settings$.value.kioskId,
        orderingChannel: 'Kiosk',
        recommendationMode: this.sessionParameters[RecoParameterKey.RecommendationMode],
        recommendationEngine: recommendationEngineUrl,
        optimize: this.sessionOptimizeUsedParameters,
        nestId: AtpApplicationSettings.getInstance().environmentDetailsJson.NestIdentifier,
      },
    };
  }
}
