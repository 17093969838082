import { Injectable } from '@angular/core';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { DotButton } from 'dotsdk';
import { ProductInfoComponent } from '@dotxix/components/product-info/product-info.component';
import { CALORIES_KEY } from './helpers/callories.helper';
import { ApplicationSettingsService } from './services/app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ButtonInfoService {
  constructor(
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private applicationSettingsService: ApplicationSettingsService
  ) {}

  public openProductInfo(button: DotButton): void {
    this.dynamicContentService.openContent(ProductInfoComponent, {
      button: button,
    });
  }

  public hasProductInfo(button: DotButton): boolean {
    return (
      !!this.translationsService.getTranslatedButtonDescription(button) ||
      (button.AllergensAndNutritionalValues?.Ingredients ?? []).length > 0 ||
      (button.AllergensAndNutritionalValues?.Allergens ?? []).length > 0 ||
      (button.AllergensAndNutritionalValues?.NutritionalValues?.length === 1 &&
        button.AllergensAndNutritionalValues?.NutritionalValues[0].Name !== CALORIES_KEY) ||
      (button.AllergensAndNutritionalValues?.NutritionalValues ?? []).length > 0
    );
  }

  public showProductInfo(button: DotButton): boolean {
    return this.applicationSettingsService.settings$.value.showInfoButton && this.hasProductInfo(button);
  }
}
