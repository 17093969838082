import { DotButton, PosElogHandler, PosRefintService, PosServingLocation } from 'dotsdk';
import { basketToOsiris, basketToProductCodeMetadata } from '@dotxix/osiris/helpers/basket-osiris.helper';
import {
  OsirisStoreOrdersPayloadData,
  OsirisStoreOrdersPayloadMetadata,
} from '@dotxix/osiris/models/interfaces/store-orders-payload.interface';
import { getCurrentElogAsJson } from '@dotxix/osiris/helpers/osiris-elog.helper';

export function getPayload(buttons: DotButton[]): OsirisStoreOrdersPayloadData {
  if (!PosElogHandler.getInstance().posConfig.posHeader.orderEndTime) {
    PosElogHandler.getInstance().posConfig.posHeader.orderEndTime = new Date();
  }
  return {
    KioskOrder: basketToOsiris(buttons),
    BIOrder: getCurrentElogAsJson(PosElogHandler.getInstance().posConfig),
  };
}

export function getMetadata(
  orderId: string,
  parentOrderId: string | null,
  customerId: string | null,
  buttons: DotButton[]
): OsirisStoreOrdersPayloadMetadata {
  const posOrderNumber = PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber || PosRefintService.getInstance()._refInt;

  return {
    storeId: PosElogHandler.getInstance().posConfig.posHeader.storeCode ?? '',
    orderId,
    parentOrderId,
    customerId,
    service: PosElogHandler.getInstance().posConfig.posHeader.servingLocation === PosServingLocation.IN ? 0 : 1,
    POSOrderNumber: posOrderNumber.toString(),
    refint: PosRefintService.getInstance()._refInt,
    totalAmount: PosElogHandler.getInstance().posConfig.posHeader.amounts?.amountsTotalPaid ?? 0,
    orderTimestamp: getOrderTime(),
    productCodes: basketToProductCodeMetadata(buttons),
    productCount: buttons.length,
  };
}

function getOrderTime(): number {
  const orderEndTime = PosElogHandler.getInstance().posConfig.posHeader.orderEndTime;
  return orderEndTime ? orderEndTime.getTime() : new Date().getTime();
}
