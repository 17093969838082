import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OsirisLoginFaceRecallService } from '@dotxix/osiris/services/osiris-login-face-recall.service';

@Component({
  selector: 'acr-account-created',
  template: `
    <div class="container">
      <div class="messages mb--50">
        {{ '20220426006' | translate }}
      </div>
      <div class="actions position-relative" (click)="seeTheMenu()">
        <button class="ui-button ui-button--secondary ui-button--x-large">{{ '20220426007' | translate }}</button>
        <div class="bullet">{{ counter$ | async }}</div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        height: 100%;
        width: 100%;
      }

      .bullet {
        position: absolute;
        height: 133rem;
        width: 133rem;
        left: 22rem;
        background-color: var(--primary);
        color: var(--secondary);
        border-radius: 50%;
        text-align: center;
        font-size: 92rem;
        line-height: calc(80 / 60);
        transition: all 250ms ease-in-out;
        margin-bottom: 0 !important;
      }

      .ui-button {
        margin-bottom: 0 !important;
      }
    `,
  ],
  styleUrls: ['../face-recall-common.scss'],
})
export class AccountCreatedComponent implements OnInit {
  public counter$ = new BehaviorSubject<number>(3);
  private interval!: NodeJS.Timer;
  constructor(private osirisLoginFaceRecallService: OsirisLoginFaceRecallService) {}

  public seeTheMenu() {
    clearInterval(this.interval);
    this.osirisLoginFaceRecallService.loginFinished();
  }

  public ngOnInit(): void {
    this.countDown();
  }

  private countDown() {
    this.interval = setInterval(() => {
      this.counter$.next(this.counter$.value - 1);
      if (this.counter$.value < 1) {
        clearInterval(this.interval);
        this.osirisLoginFaceRecallService.loginFinished();
      }
    }, 1000);
  }
}
