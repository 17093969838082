<language-change-view-trigger></language-change-view-trigger>
<div class="flex-full flex--column gap--30 basket-product-list">
  <acr-product-card
    *ngFor="let displayItem of basketDisplayItems$ | async; trackBy: trackByProduct"
    [product]="displayItem.button"
    [children]="displayItem.children ?? []"
    [allowModifyProduct]="allowModifyProduct"
    (changeProductQuantity)="onChangeProductQuantity(displayItem.button, $event)"
    (removeProduct)="onRemoveProduct(displayItem.button)"
    (customizeProduct)="onCustomizeProduct($event)"
    class="basket-product-list__product-card"
  ></acr-product-card>
</div>
