import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';

import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BasketService } from '@dotxix/services/basket.service';
import { PromosService } from '@dotxix/services/promos.service';
import { StatusService } from '@dotxix/services/status.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { PROMOS_VIEW } from '@dotxix/models/enums/promos';
import { Subscription } from 'rxjs';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { PromoFlowService } from '@dotxix/services/promo-flow.service';

@Component({
  selector: 'acr-promos',
  templateUrl: './promos.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PromosComponent extends AbstractDynamicAnimatedPopup<void, void> implements OnDestroy {
  public PROMOS_VIEW = PROMOS_VIEW;

  public promoState$ = this.promoFlowService.state$;

  public barcode = '';
  public subscriptions: Subscription[] = [];

  constructor(
    private statusService: StatusService,
    private translationsService: TranslationsService,
    private basketService: BasketService,
    private appSettingsService: ApplicationSettingsService,
    private promosService: PromosService,
    private promoFlowService: PromoFlowService
  ) {
    super();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public methodSelectionCanceled() {
    this.promoFlowService.methodSelectionCanceled();
  }

  public onKeyboardSelected() {
    this.promoFlowService.onKeyboardSelected();
  }

  public onScanSelected() {
    this.promoFlowService.onScanSelected();
  }

  public scanBarcodeCanceled() {
    this.promoFlowService.scanBarcodeCanceled();
  }

  public keyboardCanceled() {
    this.promoFlowService.keyboardCanceled();
  }

  public onKeyboardBarcodeSelected(barcode: string) {
    this.promoFlowService.keyboardBarcodeSelected(barcode);
  }

  public cancelClick(): void {
    this.close();
  }
}
