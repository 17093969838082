import {
  ApgAllowedPayments,
  CashDenominationStatus,
  CashInventoryOverallStatus,
  PeripheralCheckEntry,
  PeripheralCheckMode,
  PeripheralCheckProducerMessage,
} from 'dotsdk';
import { PAYMENT_TYPE } from '@dotxix/models/enums/payment-type';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { statusColor } from '@dotxix/helpers/status-color.helper';
import { AppSettings } from '@dotxix/models/interfaces/app-settings';
import { StatusState } from '@dotxix/services/status.service';
import { CheckoutType } from '@dotxix/models/enums/checkout-type';

/**
 * Evaluate Cash entries based on cash inventory status. If cash inventory is not OK then consider
 * that specific entries as being in an error state
 */
export const changeCashPaymentIsInErrorBasedOnInventoryStatus = (
  peripheralCheckResult: PeripheralCheckProducerMessage,
  paymentCheckMode: PeripheralCheckMode,
  ignoreGloryDispensableEmptyStatus: boolean
): PeripheralCheckProducerMessage => {
  return {
    ...peripheralCheckResult,
    entries: peripheralCheckResult.entries.map((entry) => {
      if (entry.peripheralType === 'Payment' && entry.peripheralSubtype === PAYMENT_TYPE.CASH && !entry.error) {
        if (!entry.metadata?.inventoryStatus) {
          return {
            ...entry,
            error: true,
            errorCode: -1,
            message: 'Cannot get information about cash inventory status.',
            ...(paymentCheckMode === PeripheralCheckMode.Mandatory && entry.metadata
              ? {
                  metadata: {
                    ...entry.metadata,
                    block: true,
                  },
                }
              : {}),
          };
        }

        const isDispensableInventoryEmpty = entry.metadata.inventoryStatus.dispensable.length
          ? entry.metadata.inventoryStatus.dispensable.some((i) => i.Status === CashDenominationStatus.Empty)
          : false;
        const isDispensableInventoryNearEmpty = entry.metadata.inventoryStatus.dispensable.length
          ? entry.metadata.inventoryStatus.dispensable.some((i) => i.Status === CashDenominationStatus.NearEmpty)
          : false;
        const isInternalInventoryNearFull = entry.metadata.inventoryStatus.internal.length
          ? entry.metadata.inventoryStatus.internal.some((i) => i.Status === CashDenominationStatus.NearFull)
          : false;
        const isInternalInventoryFull = entry.metadata.inventoryStatus.internal.length
          ? entry.metadata.inventoryStatus.internal.some((i) => i.Status === CashDenominationStatus.Full)
          : false;

        let overallStatus;
        if ((isDispensableInventoryEmpty && !ignoreGloryDispensableEmptyStatus) || isInternalInventoryFull) {
          overallStatus = CashInventoryOverallStatus.NOT_OK;
        } else if (isDispensableInventoryNearEmpty || isInternalInventoryNearFull) {
          overallStatus = CashInventoryOverallStatus.WARNING;
        } else {
          overallStatus = CashInventoryOverallStatus.OK;
        }

        const metadataWithUpdatedOverallStatus = {
          ...entry.metadata,
          inventoryStatus: {
            ...entry.metadata.inventoryStatus,
            overallStatus,
          },
        };

        return {
          ...entry,
          metadata: metadataWithUpdatedOverallStatus,
          ...(overallStatus === CashInventoryOverallStatus.NOT_OK
            ? {
                error: true,
                errorCode: -1,
                message: 'Glory internal inventory is full or dispensable inventory is empty.',
                ...(paymentCheckMode === PeripheralCheckMode.Mandatory
                  ? {
                      metadata: {
                        ...metadataWithUpdatedOverallStatus,
                        block: true,
                      },
                    }
                  : {}),
              }
            : {}),
        };
      }
      return entry;
    }),
  };
};

export const computeAppState = (
  state: StatusState,
  appSettings: AppSettings,
  sdkPeripheralCheckResult: PeripheralCheckProducerMessage,
  outsideWorkingHours: boolean,
  kioskBlockedDueToServiceType: boolean,
  preorderPaymentTypeEnabledInWorkingHours: boolean,
  dataLoading: boolean,
  channelIsUpdating: boolean
): StatusState => {
  const cardPaymentsTypeEnabledInBS = findCardEnablePaymentType(PAYMENT_TYPE.CARD, appSettings.paymentTypes);
  const cashPaymentTypeEnabledInBS = findEnablePaymentType(PAYMENT_TYPE.CASH, appSettings.paymentTypes);
  const preorderPaymentTypeEnabledInBS = findEnablePaymentType(PAYMENT_TYPE.PREORDER, appSettings.paymentTypes);
  const payTowerPaymentTypeEnabledInBS = findEnablePaymentType(PAYMENT_TYPE.PAY_TOWER, appSettings.paymentTypes);
  const epayPaymentTypeEnabledInBS = findEnablePaymentType(PAYMENT_TYPE.EPAY, appSettings.paymentTypes);
  const facePaymentTypeEnabledInBS = findEnablePaymentType(PAYMENT_TYPE.FACE, appSettings.paymentTypes);
  const apgPaymentTypeEnabledInBS = findEnablePaymentType(PAYMENT_TYPE.APG, appSettings.paymentTypes);

  const cashPaymentTypeCheckResult = findCheckEntryForPaymentSubType(PAYMENT_TYPE.CASH, sdkPeripheralCheckResult.entries);
  const cardPaymentTypeCheckResult = findCheckEntryForPaymentSubType(PAYMENT_TYPE.CARD, sdkPeripheralCheckResult.entries);
  const cardPaymentsTypeCheckResult = findCheckCardEntryForPaymentSubType(PAYMENT_TYPE.CARD, sdkPeripheralCheckResult.entries);
  const epayPaymentTypeCheckResult = findCheckEntryForPaymentSubType(PAYMENT_TYPE.EPAY, sdkPeripheralCheckResult.entries);
  const posCheckResult = findPosCheckResultEntry(sdkPeripheralCheckResult.entries);
  const scannerCheckResult = findScannerCheckResultEntry(sdkPeripheralCheckResult.entries);
  const cardPaymentTypeAvailableForPayment = cardPaymentsTypeCheckResult.filter((checkEntry) => !checkEntry.error);

  const cashPaymentTypeAvailableForPayment =
    !!cashPaymentTypeEnabledInBS && !!cashPaymentTypeCheckResult && !cashPaymentTypeCheckResult?.error;
  const epayPaymentTypeAvailableForPayment =
    !!epayPaymentTypeEnabledInBS && !!epayPaymentTypeCheckResult && !epayPaymentTypeCheckResult?.error;

  const apgPaymentTypeAvailableForPayment =
    (apgPaymentTypeEnabledInBS?.AllowedPaymentTypes || [])
      .map((apgAllowedPayment) => {
        switch (apgAllowedPayment) {
          case ApgAllowedPayments.card:
            return { paymentType: apgAllowedPayment, isAvailable: cardPaymentTypeAvailableForPayment.length > 0 };
          case ApgAllowedPayments.cash:
            return { paymentType: apgAllowedPayment, isAvailable: cashPaymentTypeAvailableForPayment };
          case ApgAllowedPayments.epay:
            return { paymentType: apgAllowedPayment, isAvailable: epayPaymentTypeAvailableForPayment };
        }
      })
      .filter((apgAllowedPaymentMapped) => apgAllowedPaymentMapped.isAvailable).length > 0;

  const availablePayments = computeAvailablePayments({
    appSettings,
    preorderPaymentTypeEnabledInWorkingHours,
    preorderPaymentTypeEnabledInBS,
    facePaymentTypeEnabledInBS,
    cardPaymentTypeAvailableForPayment,
    cardPaymentsTypeEnabledInBS,
    epayPaymentTypeAvailableForPayment,
    epayPaymentTypeEnabledInBS,
    cashPaymentTypeAvailableForPayment,
    cashPaymentTypeEnabledInBS,
    payTowerPaymentTypeEnabledInBS,
    apgPaymentTypeEnabledInBS,
    apgPaymentTypeAvailableForPayment,
  });

  const blockKioskDueToPeripherals = isKioskBlockedDueToPeripherals(
    sdkPeripheralCheckResult,
    appSettings.paymentCheckMode,
    availablePayments
  );

  const blockKiosk = blockKioskDueToPeripherals || kioskBlockedDueToServiceType || outsideWorkingHours || dataLoading || channelIsUpdating;

  const isScannerAvailableForApp = scannerCheckResult ? scannerCheckResult && !scannerCheckResult.error : false;
  const isCardPaymentsTypeAvailable = cardPaymentsTypeEnabledInBS.every((paymentType) =>
    cardPaymentTypeAvailableForPayment.find((cardPAymentType) => paymentType.PaymentName === cardPAymentType.peripheralName)
  );
  const isCashInventoryWarn = cashPaymentTypeCheckResult?.metadata?.inventoryStatus?.overallStatus === CashInventoryOverallStatus.WARNING;
  const kioskStatusColor = statusColor({
    isCashEnabledInBS: !!cashPaymentTypeEnabledInBS,
    isCashAvailable: cashPaymentTypeAvailableForPayment,
    isCashInventoryWarn,

    isCardEnabledInBS: cardPaymentsTypeEnabledInBS.length > 0,
    isCardAvailable: isCardPaymentsTypeAvailable,

    isElectronicPayEnabledInBS: !!epayPaymentTypeEnabledInBS,
    isElectronicPayAvailable: epayPaymentTypeAvailableForPayment,
  });

  const peripheralErrors = extractPeripheralErrors(
    sdkPeripheralCheckResult,
    cardPaymentsTypeEnabledInBS,
    !!cashPaymentTypeEnabledInBS,
    !!epayPaymentTypeEnabledInBS
  );
  const paymentMandatory = appSettings.paymentCheckMode === PeripheralCheckMode.Mandatory;
  const mandatoryCardEnabledInBSButMissingInATP = paymentMandatory && !!cardPaymentsTypeEnabledInBS && !cardPaymentTypeCheckResult;
  const mandatoryCashEnabledInBSButMissingInATP = paymentMandatory && !!cashPaymentTypeEnabledInBS && !cashPaymentTypeCheckResult;
  const mandatoryEpayEnabledInBSButMissingInATP = paymentMandatory && !!epayPaymentTypeEnabledInBS && !epayPaymentTypeCheckResult;
  const mandatoryPosUnreachable = posCheckResult && posCheckResult.metadata ? posCheckResult.metadata.block : false;

  return {
    ...state,
    deviceStatusChecked: true,
    availablePayments,
    blockKiosk,
    isScannerAvailableForApp,
    statusColor: kioskStatusColor,
    statusMessages: peripheralErrors,
    mandatoryCardEnabledInBSButMissingInATP,
    mandatoryCashEnabledInBSButMissingInATP,
    mandatoryEpayEnabledInBSButMissingInATP,
    mandatoryPosUnreachable,
    outsideWorkingHours,
    kioskBlockedDueToServiceType,
    dataLoading,
    channelIsUpdating,
  };
};

export const computeAvailablePayments = ({
  appSettings,
  preorderPaymentTypeEnabledInWorkingHours,
  preorderPaymentTypeEnabledInBS,
  facePaymentTypeEnabledInBS,
  cardPaymentTypeAvailableForPayment,
  cardPaymentsTypeEnabledInBS,
  epayPaymentTypeAvailableForPayment,
  epayPaymentTypeEnabledInBS,
  cashPaymentTypeAvailableForPayment,
  cashPaymentTypeEnabledInBS,
  payTowerPaymentTypeEnabledInBS,
  apgPaymentTypeEnabledInBS,
  apgPaymentTypeAvailableForPayment,
}: {
  appSettings: AppSettings;
  preorderPaymentTypeEnabledInWorkingHours: boolean;
  preorderPaymentTypeEnabledInBS: PaymentType | undefined;
  facePaymentTypeEnabledInBS: PaymentType | undefined;
  cardPaymentTypeAvailableForPayment: PeripheralCheckEntry[] | undefined;
  epayPaymentTypeAvailableForPayment: boolean;
  cashPaymentTypeAvailableForPayment: boolean;
  cardPaymentsTypeEnabledInBS: PaymentType[] | undefined;
  epayPaymentTypeEnabledInBS: PaymentType | undefined;
  cashPaymentTypeEnabledInBS: PaymentType | undefined;
  payTowerPaymentTypeEnabledInBS: PaymentType | undefined;
  apgPaymentTypeEnabledInBS: PaymentType | undefined;
  apgPaymentTypeAvailableForPayment: boolean;
}): PaymentType[] => {
  const availablePayments = [];
  if (
    appSettings.posInjectionFlow !== CheckoutType.PAY_BEFORE_POS &&
    preorderPaymentTypeEnabledInWorkingHours &&
    preorderPaymentTypeEnabledInBS
  ) {
    availablePayments.push(preorderPaymentTypeEnabledInBS);
  }
  if (facePaymentTypeEnabledInBS) {
    availablePayments.push(facePaymentTypeEnabledInBS);
  }
  if (cardPaymentTypeAvailableForPayment) {
    cardPaymentsTypeEnabledInBS?.forEach((cardPeymentMethod) => {
      if (
        typeof cardPeymentMethod === 'object' &&
        cardPaymentTypeAvailableForPayment.find((paymentType) => paymentType.peripheralName === cardPeymentMethod.PaymentName)
      ) {
        availablePayments.push(cardPeymentMethod);
      }
    });
  }
  if (epayPaymentTypeAvailableForPayment && typeof epayPaymentTypeEnabledInBS === 'object') {
    availablePayments.push(epayPaymentTypeEnabledInBS);
  }
  if (cashPaymentTypeAvailableForPayment && typeof cashPaymentTypeEnabledInBS === 'object') {
    availablePayments.push(cashPaymentTypeEnabledInBS);
  }
  if (payTowerPaymentTypeEnabledInBS) {
    availablePayments.push(payTowerPaymentTypeEnabledInBS);
  }
  if (apgPaymentTypeAvailableForPayment && typeof apgPaymentTypeEnabledInBS === 'object') {
    availablePayments.push(apgPaymentTypeEnabledInBS);
  }
  return availablePayments;
};

export const isKioskBlockedDueToPeripherals = (
  sdkPeripheralCheckResult: PeripheralCheckProducerMessage,
  paymentCheckMode: PeripheralCheckMode,
  availablePaymentTypes: PaymentType[]
): boolean => {
  // check if at least one non-payment peripheral is in blocking state
  if (
    sdkPeripheralCheckResult.entries
      .filter((entry) => entry.peripheralType !== 'Payment')
      .some((nonPaymentEntry) => nonPaymentEntry.metadata?.block)
  ) {
    return true;
  }

  // check if at least one payment is enabled and not blocking
  return paymentCheckMode === PeripheralCheckMode.Mandatory && availablePaymentTypes.length === 0;
};

const extractPeripheralErrors = (
  sdkPeripheralCheckResult: PeripheralCheckProducerMessage,
  cardPaymentsTypeEnabledInBS: PaymentType[],
  isCashEnabledInBS: boolean,
  isEPayEnabledInBS: boolean
) =>
  sdkPeripheralCheckResult.entries
    .filter((checkEntry) => checkEntry.metadata?.block)
    // take into account payment errors only if the payment type is enabled from BS
    .filter((checkEntry) => {
      const isPaymentType = checkEntry.peripheralType === 'Payment';
      if (isPaymentType) {
        return (
          (checkEntry.peripheralSubtype === PAYMENT_TYPE.CARD &&
            cardPaymentsTypeEnabledInBS.find(
              (cardPaymentTypeEnabled) => cardPaymentTypeEnabled.PaymentName === checkEntry.peripheralName
            )) ||
          (checkEntry.peripheralSubtype === PAYMENT_TYPE.CASH && isCashEnabledInBS) ||
          (checkEntry.peripheralSubtype === PAYMENT_TYPE.EPAY && isEPayEnabledInBS)
        );
      } else {
        return true;
      }
    })
    .map((entry) => `${entry.peripheralType} (${entry.peripheralName}) - ${entry.message}`);

export const findEnablePaymentType = (paymentType: PAYMENT_TYPE, paymentTypesHaystack: PaymentType[]) =>
  paymentTypesHaystack.find(
    (paymentTypeInHaystack) => paymentTypeInHaystack.PaymentType === paymentType && paymentTypeInHaystack.PaymentIsEnabled
  );

export const findCardEnablePaymentType = (paymentType: PAYMENT_TYPE, paymentTypesHaystack: PaymentType[]) => {
  const cardPaymentMethods: PaymentType[] = [];
  paymentTypesHaystack.forEach((paymentTypeInHaystack) => {
    if (paymentTypeInHaystack.PaymentType === paymentType && paymentTypeInHaystack.PaymentIsEnabled) {
      cardPaymentMethods.push(paymentTypeInHaystack);
    }
  });
  return cardPaymentMethods;
};

export const findCheckEntryForPaymentSubType = (paymentType: PAYMENT_TYPE, checkEntries: PeripheralCheckEntry[]) =>
  checkEntries.find((checkEntry) => checkEntry.peripheralType === 'Payment' && checkEntry.peripheralSubtype === paymentType);

export const findCheckCardEntryForPaymentSubType = (paymentType: PAYMENT_TYPE, checkEntries: PeripheralCheckEntry[]) => {
  return checkEntries.filter((checkEntry) => checkEntry.peripheralType === 'Payment' && checkEntry.peripheralSubtype === paymentType);
};

export const findPosCheckResultEntry = (checkEntries: PeripheralCheckEntry[]) =>
  checkEntries.find((checkEntry) => checkEntry.peripheralType === 'POS');

export const findScannerCheckResultEntry = (checkEntries: PeripheralCheckEntry[]) =>
  checkEntries.find((checkEntry) => checkEntry.peripheralType === 'Scanner' && checkEntry.peripheralSubtype === 'scanner');
