import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';

@Pipe({
  name: 'translateCatalogTitle',
})
export class TranslateCatalogTitle implements PipeTransform {
  constructor(private productTranslationsService: ProductTranslationsService) {}

  public transform(button: DotButton): string | null {
    return this.productTranslationsService.translateCatalogTitle(button);
  }
}
