export enum RoundingStrategies {
  // default
  ROUND_TO_NEAREST_OR_AWAY_FROM_ZERO = 0,

  // Trunc
  ROUND_TOWARD_ZERO = 1,

  ROUND_AWAY_FROM_ZERO = 2,

  // Ceil
  ROUND_TOWARD_POSITIVE = 3,

  // Floor
  ROUND_TOWARD_NEGATIVE = 4,

  ROUND_TO_NEAREST_OR_TOWARDS_ZERO = 5,

  ROUND_TO_NEAREST_OR_TOWARDS_POSITIVE = 6,

  ROUND_TO_NEAREST_OR_TOWARDS_NEGATIVE = 7,

  // Bankers
  ROUND_TO_NEAREST_OR_TO_EVEN_WHOLE_NUMBER = 8,
}
