import { Pipe, PipeTransform } from '@angular/core';
import { DotModifier } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'translateModifierImage',
})
export class ModifierImageTranslatePipe implements PipeTransform {
  constructor(
    protected translationsService: TranslationsService,
    protected appSettings: ApplicationSettingsService
  ) {}

  public transform(modifier: DotModifier): string | null {
    if (this.getPath(modifier)) {
      return `${this.appSettings.settings$.value.bridgeAssetsPath}/items/${this.getPath(modifier)}`;
    }
    return null;
  }

  public getPath(modifier: DotModifier): string | null {
    if (modifier?.PageInfo && modifier.PageInfo.PictureDictionary && Object.keys(modifier.PageInfo.PictureDictionary).length !== 0) {
      const t = modifier.PageInfo.PictureDictionary[this.translationsService.currentLanguageUpperCase];
      return t ? t : modifier.PageInfo.PictureDictionary.DEF;
    } else if (modifier?.PageInfo && modifier.PageInfo.Picture) {
      return modifier.PageInfo.Picture;
    } else {
      return null;
    }
  }
}
