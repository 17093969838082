import { DotButton, DotPage } from 'dotsdk';
import * as _ from 'lodash';
import { RecoButtonState } from '@dotxix/services/suggestions/models/reco-button-state.interface';
import { ButtonRecommendationMode } from '@dotxix/services/suggestions/models/button-recommendation-mode';

export const addRecommendationModeForPages = (suggestionPages: DotPage[], recommendationMode: ButtonRecommendationMode) => {
  return suggestionPages.map((page) => {
    page = addRecommendationModeForPage(page, recommendationMode);
    return page;
  });
};

export const addRecommendationModeForPage = (page: DotPage, recommendationMode: ButtonRecommendationMode) => {
  page = _.cloneDeep(page);
  page.Buttons.forEach((btn: DotButton<RecoButtonState>) => {
    btn.state.recommendationMode = recommendationMode;
  });
  return page;
};

export const addRecommendationMode = (buttons: DotButton[], recommendationMode: ButtonRecommendationMode) => {
  const clonedButtons = _.cloneDeep(buttons);
  clonedButtons.forEach((clonedButton: DotButton<RecoButtonState>) => {
    clonedButton.state.recommendationMode = recommendationMode;
  });
  return clonedButtons;
};
