import { Injectable } from '@angular/core';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { OsirisRoutes } from '@dotxix/osiris/osiris-routes';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { OsirisOrderStateService } from '@dotxix/osiris/services/osiris-orders-state.service';
import { OsirisService } from '@dotxix/osiris/services/osiris.service';
import { OsirisLoginMethodType } from '@dotxix/osiris/models/interfaces/osiris-login-method.interface';
import { OsirisLoginFaceRecallService } from '@dotxix/osiris/services/osiris-login-face-recall.service';
import { OsirisLoginHesetService } from '@dotxix/osiris/services/osiris-login-heset.service';
import { OsirisLoginKeyboardService } from '@dotxix/osiris/services/osiris-login-keyboard.service';
import { OsirisLoginScanService } from '@dotxix/osiris/services/osiris-login-scan.service';

@Injectable({
  providedIn: 'root',
})
export class OsirisLoginService {
  public newUserAuthenticated$ = new Subject<void>();
  public existingUserAuthenticated$ = new Subject<void>();
  public continueWithoutUser$ = new Subject<void>();
  public loginFinished$ = new Subject<void>();

  constructor(
    private osirisService: OsirisService,
    private osirisOrderStateService: OsirisOrderStateService,
    private osirisLoginKeyboardService: OsirisLoginKeyboardService,
    private osirisLoginScanService: OsirisLoginScanService,
    private osirisLoginFaceRecallService: OsirisLoginFaceRecallService,
    private osirisLoginHesetService: OsirisLoginHesetService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private router: Router
  ) {
    this.osirisLoginKeyboardService.customerAuthenticated$.subscribe(({ customerId, orders }) =>
      this.customerAuthenticated(customerId, orders)
    );
    this.osirisLoginKeyboardService.newCustomer$.subscribe((customerId) => this.newCustomer(customerId));
    this.osirisLoginScanService.customerAuthenticated$.subscribe(({ customerId, orders }) =>
      this.customerAuthenticated(customerId, orders)
    );
    this.osirisLoginScanService.newCustomer$.subscribe((customerId) => this.newCustomer(customerId));

    this.osirisLoginFaceRecallService.cancelFaceRecallLogin$.subscribe(() => this.goToLogin());
    this.osirisLoginFaceRecallService.loginFinished$.subscribe(() => this.loginFinished$.next());
    this.osirisLoginFaceRecallService.continueWithoutUser$.subscribe(() => this.continueWithoutUser());
    this.osirisLoginHesetService.loginFinished$.subscribe(() => this.existingUserAuthenticated$.next());
  }

  public goToLogin() {
    return this.router.navigate([OsirisRoutes.Login]);
  }

  public selectedLoginMethod(eventType: OsirisLoginMethodType | 'continue-without') {
    switch (eventType) {
      case 'manual':
        this.osirisLoginKeyboardService.loginWithKeyboard();
        break;
      case 'facerecall':
        this.osirisLoginFaceRecallService.loginWithFaceRecall();
        break;
      case 'scan':
        this.osirisLoginScanService.loginWithScan();
        break;
      case 'continue-without':
        this.continueWithoutUser();
        break;
      case 'heset':
        this.osirisLoginHesetService.loginWithHeset();
        break;
      default:
        break;
    }
  }

  public continueWithoutUser() {
    this.continueWithoutUser$.next();
  }

  public customerAuthenticated(customerID: string, orders: OsirisOrder[]) {
    this.osirisOrderStateService.setOrders(orders);
    this.osirisOrderStateService.setCustomerIdentification({ customerID, customerIdentificationMethod: 'manual-input' });
    this.existingUserAuthenticated$.next();
  }

  public newCustomer(customerID: string) {
    this.osirisOrderStateService.setCustomerIdentification({ customerID, customerIdentificationMethod: 'manual-input' });
    this.newUserAuthenticated$.next();
    this.showNewUserMessage(customerID);
  }

  private showNewUserMessage(customerID: string) {
    this.dynamicContentService.openContent(InfoDialogComponent, {
      title: this.translationsService
        .translate('2022051301')
        .split('<br/>')
        .map((e) => `<p>${e}</p>`)
        .join(`<h1>${customerID}</h1>`),
      buttonText: this.translationsService.translate('20210504003'),
    });
  }
}
