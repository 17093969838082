import { Component } from '@angular/core';
import { CustomThemeFlowService } from '@dotxix/custom-theme/services/custom-theme-flow.service';
import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';

@Component({
  selector: 'acr-reset-button',
  template: ` <button class="reset-button" (click)="reset()">RESET CUSTOM THEME</button> `,
  styles: [
    `
      .reset-button {
        font-size: 20rem;
        color: #f82424;
      }
    `,
  ],
})
export class ResetButtonComponent {
  constructor(
    private themeEditorStateService: ThemeEditorStateService,
    private customThemeFlowService: CustomThemeFlowService
  ) {}

  public reset() {
    this.themeEditorStateService.resetGeneralThemingVariables();
    this.customThemeFlowService.writeToFile();
  }
}
