import { NgModule } from '@angular/core';
import { AppBIService } from './bi.service';
import { BiViewSuggestionsDirective } from './directives/bi-view-suggestions.directive';
import { BiViewPromosDirective } from './directives/bi-view-promos.directive';

@NgModule({
  declarations: [BiViewSuggestionsDirective, BiViewPromosDirective],
  providers: [AppBIService],
  exports: [BiViewSuggestionsDirective, BiViewPromosDirective],
})
export class AppBIModule {}
