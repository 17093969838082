import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';
import { isButtonVisuallyUnavailable } from '@dotxix/helpers/button-status.helper';

@Pipe({
  name: 'isButtonVisuallyUnavailable',
})
export class IsButtonVisuallyUnavailablePipe implements PipeTransform {
  transform(value: DotButton): boolean {
    return isButtonVisuallyUnavailable(value);
  }
}
