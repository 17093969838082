import { Injectable } from '@angular/core';
import { HesetUserIdStateService } from '@dotxix/osiris/services/heset/heset-user-id-state.service';
import { HesetHttpService } from '@dotxix/osiris/services/heset/heset.http.service';

@Injectable({
  providedIn: 'root',
})
export class HesetManagementService {
  constructor(
    private hesetHttpService: HesetHttpService,
    private hesetUserIdStateService: HesetUserIdStateService
  ) {}

  public submitOrder(orderPosNumber: string) {
    const userId = this.hesetUserIdStateService.getUser();
    if (userId) {
      this.hesetHttpService.submitOrder(userId, orderPosNumber).then(() => {});
    }
  }
}
