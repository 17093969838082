import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { SlideShowSteps } from '@dotxix/models/interfaces/slide-show-steps';
import { SlideShowStep } from '@dotxix/models/interfaces/slide-show-step';
import { animationStyleMetadata, enter, leave } from '@dotxix/helpers/banners-animation.helper';
import { AnimationType } from '@dotxix/models/enums/animation-type';
import { AnimationAction } from '@dotxix/models/enums/animation-action';

@Component({
  selector: 'acr-slide-show',
  templateUrl: './slide-show.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enter, leave],
  host: { class: 'slide-show' },
})
export class SlideShowComponent implements OnDestroy {
  public displaySteps: SlideShowSteps = [];
  public visibleIndex = -1;
  private timeout: NodeJS.Timeout | undefined;
  private firstTimeFlag = true;
  public animationType: AnimationType = AnimationType.cssOnly;

  @Input() set steps(steps: SlideShowSteps) {
    this.displaySteps = steps;
    this.showSlide(0);
  }
  @Input() basePath = '';
  @Input() set animationTypeSelection(animationType: AnimationType) {
    if (Object.values(AnimationType).includes(animationType)) {
      this.animationType = animationType;
    }
  }

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnDestroy() {
    clearTimeout(this.timeout);
    this.firstTimeFlag = true;
  }

  public trackByPath(index: number, step: SlideShowStep) {
    return step.path;
  }

  private showSlide(index: number) {
    clearTimeout(this.timeout);
    if (this.displaySteps.length === 0) {
      return;
    }
    const step = this.displaySteps[index];
    if (step) {
      this.visibleIndex = index;
      this.changeDetector.detectChanges();
      this.timeout = setTimeout(() => {
        this.showSlide(this.visibleIndex + 1);
      }, step.duration);
    } else {
      this.showSlide(0);
    }
  }

  public resolveAnimationTypeEnter(stepIndex: number) {
    if (!stepIndex && this.firstTimeFlag) {
      this.firstTimeFlag = false;
      return;
    }
    return animationStyleMetadata(this.animationType, AnimationAction.enter);
  }
  public resolveAnimationTypeLeave() {
    return animationStyleMetadata(this.animationType, AnimationAction.leave);
  }

  public isCssOnly() {
    return this.animationType === AnimationType.cssOnly;
  }
}
