<language-change-view-trigger></language-change-view-trigger>
<main class="page-container" *ngIf="faceRecall$ | async as faceRecall">
  <header class="page-header">
    <img
      skin-part="image"
      [src]="faceRecall.screenStep === screenSteps.Error ? '/Channels/skin/icon-faceRecallSad.svg' : '/Channels/skin/icon-faceRecall.svg'"
      class="size--260"
      alt=""
    />
  </header>
  <section class="page-content" [ngSwitch]="faceRecall.screenStep">
    <acr-camera-countdown *ngSwitchDefault></acr-camera-countdown>
    <acr-scanning-face *ngSwitchCase="screenSteps.ScanningFace"></acr-scanning-face>
    <acr-customer-login *ngSwitchCase="screenSteps.CustomerLogin"></acr-customer-login>
    <acr-account-created *ngSwitchCase="screenSteps.AccountCreated"></acr-account-created>
    <acr-account-retrieved *ngSwitchCase="screenSteps.AccountRetrieved"></acr-account-retrieved>
    <acr-face-recall-error *ngSwitchCase="screenSteps.Error"></acr-face-recall-error>
    <acr-face-recall-customer-already-exists *ngSwitchCase="screenSteps.CustomerAlreadyExists"></acr-face-recall-customer-already-exists>
    <acr-qrcode-register-identity *ngSwitchCase="screenSteps.QrCodeRegisterIdentity"></acr-qrcode-register-identity>
    <acr-qrcode-pin-attempts *ngSwitchCase="screenSteps.MaxPinRetries"></acr-qrcode-pin-attempts>
    <acr-enter-pin *ngSwitchCase="screenSteps.EnterPin"></acr-enter-pin>
  </section>

  <section class="facerecall-steps">
    <acr-bullet-steps
      *ngIf="hasBulletSteps(faceRecall.screenStep)"
      class="full"
      [activeStep]="syncBulletsStepsWithFaceRecallSteps(faceRecall.screenStep)"
    ></acr-bullet-steps>
  </section>

  <acr-footer></acr-footer>
</main>
