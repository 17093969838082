import { DotSdkMocksManager, IJsonMocksOverride, mockActionButton, MockPromptEffects, TAB_TYPES } from 'dotsdk';

export class RecoUpdateOrderOverrider implements IJsonMocksOverride {
  private uiPanel: HTMLElement | undefined;
  private dotSdkMockManager = DotSdkMocksManager.getInstance();

  public processInputArgs(mockJson: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const btnSuccess = mockActionButton('Success');
      const btnFail = mockActionButton('Error');
      const btnUnauthorized = mockActionButton('Unauthorized');

      this.uiPanel = this.dotSdkMockManager.addMockPrompt({
        title: `Reco Update Order`,
        interactiveElements: [
          { htmlElement: btnSuccess, clickHandler: () => resolve(mockJson) },
          { htmlElement: btnFail, clickHandler: () => reject('Error') },
          { htmlElement: btnUnauthorized, clickHandler: () => reject({ status: 401 }) },
        ],
        canRememberChoice: true,
        uniqueId: this.dotSdkMockManager.sanitizePersistedMockReplayKey(`reco-update-order`),
        hostTabType: TAB_TYPES.PROMISED_MOCKS,
        afterAppendEffects: [MockPromptEffects.OPEN_DRAWER_TAB_AND_FOCUS_MOCK_PROMPT],
        afterCTAEffects: [MockPromptEffects.REMOVE_PANEL_AND_CLOSE_DRAWER_IF_HOST_TAB_EMPTY],
      });
    });
  }
}
