<main class="page-container table-service-entry">
  <header class="page-header">
    <img src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <div class="table-service-entry__options">
    <h1 class="text-center pt--40 pb--40">{{ '74' | translate }}</h1>
    <div class="full-width justify-center table-service-entry__payment-types">
      <button
        data-automation="table-service-yes"
        class="full-width flex flex--column justify-center table-service-entry__payment-type"
        (click)="goToSelection()"
      >
        <img
          class="ui-button--icon size--250 table-service-entry__payment-type--image"
          alt=""
          src="/Channels/skin/icon-tableServiceFlag-preorder.svg"
        />
        <div class="full-width p--30 flex justify-between align-center">
          <h3 class="text-left table-service-entry__payment-type--name">{{ '75' | translate }}</h3>
          <img src="/Channels/skin/icon-pillChevronRight.svg" class="size--44" alt="" acrRevertOnRtl />
        </div>
      </button>
      <button
        data-automation="table-service-no"
        class="full-width flex flex--column justify-center table-service-entry__payment-type"
        (click)="cancel()"
      >
        <img
          src="/Channels/skin/icon-paymentSelectionCounter.svg"
          class="ui-button--icon size--250 table-service-entry__payment-type--image"
          alt=""
        />
        <div class="full-width p--30 flex justify-between align-center">
          <h3 class="text-left table-service-entry__payment-type--name">{{ '72' | translate }}</h3>
          <img src="/Channels/skin/icon-pillChevronRight.svg" class="size--44" alt="" acrRevertOnRtl />
        </div>
      </button>
    </div>
  </div>
</main>
