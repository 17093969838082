import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppBIService } from '../../../business-intelligence/bi.service';
import { BIPOSProgressViews } from '@acrelec.foundation/business-analytics';
@Component({
  selector: 'acr-checkout-error',
  templateUrl: 'checkout-error.component.html',
})
export class CheckoutErrorComponent implements OnInit {
  public displayPaymentImage = false;

  constructor(
    private router: Router,
    private appBiService: AppBIService
  ) {
    this.displayPaymentImage = this.router.getCurrentNavigation()?.extras.state?.displayPaymentImage;
  }
  ngOnInit(): void {
    this.appBiService.actions.impressions.viewPosProgress(BIPOSProgressViews.POS_ERROR);
  }
}
