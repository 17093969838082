import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DotButton } from 'dotsdk';
import { isItemPackSelected } from '@dotxix/helpers/item-pack.helper';

@Component({
  selector: 'acr-button-item-pack',
  templateUrl: './button-item-pack.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonItemPackComponent {
  @Input() public set itemPack(button: DotButton) {
    this.button = button;
    this.selected = isItemPackSelected(button);
  }
  @Output() public clicked: EventEmitter<void> = new EventEmitter();

  public button!: DotButton;
  public selected!: boolean;

  @HostListener('click')
  public onClicked() {
    this.clicked.emit();
  }
}
