import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ColorPickerState } from '@dotxix/custom-theme/models/color-picker-state.interface';

const initialState: ColorPickerState = {
  history: [],
};

@Injectable({ providedIn: 'root' })
export class ColorPickerStateService {
  public colorPickerState$ = new BehaviorSubject<ColorPickerState>(initialState);
  public appendToHistory(value: string) {
    if (this.colorPickerState$.value.history.some((s) => s === value)) {
      return;
    }
    this.colorPickerState$.next({
      ...this.colorPickerState$.value,
      history: [value, ...this.colorPickerState$.value.history],
    });
  }
}
