import {
  AtpApplicationSettings,
  DotAvailabilityService,
  DotButton,
  DotButtonType,
  DotModifier,
  getCatalogButton,
  getCombosCatalogButton,
  IEnvironmentAbout,
  PosServingLocation,
} from 'dotsdk';
import { BasketButton, BasketComboPage, BasketCombos, BasketModifiersPage } from '@dotxix/models/interfaces/pay-tower-order.interface';

export const atpMarketIdAndNestIdentifier = (): [string, string] => {
  const envDetails: IEnvironmentAbout | undefined = AtpApplicationSettings.getInstance().environmentDetailsJson;
  if (typeof envDetails?.NestIdentifier === 'string') {
    const nestIdentifier = envDetails.NestIdentifier;
    const marketID = nestIdentifier.split('.')[1] ?? '';
    return [marketID, nestIdentifier];
  }
  return ['', ''];
};

export const extractUserSelectionFromBasket = (buttons: DotButton[]): BasketButton[] => {
  return buttons.reduce((acc, button) => {
    acc.push({
      ButtonType: button.ButtonType,
      Link: button.Link,
      quantity: button.quantity,
      isPromo: button.isPromo,
      promoId: button.promoId,
      posDiscountId: button.posDiscountId,
      StartSize: button.StartSize || button.DefaultSize,
      ...(button.ButtonType === DotButtonType.MENU_BUTTON && extractUserSelectionForCombo(button)),
      ...(button.hasModifiers && button.activeModifiers.length > 0 && extractUserSelectionForModifierPage(button.activeModifiers)),
    });
    return acc;
  }, [] as BasketButton[]);
};

const extractUserSelectionForCombo = (button: DotButton): BasketComboPage => {
  return {
    ComboPage: {
      ID: button.ComboPage.ID,
      Combos: button.ComboPage.Combos.reduce((acc, combo) => {
        acc.push({
          PageType: 'Combo',
          ComboStepID: combo.ComboStepID,
          CompID: combo.CompID,
          ComboID: combo.ComboID,
          ComboStepIndex: combo.ComboStepIndex,
          Buttons: extractUserSelectionForComboButtons(combo.Buttons),
        });
        return acc;
      }, [] as BasketCombos[]),
    },
  };
};

const extractUserSelectionForComboButtons = (buttons: DotButton[]): BasketButton[] => {
  return buttons.reduce((acc, button) => {
    if (button.Selected) {
      acc.push({
        Link: button.Link,
        quantity: button.quantity,
        ButtonType: button.ButtonType,
        isPromo: button.isPromo,
        promoId: button.promoId,
        StartSize: button.StartSize,
        posDiscountId: button.posDiscountId,
        ...(button.hasModifiers && button.activeModifiers.length > 0 && extractUserSelectionForModifierPage(button.activeModifiers)),
      });
    }
    return acc;
  }, [] as BasketButton[]);
};

const extractUserSelectionForModifierPage = (modifiers: DotModifier[]): BasketModifiersPage => {
  return {
    ModifiersPage: {
      Modifiers: modifiers.map((modifier) => {
        return {
          PageInfo: {
            ModifierID: modifier.PageInfo.ModifierID,
            ID: modifier.PageInfo.ID,
          },
          Buttons: modifier.Buttons.filter((button) => button.Selected).map((button) => {
            return {
              Link: button.Link,
              quantity: button.quantity,
              ...(button.selectedPrefixId && { selectedPrefixId: button.selectedPrefixId }),
            };
          }),
        };
      }),
    },
  };
};

export const getDotButtonsFromBasketButtons = (buttons: BasketButton[], servingLocation: PosServingLocation): DotButton[] => {
  const buttonsResult: DotButton[] = [];
  for (const button of buttons) {
    let basketButton: DotButton | null =
      button.ButtonType === DotButtonType.MENU_BUTTON ? getCombosCatalogButton(button.Link) : getCatalogButton(button.Link);
    if (!basketButton) {
      return [];
    } else {
      basketButton.quantity = button.quantity;
      basketButton.Selected = true;
      basketButton.isPromo = button.isPromo;
      basketButton.promoId = button.promoId;
      basketButton.posDiscountId = button.posDiscountId;
    }

    basketButton =
      button.ButtonType === DotButtonType.MENU_BUTTON
        ? getPreconfiguredComboButton(basketButton, button, servingLocation)
        : getPreconfiguredButton(basketButton, button, servingLocation);
    if (!basketButton) {
      return [];
    }
    buttonsResult.push(basketButton);
  }
  return buttonsResult;
};

const getPreconfiguredButton = (
  catalogButton: DotButton,
  basketButton: BasketButton,
  servingLocation: PosServingLocation
): DotButton | null => {
  if (!DotAvailabilityService.getInstance().isButtonAvailable(catalogButton, servingLocation)) {
    return null;
  }
  if (
    basketButton.ModifiersPage &&
    basketButton.ModifiersPage.Modifiers &&
    basketButton.ModifiersPage.Modifiers.some((modifier) => modifier.Buttons)
  ) {
    if (!catalogButton.hasModifiers) {
      return null;
    }
    for (const modifier of basketButton.ModifiersPage.Modifiers) {
      const catalogModifier = catalogButton.ModifiersPage.Modifiers.find(
        (modifierButton) => modifierButton.PageInfo.ModifierID === modifier.PageInfo.ModifierID
      );
      if (!catalogModifier) {
        return null;
      }
      for (const btn of modifier.Buttons) {
        const catalogModifierButton = catalogModifier.Buttons.find((b) => b.Link === btn.Link);
        if (!catalogModifierButton || !DotAvailabilityService.getInstance().isButtonAvailable(catalogModifierButton, servingLocation)) {
          return null;
        }
        catalogModifierButton.quantity = btn.quantity;
        catalogModifierButton.Selected = true;
      }
    }
  }
  return catalogButton;
};

const getPreconfiguredComboButton = (
  comboCatalogButton: DotButton,
  basketButton: BasketButton,
  servingLocation: PosServingLocation
): DotButton | null => {
  if (!DotAvailabilityService.getInstance().isButtonAvailable(comboCatalogButton, servingLocation)) {
    return null;
  }
  if (basketButton.ComboPage && basketButton.ComboPage.Combos && basketButton.ComboPage.Combos.every((combo) => combo.Buttons)) {
    if (!comboCatalogButton.hasCombos) {
      return null;
    }
    for (const combo of basketButton.ComboPage.Combos) {
      const catalogCombo = comboCatalogButton.ComboPage.Combos.find((cmb) => cmb.ComboStepID === combo.ComboStepID);
      if (!catalogCombo) {
        return null;
      }
      for (const comboButton of combo.Buttons) {
        const catalogComboButton = catalogCombo.Buttons.find((b) => b.Link === comboButton.Link && b.VisibleOn === basketButton.StartSize);
        if (!catalogComboButton || !DotAvailabilityService.getInstance().isButtonAvailable(catalogComboButton, servingLocation)) {
          return null;
        }
        catalogComboButton.quantity = comboButton.quantity;
        catalogComboButton.Selected = true;
        if (comboButton.ModifiersPage && !getPreconfiguredButton(catalogComboButton, comboButton, servingLocation)) {
          return null;
        }
      }
    }
  }
  return comboCatalogButton;
};
