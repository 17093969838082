import { Component } from '@angular/core';
import { ElectronicReceiptFlowService } from '@dotxix/services/receipt/electronic-receipt-flow.service';
import { map } from 'rxjs';

@Component({
  selector: 'acr-electronic-receipt-page',
  templateUrl: './electronic-receipt-page.component.html',
})
export class ElectronicReceiptPageComponent {
  public $loading = this.electronicReceiptFlowService.$storingReceiptContent;
  public $receiptBase64Source = this.electronicReceiptFlowService.$electronicReceiptResponse.pipe(
    map((electronicReceiptResponse) =>
      electronicReceiptResponse && electronicReceiptResponse.Base64Image
        ? `data:image/png;base64,${electronicReceiptResponse.Base64Image}`
        : ''
    )
  );
  constructor(private electronicReceiptFlowService: ElectronicReceiptFlowService) {}
  public continueClicked() {
    this.electronicReceiptFlowService.userConfirmedElectronicReceiptDelivery();
  }
  public backClicked() {
    this.electronicReceiptFlowService.userRequestedToReturnToReceiptOptions();
  }
}
