import { Component, OnInit } from '@angular/core';
import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-table-service-entry',
  templateUrl: './table-service-entry.component.html',
})
export class TableServiceEntryComponent implements OnInit {
  constructor(
    private checkoutFlowService: CheckoutFlowService,
    private appBiService: AppBIService
  ) {}

  public ngOnInit() {
    this.appBiService.actions.impressions.viewTableServiceIncentive();
  }

  public cancel() {
    this.appBiService.actions.clicks.clickedTableServiceIncentiveDecline();
    this.checkoutFlowService.proceedToPayment().then(() => {});
  }

  public goToSelection() {
    this.appBiService.actions.clicks.clickedTableServiceIncentiveAccept();
    this.checkoutFlowService.displayTableServiceSelection().then(() => {});
  }
}
