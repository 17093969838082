import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { AtpApplicationSettings, DdsClient, PosElogHandler } from 'dotsdk';
import { encryptAuthenticationUrl } from '@dotxix/osiris/helpers/crypt.helper';

@Injectable({
  providedIn: 'root',
})
export class HesetHttpService {
  private ddsClient = new DdsClient();

  constructor(private appSettings: ApplicationSettingsService) {}

  public submitNewUser(userId: string): Promise<null> {
    const HESET_POST_USER_URL = `${this.appSettings.settings$.value.hesetApiUrl}/user/register/${encryptAuthenticationUrl()}`;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<null>(async (res, rej) => {
      try {
        const responseObject = await this.ddsClient
          .post(`${HESET_POST_USER_URL}/${userId}`, null)
          .catch((err) => rej(`[submitNewUser]: rejection -> ${err}`));
        if (responseObject && responseObject.code >= 200 && responseObject.code < 202) {
          res(null);
        } else {
          rej(`[submitNewUser]: rejection -> responseObject: ${responseObject}`);
        }
      } catch (error) {
        rej(`[submitNewUser]: rejection -> DDS error: ${error}`);
      }
    });
  }
  public submitOrder(userId: string, orderPosNumber: string): Promise<null> {
    const HESET_POST_PLACE_ORDER_URL = `${this.appSettings.settings$.value.hesetApiUrl}/order`;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<null>(async (res, rej) => {
      try {
        const responseObject = await this.ddsClient
          .post(`${HESET_POST_PLACE_ORDER_URL}`, {
            Id: PosElogHandler.getInstance().posConfig.posHeader.AUID,
            MarketId: AtpApplicationSettings.getInstance().environmentDetailsJson.NestIdentifier?.split('.')[1],
            StoreId: AtpApplicationSettings.getInstance().environmentDetailsJson?.StoreDetails.Code,
            Kiosk: `${AtpApplicationSettings.getInstance().bundleSettingsJson.kioskId}`,
            Refint: `${PosElogHandler.getInstance().posConfig.posHeader.orderRefInt}`,
            OrderNumber: Number(orderPosNumber),
            UserId: userId,
          })
          .catch((err) => rej(`[submitNewUser]: rejection -> ${err}`));
        if (responseObject && responseObject.code >= 200 && responseObject.code < 202) {
          res(null);
        } else {
          rej(`[submitNewUser]: rejection -> responseObject: ${responseObject}`);
        }
      } catch (error) {
        rej(`[submitNewUser]: rejection -> DDS error: ${error}`);
      }
    });
  }
}
