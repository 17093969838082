export enum FaceRecallScreenSteps {
  CustomerLogin = 'customer-login',
  LookAtCamera = 'look-at-camera',
  ScanningFace = 'scanning-face',
  AccountCreated = 'account-created',
  Error = 'error',
  AccountRetrieved = 'account-retrieved',
  CustomerAlreadyExists = 'customer-already-exists',
  EnterPin = 'enter-pin',
  QrCodeRegisterIdentity = 'qr-code-register-identity',
  MaxPinRetries = 'max-pin-retries',
}
