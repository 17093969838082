import { Channels, CoreProducerTypes, MessageBroker, PeripheralCheckProducerMessage } from 'dotsdk';
import { KioskStatusColor } from '@dotxix/models/enums/kiosk-status-color';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { Injectable, NgZone } from '@angular/core';
import { WorkingHoursService } from '@dotxix/services/working-hours.service';
import { ServiceTypeService } from '@dotxix/services/service-type.service';
import { appLogger } from '@dotxix/log-manager';
import { changeCashPaymentIsInErrorBasedOnInventoryStatus, computeAppState } from '@dotxix/helpers/status.helper';
import { ContentService } from '@dotxix/services/content.service';
import { map } from 'rxjs/operators';

export interface StatusState {
  deviceStatusChecked: boolean;

  blockKiosk: boolean;
  availablePayments: PaymentType[];
  isScannerAvailableForApp: boolean;
  statusColor: KioskStatusColor;
  statusMessages: Array<string>;
  mandatoryCardEnabledInBSButMissingInATP: boolean;
  mandatoryCashEnabledInBSButMissingInATP: boolean;
  mandatoryEpayEnabledInBSButMissingInATP: boolean;
  mandatoryPosUnreachable: boolean;
  outsideWorkingHours: boolean;
  kioskBlockedDueToServiceType: boolean;
  dataLoading: boolean;
  channelIsUpdating: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  public state$: BehaviorSubject<StatusState> = new BehaviorSubject<StatusState>({
    deviceStatusChecked: false,

    availablePayments: [],
    blockKiosk: true,
    isScannerAvailableForApp: false,
    statusColor: KioskStatusColor.NO_COLOR,
    statusMessages: [],
    mandatoryCardEnabledInBSButMissingInATP: true,
    mandatoryCashEnabledInBSButMissingInATP: true,
    mandatoryEpayEnabledInBSButMissingInATP: true,
    mandatoryPosUnreachable: true,
    outsideWorkingHours: true,
    kioskBlockedDueToServiceType: true,
    dataLoading: true,
    channelIsUpdating: true,
  });
  public canStartOrdering$ = this.state$.pipe(map((state) => !state.blockKiosk));

  private _sdkPeripheralCheckResult: Subject<PeripheralCheckProducerMessage> = new Subject();

  constructor(
    private appSettings: ApplicationSettingsService,
    private workingHoursService: WorkingHoursService,
    private ngZone: NgZone,
    private serviceTypeService: ServiceTypeService,
    private contentService: ContentService
  ) {
    combineLatest([
      this.appSettings.settings$,
      this._sdkPeripheralCheckResult,
      this.workingHoursService.outsideWorkingHours$,
      this.workingHoursService.preorderPaymentTypeEnabled$,
      this.serviceTypeService.isKioskBlockedDueToServiceType$,
      this.contentService.dataLoadingState$,
      Channels.getInstance().isUpdating,
    ]).subscribe(
      ([
        appSettings,
        sdkPeripheralCheckResult,
        outsideWorkingHours,
        preorderPaymentTypeEnabledInWorkingHours,
        kioskBlockedDueToServiceType,
        dataLoading,
        channelIsUpdating,
      ]) => {
        this.state$.next(
          computeAppState(
            this.state$.value,
            appSettings,
            sdkPeripheralCheckResult,
            outsideWorkingHours,
            kioskBlockedDueToServiceType,
            preorderPaymentTypeEnabledInWorkingHours,
            dataLoading,
            channelIsUpdating
          )
        );
      }
    );
  }

  public initialize() {
    appLogger.debug('status: Checking device status. Please wait...');
    MessageBroker.getInstance().subscribe(CoreProducerTypes.PERIPHERALS_CHECK, (peripheralCheckResult: PeripheralCheckProducerMessage) => {
      this.ngZone.run(() => {
        this._sdkPeripheralCheckResult.next(
          changeCashPaymentIsInErrorBasedOnInventoryStatus(
            peripheralCheckResult,
            this.appSettings.settings$.value.paymentCheckMode,
            this.appSettings.settings$.value.ignoreGloryDispensableEmptyStatus
          )
        );
      });
    });
  }

  public removePaymentOptionForCurrentSession(removePaymentType: PaymentType) {
    appLogger.debug(`removing payment option for current session: ${JSON.stringify(removePaymentType)}`);
    this.state$.next({
      ...this.state$.value,
      availablePayments: this.state$.value.availablePayments.filter(
        (paymentType) =>
          !(paymentType.PaymentName === removePaymentType.PaymentName && paymentType.PaymentType === removePaymentType.PaymentType)
      ),
    });
  }
}
