<acr-status></acr-status>
<main class="page-container pay-tower-page" data-automation="pay-tower-page">
  <header class="page-header">
    <img src="/Channels/skin/icon-logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <section class="pay-tower-page__content">
    <img src="/Channels/skin/image--pay-tower.png" alt="" />
    <div class="pay-tower-page__text pay-tower-page__text--underlined">
      <ng-container *ngIf="$readyToScan | async">
        <hr class="pay-tower-page__line" />
        <h2 class="page-tower-page__title transform--none" [innerHTML]="'2001043' | translate"></h2>
      </ng-container>
      <ng-container *ngIf="$errorMessage | async as errorMessage">
        <hr class="pay-tower-page__line" />
        <h2 [innerHTML]="errorMessage | translate" class="color--errorRed"></h2>
      </ng-container>
    </div>
  </section>
</main>
