import * as _ from 'lodash';

import { CalculateTotalMode } from '@dotxix/models/enums/calculate-total-mode';
import { PAYMENT_TYPE } from '@dotxix/models/enums/payment-type';
import {
  AtpApplicationSettings,
  AtpPrinterService,
  DotButton,
  DotSessionEndType,
  IPosResponse,
  PosElogHandler,
  PosPaidState,
  PosTenderType,
} from 'dotsdk';

import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BasketService } from '@dotxix/services/basket.service';
import { Injectable } from '@angular/core';
import { PosOperationsService } from '@dotxix/services/pos-operations.service';
import { SessionService } from '@dotxix/services/session.service';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { PrintTicketOption } from '@dotxix/models/enums/print-ticket-option';
import { PrinterSettings } from '@dotxix/models/interfaces/printer-settings';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { TableServiceSelectionService } from '@dotxix/table-service/pages/table-service-selection/table-service-selection.service';
import { areButtonsSimilar } from '@dotxix/helpers/basket.helper';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { appLogger } from '@dotxix/log-manager';
import { Receipt, ReceiptPartial } from '@dotxix/services/receipt/models/receipt';
import { AppReceiptCompilerService } from '@dotxix/services/receipt/app-receipt-compiler.service';
import { encryptTableServiceUrl } from '@dotxix/osiris/helpers/crypt.helper';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  public get orderTotal() {
    return this.subtotalCents + this.taxCents;
  }

  public returnedFromCheckout = false;
  public orderNotPaidWillPayAtCounter = false;
  public hesetLogged = false;
  public gloryLegalRequirementAccepted = false;
  public paymentType: PAYMENT_TYPE | undefined;
  public subtotalCents = 0;
  public taxCents = 0;
  public orderPOSNumber: number | null = null;
  public tenderMediaId: string | undefined;
  public paymentRetries: number | undefined;
  public openedOrder: boolean | undefined;
  public receiptContent: string | undefined;
  public calculatedOrder = false;
  private checkoutBasketButtons: DotButton[] | null = null;

  constructor(
    private appReceiptCompilerService: AppReceiptCompilerService,
    private appSettingsService: ApplicationSettingsService,
    private sessionService: SessionService,
    private basketService: BasketService,
    private posOperationService: PosOperationsService,
    private translationsService: TranslationsService,
    private paymentService: PaymentService,
    private tableSelectionService: TableServiceSelectionService
  ) {
    this.sessionService.onRestartSession.subscribe(() => {
      this.checkoutBasketButtons = null;
      this.resetOrderTotal();
      this.hesetLogged = false;
      this.openedOrder = false;
      this.calculatedOrder = false;
      this.returnedFromCheckout = false;
      this.gloryLegalRequirementAccepted = false;
    });
    this.basketService.buttons$.subscribe(() => {
      if (this.appSettingsService.settings$.value.skipPrecalculate) {
        this.subtotalCents = this.basketService.totalPrice;
      }
    });
  }

  public getAmountOwed() {
    return this.orderTotal - this.paymentService.state$.value.paidAmount;
  }

  public startCheckoutTunnel() {
    this.subtotalCents = this.basketService.totalPrice;
    this.taxCents = 0;
    this.orderPOSNumber = null;
    this.tenderMediaId = '-1';
    this.openedOrder = false;
    this.orderNotPaidWillPayAtCounter = false;
    this.receiptContent = '';
    this.paymentRetries = this.appSettingsService.settings$.value.maxPaymentRetries;
  }

  public returnFromCheckout() {
    this.checkoutBasketButtons = _.cloneDeep(this.basketService.buttons);
    this.resetOrderTotal();
    this.returnedFromCheckout = true;
  }

  public resetOrderTotal() {
    this.subtotalCents = 0;
    this.taxCents = 0;
  }

  public async voidOrder() {
    if (this.openedOrder) {
      this.openedOrder = !(await this.posOperationService.sendVoidOrderToPOS());
    } else if (this.calculatedOrder && this.appSettingsService.settings$.value.calculateTotalMode === CalculateTotalMode.VOID_ON_RETURN) {
      this.calculatedOrder = !(await this.posOperationService.sendVoidOrderToPOS());
    }
  }

  public async voidOrderAndRestartSession(reason: DotSessionEndType) {
    await this.voidOrder();
    return this.sessionService.restartSession(reason);
  }

  public populateElogBeforeEndScene() {
    const subtotalAmount = this.appSettingsService.settings$.value.skipPrecalculate
      ? this.subtotalCents + this.orderDiscount()
      : this.subtotalCents;
    PosElogHandler.getInstance().posConfig.posHeader.amounts = {
      ...PosElogHandler.getInstance().posConfig.posHeader.amounts,
      subtotalAmount: subtotalAmount,
      taxAmount: this.taxCents,
      amountsTotalPaid: this.appSettingsService.settings$.value.skipPrecalculate
        ? this.subtotalCents + this.taxCents
        : this.subtotalCents + this.taxCents - this.orderDiscount(),
    };
  }

  public updateTotalFromBasket() {
    const basketTotalCents = this.basketService.calculateTotalPrice(this.basketService.buttons);
    if (this.hasOrderDiscount()) {
      const orderDiscount = this.orderDiscount();
      this.subtotalCents = orderDiscount <= basketTotalCents ? basketTotalCents - orderDiscount : basketTotalCents;
    } else {
      this.subtotalCents = basketTotalCents;
    }
  }

  public processCalculateTotalsResponse(calculateTotalsResponse: IPosResponse) {
    const orderPOSNumber =
      this.appSettingsService.settings$.value.calculateTotalMode === CalculateTotalMode.VOID_ON_RETURN
        ? calculateTotalsResponse.OrderPOSNumber
        : null;
    this.orderPOSNumber = orderPOSNumber;
    PosElogHandler.getInstance().posConfig.posHeader.orderPosNumber = orderPOSNumber === null ? '' : orderPOSNumber.toString();
    this.subtotalCents = Number(calculateTotalsResponse.SubtotalCents) || 0;
    this.taxCents = Number(calculateTotalsResponse.TaxCents) || 0;
    this.updateCalculateTotalsTenders();
    this.calculatedOrder = true;
  }

  public processOpenOrderResponse(openOrderResponse: IPosResponse) {
    this.openedOrder = true;
    this.orderPOSNumber = openOrderResponse.OrderPOSNumber;
    this.receiptContent = openOrderResponse.Receipt;
  }

  public processTenderOrderResponse(tenderOrderResponse: IPosResponse) {
    this.orderPOSNumber = tenderOrderResponse.OrderPOSNumber;
    this.receiptContent = tenderOrderResponse.Receipt;
  }

  public processCompleteOrderResponse(completeOrderResponse: IPosResponse | null = null) {
    if (completeOrderResponse) {
      this.orderPOSNumber = completeOrderResponse.OrderPOSNumber;
      this.receiptContent = completeOrderResponse.Receipt;
    } else {
      this.orderPOSNumber = 0;
      this.receiptContent = '';
    }
  }

  public sameBasket(): boolean {
    if (this.checkoutBasketButtons === null) {
      return false;
    }
    if (this.basketService.buttons.length !== this.checkoutBasketButtons.length) {
      return false;
    }
    for (const oldButton of this.checkoutBasketButtons) {
      const newButton = this.basketService.buttons.find(
        (newBtn) => newBtn.Link === oldButton.Link && newBtn.uuid === oldButton.uuid && newBtn.quantity === oldButton.quantity
      );
      if (!newButton) {
        return false;
      } else {
        const similarButtons = areButtonsSimilar(oldButton, newButton);
        if (!similarButtons) {
          return false;
        }
      }
    }
    return true;
  }

  public orderDiscount(): number {
    const discountAmountButton = this.basketService.buttons.find((button: DotButton<ButtonState>) => button.state.$$OrderDiscount);
    return discountAmountButton && Number.isInteger(discountAmountButton.state.$$OrderDiscount)
      ? (discountAmountButton.state.$$OrderDiscount as number)
      : 0;
  }

  public hasOrderDiscount(): boolean {
    return this.basketService.buttons.some((button: DotButton<ButtonState>) => button.state.$$OrderDiscount);
  }

  public updateOpenOrderTenders(amountOwed: number) {
    if (!PosElogHandler.getInstance().posConfig.posHeader) {
      return;
    }
    if (typeof PosElogHandler.getInstance().posConfig.posHeader.amounts === undefined) {
      return;
    }
    const cardTender = {
      paid: PosPaidState.PAID,
      type: PosTenderType.CARD,
      paymentMediaId: '-1',
      paidAmount: amountOwed,
    };
    const amounts = PosElogHandler.getInstance().posConfig.posHeader ? PosElogHandler.getInstance().posConfig.posHeader.amounts : null;
    if (amounts) {
      if (amounts.tenders) {
        amounts.tenders.push(cardTender);
      } else {
        amounts.tenders = [cardTender];
      }
    }
  }

  public printReceipt(settings: PrinterSettings, receiptOverride: ReceiptPartial = {}): void {
    this.generatePrintableReceiptContent(settings, receiptOverride).then((content) => {
      if (content) {
        appLogger.debug(`Printing receipt text: \n${content}`);
        AtpPrinterService.getInstance().print(content);
      } else {
        appLogger.warn(`Print receipt skipped. Content is empty.`);
      }
    });
  }

  public generatePrintableReceiptContent(settings: PrinterSettings, receiptOverride: ReceiptPartial = {}): Promise<string> {
    return this.appReceiptCompilerService.compile(settings, { ...this.computeSessionReceiptInformation(settings), ...receiptOverride });
  }

  public printOrderCompletelyPaidButNotIntegratedIntoPos() {
    this.printReceipt({
      orderCompletelyPaidButNotIntegratedIntoPos: true,
      printTicketOption: PrintTicketOption.FULL,
    });
  }

  private computeSessionReceiptInformation(settings: PrinterSettings): Receipt {
    const isCompletelyPaid = this.paymentService.state$.value.paidAmount >= this.orderTotal;
    const orderNumber = `${this.orderPOSNumber ? this.orderPOSNumber : ''}`;
    return {
      printTicketOption: settings.printTicketOption,
      orderCompletelyPaidButNotIntegratedIntoPos: !!settings.orderCompletelyPaidButNotIntegratedIntoPos,
      orderNotPaidWillPayAtCounter: this.orderNotPaidWillPayAtCounter,
      posReceiptContent: this.receiptContent,
      printApplicationBasketContent: !this.appSettingsService.settings$.value.printPOSReceipt || !this.receiptContent,
      serviceType: this.sessionService.serviceType,
      paidAmount: this.paymentService.state$.value.paidAmount,
      cardPaidAmount: this.paymentService.state$.value.card.cardPaidAmount,
      totalCashInserted:
        this.paymentService.state$.value.cash.cashPaidAmount +
        this.paymentService.state$.value.cash.cashRefundedAmount +
        this.paymentService.state$.value.cash.cashFailedToRefundAmount,
      totalCashRefunded: this.paymentService.state$.value.cash.cashRefundedAmount,
      totalCashFailedToRefund: this.paymentService.state$.value.cash.cashFailedToRefundAmount,
      cashBackRefundedAmount: this.paymentService.state$.value.cash.cashBack.cashBackRefundedAmount,
      cashBackFailedToRefundAmount: this.paymentService.state$.value.cash.cashBack.cashBackFailedToRefundAmount,
      cashBackInsertedCash:
        this.paymentService.state$.value.cash.cashBack.cashBackRefundedAmount +
        this.paymentService.state$.value.cash.cashBack.cashBackFailedToRefundAmount,
      isCompletelyPaid,
      orderTotalAmount: this.orderTotal,
      subtotalCentsAmount: this.subtotalCents,
      taxCentsAmount: this.taxCents,
      orderNumber,
      tableServiceTentNumber: this.tableSelectionService.userInputTentNumber,
      hesetLogged: this.hesetLogged,
      defaultStoreLanguageIsRTL: this.translationsService.defaultLanguageIsRtl$.value,
      bundleSettings: AtpApplicationSettings.getInstance().bundleSettingsJson,
      orderDate: new Date(),
      hesetQrCode:
        this.hesetLogged && orderNumber && isCompletelyPaid
          ? `${this.appSettingsService.settings$.value.hesetMobileBaseUrl}/tablepick/${encryptTableServiceUrl(
              PosElogHandler.getInstance().posConfig.posHeader.AUID || ''
            )}`
          : undefined,
      kioskId: this.appSettingsService.settings$.value.kioskId,
    };
  }

  private updateCalculateTotalsTenders() {
    const amounts = PosElogHandler.getInstance().posConfig.posHeader.amounts;
    if (!amounts) {
      return;
    }

    if (!amounts.services) {
      amounts.services = [];
    }

    amounts.subtotalAmount =
      this.appSettingsService.settings$.value.SubtotalIncludesVAT === 1
        ? this.subtotalCents + this.orderDiscount() + this.taxCents
        : this.subtotalCents + this.orderDiscount();
    amounts.taxAmount = this.taxCents;
    amounts.amountsTotalPaid = this.subtotalCents + this.taxCents;
  }
}
