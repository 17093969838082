<main class="page-container pay-tower-page" data-automation="pay-tower-page">
  <header class="page-header">
    <img src="/Channels/skin/icon-logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <acr-loading *ngIf="$loading | async" [waitingMessage]="'2001038' | translate"></acr-loading>
  <section class="pay-tower-page__content">
    <img src="/Channels/skin/image--pay-tower.png" alt="" />
    <div class="pay-tower-page__text text-center">
      <ng-container *ngIf="$orderSaved | async">
        <img src="/Channels/skin/icon-tableServiceReceipt--gray.svg" class="size--80" alt="" />
        <h2 class="page-tower-page__title transform--none" [innerHTML]="'2001040' | translate"></h2>
      </ng-container>
    </div>
  </section>
</main>
