import { Pipe, PipeTransform } from '@angular/core';
import { isImage, isMp4, isWebm } from '@dotxix/helpers/file-extension.helper';
import { DotBanner } from 'dotsdk';
import { SlideShowSteps } from '@dotxix/models/interfaces/slide-show-steps';

@Pipe({
  name: 'bannersToSlideShowSteps',
})
export class BannersToSlideShowStepsPipe implements PipeTransform {
  transform(value: DotBanner[]): SlideShowSteps {
    return value.map((banner) => {
      return {
        path: banner.Image,
        duration: banner.Interval,
        isImage: isImage(banner.Image),
        isMp4: isMp4(banner.Image),
        isWebm: isWebm(banner.Image),
      };
    });
  }
}
