export enum AutoPopFeatMode {
  NOT_POP_ON_ORDER_POP_ON_MODIFY = '0',
  POP_ON_ORDER_POP_ON_MODIFY = '1',
  POP_ON_ORDER_NOT_POP_ON_MODIFY = '2',
  NOT_POP_ON_ORDER_NOT_POP_ON_MODIFY = '3',
  POP_ON_ORDER_ITEMS_NOT_POP_ON_ORDER_COMBOS_POP_ON_MODIFY = '4',
  POP_ON_COMBOS_NOT_POP_ON_ITEMS = '5',
  NOT_POP_ON_ORDER_TAKE_DEFAULTS_POP_ON_MODIFY = '6',
  POP_ON_ORDER_TAKE_DEFAULTS_POP_ON_MODIFY = '7',
  POP_ON_ORDER_TAKE_DEFAULTS_NOT_POP_ON_MODIFY = '8',
  NOT_POP_ON_ORDER_TAKE_DEFAULTS_NOT_POP_ON_MODIFY = '9',
}
