import { Pipe, PipeTransform } from '@angular/core';
import { DotPage, DotPageInfo } from 'dotsdk';

import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'pageTitle',
  pure: false,
})
export class PageTitlePipe implements PipeTransform {
  constructor(private translationsService: TranslationsService) {}

  public transform(page: DotPage | DotPageInfo): string {
    const title = page.TitleDictionary ? this.translationsService.translateTitle(page.TitleDictionary) : page?.Title || '';
    return title.replaceAll('|', '<br/>');
  }
}
