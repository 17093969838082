import { calculateButtonPrice, ComboStepType, DotButton, DotButtonType, DotModifier, PosServingLocation } from 'dotsdk';
import { BasketDisplayItem } from '@dotxix/models/interfaces/basket-display-item';
import { createFakeButton } from '@dotxix/helpers/fake-button.helper';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { hasModifiersWithMenuModifierTemplate, isItemPackButton, isNotItemPackButton } from '@dotxix/helpers/button.helper';
import { AutoPopFeatMode } from '@dotxix/models/enums/auto-pop-feat';
import { ModifierState } from '@dotxix/models/interfaces/modifier-state';

export const computeBasketDisplayItems = (buttons: DotButton[]): BasketDisplayItem[] => {
  const displayButtons = computeProductBasketDisplayButtons(buttons);
  return displayButtons.map((button) => ({
    button,
    children: computeProductBasketDisplayChildren(button, buttons),
  }));
};

export const computeProductBasketDisplayButtons = (buttons: DotButton<ButtonState>[]): DotButton[] =>
  buttons.reduce((acc: DotButton<ButtonState>[], btn: DotButton<ButtonState>, i, arr) => {
    if (
      !btn.state.childLinkUUID &&
      (!btn.isPromo || arr.filter((b) => b.state.promoMetadata?.UUID === btn.state.promoMetadata?.UUID).length <= 1)
    ) {
      acc.push(btn);
    } else if (btn.state.childLinkUUID) {
      return acc;
    } else if (btn.isPromo && !btn.state.$$OrderDiscount) {
      const find = acc.find((a) => a.state.promoMetadata?.UUID === btn.state.promoMetadata?.UUID);
      if (find) {
        find.state.promoChildButtons?.push(btn);
      } else {
        const fakeButton = createFakeButton();
        fakeButton.state.promoMetadata = btn.state.promoMetadata;
        fakeButton.Price = '0';
        fakeButton.Caption = btn.state.promoMetadata?.name ?? '';
        fakeButton.isPromo = true;
        fakeButton.quantity = 1;
        fakeButton.ButtonType = DotButtonType.ITEM_BUTTON;
        fakeButton.state.promoChildButtons = [btn];
        acc.push(fakeButton);
      }
    }
    return acc;
  }, []);

export const computeProductBasketDisplayChildren = (
  button: DotButton<ButtonState>,
  basketButtons: DotButton<ButtonState>[]
): DotButton<ButtonState>[] => {
  return button.state.promoChildButtons
    ? button.state.promoChildButtons
    : basketButtons.filter((b) => button.state.parentLinkUUID && b.state.childLinkUUID === button.state.parentLinkUUID);
};

export const computeProductDisplayPrice = (
  serviceType: PosServingLocation,
  product: DotButton,
  multiplyWithQuantity: boolean,
  children: DotButton[]
) => {
  const basketButtonPrice = calculateButtonPrice(product, serviceType);

  if (children.length > 0) {
    return children.reduce(
      (acc, button) => acc + calculateButtonPrice(button, serviceType) * button.quantity,
      basketButtonPrice * product.quantity
    );
  } else {
    return multiplyWithQuantity ? basketButtonPrice * product.quantity : basketButtonPrice;
  }
};

export const computeProductDetailItems = (product: DotButton): DotButton[] => {
  if (product.hasCombos) {
    return computeProductDetailItemsForCombo(product);
  }

  if (product.hasModifiers || hasModifiersWithMenuModifierTemplate(product)) {
    return computeProductDetailItemsForModifiers(product);
  }

  return [];
};

const computeProductDetailItemsForModifiers = (product: DotButton): DotButton[] => {
  let displayItems: DotButton[] = [];
  product.ModifiersPage.Modifiers.forEach((modifier: DotModifier<ModifierState>) => {
    const hasIceModifierTemplate =
      modifier.modifierTemplate === 'ice' &&
      (modifier.PageInfo.AutoPopFeat !== AutoPopFeatMode.NOT_POP_ON_ORDER_POP_ON_MODIFY ||
        (modifier.PageInfo.AutoPopFeat === AutoPopFeatMode.NOT_POP_ON_ORDER_POP_ON_MODIFY && modifier.state.iceModifierSelected));
    const hasOnlyOneIceButton = hasIceModifierTemplate && modifier.Buttons.length === 1;

    modifier.Buttons.forEach((modifierButton: DotButton<ButtonState>) => {
      modifierButton.state.hasIceModifierTemplate = hasIceModifierTemplate;
      modifierButton.state.hasOnlyOneIceButton = hasOnlyOneIceButton;

      if (isNotItemPackButton(modifierButton)) {
        displayItems = [...displayItems, modifierButton];
      }
      if (isItemPackButton(modifierButton)) {
        const subgroupButtons = modifierButton.Page?.Buttons?.filter((b) => b.Selected) || [];
        displayItems = [...displayItems, ...subgroupButtons];
      }
    });
  });
  return displayItems;
};

const computeProductDetailItemsForCombo = (product: DotButton) => {
  let displayItems: DotButton[] = [];

  const comboItems = computeListWithFirstSelectedAndVisibleButtonFromVisibleComboSteps(product);

  comboItems.forEach((comboItem) => {
    comboItem?.ModifiersPage?.Modifiers?.forEach((modifier) => {
      const hasIceModifierTemplate =
        modifier.modifierTemplate === 'ice' && modifier.PageInfo.AutoPopFeat !== AutoPopFeatMode.NOT_POP_ON_ORDER_POP_ON_MODIFY;
      const hasOnlyOneIceButton = hasIceModifierTemplate && modifier.Buttons.length === 1;

      modifier.Buttons.forEach((modifierButton: DotButton<ButtonState>) => {
        modifierButton.state.hasIceModifierTemplate = hasIceModifierTemplate;
        modifierButton.state.hasOnlyOneIceButton = hasOnlyOneIceButton;
      });
    });
  });

  const unPackedItems = comboItems.filter((displayItem) => isNotItemPackButton(displayItem));
  displayItems = [...displayItems, ...unPackedItems];

  const packedItems = comboItems.filter((btn) => isItemPackButton(btn) && btn.Selected);
  packedItems.forEach((selectedBtn) => {
    displayItems = [...displayItems, ...selectedBtn.Page.Buttons];
  });
  return displayItems;
};

export const computeListWithFirstSelectedAndVisibleButtonFromVisibleComboSteps = (product: DotButton): DotButton[] => {
  const lowerCaseComboStep = ComboStepType.ComboStep.toLowerCase();
  const visibleComboSteps = product.ComboPage.Combos.filter(
    (combo) => combo.Visible && combo.ComboStepType.toLowerCase() === lowerCaseComboStep
  );

  return visibleComboSteps.reduce((acc, comboStep) => {
    const firstSelectedAndVisibleButton = comboStep.Buttons.find((btn) => btn.Selected && btn.Visibility !== '255');
    return firstSelectedAndVisibleButton ? [...acc, firstSelectedAndVisibleButton] : acc;
  }, [] as DotButton[]);
};

export const computeProductListOfSelectedButtonsFromModifiersPage = (button: DotButton): DotButton[] => {
  const acc: DotButton[] = [];
  return button.hasModifiers ? button.ModifiersPage.Modifiers.reduce((acc, mod) => [...acc, ...mod.selectedButtons], acc) : [];
};
