<div class="container" [style.border-bottom]="toggle ? 'none' : ''">
  <div class="title" (click)="toggleContent()">
    {{ title }}
    <div
      class="icon"
      style="width: 25px; height: 25px"
      [style.transform]="toggle ? 'translateY(-50%) translateX(-30%) rotate(180deg)' : ''"
    >
      <svg viewBox="0 0 49.685 41.102">
        <path
          style="fill: #000000"
          d="M3.825 6.501c-1.872 0.024-3.38 1.547-3.38 3.422 0 0.946 0.383 1.802 1.004 2.421l12.153 12.153c0.62 0.62 1.476 1.003 2.422 1.003s1.802-0.383 2.422-1.003l12.153-12.153c0.592-0.615 0.956-1.452 0.956-2.374 0-1.891-1.533-3.425-3.425-3.425-0.922 0-1.759 0.365-2.375 0.957l0.001-0.001-9.73 9.73-9.722-9.73c-0.62-0.619-1.475-1.002-2.42-1.002-0.019 0-0.037 0-0.055 0l0.003-0z"
        ></path>
      </svg>
    </div>
  </div>
  <div @leaveHeight @enterHeight *ngIf="toggle">
    <ng-content></ng-content>
  </div>
</div>
