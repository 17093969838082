import { Component } from '@angular/core';
import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-go-back',
  templateUrl: './go-back.component.html',
})
export class GoBackComponent {
  constructor(
    private checkoutFlowService: CheckoutFlowService,
    private appBIService: AppBIService
  ) {}

  public click() {
    this.appBIService.actions.clicks.clickedReturnFromCheckout();
    this.checkoutFlowService.returnFromCheckout();
  }
}
