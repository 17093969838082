import { Injectable } from '@angular/core';

import { FilesLoaderService } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { CssVariableTable as Model } from '@dotxix/custom-theme/models/css-variable-loader.model';
import { CssVariablesTable } from '@dotxix/custom-theme/models/css-variables.interface';

export const CUSTOM_CSS_VARIABLES_FILE_NAME = 'theme_editor.json';
const BASE_PATH = 'shared\\assets\\';
export const CUSTOM_CSS_VARIABLES_FULL_PATH = BASE_PATH.concat(CUSTOM_CSS_VARIABLES_FILE_NAME);

@Injectable({ providedIn: 'root' })
export class CssVariablesInjectService {
  // public atpLoadedCssVariables$ = new BehaviorSubject<CssVariablesTable>(null);
  constructor(private appSettingsService: ApplicationSettingsService) {}

  public initialize() {
    // const dataFromBs = this.appSettingsService.settings$.value.themeData;
    // if (dataFromBs) {
    //   this.loadData(dataFromBs);
    //   return;
    // }
    this.initialiseCssVariablesFromLoader().then((variables) => {
      if (variables) {
        // this.loadData(variables);
      }
    });
  }

  private initialiseCssVariablesFromLoader() {
    return new Promise<CssVariablesTable | null>((res) => {
      FilesLoaderService.getInstance()
        .registerLoadersFor(`${this.appSettingsService.settings$.value.sharedFolderPath}/assets`, [
          { modelType: Model, filename: CUSTOM_CSS_VARIABLES_FILE_NAME },
        ])
        .then((x) => {
          res(x.get(Model) as CssVariablesTable);
        })
        .catch(() => res(null));
    });
  }

  // private loadData(data: CssVariablesTable) {
  // const cssVariablesfromFileOrDefault = Object.keys(data.generalTheming).length
  //   ? data
  //   : {
  //       ...fallBackVariables,
  //       generalTheming: extractCssVariablesValues(document, defaultCssVariableKeys),
  //     };
  // this.atpLoadedCssVariables$.next(cssVariablesfromFileOrDefault);
  // Object.keys(cssVariablesfromFileOrDefault.generalTheming).map((key) => {
  // document.documentElement.style.setProperty(key, cssVariablesfromFileOrDefault.generalTheming[key]);
  // });
  //
  // if (data.logoImage) {
  //   // document.documentElement.style.setProperty(CustomLogoKey.CssVariableLogoKey, data.logoImage[CustomLogoKey.CssVariableLogoKey]);
  // }
  // }
}
