import { Injectable } from '@angular/core';
import { Channels } from 'dotsdk';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  public initialize() {
    Channels.getInstance().updated.subscribe(() => {
      window.location.reload();
    });
  }
}
