import { Injectable } from '@angular/core';
import { PromosComponent } from '@dotxix/pages/promotions/promos.component';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { DynamicContentRef } from '@dotxix/services/dynamic-content/models/dynamic-content.ref';
import { PromoInputOption } from '@dotxix/models/enums/promo-input-option';
import { PromoMethod, PROMOS_VIEW } from '@dotxix/models/enums/promos';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { StatusService } from '@dotxix/services/status.service';
import { BehaviorSubject } from 'rxjs';
import { PromoState } from '@dotxix/models/interfaces/promo-state';
import { PromosService } from '@dotxix/services/promos.service';
import { OrderDiscountResponse, PromoNode, PromotionQuery, PromotionsService } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { ConfirmDialogDataParams } from '@dotxix/models/interfaces/confirm-dialog-data-params';
import { PromoScannerService } from '@dotxix/services/promo-scanner.service';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { PromoStepperComponent } from '@dotxix/components/promos/promo-stepper/promo-stepper.component';
import { AppBIService } from '../business-intelligence/bi.service';

export const initialPromoFlowState: PromoState = {
  view: null,
};

@Injectable({
  providedIn: 'root',
})
export class PromoFlowService {
  public state$ = new BehaviorSubject<PromoState>(initialPromoFlowState);

  private promosDialog: DynamicContentRef<void, void> | null = null;
  private keyboardErrorDialog: DynamicContentRef<ConfirmDialogDataParams, ConfirmDialogButtons> | null = null;
  private scanErrorDialog: DynamicContentRef<ConfirmDialogDataParams, ConfirmDialogButtons> | null = null;

  private readonly BI_PROMO_CODE_INVALID_DIALOG = 'BI_PROMO_CODE_INVALID_DIALOG';

  constructor(
    private statusService: StatusService,
    private applicationSettingsService: ApplicationSettingsService,
    private dynamicContentService: DynamicContentService,
    private promosService: PromosService,
    private translationsService: TranslationsService,
    private promoScannerService: PromoScannerService,
    private appBiService: AppBIService
  ) {
    this.promoScannerService.barcode$.subscribe({ next: (barcode) => this.onBarcodeScanned(barcode), error: () => this.onScanError() });
  }

  public showVouchersScreen() {
    const startView = this.getVouchersStartScreen();
    switch (startView) {
      case PROMOS_VIEW.SELECT_METHOD:
        this.showSelectMethod();
        break;
      case PROMOS_VIEW.KEYBOARD:
        this.enterWithKeyboard();
        break;
      case PROMOS_VIEW.SCAN:
        this.scanBarcode();
        break;
    }
  }

  public methodSelectionCanceled() {
    this.promosDialog?.close();
  }

  public onKeyboardSelected() {
    this.enterWithKeyboard();
  }

  public onScanSelected() {
    this.scanBarcode();
  }

  public scanBarcodeCanceled() {
    this.promoScannerService.stopScanning();
    this.promosDialog?.close();
  }

  public keyboardCanceled() {
    this.promosDialog?.close();
  }

  public keyboardBarcodeSelected(barcode: string) {
    this.onKeyboardBarcodeSelected(barcode);
  }

  public findByPageIDAndApply(barcode: string, pageID: string) {
    const promotionQuery = PromotionsService.getInstance().getPromoByButtonLink(pageID);
    if (promotionQuery && promotionQuery.success) {
      this.applyPromoPageIDIfAllowed(barcode, promotionQuery);
    } else {
      this.showPromoError(promotionQuery?.message ?? null);
    }
  }

  private getVouchersStartScreen() {
    switch (this.applicationSettingsService.settings$.value.promoInputOption) {
      case PromoInputOption.KEYBOARD:
        return PROMOS_VIEW.KEYBOARD;
      case PromoInputOption.SCANNER:
        return this.statusService.state$.value.isScannerAvailableForApp ? PROMOS_VIEW.SCAN : PROMOS_VIEW.KEYBOARD;
      case PromoInputOption.BOTH:
        return this.statusService.state$.value.isScannerAvailableForApp ? PROMOS_VIEW.SELECT_METHOD : PROMOS_VIEW.KEYBOARD;
      case PromoInputOption.NONE:
        return;
    }
  }

  private showSelectMethod() {
    this.state$.next({ ...this.state$.value, view: PROMOS_VIEW.SELECT_METHOD });
    this.openPromosDialog();
  }

  private enterWithKeyboard() {
    this.state$.next({ ...this.state$.value, view: PROMOS_VIEW.KEYBOARD });
    this.openPromosDialog();
  }

  private scanBarcode() {
    this.promoScannerService.startScanning();
    this.state$.next({ ...this.state$.value, view: PROMOS_VIEW.SCAN });
    this.openPromosDialog();
  }

  private openPromosDialog() {
    if (this.promosDialog) {
      return;
    }
    this.promosDialog = this.dynamicContentService.openContent(PromosComponent);
    this.promosDialog.afterClosed.subscribe(() => (this.promosDialog = null));
  }

  private showKeyboardError(message: string | null) {
    if (this.promosDialog) {
      this.promosDialog.close();
    }
    const title = message || this.translationsService.translate('68'); // Invalid code
    const leftButtonText = this.translationsService.translate('23'); // Cancel
    const rightButtonText = this.translationsService.translate('70'); // Retry
    this.keyboardErrorDialog = this.dynamicContentService.openContent(ConfirmDialogComponent, {
      title,
      leftButtonText,
      rightButtonText,
    });

    this.appBiService.actions.impressions.viewConfirmationDialog(this.BI_PROMO_CODE_INVALID_DIALOG, [leftButtonText, rightButtonText]);

    this.keyboardErrorDialog.afterClosed.subscribe((response) => {
      this.keyboardErrorDialog = null;
      if (response === ConfirmDialogButtons.RIGHT_BUTTON) {
        this.appBiService.actions.clicks.clickedConfirmationDialog(this.BI_PROMO_CODE_INVALID_DIALOG, rightButtonText);
        this.enterWithKeyboard();
      } else if (response === ConfirmDialogButtons.LEFT_BUTTON) {
        this.appBiService.actions.clicks.clickedConfirmationDialog(this.BI_PROMO_CODE_INVALID_DIALOG, leftButtonText);
      }
    });
  }

  private showPromoError(message: string | null) {
    const title = message || this.translationsService.translate('68'); // Invalid code
    const buttonText = this.translationsService.translate('20210504003');

    this.appBiService.actions.impressions.viewConfirmationDialog(this.BI_PROMO_CODE_INVALID_DIALOG, [buttonText]);

    this.dynamicContentService
      .openContent(InfoDialogComponent, {
        title,
        buttonText,
      })
      .afterClosed.subscribe(() => {
        this.keyboardErrorDialog = null;

        this.appBiService.actions.clicks.clickedConfirmationDialog(this.BI_PROMO_CODE_INVALID_DIALOG, buttonText);
      });
  }

  private showScanError(message: string | null) {
    const title = message || this.translationsService.translate('2021012204'); // TODO missing internationalization text
    const leftButtonText = this.translationsService.translate('32'); // no
    const rightButtonText = this.translationsService.translate('33'); // yes
    this.scanErrorDialog = this.dynamicContentService.openContent(ConfirmDialogComponent, {
      title,
      leftButtonText,
      rightButtonText,
    });

    this.appBiService.actions.impressions.viewConfirmationDialog(this.BI_PROMO_CODE_INVALID_DIALOG, [leftButtonText, rightButtonText]);

    this.scanErrorDialog.afterClosed.subscribe((response) => {
      this.scanErrorDialog = null;
      if (response === ConfirmDialogButtons.LEFT_BUTTON) {
        this.appBiService.actions.clicks.clickedConfirmationDialog(this.BI_PROMO_CODE_INVALID_DIALOG, leftButtonText);
        if (this.applicationSettingsService.settings$.value.promoInputOption === PromoInputOption.BOTH) {
          this.showSelectMethod();
        } else {
          this.scanBarcode();
        }
      }

      if (response === ConfirmDialogButtons.RIGHT_BUTTON) {
        this.appBiService.actions.clicks.clickedConfirmationDialog(this.BI_PROMO_CODE_INVALID_DIALOG, rightButtonText);
        this.scanBarcode();
      }
    });
  }

  private onKeyboardBarcodeSelected(barcode: string) {
    this.promosDialog?.close();
    this.findByBarcodeAndApply(barcode, PromoMethod.KEYBOARD);
  }

  private onBarcodeScanned(barcode: string) {
    this.promoScannerService.stopScanning();
    this.promosDialog?.close();
    this.findByBarcodeAndApply(barcode, PromoMethod.SCAN);
  }

  private onScanError() {
    this.showScanError(this.translationsService.translate('2020101408'));
  }

  private findByBarcodeAndApply(barcode: string, method: PromoMethod) {
    const promotionQuery = PromotionsService.getInstance().getPromoByBarcode(barcode);

    if (promotionQuery && promotionQuery.success && promotionQuery.isAvailable) {
      this.applyPromoIfAllowed(barcode, promotionQuery);
    } else {
      const userMessage = this.translationsService.translate('2022121201');
      if (method === PromoMethod.KEYBOARD) {
        this.showKeyboardError(userMessage);
      }
      if (method === PromoMethod.SCAN) {
        this.showScanError(userMessage);
      }
    }
  }

  private applyPromoIfAllowed(barcode: string, promotionQuery: PromotionQuery) {
    if (!this.promosService.isSameTypePromotionAllowed(barcode, promotionQuery)) {
      this.clearOrderDiscountFromElog(promotionQuery);
      this.showMaximumAllowedPromo(true);
      return;
    }
    if (!this.promosService.isPromotionAllowedForOrder(barcode, promotionQuery)) {
      this.clearOrderDiscountFromElog(promotionQuery);
      this.showMaximumAllowedPromo(false);
      return;
    }

    if (promotionQuery.promoPayload instanceof PromoNode) {
      this.dynamicContentService.openContent(PromoStepperComponent, {
        barcode: barcode,
        promoNode: promotionQuery.promoPayload,
        promoName: promotionQuery.message,
        promoPageID: promotionQuery.pageId,
      });
    }
    if (promotionQuery.promoPayload instanceof OrderDiscountResponse) {
      const replacingOrderDiscount = this.promosService.hasOrderDiscount();
      this.promosService.setOrderDiscount(barcode, promotionQuery);
      this.dynamicContentService.openContent(InfoDialogComponent, {
        title: replacingOrderDiscount
          ? this.translationsService.translate('20210504005')
          : this.translationsService.translate('20210504002'),
        buttonText: this.translationsService.translate('20210504003'),
      });
    }
  }

  private applyPromoPageIDIfAllowed(barcode: string, promotionQuery: PromotionQuery) {
    if (!this.promosService.isSamePromotionAllowed(promotionQuery.pageId, promotionQuery)) {
      this.clearOrderDiscountFromElog(promotionQuery);
      this.showMaximumAllowedPromo(true);
      return;
    }
    if (!this.promosService.isPromotionAllowedForOrder(promotionQuery.pageId, promotionQuery)) {
      this.clearOrderDiscountFromElog(promotionQuery);
      this.showMaximumAllowedPromo(false);
      return;
    }

    if (promotionQuery.promoPayload instanceof PromoNode) {
      this.dynamicContentService.openContent(PromoStepperComponent, {
        barcode,
        promoNode: promotionQuery.promoPayload,
        promoName: promotionQuery.message,
        promoPageID: promotionQuery.pageId,
      });
    }
    if (promotionQuery.promoPayload instanceof OrderDiscountResponse) {
      const replacingOrderDiscount = this.promosService.hasOrderDiscount();
      this.promosService.setOrderDiscount(barcode, promotionQuery);
      this.dynamicContentService.openContent(InfoDialogComponent, {
        title: replacingOrderDiscount
          ? this.translationsService.translate('20210504005')
          : this.translationsService.translate('20210504002'),
        buttonText: this.translationsService.translate('20210504003'),
      });
    }
  }

  private showMaximumAllowedPromo(sameType = false) {
    this.dynamicContentService.openContent(InfoDialogComponent, {
      title: sameType ? this.translationsService.translate('69') : this.translationsService.translate('20210517001'),
      buttonText: this.translationsService.translate('20210504003'),
    });
  }

  private clearOrderDiscountFromElog(promotionQuery: PromotionQuery) {
    if (promotionQuery.promoPayload instanceof OrderDiscountResponse) {
      promotionQuery.promoPayload.clearAppliedOrderDiscountFromElog();
    }
  }
}
