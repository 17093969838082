import { Component, OnInit } from '@angular/core';

import { PAYMENT_TYPE } from '@dotxix/models/enums/payment-type';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';
import { StatusService } from '@dotxix/services/status.service';
import { PaymentFlowService } from '@dotxix/payment/services/payment-flow.service';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { map, take } from 'rxjs/operators';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-payment-selection',
  templateUrl: './payment-selection-page.component.html',
})
export class PaymentSelectionPageComponent implements OnInit {
  public availablePayments$ = this.statusService.state$.pipe(map((state) => state.availablePayments));
  public amountOwed = 0;
  public cashGloryPayableAmountReached = false;
  public PAYMENT_TYPE = PAYMENT_TYPE;

  constructor(
    private statusService: StatusService,
    private checkoutFlowService: CheckoutFlowService,
    private checkoutService: CheckoutService,
    private paymentFlowService: PaymentFlowService,
    private paymentService: PaymentService,
    private applicationSettingsService: ApplicationSettingsService,
    private appBIService: AppBIService
  ) {}

  public ngOnInit() {
    this.amountOwed = this.checkoutService.getAmountOwed();
    this.cashGloryPayableAmountReached =
      this.paymentService.state$.value.cash.cashPaidAmount >= this.applicationSettingsService.settings$.value.gloryPayableAmount;
    this.analyticsViewPaymentOptions();
  }

  public onPaymentTypeClicked(payment: PaymentType) {
    this.appBIService.actions.clicks.clickedPaymentOption(payment.PaymentName);
    this.paymentFlowService.userSelectedPaymentType(payment);
  }

  public onCancelClicked() {
    this.appBIService.actions.clicks.clickedPaymentOptionsCancel();
    this.checkoutFlowService.paymentSelectionCanceled().then(() => {});
  }

  private analyticsViewPaymentOptions() {
    this.availablePayments$.pipe(take(1)).subscribe((availablePayments) => {
      this.appBIService.actions.impressions.viewedPaymentOptions(availablePayments.map((value) => value.PaymentName));
    });
  }
}
