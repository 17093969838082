import { Component } from '@angular/core';
import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';

@Component({
  selector: 'acr-toggle-button',
  template: `
    <button (click)="toggleThemeEditor()" class="theme-editor-switch-button">
      <svg *ngIf="(editorOpenClose$ | async) === false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 120" style="width:50rem">
        <path
          d="M42.6,114.51a46.35,46.35,0,0,0,23.82-3.13A50.7,50.7,0,0,0,74.79,107l.7,6.9a55.438,55.438,0,0,1-6.71,3.27,52.53,52.53,0,0,1-27,3.52,45.4,45.4,0,0,1-26-13.18A55,55,0,0,1,1.16,80.22a52.92,52.92,0,0,1,5.71-37A54.1,54.1,0,0,1,35.69,18.76,26.41,26.41,0,0,1,48.12,17a13.43,13.43,0,0,1,9.34,5.38,3.42,3.42,0,0,1,.33.59c2.91,6.65-.63,10.82-4,14.8-2,2.3-3.81,4.5-2.71,6.6.46.9,1.61,1.58,3.26,2a19.72,19.72,0,0,0,7.93.11c2.58-.41,5.25-1,7.79-1.51,1.54-.33,3-.65,4.5-.93l-2,6.81-1.22.26c-2.52.53-5.17,1.09-8.11,1.56a26.22,26.22,0,0,1-10.48-.23c-3.38-.88-5.92-2.65-7.24-5.18-3.05-5.85.14-9.61,3.47-13.55,2.07-2.44,4.24-5,3.19-7.92a7.4,7.4,0,0,0-4.92-2.59,20.41,20.41,0,0,0-9.46,1.4A47.9,47.9,0,0,0,12.34,46.24a46.78,46.78,0,0,0-5,32.68,48.77,48.77,0,0,0,12.9,24.2A39.12,39.12,0,0,0,42.6,114.51Z"
          transform="translate(-0.015)"
          fill="#1a1a1a"
        />
        <path d="M22.1,55.71a9,9,0,1,0,.144-6.937A9,9,0,0,0,22.1,55.71Z" transform="translate(-0.015)" fill="#efaa3a" fill-rule="evenodd" />
        <path d="M16.76,82.51a7.043,7.043,0,1,1,.02,0Z" transform="translate(-0.015)" fill="#25a700" fill-rule="evenodd" />
        <path
          d="M62.07,81.66a10.4,10.4,0,0,0,10.8,6.6L70.76,67.57a10.17,10.17,0,0,0-2.72.65,10.39,10.39,0,0,0-6,13.44Z"
          transform="translate(-0.015)"
          fill="#e22952"
          fill-rule="evenodd"
        />
        <path
          d="M89.52,0c-.77,12.18-16.75,19.69-11.11,32.37a14.14,14.14,0,0,0,8.46,7.57,9.07,9.07,0,0,0,6.58-.42c8.3-3.76,9.29-13.94,6.84-21.77C98,10.54,93.08,4.17,89.52,0Zm10.66,62.89a46.07,46.07,0,0,1-19.92.19L85.66,116a13.45,13.45,0,0,0,.56,3.36,5.19,5.19,0,0,0,8.54,2,6.07,6.07,0,0,0,1.43-3.88l4-54.6Zm-5.4-17.83c-2.51,1-7,1.59-10.21,0L80.36,59.58c7,1.56,13.82,1.85,20.38,0-3.26-6.33-3.52-7-6-14.47Z"
          transform="translate(-0.015)"
          fill="#1a1a1a"
          fill-rule="evenodd"
        />
        <path d="M40.75,101.59a7.044,7.044,0,1,1,.03,0Z" transform="translate(-0.015)" fill="#42a3cc" fill-rule="evenodd" />
      </svg>
      <span *ngIf="editorOpenClose$ | async" class="fs--25">CLOSE</span>
    </button>
  `,
  styles: [
    `
      :host {
        height: 100%;
        display: inline-block;
        width: 100%;
      }
      .theme-editor-switch-button {
        width: 100%;
        height: 100%;
        color: black;
      }
    `,
  ],
})
export class ThemeToggleButtonComponent {
  public editorOpenClose$ = this.themeEditorStateService.editorOpenClose$;

  constructor(private themeEditorStateService: ThemeEditorStateService) {}

  public toggleThemeEditor() {
    this.themeEditorStateService.toggleThemeEditor();
  }
}
