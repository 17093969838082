import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';
import { AnimationType } from '@dotxix/models/enums/animation-type';
import { AnimationAction } from '@dotxix/models/enums/animation-action';
import { AnimationParameters } from '@dotxix/models/interfaces/animation-parameters';

type AnimationMeta = {
  [key in Exclude<AnimationType, AnimationType.cssOnly>]: {
    [k in AnimationAction]: AnimationParameters;
  };
};

export const enter: AnimationTriggerMetadata = trigger('enter', [
  transition(
    ':enter',
    [
      style({ transform: '{{ fromTransform }}', opacity: '{{fromOpacity}}' }),
      animate('{{animateTime}} {{animateCurve}}', style({ transform: '{{toTransform}}', opacity: '{{toOpacity}}' })),
    ],
    {
      params: {
        fromTransform: 'unset',
        fromOpacity: 'unset',
        toTransform: 'unset',
        toOpacity: 'unset',
        animateTime: '0.5s',
        animateCurve: 'ease-in-out',
      },
    }
  ),
]);
export const leave: AnimationTriggerMetadata = trigger('leave', [
  transition(
    ':leave',
    [
      style({ transform: '{{ fromTransform }}', opacity: '{{fromOpacity}}', position: 'absolute', left: 0, right: 0, top: 0 }),
      animate('{{animateTime}} {{animateCurve}}', style({ transform: '{{toTransform}}', opacity: '{{toOpacity}}' })),
    ],
    {
      params: {
        fromTransform: 'unset',
        fromOpacity: 'unset',
        toTransform: 'unset',
        toOpacity: 'unset',
        animateTime: '0.5s',
        animateCurve: 'ease-in-out',
      },
    }
  ),
]);

const animationMeta: AnimationMeta = {
  slideRightToLeft: {
    enter: {
      fromTransform: 'translateX(100%)',
      toTransform: 'translateX(0%)',
      animateTime: '500ms',
      animateCurve: 'linear',
    },
    leave: {
      fromTransform: 'translateX(0%)',
      toTransform: 'translateX(-100%)',
      animateTime: '500ms',
      animateCurve: 'linear',
    },
  },
  slideLeftToRight: {
    enter: {
      fromTransform: 'translateX(-100%)',
      toTransform: 'translateX(0%)',
      animateTime: '500ms',
      animateCurve: 'linear',
    },
    leave: {
      fromTransform: 'translateX(0%)',
      toTransform: 'translateX(100%)',
      animateTime: '500ms',
      animateCurve: 'linear',
    },
  },
  darthFader: {
    enter: {
      fromOpacity: '0',
      toOpacity: '1',
      animateTime: '1s',
      animateCurve: 'linear',
    },
    leave: {
      fromOpacity: '1',
      toOpacity: '0',
      animateTime: '700ms',
      animateCurve: 'linear',
    },
  },
};

export const animationStyleMetadata = (animationType: AnimationType, animationAction: AnimationAction) => {
  if (animationType !== AnimationType.cssOnly) {
    if (animationMeta[animationType]) {
      return { value: undefined, params: animationMeta[animationType][animationAction] };
    }
  }
  return;
};
