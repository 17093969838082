import { Component } from '@angular/core';
// import { LogoPickerStateService } from '@dotxix/custom-theme/services/logo-picker-state.service';
// import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';

@Component({
  selector: 'acr-upload-file',
  template: `
    <div class="container">
      <div class="upload-actions-container">
        <input class="form-control" type="file" accept="image/png, .svg, image/jpeg" #fileUpload />
        <button class="file-upload-click-button" (click)="fileUpload.click()">
          <img class="logo" width="100%" alt="" />
        </button>
      </div>
      <div class="header-title">CHANGE LOGO</div>
    </div>
  `,
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  // constructor(
  //   private logoPickerStateService: LogoPickerStateService,
  //   private themeEditorStateService: ThemeEditorStateService
  // ) {}
  // public processFile(imageInput: any) {
  //   const file: File = imageInput.target.files[0];
  //   const reader = new FileReader();
  //
  //   reader.onloadend = () => {
  //     if (typeof reader.result === 'string') {
  //       this.logoPickerStateService.updateLogo(reader.result);
  //       this.themeEditorStateService.showToastVariablesChangedNotification(NotificationType.customLogoVariable);
  //     }
  //   };
  //
  //   reader.readAsDataURL(file);
  // }
}
