import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';
import { OsirisState } from '@dotxix/osiris/models/interfaces/osiris-state.interface';
import { DotButton } from 'dotsdk';
import { map } from 'rxjs/operators';
import { OrderInfo } from '@dotxix/osiris/models/interfaces/order-info.interface';
import { RecallOrderStatus } from '@dotxix/osiris/models/enums/recall-order-status.enum';
import { FaceRecallScreenSteps } from '@dotxix/osiris/models/enums/face-recall-screen-steps.enum';
import { QrCodeScreenSteps } from '@dotxix/osiris/models/enums/qr-code-screen-steps.enum';
import { SessionService } from '@dotxix/services/session.service';
import { OrderNumberSteps } from '@dotxix/osiris/models/enums/order-number-steps.enum';

const initialState: OsirisState = {
  reviewOrder: null,
  processOrderError: null,
  faceRecall: {
    screenStep: FaceRecallScreenSteps.CustomerLogin,
    customError: null,
    loginWithExisting: false,
  },
  heset: {
    screenStep: QrCodeScreenSteps.ConnectingToSocket,
  },
  orderNumber: {
    screenStep: OrderNumberSteps.None,
  },
  showAccountCreated: false,
};

@Injectable({
  providedIn: 'root',
})
export class OsirisStateService {
  public state$: BehaviorSubject<OsirisState> = new BehaviorSubject<OsirisState>(initialState);
  public reviewOrder$ = this.state$.pipe(map((state) => state.reviewOrder));
  public processOrderError$ = this.state$.pipe(map((state) => state.processOrderError));
  public faceRecall$ = this.state$.pipe(map((state) => state.faceRecall));
  public hesetScreenStep$: Observable<QrCodeScreenSteps> = this.state$.pipe(map((state) => state.heset.screenStep));
  public orderNumberScreenStep$: Observable<OrderNumberSteps> = this.state$.pipe(map((state) => state.orderNumber.screenStep));
  public showAccountCreated$ = this.state$.pipe(map((state) => state.showAccountCreated));
  public constructor(private sessionService: SessionService) {
    this.sessionService.onRestartSession.subscribe(() => this.state$.next(initialState));
  }

  public reviewOrder(order: OsirisOrder, basketButtons: DotButton[]) {
    const orderInfo: OrderInfo = { orderDate: order.d, orderId: order.i };
    this.state$.next({ ...this.state$.value, reviewOrder: { orderInfo, basketButtons } });
  }
  public reviewOrderCanceled() {
    this.state$.next({ ...this.state$.value, reviewOrder: null });
  }
  public reviewOrderAccepted() {
    this.state$.next({ ...this.state$.value, reviewOrder: null });
  }

  public processOrderStarted() {
    this.state$.next({ ...this.state$.value, processOrderError: null });
  }
  public processOrderSuccess() {
    this.state$.next({ ...this.state$.value, processOrderError: null });
  }
  public processOrderError() {
    this.state$.next({ ...this.state$.value, processOrderError: RecallOrderStatus.SERVER_TIMEOUT });
  }
  public recallOrderError() {
    this.state$.next({ ...this.state$.value, processOrderError: RecallOrderStatus.ORDER_UNAVAILABLE });
  }

  public setFaceRecallStep(screenStep: FaceRecallScreenSteps) {
    this.state$.next({ ...this.state$.value, faceRecall: { ...this.state$.value.faceRecall, screenStep, customError: null } });
  }
  public faceRecallLoginWithExisting(loginWithExisting: boolean) {
    this.state$.next({ ...this.state$.value, faceRecall: { ...this.state$.value.faceRecall, loginWithExisting } });
  }
  public faceRecallError(customError: string) {
    this.state$.next({
      ...this.state$.value,
      faceRecall: { ...this.state$.value.faceRecall, screenStep: FaceRecallScreenSteps.Error, customError },
    });
  }

  public setHesetScreenStep(screenStep: QrCodeScreenSteps) {
    this.state$.next({ ...this.state$.value, heset: { screenStep } });
  }

  public setOrderNumberStep(screenStep: OrderNumberSteps) {
    this.state$.next({ ...this.state$.value, orderNumber: { screenStep } });
  }

  public showAccountCreated(showAccountCreated: boolean) {
    this.state$.next({ ...this.state$.value, showAccountCreated });
  }
}
