import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { getModifiersWithEditableButtons } from '@dotxix/helpers/auto-pop-feat.helper';
import { qaAutomationLog } from '@dotxix/helpers/qa-automation-log.helper';

import { calculateButtonPrice, DotButton, DotModifier, DotNutritionalValue, DotSessionService } from 'dotsdk';
import { AbstractDynamicAnimatedPopup } from '../../animation/abstract-animated-popup';
import { ButtonDetailsDataParams } from '@dotxix/models/interfaces/button-details-data-params';
import {
  addAutoCompleteModifiers,
  initializeButtonWithModifiers,
  isModifierSelectionValidForBasket,
} from '@dotxix/helpers/modifiers.helper';
import { ApplicationSettingsService } from '../../services/app-settings.service';
import { updateButtonPromoCustomForcePrice } from '../../helpers/promo.helper';
import { ButtonInfoService } from '@dotxix/button-info.service';
import { extractCaloriesByButtonType } from '@dotxix/helpers/callories.helper';
import { AppBIService } from '../../business-intelligence/bi.service';
import { BIContexts } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-button-details',
  templateUrl: './button-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDetailsComponent
  extends AbstractDynamicAnimatedPopup<ButtonDetailsDataParams, DotButton | null | undefined>
  implements OnInit
{
  public button!: DotButton;
  public editableModifiers!: DotModifier[];
  public displayModifiers!: boolean;
  public confirmButtonDisabled!: boolean;
  public displayPrice!: number;
  public showInfoButton!: boolean;
  public calories!: DotNutritionalValue | undefined;

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private buttonInfoService: ButtonInfoService,
    private appBIService: AppBIService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.appBIService.actions.impressions.viewProductCustomization(this.dataParams!.button);
    this.button = initializeButtonWithModifiers(this.dataParams!.button, true);
    this.calories = extractCaloriesByButtonType(this.button);
    this.showInfoButton =
      this.applicationSettingsService.settings$.value.showInfoButton && this.buttonInfoService.hasProductInfo(this.button);

    qaAutomationLog('button: ', this.button);

    this.editableModifiers = this.computeEditableModifiers();
    this.displayModifiers = this.editableModifiers.length > 0;
    this.updatePromoButtonForcePrice();
    this.updateDisplayPrice();
    this.validate();
  }

  public onProductQuantityUpdate(count: 1 | -1) {
    if (count >= 1) {
      this.appBIService.actions.clicks.increaseProductQuantity(this.button, BIContexts.SingleProductCustomization);
    } else {
      this.appBIService.actions.clicks.decreaseProductQuantity(this.button, BIContexts.SingleProductCustomization);
    }
    this.button.quantity += count;
    this.updatePromoButtonForcePrice();
    this.updateDisplayPrice();
    this.validate();
  }

  public onModifierChanged() {
    this.updatePromoButtonForcePrice();
    this.updateDisplayPrice();
    this.validate();
  }

  public confirmClick(): void {
    if (this.confirmButtonDisabled) {
      return;
    }

    addAutoCompleteModifiers(this.button);

    this.updatePromoButtonForcePrice();
    this.appBIService.actions.clicks.clickedProductCustomizationAccept(this.button);
    this.close(this.button);
  }

  public cancelClick(): void {
    this.appBIService.actions.clicks.clickedProductCustomizationCancel(this.button);
    this.close();
  }

  public onProductInfoClick(event: MouseEvent): void {
    event.stopPropagation();
    this.buttonInfoService.openProductInfo(this.button);
  }

  private updatePromoButtonForcePrice() {
    updateButtonPromoCustomForcePrice(
      this.button,
      DotSessionService.getInstance().currentPosServingLocation,
      this.applicationSettingsService.settings$.value.promoDiscountsMode
    );
  }
  private updateDisplayPrice() {
    this.displayPrice = calculateButtonPrice(this.button, DotSessionService.getInstance().currentPosServingLocation) * this.button.quantity;
  }

  private computeEditableModifiers() {
    return this.button.hasModifiers
      ? getModifiersWithEditableButtons(this.button.ModifiersPage.Modifiers, this.dataParams!.isUpdate, false)
      : [];
  }

  private validate() {
    this.confirmButtonDisabled = !this.isValidForBasket();
  }

  private isValidForBasket() {
    if (this.button.MinQuantity && this.button.quantity < this.button.MinQuantity) {
      return false;
    }
    if (this.button.MaxQuantity && this.button.quantity > this.button.MaxQuantity) {
      return false;
    }

    if (this.button.hasModifiers) {
      return isModifierSelectionValidForBasket(this.button);
    } else {
      return true;
    }
  }
}
