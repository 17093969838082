<language-change-view-trigger></language-change-view-trigger>
<div class="full flex justify-around">
  <div
    *ngFor="let button of displayButtons"
    class="dropdown__modifier"
    (click)="select(button)"
    [class.dropdown__modifier--disabled]="button | isButtonVisuallyUnavailable"
  >
    <h4>{{ getPrice(button) | dotCurrency }}</h4>
    <img
      class="dropdown__modifier--image"
      [class.logo]="!(button | translatePicture)"
      [src]="button | translatePicture"
      alt=""
      width="150rem"
      height="150rem"
    />
    <button class="ui-button ui-button--tertiary dropdown__modifier--button" [attr.data-automation]="'Button: ' + button.Link">
      {{ button | translateCaption }}
    </button>
  </div>
</div>
