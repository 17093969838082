import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { PosServingLocation } from 'dotsdk';
import { WorkingHoursService } from '@dotxix/services/working-hours.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceTypeService {
  public isKioskBlockedDueToServiceType$ = new BehaviorSubject<boolean>(true);
  public serviceTypeInEnabled$ = new BehaviorSubject<boolean>(false);
  public serviceTypeOutEnabled$ = new BehaviorSubject<boolean>(false);
  constructor(
    private workingHoursService: WorkingHoursService,
    private applicationSettingsService: ApplicationSettingsService
  ) {
    combineLatest([
      this.workingHoursService.whEatInEnabled$,
      this.workingHoursService.whTakeAwayEnabled$,
      this.applicationSettingsService.settings$,
    ]).subscribe(([isEatInWhEnabled, isTakeOutWhEnabled, settings]) => {
      this.serviceTypeInEnabled$.next(isEatInWhEnabled && this.canUseServiceType(PosServingLocation.IN, settings.serviceType));
      this.serviceTypeOutEnabled$.next(isTakeOutWhEnabled && this.canUseServiceType(PosServingLocation.OUT, settings.serviceType));
      this.isKioskBlockedDueToServiceType$.next(!this.serviceTypeInEnabled$.value && !this.serviceTypeOutEnabled$.value);
    });
  }

  public findEnabledServiceType() {
    if (
      this.workingHoursService.whEatInEnabled$.value &&
      this.canUseServiceType(PosServingLocation.IN, this.applicationSettingsService.settings$.value.serviceType)
    ) {
      return PosServingLocation.IN;
    } else if (
      this.workingHoursService.whTakeAwayEnabled$.value &&
      this.canUseServiceType(PosServingLocation.OUT, this.applicationSettingsService.settings$.value.serviceType)
    ) {
      return PosServingLocation.OUT;
    }
    return null;
  }

  private canUseServiceType(posServingLocation: PosServingLocation, settingsServiceType: PosServingLocation) {
    return (
      settingsServiceType === PosServingLocation.ALL ||
      settingsServiceType === PosServingLocation.ALL_ALTERNATIVE ||
      settingsServiceType === posServingLocation
    );
  }
}
