import { Injectable } from '@angular/core';
import { DotButton } from 'dotsdk';
import { isAutoPopFeatVisible } from '@dotxix/helpers/auto-pop-feat.helper';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { ButtonDetailsComponent } from '@dotxix/components/button-details/button-details.component';
import { lastValueFrom } from 'rxjs';
import { ComboStepperComponent } from '@dotxix/components/combo-stepper/combo-stepper.component';
import { hasModifiersWithMenuModifierTemplate } from '@dotxix/helpers/button.helper';
import { ModifiersStepperComponent } from '@dotxix/components/modifiers-stepper/modifiers-stepper.component';
import { ButtonDetailsDataParams } from '@dotxix/models/interfaces/button-details-data-params';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { CustomizeProductConfig } from '@dotxix/models/interfaces/customize-product-config';
import { CustomizeProductResult } from '@dotxix/models/interfaces/customize-product-result';
import { addComponentItemsToCombo, isAutoPopCombo } from '@dotxix/helpers/auto-pop-combo.helper';
import { appLogger } from '@dotxix/log-manager';
import { AppBIService } from '../business-intelligence/bi.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCustomizationService {
  private BI_CONFIRM_CANCEL_SUBJECT = 'CONFIRM_PRODUCT_CUSTOMIZATION_CANCEL';
  constructor(
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private appBIService: AppBIService
  ) {}
  public async customizeProduct(config: CustomizeProductConfig): Promise<CustomizeProductResult> {
    appLogger.debug(`customizing product: ${config.product.Link}`);
    const { product, updatingProduct } = config;
    if (product.hasCombos) {
      if (!isAutoPopCombo(product, !updatingProduct)) {
        return { customized: true, resultButton: addComponentItemsToCombo(product) };
      }
      const resultButton = await this.customizeWithComponent(ComboStepperComponent, { button: product, isUpdate: updatingProduct });
      if (resultButton) {
        return { customized: true, resultButton };
      } else {
        return { customized: true, resultButton: null };
      }
    } else if (hasModifiersWithMenuModifierTemplate(product)) {
      const resultButton = await this.customizeWithComponent(ModifiersStepperComponent, { button: product, isUpdate: updatingProduct });
      if (resultButton) {
        return { customized: true, resultButton };
      } else {
        return { customized: true, resultButton: null };
      }
    } else if (product.hasModifiers && product.ModifiersPage.Modifiers.some((mod) => isAutoPopFeatVisible(mod, !updatingProduct, true))) {
      const resultButton = await this.customizeWithComponent(ButtonDetailsComponent, { button: product, isUpdate: updatingProduct });
      if (resultButton) {
        return { customized: true, resultButton };
      } else {
        return { customized: true, resultButton: null };
      }
    } else {
      return { customized: false, resultButton: null };
    }
  }

  public customizeWithComponent(
    component: typeof ButtonDetailsComponent | typeof ComboStepperComponent | typeof ModifiersStepperComponent,
    params: ButtonDetailsDataParams
  ): Promise<DotButton | null | undefined> {
    return lastValueFrom(this.dynamicContentService.openContent(component, params).afterClosed);
  }

  public onCancelButton(
    button: DotButton | undefined | null,
    component: AbstractDynamicAnimatedPopup<ButtonDetailsDataParams, DotButton | undefined | null>
  ): void {
    const leftButtonText = this.translationsService.translate('32');
    const rightButtonText = this.translationsService.translate('33');
    this.appBIService.actions.clicks.clickedProductCustomizationCancel(button || { Caption: '', Link: '', ButtonType: -1 });
    this.appBIService.actions.impressions.viewConfirmationDialog(this.BI_CONFIRM_CANCEL_SUBJECT, [leftButtonText, rightButtonText]);
    this.dynamicContentService
      .openContent(ConfirmDialogComponent, {
        title: this.translationsService.translate('20210201001'),
        leftButtonText,
        rightButtonText,
      })
      .afterClosed.subscribe((response) => {
        if (response === ConfirmDialogButtons.RIGHT_BUTTON) {
          this.appBIService.actions.clicks.clickedConfirmationDialog(this.BI_CONFIRM_CANCEL_SUBJECT, rightButtonText);
          component.close(button);
        } else if (response === ConfirmDialogButtons.LEFT_BUTTON) {
          this.appBIService.actions.clicks.clickedConfirmationDialog(this.BI_CONFIRM_CANCEL_SUBJECT, leftButtonText);
        }
      });
  }
}
