import { Injectable } from '@angular/core';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { PaymentRoutes } from '@dotxix/payment/payment-routes';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { ElectronicPaymentService } from '@dotxix/payment/services/electronic-payment.service';
import { electronicPaymentLogger } from '@dotxix/log-manager';
import { EPayQrCodeScreenSteps } from '../models/qr-code-screen-steps.enum';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';
import { AppBIService } from '../../business-intelligence/bi.service';

@Injectable({ providedIn: 'root' })
export class ElectronicPaymentFlowService {
  public electronicPaymentWithSuccess$ = new Subject<void>();
  public displayPaymentSelection$ = new Subject<void>();

  constructor(
    private router: Router,
    private electronicPaymentService: ElectronicPaymentService,
    private checkoutService: CheckoutService,
    private paymentService: PaymentService,
    private appBiService: AppBIService
  ) {}

  public async startPayment(paymentType: PaymentType) {
    const amountOwned = this.checkoutService.getAmountOwed();
    this.paymentService.onElectronicPaymentAttempt(paymentType.PaymentName);
    if (amountOwned === 0) {
      this.electronicPaymentWithSuccess$.next();
      return;
    }
    this.electronicPaymentService.ePayScreenStep$.next(EPayQrCodeScreenSteps.ConnectingToPayment);
    await this.router.navigate([PaymentRoutes.ElectronicPayment]);
    this.electronicPaymentService.startListeningPaymentProgress();

    electronicPaymentLogger.debug(`Start electronic pay...`);
    const payResult = await this.electronicPaymentService.ePay(amountOwned, paymentType);
    if (payResult && payResult.PaidAmount) {
      this.paymentService.onElectronicPaymentSuccess(payResult.PaidAmount);
      this.electronicPaymentWithSuccess$.next();
    } else {
      this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.ELECTRONIC_PAYMENT_ERROR);
      this.displayPaymentSelection$.next();
    }
  }

  public onCancelPayClicked() {
    this.electronicPaymentService.cancelEPay();
  }
}
