import { Injectable } from '@angular/core';
import { appLogger } from '../../log-manager';
import { Receipt } from './models/receipt';
import { PrinterSettings } from '../../models/interfaces/printer-settings';
import { PrintTicketOption } from '../../models/enums/print-ticket-option';
import { ApplicationSettingsService } from '../app-settings.service';
import { calculateButtonPrice, DotButton, DotComboPage, DotModifiersPage, PosServingLocation } from '@acrelec.foundation/dotsdk';
import { BasketService } from '../basket.service';
import * as _ from 'lodash';
import { SessionService } from '../session.service';
import { DotReceiptCompiler, OrderProduct } from '@acrelec.foundation/dot-receipt-compiler';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { ReplaceTextType } from '@dotxix/models/interfaces/replace-receipt-text.interface';

@Injectable({
  providedIn: 'root',
})
export class AppReceiptCompilerService {
  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private basketService: BasketService,
    private sessionService: SessionService,
    private translationService: TranslationsService
  ) {}

  public async compile(settings: PrinterSettings, receiptContext: Receipt): Promise<string> {
    if (receiptContext.printTicketOption === PrintTicketOption.NONE && !this.hasImportantMessagesForCustomer(settings, receiptContext)) {
      return '';
    }
    const templatesDirPath = '/Channels/receipt-templates';
    const rootTemplateName = this.getRootTemplateName(settings, receiptContext);

    const maxCharactersPerLine = this.applicationSettingsService.settings$.value.printerMaxCharsPerRow;
    const languageCode = this.applicationSettingsService.settings$.value.defaultLanguage;
    const orderProducts: OrderProduct[] = this.mapDotButtonsToReceiptOrderProducts(
      this.basketService.buttons,
      languageCode,
      this.sessionService.serviceType
    );
    const languageIsRTL = this.translationService.defaultLanguageIsRtl$.value;

    const currencyDecimals = this.applicationSettingsService.settings$.value.currencyDecimals;
    const currencySymbol = this.applicationSettingsService.settings$.value.currencySymbol;
    const currencySymbolBefore = this.applicationSettingsService.settings$.value.currencySymbolBefore;
    const decimalSeparator = this.applicationSettingsService.settings$.value.decimalSeparator;

    const compileResult = await DotReceiptCompiler.compileTemplate(
      templatesDirPath,
      rootTemplateName,
      {
        maxCharactersPerLine,
        orderProducts,
        languageCode,
        languageIsRTL,
        currencyDecimals,
        currencySymbol,
        currencySymbolBefore,
        decimalSeparator,
      },
      receiptContext
    );
    if (compileResult.success) {
      return this.replaceTextOnReceiptAsDefinedInBundleSettings(compileResult.result);
    } else {
      appLogger.error(`Receipt compiler failure: ${compileResult.error}`);
      return '';
    }
  }

  private getRootTemplateName(settings: PrinterSettings, receiptContext: Receipt): string {
    if (settings.printTicketOption === PrintTicketOption.PAY_TOWER) {
      return 'pay-tower';
    }
    if (this.hasImportantMessagesForCustomer(settings, receiptContext)) {
      return 'default';
    }

    switch (receiptContext.printTicketOption) {
      case PrintTicketOption.ORDER_TICKET:
        return 'order-only';
      case PrintTicketOption.SMALL:
        return 'small';
      case PrintTicketOption.CREDIT_CARD_TICKET:
        return 'only-credit-card';
      default:
        return 'default';
    }
  }

  private hasImportantMessagesForCustomer(settings: PrinterSettings, receiptContext: Receipt) {
    return (
      settings.orderCompletelyPaidButNotIntegratedIntoPos ||
      receiptContext.orderNotPaidWillPayAtCounter ||
      receiptContext.totalCashFailedToRefund !== 0 ||
      receiptContext.cashBackFailedToRefundAmount !== 0
    );
  }

  private replaceTextOnReceiptAsDefinedInBundleSettings(content: string) {
    this.applicationSettingsService.settings$.value.replaceTextOnReceipt?.forEach((qrCode) => {
      const qrCodeOldValue = qrCode?.input;
      if (qrCodeOldValue?.trim()?.length > 0) {
        content = content.replaceAll(
          qrCodeOldValue,
          qrCode.type === ReplaceTextType.IMAGE ? `<@Image>assets/images/${qrCode.value}<@ImageEnd>` : qrCode.value
        );
      }
    });
    return content;
  }

  private isButtonVisibleToPrinter(button: DotButton): boolean {
    if (!button) {
      return false;
    }
    if (typeof button.Visibility === 'string') {
      const visibilityNumber = Number(button.Visibility);
      if (Number.isInteger(visibilityNumber)) {
        return !((visibilityNumber & 16) === 16);
      }
    }
    return true;
  }

  private getName(button: DotButton, languageCode: string): string {
    let productSName = this.translationService.translateTitleInLang(button.CaptionDictionary || button.Caption || '', languageCode);
    if (productSName && productSName.indexOf('|') > -1) {
      productSName = productSName.substring(0, productSName.indexOf('|'));
    }
    return productSName;
  }

  private getMenuBasePrice(comboPage: DotComboPage, serviceType: PosServingLocation): number | undefined {
    for (let i = 0; i < comboPage.Combos.length; i++) {
      for (let j = 0; j < comboPage.Combos[i].Buttons.length; j++) {
        const currentButton = comboPage.Combos[i].Buttons[j];
        if (currentButton.Selected && !this.isButtonVisibleToPrinter(currentButton)) {
          // menu selected price keeper satisfies the following condition: buton has visibility 255 and is selected
          return calculateButtonPrice(currentButton, serviceType);
        }
      }
    }
    return undefined;
  }

  private splitButtonsByChargeThreshold(dotButtons:DotButton[]): DotButton[] {
    const buttonsBellowChargeThreshold: DotButton[] = [];
    const buttonsAboveChargeThreshold: DotButton[] = [];

    dotButtons.forEach((dotButton) => {
      if(dotButton.quantity > 0 && typeof dotButton.ChargeThreshold === 'number' && dotButton.ChargeThreshold > 0) {
        const quantumBelowThreshold = Math.min(dotButton.quantity, dotButton.ChargeThreshold )
        const quantumAboveThreshold = Math.max(0, dotButton.quantity - dotButton.ChargeThreshold)
        if(quantumBelowThreshold > 0){
          buttonsBellowChargeThreshold.push(new DotButton({...dotButton, ...{quantity: quantumBelowThreshold, Price: 0, Prices: [] }}))
        }
        if(quantumAboveThreshold > 0){
          buttonsAboveChargeThreshold.push(new DotButton({...dotButton, ...{quantity: quantumAboveThreshold}}))
        }
      } else {
        buttonsBellowChargeThreshold.push(dotButton)
      }
    })
    return [...buttonsBellowChargeThreshold, ...buttonsAboveChargeThreshold]
  }

  private mapDotButtonsToReceiptOrderProducts(
    dotButtons: DotButton[],
    languageCode: string,
    serviceType: PosServingLocation
  ): OrderProduct[] {
    

    return this.splitButtonsByChargeThreshold(dotButtons).map((dotButton) => {
      
      let basePrice;
      let orderProductChildren: OrderProduct[] = [];
      if (dotButton.hasModifiers) {
        const strippedButton = _.cloneDeep(dotButton) as DotButton;
        strippedButton.ModifiersPage = new DotModifiersPage({ Modifiers: [] });

        basePrice = calculateButtonPrice(strippedButton, serviceType);

        dotButton.activeModifiers.forEach((x) => {
          orderProductChildren = [
            ...orderProductChildren,
            ...this.mapDotButtonsToReceiptOrderProducts(x.selectedButtons, languageCode, serviceType),
          ];
        });
      } else if (dotButton.hasCombos) {
        basePrice = this.getMenuBasePrice(dotButton.ComboPage, serviceType) || 0;

        dotButton.ComboPage.Combos.forEach((combo) => {
          const printableComboComponentButtons = combo.Buttons.filter((btn) => btn.Selected && this.isButtonVisibleToPrinter(btn));
          orderProductChildren = [
            ...orderProductChildren,
            ...this.mapDotButtonsToReceiptOrderProducts(printableComboComponentButtons, languageCode, serviceType),
          ];
        });
      } else {
        basePrice = calculateButtonPrice(dotButton, serviceType);
      }
      return {
        caption: this.getName(dotButton, languageCode),
        quantity: dotButton.quantity,
        priceCentimes: basePrice,
        children: orderProductChildren,
      };
    });
  }
}
