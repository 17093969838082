import { DotAvailabilityService, DotButton, DotButtonType, getCatalogButton, getCombosCatalogButton } from 'dotsdk';
import { OsirisButton } from '@dotxix/osiris/models/types/osiris-button.type';

export function extractOrderFromOsiris(buttons: OsirisButton[], orderId: string): DotButton[] {
  const buttonsResult: DotButton[] = [];
  for (const button of buttons) {
    let basketButton: DotButton | null =
      button.ButtonType === DotButtonType.MENU_BUTTON ? getCombosCatalogButton(button.Link) : getCatalogButton(button.Link);
    if (!basketButton) {
      return [];
    } else {
      basketButton.OriginalAUID = orderId;
      basketButton.quantity = button.quantity;
      basketButton.Selected = true;
      basketButton.isPromo = !!button.isPromo;
      basketButton.promoId = button.promoId || null;
      basketButton.posDiscountId = button.posDiscountId || false;
    }
    basketButton =
      button.ButtonType === DotButtonType.MENU_BUTTON
        ? getValidatedComboButton(basketButton, button)
        : getValidatedButton(basketButton, button);
    if (!basketButton) {
      return [];
    }
    buttonsResult.push(basketButton);
  }
  return buttonsResult;
}

function getValidatedButton(catalogButton: DotButton, osirisButton: OsirisButton): DotButton | null {
  if (!DotAvailabilityService.getInstance().isButtonAvailable(catalogButton)) {
    return null;
  }
  if (
    osirisButton.ModifiersPage &&
    osirisButton.ModifiersPage.Modifiers &&
    osirisButton.ModifiersPage.Modifiers.some((modifier) => modifier.Buttons)
  ) {
    if (!catalogButton.hasModifiers) {
      return null;
    }
    for (const modifier of osirisButton.ModifiersPage.Modifiers) {
      const catalogModifier = catalogButton.ModifiersPage.Modifiers.find(
        (modif) => modif.PageInfo.ModifierID === modifier.PageInfo.ModifierID
      );
      if (!catalogModifier) {
        return null;
      }
      for (const btn of modifier.Buttons) {
        const catalogModifierButton = catalogModifier.Buttons.find((b) => b.Link === btn.Link);
        if (!catalogModifierButton || !DotAvailabilityService.getInstance().isButtonAvailable(catalogModifierButton)) {
          return null;
        }
        catalogModifierButton.quantity = btn.quantity;
        catalogModifierButton.Selected = true;
      }
    }
  }
  return catalogButton;
}

function getValidatedComboButton(comboCatalogButton: DotButton, osirisButton: OsirisButton): DotButton | null {
  if (!DotAvailabilityService.getInstance().isButtonAvailable(comboCatalogButton)) {
    return null;
  }
  if (osirisButton.ComboPage && osirisButton.ComboPage.Combos && osirisButton.ComboPage.Combos.every((combo) => combo.Buttons)) {
    if (!comboCatalogButton.hasCombos) {
      return null;
    }
    for (const combo of osirisButton.ComboPage.Combos) {
      const catalogCombo = comboCatalogButton.ComboPage.Combos.find((cmb) => cmb.ComboStepID === combo.ComboStepID);
      if (!catalogCombo) {
        return null;
      }
      for (const btn of combo.Buttons) {
        const catalogComboButton = catalogCombo.Buttons.find((b) => b.Link === btn.Link && b.VisibleOn === osirisButton.StartSize);
        if (!catalogComboButton || !DotAvailabilityService.getInstance().isButtonAvailable(catalogComboButton)) {
          return null;
        }
        catalogComboButton.quantity = btn.quantity;
        catalogComboButton.Selected = true;
        if (btn.ModifiersPage && !getValidatedButton(catalogComboButton, btn)) {
          return null;
        }
      }
    }
  }
  return comboCatalogButton;
}
