import { Pipe, PipeTransform } from '@angular/core';
import { DotAllergen } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'translateAllergenLabel',
})
export class AllergenLabelTranslationPipe implements PipeTransform {
  constructor(protected translationsService: TranslationsService) {}

  public transform(allergen: DotAllergen): string | null {
    if (allergen && allergen.LabelDictionary && Object.keys(allergen.LabelDictionary).length !== 0) {
      const t = allergen.LabelDictionary[this.translationsService.currentLanguageUpperCase];
      return t ? t : allergen.LabelDictionary.DEF;
    } else if (allergen && allergen.Label) {
      return allergen.Label;
    } else {
      return null;
    }
  }
}
