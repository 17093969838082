<div class="flex gap--5 product-card-details">
  <span *ngIf="item.quantity > 0 && !hasDefaultQuantity">{{ item.quantity }}</span>
  <span *ngIf="item.quantity > 0 && !hasDefaultQuantity">x</span>
  <span *ngIf="prefix">{{ prefix | translatePrefixesName }}</span>
  <span *ngIf="item.quantity > 0 && !hasDefaultQuantity && !hasIceTags">{{ item | translateCaption }}</span>
  <button *ngIf="showChangeButton" class="product-card-detail__change-step-button" (click)="onCustomizeProduct()">
    {{ '18' | translate }}
  </button>
</div>
<span *ngIf="hasIceTags">{{ iceLabel | translate }}</span>
<h5 *ngIf="hasDefaultQuantity && prefixedModifierCaption">{{ prefixedModifierCaption }}</h5>
<div class="ml--20 mr--20 font--tertiary fs--20">
  {{ selectedModifiers }}
</div>
