<div class="variable">
  <div class="variable-square" #pickerTrigger [style.background]="cssVariableValue" (click)="toggleColorPicker()"></div>
  <div class="color-picker-container" *ngIf="showColorPicker" [class.positionAbove]="variableIndex > variablesListLength / 1.7">
    <!--    <acr-color-picker-wrapper-->
    <!--      (colorChange)="colorChange($event)"-->
    <!--      [color]="cssVariableValue"-->
    <!--      [triggerRef]="elementTrigger"-->
    <!--    ></acr-color-picker-wrapper>-->
  </div>
  <div class="variable-name">{{ prettifyCSSVariableName(cssVariableName) }}</div>
</div>
