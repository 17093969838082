import { appLogger } from '@dotxix/log-manager';

export const booleanOrDefault = (value: unknown, defaultValue: boolean): boolean => {
  return typeof value === 'boolean' ? value : defaultValue;
};
export const stringOrDefault = (value: unknown, defaultValue: string): string => {
  return typeof value === 'string' ? value : defaultValue;
};
export const numberOrDefault = (value: unknown, defaultValue: number): number => {
  return typeof value === 'number' ? value : defaultValue;
};
export const jsonOrDefault = <T>(value: unknown, defaultValue: T, parseErrorIdentifier: string): T => {
  try {
    return typeof value === 'string' && value !== '' ? (JSON.parse(value.replaceAll(`'`, '"')) as unknown as T) : defaultValue;
  } catch (e) {
    appLogger.error(`Error parsing bundle setting ${parseErrorIdentifier} as JSON: (${(e as SyntaxError).message})`);
    throw e;
  }
};
export const parseGloryPayableAmount = (value: unknown, defaultValue: number): number => {
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  return defaultValue;
};
