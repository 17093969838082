<acr-loading *ngIf="$sendingSms | async" [waitingMessage]="'2003006' | translate"></acr-loading>
<main class="page-container sms-receipt-page">
  <header class="page-header sms-receipt-page__header">
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
    <h2 class="sms-receipt-page__title">{{ '2003008' | translate }}</h2>
  </header>
  <section class="sms-receipt-page__container">
    <acr-keyboard
      [text]="phoneNumber"
      [keyboardLayout]="'numpad'"
      [limitNumberChars]="limitNumberChars"
      (inputChanged)="onSmsInputChanged($event)"
    ></acr-keyboard>
  </section>

  <acr-footer>
    <button
      left-slot
      class="ui-button ui-button--tertiary ui-button--small"
      (click)="backToReceiptOption()"
      [disabled]="$sendingSms | async"
    >
      {{ '9' | translate }}
    </button>
    <button right-slot class="ui-button ui-button--secondary ui-button--small" (click)="sendEmail()" [disabled]="phoneNumber.trim() === ''">
      {{ '71' | translate }}
    </button>
  </acr-footer>
</main>
