import { Component } from '@angular/core';
import { RecallOrderStatus } from '@dotxix/osiris/models/enums/recall-order-status.enum';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';

@Component({
  selector: 'acr-osiris-process-order',
  templateUrl: './osiris-process-order.component.html',
})
export class OsirisProcessOrderComponent {
  public recallOrderStatus = RecallOrderStatus;
  public processOrderError$ = this.osirisStateService.processOrderError$;

  constructor(private osirisStateService: OsirisStateService) {}
}
