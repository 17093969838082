import { Component, Input } from '@angular/core';

@Component({
  selector: 'acr-bullet-steps',
  template: `
    <div class="container">
      <div class="bullet" [class.active]="activeStep === i + 1" *ngFor="let _ of [].constructor(numberOfSteps); let i = index">
        {{ i + 1 }}
      </div>
    </div>
  `,
  styles: [
    `
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-width: 50%;
        margin: 0 auto;
      }

      .bullet {
        font-size: 50rem;
        height: 75rem;
        width: 75rem;
        border-radius: 50%;
        background-color: var(--primary);
        color: var(--secondary);
        line-height: calc(75 / 50);
        text-align: center;
        transition: all 250ms ease-in-out;
      }

      .bullet.active {
        background-color: var(--accent);
        color: var(--primary);
      }
    `,
  ],
})
export class BulletStepsComponent {
  @Input() public activeStep!: number;
  @Input() public numberOfSteps = 3;
}
