import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { map, take } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { AppBIService } from '../../business-intelligence/bi.service';
import { Language } from '../../models/interfaces/language';

@Component({
  selector: 'acr-languages',
  templateUrl: './languages.component.html',
  host: { class: 'align-self-center' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesComponent implements OnInit, OnDestroy {
  public languages$ = combineLatest([
    this.appSettings.settings$.pipe(map((bs) => bs.languages)),
    this.translationsService.currentLanguageCode$,
  ]).pipe(map(([languages, activeCode]) => languages.map((l) => (l.code === activeCode ? { ...l, active: true } : l))));
  private subscriptions: Array<Subscription> = [];

  constructor(
    private appSettings: ApplicationSettingsService,
    private translationsService: TranslationsService,
    private appBIService: AppBIService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.analyticsViewLanguageOptions();
    this.subscriptions.push(this.translationsService.dictionaryReloaded.subscribe(() => this.changeDetector.markForCheck()));
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onLanguageButtonClicked(languageCode: string) {
    this.appBIService.actions.clicks.selectedLanguage(languageCode);
    this.translationsService.setCurrentLanguage(languageCode);
  }
  private analyticsViewLanguageOptions() {
    this.languages$
      .pipe(
        take(1),
        map((languages: Language[]) => languages.map((language) => language.code))
      )
      .subscribe((languages) => this.appBIService.actions.impressions.languageOptions(languages));
  }
}
