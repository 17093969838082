import { BaseModel } from 'dotsdk';

export class CssVariableTable extends BaseModel {
  // generalTheming: CssVariables;
  // specificTheming: CssVariables;
  // generatedTheming: {
  //   [key: string]: CssVariables;
  // };
  // logoImage: CustomLogoVariable;
  // constructor(input?: any) {
  //   super(input);
  // }
}
