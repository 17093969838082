import { PosElogHandler, PosPaidState, PosTenderType } from 'dotsdk';

export const getAmountPaidWithCash = (): number => {
  const tenders = PosElogHandler.getInstance().posConfig.posHeader.amounts?.tenders;
  if (!Array.isArray(tenders)) {
    return 0;
  }
  return tenders.reduce(
    (sum, tender) => sum + (tender.type === PosTenderType.CASH && tender.paid === PosPaidState.PAID ? tender.paidAmount : 0),
    0
  );
};

export const getElogPaidAmount = (): number => {
  const tenders = PosElogHandler.getInstance().posConfig.posHeader.amounts?.tenders;
  if (!Array.isArray(tenders)) {
    return 0;
  }
  return tenders.reduce((sum, tender) => sum + (tender.paid === PosPaidState.PAID ? tender.paidAmount : 0), 0);
};
