import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Skin } from '@dotxix/skin/skin.interface';

@Injectable({
  providedIn: 'root',
})
export class SkinProviderService {
  constructor(private httpClient: HttpClient) {}
  public async getSkin(): Promise<Skin> {
    return await firstValueFrom(this.httpClient.get<Skin>('/Channels/skin/skin.json')).catch(() => this.emptySkin());
  }

  private emptySkin() {
    return {
      global: {},
      fonts: {},
      sections: {},
    };
  }
}
