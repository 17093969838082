import { calculateButtonPrice, DotButton, DotModifier, DotSessionService } from 'dotsdk';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { isItemPackButton } from '@dotxix/helpers/button.helper';
import * as _ from 'lodash';
import { initializeProductModifiersQuantities } from '@dotxix/helpers/modifiers.helper';

export const initializeModifierStepperButton = (button: DotButton<ButtonState>) => {
  initializeModifierStepperQuantities(button);

  button.Selected = true;
  button.quantity = button.quantity || 1;
};

export const initializeModifierStepperQuantities = (button: DotButton) => {
  if (button.hasModifiers) {
    button.ModifiersPage.Modifiers.forEach((modifier) => {
      modifier.Buttons.forEach((modifierComponent) => initializeProductModifiersQuantities(modifierComponent));
    });
  }
};

export const getSelectedModifierStepComponent = (modifier: DotModifier): DotButton | undefined => {
  const selectedButton = modifier.Buttons.find((btn) => btn.Selected);
  if (selectedButton) {
    return selectedButton;
  }

  const itemPackButtons = modifier.Buttons.filter((button) => isItemPackButton(button));
  const buttonsLength = itemPackButtons.length;
  for (let index = 0; index < buttonsLength; index++) {
    for (const itemPackButton of itemPackButtons[index].Page.Buttons) {
      if (itemPackButton.Selected) {
        return itemPackButton;
      }
    }
  }
  return undefined;
};

export const computeModifierStepComponentsMinPrice = (buttons: DotButton[]): number =>
  buttons.reduce((currentMinPrice, btn) => {
    if (isItemPackButton(btn)) {
      const itemPackMinPrice = computeModifierStepComponentsMinPrice(btn.Page.Buttons);
      return currentMinPrice > itemPackMinPrice ? itemPackMinPrice : currentMinPrice;
    } else {
      const buttonPrice = calculateButtonPrice(btn, DotSessionService.getInstance().currentPosServingLocation);
      return currentMinPrice > buttonPrice ? buttonPrice : currentMinPrice;
    }
  }, Number.MAX_SAFE_INTEGER);

export const multiplyModifiersWithMinQuantity = (modifiers: DotModifier[]) => {
  const resultSteps = [] as DotModifier[];
  modifiers.forEach((modifier) => {
    if (modifier.PageInfo.MinQuantity > 1 && modifier.PageInfo.MinQuantity > modifiers.length) {
      for (let cloneIndex = 0; cloneIndex < modifier.PageInfo.MinQuantity; cloneIndex++) {
        resultSteps.push(cloneModifierStep(modifier, cloneIndex));
      }
    } else {
      resultSteps.push(modifier);
    }
  });
  return resultSteps;
};

export const cloneModifierStep = (modifier: DotModifier, targetCloneIndex: number) => {
  const clonedModifierStep = _.cloneDeep(modifier);
  clonedModifierStep.Buttons.forEach((button) => {
    button.quantity = 0;
    button.Selected = false;
  });
  let cloneIndex = -1;
  modifier.Buttons.forEach((button, buttonIndexInModifier) => {
    if (button.Selected) {
      for (let quantityIndex = 0; quantityIndex < button.quantity; quantityIndex++) {
        cloneIndex++;
        if (targetCloneIndex === cloneIndex) {
          clonedModifierStep.Buttons[buttonIndexInModifier].quantity = 1;
          clonedModifierStep.Buttons[buttonIndexInModifier].Selected = true;
        }
      }
    }
  });
  return clonedModifierStep;
};

export const deselectComponent = (component: DotButton) => {
  component.quantity = 0;
  component.Selected = false;
};

export const deselectModifierStepComponent = (components: DotButton[]) => {
  components.forEach((component) => {
    deselectComponent(component);
    component.Page?.Buttons?.forEach((itemPackButton) => deselectComponent(itemPackButton));
    component.ModifiersPage?.Modifiers?.forEach((modifier) => {
      modifier.Buttons?.forEach((itemPackButton) => deselectComponent(itemPackButton));
    });
  });
};
