import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';

import { DotButton } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { SessionService } from '@dotxix/services/session.service';
import { price } from '@dotxix/helpers/price.helper';

@Component({
  selector: 'acr-dropdown',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'dropdown' },
})
export class DropdownComponent implements AfterViewInit {
  @Input() public set buttons(buttons: DotButton[]) {
    this.displayButtons = buttons;
    this.scrollIntoView();
  }
  @Output() public dropdownButtonClick: EventEmitter<DotButton> = new EventEmitter();

  public displayButtons: DotButton[] = [];
  private scrollBehaviour: ScrollBehavior = 'smooth';

  constructor(
    protected sessionService: SessionService,
    protected elementRef: ElementRef<HTMLElement>,
    protected zone: NgZone,
    protected appSettings: ApplicationSettingsService
  ) {}

  public ngAfterViewInit() {
    this.scrollIntoView();
  }

  public getPrice(button: DotButton): number {
    return price(button, this.sessionService.serviceType);
  }

  public select(button: DotButton) {
    this.dropdownButtonClick.emit(button);
  }

  private scrollIntoView() {
    setTimeout(() => {
      this.elementRef?.nativeElement?.scrollIntoView({ behavior: this.scrollBehaviour, block: 'end' });
    }, 0);
  }
}
