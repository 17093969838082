import { Component } from '@angular/core';
import { OsirisLoginFaceRecallService } from '@dotxix/osiris/services/osiris-login-face-recall.service';

@Component({
  selector: 'acr-customer-login',
  host: { class: 'full-height full-width customer-login' },
  template: `
    <div class="container">
      <div class="messages">
        <p class="message" [innerHTML]="'20220426001' | translate"></p>
      </div>
      <div class="actions smaller customer-login__actions">
        <button class="ui-button ui-button--secondary ui-button--x-large" (click)="onLoginClicked()">{{ '2022103104' | translate }}</button>
        <button class="ui-button ui-button--primary ui-button--x-large color--secondary" (click)="onCancelClicked()">
          {{ '9' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['../face-recall-common.scss'],
})
export class CustomerLoginComponent {
  constructor(private osirisLoginFaceRecallService: OsirisLoginFaceRecallService) {}

  public onCancelClicked() {
    this.osirisLoginFaceRecallService.cancelFaceRecallLogin();
  }

  public onLoginClicked() {
    this.osirisLoginFaceRecallService.customerSelectedExistingAccount();
  }
}
