import { Pipe, PipeTransform } from '@angular/core';
import { calculateButtonPrice, DotButton, PosElogHandler } from 'dotsdk';

@Pipe({
  name: 'calculateButtonPrice',
})
export class CalculateButtonPricePipe implements PipeTransform {
  public transform(button: DotButton) {
    return calculateButtonPrice(button, PosElogHandler.getInstance().posConfig.posHeader.servingLocation);
  }
}
