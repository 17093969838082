<div class="popup confirm-dialog" [ngStyle]="{ 'z-index': dataParams?.alwaysOnTop && 999 }" data-automation="confirm-dialog-popup">
  <div class="popup__content confirm-dialog__content">
    <h3 class="popup__title confirm-dialog__title" [innerHTML]="dataParams!.title" data-automation="Confirm Dialog Title"></h3>
    <div class="popup__actions confirm-dialog__actions">
      <button
        class="ui-button ui-button--secondary confirm-dialog__actions--button"
        (click)="onControlsButtonsClick(ConfirmDialogButtons.LEFT_BUTTON)"
        [attr.data-automation]="dataParams!.leftButtonText"
      >
        <span class="ui-button__wrapper">
          <span>{{ dataParams!.leftButtonText }}</span>
        </span>
      </button>

      <button
        class="ui-button ui-button--tertiary confirm-dialog__actions--button"
        (click)="onControlsButtonsClick(ConfirmDialogButtons.RIGHT_BUTTON)"
        [attr.data-automation]="dataParams!.rightButtonText"
      >
        <span class="ui-button__wrapper">
          <span>{{ dataParams!.rightButtonText }}</span>
        </span>
      </button>
    </div>
  </div>
</div>
