<main class="justify-evenly align-center page-container table-service-selection">
  <header class="page-header">
    <h2 class="table-service-selection__title" acrScaleTextToFitContainer>{{ '79' | translate }}</h2>
  </header>
  <section class="flex flex--column flex--1 justify-evenly align-center page-content">
    <picture class="circle circle--medium">
      <img class="p--40 ui-button--icon color--accent size--260" alt="" src="/Channels/skin/icon-tableServiceFlag-table-service.svg" />
    </picture>
    <div class="flex flex--column place-center justify-center">
      <h2 class="text-center">{{ '76' | translate }}</h2>
      <acr-keyboard (inputChanged)="updateTableServiceNumber($event)" [keyboardLayout]="'numeric'" [useRange]="true"></acr-keyboard>
    </div>
  </section>
  <footer class="flex justify-center place-center table-service-selection__footer">
    <button
      *ngIf="hideOnMandatoryTSS"
      class="ui-button ui-button--primary tertiary ui-button--small"
      (click)="cancel()"
      data-automation="Cancel Table Service Button"
    >
      <span class="ui-button__wrapper">
        <span>{{ '23' | translate }}</span>
      </span>
    </button>
    <div class="spacer--40"></div>
    <button
      class="ui-button ui-button--secondary ui-button--small"
      (click)="confirmTableServiceNumber()"
      data-automation="Confirm Table Service Button"
      [disabled]="disableConfirmButton"
    >
      <span class="ui-button__wrapper">
        <span>{{ '71' | translate }}</span>
      </span>
    </button>
  </footer>
</main>
