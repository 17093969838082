import { Pipe, PipeTransform } from '@angular/core';

import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(private translationsService: TranslationsService) {}

  public transform(value: string): string {
    return this.translationsService.translate(value);
  }
}
