import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { Subscription } from 'rxjs';
import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';
import { GeneratedThemeStateService } from '@dotxix/custom-theme/services/generated-theme-state.service';
import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';

@Directive({ selector: '[theme-editor]' }) // eslint-disable-line
export class ThemeEditorDirective implements OnInit, OnDestroy {
  @Input('theme-editor') public uniqueId!: string;

  private listener!: () => void;
  private subscription!: Subscription;
  // private isEditorEnabled = this.appSettings.settings$.value.enableThemeEditor;
  constructor(
    private ref: ElementRef,
    private renderer: Renderer2,
    private themeEditorStateService: ThemeEditorStateService,
    private generatedThemeStateService: GeneratedThemeStateService,
    private appSettings: ApplicationSettingsService
  ) {}

  ngOnInit(): void {
    const identifiedGeneratedVariables = this.generatedThemeStateService.generatedThemState$.value[this.uniqueId];
    if (identifiedGeneratedVariables) {
      this.setInlineStyleToHost(this.ref, this.renderer, identifiedGeneratedVariables);
    }
  }

  // ngAfterViewInit(): void {
  // if (this.isEditorEnabled) {
  //   this.subscription = this.generatedThemeStateService.generatedVariableUpdated$.subscribe(() => {
  //     const identifiedGeneratedVariables = this.generatedThemeStateService.generatedThemState$.value[this.uniqueId];
  //     this.setInlineStyleToHost(this.ref, this.renderer, identifiedGeneratedVariables);
  //   });
  //   const clone: Node = this.ref.nativeElement.cloneNode(true);
  //
  //   this.initializeHostElement(this.ref, this.renderer);
  //   const editTagElement = this.createEditorEditTagElement(this.ref, this.renderer);
  //
  //   this.listener = this.renderer.listen(editTagElement, 'click', () => {
  //     this.themeEditorStateService.setEditElementClone(clone);
  //     this.themeEditorStateService.setGeneratedThemeIdentifier(this.uniqueId);
  //     this.themeEditorStateService.toggleEditElementOverlay();
  //   });
  // }
  // }
  ngOnDestroy(): void {
    this.renderer.removeStyle(this.ref.nativeElement, 'position');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.listener) {
      this.listener();
    }
  }

  private initializeHostElement(host: ElementRef, renderer: Renderer2) {
    const elementPosition = getComputedStyle(host.nativeElement).getPropertyValue('position');
    if (elementPosition !== 'absolute') {
      renderer.setStyle(host.nativeElement, 'position', 'relative');
    }
  }

  private createEditorEditTagElement(host: ElementRef, renderer: Renderer2): HTMLDivElement {
    const element: HTMLDivElement = renderer.createElement('div');
    renderer.addClass(element, 'editor-edit-tag');
    const text = renderer.createText('edit');
    renderer.appendChild(element, text);
    renderer.appendChild(host.nativeElement, element);
    return element;
  }

  private setInlineStyleToHost(host: ElementRef, renderer: Renderer2, styles: CssVariables) {
    if (host && styles && renderer) {
      Object.keys(styles).forEach((key) => {
        renderer.setStyle(host.nativeElement, key, styles[key], RendererStyleFlags2.DashCase);
      });
    }
  }
}
