export enum GloryCashPaymentView {
  PAYMENT_PROGRESS = 'PAYMENT_PROGRESS',
  PAYMENT_PROGRESS_ERROR = 'PAYMENT_PROGRESS_ERROR',
  PAYMENT_PROGRESS_ERROR_PRINT = 'PAYMENT_PROGRESS_ERROR_PRINT',
  PAYMENT_OVERPAY_REFUND_PROGRESS = 'PAYMENT_OVERPAY_REFUND_PROGRESS',
  PAYMENT_OVERPAY_REFUND_FAILED = 'PAYMENT_OVERPAY_REFUND_FAILED',
  PAYMENT_OVERPAY_REFUND_SUCCESS = 'PAYMENT_OVERPAY_REFUND_SUCCESS',
  PAYMENT_CANCEL_TRANSACTION_REFUND_PROGRESS = 'PAYMENT_CANCEL_TRANSACTION_REFUND_PROGRESS',
  PAYMENT_CANCEL_TRANSACTION_REFUND_SUCCESS = 'PAYMENT_CANCEL_TRANSACTION_REFUND_SUCCESS',
  CASHBACK_PROGRESS = 'CASHBACK_PROGRESS',
  CASHBACK_SUCCESS = 'CASHBACK_SUCCESS',
  CASHBACK_FAILED = 'CASHBACK_FAILED',
}
