<div class="popup edit-single-combo-step" data-automation="edit-single-combo-step-popup">
  <div class="popup__content edit-single-combo-step__content">
    <main class="flex flex--column full-height popup__page-container edit-single-combo-step__page-container">
      <acr-product-stepper-header
        [productTitle]="comboTitle"
        [productSubTitle]="comboSubTitle"
        [images]="headerImages"
      ></acr-product-stepper-header>

      <section class="edit-single-combo-step__top-menu">
        <button
          *ngIf="displayBackButton"
          class="ui-button ui-button--primary bg--tertiary ui-button--tiny ui-button--iconLeft"
          data-automation="Combo Back Button"
          (click)="onBackButtonClicked()"
        >
          <span class="ui-button__wrapper">
            <img skin-part="image" src="/Channels/skin/icon-chevronLeft-back.svg" class="size--20" alt="" acrRevertOnRtl />
            <span>{{ '9' | translate }}</span>
          </span>
        </button>
        <h4 *ngIf="currentComboStepState === ComboStepState.CHOOSE_COMPONENT" class="edit-single-combo-step__top-menu--title">
          {{ currentComboStep | translateComboStepTitle }}
        </h4>
      </section>

      <acr-scrollable-container [scrollToTop]="scrollToTop$">
        <ng-container *ngIf="currentComboStepState === ComboStepState.CHOOSE_COMPONENT">
          <div class="modifiers-grid">
            <acr-button-item
              *ngFor="let comboStepComponent of currentComboStepComponents; let index = index"
              [button]="comboStepComponent"
              [selected]="isComboStepComponentSelected(comboStepComponent)"
              [displayPrice]="computeComponentDisplayPrice(comboStepComponent) | dotCurrency"
              [showCounterEdit]="false"
              (buttonClicked)="onComponentButtonClicked(comboStepComponent)"
            ></acr-button-item>
          </div>
        </ng-container>

        <ng-container *ngIf="currentComboStepState === ComboStepState.SUBGROUP">
          <div class="modifiers-grid">
            <acr-button-item
              *ngFor="let itemPackButton of itemPackVisibleComponents"
              [button]="itemPackButton"
              [selected]="itemPackButton.Selected"
              [displayPrice]="computeComponentDisplayPrice(itemPackButton) | dotCurrency"
              [showCounterEdit]="false"
              (buttonClicked)="onItemPackComponentButtonClicked(itemPackButton)"
            ></acr-button-item>
          </div>
        </ng-container>

        <ng-container *ngIf="currentComboStepState === ComboStepState.CUSTOMIZE">
          <ng-container *ngFor="let comboButtonModifier of currentComboStepComponentFeatures">
            <acr-button-modifier-list
              [modifier]="comboButtonModifier"
              [isCombo]="true"
              [onOrder]="!dataParams!.isUpdate"
              (modifierChanged)="currentComboStepComponentFeaturesChanged()"
            ></acr-button-modifier-list>
          </ng-container>
        </ng-container>
      </acr-scrollable-container>

      <footer class="popup__actions edit-single-combo-step__actions">
        <button class="ui-button ui-button--primary ui-button--small" (click)="cancelClick()" data-automation="Cancel Button">
          <span class="ui-button__wrapper">
            <span>{{ '23' | translate }}</span>
          </span>
        </button>

        <button
          [disabled]="disableConfirmButton"
          class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
          (click)="onConfirmClicked()"
          data-automation="Confirm Button"
        >
          <span class="ui-button__wrapper">
            <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
            <span class="fs--26">{{ '27' | translate }}</span>
          </span>
        </button>
      </footer>
    </main>
  </div>
</div>
