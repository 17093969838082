import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { GloryCashFlowService } from '@dotxix/payment/services/glory-cash-flow.service';
import { AppBIService } from '../../../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-glory-cash-payment-progress-error',
  templateUrl: './glory-cash-payment-progress-error.component.html',
  host: { class: 'flex flex--column flex--1' },
})
export class GloryCashPaymentProgressErrorComponent implements OnInit {
  public paymentServiceState$ = this.paymentService.state$;

  constructor(
    private paymentService: PaymentService,
    private gloryCashFlowService: GloryCashFlowService,
    private appBiService: AppBIService
  ) {}
  ngOnInit(): void {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CASH_PAYMENT_PROGRESS_ERROR);
  }

  public onPrintReceiptClicked() {
    this.gloryCashFlowService.printCashPaymentErrorReceipt();
  }
}
