import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ColorPickerModule as ipColorPicker } from '@iplab/ngx-color-picker';
import { AccordeonComponent } from '@dotxix/custom-theme/components/accorderon/accordeon.component';
import { ResetButtonComponent } from '@dotxix/custom-theme/components/action-buttons/reset-button/reset-button.component';
import { SaveButtonComponent } from '@dotxix/custom-theme/components/action-buttons/save-button/save-button.component';
import { ThemeToggleButtonComponent } from '@dotxix/custom-theme/components/action-buttons/theme-toggle-button/theme-toggle-button.component';
import { ColorPickerWrapperComponent } from '@dotxix/custom-theme/components/color-picker-wrapper/color-picker-wrapper.component';
import { EditElementOverlayComponent } from '@dotxix/custom-theme/components/edit-element-overlay/edit-element-overlay.component';
import { EditVariableComponent } from '@dotxix/custom-theme/components/edit-variable/edit-variable.component';
import { ThemeEditorComponent } from '@dotxix/custom-theme/components/theme-editor/theme-editor.component';
import { UploadFileComponent } from '@dotxix/custom-theme/components/upload-file/upload-file.component';
import { VariableGroupsComponent } from '@dotxix/custom-theme/components/variable-groups/variable-groups.component';
import { ThemeEditorDirective } from '@dotxix/custom-theme/directives/theme-editor.directive';

@NgModule({
  imports: [
    CommonModule,
    // ipColorPicker,
    BrowserAnimationsModule,
  ],
  exports: [ThemeEditorComponent, ThemeEditorDirective],
  declarations: [
    ThemeEditorComponent,
    EditVariableComponent,
    SaveButtonComponent,
    ThemeEditorDirective,
    EditElementOverlayComponent,
    ResetButtonComponent,
    ThemeToggleButtonComponent,
    ColorPickerWrapperComponent,
    UploadFileComponent,
    AccordeonComponent,
    VariableGroupsComponent,
  ],
  providers: [],
})
export class CustomThemeModule {}
