import { Injectable } from '@angular/core';
import { PaymentType } from '@dotxix/models/interfaces/payment-type';
import { lastValueFrom, Subject, timer } from 'rxjs';
import { GloryCashPaymentService } from '@dotxix/payment/services/glory-cash-payment.service';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@dotxix/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogButtons } from '@dotxix/models/enums/confirm-dialog-buttons';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { CheckoutService } from '@dotxix/services/checkout.service';
import { DotSessionEndType } from 'dotsdk';
import { PaymentRoutes } from '@dotxix/payment/payment-routes';
import { PrintTicketOption } from '@dotxix/models/enums/print-ticket-option';

@Injectable({
  providedIn: 'root',
})
export class GloryCashFlowService {
  public displayPaymentSelection$ = new Subject<void>();
  public cashPaymentTransactionFinished$ = new Subject<void>();
  public cashPaymentStartAcceptMoneyError$ = new Subject<void>();
  private paymentState$ = this.paymentService.state$;

  constructor(
    private router: Router,
    private gloryCashPaymentService: GloryCashPaymentService,
    private paymentService: PaymentService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private checkoutService: CheckoutService
  ) {
    this.gloryCashPaymentService.paymentProgressError$.subscribe((errorCurrentPaidAmount: number) =>
      this.onPaymentProgressError(errorCurrentPaidAmount)
    );
    this.gloryCashPaymentService.currentPaidAmount$.subscribe(({ currentPaidAmount, paymentType }) =>
      this.onCurrentPaidAmountUpdated(currentPaidAmount, paymentType)
    );
  }

  public async startPayment(paymentType: PaymentType) {
    this.paymentService.onStartCashPayment(this.checkoutService.getAmountOwed());

    this.gloryCashPaymentService.startAcceptMoney(paymentType).then((acceptingMoney) => {
      if (acceptingMoney) {
        this.gloryCashPaymentService.startListeningPaymentProgress(paymentType);
      } else {
        this.cashPaymentStartAcceptMoneyError$.next();
      }
    });

    this.displayGloryPaymentPage();
  }

  public async cancelCurrentCashCurrentTransaction() {
    let cancelTransaction = true;
    if (this.paymentState$.value.cash.currentTransactionPaidAmount > 0) {
      cancelTransaction = await this.confirmCancelPayment();
    }
    if (cancelTransaction === false) {
      return;
    }

    this.paymentService.onRefundAfterCancelTransactionStarted();
    await this.gloryCashPaymentService.endAcceptMoney(0, this.paymentState$.value.cash.currentPaymentType!);

    if (this.checkoutService.getAmountOwed() > 0) {
      this.paymentService.onRefundAfterCancelTransactionSuccess();
      await lastValueFrom(timer(3000));
      this.displayPaymentSelection$.next();
    } else {
      this.cashPaymentTransactionFinished$.next();
    }
  }

  public async printCashPaymentErrorReceipt() {
    this.paymentService.onPrintingCashPaymentErrorReceipt();
    this.checkoutService.printReceipt({ printTicketOption: PrintTicketOption.FULL });

    await lastValueFrom(timer(3000));
    await this.checkoutService.voidOrderAndRestartSession(DotSessionEndType.CANCEL_ORDER);
  }

  public async finishWithOtherPayment() {
    await this.gloryCashPaymentService.endAcceptMoney(
      this.paymentState$.value.cash.currentTransactionPaidAmount,
      this.paymentState$.value.cash.currentPaymentType!
    );
    this.cashPaymentTransactionFinished$.next();
  }

  public async cashBack() {
    if (this.paymentService.state$.value.cash.cashPaidAmount === 0) {
      return;
    }

    this.displayGloryPaymentPage();
    await this.gloryCashPaymentService.cashBack();
  }

  private onPaymentProgressError(errorCurrentPaidAmount: number) {
    this.gloryCashPaymentService.removePaymentProgressSubscription();
    this.paymentService.onCashPaymentProgressError(errorCurrentPaidAmount);
  }

  private onCurrentPaidAmountUpdated(paidAmount: number, paymentType: PaymentType) {
    this.paymentService.onCashCurrentTransactionPaidAmountUpdated(paidAmount);
    if (paidAmount >= this.paymentState$.value.cash.maximumAmountThatCanBePaidAtTransactionStart) {
      this.endCashPaidTransaction(paymentType);
    }
  }

  private async endCashPaidTransaction(paymentType: PaymentType) {
    if (this.paymentState$.value.cash.currentTransactionNeedsToEndAcceptMoney) {
      const overPaid =
        this.paymentState$.value.cash.currentTransactionPaidAmount >
        this.paymentState$.value.cash.maximumAmountThatCanBePaidAtTransactionStart;

      if (overPaid) {
        this.paymentService.onRefundAfterOverpayStarted();
      }

      const amountToKeep = Math.min(
        this.paymentState$.value.cash.currentTransactionPaidAmount,
        this.paymentState$.value.cash.maximumAmountThatCanBePaidAtTransactionStart
      );
      const transactionDetails = await this.gloryCashPaymentService.endAcceptMoney(amountToKeep, paymentType);

      if (overPaid) {
        if (transactionDetails.failedToRefundAmount > 0) {
          this.paymentService.onRefundAfterOverpayFailed();
        } else {
          this.paymentService.onRefundAfterOverpaySuccess();
        }

        await lastValueFrom(timer(3000));
      }

      this.cashPaymentTransactionFinished$.next();
    }
  }

  private displayGloryPaymentPage() {
    this.router.navigate([PaymentRoutes.GloryPayment]);
  }

  private async confirmCancelPayment() {
    const response = await lastValueFrom(
      this.dynamicContentService.openContent(ConfirmDialogComponent, {
        title: this.translationsService.translate('2001009'), // do you really want to cancel your payment ?
        rightButtonText: this.translationsService.translate('33'),
        leftButtonText: this.translationsService.translate('32'),
      }).afterClosed
    );
    return response === ConfirmDialogButtons.RIGHT_BUTTON;
  }
}
