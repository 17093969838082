import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { AdaptabilityService } from '@dotxix/services/adaptability.service';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'acr-scrollable-container',
  templateUrl: './scrollable-container.component.html',
  host: { class: 'scrollable-container' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollableContainerComponent implements AfterViewInit, OnDestroy {
  @Input() public scrollToTop: Observable<void> | undefined;
  @Output() public scrollArrowClick: EventEmitter<void> = new EventEmitter();
  @ViewChild('scrollRef') public scrollRef: ElementRef | undefined;
  public enableButtons = false;
  public disableUpArrow = true;
  public disableDownArrow = true;
  private incrementHeight = 0;
  private debouncedEvaluateDisable: () => void = () => {};
  private subscriptions: Subscription[] = [];
  constructor(
    private adaptabilityService: AdaptabilityService,
    private appSettings: ApplicationSettingsService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.enableButtons = this.appSettings.settings$.value.touchlessMode;
    if (this.enableButtons) {
      this.debouncedEvaluateDisable = _.debounce(() => this.evaluateDisable(), 300, { leading: true, maxWait: 300, trailing: true });
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public ngAfterViewInit() {
    if (this.scrollToTop) {
      this.subscriptions.push(this.scrollToTop.subscribe(() => this.scrollContainerToTop()));
    }
    if (this.enableButtons) {
      this.subscriptions.push(
        this.adaptabilityService.isAdaEnabled$.subscribe(() => {
          this.onAdaUpdated();
        })
      );
      this.evaluateDisable();
      this.bindScrollRefNodeMutationsToDebouncedEvaluateDisable();
    }
  }

  public onScrollUp() {
    if (!this.scrollRef) {
      return;
    }
    this.setScroll(this.scrollRef.nativeElement.scrollTop - this.incrementHeight);
    this.scrollArrowClick.emit();
  }

  public onScrollDown() {
    if (!this.scrollRef) {
      return;
    }
    this.setScroll(this.scrollRef.nativeElement.scrollTop + this.incrementHeight);
    this.scrollArrowClick.emit();
  }

  public onScrolled() {
    this.debouncedEvaluateDisable();
  }

  private onAdaUpdated() {
    setTimeout(() => {
      this.updateIncrementHeight();
    }, 0);
    this.evaluateDisable();
  }

  private bindScrollRefNodeMutationsToDebouncedEvaluateDisable() {
    const mutationObserver = new MutationObserver(() => this.debouncedEvaluateDisable());
    mutationObserver.observe(this.scrollRef?.nativeElement, { childList: true, subtree: true });
  }

  private updateIncrementHeight() {
    if (!this.scrollRef) {
      return;
    }
    this.incrementHeight = this.scrollRef.nativeElement.clientHeight / (this.adaptabilityService.isAdaEnabled$.value ? 1.6 : 3);
  }

  private setScroll(scrollTop: number) {
    if (!this.scrollRef) {
      return;
    }
    this.scrollRef.nativeElement.scrollTo({ top: scrollTop, behavior: 'smooth' });
  }

  private evaluateDisable() {
    if (!this.scrollRef) {
      return;
    }
    const nativeElement = this.scrollRef.nativeElement;
    let disableUp: boolean;
    let disableDown: boolean;
    if (nativeElement.clientHeight === nativeElement.scrollHeight) {
      disableUp = true;
      disableDown = true;
    } else {
      disableUp = nativeElement.scrollTop === 0;
      disableDown = Math.ceil(nativeElement.scrollTop) + nativeElement.clientHeight >= nativeElement.scrollHeight;
    }
    if (this.disableUpArrow !== disableUp || this.disableDownArrow !== disableDown) {
      setTimeout(() => {
        this.disableUpArrow = disableUp;
        this.disableDownArrow = disableDown;
        this.changeDetector.detectChanges();
      }, 0);
    }
  }

  private scrollContainerToTop() {
    if (!this.scrollRef) {
      return;
    }
    this.scrollRef.nativeElement.scrollTo({ top: 0, left: 0, behaviour: 'auto' });
  }
}
