import { Injectable } from '@angular/core';
import { merge, Observable, Subscription, switchMap, take, timer } from 'rxjs';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { DynamicContentService } from '@dotxix/services/dynamic-content/dynamic-content.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { DynamicContentRef } from '@dotxix/services/dynamic-content/models/dynamic-content.ref';
import { InfoDialogComponent } from '@dotxix/components/info-dialog/info-dialog.component';
import { InfoDialogDataParams } from '@dotxix/models/interfaces/info-dialog-data-params';
import { filter, map } from 'rxjs/operators';
import { restartableTimer } from '@dotxix/helpers/rxjs.helper';
import { ReceiptTimeoutWarningResult } from '@dotxix/services/receipt/models/receipt-timeout-warning-result.enum';

@Injectable({
  providedIn: 'root',
})
export class ReceiptQuestionTimeoutService {
  private warningContentRef: DynamicContentRef<InfoDialogDataParams, string> | undefined;
  private timeoutTicketQuestionInMilliseconds!: number;

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService
  ) {
    this.applicationSettingsService.settings$.subscribe(
      (settings) => (this.timeoutTicketQuestionInMilliseconds = settings.timeoutTicketQuestion * 1000)
    );
  }

  public start(restartTrigger: Observable<void> | undefined = undefined) {
    return new Observable<void>((timeoutObserver) => {
      const [warningTimer, restart] = restartableTimer(this.timeoutTicketQuestionInMilliseconds);
      let restartTriggerSubscription: Subscription | undefined;
      if (restartTrigger) {
        restartTriggerSubscription = restartTrigger.subscribe(() => restart());
      }
      const warningTimerSubscription = warningTimer.pipe(switchMap(() => this.showTimeoutWarning())).subscribe((result) => {
        switch (result) {
          case ReceiptTimeoutWarningResult.RESTART:
            return restart();
          case ReceiptTimeoutWarningResult.TIMEOUT:
            if (restartTriggerSubscription) {
              restartTriggerSubscription.unsubscribe();
            }
            warningTimerSubscription.unsubscribe();
            timeoutObserver.next();
            timeoutObserver.complete();
            return;
        }
      });

      return () => {
        if (restartTriggerSubscription) {
          restartTriggerSubscription.unsubscribe();
        }
        warningTimerSubscription.unsubscribe();
        timeoutObserver.complete();
      };
    });
  }

  private showTimeoutWarning() {
    return new Observable<ReceiptTimeoutWarningResult>((observer) => {
      this.warningContentRef = this.dynamicContentService.openContent(InfoDialogComponent, {
        title: this.translationsService.translate('2021020901'),
        buttonText: this.translationsService.translate('28'),
      });

      const timeout = timer(this.timeoutTicketQuestionInMilliseconds).pipe(map(() => ReceiptTimeoutWarningResult.TIMEOUT));
      const userPressedContinue = this.warningContentRef.afterClosed.pipe(
        filter((result) => result === 'Ok'),
        map(() => ReceiptTimeoutWarningResult.RESTART)
      );
      const subscription = merge(timeout, userPressedContinue)
        .pipe(take(1))
        .subscribe((result) => {
          if (result === ReceiptTimeoutWarningResult.TIMEOUT && this.warningContentRef) {
            this.warningContentRef.close();
          }

          observer.next(result);
          observer.complete();
        });
      return () => {
        if (this.warningContentRef) {
          this.warningContentRef.close();
        }
        subscription.unsubscribe();
        observer.complete();
      };
    });
  }
}
