import { Component } from '@angular/core';
import { PayTowerAppFlowService } from '@dotxix/services/pay-tower-app-flow.service';

@Component({
  selector: 'acr-pay-tower-start-page',
  templateUrl: './pay-tower-start-page.component.html',
})
export class PayTowerStartPageComponent {
  public $errorMessage = this.payTowerAppFlowService.$errorMessage;
  public $readyToScan = this.payTowerAppFlowService.$readyToScan;
  constructor(private payTowerAppFlowService: PayTowerAppFlowService) {}
}
