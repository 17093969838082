import { Injectable } from '@angular/core';
import { AtpApplicationSettings, AtpEnvironmentService, DdsClient, DdsHeaders, DdsResponse } from 'dotsdk';
import { osirisLogger } from '@dotxix/log-manager';
import { GetLastOrdersOptions } from '@dotxix/osiris/models/interfaces/get-last-orders-options.interface';
import { GetLastOrdersResponseInterface } from '@dotxix/osiris/models/interfaces/get-last-orders-response.interface';
import { RecallOrderOptions } from '@dotxix/osiris/models/interfaces/recall-order-options.interface';
import { RecallOrderResponse } from '@dotxix/osiris/models/interfaces/recall-order-response.interface';
import { StoreOrdersOptions } from '@dotxix/osiris/models/interfaces/store-orders-options.interface';
import { OsirisButton } from '@dotxix/osiris/models/types/osiris-button.type';
import { OsirisHttpHandshakeService } from './osiris-http-handshake';

@Injectable({
  providedIn: 'root',
})
export class OsirisHttpService {
  private ddsClient = new DdsClient();

  constructor(private osirisHttpHandshakeService: OsirisHttpHandshakeService) {}

  private get bsOsirisBaseUrl(): string {
    const baseUrlBsName = 'osirisBaseUrl';
    const bundleSettings = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const baseUrl = bundleSettings.osirisBaseUrl;
    if (!baseUrl) {
      const msg = `Osiris mandatory Bundle Setting missing: ${baseUrlBsName}. Osiris calls will fail without it.`;
      osirisLogger.error(msg);
      throw new Error(msg);
    }
    return baseUrl;
  }

  private get bsOsirisOrdersCount(): string {
    const osirisOrdersCount = AtpApplicationSettings.getInstance().bundleSettingsJson.osirisOrdersCount;
    return osirisOrdersCount ? `?count=${osirisOrdersCount}` : '';
  }

  public async getLastOrders(options: GetLastOrdersOptions): Promise<GetLastOrdersResponseInterface> {
    const fullUrl = `${this.bsOsirisBaseUrl}/api/v1/kiosk/getLastOrders/${options.customerID}${this.bsOsirisOrdersCount}`;

    let responseObject: DdsResponse;
    try {
      responseObject = await this.ddsClient.get(fullUrl, {
        headers: await this.buildHeaders(),
      });
    } catch (errorResponse: unknown) {
      return await this.logHttpErrorMessageAndReject(errorResponse as XMLHttpRequest, fullUrl);
    }
    let responseBody: GetLastOrdersResponseInterface;
    try {
      responseBody = JSON.parse(responseObject?.body);
    } catch (e: unknown) {
      return await this.logGenericErrorMessageAndReject(
        new Error(`Error while parsing response from ${fullUrl}. Error: ${(e as SyntaxError)?.message}`)
      );
    }
    return responseBody;
  }

  public async storeOrders(options: StoreOrdersOptions): Promise<unknown> {
    const fullUrl = `${this.bsOsirisBaseUrl}/api/v1/kiosk/storeOrder`;

    let responseObject: DdsResponse;
    try {
      responseObject = await this.ddsClient.post(fullUrl, options.StoreOrdersPayload, {
        headers: await this.buildHeaders(),
      });
    } catch (errorResponse: unknown) {
      return await this.logHttpErrorMessageAndReject(errorResponse as XMLHttpRequest, fullUrl);
    }
    let responseBody: unknown;
    try {
      responseBody = JSON.parse(responseObject?.body);
    } catch (e: unknown) {
      return await this.logGenericErrorMessageAndReject(
        new Error(`Error while parsing response from ${fullUrl}. Error: ${(e as SyntaxError).message}`)
      );
    }
    return responseBody;
  }
  public async recallOrder(options: RecallOrderOptions): Promise<RecallOrderResponse> {
    const fullUrl = `${this.bsOsirisBaseUrl}/api/v1/kiosk/recallOrder/${options.orderID}`;

    let responseObject: DdsResponse;
    try {
      responseObject = await this.ddsClient.get(fullUrl, {
        headers: await this.buildHeaders(),
      });
    } catch (errorResponse: unknown) {
      return await this.logHttpErrorMessageAndReject(errorResponse as XMLHttpRequest, fullUrl);
    }
    let responseBody: OsirisButton[];
    try {
      responseBody = JSON.parse(responseObject?.body);
    } catch (e: unknown) {
      return await this.logGenericErrorMessageAndReject(
        new Error(`Error while parsing response from ${fullUrl}. Error: ${(e as SyntaxError)?.message}`)
      );
    }
    return { KioskOrder: responseBody };
  }

  private async buildHeaders(): Promise<DdsHeaders> {
    const envDetails = await AtpEnvironmentService.getInstance().getEnvironmentDetails();
    const key = await this.osirisHttpHandshakeService.requestAutorizationCode();
    const nestIdentifier = envDetails.NestIdentifier;
    const marketID = nestIdentifier.split('.')[1];
    const headers: DdsHeaders = {
      Authorization: `otp ${key}`,
      marketID,
      nestIdentifier,
      scrambledKeyVersion: 'v1',
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'X-ApiVersion': '1.1',
    };
    return headers;
  }
  private async logHttpErrorMessageAndReject(error: XMLHttpRequest, url: string) {
    const errorMessage: string = error?.response || `Error on accessing Osiris URL: ${url}. Status: ${error?.status}`;
    return await this.logGenericErrorMessageAndReject(new Error(errorMessage));
  }
  private async logGenericErrorMessageAndReject(error: Error) {
    await osirisLogger.error(error.message);
    return Promise.reject(error.message);
  }
}
