import { DotButton } from 'dotsdk';
import { AutoPopComboMode } from '@dotxix/models/enums/auto-pop-combo';
import { comboInitialStartSize } from './combo.helper';
import { visibleOnSize } from './combo-up-size.helper';
import {
  addAutoCompleteModifiers,
  computeModifierSelectedQuantity,
  hasAutoCompleteButtonInModifier,
  hasIceModifierUnselected,
} from './modifiers.helper';
import { isItemPackButton } from './button.helper';

export function isAutoPopCombo(button: DotButton, onOrder: boolean): boolean {
  if (!button.hasCombos || !isPredefinedButton(button)) {
    return true;
  }
  const autoPopCombo = button.CustomInfo?.CustomElementList?.find((elem) => !!elem.AUTOPOPCOMBO)
    ? button.CustomInfo.CustomElementList.find((elem) => !!elem.AUTOPOPCOMBO)?.AUTOPOPCOMBO
    : AutoPopComboMode.POP_ON_ORDER_POP_ON_MODIFY;

  switch (autoPopCombo) {
    case AutoPopComboMode.NOT_POP_ON_ORDER_POP_ON_MODIFY:
      return !onOrder;
    case AutoPopComboMode.POP_ON_ORDER_POP_ON_MODIFY:
      return true;
    case AutoPopComboMode.POP_ON_ORDER_NOT_POP_ON_MODIFY:
      return onOrder;
    case AutoPopComboMode.NOT_POP_ON_ORDER_NOT_POP_ON_MODIFY:
      return false;
  }
  return false;
}

export const addComponentItemsToCombo = (button: DotButton): DotButton => {
  if (!button.hasCombos) {
    return button;
  }
  button.ComboPage.Combos.forEach((combo) => {
    const comboButtonToAdd =
      combo.Buttons.length === 0 ? combo.Buttons[0] : combo.Buttons.find((btn) => !!btn.Selected || btn.IncludedQuantity === 1);
    if (comboButtonToAdd) {
      comboButtonToAdd.Selected = true;
      comboButtonToAdd.quantity = 1;
      addAutoCompleteModifiers(comboButtonToAdd);
    }
  });
  return button;
};

const isPredefinedButton = (button: DotButton): boolean => {
  const currentSize = button.selectedSize || comboInitialStartSize(button);
  return button.ComboPage.Combos.every((combo) => {
    const includedQuantityButtons = combo.Buttons.filter((btn) => btn.IncludedQuantity === 1 && visibleOnSize(btn, currentSize));
    const singleIncludedQuantityButton = includedQuantityButtons.length === 1 ? includedQuantityButtons[0] : null;
    return (
      (singleIncludedQuantityButton && meetModifierRequirements(singleIncludedQuantityButton)) ||
      (combo.Buttons.length === 1 && visibleOnSize(combo.Buttons[0], currentSize) && meetModifierRequirements(combo.Buttons[0]))
    );
  });
};

const meetModifierRequirements = (button: DotButton): boolean =>
  !hasIceModifierUnselected(button) &&
  (!button.hasModifiers ||
    button.ModifiersPage.Modifiers.every(
      (modifierButton) =>
        (!modifierButton.PageInfo.MinQuantity && modifierButton.Buttons.every((btn) => hasQuantityGreaterThanMinimumQuantity(btn))) ||
        hasAutoCompleteButtonInModifier(modifierButton) ||
        (computeModifierSelectedQuantity(modifierButton) >= modifierButton.PageInfo.MinQuantity &&
          modifierButton.Buttons.every((btn) => hasQuantityGreaterThanMinimumQuantity(btn)))
    ));

const hasQuantityGreaterThanMinimumQuantity = (button: DotButton): boolean => {
  if (isItemPackButton(button)) {
    return button.Page?.Buttons?.every((btn) => hasQuantityGreaterThanMinimumQuantity(btn));
  } else {
    return !button.MinQuantity || button.MinQuantity <= button.quantity;
  }
};
