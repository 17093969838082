import { NgModule } from '@angular/core';
import { TranslatePicturePipe } from '@dotxix/pipes/translate-picture.pipe';
import { ComboStepTitleTranslationPipe } from '@dotxix/pipes/translate-combo-title.pipe';
import { CatalogPicturePipe } from '@dotxix/pipes/catalog-picture.pipe';
import { TranslateCatalogTitle } from '@dotxix/pipes/translate-catalog-title.pipe';
import { AllergenLabelTranslationPipe } from '@dotxix/pipes/translate-allergen-label.pipe';
import { CurrencyPipe } from '@dotxix/pipes/currency.pipe';
import { PrefixesNameTranslationPipe } from '@dotxix/pipes/translate-prefixes-name.pipe';
import { DlgMessageDictionaryTranslationPipe } from '@dotxix/pipes/translate-dlg-message.pipe';
import { FilterCatalogAvailablePipe } from '@dotxix/pipes/catalog-availability.pipe';
import { TranslateCaptionPipe } from '@dotxix/pipes/dot-translate-caption.pipe';
import { TranslatePipe } from '@dotxix/pipes/dot-translate.pipe';
import { PageTitlePipe } from '@dotxix/pipes/page-title.pipe';
import { CatalogButtonMinPricePipe } from '@dotxix/pipes/catalog-button-min-price.pipe';
import { StatusBulletColorClassPipe } from '@dotxix/pipes/status-bullet-color-class.pipe';
import { BannersToSlideShowStepsPipe } from '@dotxix/pipes/banners-to-slide-show-steps.pipe';
import { IsButtonVisuallyUnavailablePipe } from '@dotxix/pipes/is-button-visually-unavailable.pipe';
import { CaloriesPipe } from '@dotxix/pipes/calories.pipe';
import { PrefixVisualQuantityPipe } from '@dotxix/pipes/prefix-visual-quantity.pipe';
import { IsButtonUnavailablePipe } from '@dotxix/helpers/is-button-unavailable.pipe';
import { CalculateButtonPricePipe } from '@dotxix/pipes/calculate-button-price.pipe';
import { PrefixLabelPipe } from '@dotxix/pipes/prefix-label.pipe';
import { ModifierButtonDefaultQuantityLabelPipe } from '@dotxix/pipes/modifier-button-default-quantity-label.pipe';
import { ModifierStepNameTranslationPipe } from '@dotxix/pipes/translate-modifier-step-name.pipe';
import { ModifierImageTranslatePipe } from '@dotxix/pipes/translate-modifier-image.pipe';
import { NutritionalDescription } from './nutritional-description.pipe';
import { NutritionalLabel } from './nutritional-label.pipe';
import { NutritionalUnit } from './nutritional-unit.pipe';
import { IngredientLabelTranslationPipe } from './translate-ingredient-label.pipe';

const pipes = [
  AllergenLabelTranslationPipe,
  BannersToSlideShowStepsPipe,
  CalculateButtonPricePipe,
  CaloriesPipe,
  CatalogPicturePipe,
  ModifierImageTranslatePipe,
  ModifierStepNameTranslationPipe,
  ComboStepTitleTranslationPipe,
  CurrencyPipe,
  DlgMessageDictionaryTranslationPipe,
  FilterCatalogAvailablePipe,
  IsButtonUnavailablePipe,
  IsButtonVisuallyUnavailablePipe,
  CatalogButtonMinPricePipe,
  ModifierButtonDefaultQuantityLabelPipe,
  PageTitlePipe,
  PrefixesNameTranslationPipe,
  PrefixLabelPipe,
  PrefixVisualQuantityPipe,
  StatusBulletColorClassPipe,
  TranslateCaptionPipe,
  TranslateCatalogTitle,
  TranslatePicturePipe,
  TranslatePipe,
  NutritionalDescription,
  NutritionalUnit,
  NutritionalLabel,
  IngredientLabelTranslationPipe,
];

@NgModule({
  declarations: pipes,
  exports: pipes,
})
export class PipesModule {}
