import { Injectable } from '@angular/core';
import { AtpApplicationSettings, Mockable, MocksOptions, UseMocks, Xhr } from 'dotsdk';
import { environment } from '../../../environments/environment';
import { StoresAuthenticateResponse } from '@dotxix/services/suggestions/models/stores-authenticate-response';
import { RecoStoresAuthenticateOverrider } from '@dotxix/services/suggestions/mocks-overriders/reco-stores-authenticate-overrider';
import { RecoEnginesOverrider } from '@dotxix/services/suggestions/mocks-overriders/reco-engines-overrider';
import { EnginesResponse } from '@dotxix/services/suggestions/models/engines-response';
import { RecoOrderResponse } from '@dotxix/services/suggestions/models/reco-order-response';
import { RecoCreateOrderOverrider } from '@dotxix/services/suggestions/mocks-overriders/reco-create-order-overrider';
import { RecoGenerateCampaignOverrider } from '@dotxix/services/suggestions/mocks-overriders/reco-generate-campaign-overrider';
import { RecoCampaignResponse } from '@dotxix/services/suggestions/models/reco-campaign-response';
import { RecoPopularItemsOverrider } from '@dotxix/services/suggestions/mocks-overriders/reco-popular-items-overrider';
import { RecommendedProduct } from '@dotxix/services/suggestions/models/recommended-product';
import { RecoUserActionOverrider } from '@dotxix/services/suggestions/mocks-overriders/reco-user-action-overrider';
import { RecoUpdateOrderOverrider } from '@dotxix/services/suggestions/mocks-overriders/reco-update-order-overrider';
import { RecoOrderSuggestionsOverrider } from '@dotxix/services/suggestions/mocks-overriders/reco-order-suggestions-overrider';

@Injectable({
  providedIn: 'root',
})
export class RecommendationHttpService implements Mockable {
  public mocksOptions: MocksOptions = {
    useMocks: environment.useMocksForRecommendations,
    mocksFolder: environment.recommendationsMocksPath,
  };
  private xhrClient = new Xhr();
  private jsonHeaders = [
    {
      headerName: 'Content-Type',
      headerValue: 'application/json',
    },
    {
      headerName: 'Accept',
      headerValue: '*/*',
    },
  ];
  private accessTokenHeader = {
    headerName: 'access-token',
    headerValue: '',
  };

  public setAccessToken(accessToken: string) {
    this.accessTokenHeader.headerValue = accessToken;
  }

  @UseMocks(RecoStoresAuthenticateOverrider)
  public async storesAuthenticate(storeId: number, publicKey: string): Promise<StoresAuthenticateResponse> {
    const data = await this.xhrClient.makeRequest({
      url: `${AtpApplicationSettings.getInstance().bundleSettingsJson.recoEndpoint}/stores/authenticate`,
      method: 'POST',
      payload: JSON.stringify({ storeId, publicKey }),
      headers: this.jsonHeaders,
    });
    return JSON.parse(data.response);
  }

  @UseMocks(RecoEnginesOverrider)
  public async engines(): Promise<EnginesResponse> {
    const data = await this.xhrClient.makeRequest({
      url: `${AtpApplicationSettings.getInstance().bundleSettingsJson.recoEndpoint}/engines`,
      method: 'GET',
      headers: [this.accessTokenHeader],
    });
    return JSON.parse(data.response);
  }

  @UseMocks(RecoCreateOrderOverrider)
  public async createOrder(serviceType: string): Promise<RecoOrderResponse> {
    const bundleSettings = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const data = await this.xhrClient.makeRequest({
      url: `${bundleSettings.recoEndpoint}/stores/${bundleSettings.recoStoreId}/orders`,
      method: 'POST',
      payload: JSON.stringify({ environment: AtpApplicationSettings.getInstance().bundleSettingsJson.recoEnvironment, serviceType }),
      headers: [this.accessTokenHeader, ...this.jsonHeaders],
    });
    return JSON.parse(data.response);
  }

  @UseMocks(RecoGenerateCampaignOverrider)
  public async generateCampaign(orderId: number): Promise<RecoCampaignResponse> {
    const bundleSettings = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const data = await this.xhrClient.makeRequest({
      url: `${bundleSettings.recoEndpoint}/optimize/campaigns/generate?storeId=${bundleSettings.recoStoreId}&orderId=${orderId}`,
      method: 'GET',
      headers: [this.accessTokenHeader],
    });
    return JSON.parse(data.response);
  }

  @UseMocks(RecoUserActionOverrider)
  public async userAction(payload: object, orderId: number) {
    const bundleSettings = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const data = await this.xhrClient.makeRequest({
      url: `${bundleSettings.recoEndpoint}/stores/${bundleSettings.recoStoreId}/orders/${orderId}/items`,
      method: 'PUT',
      payload: JSON.stringify(payload),
      headers: [this.accessTokenHeader, ...this.jsonHeaders],
    });
    return JSON.parse(data.response);
  }

  @UseMocks(RecoUpdateOrderOverrider)
  public async updateOrder(payload: object, orderId: number) {
    const bundleSettings = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const data = await this.xhrClient.makeRequest({
      url: `${bundleSettings.recoEndpoint}/stores/${bundleSettings.recoStoreId}/orders/${orderId}`,
      method: 'PATCH',
      payload: JSON.stringify(payload),
      headers: [this.accessTokenHeader, ...this.jsonHeaders],
    });
    return JSON.parse(data.response);
  }

  @UseMocks(RecoPopularItemsOverrider)
  public async popularItems(count: number, engine: string): Promise<RecommendedProduct[]> {
    const bundleSettings = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const data = await this.xhrClient.makeRequest({
      url: `${bundleSettings.recoEndpoint}/stores/${bundleSettings.recoStoreId}/recommendations/populars?count=${count}&engine=${engine}`,
      method: 'POST',
      headers: [this.accessTokenHeader],
    });
    return JSON.parse(data.response);
  }

  @UseMocks(RecoOrderSuggestionsOverrider)
  public async orderRecommendedItems(orderId: number, engine: string, count: number): Promise<RecommendedProduct[]> {
    const bundleSettings = AtpApplicationSettings.getInstance().bundleSettingsJson;
    const data = await this.xhrClient.makeRequest({
      url: `${bundleSettings.recoEndpoint}/stores/${bundleSettings.recoStoreId}/recommendations?orderId=${orderId}&count=${count}&engine=${engine}`,
      method: 'POST',
      headers: [this.accessTokenHeader],
    });
    return JSON.parse(data.response);
  }
}
