import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { LocalizationService } from '@dotxix/services/localization.service';
import { TranslationsService } from '@dotxix/services/translations/translations.service';
import { Component } from '@angular/core';
import { GloryCashLegalRequirementsService } from '@dotxix/payment/services/glory-cash-legal-requirements.service';

@Component({
  selector: 'acr-glory-legal-requirements',
  templateUrl: './glory-legal-requirements.component.html',
})
export class GloryLegalRequirementsComponent {
  public legalMessage;

  constructor(
    private translationsService: TranslationsService,
    private localizationService: LocalizationService,
    private appSettingsService: ApplicationSettingsService,
    private gloryCashLegalRequirementsService: GloryCashLegalRequirementsService
  ) {
    const gloryPayableAmount = this.appSettingsService.settings$.value.gloryPayableAmount;
    this.legalMessage = `${this.translationsService
      .translate('2001012')
      .replace(/%%%/, this.localizationService.formatCurrency(gloryPayableAmount))} ${this.translationsService.translate('2001013')}`;
  }

  public acceptLegalTerms() {
    this.gloryCashLegalRequirementsService.approve();
  }

  public finishWithOtherMethodClicked() {
    this.gloryCashLegalRequirementsService.finishWithOtherMethod();
  }
}
