import { DotButton, DotButtonType, DotModifier } from 'dotsdk';
import { OsirisComboPage } from '@dotxix/osiris/models/interfaces/osiris-combo-page.model';
import { OsirisModifiersPage } from '@dotxix/osiris/models/interfaces/osiris-modifiers-page.model';
import { OsirisButton } from '@dotxix/osiris/models/types/osiris-button.type';
import { OsirisCombos } from '@dotxix/osiris/models/interfaces/osiris-combos.model';

export function basketToProductCodeMetadata(buttons: DotButton[]): string {
  return buttons
    .reduce((acc, button) => {
      acc += button.Link + '|' + button.quantity + ',';
      return acc;
    }, '')
    .slice(0, -1);
}

export function basketToOsiris(buttons: DotButton[]): OsirisButton[] {
  return buttons.reduce((acc, button) => {
    acc.push({
      ButtonType: button.ButtonType,
      Link: button.Link,
      quantity: button.quantity,
      isPromo: button.isPromo,
      promoId: button.promoId,
      posDiscountId: button.posDiscountId,
      ...(button.ButtonType === DotButtonType.MENU_BUTTON && { StartSize: button.StartSize || button.DefaultSize }),
      ...(button.ButtonType === DotButtonType.MENU_BUTTON && getComboPage(button)),
      ...(button.hasModifiers && button.activeModifiers.length > 0 && getModifiersPage(button.activeModifiers)),
    } as OsirisButton);
    return acc;
  }, [] as OsirisButton[]);
}

function getComboPage(button: DotButton): OsirisComboPage {
  return {
    ComboPage: {
      ID: button.ComboPage.ID.toString(),
      Combos: button.ComboPage.Combos.reduce((acc, combo) => {
        acc.push({
          PageType: 'Combo',
          ComboStepID: combo.ComboStepID,
          CompID: combo.CompID,
          ComboID: combo.ComboID,
          ComboStepIndex: combo.ComboStepIndex,
          Buttons: getButtonsFromCombo(combo.Buttons),
        });
        return acc;
      }, [] as OsirisCombos[]),
    },
  };
}

function getButtonsFromCombo(buttons: DotButton[]): OsirisButton[] {
  return buttons.reduce((acc, button) => {
    if (button.Selected === true) {
      acc.push({
        Link: button.Link,
        quantity: button.quantity,
        ...(button.hasModifiers && button.activeModifiers.length > 0 && getModifiersPage(button.activeModifiers)),
      } as OsirisButton);
    }
    return acc;
  }, [] as OsirisButton[]);
}

function getModifiersPage(modifiers: DotModifier[]): OsirisModifiersPage {
  return {
    ModifiersPage: {
      Modifiers: modifiers.map((modifier) => {
        return {
          PageInfo: {
            ModifierID: modifier.PageInfo.ModifierID,
            ID: modifier.PageInfo.ID,
          },
          Buttons: modifier.Buttons.filter((button) => button.Selected === true).map((button) => {
            return {
              Link: button.Link,
              quantity: button.quantity,
              ...(button.selectedPrefixId && { selectedPrefixId: button.selectedPrefixId }),
            };
          }),
        };
      }),
    },
  };
}
