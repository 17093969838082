import { Component } from '@angular/core';
import { CustomThemeFlowService } from '@dotxix/custom-theme/services/custom-theme-flow.service';

@Component({
  selector: 'acr-save-button',
  styles: [
    `
      .save-button {
        width: 80rem;
        height: 80rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #064206;
        background: rgb(132, 209, 132);
        box-shadow:
          rgb(0 0 0 / 30%) 0px 0px 2px,
          rgb(0 0 0 / 30%) 0px 1px 4px;
        border-radius: 15rem;
        box-shadow: 5rem 4rem 27rem rgb(0 0 0 / 17%);
      }
    `,
  ],
  template: `
    <button (click)="saveChanges()" class="save-button">
      <svg
        style="width: 100rem; height: 50rem"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#064206"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-save"
      >
        <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
        <polyline points="17 21 17 13 7 13 7 21"></polyline>
        <polyline points="7 3 7 8 15 8"></polyline>
      </svg>
    </button>
  `,
})
export class SaveButtonComponent {
  constructor(private customThemeFlowService: CustomThemeFlowService) {}

  public saveChanges() {
    this.customThemeFlowService.writeToFile();
  }
}
