import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StatusService } from '@dotxix/services/status.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'acr-payment-logos',
  templateUrl: './payment-logos.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentLogosComponent {
  public payments$ = this.statusService.state$.pipe(map((state) => state.availablePayments));
  constructor(protected statusService: StatusService) {}
}
