<language-change-view-trigger></language-change-view-trigger>

<ng-content select="[top-slot]"></ng-content>
<div class="footer">
  <button class="ui-button ui-button--icon-button footer__ada-button" data-automation="Accessibility Switcher" (click)="switchAdaMode()">
    <img skin-part="image" class="ui-button--icon ada size--72 footer__ada-button-image" src="/Channels/skin/icon-ada.svg" alt="" />
    <img
      skin-part="image"
      class="ui-button--icon ada size--72 footer__ada-button-image footer__ada-button-image--light"
      src="/Channels/skin/icon-ada-light.svg"
      alt=""
    />
  </button>
  <div class="footer__left">
    <ng-content select="[left-slot]"></ng-content>
  </div>
  <div class="footer__middle">
    <ng-content select="[middle-slot]"></ng-content>
  </div>
  <div class="footer__right">
    <ng-content select="[right-slot]"></ng-content>
  </div>
</div>
<ng-content select="[order-button-slot]"></ng-content>
