import { Injectable, PipeTransform } from '@angular/core';
import { getCatalogButton, getCombosCatalogButton } from 'dotsdk';
import { ProductTranslationsService } from '@dotxix/services/translations/product-translations.service';

@Injectable({
  providedIn: 'root',
})
export class PictureCatalogService implements PipeTransform {
  constructor(private productTranslationsService: ProductTranslationsService) {}

  public transform(link: string): string | null {
    let catalogButton = getCatalogButton(link);
    if (catalogButton) {
      return this.productTranslationsService.translatePicture(catalogButton);
    }
    catalogButton = getCombosCatalogButton(link);
    if (catalogButton) {
      return this.productTranslationsService.translatePicture(catalogButton);
    }
    return '/Channels/skin/logo.svg';
  }
}
