import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppBIService } from '../../../business-intelligence/bi.service';
import { BIContexts } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-promos-keyboard',
  templateUrl: './promos-keyboard.component.html',
})
export class PromosKeyboardComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() continue = new EventEmitter<string>();

  public barcode = '';

  constructor(private appBiService: AppBIService) {}

  public ngOnInit() {
    this.appBiService.actions.impressions.viewKeyboard(BIContexts.Promotions);
  }

  public cancelClicked() {
    this.appBiService.actions.clicks.clickKeyboardInputCancel(BIContexts.Promotions);
    this.cancel.next();
  }

  public confirmClicked(value: string) {
    this.appBiService.actions.clicks.clickKeyboardInputConfirm(BIContexts.Promotions);
    this.continue.next(value);
  }
}
