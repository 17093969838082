import { Injectable } from '@angular/core';
// import { BehaviorSubject, pairwise, startWith } from 'rxjs';
// import { GroupIdentifier } from '@dotxix/custom-theme/enums/variable-groups.enum';
// import { CssVariables } from '@dotxix/custom-theme/models/css-variables.interface';
import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';

// interface CssVariablesGroups {
//   groupIdentifier: string;
//   groupVariables: CssVariables;
// }

// const initialState = (): CssVariablesGroups[] => {
//   return Object.values(GroupIdentifier).reduce((a, c) => [...a, ...[{ groupIdentifier: GroupIdentifier[c], groupVariables: {} }]], []);
// };

@Injectable({ providedIn: 'root' })
export class VariableGroupsStateService {
  // public variableGroupsState$ = new BehaviorSubject<CssVariablesGroups[]>(initialState());
  constructor(private themeEditorStateService: ThemeEditorStateService) {
    // this.themeEditorStateService.generalThemingVariables$
    //   .pipe(startWith(null))
    //   .pipe(pairwise())
    //   .subscribe(([prevVars, currVars]) => {
    //     if (prevVars) {
    //       const changedKeys = Object.keys(currVars).filter((f) => currVars[f] !== prevVars[f]);
    //       return changedKeys.length > 1
    //         ? this.populateWithCssVariables(currVars)
    //         : this.updateForSingleValue(changedKeys[0], currVars[changedKeys[0]]);
    //     }
    //
    //     this.populateWithCssVariables(currVars);
    //   });
  }

  // private populateWithCssVariables(variables: CssVariables) {
  //   let newState = this.variableGroupsState$.value;
  //   Object.keys(variables).forEach((key) => {
  //     const index = this.variableGroupsState$.value.findIndex((f) => key.includes(f.groupIdentifier));
  //     newState.at(index).groupVariables = { ...newState.at(index).groupVariables, [key]: variables[key] };
  //   });
  //   this.variableGroupsState$.next(newState);
  // }
  //
  // private updateForSingleValue(cssVariableKey: string | undefined, cssVariableValue: string | undefined) {
  //   if (cssVariableKey) {
  //     const index = this.variableGroupsState$.value.findIndex((f) => cssVariableKey.includes(f.groupIdentifier));
  //     let newState = this.variableGroupsState$.value;
  //     newState.at(index).groupVariables = { ...newState.at(index).groupVariables, [cssVariableKey]: cssVariableValue };
  //     this.variableGroupsState$.next(newState);
  //   }
  // }
}
