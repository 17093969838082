<div class="product-card-sub-card-item__item">
  <img
    class="product-card-sub-card-item__item--image"
    [class.logo]="!(itemButton | translatePicture)"
    [src]="itemButton | translatePicture"
    width="80rem"
    height="80rem"
    alt=""
  />
  <div class="product-card-sub-card-item__item--product">
    <header class="product-card-sub-card-item__item--header">
      <span
        class="product-card-sub-card-item__item--quantity"
        *ngIf="!itemButton.isPromo && itemButton.quantity > 1"
        data-automation="Sub Product Quantity"
      >
        {{ itemButton.quantity }}x
      </span>
      <span class="product-card-sub-card-item__item--title" data-automation="Sub Product Title">{{ itemButton | translateCaption }}</span>
      <span class="product-card-sub-card-item__item--price font--secondary" data-automation="Sub Product Price">
        {{ itemButton | calculateButtonPrice | dotCurrency }}
      </span>
    </header>
    <ul class="product-card-sub-card-item__item--modifiers">
      <li
        class="product-card-sub-card-item__modifier"
        *ngFor="let modifierButton of modifierButtons; let i = index; let modifiersCount = count"
      >
        <span
          class="product-card-sub-card-item__modifier--quantity"
          *ngIf="modifierButton.quantity > 1"
          data-automation="Sub Product Modifier Quantity"
        >
          {{ modifierButton.quantity }}x
        </span>
        <span class="product-card-sub-card-item__modifier--title" data-automation="Sub Product Modifier Title">
          {{ modifierButton | translateCaption }}
        </span>
        <span *ngIf="i < modifiersCount - 1">, &nbsp;</span>
      </li>
    </ul>
  </div>
  <div class="product-card-sub-card-item__actions">
    <button
      *ngIf="allowModifyProduct && !itemButton.isPromo"
      (click)="onRemoveClick()"
      data-automation="Button: Remove"
      class="ui-button ui-button--primary ui-button--tiny pl--40 fs--22"
    >
      {{ '2021041901' | translate }}
    </button>
    <button
      *ngIf="displayChangeButton"
      (click)="changeProduct(itemButton)"
      data-automation="Button: Change"
      class="ui-button ui-button--primary ui-button--tiny pl--40 fs--22"
    >
      {{ '18' | translate }}
    </button>
  </div>
</div>
