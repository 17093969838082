<main class="page-container table-service-unavailable">
  <header class="page-header">
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
  </header>
  <section class="flex flex--column flex--1 align-center justify-evenly page-content">
    <div>
      <h2 class="text-center color--errorRed" [innerHtml]="'82' | translate"></h2>
      <div class="spacer--100"></div>
      <h3 class="text-center" [innerHtml]="'81' | translate"></h3>
    </div>
    <div class="flex place-center justify-center">
      <picture class="circle circle--medium">
        <img
          skin-part="image"
          class="ui-button--icon color--accent size--260 p--40 table-service-unavailable__circle-image"
          alt=""
          src="/Channels/skin/icon-tableServiceFlag.svg"
        />
      </picture>
      <img skin-part="image" src="/Channels/skin/icon-plus.svg" class="ui-button--icon size--46 m--50" alt="" />
      <picture class="circle circle--medium">
        <img
          skin-part="image"
          class="ui-button--icon color--accent size--260 p--40 table-service-unavailable__circle-image"
          alt=""
          src="/Channels/skin/icon-tableServiceReceipt.svg"
        />
      </picture>
    </div>
  </section>
</main>
