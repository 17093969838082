import { CheckoutFlowService } from '@dotxix/services/checkout-flow.service';
import { Component, Input } from '@angular/core';
import { BasketService } from '@dotxix/services/basket.service';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-pay-order',
  templateUrl: './pay-order.component.html',
})
export class PayOrderComponent {
  @Input() public isDisabled = false;
  constructor(
    public basketService: BasketService,
    private checkoutFlowService: CheckoutFlowService,
    private appBIService: AppBIService
  ) {}

  public onClick() {
    this.appBIService.actions.clicks.clickedProceedToPayment();
    this.checkoutFlowService.checkAvailablePaymentsAndSelectTableService().then(() => {});
  }
}
