import { Pipe, PipeTransform } from '@angular/core';
import { KioskStatusColor } from '@dotxix/models/enums/kiosk-status-color';

@Pipe({
  name: 'statusBulletColorClass',
})
export class StatusBulletColorClassPipe implements PipeTransform {
  transform(value: KioskStatusColor): unknown {
    switch (value) {
      case KioskStatusColor.BLUE:
        return 'bg--blue';
      case KioskStatusColor.ORANGE:
        return 'bg--orange';
      case KioskStatusColor.RED:
        return 'bg--red';
      case KioskStatusColor.PURPLE:
        return 'bg--purple';
      case KioskStatusColor.NO_COLOR:
      default:
        return '';
    }
  }
}
