<main class="page-container osiris-order-history">
  <section class="osiris-order-history__header">
    <h2 class="color--white">{{ '20220418001' | translate }}</h2>
  </section>
  <section class="osiris-order-history__orders scrollY">
    <acr-osiris-order-card
      *ngFor="let order of orders"
      [order]="order"
      (reorder)="onReorder(order)"
      (viewFullOrder)="onViewFullOrder(order)"
    ></acr-osiris-order-card>
    <ng-container *ngIf="(!showLoadingMessage && orders.length <= 0) || showNoOrdersMessage">
      <p class="osiris-order-history--generic-text" [innerHTML]="'20220418006' | translate"></p>
    </ng-container>
    <ng-container *ngIf="showLoadingMessage && !serverTimeout">
      <div class="osiris-order-history__spinner-container">
        <acr-spinner></acr-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="serverTimeout">
      <div class="osiris-order-history__server-timeout-message">
        <h2 class="color--white">{{ '2022050405' | translate }}</h2>
      </div>
    </ng-container>
  </section>
  <acr-footer>
    <button middle-slot class="ui-button ui-button--secondary ui-button--x-large" (click)="goToMenuClick()">
      <span class="ui-button__wrapper">
        <span>{{ '20220418007' | translate }}</span>
      </span>
    </button>
  </acr-footer>
</main>
