<div class="popup modifiers-stepper" data-automation="modifiers-stepper-popup">
  <div class="popup__content modifiers-stepper__content">
    <main class="popup__page-container modifiers-stepper__page-container">
      <acr-product-stepper-header
        [productTitle]="modifierTitle"
        [productSubTitle]="(button | translateCaption) ?? ''"
        [images]="headerImages"
      ></acr-product-stepper-header>

      <section class="modifiers-stepper__top-menu">
        <button
          *ngIf="displayBackButton"
          class="ui-button ui-button--primary bg--tertiary ui-button--tiny ui-button--iconLeft"
          data-automation="Modifier Back Button"
          (click)="onBackButtonClicked()"
        >
          <span class="ui-button__wrapper">
            <img skin-part="image" src="/Channels/skin/icon-chevronLeft.svg" class="size--20" alt="" acrRevertOnRtl />
            <span>{{ '9' | translate }}</span>
          </span>
        </button>
        <h4 *ngIf="currentModifierStepState === ModifierStepState.CHOOSE_COMPONENT" class="modifiers-stepper__top-menu--title">
          {{ currentModifierStep.getTitleTranslation() }}
        </h4>
      </section>

      <acr-scrollable-container [scrollToTop]="scrollToTop$">
        <ng-container *ngIf="currentModifierStepState === ModifierStepState.CHOOSE_COMPONENT">
          <div class="modifiers-grid">
            <acr-button-item
              *ngFor="let modifierStepComponent of currentModifierStepComponents; let index = index"
              [button]="modifierStepComponent"
              [selected]="isModifierStepComponentSelected(modifierStepComponent)"
              [displayPrice]="computeComponentDisplayPrice(modifierStepComponent) | dotCurrency"
              [showCounterEdit]="false"
              [displayPrefixPlusSignForPrice]="true"
              (buttonClicked)="onComponentButtonClicked(modifierStepComponent)"
            ></acr-button-item>
          </div>
        </ng-container>

        <ng-container *ngIf="currentModifierStepState === ModifierStepState.SUBGROUP">
          <div class="modifiers-grid">
            <acr-button-item
              *ngFor="let itemPackButton of itemPackVisibleComponents"
              [button]="itemPackButton"
              [selected]="itemPackButton.Selected"
              [displayPrice]="computeComponentDisplayPrice(itemPackButton) | dotCurrency"
              [showCounterEdit]="false"
              (buttonClicked)="onItemPackComponentButtonClicked(itemPackButton)"
            ></acr-button-item>
          </div>
        </ng-container>

        <ng-container *ngIf="currentModifierStepState === ModifierStepState.CUSTOMIZE">
          <ng-container *ngFor="let modifierButtonModifier of currentModifierStepComponentFeatures">
            <acr-button-modifier-list
              [modifier]="modifierButtonModifier"
              [onOrder]="!dataParams!.isUpdate"
              (modifierChanged)="currentModifierStepComponentFeaturesChanged()"
            ></acr-button-modifier-list>
          </ng-container>
        </ng-container>
      </acr-scrollable-container>

      <footer class="popup__actions modifiers-stepper__actions">
        <button class="ui-button ui-button--primary ui-button--small" (click)="cancelClick()" data-automation="Cancel Button">
          <span class="ui-button__wrapper">
            <span>{{ '23' | translate }}</span>
          </span>
        </button>

        <acr-stepper [steps]="stepperSteps" [activeIndex]="currentModifierStepIndex"></acr-stepper>

        <button
          [disabled]="disableConfirmButton"
          class="ui-button ui-button--secondary ui-button--iconRight ui-button--small"
          (click)="onConfirmClicked()"
          data-automation="Confirm Button"
        >
          <span class="ui-button__wrapper">
            <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
            <span *ngIf="!isLastStep">{{ '28' | translate }}</span>
            <span *ngIf="isLastStep" class="fs--26">{{ '27' | translate }}</span>
          </span>
        </button>
      </footer>
    </main>
  </div>
</div>
