import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@dotxix/payment/services/payment.service';
import { AppBIService } from '../../../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-glory-cash-cancel-transaction-refund-progress',
  host: { class: 'flex flex--column full-height' },
  template: `<acr-glory-cash-message
      [message]="'2001034'"
      [amounts]="[{ label: '2001027', value: (paymentServiceState$ | async)!.cash.currentTransactionPaidAmount }]"
    ></acr-glory-cash-message>

    <!-- Cash Paid: -->

    <!-- Your refund is in the works. |Please wait for your transaction to be processed. -->`,
})
export class GloryCashCancelTransactionRefundProgressComponent implements OnInit {
  public paymentServiceState$ = this.paymentService.state$;
  constructor(
    private paymentService: PaymentService,
    private appBiService: AppBIService
  ) {}
  public ngOnInit() {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.CASH_PAYMENT_CANCEL_TRANSACTION_REFUND_PROGRESS);
  }
}
