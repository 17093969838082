import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentRoutes } from "@dotxix/payment/payment-routes";
import { BehaviorSubject, firstValueFrom, merge, Subject, timer } from "rxjs";
import { DynamicContentService } from "@dotxix/services/dynamic-content/dynamic-content.service";
import { InfoDialogComponent } from "@dotxix/components/info-dialog/info-dialog.component";
import { TranslationsService } from "@dotxix/services/translations/translations.service";
import { SessionService } from "@dotxix/services/session.service";
import { PayTowerService } from "@dotxix/services/pay-tower.service";
import { CheckoutService } from "@dotxix/services/checkout.service";
import { PrintTicketOption } from "@dotxix/models/enums/print-ticket-option";
import { ApplicationSettingsService } from "@dotxix/services/app-settings.service";
import { PosElogHandler } from "dotsdk";
import { OsirisFlowService } from "@dotxix/osiris/services/osiris-flow.service";

@Injectable({
  providedIn: 'root',
})
export class PayTowerPaymentFlowService {
  public printedPayTowerReceiptWithQrCode$ = new Subject<void>();
  public error$ = new Subject<void>();

  public savingOrder$ = new BehaviorSubject(false);
  public orderSaved$ = new BehaviorSubject(false);

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private router: Router,
    private payTowerService: PayTowerService,
    private dynamicContentService: DynamicContentService,
    private translationsService: TranslationsService,
    private sessionService: SessionService,
    private checkoutService: CheckoutService,
    private osirisFlowService: OsirisFlowService
  ) {
    this.sessionService.sessionStarted.subscribe(() => {
      this.savingOrder$.next(false);
      this.orderSaved$.next(false);
    });
  }

  public async startPayment() {
    this.router.navigate([PaymentRoutes.PayTower]);
    const orderId = await this.saveOrder();
    if (orderId) {
      this.printReceiptWithQrCode(orderId);
      this.setPosHeaderCvar();
      this.osirisFlowService.storeOrder();
      await firstValueFrom(timer(this.applicationSettingsService.settings$.value.payTowerTimeout));
      this.printedPayTowerReceiptWithQrCode$.next();
    } else {
      this.onFailedToSaveOrder();
    }
  }

  private async saveOrder(): Promise<string | null> {
    this.savingOrder$.next(true);
    const orderSaved = await this.payTowerService.saveOrder();
    this.savingOrder$.next(false);

    this.orderSaved$.next(!!orderSaved);

    return orderSaved;
  }

  private onFailedToSaveOrder() {
    this.showErrorMessageInfoDialogWithAutoDismiss().then(() => this.error$.next());
  }

  private async showErrorMessageInfoDialogWithAutoDismiss() {
    const infoDialogRef = this.dynamicContentService.openContent(InfoDialogComponent, {
      title: this.translationsService.translate('2001041'),
      buttonText: this.translationsService.translate('2001014'),
    });
    const $readTimeExpired = timer(6000);
    await firstValueFrom(merge($readTimeExpired, infoDialogRef.afterClosed));
    infoDialogRef.close();
  }

  private printReceiptWithQrCode(orderId: string) {
    this.checkoutService.printReceipt({ printTicketOption: PrintTicketOption.PAY_TOWER }, { payTowerQrCode: `pay-tower://${orderId}` });
  }

  private setPosHeaderCvar() {
    const posHeader = PosElogHandler.getInstance().posConfig.posHeader;
    if (typeof posHeader.cvars === 'object') {
      PosElogHandler.getInstance().posConfig.posHeader.cvars!.PayTower = 'true';
    } else {
      PosElogHandler.getInstance().posConfig.posHeader.cvars = { PayTower: 'true' };
    }
  }
}
