import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppBIService } from '../../../business-intelligence/bi.service';

@Component({
  selector: 'acr-promos-select-method',
  templateUrl: './promos-select-method.component.html',
})
export class PromosSelectMethodComponent implements OnInit {
  @Output() keyboardSelected = new EventEmitter<void>();
  @Output() scanSelected = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor(private appBiService: AppBIService) {}

  public ngOnInit() {
    this.appBiService.actions.impressions.viewInputOptions(['scan', 'keyboard']);
  }

  public onKeyboardSelected() {
    this.appBiService.actions.clicks.clickInputOption('keyboard');
    this.keyboardSelected.next();
  }
  public onScanSelected() {
    this.appBiService.actions.clicks.clickInputOption('scan');
    this.scanSelected.next();
  }

  public onCancelSelected() {
    this.appBiService.actions.clicks.clickInputOptionsDecline();
    this.cancel.next();
  }
}
