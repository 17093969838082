<language-change-view-trigger></language-change-view-trigger>
<div class="popup button-details" data-automation="button-details-popup">
  <div class="popup__content">
    <main class="flex flex--column full-height pt--100 button-details__container">
      <section class="button-details__header" [class.mb--60]="!displayModifiers">
        <div class="button-details__header--product">
          <h5 class="button-details__header--product-title">{{ button | translateCatalogTitle }}</h5>
          <img
            class="button-details__header--product-img"
            [class.logo]="!(button | catalogPicture)"
            [src]="button | catalogPicture"
            alt=""
            width="200rem"
            height="200rem"
          />
          <span *ngIf="calories">{{ calories.Value }} {{ calories | nutritionalUnit }}</span>
          <img
            *ngIf="showInfoButton"
            skin-part="image"
            alt=""
            src="/Channels/skin/icon-info.svg"
            class="ui-button--icon size--34 mr--10 button-details__header--info"
            (click)="onProductInfoClick($event)"
          />
        </div>
        <ng-container *ngIf="!button.isPromo">
          <h3 class="mt--30">{{ '66' | translate }}</h3>
          <acr-buttons-counter-edit
            [quantity]="button.quantity"
            [label]="displayPrice | dotCurrency"
            [isDecreaseDisabled]="button.quantity <= (button.MinQuantity || 1)"
            [isIncreaseDisabled]="!!button.MaxQuantity && button.quantity >= button.MaxQuantity"
            (quantityUpdate)="onProductQuantityUpdate($event)"
          ></acr-buttons-counter-edit>
        </ng-container>
      </section>

      <acr-scrollable-container *ngIf="displayModifiers">
        <ng-container *ngFor="let modifier of editableModifiers; let index = index">
          <acr-button-modifier-list
            [modifier]="modifier"
            [onOrder]="!dataParams!.isUpdate"
            (modifierChanged)="onModifierChanged()"
          ></acr-button-modifier-list>
        </ng-container>
      </acr-scrollable-container>
    </main>

    <footer class="popup__actions">
      <button data-automation="Cancel Button" class="ui-button ui-button--primary ui-button--small" (click)="cancelClick()">
        <span class="ui-button__wrapper">
          <span>{{ '23' | translate }}</span>
        </span>
      </button>
      <button
        class="ui-button ui-button--secondary ui-button--small ui-button--iconRight"
        data-automation="Confirm Button"
        (click)="confirmClick()"
        [disabled]="confirmButtonDisabled"
      >
        <span class="ui-button__wrapper">
          <img skin-part="image" src="/Channels/skin/icon-chevronRight.svg" class="size--20" alt="" acrRevertOnRtl />
          <span>{{ '71' | translate }}</span>
        </span>
      </button>
    </footer>
  </div>
</div>
