import { Component, OnInit } from '@angular/core';
import { PayTowerPaymentFlowService } from '@dotxix/payment/services/pay-tower-payment-flow.service';
import { AppBIService } from '../../../business-intelligence/bi.service';
import { BIPaymentProgressViews } from '@acrelec.foundation/business-analytics';

@Component({
  selector: 'acr-pay-tower-payment',
  templateUrl: './pay-tower-payment.component.html',
})
export class PayTowerPaymentComponent implements OnInit {
  public $loading = this.payTowerPaymentFlowService.savingOrder$;
  public $orderSaved = this.payTowerPaymentFlowService.orderSaved$;
  constructor(
    private payTowerPaymentFlowService: PayTowerPaymentFlowService,
    private appBiService: AppBIService
  ) {}
  ngOnInit(): void {
    this.appBiService.actions.impressions.viewPaymentProgress(BIPaymentProgressViews.PAYTOWER_PAYMENT_PROGRESS);
  }
}
