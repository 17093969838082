<main class="page-container preorder">
  <ng-container *ngIf="isTableServiceActive">
    <section class="flex flex--column align-center justify-evenly flex--2">
      <div class="square flex justify-center place-center">
        <img
          skin-part="image"
          src="/Channels/skin/icon-paymentSelectionCounter-preorder.svg"
          class="ui-button--icon color--accent p--40 size--400"
          alt=""
        />
      </div>
      <img skin-part="image" class="ui-button--icon size--140 color--accent bounceUp" alt="" src="/Channels/skin/icon-longArrowUp.svg" />
      <h2 class="text-center" [innerHtml]="'20210208001' | translate"></h2>
    </section>

    <section class="flex-center flex--1">
      <picture class="circle circle--medium">
        <img
          skin-part="image"
          class="ui-button--icon color--accent size--260 p--40 circle__image"
          alt=""
          src="/Channels/skin/icon-tableServiceFlag-table-service.svg"
        />
      </picture>
      <img skin-part="image" src="/Channels/skin/icon-plus.svg" class="ui-button--icon size--46 m--50" alt="" />
      <picture class="circle circle--medium">
        <img
          skin-part="image"
          class="ui-button--icon color--accent size--260 p--40 circle__image"
          alt=""
          src="/Channels/skin/icon-tableServiceReceipt.svg"
        />
      </picture>
    </section>
  </ng-container>

  <ng-container *ngIf="!isTableServiceActive">
    <header class="page-header">
      <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
    </header>
    <section class="flex flex--column align-center justify-evenly flex--2">
      <div class="square flex justify-center place-center">
        <img
          skin-part="image"
          src="/Channels/skin/icon-paymentSelectionCounter-preorder.svg"
          class="ui-button--icon color--accent p--40 size--400"
          alt=""
        />
      </div>
      <h3 class="text-center" [innerHtml]="'20210208002' | translate"></h3>
      <img skin-part="image" class="ui-button--icon size--140 color--accent bounceUp" alt="" src="/Channels/skin/icon-longArrowUp.svg" />
    </section>
  </ng-container>
</main>
