export enum AppRoutes {
  Banners = 'banners',
  ServiceTypeSelection = 'service-type-selection',
  PayTowerStartPage = 'pay-tower-start-page',
  Menu = 'menu',
  CheckoutError = 'checkout-error',
  BridgeOperation = 'bridge-operation',
  OrderNumber = 'order-number',
  CodView = 'cod-view',
  Promos = 'promos',
  Preorder = 'preorder',
  ReceiptOptions = 'receipt-options',
  ElectronicReceipt = 'electronic-receipt',
  EmailReceipt = 'email-receipt',
  SmsReceipt = 'sms-receipt',
}
