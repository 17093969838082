import { Directive, Input, OnInit } from '@angular/core';
import { AppBIService } from '../bi.service';
import { DotButton } from '@acrelec.foundation/dotsdk';

@Directive({
  selector: '[acrBiViewPromos]',
})
export class BiViewPromosDirective implements OnInit {
  @Input() public acrBiViewPromos: { products: DotButton[] | null } | undefined;

  constructor(private appBiService: AppBIService) {}

  public ngOnInit() {
    if (!this.acrBiViewPromos) {
      return;
    }
    if (!this.acrBiViewPromos.products) {
      return;
    }
    if (!this.acrBiViewPromos.products.length) {
      return;
    }
    this.appBiService.actions.impressions.viewPromotions(this.acrBiViewPromos.products);
  }
}
