import { Component } from '@angular/core';
import { enter, leave, pulseEnter } from '@dotxix/custom-theme/helpers/general-helpers.helper';
import { ThemeEditorStateService } from '@dotxix/custom-theme/services/theme-editor-state.service';

@Component({
  selector: 'acr-theme-editor',
  templateUrl: './theme-editor.component.html',
  styleUrls: ['theme-editor.component.scss'],
  animations: [enter, leave, pulseEnter],
})
export class ThemeEditorComponent {
  public editorOpenClose$ = this.themeEditorStateService.editorOpenClose$;
  public changesSent$ = this.themeEditorStateService.changesSent$;
  public showEditElementOverlay$ = this.themeEditorStateService.showEditElementOverlay$;
  public cssVariablesupdatesWereMade$ = this.themeEditorStateService.cssVariablesUpdatesWereMade$;
  public showSaveButton$ = this.themeEditorStateService.showSaveButton$;
  constructor(private themeEditorStateService: ThemeEditorStateService) {}
}
