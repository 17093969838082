import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DotButton, DotModifier } from 'dotsdk';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { ModifierSubgroupInput } from '@dotxix/models/interfaces/modifier-subgroup-input';
import { modifierButtonRemoveOne, selectModifierButtonInGroup } from '@dotxix/helpers/modifier-group.helper';
import { computeModifierSelectedQuantity, modifierButtonIncreaseQuantity } from '@dotxix/helpers/modifiers.helper';
import * as _ from 'lodash';
import { hasPrefixes, modifierButtonDecreasePrefixQuantity, modifierButtonIncreasePrefixQuantity } from '@dotxix/helpers/prefixes.helper';

@Component({
  selector: 'acr-button-modifier-subgroup',
  templateUrl: './button-modifier-subgroup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonModifierSubgroupComponent extends AbstractDynamicAnimatedPopup<ModifierSubgroupInput, DotModifier> implements OnInit {
  public modifier!: DotModifier;
  public itemPackButton!: DotButton;
  public totalGroupSelectedQuantity!: number;
  public minQuantityGroup!: number;
  public maxQuantityGroup!: number;
  public groupMaxQuantityReached = false;

  public ngOnInit(): void {
    this.modifier = _.cloneDeep(this.dataParams!.modifier);
    this.itemPackButton = this.modifier.Buttons.find((button) => button.Link === this.dataParams!.itemPackButtonLink)!;
    this.totalGroupSelectedQuantity = computeModifierSelectedQuantity(this.modifier);
    this.maxQuantityGroup = this.modifier.PageInfo.MaxQuantity;
    this.minQuantityGroup = this.modifier.PageInfo.MinQuantity;
    this.updateGroupMaxQuantityReached();
  }

  public async onModifierButtonClicked(button: DotButton) {
    // prefixes logic
    if (hasPrefixes(button)) {
      modifierButtonIncreasePrefixQuantity(button);
      this.onModifierChanged();
      return;
    }

    if (this.modifier) {
      selectModifierButtonInGroup(button, this.modifier, computeModifierSelectedQuantity(this.modifier));
      this.onModifierChanged();
    }
  }

  public async onModifierButtonQuantityChanged(button: DotButton, changedQuantity: number) {
    if (changedQuantity > 0) {
      this.onButtonModifierQuantityIncrease(button).then();
    } else {
      this.onButtonModifierQuantityDecrease(button);
    }
    this.onModifierChanged();
  }

  public onCancelClicked() {
    this.close();
  }

  public onConfirmClicked() {
    this.close(this.modifier);
  }

  private async onButtonModifierQuantityIncrease(modifierButton: DotButton) {
    if (hasPrefixes(modifierButton)) {
      modifierButtonIncreasePrefixQuantity(modifierButton);
      this.onModifierChanged();
      return;
    }

    modifierButtonIncreaseQuantity(
      modifierButton,
      this.modifier?.PageInfo.MaxQuantity || Number.MAX_SAFE_INTEGER,
      this.modifier ? computeModifierSelectedQuantity(this.modifier) : 0
    );
    this.onModifierChanged();
  }

  private onButtonModifierQuantityDecrease(modifierButton: DotButton) {
    if (hasPrefixes(modifierButton)) {
      modifierButtonDecreasePrefixQuantity(modifierButton);
      this.onModifierChanged();
      return;
    }

    modifierButtonRemoveOne(modifierButton);
    this.onModifierChanged();
  }

  private onModifierChanged() {
    if (!this.modifier) {
      return;
    }
    this.modifier.Buttons = _.cloneDeep(this.modifier?.Buttons) ?? [];
    this.itemPackButton = this.modifier.Buttons.find((button) => button.Link === this.dataParams!.itemPackButtonLink)!;
    this.updateGroupMaxQuantityReached();
  }

  private updateGroupMaxQuantityReached() {
    if (this.modifier?.PageInfo.MaxQuantity) {
      this.groupMaxQuantityReached = computeModifierSelectedQuantity(this.modifier) >= this.modifier.PageInfo.MaxQuantity;
    }
  }
}
