import { Pipe, PipeTransform } from '@angular/core';
import { DotButton } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'translateDlgMessageDictionary',
})
export class DlgMessageDictionaryTranslationPipe implements PipeTransform {
  constructor(protected translationsService: TranslationsService) {}

  public transform(button: DotButton): string {
    return this.translationsService.getTranslatedDlgMessage(button);
  }
}
