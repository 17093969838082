<main class="page-container receipt-options">
  <header class="page-header receipt-options__header">
    <img skin-part="image" src="/Channels/skin/logo.svg" class="logo" alt="" width="200rem" height="200rem" />
    <h2 class="receipt-options__header-title">{{ '2003004' | translate }}</h2>
  </header>
  <section class="receipt-options__container flex flex--column flex--center">
    <button
      *ngFor="let receiptOption of availableReceiptOptions"
      class="receipt-options__receipt-option-button ui-button ui-button--primary ui-button--xx-large"
      (click)="receiptOptionClick(receiptOption)"
    >
      <span>{{ receiptOptionLabel[receiptOption] | translate }}</span>
    </button>
  </section>
  <acr-footer></acr-footer>
</main>
