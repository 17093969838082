import { DotButton, DotButtonType, DotPage, OrderDiscountResponse, PromoNode, PromotionQuery, PromotionsService } from 'dotsdk';

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ButtonState } from '@dotxix/models/interfaces/button-state';
import { BasketService } from '@dotxix/services/basket.service';
import { createOrderDiscountInfoButton } from '@dotxix/helpers/order-discount.helper';
import { SessionService } from '@dotxix/services/session.service';
import { PromoDefinition } from '@dotxix/models/interfaces/promo-definition';
import { BehaviorSubject } from 'rxjs';
import { filterCatalogAvailable } from '@dotxix/helpers/catalog-availability.helper';
import { computePromotionPageMaxQuantity, getSelectedPromotionButtonsFromPage } from '@dotxix/helpers/promo.helper';

export interface PromotionsState {
  currentPromotionPage: DotPage | undefined;
  currentRootPage: DotPage | undefined;
  isRootPage: boolean;
  isLastPage: boolean;
  disableAddToBag: boolean;
  disableButtons: boolean;
  maxQuantity: number;
  allowedQuantity: number;
}

export const initialState: PromotionsState = {
  currentPromotionPage: undefined,
  currentRootPage: undefined,
  isRootPage: true,
  isLastPage: false,
  disableAddToBag: true,
  disableButtons: false,
  maxQuantity: 0,
  allowedQuantity: 0,
};
@Injectable({
  providedIn: 'root',
})
export class PromosService {
  public state$ = new BehaviorSubject<PromotionsState>(initialState);
  public currentStepIndex$ = new BehaviorSubject<number>(0);
  public currentPromoNode$ = new BehaviorSubject<PromoNode | null>(null);
  public currentPage: DotPage | undefined;

  public backupCurrentPageButtons: DotButton[] | undefined;

  public activePromos: PromoDefinition[] = [];
  public activeOrderDiscountUUID: string | null = null;
  public activeOrderDiscountPromoInfo: OrderDiscountResponse | null = null;

  private _pagesHistory: DotPage[] = [];

  constructor(
    private sessionService: SessionService,
    private basketService: BasketService
  ) {
    this.sessionService.onRestartSession.subscribe(() => this.resetPromos());
    this.basketService.buttons$.subscribe(() => this.onBasketButtonsChanged());
  }

  public initialize() {
    // disable auto-apply on lookup functionality for order discounts
    PromotionsService.getInstance().configuration.orderDiscountConfig.autoApplyOrderDiscountOnLookup = false;
  }

  public resetPromos() {
    this.activePromos = [];
  }

  public addActivePromo(promoDefinition: PromoDefinition) {
    this.activePromos = [...this.activePromos, promoDefinition];
  }

  public removeBarcode(promoUuid: string) {
    this.activePromos = this.activePromos.filter((promoDefinition) => promoDefinition.UUID !== promoUuid);
  }

  public isSameTypePromotionAllowed(barcode: string, promotionQuery: PromotionQuery) {
    if (promotionQuery.promoPayload instanceof OrderDiscountResponse) {
      return promotionQuery.maximumPromotionNumber >= 1;
    }
    if (promotionQuery.promoPayload instanceof PromoNode) {
      const numberOfPromotionsWithSameBarcode = this.countPromotionsWithBarcode(barcode);
      return !promotionQuery.maximumPromotionNumber || promotionQuery.maximumPromotionNumber > numberOfPromotionsWithSameBarcode;
    }
    // unknown promotion type
    return false;
  }

  public isSamePromotionAllowed(pageID: string, promotionQuery: PromotionQuery) {
    if (promotionQuery.promoPayload instanceof OrderDiscountResponse) {
      return promotionQuery.maximumPromotionNumber >= 1;
    }
    if (promotionQuery.promoPayload instanceof PromoNode) {
      const numberOfPromotionsWithSameBarcode = this.countPromotionsWithPageID(pageID);
      return !promotionQuery.maximumPromotionNumber || promotionQuery.maximumPromotionNumber > numberOfPromotionsWithSameBarcode;
    }
    return false;
  }

  public isPromotionAllowedForOrder(barcode: string, promotionQuery: PromotionQuery) {
    if (promotionQuery.promoPayload instanceof OrderDiscountResponse) {
      if (
        this.basketService.buttons.some(
          (button: DotButton<ButtonState>) => typeof button.state.$$OrderDiscount === 'number' && button.state.$$OrderDiscount >= 0
        )
      ) {
        return true;
      }
      return !promotionQuery.maximumAllowedPromotionsNumber || promotionQuery.maximumAllowedPromotionsNumber > this.activePromos.length;
    }
    if (promotionQuery.promoPayload instanceof PromoNode) {
      return !promotionQuery.maximumAllowedPromotionsNumber || promotionQuery.maximumAllowedPromotionsNumber > this.activePromos.length;
    }
    // unknown promotion type
    return false;
  }

  public hasOrderDiscount() {
    return !!this.activeOrderDiscountUUID;
  }

  public setOrderDiscount(barcode: string, promotionQuery: PromotionQuery) {
    if (this.activeOrderDiscountUUID) {
      this.removeBarcode(this.activeOrderDiscountUUID);
      (promotionQuery.promoPayload as OrderDiscountResponse).clearAppliedOrderDiscountFromElog();
    }

    const orderDiscountInfoButton = createOrderDiscountInfoButton(barcode, promotionQuery);
    this.addActivePromo(orderDiscountInfoButton.state.promoMetadata as PromoDefinition);
    this.activeOrderDiscountUUID = orderDiscountInfoButton.state.promoMetadata?.UUID ?? null;
    this.activeOrderDiscountPromoInfo = promotionQuery.promoPayload as OrderDiscountResponse;

    this.basketService.addOrderDiscountInfoButton(orderDiscountInfoButton);
    (promotionQuery.promoPayload as OrderDiscountResponse).applyOrderDiscountToElog();
  }

  public setPromotionSubPage(page: DotPage): void {
    if (this._pagesHistory.length === 0 && this.currentPage) {
      this._pagesHistory.push(this.currentPage);
    }
    this._pagesHistory.push(page);

    const filteredButtons = filterCatalogAvailable(page.Buttons);
    this.backupCurrentPageButtons = _.cloneDeep(filteredButtons);

    this.updateState();
  }

  public loadPromoNode(promoNode: PromoNode, previousSelections: DotButton[]) {
    this.currentPromoNode$.next(promoNode);
    this.currentPage = this.currentPromoNode$.value?.getData(previousSelections);
    const filteredButtons = filterCatalogAvailable(this.currentPage?.Buttons ?? []);

    this.backupCurrentPageButtons = _.cloneDeep(filteredButtons);
    this.updateState();
  }

  public updateState(): void {
    this.state$.next({
      ...this.state$.value,
      currentPromotionPage: this.getCurrentPromotionPage(),
      currentRootPage: this.currentPage,
      isRootPage: this.isRootPage(),
      isLastPage: this.isLastPage(),
      disableAddToBag: (this.currentPage ? this.checkNumberOfSelectedItems(this.currentPage) : 0) < 1,
      disableButtons: this.getDisableButtons(),
      maxQuantity: this.currentPage ? computePromotionPageMaxQuantity(this.currentPage) : 0,
      allowedQuantity: this.getPageAllowedQuantity(),
    });
  }

  public goToPreviousStep() {
    if (this._pagesHistory.length > 1) {
      this._pagesHistory.pop();
      this.updateState();
      if (this._pagesHistory.length === 1) {
        this._pagesHistory.length = 0;
      }
      return;
    }
    if (this.currentPromoNode$.value?.back) {
      this.currentStepIndex$.next(this.currentStepIndex$.value - 1);
      this.loadPromoNode(this.currentPromoNode$.value.back, this.currentPromoNode$.value.back.previousSelections);
    }
  }
  public goToNextStep() {
    this.currentStepIndex$.next(this.currentStepIndex$.value + 1);
    if (this.currentPromoNode$.value && this.state$.value.currentPromotionPage) {
      this.loadPromoNode(this.currentPromoNode$.value.next, getSelectedPromotionButtonsFromPage(this.state$.value.currentPromotionPage));
    }
  }

  public resetPromoState() {
    this.state$.next(initialState);
    this._pagesHistory.length = 0;
    this.currentStepIndex$.next(0);
  }

  private onBasketButtonsChanged() {
    this.removeActivePromosWithoutCorrespondingButtonInBasket();
  }

  private removeActivePromosWithoutCorrespondingButtonInBasket() {
    if (
      this.activeOrderDiscountUUID &&
      this.basketService.buttons$.value.every((basketButton) => basketButton.state.promoMetadata?.UUID !== this.activeOrderDiscountUUID)
    ) {
      this.clearActiveOrderDiscount();
    }

    this.activePromos = this.activePromos.filter(
      (promoDef) =>
        promoDef.UUID === this.activeOrderDiscountUUID ||
        this.basketService.buttons$.value.some((basketButton) => basketButton.state.promoMetadata?.UUID === promoDef.UUID)
    );
  }

  private clearActiveOrderDiscount() {
    this.activeOrderDiscountPromoInfo?.clearAppliedOrderDiscountFromElog();
    this.activeOrderDiscountPromoInfo = null;
    this.activeOrderDiscountUUID = null;
    this.activePromos = this.activePromos.filter((promoDef) => promoDef.UUID !== this.activeOrderDiscountUUID);
  }

  private countPromotionsWithBarcode(barcode: string) {
    return this.activePromos.reduce((acc, promoDef) => {
      return promoDef.barcode === barcode ? acc + 1 : acc;
    }, 0);
  }

  private countPromotionsWithPageID(pageID: string) {
    return this.activePromos.reduce((acc, promoDef) => {
      return promoDef.pageID === pageID ? acc + 1 : acc;
    }, 0);
  }

  private isRootPage(): boolean {
    return this._pagesHistory.length <= 1;
  }

  private isLastPage(): boolean {
    return !this.currentPromoNode$.value?.next;
  }

  private getCurrentPromotionPage(): DotPage | undefined {
    if (this._pagesHistory.length > 1) {
      return this._pagesHistory[this._pagesHistory.length - 1];
    } else {
      return this.currentPage;
    }
  }
  private checkNumberOfSelectedItems(page: DotPage): number {
    let numberOfSelectedItems = 0;

    page.Buttons.forEach((btn) => {
      if (btn.ButtonType === DotButtonType.PAGE_BUTTON || btn.ButtonType === DotButtonType.ITEM_PACK_BUTTON) {
        numberOfSelectedItems += this.checkNumberOfSelectedItems(btn.Page);
      } else {
        numberOfSelectedItems += btn.Selected && btn.quantity > 0 ? btn.quantity : 0;
      }
    });

    return numberOfSelectedItems;
  }

  private getDisableButtons(): boolean {
    if (!this.currentPage) {
      return false;
    }
    if (!this.currentPage.MaxQty) {
      return false;
    }
    return this.checkNumberOfSelectedItems(this.currentPage) >= this.currentPage.MaxQty;
  }

  private getPageAllowedQuantity() {
    if (!this.currentPage?.MaxQty) {
      return Number.MAX_SAFE_INTEGER;
    }
    // return Math.max(0, this.currentPage.MaxQty - this.checkNumberOfSelectedItems(this.currentPage));
    return Math.max(0, this.currentPage?.MaxQty ?? 0);
  }
}
