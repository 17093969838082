import { Component } from '@angular/core';
import { TranslationsService } from '../../services/translations/translations.service';

/**
 * The component forces the view of the parent component to rerender once the language has changed.
 * It is useful for components with changeDetection: ChangeDetectionStrategy.OnPush
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'language-change-view-trigger',
  template: `<div [attr.dummy]="languageCode$ | async"></div>`,
})
export class LanguageChangeViewTriggerComponent {
  constructor(private translationsService: TranslationsService) {}
  public languageCode$ = this.translationsService.currentLanguageCode$;
}
