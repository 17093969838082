import { Injectable } from '@angular/core';
import { HmacSHA1 } from 'crypto-js';
import { appLogger } from '@dotxix/log-manager';
import { generateUUID, PosElogHandler, PosRefintService, Xhr } from 'dotsdk';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BasketService } from '@dotxix/services/basket.service';
import { atpMarketIdAndNestIdentifier, extractUserSelectionFromBasket } from '@dotxix/helpers/pay-tower.helper';
import { PayTowerOrder } from '@dotxix/models/interfaces/pay-tower-order.interface';

const logPrefix = '[Pay Tower Service]: ';

@Injectable({
  providedIn: 'root',
})
export class PayTowerService {
  private xhr = new Xhr();

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private basketService: BasketService
  ) {}

  public extractPayTowerOrderIdFromScannedString(scannedString: string): string | null {
    const testString = scannedString ?? '';
    const matches = testString.match(/^pay-tower:\/\/([a-z0-9-]+)$/);
    return matches ? matches[1] ?? null : null;
  }

  public async retrieveOrder(orderId: string): Promise<PayTowerOrder | null> {
    if (!this.applicationSettingsService.settings$.value.payTowerOrderApiUrl) {
      appLogger.debug(`${logPrefix}Bundle Setting payTowerOrderApiUrl is not defined, can not retrieve order`);
      return null;
    }

    const authorization = await this.apiAuthorizationKey();
    if (!authorization) {
      appLogger.debug(`${logPrefix}Failed to retrieve api authorization key`);
      return null;
    }

    const recalledOrderResponse = await this.xhr
      .makeRequest({
        method: 'GET',
        url: `${this.applicationSettingsService.settings$.value.payTowerOrderApiUrl}/api/v1/kiosk/recallOrder/${orderId}`,
        headers: this.orderApiRequestHeaders(authorization),
        useDds: true,
      })
      .catch((e) => {
        appLogger.debug(`${logPrefix}recall order request failure ${e?.message}`);
        return null;
      });

    if (recalledOrderResponse) {
      try {
        return JSON.parse(recalledOrderResponse.response);
      } catch (e) {
        appLogger.debug(`${logPrefix}Failed to parse recalled order response ${e}`);
      }
    }

    return null;
  }

  public async saveOrder(): Promise<string | null> {
    if (!this.applicationSettingsService.settings$.value.payTowerOrderApiUrl) {
      appLogger.debug(`${logPrefix}Bundle Setting payTowerOrderApiUrl is not defined, can not save order for pay tower`);
      return null;
    }

    const authorization = await this.apiAuthorizationKey();
    if (!authorization) {
      appLogger.debug(`${logPrefix}Failed to retrieve api authorization key`);
      return null;
    }

    const orderId = generateUUID();
    appLogger.debug(`${logPrefix}saving order '${orderId}'`);

    const responseObject = await this.xhr
      .makeRequest({
        method: 'POST',
        url: `${this.applicationSettingsService.settings$.value.payTowerOrderApiUrl}/api/v1/kiosk/storeOrder`,
        payload: JSON.stringify(this.storeOrderPayload(orderId)),
        headers: this.orderApiRequestHeaders(authorization),
        useDds: true,
      })
      .catch((e) => {
        appLogger.debug(`${logPrefix}store order request failure ${e?.message}`);
        return null;
      });
    if (responseObject) {
      appLogger.debug(`${logPrefix}'${orderId}' order saved successfully`);
      return orderId;
    } else {
      return null;
    }
  }

  private storeOrderPayload(orderId: string) {
    return {
      MetaData: {
        storeId: PosElogHandler.getInstance().posConfig.posHeader.storeCode ?? '',
        orderId,
        service: 0,
        refint: PosRefintService.getInstance()._refInt,
        totalAmount: 0,
        orderTimestamp: new Date().getTime(),
        endStatus: 31,
        POSOrderNumber: '0',
      },
      Payload: {
        KioskOrder: {
          parentOrderID: PosElogHandler.getInstance().posConfig.posHeader.AUID,
          basket: extractUserSelectionFromBasket(this.basketService.buttons),
          servingLocation: PosElogHandler.getInstance().posConfig.posHeader.servingLocation,
        },
        BIOrder: null,
      },
    };
  }

  private orderApiRequestHeaders(storeOrderAuthorizationKey: string) {
    const [marketID, nestIdentifier] = atpMarketIdAndNestIdentifier();
    return [
      {
        headerName: 'Authorization',
        headerValue: `otp ${storeOrderAuthorizationKey}`,
      },
      {
        headerName: 'marketID',
        headerValue: marketID,
      },
      {
        headerName: 'nestIdentifier',
        headerValue: nestIdentifier,
      },
      {
        headerName: 'scrambledKeyVersion',
        headerValue: 'v1',
      },
      {
        headerName: 'Content-Type',
        headerValue: 'application/json',
      },
    ];
  }

  private async requestAuthorizationKey() {
    const fullUrl = `${this.applicationSettingsService.settings$.value.payTowerOrderApiUrl}/api/Auth`;
    return await this.xhr
      .makeRequest({
        url: fullUrl,
        method: 'GET',
        headers: [{ headerName: 'Accept', headerValue: '*/*' }],
        useDds: true,
      })
      .catch((e) => {
        appLogger.debug(`${logPrefix}store order api authorization failure ${e?.message}`);
        return null;
      });
  }

  public async apiAuthorizationKey(): Promise<string | null> {
    const authorizationResult = await this.requestAuthorizationKey();
    if (!authorizationResult) {
      return null;
    }

    const { challenge, keyId } = JSON.parse(authorizationResult.response) ?? {};
    const foundSpecificKeyValue = this.applicationSettingsService.settings$.value.payTowerOrderApiAuthKeys.find((f) => f.keyId === keyId)
      ?.keyValue;
    if (challenge && foundSpecificKeyValue) {
      const decrypt = HmacSHA1(challenge, foundSpecificKeyValue).toString();
      return btoa(`${challenge}:${decrypt}`);
    }

    return null;
  }
}
