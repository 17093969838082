import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OsirisComponent } from '@dotxix/osiris/components/osiris-login.component';
import { OsirisLoginScanComponent } from '@dotxix/osiris/components/osiris-login-scan/osiris-login-scan.component';
import { ComponentsModules } from '@dotxix/components/components.module';
import { OsirisOrderHistoryComponent } from '@dotxix/osiris/components/osiris-order-history/osiris-order-history.component';
import { OsirisOrderCardComponent } from '@dotxix/osiris/components/osiris-order-card/osiris-order-card.component';
import { OsirisOrderReviewComponent } from '@dotxix/osiris/components/osiris-order-review/osiris-order-review.component';
import { OsirisProcessOrderComponent } from '@dotxix/osiris/components/osiris-process-order/osiris-process-order.component';
import { OsirisRoutingModule } from '@dotxix/osiris/osiris-routing.module';
import { BulletStepsComponent } from '@dotxix/osiris/components/face-recall/bullet-steps/bullet-steps.component';
import { FaceRecallComponent } from '@dotxix/osiris/components/face-recall/face-recall/face-recall.component';
import { CameraCountdownComponent } from '@dotxix/osiris/components/face-recall/camera-countdown/camera-countdown.component';
import { ScanningFaceComponent } from '@dotxix/osiris/components/face-recall/scanning-face/scanning-face.component';
import { CustomerLoginComponent } from '@dotxix/osiris/components/face-recall/customer-login/customer-login.component';
import { FaceRecallErrorComponent } from '@dotxix/osiris/components/face-recall/face-recall-error/face-recall-error.component';
import { AccountCreatedComponent } from '@dotxix/osiris/components/face-recall/account-created/account-created.component';
import { AccountRetrievedComponent } from '@dotxix/osiris/components/face-recall/account-retrieved/account-retrieved.component';
import { CustomerAlreadyExistingComponent } from '@dotxix/osiris/components/face-recall/customer-already-existing/customer-already-existing.component';
import { HesetQrCodeLoginComponent } from '@dotxix/osiris/components/heset/heset-qr-code-login/heset-qr-code-login.component';
import { OsirisScanComponent } from '@dotxix/osiris/components/osiris-scan/osiris-scan.component';
import { OsirisLoginKeyboardComponent } from '@dotxix/osiris/components/osiris-login-keyboard/osiris-login-keyboard.component';
import { OsirisOrderNumberInfoComponent } from '@dotxix/osiris/components/osiris-order-number-info/osiris-order-number-info.component';
import { PipesModule } from '@dotxix/pipes/pipes.modules';
import { QrcodeRegisterIdentityComponent } from './components/face-recall/qrcode-register-identity/qrcode-register-identity.component';
import { EnterPinComponent } from './components/face-recall/enter-pin/enter-pin.component';
import { QrcodePinAttemptsComponent } from './components/face-recall/qrcode-pin-attempts/qrcode-pin-attempts.component';

@NgModule({
  declarations: [
    OsirisComponent,
    OsirisLoginScanComponent,
    OsirisOrderHistoryComponent,
    OsirisOrderCardComponent,
    OsirisOrderReviewComponent,
    OsirisProcessOrderComponent,
    BulletStepsComponent,
    FaceRecallComponent,
    CameraCountdownComponent,
    ScanningFaceComponent,
    CustomerLoginComponent,
    FaceRecallErrorComponent,
    AccountCreatedComponent,
    AccountRetrievedComponent,
    CustomerAlreadyExistingComponent,
    HesetQrCodeLoginComponent,
    OsirisScanComponent,
    OsirisLoginKeyboardComponent,
    OsirisOrderNumberInfoComponent,
    QrcodeRegisterIdentityComponent,
    EnterPinComponent,
    QrcodePinAttemptsComponent,
  ],
  imports: [CommonModule, PipesModule, ComponentsModules, OsirisRoutingModule],
  exports: [OsirisComponent, OsirisLoginScanComponent, OsirisOrderHistoryComponent, OsirisOrderNumberInfoComponent, EnterPinComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class OsirisModule {}
