import { Pipe, PipeTransform } from '@angular/core';
import { DotPrefixes } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'translatePrefixesName',
})
export class PrefixesNameTranslationPipe implements PipeTransform {
  constructor(protected translationsService: TranslationsService) {}

  public transform(prefix: DotPrefixes): string | null {
    if (prefix && prefix.NameDictionary && Object.keys(prefix.NameDictionary).length !== 0) {
      const t = prefix.NameDictionary[this.translationsService.currentLanguageUpperCase];
      return t ? t : prefix.NameDictionary.DEF;
    } else {
      return null;
    }
  }
}
