import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HesetWebSocketService {
  private hubConnection: signalR.HubConnection | null = null;
  private _onUserAuthenticated: Subject<string> = new Subject();
  private _onUnknownUser: Subject<null> = new Subject();

  constructor(private appSettings: ApplicationSettingsService) {}

  public startConnection(authToken: string): Promise<void> {
    if (this.hubConnection) {
      return new Promise((res) => res());
    }
    this.hubConnection = null;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.appSettings.settings$.value.hesetWebSocketUrl, { withCredentials: false })
      .withAutomaticReconnect()
      .build();
    return new Promise((res, rej) => {
      this.hubConnection!.start()
        .then(() => {
          this.hubConnection!.invoke('OnKioskConnectedAsync', authToken).then();
          this.hubConnection!.on('UserAuthenticated', (userId: string) => {
            this._onUserAuthenticated.next(userId);
          });
          this.hubConnection!.on('UnknownUserAuthenticated', () => {
            this._onUnknownUser.next(null);
          });
          res();
        })
        .catch((err) => {
          this.hubConnection = null;
          rej(`[startConnection]:Error while starting connection ${err}`);
        });
    });
  }

  public onUserAuthenticated(): Observable<string> {
    return this._onUserAuthenticated.asObservable();
  }

  public onUnknownUser(): Observable<null> {
    return this._onUnknownUser.asObservable();
  }
}
