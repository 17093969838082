import { Component } from '@angular/core';
import { AbstractDynamicAnimatedPopup } from '@dotxix/animation/abstract-animated-popup';
import { OsirisLoginKeyboardService } from '@dotxix/osiris/services/osiris-login-keyboard.service';

@Component({
  selector: 'acr-osiris-login-keyboard',
  templateUrl: 'osiris-login-keyboard.component.html',
})
export class OsirisLoginKeyboardComponent extends AbstractDynamicAnimatedPopup<void, void> {
  public customerId = '';
  /**
   * Limit number chars of osiris login keyboard component
   */
  public limitNumberChars = 5;

  constructor(private osirisLoginKeyboardService: OsirisLoginKeyboardService) {
    super();
  }

  public onCancelClicked() {
    this.osirisLoginKeyboardService.keyboardCanceled();
  }

  public onInputChanged(customerId: string) {
    this.customerId = customerId;
  }

  public onConfirmClicked() {
    this.osirisLoginKeyboardService.validateKeyboardInput(this.customerId).then();
  }
}
