import { Component, OnInit } from '@angular/core';
import { FacePayProgressMessages, FacePayProgressStateService } from '@dotxix/services/face-pay-progress-state.service';

@Component({
  selector: 'acr-face-pay-progress',
  template: ` <main class="page-container align-center justify-evenly">
    <div class="spacer--100"></div>
    <acr-spinner></acr-spinner>
    <div class="text-center" *ngIf="(getMessage$ | async) === FacePayProgressMessages.Error">
      <h3 class="color--red" [innerHtml]="'2022050301' | translate"></h3>
      <h3 [innerHtml]="'2022110103' | translate"></h3>
    </div>
    <div class="text-center" *ngIf="(getMessage$ | async) === FacePayProgressMessages.Waiting">
      <h3 [innerHtml]="'2022110102' | translate"></h3>
    </div>
  </main>`,
})
export class FacePayprogressComponent implements OnInit {
  public getMessage$ = this.facePayProgressStateService.getMessage$;
  public FacePayProgressMessages = FacePayProgressMessages;
  constructor(private facePayProgressStateService: FacePayProgressStateService) {}

  ngOnInit() {
    this.facePayProgressStateService.payWithFace();
  }
}
