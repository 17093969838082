import { AutoPopFeatMode } from '@dotxix/models/enums/auto-pop-feat';
import { calculateButtonPrice, DotButton, DotButtonStatus, DotButtonType, DotSessionService } from 'dotsdk';

export const isItemPackButton = (button: DotButton) => button.ButtonType === DotButtonType.ITEM_PACK_BUTTON;
export const isNotItemPackButton = (button: DotButton) => button.ButtonType !== DotButtonType.ITEM_PACK_BUTTON;
export const isButtonDisabled = (button: DotButton) => button.ButtonStatus === DotButtonStatus.DISABLED;
export const hasMakeItAMeal = (button: DotButton) =>
  button.MakeItAMeal &&
  button.MakeItAMeal.length > 0 &&
  button.MakeItAMeal.some((btn) => btn.ButtonType === DotButtonType.MENU_BUTTON || btn.ButtonType === DotButtonType.ITEM_BUTTON);
export const hasModifiersWithMenuModifierTemplate = (product: DotButton) =>
  product.hasModifiers && product.ModifiersPage.Modifiers.some((m) => m.PageInfo.ModifierTemplate.toLowerCase() === 'menu');
export const computeButtonDisplayPrice = (button: DotButton, minPrice: number): number => {
  if (isItemPackButton(button)) {
    return 0;
  }
  return Math.max(0, calculateButtonPrice(button, DotSessionService.getInstance().currentPosServingLocation) - minPrice);
};
export const computeAutoPopFeatDefaults = (componentButton: DotButton): void => {
  componentButton.ModifiersPage?.Modifiers.find((modifier) => {
    switch (modifier.PageInfo.AutoPopFeat) {
      case AutoPopFeatMode.NOT_POP_ON_ORDER_TAKE_DEFAULTS_POP_ON_MODIFY:
      case AutoPopFeatMode.POP_ON_ORDER_TAKE_DEFAULTS_POP_ON_MODIFY:
      case AutoPopFeatMode.POP_ON_ORDER_TAKE_DEFAULTS_NOT_POP_ON_MODIFY:
      case AutoPopFeatMode.NOT_POP_ON_ORDER_TAKE_DEFAULTS_NOT_POP_ON_MODIFY: {
        const includedQuantityButtonsLength = modifier.Buttons.filter((button) => button.IncludedQuantity > 0)?.length;
        if (modifier.PageInfo.MaxQuantity >= includedQuantityButtonsLength) {
          modifier.Buttons.forEach((button) => {
            if (button.IncludedQuantity > 0) {
              button.Selected = true;
              button.quantity = button.IncludedQuantity;
            }
          });
        }
        break;
      }
    }
  });
};
export const sortByMinPrice = (buttons: DotButton[]) => buttons.sort((a, b) => (a.MinPrice > b.MinPrice ? -1 : 1));
