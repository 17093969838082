import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subject, timer } from 'rxjs';
import { ApplicationSettingsService } from '@dotxix/services/app-settings.service';
import { BasketService } from '@dotxix/services/basket.service';
import { SessionService } from '@dotxix/services/session.service';
import { OsirisRoutes } from '@dotxix/osiris/osiris-routes';
import { Router } from '@angular/router';
import { OsirisOrder } from '@dotxix/osiris/models/interfaces/osiris-order.interface';
import { DotButton, PosElogHandler, PosPaymentStatus } from 'dotsdk';
import { OsirisStateService } from '@dotxix/osiris/services/osiris-state.service';
import { OsirisState } from '@dotxix/osiris/models/interfaces/osiris-state.interface';
import { OsirisOrderStateService } from '@dotxix/osiris/services/osiris-orders-state.service';
import { OsirisLoginService } from '@dotxix/osiris/services/osiris-login.service';
import { OsirisService } from '@dotxix/osiris/services/osiris.service';
import { OrderRecallModes } from '@dotxix/models/enums/order-recall-modes';
import { AppRoutes } from '@dotxix/app-routes';
import { OrderNumberSteps } from '@dotxix/osiris/models/enums/order-number-steps.enum';
import { OsirisLoginFaceRecallService } from '@dotxix/osiris/services/osiris-login-face-recall.service';
import { ParentOrderIdService } from './parent-order-id.service';
import { generateAUIDOnPosHeader } from '../helpers/osiris-elog.helper';

@Injectable({
  providedIn: 'root',
})
export class OsirisFlowService {
  public displayMainMenu$ = new Subject<void>();
  public checkoutFinished$ = new Subject<void>();
  public signalSessionStart$ = this.sessionService.sessionStarted;

  private state: BehaviorSubject<OsirisState> = this.osirisStateService.state$;
  private faceRecallLoginTimeout: NodeJS.Timeout | undefined;

  constructor(
    private osirisStateService: OsirisStateService,
    private osirisLoginService: OsirisLoginService,
    private osirisService: OsirisService,
    private router: Router,
    private sessionService: SessionService,
    private appSettings: ApplicationSettingsService,
    private basketService: BasketService,
    private osirisOrdersState: OsirisOrderStateService,
    private osirisLoginFaceRecallService: OsirisLoginFaceRecallService,
    private parentOrderIdService: ParentOrderIdService
  ) {
    this.osirisLoginService.existingUserAuthenticated$.subscribe(() => this.goToOrderHistory());
    this.osirisLoginService.newUserAuthenticated$.subscribe(() => this.goToMenu());
    this.osirisLoginService.continueWithoutUser$.subscribe(() => this.goToMenu());
    this.osirisLoginService.loginFinished$.subscribe(() => this.goToMenu());
    this.osirisLoginFaceRecallService.cancelEndScreenFaceRecallLogin$.subscribe(() => this.displayOrderNumberView());
    this.osirisLoginFaceRecallService.cancelEndScreenFaceRecallLoginNoRegistration$.subscribe(() => this.displayOrderNumberView(true));
    this.osirisLoginFaceRecallService.endScreenLoggedIn$.subscribe(() => this.displayOrderNumberView());
    PosElogHandler.getInstance().beforePosConfigReset.subscribe((posConfig) => {
      if (PosElogHandler.getInstance().posConfig.posHeader.AUID) {
        this.storeOrdersOnSessionType(posConfig.posHeader.paymentPosStatus ?? undefined);
      }
    });
    this.signalSessionStart$.subscribe(() => generateAUIDOnPosHeader());
  }

  public onSessionStarted() {
    if (
      this.appSettings.settings$.value.orderRecallMode === OrderRecallModes.Full &&
      this.appSettings.settings$.value.availableOsirisLoginMethods.some((method) => method.IsEnabled)
    ) {
      this.osirisLoginService.goToLogin().then(() => {});
    } else {
      this.goToMenu();
    }
  }

  public async reorder(order: OsirisOrder) {
    const basketButtons = await this.recallOrderAsBasket(order);
    if (basketButtons.length) {
      this.addOrderToBasket(basketButtons);
      this.parentOrderIdService.setParentOrderId(order.i);
    } else {
      this.recallFailed(order);
    }
  }

  public addOrderToBasket(basketButtons: DotButton[]) {
    basketButtons.forEach((button) => this.basketService.addButtonToBasket(button));
    this.goToMenu();
  }

  public async viewFullOrder(order: OsirisOrder) {
    const basketButtons = await this.recallOrderAsBasket(order);
    if (basketButtons.length) {
      this.reviewOrder(order, basketButtons);
    } else {
      this.recallFailed(order);
    }
  }

  public reviewOrder(order: OsirisOrder, basketButtons: DotButton[]) {
    this.osirisStateService.reviewOrder(order, basketButtons);
    this.router.navigate([OsirisRoutes.OrderReview]).then();
  }

  public reviewOrderCanceled() {
    this.osirisStateService.reviewOrderCanceled();
    this.goToOrderHistory();
  }

  public reviewOrderAccepted() {
    this.addOrderToBasket(this.state.value.reviewOrder?.basketButtons ?? []);
    this.parentOrderIdService.setParentOrderId(this.state.value.reviewOrder?.orderInfo.orderId ?? null);
    this.osirisStateService.reviewOrderAccepted();
  }

  public goToOrderHistory() {
    this.router.navigate([OsirisRoutes.OrderHistory]).then();
  }

  public goToMenu() {
    this.displayMainMenu$.next();
  }

  public async displayOrderNumberView(endScreenNoRegistration = false) {
    await this.router.navigate([AppRoutes.OrderNumber]);
    if (this.appSettings.settings$.value.orderRecallMode === OrderRecallModes.Disabled) {
      await this.endCheckoutWithDelay();
    }
    if (this.appSettings.settings$.value.orderRecallMode === OrderRecallModes.LogOrdersNoUi) {
      this.osirisService.storeAnonymousOrder();
      await this.endCheckoutWithDelay();
    }
    if (this.appSettings.settings$.value.orderRecallMode === OrderRecallModes.Full) {
      const customerId = this.osirisOrdersState.getCustomerIdentification()?.customerID;
      if (customerId) {
        this.saveCustomerOrder(customerId);
      } else {
        if (this.displayFaceRecallLoginOptionAtEndOfOrder() && !endScreenNoRegistration) {
          this.osirisStateService.setOrderNumberStep(OrderNumberSteps.FaceRecallLogin);
          this.faceRecallLoginTimeout = setTimeout(() => {
            this.checkoutFinished$.next();
          }, 5000);
        } else {
          this.osirisService.storeAnonymousOrder();
          this.osirisStateService.setOrderNumberStep(OrderNumberSteps.DisableFaceRecallLoginOption);
          await this.endCheckoutWithDelay();
        }
      }
    }
  }

  public orderNumberFaceRecallRegistrationRequested() {
    clearTimeout(this.faceRecallLoginTimeout);
    this.osirisLoginFaceRecallService.endScreenLoginWithFaceRecall();
  }

  public storeOrder() {
    if (this.appSettings.settings$.value.orderRecallMode === OrderRecallModes.Disabled) {
      return;
    }
    if (this.appSettings.settings$.value.orderRecallMode === OrderRecallModes.LogOrdersNoUi) {
      this.osirisService.storeAnonymousOrder();
    }
    if (this.appSettings.settings$.value.orderRecallMode === OrderRecallModes.Full) {
      const customerId = this.osirisOrdersState.getCustomerIdentification()?.customerID;
      if (customerId) {
        this.osirisService.storeCustomerOrder(customerId);
      } else {
        this.osirisService.storeAnonymousOrder();
      }
    }
  }

  private async endCheckoutWithDelay() {
    await lastValueFrom(timer(5000));
    this.checkoutFinished$.next();
  }

  private saveCustomerOrder(customerId: string) {
    this.osirisService
      .storeCustomerOrder(customerId)
      .then(() => this.osirisStateService.setOrderNumberStep(OrderNumberSteps.OrderSaved))
      .catch(() => {
        this.osirisStateService.setOrderNumberStep(OrderNumberSteps.OrderFail);
      });
    setTimeout(async () => this.checkoutFinished$.next(), 5000);
  }

  private async recallOrderAsBasket(order: OsirisOrder) {
    this.osirisStateService.processOrderStarted();
    this.router.navigate([OsirisRoutes.ProcessOrder]).then();
    const basketButtons = await this.osirisService.recallOrderAsBasket(order).catch(() => {
      this.osirisStateService.processOrderError();
      return [];
    });
    this.osirisStateService.processOrderSuccess();
    return basketButtons;
  }

  private recallFailed(payload?: OsirisOrder, delayInMs = 2000) {
    this.osirisStateService.recallOrderError();
    setTimeout(() => {
      this.router.navigate([OsirisRoutes.OrderHistory], { state: payload ? { failedOrderToBeRemoved: payload } : {} });
    }, delayInMs);
  }

  private displayFaceRecallLoginOptionAtEndOfOrder() {
    return this.appSettings.settings$.value.availableOsirisLoginMethods.find(
      (method) => method.Type === 'facerecall' && method.DisplayLoginOptionEndOfOrder && method.IsEnabled
    );
  }

  public storeOrdersOnSessionType(posPaymentStatus: PosPaymentStatus | undefined) {
    if (this.appSettings.settings$.value.orderRecallMode !== OrderRecallModes.Disabled) {
      if (this.appSettings.settings$.value.addFailedOrdersIntoOsiris) {
        this.osirisService.storeAnonymousOrder();
      } else {
        if (
          posPaymentStatus == PosPaymentStatus.PAYMENT_AND_POS_SUCCESSFUL_INTEGRATION ||
          posPaymentStatus == PosPaymentStatus.POS_TRANSMISSION_FAILURE_AFTER_PAYMENT
        ) {
          this.osirisService.storeAnonymousOrder();
        }
      }
    }
  }
}
