import { Component, Input } from '@angular/core';
import { ViewAmount } from '@dotxix/payment/models/view-amount.model';

@Component({
  selector: 'acr-glory-cash-message',
  templateUrl: './glory-cash-message.component.html',
  host: { class: 'flex flex--column full-height' },
})
export class GloryCashMessageComponent {
  @Input() public message: string | undefined;
  @Input() public amounts: ViewAmount[] | undefined;
}
