import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'acr-camera-countdown',
  host: { class: 'full-height full-width' },
  template: `
    <language-change-view-trigger></language-change-view-trigger>
    <div class="container">
      <div class="messages">
        <div>{{ '20220426011' | translate }}</div>
        <div>{{ '20220426012' | translate }}</div>
      </div>
      <div class="fs--120">{{ counter$ | async }}</div>
    </div>
  `,
  styleUrls: ['../face-recall-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraCountdownComponent implements OnInit {
  public counter$ = new BehaviorSubject<number>(3);

  public ngOnInit(): void {
    this.countDown();
  }

  private countDown() {
    const interval = setInterval(() => {
      this.counter$.next(this.counter$.value - 1);
      if (this.counter$.value < 1) {
        clearInterval(interval);
      }
    }, 1000);
  }
}
