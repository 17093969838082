import {
  calculateButtonPrice,
  DotButton,
  DotButtonType,
  DotSdkMocksManager,
  getRandomUniqueCatalogsItems,
  IJsonMocksOverride,
  mockActionButton,
  MockPromptEffects,
  TAB_TYPES,
} from 'dotsdk';

import { RecommendedProduct } from '@dotxix/services/suggestions/models/recommended-product';
import { RecommendedProductType } from '@dotxix/services/suggestions/models/recommended-product-type';

export class RecoOrderSuggestionsOverrider implements IJsonMocksOverride {
  private uiPanel: HTMLElement | undefined;
  private dotSdkMockManager = DotSdkMocksManager.getInstance();

  public processInputArgs(mockJson: RecommendedProduct[], orderId: number, engine: string, count: number): Promise<RecommendedProduct[]> {
    return new Promise((resolve, reject) => {
      const btnMockedFile = mockActionButton('Mocked File');
      const btnRandCatalog = mockActionButton('Random from Catalogs');
      const btnEmpty = mockActionButton('Empty');
      const btnFail = mockActionButton('Error');
      const btnUnauthorized = mockActionButton('Unauthorized');

      this.uiPanel = this.dotSdkMockManager.addMockPrompt({
        title: `Reco Order Suggestions`,
        interactiveElements: [
          { htmlElement: btnMockedFile, clickHandler: () => resolve(mockJson) },
          { htmlElement: btnRandCatalog, clickHandler: this.randomFromCatalogs.bind(this, count, resolve) },
          { htmlElement: btnEmpty, clickHandler: () => resolve([]) },
          { htmlElement: btnFail, clickHandler: () => reject('Error') },
          { htmlElement: btnUnauthorized, clickHandler: () => reject({ status: 401 }) },
        ],
        canRememberChoice: true,
        uniqueId: this.dotSdkMockManager.sanitizePersistedMockReplayKey(`reco-order-suggestions`),
        hostTabType: TAB_TYPES.PROMISED_MOCKS,
        afterAppendEffects: [MockPromptEffects.OPEN_DRAWER_TAB_AND_FOCUS_MOCK_PROMPT],
        afterCTAEffects: [MockPromptEffects.REMOVE_PANEL_AND_CLOSE_DRAWER_IF_HOST_TAB_EMPTY],
      });
    });
  }
  private randomFromCatalogs(count: number, resolve: (value: RecommendedProduct[] | PromiseLike<RecommendedProduct[]>) => void) {
    const randomCatalogButtons = getRandomUniqueCatalogsItems({
      count,
      simpleItemsOptions: { chanceWeight: 1, extraFilter: (button) => calculateButtonPrice(button) > 0 },
      comboItemsOptions: { chanceWeight: 1, extraFilter: (button) => calculateButtonPrice(button) > 0 },
    });

    const itemsToreturn: RecommendedProduct[] = randomCatalogButtons.map<RecommendedProduct>((randomButton: DotButton, index) => {
      return {
        id: index,
        key: `item-${index}`,
        productId: randomButton.Link,
        productType:
          randomButton.ButtonType === DotButtonType.ITEM_BUTTON ? RecommendedProductType.ITEM_BUTTON : RecommendedProductType.MENU_BUTTON,
        quantity: 1,
        context: null,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
    });

    resolve(itemsToreturn);
  }
}
