import { Pipe, PipeTransform } from '@angular/core';
import { DotModifier } from 'dotsdk';
import { TranslationsService } from '@dotxix/services/translations/translations.service';

@Pipe({
  name: 'translateModifierStepName',
})
export class ModifierStepNameTranslationPipe implements PipeTransform {
  constructor(protected translationsService: TranslationsService) {}

  public transform(modifier: DotModifier): string | null {
    if (modifier.PageInfo && modifier.PageInfo.NameDictionary && Object.keys(modifier.PageInfo.NameDictionary).length !== 0) {
      const t = modifier.PageInfo.NameDictionary[this.translationsService.currentLanguageUpperCase];
      return t ? t : modifier.PageInfo.NameDictionary.DEF;
    } else {
      return null;
    }
  }
}
